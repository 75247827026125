import { FC, useEffect } from 'react';
import { Form, Row, Col, Space } from 'antd';
import { FormContent } from '@maxtropy/components';
import { Link } from 'react-router-dom';

import ShowInput from '../../../components/ShowInput';
import { QlDetailProps } from '../../../api/cc-tenant-ql';
import { formLayout } from '../common';
const { useForm } = Form;

interface Iprops {
  isSet?: boolean; // 区分开户和开户设置
  initData?: QlDetailProps;
}

const BaseInfo: FC<Iprops> = ({ isSet, initData }) => {
  const [form] = useForm();

  // 获取开户详情
  useEffect(() => {
    if (initData) {
      form.setFieldsValue({
        ...initData,
        qingFlowWorkspaceIdCode: initData?.qingFlowWorkspaceIdCode ? initData?.qingFlowWorkspaceIdCode : null,
      });
    }
  }, [initData, isSet, form]);

  return (
    <Form form={form} layout="vertical" {...formLayout}>
      <FormContent title="租户基础信息">
        <Row>
          <Col span={6}>
            <Form.Item name="tenantCode" label="租户CODE">
              <ShowInput></ShowInput>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="tenantName" label="租户名称">
              <ShowInput></ShowInput>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              name="qingFlowWorkspaceIdCode"
              label={
                <Space>
                  <span>轻流工作区ID</span>
                  <Link to="/tenant/customer/oalite/workspace/manage" style={{ display: 'inline-block' }}>
                    工作区管理
                  </Link>
                </Space>
              }
            >
              <ShowInput></ShowInput>
            </Form.Item>
          </Col>
          {isSet && (
            <Col span={5}>
              <Form.Item name="qingFlowDeptId" label="轻流部门ID">
                <ShowInput></ShowInput>
              </Form.Item>
            </Col>
          )}
        </Row>
      </FormContent>
    </Form>
  );
};

export default BaseInfo;
