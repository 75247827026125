import { RedoOutlined } from '@ant-design/icons';
import { Table, Paging, usePaging, useUpdate, Checkbox } from '@maxtropy/components';
import { Button, Form, Col, Input, Select } from 'antd';
import { FC, Key, useEffect, useMemo, useState } from 'react';
import { AlarmLevel, AlarmLevelDisplay, AlarmStatus, AlarmType, IotProtocolType, AlarmChannel } from '@/shared/types';
import Filter from '@/shared/components/Filter';

import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { columns, modalColumns } from '../util';
import { RulePageList } from '@/pages/Alarm/List/types';
import { V2RulePagePostRequest, apiV2RulePagePost } from '@maxtropy/tody-mgmt-apis-v2';

export interface AlarmRulesModalProps {
  value?: RulePageList[];
  onChange?: (value: RulePageList[]) => void;
  iotProtocolType?: IotProtocolType;
  mcid?: string;
  deviceTypeId?: number;
  // 规则类型
  type: AlarmType;
  disabledIds?: Key[];
  alarmChannel: AlarmChannel;
  physicalModelId?: number; // 物理模型id
}

// type SearchParams = Omit<
//   RulePageRequest,
//   'rootMcid' | 'iotProtocol' | 'size' | 'page' | 'type' | 'status' | 'deviceTypeId'
// >;

const AlarmRulesModal: FC<AlarmRulesModalProps> = ({
  value,
  onChange,
  iotProtocolType,
  mcid,
  deviceTypeId,
  disabledIds,
  type,
  alarmChannel,
  physicalModelId,
}) => {
  const [form] = Form.useForm();

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [searchParams, setSearchParams] = useState<V2RulePagePostRequest>();
  const [rules, setRules] = useState<RulePageList[]>([]);
  const [updateState, updateFn] = useUpdate();

  const ruleIds = useMemo<number[]>(() => {
    return (value ?? []).filter(i => i !== undefined).map(i => i.id as number);
  }, [value]);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    apiV2RulePagePost({
      rootMcid: mcid,
      iotProtocol: alarmChannel === AlarmChannel.LONGSHINE ? IotProtocolType.LONGSHINE : iotProtocolType,
      type,
      physicalModelId,
      channel: alarmChannel,
      deviceTypeId,
      status: AlarmStatus.ENABLE,
      onlyPhysicalModel: true,
      ...searchParams,
      page: pageOffset,
      size: pageSize,
    }).then(res => {
      setLoading(false);
      if (res) {
        setTotalCount(res.total ?? 0);
        res.list && setRules(res.list);
      }
    });
  }, [
    mcid,
    iotProtocolType,
    type,
    pageOffset,
    pageSize,
    searchParams,
    setTotalCount,
    updateState,
    deviceTypeId,
    alarmChannel,
    physicalModelId,
  ]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: RulePageList[]) => {
      // console.log(`selectedRowKeys:`, selectedRowKeys, 'selectedRows: ', selectedRows);
      // TODO: 这里需要合并value
      // console.log(selectedRows);
      const rulesId = rules.map(i => i.id);
      // const unSaveRulesId = rules.filter(i => !disabledIds?.includes(i.id)).map(i => i.id)
      const buildDataMap = new Map<number, RulePageList>();
      (value ?? [])
        .filter(i => !rulesId.includes(i.id))
        .forEach(i => {
          if (i.id !== undefined && !buildDataMap.has(i.id)) {
            buildDataMap.set(i.id, i);
          }
        });
      selectedRows.forEach(i => {
        if (i.id !== undefined && !buildDataMap.has(i.id)) {
          buildDataMap.set(i.id, i);
        }
      });
      onChange && onChange(Array.from(buildDataMap.values()));
    },
    getCheckboxProps: (record: RulePageList) => ({
      disabled: (disabledIds ?? []).includes(record.id!),
    }),
    selectedRowKeys: ruleIds,
  };

  const onFinish = (value: V2RulePagePostRequest) => {
    setSearchParams(value);
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams(undefined);
    form.setFieldsValue({
      nameOrCode: undefined,
      alarmType: undefined,
    });
    setPageOffset(1);
  };

  const filters = (
    <Filter
      form={form}
      onFinish={val => {
        onFinish(val as V2RulePagePostRequest);
      }}
      onReset={onReset}
    >
      <>
        <Col span={12}>
          <Form.Item name="nameOrCode" label="编号/名称">
            <Input placeholder="请输入编号或名称查询" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="报警等级" name="alarmType">
            <Select placeholder="请选择">
              <Select.Option value={AlarmLevel.HIGHEST}>{AlarmLevelDisplay[AlarmLevel.HIGHEST]}</Select.Option>
              <Select.Option value={AlarmLevel.HIGH}>{AlarmLevelDisplay[AlarmLevel.HIGH]}</Select.Option>
              <Select.Option value={AlarmLevel.MIDDLE}>{AlarmLevelDisplay[AlarmLevel.MIDDLE]}</Select.Option>
              <Select.Option value={AlarmLevel.LOW}>{AlarmLevelDisplay[AlarmLevel.LOW]}</Select.Option>
              <Select.Option value={AlarmLevel.LOWEST}>{AlarmLevelDisplay[AlarmLevel.LOWEST]}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="onlyPhysicalModel" valuePropName="checked" style={{ marginLeft: 20 }} initialValue={true}>
            <Checkbox>只查看物模型型号下的规则</Checkbox>
          </Form.Item>
        </Col>
      </>
    </Filter>
  );

  return (
    <>
      {filters}
      <div className={styles.tips}>
        <div>
          已选择<span className={styles.red}>{(value ?? []).length}</span>项
        </div>
        <div>
          <Button type="link">
            <Link to={`/device/rule/list/create`} target="_blank">
              新建报警规则
            </Link>
          </Button>
          <Button style={{ padding: 0, paddingLeft: 2, paddingRight: 2, height: 24 }} onClick={updateFn}>
            <div>
              <RedoOutlined rotate={270} />
              刷新页面
            </div>
          </Button>
        </div>
      </div>
      <Table
        loading={loading}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        sticky
        scroll={{ y: 300 }}
        rowKey="id"
        columns={modalColumns}
        dataSource={rules}
      />
      <Paging pagingInfo={pagingInfo} />
    </>
  );
};

export default AlarmRulesModal;
