import { ForwardRefRenderFunction, useEffect, useImperativeHandle, useRef, useState, forwardRef } from 'react';
import { Key, getRealUrl, RoleTree, TreeDataNode as DataNode, getRolePermissions } from '@maxtropy/components';

import styles from './index.module.scss';
import {
  getIntegratedAppPermissionTree,
  getIntegratedPermissionList,
  IntegratedAppPermission,
} from '../../../api/cc-role';
import { AppFunctionPermissionVo } from '../../../api/cc-app';

interface EditIntegratedPermissionsProps {
  disabled?: boolean;
  integratedAppId: number;
  roleCode: string;
}

export interface RefProps {
  submit: () => IntegratedAppPermission;
}

export function dig(data: AppFunctionPermissionVo[], level = 0): DataNode[] {
  return data.map(item => {
    const isLeaf = !(Array.isArray(item.children) && item.children.length > 0);
    const node = {
      children: [] as DataNode[],
      key: item.code,
      title: item.name,
      customized: !item.standard,
      type: item.type,
      pic: getRealUrl(item.pic),
      description: item.description,
    };
    if (!isLeaf) {
      node.children = dig(item.children, level + 1);
    }
    return node;
  });
}

const EditIntegratedPermissions: ForwardRefRenderFunction<RefProps, EditIntegratedPermissionsProps> = (
  { disabled = false, integratedAppId, roleCode },
  ref
) => {
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<Key[]>([]);
  const [autoSyncedKeys, setAutoSyncedKeys] = useState<Key[]>([]);
  const originalData = useRef<{ originalCheckedKeys: Key[]; originalAutoSyncedKeys: Key[] }>();

  useEffect(() => {
    if (integratedAppId !== undefined || integratedAppId !== null) {
      getIntegratedAppPermissionTree(integratedAppId).then(data => {
        const treeData = dig(data);
        setTreeData(treeData);
      });
    }
  }, [integratedAppId]);

  useEffect(() => {
    if ((integratedAppId !== undefined || integratedAppId !== null) && roleCode) {
      getIntegratedPermissionList(roleCode, integratedAppId).then(data => {
        const { permissions = [], syncs = [] } = data;
        setCheckedKeys(permissions);
        setAutoSyncedKeys(syncs);
        originalData.current = { originalCheckedKeys: permissions, originalAutoSyncedKeys: syncs };
      });
    }
  }, [roleCode, integratedAppId]);

  useImperativeHandle(
    ref,
    () => ({
      submit: () => {
        const { permissions, syncs } = getRolePermissions({
          treeData,
          checkedKeys,
          autoSyncedKeys,
          ...originalData.current!,
        });
        return {
          roleCode: roleCode,
          addPermissions: permissions[0],
          deletePermission: permissions[1],
          addSyncs: syncs[0],
          deleteSyncs: syncs[1],
          integratedAppId,
        };
      },
    }),
    [checkedKeys, autoSyncedKeys, treeData, roleCode, integratedAppId]
  );

  return (
    <>
      <div className={styles.treeContainer}>
        <h3>权限功能管理</h3>
        <RoleTree
          treeData={treeData}
          className={styles.treeWrapper}
          checkedKeys={checkedKeys}
          onCheck={setCheckedKeys}
          autoSyncedKeys={autoSyncedKeys}
          onAutoSync={setAutoSyncedKeys}
          autoSyncable
          showDetail
          disabled={disabled}
        />
      </div>
    </>
  );
};

export default forwardRef(EditIntegratedPermissions);
