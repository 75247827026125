// 发布状态
export enum StatusEnum {
  DRAFT = 1,
  PUBLISHED = 2,
  PACKING = 3,
  PACKED = 4,
}

export const StatusEnumDisplay = {
  [StatusEnum.DRAFT]: '编辑',
  [StatusEnum.PUBLISHED]: '已发布',
  [StatusEnum.PACKING]: '打包中',
  [StatusEnum.PACKED]: '已发布',
};

export enum UpgradeStatusEnum {
  // 0 编辑 1 升级
  EDIT = 0,
  UPGRADE = 1,
}

export interface ListDataType {
  id?: number;
  /**
   * ID
   */
  code?: string;
  /**
   * 名字
   */
  name?: string;
  /**
   * 状态1->草稿 2->已发布
   */
  status?: number;
  /**
   * 主体
   */
  body?: string;
  /**
   * 版本
   */
  version?: string;
  upgradeStatus?: number;
  /**
   * 更新时间
   */
  updateTime?: {
    /**
     * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
     */
    seconds?: number;
    /**
     * The number of nanoseconds, later along the time-line, from the seconds field.
     * This is always positive, and never exceeds 999,999,999.
     */
    nanos?: number;
  };
}
