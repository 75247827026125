export enum FactorPropertyEnum {
  // 燃料相关
  // 燃料类型
  FuelType = 100,
  // 低位发热值
  LowCalorificValue = 101,
  // 单位热值含碳量
  CarbonContentPerUnitCalorificValue = 102,
  // 燃料碳氧化率
  FuelCarbonOxidationRate = 103,
  // 单位
  FuelUnit = 104,

  // 碳酸盐相关
  // CO2排放因子
  CarbonateCO2EmissionFactor = 200,
  // 单位
  CarbonateUnit = 201,
  // 分子式
  CarbonateMolecularFormula = 202,

  // 电力相关
  // CO2排放因子
  ElectricCO2EmissionFactor = 300,
  // 单位
  ElectricUnit = 301,

  // 热力相关
  // CO2排放因子
  HeatCO2EmissionFactor = 400,
  // 单位
  HeatUnit = 401,

  // 蒸汽相关
  // 蒸汽类型
  SteamType = 500,
  // 压力/MPa
  SteamPressure = 501,
  // 温度/℃
  SteamTemp = 502,
  // 热焓值/kJ/kg
  SteamEnthalpy = 503,

  // MCF相关
  // 处理方法
  MCFApproach = 600,
  // MCF值
  MCFValue = 601,
  // 范围
  MCFRange = 602,
  // 备注
  MCFRemark = 603,

  // 工业生产过程相关
  // 工艺方法
  ProcessMethod = 700,
  // 排放因子
  ProcessEmissionFactor = 701,

  // GWP相关
  // GWP值
  GWPValue = 800,
  // 分子式
  GWPMolecularFormula = 801,
  // 分子量
  GWPMolecularWeight = 802,

  // 通用因子
  // CH4排放因子
  CH4 = 2,
  // N2O排放因子
  N2O = 3,
  // HFCs排放因子
  HFCs = 4,
  // PFCs排放因子
  PFCs = 5,
  // SF6排放因子
  SF6 = 6,
  // NF3排放因子
  NF3 = 7,
  TRANSPORTATION_CO2EmissionFactor = 900,
  GAS_DISPERSION_CO2EmissionFactor = 1000,
  WASTE_DISPOSAL_CO2EmissionFactor = 1100,
  EMPLOYEE_COMMUTING_CO2EmissionFactor = 1200,
  // 单位
  CommonUnit = 8,
}

export enum FuelTypeEnum {
  SOLIDITY = 1,
  LIQUIDITY = 2,
  GAS = 3,
}

export const fuelTypeValue = {
  [FuelTypeEnum.SOLIDITY]: '固态燃料',
  [FuelTypeEnum.LIQUIDITY]: '液态燃料',
  [FuelTypeEnum.GAS]: '气态燃料',
};

export const fuelTypeOptions = [
  { label: fuelTypeValue[FuelTypeEnum.SOLIDITY], value: FuelTypeEnum.SOLIDITY },
  { label: fuelTypeValue[FuelTypeEnum.LIQUIDITY], value: FuelTypeEnum.LIQUIDITY },
  { label: fuelTypeValue[FuelTypeEnum.GAS], value: FuelTypeEnum.GAS },
];

export enum SteamTypeEnum {
  SATURATED = 1,
  SUPERHEAT = 2,
}

export const steamTypeValue = {
  [SteamTypeEnum.SATURATED]: '饱和蒸汽',
  [SteamTypeEnum.SUPERHEAT]: '过热蒸汽',
};

export const steamTypeOptions = [
  { label: steamTypeValue[SteamTypeEnum.SATURATED], value: SteamTypeEnum.SATURATED },
  { label: steamTypeValue[SteamTypeEnum.SUPERHEAT], value: SteamTypeEnum.SUPERHEAT },
];
