export interface Options {
  label: string | undefined;
  value: number | undefined;
}

export interface Query {
  name?: string;
  industryId?: string;
  status?: Status;
}

export enum Status {
  DRAFT = 0,
  ENABLE = 1,
  DISABLE = 2,
}

export const StatusDisplay = {
  [Status.DRAFT]: '草稿',
  [Status.ENABLE]: '启用',
  [Status.DISABLE]: '禁用',
};

export const StatusBtnDisplayMap = {
  [Status.DRAFT]: '启用',
  [Status.ENABLE]: '禁用',
  [Status.DISABLE]: '启用',
};

export interface TableData {
  page: number;
  size: number;
  total: number;
  list: List[];
}

export interface List {
  id: number;
  industryId: number;
  standard: string;
  issuingInstitution: string;
  issuingDate: string;
  state: number;
  guideClassificationList: ListGuideClassificationList[];
}

export interface ListGuideClassificationList {
  id: number;
  range: number;
  classificationName: string;
  classificationOrder: number;
  createTime: AteTime;
  updateTime: AteTime;
  guideClassificationList: GuideClassificationListGuideClassificationList[];
}

export interface AteTime {
  seconds: number;
  nanos: number;
}

export interface GuideClassificationListGuideClassificationList {
  id: number;
  classificationId: number;
  emissionProcessId: number;
  processOrder: number;
  createTime: AteTime;
  updateTime: AteTime;
}
