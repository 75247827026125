import { FC, useDeferredValue, useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Row, Col, Button, Modal, Space, Divider, Cascader } from 'antd';
import { AttachmentUpload } from '@maxtropy/components';
import { commonPhoneRegex } from '../../../utils/regex';
import ModalSelect from '../components/ModalSelect';

import { updateTenantBaseInfo, CreateTenantBaseInforequest, TenantBaseInfoResponse } from '../../../api/cc-tenant';
import { TabsBaseProps } from '../util';

import { useCountDownGotoModal } from '../../../utils/help';

import styles from './index.module.scss';
import { areaCodeToName, getParents, useAreaJson } from '../../../utils/hooks';
import { LocationTipsResponse, fetchLocationTips } from '../../../api/cc-location';
import { SearchLocation } from '../../../components/FormComponent';

const formLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const { useForm } = Form;

interface EditTenantBaseInfoProps extends TabsBaseProps {
  tenant?: TenantBaseInfoResponse;
  disabled?: boolean;
}

const EditTenantBaseInfo: FC<EditTenantBaseInfoProps> = props => {
  const [modalApi, modalContextHolder] = Modal.useModal();
  const { onBreacrumbChange, tenant, disabled = false } = props;
  const navigate = useNavigate();
  const [form] = useForm();

  const [locationSearchData, setLocationSearchData] = useState<LocationTipsResponse['tips']>([]);

  const adCode = Form.useWatch<Array<string> | undefined>('adCode', form);

  const [search, setSearch] = useState<string>('');
  const [searchLoading, setSearchLoading] = useState<boolean>(false);

  const deferredSearch = useDeferredValue(search);

  useEffect(() => {
    if (deferredSearch && adCode) {
      setSearchLoading(true);
      fetchLocationTips({
        keywords: deferredSearch,
        city: adCode[2],
      })
        .then(res => {
          setLocationSearchData(res.tips);
          setSearch('');
        })
        .finally(() => {
          setSearchLoading(false);
        });
    }
  }, [adCode, deferredSearch]);

  const districtData = useAreaJson();

  const routes = useMemo(() => {
    return [
      { name: '租户管理' },
      { name: '租户组织权限和账户' },
      { name: `${disabled ? '查看' : '编辑'}租户` },
      { name: '租户基础信息' },
    ];
  }, [disabled]);

  const { setOption, contextHolder } = useCountDownGotoModal(10);

  useEffect(() => {
    if (tenant && districtData) {
      form.setFieldsValue({
        ...tenant,
        adCode: getParents(districtData, tenant.adCode).map(i => i.adcode),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant, districtData]);

  useEffect(() => {
    onBreacrumbChange && onBreacrumbChange(routes);
  }, [onBreacrumbChange, routes]);

  const onFinish = (values: Omit<CreateTenantBaseInforequest, 'adCode'> & { adCode: string[] }) => {
    console.log('333', values);
    updateTenantBaseInfo({
      id: tenant?.id!,
      ...values,
      adCode: values?.adCode?.[values.adCode.length - 1],
      provinceCityDistrict: values?.adCode
        ? areaCodeToName(districtData ?? [], values.adCode[values.adCode.length - 1])
        : undefined,
      affiliatendMcids: (values?.enterprises ?? []).map(i => i.mcid),
      enterprises: undefined,
    }).then(res => {
      setOption({
        // title: '操作成功',
        okText: '返回列表',
        content: `已经成功编辑租户${res.name}信息`,
        keyboard: false,
        onOk() {
          navigate('/tenant/customer/manage');
        },
      });
    });
  };

  const onCacel = () => {
    modalApi.confirm({
      title: null,
      icon: null,
      content: <div>是否放弃所有未保存信息并返回列表？</div>,
      onOk: () => {
        navigate('/tenant/customer/manage');
      },
    });
  };

  return (
    <div className={styles.container}>
      <Form layout="vertical" {...formLayout} onFinish={onFinish} form={form}>
        <Row>
          <Col span={8}>
            <Form.Item
              name="name"
              label="租户全称"
              rules={[
                { required: true, message: '请输入租户全称' },
                { pattern: /^[\u4e00-\u9fa5a-zA-Z0-9\s\-.,()\uFF08\uFF09]+$/g, message: '租户全称不能包含特殊字符' },
                { max: 30, message: '租户全称最多30个字符' },
              ]}
              validateFirst={true}
            >
              <Input placeholder="请输入租户全称" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="uscc"
              label="统一社会信用代码"
              rules={[
                {
                  pattern: /(^\d{15,15}$)|(^[A-Z0-9]{18,18}$)/,
                  message: '请输入正确的统一社会信用代码',
                },
              ]}
              validateFirst={true}
            >
              <Input placeholder="请输入统一社会信用代码" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="所属省市" name="adCode">
              <Cascader
                disabled={disabled}
                options={districtData}
                fieldNames={{ label: 'name', value: 'adcode', children: 'districts' }}
                placeholder="请选择"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="详细地址" name="address">
              <SearchLocation
                disabled={disabled}
                onSearch={setSearch}
                placeholder="请输入详细地址"
                loading={searchLoading}
                options={(locationSearchData ?? []).map(d => ({
                  value: d.name,
                  label: d.name,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="公司电话"
              name="phone"
              rules={[
                {
                  pattern: commonPhoneRegex,
                  message: '请输入正确的公司电话',
                },
              ]}
            >
              <Input placeholder="请输入公司电话" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="营业执照" name="businessLicense">
              <AttachmentUpload
                hoverShow
                disabled={disabled}
                showUploadList={{
                  showDownloadIcon: true,
                }}
              />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item label="添加关联企业" name="enterprises">
              <ModalSelect disabled={disabled} tenant={tenant} />
            </Form.Item>
          </Col>
        </Row>
        {!disabled && (
          <Row className={styles.buttonWrapper}>
            <Divider />
            <Col span={7}>
              <Form.Item>
                <Space>
                  <Button htmlType="submit" type="primary">
                    保存
                  </Button>
                  <Button onClick={onCacel}>关闭</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
      {contextHolder}
      {modalContextHolder}
    </div>
  );
};

export default EditTenantBaseInfo;
