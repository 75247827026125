import React, { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { Input, InputRef, Space, Tag, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import './index.scss';
import useMergedState from '../../hooks/useMergedState';

interface DeviceLabelProps {
  value?: string[];
  onChange?: (value: string[]) => void;
  disabled?: boolean;
}

const DeviceTags: React.FC<DeviceLabelProps> = props => {
  const prefixCls = 'mx';
  const { disabled } = props;
  const [tags, setTags] = useMergedState<string[]>([], { value: props.value, onChange: props.onChange });
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');

  const saveInputRef = useRef<InputRef>(null);
  const saveEditInputRef = useRef<InputRef>(null);

  useEffect(() => {
    saveInputRef.current?.focus();
  }, [inputVisible]);

  useEffect(() => {
    saveEditInputRef.current?.focus();
  }, [editInputIndex]);

  const handleClose = (removedTag: string) => {
    setTags(tags.filter(tag => tag !== removedTag));
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = e => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    let _tags = tags;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      _tags = [...tags, inputValue];
    }
    setTags(_tags);
    setInputVisible(false);
    setInputValue('');
  };

  const handleEditInputChange: ChangeEventHandler<HTMLInputElement> = e => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;

    setTags(newTags);
    setEditInputIndex(-1);
    setEditInputValue('');
  };

  return (
    <Space size={[8, 14]} wrap style={{ alignItems: 'center', marginBottom: 10 }}>
      {tags.map((tag, index) => {
        if (editInputIndex === index) {
          return (
            <Input
              ref={saveEditInputRef}
              key={tag}
              size="small"
              className="tag-input"
              value={editInputValue}
              onChange={handleEditInputChange}
              onBlur={handleEditInputConfirm}
              onPressEnter={handleEditInputConfirm}
              maxLength={10}
            />
          );
        }

        const isLongTag = tag.length > 20;

        const tagElem = (
          <Tag key={tag} closable={!disabled} onClose={() => handleClose(tag)} style={{ userSelect: 'none' }}>
            <span
              onDoubleClick={e => {
                if (!disabled) {
                  setEditInputIndex(index);
                  setEditInputValue(tag);
                  e.preventDefault();
                }
              }}
            >
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </span>
          </Tag>
        );
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
      {inputVisible && (
        <Input
          ref={saveInputRef}
          type="text"
          size="small"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
          style={{ width: 78, verticalAlign: 'top' }}
          maxLength={10}
        />
      )}
      {!inputVisible && tags.length < 5 && !disabled && (
        <Tag onClick={showInput} className={`${prefixCls}-device-tags-add`}>
          <PlusOutlined /> 标签
        </Tag>
      )}
    </Space>
  );
};

export default DeviceTags;

export const deviceTagsValidator = (_: any, tags: string[]) => {
  if (
    (tags ?? []).every(tag => /^[\u4e00-\u9fa5a-zA-Z0-9\/\-—*\u0000-\u00ff]+$/g.test(tag)) &&
    (tags ?? []).every(tag => !/\s+/g.test(tag))
  ) {
    return Promise.resolve();
  } else {
    return Promise.reject('可输入的特殊符号有：【/】、【-】、【—】、【*】、半角标点符号');
  }
};
