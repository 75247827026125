import { EllipsisSpan } from '@maxtropy/components';
import { Tag } from 'antd';
import { AlarmLevel, AlarmLevelColorDisplay, AlarmLevelDisplay } from '@/shared/types';

export const columns = [
  {
    title: '规则编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '规则名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警信息',
    dataIndex: 'alarmName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警等级',
    dataIndex: 'alarmType',
    ellipsis: { showTitle: true },
    render: (v: AlarmLevel) => (
      <EllipsisSpan value={<Tag color={AlarmLevelColorDisplay[v]}>{AlarmLevelDisplay[v]}</Tag>} />
    ),
  },
];

export const modalColumns = [
  {
    title: '规则编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '规则名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警信息',
    dataIndex: 'alarmName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备类目',
    dataIndex: 'deviceTypeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '物模型',
    dataIndex: 'physicalModelName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警等级',
    dataIndex: 'alarmType',
    ellipsis: { showTitle: true },
    render: (v: AlarmLevel) => (
      <EllipsisSpan value={<Tag color={AlarmLevelColorDisplay[v]}>{AlarmLevelDisplay[v]}</Tag>} />
    ),
  },
];
