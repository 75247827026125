import React, { useEffect, useState } from 'react';
import { Form, Input, Checkbox, Button, Alert } from 'antd';
import Cookies from 'js-cookie';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { login, LoginRequest } from '@/api/login';
import styles from './index.module.scss';
import vo from '../../assets/video/welcome.webm';

const Login = () => {
  const [form] = Form.useForm();
  const [btnTxt, setBtnTxt] = useState<string>('登录');
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [alertTxt, setAlertTxt] = useState<string>('');

  const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > 0;

  useEffect(() => {
    const rememberUserName = localStorage.getItem('rememberUserName');

    const formValues: Record<string, any> = {
      rememberUserName: true,
    };

    if (rememberUserName === 'true') {
      const username = localStorage.getItem('username');
      formValues.username = username;
    }

    form.setFieldsValue(formValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleForm = () => {
    const { username, password, rememberUserName } = form.getFieldsValue();
    if (!username || !password) {
      const text = !username && !password ? '请输入账号  请输入密码' : !username ? '请输入账号' : '请输入密码';
      setAlertTxt(text);
      setVisible(true);
      return;
    }

    if (rememberUserName) {
      localStorage.setItem('rememberUserName', 'true');
      localStorage.setItem('username', username);
    } else {
      localStorage.setItem('rememberUserName', 'false');
      localStorage.removeItem('username');
    }

    setBtnTxt('请稍后...');
    setLoading(true);

    const params: LoginRequest = {
      username,
      password,
    };

    login(params)
      .then(res => {
        Cookies.set('token', res.jwtToken);
        const urlParams = new URLSearchParams(window.location.search);
        const url = urlParams.get('redirect');

        window.location.replace(url || '/');
      })
      .finally(() => {
        setBtnTxt('登录');
        setLoading(false);
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.loginHeader}>
          <div className={styles.headerTitle}>{window?.SLOGAN ?? '工业赋能 模式创新'}</div>
          <div className={styles.headerSubTitle}>
            {window?.IDEAS ?? '打造闭环赋能体系，共建工业互联网生态，落地产业应用！'}
          </div>
        </div>
        <div className={styles.display}>
          {isChrome ? (
            <video className={styles.displayVideo} muted autoPlay loop>
              <source src={vo} type="video/webm" />
            </video>
          ) : (
            <div className={styles.displayImage} />
          )}
        </div>
        <div className={styles.loginForm}>
          <div className={styles.cardForm}>
            <div className={styles.cardFormTitle}>
              <h2 className={styles.mainTitle}>{window?.LOGINTITLE ?? '熵云工业互联网统一登录中心'}</h2>
              <h5 className={styles.subTitle}>{window?.WELCOMETXT ?? '欢迎登录'}</h5>
            </div>
            {visible && (
              <Alert
                className={styles.alertMsg}
                message={alertTxt}
                type="error"
                closable
                onClose={() => setVisible(false)}
              />
            )}
            <Form form={form} className={styles.cardFormBody}>
              <Form.Item name="username" className={styles.formGroup}>
                <Input placeholder="账号" size="large" prefix={<UserOutlined />} />
              </Form.Item>
              <Form.Item name="password" className={styles.formGroup}>
                <Input.Password placeholder="密码" size="large" prefix={<LockOutlined />} />
              </Form.Item>
              <Form.Item name="rememberUserName" valuePropName="checked" className={styles.rememberName}>
                <Checkbox>记住账号</Checkbox>
              </Form.Item>
              <Button className={styles.loginBtn} onClick={handleForm} loading={loading}>
                {btnTxt}
              </Button>
            </Form>
          </div>
        </div>
        <p className={styles.bottomText}>
          <a id="policeConfig" href={window.CASPOLICEURL}>
            {window.CASPOLICETXT}
          </a>
        </p>
      </div>
    </div>
  );
};

export default Login;
