import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Form } from 'antd';
import { FormInstance } from 'antd/es/form';
import { FieldValue, AttributeField } from '@/shared/types';
import AttributeItem from '@/shared/components/AttributeItem';
import CustomAttributesDisplay from '@/shared/components/CustomAttributes';
import { getTemplateByVersion, Template } from '../../../../api/template';
import { ActionType } from '../index';
import styles from './index.module.scss';

export interface AttributeFormValues {
  [key: string]: FieldValue;
}

export interface AttributeFormRef {
  form: FormInstance<AttributeFormValues> | null;
  templateFlatten: AttributeField[];
  checkedIds: number[];
}

export interface AttributeFormProps {
  templateId?: number;
  templateVersion?: number;
  actionType?: ActionType;
}

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 9 },
};

const AttributeForm = React.forwardRef(
  ({ templateId, templateVersion, actionType }: AttributeFormProps, ref: React.Ref<AttributeFormRef>) => {
    const [form] = Form.useForm();
    const formRef = React.useRef(null);

    const [template, setTemplate] = useState<Template>();
    const [checkedIds, setCheckedIds] = useState<number[]>([]);

    useEffect(() => {
      setTemplate(undefined);
      if (templateId !== undefined && templateVersion !== undefined) {
        getTemplateByVersion(templateId, templateVersion).then(res => {
          if (res && res.template) {
            setTemplate(res.template);
          }
        });
      }
    }, [templateId, templateVersion]);

    const templateFlatten = useMemo(() => {
      if (template) {
        return template.deviceAttributes.map(i => i.deviceFields).flat();
      } else {
        return [];
      }
    }, [template]);

    const customAttributes = useMemo(() => (template?.description ? JSON.parse(template.description) : []), [template]);

    useImperativeHandle(ref, () => ({
      form: formRef.current,
      templateFlatten,
      checkedIds,
    }));

    const onCheck = (checked: boolean, item: AttributeField) => {
      form.resetFields([item.id]);

      const copyCheckedIds = [...checkedIds];
      const index = checkedIds.indexOf(item.id);
      if (checked && index === -1) {
        copyCheckedIds.push(item.id);
      } else if (!checked && index > -1) {
        copyCheckedIds.splice(index, 1);
      }
      setCheckedIds(copyCheckedIds);
    };

    const checkable = actionType === ActionType.EDIT;

    return (
      <Form form={form} {...formItemLayout} ref={formRef} validateTrigger="onBlur">
        <div className={styles.list}>
          {template?.deviceAttributes.map((item, index) => (
            <AttributeItem key={index} disabled={checkable} attribute={item} checkable={checkable} onCheck={onCheck} />
          ))}
        </div>
        <CustomAttributesDisplay style={{ padding: '20px' }} customAttributes={customAttributes} />
      </Form>
    );
  }
);

export default AttributeForm;
