import { CSSProperties } from 'react';

export function str2LongStrList(content?: string): string[] {
  if (!content) {
    return [];
  }
  return content
    .split(/[,;\s]+/)
    .map(x => x.trim())
    .filter(x => x)
    .filter(x => isFinite(parseFloat(x)));
}

export function str2doubleList(content?: string): number[] {
  if (!content) {
    return [];
  }
  return content
    .split(/[，,;\s]+/u)
    .map(x => x.trim())
    .filter(x => x)
    .map(i => +i)
    .filter(x => isFinite(x));
}

export const cssMonospaceFont: CSSProperties = {
  fontFamily: 'Consolas, Menlo, monospace',
};
