import { useCallback, useMemo, useState, FC, useEffect, useRef } from 'react';
import {
  createPointSetV2,
  deletePointSetV2,
  getPointSetByRuleSetIdV2,
  updatePointSetV2,
} from '../../api/mockPoint-set';
import { Button, Checkbox, Col, Form, Input, List, message, Modal, Row, Select, SelectProps, Spin, Tag } from 'antd';
import { PointSetV2, Device } from '../../api/entities';
import { cssMonospaceFont } from './util';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { renderPointKey } from './RuleSets';
import { getDataPropertyList, getDeviceList } from '../../api/mockDevice';
import { debounce, isEmpty, isNil } from 'lodash-es';

interface PointSetRowProps {
  canEdit: boolean;
  pointSetId?: number;
  pointSet: PointSetV2;
  isCreate: boolean;
  startEdit: () => void;
  endEdit: () => void;
  tenantMcid: string;
}

// prettier-ignore
const PointSetRow: FC<PointSetRowProps> = props => {// NOSONAR
  const [messageApi, messageContextHolder] = message.useMessage();
  const [modalApi, modalContextHolder] = Modal.useModal();
  const { pointSet, pointSetId, isCreate, startEdit, endEdit, canEdit, tenantMcid } = props;
  const [editing, setEditing] = useState(isCreate);
  const [saveLoading, setSaveLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [fetchingDevices, setFetchingDevices] = useState(false);
  const fetchDevicesRef = useRef(0);
  const [editDevices, setEditDevices] = useState<{ value: number; label: string }[]>(
    pointSet.devices.map(v => ({ value: v.id, label: v.name }))
  );
  const [deviceOptions, setDeviceOptions] = useState<SelectProps['options']>(
    pointSet.devices.map(v => ({ label: v.name, value: v.id }))
  );
  const initialFetchedDevicesRef = useRef(false);
  const [fetchingDataPropertys, setFetchingDataPropertys] = useState(false);
  const [editDataPropertys, setEditDataPropertys] = useState<number[]>(pointSet.dataPropertyIds);
  const [dataPropertyOptions, setDataPropertyOptions] = useState<SelectProps['options']>(
    pointSet.dataPropertys.map(v => ({ value: v.id, label: v.name }))
  );
  const [editNonModelingPointNames, setEditNonModelingPointNames] = useState<string[]>(pointSet.nonModelingPointNames);
  const [editComputeOnce, setEditComputeOnce] = useState(pointSet.computeOnce);
  const [editDataTimeRuleConstants, setEditDataTimeRuleConstants] = useState<number[]>(pointSet.dataTimeRuleConstants);
  const [editDataValueRuleConstants, setEditDataValueRuleConstants] = useState<number[]>(
    pointSet.dataValueRuleConstants
  );

  const createOrUpdatePointSet = () => {
    setSaveLoading(true);
    let savePromise: Promise<PointSetV2>;
    if (isCreate) {
      savePromise = createPointSetV2({
        ruleSetId: pointSet.ruleSetId,
        computeOnce: editComputeOnce,
        dataTimeRuleConstants: editDataTimeRuleConstants,
        dataValueRuleConstants: editDataValueRuleConstants,
        deviceIds: editDevices.map(v => v.value),
        dataPropertyIds: editDataPropertys,
        nonModelingPointNames: editNonModelingPointNames,
      });
    } else {
      if (isNil(pointSetId)) return;
      savePromise = updatePointSetV2(pointSetId, {
        ruleSetId: pointSet.ruleSetId,
        computeOnce: editComputeOnce,
        dataTimeRuleConstants: editDataTimeRuleConstants,
        dataValueRuleConstants: editDataValueRuleConstants,
        deviceIds: editDevices.map(v => v.value),
        dataPropertyIds: editDataPropertys,
        nonModelingPointNames: editNonModelingPointNames,
      });
    }
    savePromise
      .then(() => {
        messageApi.success('保存成功');
      })
      .catch(e => {
        messageApi.error('保存失败');
      })
      .finally(() => {
        setSaveLoading(false);
        setEditing(false);
        endEdit();
      });
  };

  const onSave = () => {
    if (saveLoading) return;

    if (isEmpty(editDevices)) {
      modalApi.warning({
        title: '设备不能为空',
      });
      return;
    }

    if (isEmpty(editDataPropertys) && isEmpty(editNonModelingPointNames)) {
      modalApi.warning({
        title: '数据属性和非建模点必须有一个为非空！',
      });
      return;
    }

    if (!isEmpty(editDevices) && isEmpty(editDataPropertys)) {
      modalApi.confirm({
        title: '设备属性为空, 确认要提交吗?',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          createOrUpdatePointSet();
        },
        onCancel() {
          return;
        },
      });
      return;
    }

    createOrUpdatePointSet();
  };

  const onCancel = useCallback(() => {
    if (saveLoading) return;
    setEditing(false);
    endEdit();
  }, [saveLoading, endEdit]);

  const onDelete = () => {
    if (!canEdit) return;
    startEdit();
    setDeleteLoading(true);
    if (isNil(pointSetId)) return;
    deletePointSetV2(pointSetId)
      .then(() => {
        messageApi.success('删除成功');
      })
      .catch(e => {
        messageApi.error('删除失败');
      })
      .finally(() => {
        setDeleteLoading(false);
        endEdit();
      });
  };

  const onEdit = useCallback(() => {
    if (!canEdit) return;
    setEditing(true);
    startEdit();
  }, [canEdit, startEdit]);

  useEffect(() => {
    if (!isEmpty(editDevices)) {
      getDataPropertyList({ deviceIds: editDevices.map(v => v.value) }, tenantMcid)
        .then(res => {
          setDataPropertyOptions(res.map(v => ({ value: v.id, label: v.name })));
          // 选中的设备属性也根据设备变化, 若删除了对应的设备, 选中设备属性也随之删除, 只保留对应设备的
          setEditDataPropertys(prevEditDataPropertys =>
            prevEditDataPropertys.filter(v => res.map(r => r.id).includes(v))
          );
        })
        .finally(() => {
          setFetchingDataPropertys(false);
        });
    } else {
      // 未选设备, 数据属性以及选项均被清空
      setDataPropertyOptions([]);
      setEditDataPropertys([]);
    }
  }, [editDevices, tenantMcid]);

  const combineDeviceOptions = useCallback(
    (res: Device[]) => {
      // 筛选不在已选的设备里的设备
      const notIncludedDevices = res
        .filter(v => !editDevices.map(v => v.value).includes(v.id))
        .sort((a, b) => Date.parse(b.updateTime) - Date.parse(a.updateTime));
      // 最多展示 20 个数据, 需要保证之前已选的设备在下拉框里, 同时请求得到的新设备在按 updateTime 排序插入
      setDeviceOptions([
        ...editDevices,
        ...notIncludedDevices.slice(0, 20 - editDevices.length).map(v => ({ value: v.id, label: v.name })),
      ]);
    },
    [editDevices]
  );

  useEffect(() => {
    if (initialFetchedDevicesRef.current === false) {
      setFetchingDevices(true);
      getDeviceList({ tenantMcid })
        .then(res => {
          combineDeviceOptions(res);
        })
        .finally(() => {
          setFetchingDevices(false);
          initialFetchedDevicesRef.current = true;
        });
    }
  }, [combineDeviceOptions, tenantMcid]);

  const onDeviceSearch = useMemo(() => {
    const loadDeviceOptions = (searchValue: string) => {
      fetchDevicesRef.current += 1;
      const fetchId = fetchDevicesRef.current;
      setFetchingDevices(true);

      getDeviceList({ tenantMcid, name: searchValue })
        .then(res => {
          if (fetchId !== fetchDevicesRef.current) {
            // for fetch callback order
            return;
          }
          combineDeviceOptions(res);
        })
        .finally(() => {
          setFetchingDevices(false);
        });
    };

    return debounce(loadDeviceOptions, 800);
  }, [combineDeviceOptions, tenantMcid]);

  return editing ? (
    <Row gutter={5} align="middle" style={{ width: '100%' }}>
      <Col span={6}>
        {/* <Input
          addonBefore="点"
          style={cssMonospaceFont}
          placeholder="逗号分隔"
          value={editPointKeys}
          onChange={e => setEditPointKeys(e.target.value)}
        /> */}
        <Form.Item label="选择设备">
          <Select
            labelInValue
            allowClear
            filterOption={false}
            style={{ ...cssMonospaceFont }}
            mode="multiple"
            value={editDevices}
            options={deviceOptions}
            onChange={(values: { label: string; value: number }[]) => {
              setEditDevices(values);
            }}
            // onSearch 无法监听选中之后输入框搜索值变为空的情况, 即这种情况下不会触发请求, 导致 options 仍旧为之前搜索得到的结果
            onSearch={onDeviceSearch}
            onBlur={() => {
              // 失焦后清空搜索内容并且请求一次所有的设备
              getDeviceList({ tenantMcid }).then(res => {
                combineDeviceOptions(res);
              });
            }}
            notFoundContent={fetchingDevices ? <Spin size="small" /> : null}
          />
        </Form.Item>
        <Form.Item label="输入数据属性">
          <Select
            allowClear
            style={{ ...cssMonospaceFont }}
            mode="multiple"
            optionFilterProp="label"
            value={editDataPropertys}
            options={dataPropertyOptions}
            onChange={(values: number[]) => {
              setEditDataPropertys(values);
            }}
            notFoundContent={fetchingDataPropertys ? <Spin size="small" /> : null}
          />
        </Form.Item>
        <Form.Item label="输入非建模点">
          <Select
            allowClear
            placeholder="回车输入新非建模点"
            style={{ ...cssMonospaceFont }}
            mode="tags"
            open={false}
            value={editNonModelingPointNames}
            onChange={(values: string[]) => {
              setEditNonModelingPointNames(values);
            }}
          />
        </Form.Item>
      </Col>
      <Col span={3}>
        <Checkbox checked={editComputeOnce} onChange={e => setEditComputeOnce(e.target.checked)} /> 统一计算
      </Col>
      <Col span={6}>
        <Input.Group compact>
          <Input
            disabled
            value="时参"
            style={{ width: '20%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto', backgroundColor: '#FAFAFA' }}
          />
          <Select
            allowClear
            placeholder="回车输入新内容"
            style={{ ...cssMonospaceFont, width: '80%' }}
            mode="tags"
            open={false}
            value={editDataTimeRuleConstants}
            onChange={value => {
              setEditDataTimeRuleConstants(value);
            }}
          />
        </Input.Group>
      </Col>
      <Col span={6}>
        <Input.Group compact>
          <Input
            disabled
            value="值参"
            style={{ width: '20%', color: 'rgba(0, 0, 0, 0.65)', cursor: 'auto', backgroundColor: '#FAFAFA' }}
          />
          <Select
            allowClear
            placeholder="回车输入新内容"
            style={{ ...cssMonospaceFont, width: '80%' }}
            mode="tags"
            open={false}
            value={editDataValueRuleConstants}
            onChange={value => {
              setEditDataValueRuleConstants(value);
            }}
          />
        </Input.Group>
      </Col>
      <Col span={3}>
        <Button
          type="primary"
          loading={saveLoading}
          disabled={saveLoading}
          icon={<CheckOutlined />}
          size="small"
          onClick={onSave}
        />
        &ensp;
        <Button loading={saveLoading} disabled={saveLoading} icon={<CloseOutlined />} size="small" onClick={onCancel} />
      </Col>
    </Row>
  ) : (
    <Row gutter={5} align="middle" style={{ width: '100%' }}>
      <Col span={6}>
        {/* <Tag style={{ width: '100%', whiteSpace: 'normal' }}>{editPointKeys.replaceAll(',', ', ')}</Tag> */}
        {renderPointKey(pointSet, tenantMcid)}
      </Col>
      <Col span={3}>
        {pointSet.computeOnce ? <Tag color="lime">统一计算</Tag> : <Tag color="geekblue">分别计算</Tag>}
      </Col>
      <Col span={6}>
        时参：
        {editDataTimeRuleConstants.length
          ? editDataTimeRuleConstants.map((v, index) => (
            <Tag key={index} color="purple">
              {v}
            </Tag>
          ))
          : '无'}
      </Col>
      <Col span={6}>
        值参：
        {editDataValueRuleConstants.length
          ? editDataValueRuleConstants.map((v, index) => (
            <Tag key={index} color="purple">
              {v}
            </Tag>
          ))
          : '无'}
      </Col>
      <Col span={3}>
        <Button icon={<EditOutlined />} size="small" type="primary" onClick={onEdit} disabled={!canEdit} />
        &ensp;
        <Button
          loading={deleteLoading}
          icon={<DeleteOutlined />}
          size="small"
          danger
          onClick={onDelete}
          disabled={!canEdit}
        />
      </Col>
      {modalContextHolder}
      {messageContextHolder}
    </Row>
  );
};

function blankPointSet(ruleSetId: number): PointSetV2 {
  return {
    id: -1,
    computeOnce: true,
    dataTimeRuleConstants: [],
    dataValueRuleConstants: [],
    ruleSetId,
    createTime: '',
    updateTime: '',
    deviceIds: [],
    devices: [],
    dataPropertyIds: [],
    dataPropertys: [],
    nonModelingPointNames: [],
  };
}

interface PointSetModalProps {
  ruleSetId: number;
  tenantMcid: string;
}

const PointSetModal: FC<PointSetModalProps> = props => {
  const { ruleSetId, tenantMcid } = props;
  const [counter, setCounter] = useState(0);
  const [pointSets, setPointSets] = useState<PointSetV2[]>([]);
  const [creating, setCreating] = useState(false);
  const [editing, setEditing] = useState(false);
  const displayPointSets = useMemo(
    () => (creating ? [blankPointSet(ruleSetId)].concat(pointSets) : pointSets),
    [pointSets, creating, ruleSetId]
  );

  const doRefresh = useCallback(() => setCounter(i => i + 1), []);
  const startEdit = useCallback(() => setEditing(true), []);
  const endEdit = useCallback(() => {
    setEditing(false);
    setCreating(false);
    doRefresh();
  }, [doRefresh]);
  const doCreate = useCallback(() => {
    setCreating(true);
    setEditing(true);
  }, []);

  useEffect(() => {
    getPointSetByRuleSetIdV2(ruleSetId, tenantMcid).then(res => setPointSets(res));
  }, [ruleSetId, tenantMcid, counter]);

  return (
    <div style={{ position: 'relative' }}>
      <p>
        <strong>说明：</strong>
        可选覆盖规则集的数据时间参数和上传时间参数。填入参数将按顺序对应覆盖规则集的参数。可以少填或不填。
      </p>
      <p>
        <Button style={{ position: 'sticky', top: 20 }} type="primary" onClick={doCreate}>
          新建点集
        </Button>
      </p>
      <div style={{ height: '60vh', overflow: 'auto' }}>
        <List
          bordered
          dataSource={displayPointSets}
          renderItem={pointSet => (
            <List.Item>
              <PointSetRow
                key={pointSet.id}
                canEdit={!editing}
                pointSetId={pointSet.id}
                pointSet={pointSet}
                isCreate={pointSet.id < 0}
                startEdit={startEdit}
                endEdit={endEdit}
                tenantMcid={tenantMcid}
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

export default PointSetModal;
