import React, { useEffect, useState } from 'react';
import { FormContent, Wrapper } from '@maxtropy/components';
import { Button, Form, Input, message, Modal, Radio, Space, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import classnames from 'classnames';
import Footer from '../../../components/Footer';
import { ExclamationCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import ShowInput from '../../../components/ShowInput';
import { RoleType, RoleTypeDisplay, StateType, StateTypeDisplay } from '../../../api/openPlatform-const';
import { queryDeveloperAccount, resetAccountPwd, updateDeveloperAccount } from '../../../api/openPlatform-account';
import { isNil } from 'lodash-es';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const routes = [{ name: '开发者平台' }, { name: '应用开发开放平台' }, { name: '开发者账号管理' }];

type FormValues = {
  id: number;
  independentSoftwareVendorName: string;
  independentSoftwareVendorStatus: number;
  roleType: number;
  username: string;
  status: number;
  cellphone: string;
  description: string;
};

interface EditDeveloperAccountProps {
  isDetail?: boolean;
}

const EditDeveloperAccount: React.FC<EditDeveloperAccountProps> = ({ isDetail }) => {
  const [form] = Form.useForm<FormValues>();
  const [loading, setLoading] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [data, setData] = useState<FormValues>({} as FormValues);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [messageApi, contextHolder] = message.useMessage();
  const [modal, modalContextHolder] = Modal.useModal();

  useEffect(() => {
    if (id) {
      setPageLoading(true);
      queryDeveloperAccount(id)
        .then(res => {
          form.setFieldsValue(res);
          setData(res);
        })
        .finally(() => {
          setPageLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSave = () => {
    if (isNil(id)) return;
    form.validateFields().then(() => {
      let { roleType, cellphone, description } = form.getFieldsValue();
      setLoading(true);
      updateDeveloperAccount(id, {
        roleType,
        cellphone,
        description,
      })
        .then(() => {
          navigate('/open-platform/open-platform/developer-account');
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const resetPwd = () => {
    modal.confirm({
      title: '是否为此账号重置密码？',
      icon: <ExclamationCircleFilled />,
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        if (isNil(id)) return;
        resetAccountPwd(id).then(res => {
          messageApi.success('重置密码成功');
        });
      },
    });
  };

  return (
    <Wrapper
      routes={[...routes, { name: isDetail ? '账号详情' : '编辑账号' }]}
      className={classnames('open-platform-common-wrapper')}
    >
      {contextHolder}

      <Form {...layout} name="account-form" form={form} layout="vertical" initialValues={{ roleType: RoleType.ADMIN }}>
        <FormContent title={isDetail ? '账号详情' : '编辑账号'}>
          <Spin spinning={pageLoading}>
            <div style={{ marginLeft: 30 }}>
              <Form.Item name="independentSoftwareVendorName" label="服务商">
                <ShowInput />
              </Form.Item>
              <Form.Item name="username" label="账号">
                <ShowInput />
              </Form.Item>
              <Form.Item label="角色">
                {isDetail ? (
                  <ShowInput value={RoleTypeDisplay[data.roleType as RoleType]} />
                ) : (
                  <Form.Item name="roleType">
                    <Radio.Group>
                      <Radio value={RoleType.ADMIN}>{RoleTypeDisplay[RoleType.ADMIN]}</Radio>
                      <Radio value={RoleType.COMMON}>{RoleTypeDisplay[RoleType.COMMON]}</Radio>
                    </Radio.Group>
                  </Form.Item>
                )}
              </Form.Item>
              {isDetail ? (
                <>
                  <Form.Item label="状态">
                    <ShowInput value={StateTypeDisplay[data.status as StateType]} />
                  </Form.Item>
                  <Form.Item label="手机号" name="cellphone">
                    <ShowInput />
                  </Form.Item>
                </>
              ) : (
                <Form.Item
                  label="手机号"
                  name="cellphone"
                  validateTrigger="onBlur"
                  rules={[{ required: true, message: '请输入正确的手机号', pattern: /^1[3456789]\d{9}$/ }]}
                  extra={
                    <div style={{ marginTop: 4, color: 'rgba(0,0,0,0.35)' }}>
                      <InfoCircleOutlined style={{ color: 'var(--primary-color)' }} />{' '}
                      请填写正确的手机号，提交后登录密码将发送到该手机号
                    </div>
                  }
                >
                  <Input style={{ width: 300 }} placeholder="请输入手机号" />
                </Form.Item>
              )}
              <Form.Item name="description" label="备注">
                {isDetail ? (
                  <ShowInput style={{ width: 460, wordWrap: 'break-word' }} />
                ) : (
                  <Input.TextArea rows={4} maxLength={200} style={{ width: 460 }} placeholder="请输入200字" />
                )}
              </Form.Item>
            </div>
          </Spin>
        </FormContent>
      </Form>

      <Footer>
        <Space>
          {isDetail ? (
            <Button type="primary" onClick={resetPwd}>
              重置密码
            </Button>
          ) : (
            <Button type="primary" onClick={onSave} loading={loading}>
              提交
            </Button>
          )}
          <Button onClick={() => navigate('/open-platform/open-platform/developer-account')}>
            {isDetail ? '返回' : '取消'}
          </Button>
        </Space>
      </Footer>
      {modalContextHolder}
    </Wrapper>
  );
};

export default EditDeveloperAccount;
