import { EllipsisSpan, Filter, Paging, Table, usePaging, Wrapper } from '@maxtropy/components';
import { Col, Form, Input, Select } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import {
  DataPropertyPageRes,
  DataPropertyType,
  DataPropertyTypeDisplay,
  DeviceType,
  FilterParams,
  getDataPropertyPage,
  getDeviceTypeTreeWithoutScene,
  getManufacturerList,
  getPhysicalModelList,
  ManufacturerItem,
  MeasurementType,
  MeasurementTypeDisplay,
  PhysicalModelRes,
} from '../../api/openPlatform-dataProperty';

const routes = [{ name: '开发者平台' }, { name: '应用开发开放平台' }, { name: '数据属性管理' }];

function getNameOrId(v: string, record: DataPropertyPageRes, result: string, degree: number) {
  if (record.deviceTypes && record.deviceTypes.length !== 0) {
    const type = record.deviceTypes.filter(i => i.degree === degree);
    if (type.length !== 0 && result === 'id') {
      return <EllipsisSpan value={type[0].id} />;
    }
    if (type.length !== 0 && result === 'name') {
      return <EllipsisSpan value={type[0].name} />;
    }
  }
  return <EllipsisSpan value={v} />;
}

const DataPropertyManagement: React.FC = () => {
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [filterForm] = Form.useForm();
  const [filterParams, setFilterParams] = useState<FilterParams>();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState<DataPropertyPageRes[]>([]);
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>([]);
  const [primaryTypeId, setPrimaryTypeId] = useState<number>();
  const [typeIds, setTypeIds] = useState<number[]>([]);
  const manufacturerId = Form.useWatch('manufacturerId', filterForm);

  const columns = [
    {
      title: '数据属性ID',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '数据属性名称',
      dataIndex: 'name',
      key: 'name',
      width: 165,
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '数据属性类型',
      dataIndex: 'type',
      key: 'type',
      width: 120,
      render: (v: DataPropertyType) => <EllipsisSpan value={DataPropertyTypeDisplay[v]} />,
    },
    {
      title: '瞬时量/累计量',
      dataIndex: 'measurementType',
      key: 'measurementType',
      width: 120,
      render: (v: MeasurementType) => <EllipsisSpan value={MeasurementTypeDisplay[v]} />,
    },
    {
      title: '枚举类型',
      dataIndex: 'enumValue',
      key: 'enumValue',
      width: 200,
      render: (v: any) => {
        if (v !== null) {
          const x = Object.entries(v)
            .map(([key, value]) => `${key}：${value}`)
            .join('、');
          return <EllipsisSpan value={x} />;
        }
        return <EllipsisSpan value={v} />;
      },
    },
    {
      title: '默认单位ID',
      dataIndex: 'basicPhysicalQuantityId',
      key: 'basicPhysicalQuantityId',
      width: 100,
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '默认单位',
      dataIndex: 'unitGeneralName',
      key: 'unitGeneralName',
      width: 80,
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '设备一级类目ID',
      dataIndex: 'primaryTypeId',
      key: 'primaryTypeId',
      width: 120,
      render: (v: string, record: DataPropertyPageRes) => getNameOrId(v, record, 'id', 1),
    },
    {
      title: '设备一级类目',
      dataIndex: 'primaryTypeName',
      key: 'primaryTypeName',
      width: 110,
      render: (v: string, record: DataPropertyPageRes) => getNameOrId(v, record, 'name', 1),
    },
    {
      title: '设备二级类目ID',
      dataIndex: 'secondaryTypeId',
      key: 'secondaryTypeId',
      width: 120,
      render: (v: string, record: DataPropertyPageRes) => getNameOrId(v, record, 'id', 2),
    },
    {
      title: '设备二级类目',
      dataIndex: 'secondaryTypeName',
      key: 'secondaryTypeName',
      width: 180,
      render: (v: string, record: DataPropertyPageRes) => getNameOrId(v, record, 'name', 2),
    },
    {
      title: '厂商',
      dataIndex: 'manufacturerName',
      key: 'manufacturerName',
      width: 100,
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '物模型型号',
      dataIndex: 'physicalModelNo',
      key: 'physicalModelNo',
      width: 100,
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '更新时间',
      dataIndex: 'updateTime',
      key: 'updateTime',
      width: 160,
      render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'} />,
    },
  ];

  const onFinish = (value: FilterParams) => {
    setFilterParams({ ...value });
    setPageOffset(1);
  };

  const onReset = () => {
    setFilterParams(undefined);
    setTypeIds([]);
    setPrimaryTypeId(undefined);
    setPageOffset(1);
  };

  useEffect(() => {
    getDeviceTypeTreeWithoutScene().then(res => {
      setDeviceTypes(res.deviceTypes);
    });
  }, []);

  const primaryTypeOptions = useMemo(() => {
    if (deviceTypes.length !== 0) {
      return deviceTypes.map(i => ({ label: i.name, value: i.id }));
    }
  }, [deviceTypes]);

  const secondaryTypeOptions = useMemo(() => {
      const primaryType = deviceTypes.find(i => i.id === primaryTypeId);

      setTypeIds(primaryType?.children?.map(i => i.id) ?? []);
      return primaryType?.children?.map(i => ({ label: i.name, value: i.id })) ?? [];
  }, [deviceTypes, primaryTypeId]);

  const primaryTypeChange = (value: number) => {
    setPrimaryTypeId(value);
    filterForm.setFieldsValue({ secondaryTypeId: undefined, physicalModelId: undefined });
  };

  const secondaryTypeChange = (value: number) => {
    setTypeIds([value]);
    filterForm.setFieldsValue({ physicalModelId: undefined });
  };

  const [manufacturerList, setManufacturerList] = useState<ManufacturerItem[]>([]); // 厂商列表

  useEffect(() => {
    getManufacturerList().then(setManufacturerList);
  }, []);

  const manufacturerOptions = useMemo(() => {
    if (manufacturerList && manufacturerList.length > 0) {
      return manufacturerList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [manufacturerList]);

  const [physicalModelList, setPhysicalModelList] = useState<PhysicalModelRes[]>([]); // all 物模型型号列表

  useEffect(() => {
    const params = {
      deviceTypeIds: typeIds ? typeIds : primaryTypeId ? [primaryTypeId] : undefined,
      manufacturerId,
    };
    // 物模型型号
    getPhysicalModelList(params).then(setPhysicalModelList);
  }, [typeIds, primaryTypeId, manufacturerId]);

  const objectModalTypeOptions = useMemo(() => {
    if (physicalModelList && physicalModelList.length > 0) {
      return physicalModelList.map(i => ({ label: i.modelNo, value: i.id }));
    }
  }, [physicalModelList]);

  useEffect(() => {
    setLoading(true);
    const params = {
      ...filterParams,
      deviceTypeIds: typeIds,
    };
    getDataPropertyPage({
      ...params,
      offset: pageOffset,
      count: pageSize,
    })
      .then(res => {
        setTableData(res.records);
        setTotalCount(res.total);
        setPageOffset(res.current);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, pageSize, filterParams, setTotalCount]);

  const filters = (
    <Filter<FilterParams> form={filterForm} onFinish={val => onFinish(val)} onReset={onReset}>
      <>
        <Col span={6}>
          <Form.Item
            name="dataPropertyName"
            label="数据属性名称"
            rules={[{ pattern: /^[\u4e00-\u9fa5a-zA-Z0-9#]+$/g, message: '请输入中文或字母或数字或#' }]}
          >
            <Input maxLength={20} placeholder={'请输入数据属性名称'} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="primaryTypeId" label="一级类目">
            <Select placeholder="请选择" options={primaryTypeOptions} onChange={primaryTypeChange} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="secondaryTypeId" label="二级类目">
            <Select placeholder="请选择" options={secondaryTypeOptions} onChange={secondaryTypeChange} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="manufacturerId" label="厂商">
            <Select
              optionFilterProp="label"
              placeholder="请选择"
              showSearch
              options={manufacturerOptions}
              onChange={() => {
                filterForm.setFieldsValue({
                  physicalModelId: undefined,
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="physicalModelId" label="物模型型号">
            <Select placeholder="请选择" showSearch options={objectModalTypeOptions} />
          </Form.Item>
        </Col>
      </>
    </Filter>
  );

  return (
    <Wrapper routes={routes} filters={filters}>
      <Table
        loading={loading}
        columns={columns}
        dataSource={tableData}
        sticky={{
          getContainer: () => {
            return document.querySelector('.central-menu-content') as HTMLElement;
          },
        }}
        rowKey="id"
        scroll={{ x: 1300 }}
      />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default DataPropertyManagement;
