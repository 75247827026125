import { EllipsisSpan, Modal, Table } from '@maxtropy/components';
import { TenantMenuNavigationResponse } from '../../../../../api/cc-navigation';
import { apiV2NavigationUpdateWeightingPost, apiV2NavigationTreePost } from '@maxtropy/tody-mgmt-apis-v2';
import { Form, InputNumber } from 'antd';
import { FC, useEffect } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import styles from './index.module.scss';
import { formatTreeData } from '../../index';
interface EditModalProps {
  setOpened: (val: boolean) => void;
  opened?: boolean;
  isChild?: boolean;
  setIsChild: (val: boolean) => void;
  editId?: string;
  refreshData: (val: string) => void;
  current?: TenantMenuNavigationResponse;
}
const SortNavigation: FC<EditModalProps> = props => {
  const { setOpened, opened, isChild, setIsChild, refreshData, editId, current } = props;
  const [form] = Form.useForm();

  const onClose = () => {
    form.resetFields();
    setIsChild(false);
    setOpened(false);
  };

  useEffect(() => {
    if (opened && !isChild) {
      apiV2NavigationTreePost({ id: editId }).then(res => {
        form.setFieldsValue({
          requestList: formatTreeData(
            (res.list ?? []).sort(function (a, b) {
              return (b.weighting as number) - (a.weighting as number);
            })
          ),
        });
      });
    } else if (opened && isChild) {
      form.setFieldsValue({
        requestList: formatTreeData(
          (current?.trees ?? []).sort(function (a: any, b: any) {
            return (b.weighting as number) - (a.weighting as number);
          })
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  const onOk = () => {
    form.validateFields().then(res => {
      const mapList = res.requestList.map((item: any) => ({
        id: item.id,
        weighting: Number(item.weighting),
      }));

      apiV2NavigationUpdateWeightingPost({ requestList: mapList }).then(() => {
        onClose();
        refreshData(editId as string);
      });
    });
  };
  const Columns = [
    {
      title: '导航',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (text: string) => <EllipsisSpan value={text} />,
    },
    {
      title: '排序权重',
      dataIndex: 'weighting',
      ellipsis: { showTitle: true },
      render: (text: string, record: any, index: number) => {
        return (
          <Form.Item noStyle name={['requestList', index, 'weighting']}>
            <InputNumber></InputNumber>
          </Form.Item>
        );
      },
    },
  ];

  return (
    <div className={styles.content}>
      <Modal
        title={`导航排序`}
        size={'normal'}
        open={opened}
        onCancel={onClose}
        destroyOnClose
        onOk={onOk}
        bodyStyle={{
          display: 'flex',
          justifyContent: 'center',
          maxHeight: 758,
          overflow: 'auto',
        }}
      >
        <Form form={form} labelCol={{ flex: '110px' }} labelAlign="right">
          {isChild && (
            <div className={styles.top}>
              <div>父导航：{current?.name} </div>
              <div>
                <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
                &nbsp;权重大排在上方。
              </div>
            </div>
          )}
          {!isChild && (
            <div className={styles.top}>
              <div className={styles.icon}>
                <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
                &nbsp;权重大排在上方!
              </div>
            </div>
          )}
          <Form.Item name="requestList" valuePropName="dataSource">
            <Table rowKey="id" columns={Columns} pagination={false} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default SortNavigation;
