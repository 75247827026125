import React, { useEffect } from 'react';
import { Modal, Form, Input } from 'antd';
import { UpdateWorkspaceParams, updateWorkspace, Workspace } from '../../../api/cc-tenant-ql';
import { isEmpty } from 'lodash-es';

type CreateWorkspaceModalProps = {
  open: boolean;
  closeModal: () => void;
  forceUpdate: () => void;
  workspaceInfo?: Workspace;
};

export default function UpdateWorkspaceModal(props: CreateWorkspaceModalProps) {
  const [modalApi, modalContextHolder] = Modal.useModal();
  const { open, closeModal, forceUpdate, workspaceInfo } = props;
  const [form] = Form.useForm<UpdateWorkspaceParams>();

  useEffect(() => {
    if (!isEmpty(workspaceInfo)) {
      form.setFieldsValue({
        spaceIdCode: workspaceInfo?.spaceIdCode,
        spaceName: workspaceInfo?.spaceName,
        adminAccessToken: workspaceInfo?.adminAccessToken,
        adminAccount: workspaceInfo?.adminAccount,
        adminPassword: workspaceInfo?.adminPassword,
        userId: workspaceInfo?.userId,
      });
    }
  }, [form, workspaceInfo]);

  const onFinish = (values: UpdateWorkspaceParams) => {
    updateWorkspace(workspaceInfo!.id, values)
      .then(res => {
        modalApi.success({
          content: '更新工作区成功',
          onOk: () => {
            // 强制渲染列表页面, 拉取新的工作区
            forceUpdate();
          },
        });
      })
      .finally(() => {
        closeModal();
      });
  };

  return (
    <>
      <Modal
        title="更新工作区"
        // destroyOnClose
        open={open}
        onOk={() => {
          form.submit();
        }}
        onCancel={() => {
          closeModal();
        }}
      >
        <Form
          labelAlign="left"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          form={form}
          // preserve={false}
          onFinish={onFinish}
        >
          <Form.Item name="spaceIdCode" label="工作区ID">
            <Input disabled placeholder="请输入工作区ID" />
          </Form.Item>
          <Form.Item rules={[{ required: true }]} name="spaceName" label="工作区名称">
            <Input placeholder="请输入工作区名称" />
          </Form.Item>
          <Form.Item rules={[{ required: true }]} name="adminAccessToken" label="adminAccessToken">
            <Input.Password placeholder="请输入adminAccessToken" />
          </Form.Item>
          <Form.Item rules={[{ required: true }]} name="adminAccount" label="管理员账号">
            <Input placeholder="请输入管理员账号" />
          </Form.Item>
          <Form.Item rules={[{ required: true }]} name="adminPassword" label="管理员密码">
            <Input.Password placeholder="请输入管理员密码" />
          </Form.Item>
          <Form.Item rules={[{ required: true }]} name="userId" label="成员ID">
            <Input.Password placeholder="请输入成员ID" />
          </Form.Item>
        </Form>
      </Modal>
      {modalContextHolder}
    </>
  );
}
