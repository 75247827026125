import React, { Key } from 'react';
import { AttributeInfoProps, EdgeDeviceTemplatePoint } from './index';

export interface PointContextProps<T = EdgeDeviceTemplatePoint> {
  dataSource?: T[];
  row?: Partial<T>;
  setRow?: React.Dispatch<React.SetStateAction<Partial<T> | undefined>>;
  info?: AttributeInfoProps;
  promptSlot?: React.ReactElement;
  editable?: boolean;
  canAdjustField?: boolean;
  isBatch?: boolean;
  edgeGatewayId?: Key;
  setDataSource?: (data: EdgeDeviceTemplatePoint[]) => void;
}

// @ts-ignore
export const PointContext = React.createContext<PointContextProps>(null);
