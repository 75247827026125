import React, { FC, useEffect, useState } from 'react';
import { AttachmentUpload, BaseInfoContent, Form } from '@maxtropy/components';
import ShowInput from '@/shared/components/ShowInput';
import { DevicePropertyResponse, getDeviceProperty } from '../../../../api/device';
import dayjs from 'dayjs';
import TagDisplayForm from '@/shared/components/MultiModalSelect/TagDisplayForm';
import DeviceTagsDisplayForm from '@/shared/components/DeviceTags/TagsDisplayForm';

interface PropertyDetailProps {
  id: string;
}

const PropertyDetail: FC<PropertyDetailProps> = ({ id }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState<DevicePropertyResponse>();
  useEffect(() => {
    if (id) {
      getDeviceProperty(id).then(res => {
        setData(res);
        form.setFieldsValue({
          name: res.name,
          sn: res.sn,
          typeId: res.typeName,
          modelId: res.modelName,
          manufactureDate: res.manufactureDate ? dayjs(res.manufactureDate).format('YYYY-MM-DD') : undefined,
          installationDate: res.installationDate ? dayjs(res.installationDate).format('YYYY-MM-DD') : undefined,
          pic: res.pic,
          rootMcid: res.tenantName,
          customerMcid: res.customerName,
          assetCode: res.assetCode,
          ouIds: res.ouIds,
          tags: res.tags,
          code: res.code,
          thirdParty: res.thirdParty,
          objectModalType: res.physicalModelId
            ? `${res.physicalModelNo ?? res.modelNo}-${res.manufacturerName}`
            : res.physicalModelId,
        });
      });
    }
  }, [id, form]);

  return (
    <Form form={form} style={{ marginRight: 50 }}>
      <BaseInfoContent layout="horizontal">
        <Form.Item name="sn" label="设备S/N码">
          <ShowInput />
        </Form.Item>
        <Form.Item name="typeId" label="所属类目">
          <ShowInput />
        </Form.Item>
        <Form.Item name="objectModalType" label="物模型型号">
          <ShowInput />
        </Form.Item>
        <Form.Item name="modelId" label="所属型号">
          <ShowInput />
        </Form.Item>
        <Form.Item name="manufactureDate" label="出厂日期">
          <ShowInput />
        </Form.Item>
        <Form.Item name="installationDate" label="安装日期">
          <ShowInput />
        </Form.Item>
        <Form.Item name="rootMcid" label="所属租户">
          <ShowInput />
        </Form.Item>
        <Form.Item name="customerMcid" label="所属组织">
          <ShowInput />
        </Form.Item>
        <Form.Item name="assetCode" label="资产编码">
          <ShowInput />
        </Form.Item>
        <Form.Item name="ouIds" label="生产运营单元">
          <TagDisplayForm />
        </Form.Item>
        <Form.Item name="tags" label="设备标签">
          <DeviceTagsDisplayForm />
        </Form.Item>
        <Form.Item label="第三方对接">
          <ShowInput value={data?.thirdParty ? '是' : '否'} />
        </Form.Item>
        <Form.Item name="pic" label="设备图片">
          <AttachmentUpload disabled fileSize={5} />
        </Form.Item>
      </BaseInfoContent>
    </Form>
  );
};

export default PropertyDetail;
