import { EllipsisSpan } from '@maxtropy/components';
import { Button, Modal, Space, Table } from 'antd';
import { useState } from 'react';
import { getQlQuota, QuotaProps, updateUserStatus } from '../../api/cc-tenant-ql';
const columns = [
  {
    title: '姓名',
    dataIndex: 'name',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '员工工号',
    dataIndex: 'code',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'disable',
    render: (v: boolean) => <EllipsisSpan value={v ? '禁用' : '启用'} />,
  },
];
interface Iprops {
  mcid?: string;
  listData?: QuotaProps[];
  onCancel?: (list?: QuotaProps[]) => void;
  onOk?: (list?: QuotaProps[]) => void;
}
const AssignDetailModal: React.FC<Iprops> = props => {
  const { mcid, listData } = props;
  const [quotaList, setQuotaList] = useState(listData || []);
  // 启用禁用
  const changeStatus = (useId: string, status: boolean) => {
    updateUserStatus(useId, mcid!, status).then(res => {
      res && reSearchList();
    });
  };
  const reSearchList = () => {
    getQlQuota(mcid!).then(res => {
      setQuotaList(res);
    });
  };
  const opColumn = {
    title: '操作',
    fixed: 'right' as const,
    width: 120,
    render: (_: any, record: QuotaProps) => {
      return (
        <Space>
          <Button disabled={!record.disable} type="link" onClick={() => changeStatus(record.code!, false)}>
            启用
          </Button>
          <Button disabled={record.disable} type="link" onClick={() => changeStatus(record.code!, true)}>
            禁用
          </Button>
        </Space>
      );
    },
  };
  return (
    <>
      <Modal
        open
        width={800}
        title="配额详情"
        onOk={() => props.onOk && props.onOk(quotaList)}
        onCancel={() => props.onCancel && props.onCancel(quotaList)}
      >
        <Table rowKey="code" pagination={false} dataSource={quotaList} columns={[...columns, opColumn]} />
      </Modal>
    </>
  );
};

export default AssignDetailModal;
