import React, { CSSProperties, forwardRef, HTMLAttributes } from 'react';
import { CloseOutlined } from '@ant-design/icons';

import { getRealUrl } from '@maxtropy/components';
import styles from '../index.module.scss';
import { apiV2MiniAppToolEditPost } from '@maxtropy/cc-mgmt-apis-v2';

export type ItemProps = HTMLAttributes<HTMLDivElement> & {
  id: string;
  withOpacity?: boolean;
  isDragging?: boolean;
  name: string;
  currentCenterId?: number;
  update: () => void;
  icon: string;
};

const Item = forwardRef<HTMLDivElement, ItemProps>(
  ({ id, name, currentCenterId, icon, update, withOpacity, isDragging, style, ...props }, ref) => {
    const inlineStyles: CSSProperties = {
      position: 'relative',
      opacity: withOpacity ? '0.5' : '1',
      transformOrigin: '50% 50%',
      height: '60px',
      width: '160px',
      borderRadius: '8px',
      backdropFilter: 'blur(0px)',
      cursor: isDragging ? 'grabbing' : 'grab',
      backgroundColor: '#ffffff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      boxShadow: isDragging
        ? 'rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px'
        : 'rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px',
      transform: isDragging ? 'scale(1.05)' : 'scale(1)',
      overflow: 'hidden',
      ...style,
    };

    const onToolItemDelete = () => {
      apiV2MiniAppToolEditPost({ menuId: currentCenterId, deleteToolIds: [Number(id)] }).then(_res => {
        update();
      });
    };

    return (
      <div ref={ref} style={inlineStyles} {...props} className={styles.itemBoxHover}>
        <div className={styles.itemInfo}>{name}</div>
        <div className={styles.itemIcon} style={{ backgroundImage: `url(${getRealUrl(icon)})` }}></div>
        <div className={styles.closeStyle} onClick={onToolItemDelete}>
          <CloseOutlined style={{ color: '#fff' }} className={styles.closeIcon} />
        </div>
      </div>
    );
  }
);

export default Item;
