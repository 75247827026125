import React, { useMemo } from 'react';
import './style/index.scss';
import { Row, Col } from 'antd';
import classnames from 'classnames';
import { MappingProps } from '../LogList';
import objectPath from 'object-path';

interface LogFieldListProps {
  name: string;
  oldValue: string;
  newValue: string;
  modified: boolean;
}

interface LogDetailProps {
  mapping: MappingProps[];
  lastRecord: { [key: string]: any };
  currentRecord: { [key: string]: any };
}

const LogDetail: React.FC<LogDetailProps> = props => {
  const prefixCls = 'open-platform-log-detail';
  const { mapping, lastRecord, currentRecord } = props;

  const logFieldList = useMemo<LogFieldListProps[]>(() => {
    return mapping.map(item => {
      const { label, dataIndex, type, enumDisplay } = item;
      let oldValue =
        type === 'enum' ? enumDisplay?.[objectPath.get(lastRecord, dataIndex)] : objectPath.get(lastRecord, dataIndex);
      let newValue =
        type === 'enum'
          ? enumDisplay?.[objectPath.get(currentRecord, dataIndex)]
          : objectPath.get(currentRecord, dataIndex);
      return {
        name: label,
        oldValue,
        newValue,
        modified: oldValue !== newValue,
      };
    });
  }, [mapping, lastRecord, currentRecord]);

  return (
    <div className={`${prefixCls}-wrapper`}>
      <Row gutter={20}>
        <Col span={16}>
          <Row>
            <Col className={classnames(`${prefixCls}-header`, `${prefixCls}-header-origin`)} span={12}>
              字段
            </Col>
            <Col className={classnames(`${prefixCls}-header`, `${prefixCls}-header-origin`)} span={12}>
              原始数据
            </Col>
          </Row>
        </Col>
        <Col span={8} className={classnames(`${prefixCls}-header`, `${prefixCls}-header-modified`)}>
          编辑后数据
        </Col>
      </Row>
      {logFieldList.map((item, index) => {
        return (
          <Row gutter={20} key={index}>
            <Col span={16}>
              <Row>
                <Col className={classnames(`${prefixCls}-body`)} span={12}>
                  {item.name}
                </Col>
                <Col className={classnames(`${prefixCls}-body`)} span={12}>
                  {item.oldValue ?? '--'}
                </Col>
              </Row>
            </Col>
            <Col
              className={classnames(`${prefixCls}-body`, { [`${prefixCls}-body-modified`]: item.modified })}
              span={8}
            >
              {item.newValue ?? '--'}
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default LogDetail;
