import { Dayjs } from "dayjs";

export interface QueryData {
  codeOrName: string;
  time: [Dayjs, Dayjs];
  tenantName: string;
  userName: string;
}

export interface List {
  id: number;
  tenantName: string;
  deviceCode: string;
  deviceName: string;
  deviceTypeName: string;
  unitName: string;
  dataDelStartTime: string;
  dataDelEndTime: string;
  userName: string;
  operatingTime: string;
}


export enum Status {
  DELETED = 2,
  RECOVERED = 4
}

export const StatusDisplay = {
  [Status.DELETED]: '已删除',
  [Status.RECOVERED]: '已恢复',
}

export const StatusColorMap = {
  [Status.DELETED]: 'error',
  [Status.RECOVERED]: 'info',
};

export enum Type {
  MANUALLY_DELETED = 1,
  EXCEPTION_FILTERING_DELETED = 2
}
