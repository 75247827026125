import { fetch } from '@maxtropy/components';

export interface FunctionPermissionVo {
  id: number;
  code: string;
  parentId: number;
  name: string;
  level: number;
  children?: FunctionPermissionVo[];
}

export function getPermissionTree(): Promise<FunctionPermissionVo[]> {
  return fetch('/api/function/permission/tree');
}
