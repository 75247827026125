import { useState } from 'react';
import {
  apiCarbonAccountingGuideDeletePost,
  apiCarbonAccountingGuidePagePost,
  apiCarbonAccountingGuideStatusPost,
  apiCarbonEmissionProcessPagePost,
  apiCarbonIndustryAllPost,
  CarbonAccountingGuidePagePostResponse,
} from '@maxtropy/device-mgmt-apis';
import { List, Options, Query, Status } from './types';
import { ProcessModalResType } from '../Create/create';

export const useCarbonEmissions = (update?: () => void) => {
  const [loading, setLoading] = useState(false);
  const [industryOptions, setIndustryOptions] = useState<Options[]>([]);
  const [guideTableData, setGuideTableData] = useState<CarbonAccountingGuidePagePostResponse['list']>([]);
  const [processTableData, setProcessTableData] = useState<ProcessModalResType[]>([]);

  const fetchIndustryOptions = async () => {
    try {
      const res = await apiCarbonIndustryAllPost();
      const tempOptions = res.map(item => ({ label: item.name, value: item.id }));
      setIndustryOptions(tempOptions);
    } catch (error) {
      console.error('Failed to fetch industry options:', error);
    }
  };

  const fetchData = async (
    apiFunction: any,
    setFunction: React.Dispatch<React.SetStateAction<any>>,
    searchParams: Query | undefined,
    pageOffset: number,
    pageSize: number,
    setTotalCount: (count: number) => void,
    additionalParams: Record<string, any> = {}
  ) => {
    setLoading(true);
    try {
      const params = { ...searchParams, page: String(pageOffset), size: String(pageSize), ...additionalParams };
      const res = await apiFunction(params);
      setFunction(res.list);
      setTotalCount(res.total);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    } finally {
      setLoading(false);
    }
  };

  const getGuideTableData = async (
    searchParams: Query | undefined,
    pageOffset: number,
    pageSize: number,
    setTotalCount: (count: number) => void
  ) => {
    fetchData(apiCarbonAccountingGuidePagePost, setGuideTableData, searchParams, pageOffset, pageSize, setTotalCount);
  };

  const getProcessTableData = async (
    searchParams: Query | undefined,
    pageOffset: number,
    pageSize: number,
    setTotalCount: (count: number) => void
  ) => {
    fetchData(
      apiCarbonEmissionProcessPagePost,
      setProcessTableData,
      searchParams,
      pageOffset,
      pageSize,
      setTotalCount,
      { state: String(Status.ENABLE) }
    );
  };

  const handleChangeStatus = async (record: List) => {
    try {
      const { id, state } = record;
      const newStatus = state === Status.DRAFT || state === Status.DISABLE ? Status.ENABLE : Status.DISABLE;
      await apiCarbonAccountingGuideStatusPost({ id: String(id), status: String(newStatus) });
    } finally {
      update?.();
    }
  };

  const handleDelete = async (record: List) => {
    try {
      await apiCarbonAccountingGuideDeletePost({ id: String(record.id) });
    } finally {
      update?.();
    }
  };

  return {
    loading,
    industryOptions,
    guideTableData,
    processTableData,
    getGuideTableData,
    getProcessTableData,
    handleChangeStatus,
    handleDelete,
    fetchIndustryOptions,
  };
};
