import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MiniAppAddOrUpdatePartOnePostRequest, MiniAppPartOneTwoDetailPostResponse } from '@maxtropy/cc-mgmt-apis';
import { extname } from './tools';
import { Button, Col, Form, FormInstance, Input, message, Row, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { RuleObject } from 'antd/es/form';
import { RcFile, UploadChangeParam, UploadFile, UploadProps } from 'antd/es/upload';
import { UpgradeStatusEnum } from '../../List/types';
import styles from './index.module.scss';
import { isEmpty } from 'lodash-es';

const { TextArea } = Input;

interface Props {
  form: FormInstance<MiniAppAddOrUpdatePartOnePostRequest>;
  update?: boolean;
  partOneTwoData?: MiniAppPartOneTwoDetailPostResponse;
}
const BasicInfo: React.FC<Props> = ({ form, partOneTwoData }) => {
  const [searchParams] = useSearchParams();
  const status = Number(searchParams.get('status'));
  const [messageApi, contextHolder] = message.useMessage();
  const [prevVersion] = useState(partOneTwoData?.version ?? '1.0.0');
  const [fileName, setFileName] = useState<string>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const validateVersion = (_: RuleObject, value: string) => {
    // 验证版本号的格式
    const versionPattern = /^\d+\.\d+\.\d+$/;
    if (!versionPattern.test(value)) {
      return Promise.reject(new Error('版本号格式不正确'));
    }
    // 拆分版本号为数字数组
    const newVersionArray = value.split('.').map(Number);
    const prevVersionArray = prevVersion.split('.').map(Number);

    // 检查是否递增
    let isValid = true;
    for (let i = 0; i < 3; i++) {
      if (newVersionArray[i] < prevVersionArray[i]) {
        isValid = false;
        break;
      }
    }

    if (!isValid) {
      return Promise.reject(new Error('版本号必须递增'));
    }

    return Promise.resolve();
  };

  // 上传校验
  const beforeUploadFile = (file: RcFile) => {
    const { name } = file;
    setFileName(name);

    const extName = extname(name);
    // 小于1m
    const isLt1M = file.size / 1024 / 1024 < 1;
    const limitFileType = extName === '.key';
    if (!limitFileType) {
      messageApi.warning('文件格式不正确');
      return false;
    }
    if (!isLt1M) {
      messageApi.warning('文件大小不能超过1M');
      return false;
    }

    return true;
  };

  const onChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    setFileList(info.fileList);
    if (info.file.status === 'done') {
      const response = info.file.response;
      if (response && response.key) {
        form.setFieldsValue({ uploadSecret: response.key });
      }
    }
  };

  useEffect(() => {
    if (!partOneTwoData) return;
    const path = partOneTwoData?.uploadSecret;
    const parts = path?.split('/');
    const lastPart = parts?.[parts.length - 1];
    setFileList([{ name: lastPart as string, uid: '1', url: path }]);
  }, [partOneTwoData]);

  return (
    <>
      <Form className={styles.form} form={form}>
        <Row>
          <Col span={24}>
            <Form.Item name="name" label="名称" labelCol={{ span: 24 }} rules={[{ required: true, min: 2, max: 10 }]}>
              <Input placeholder="请输入" allowClear />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="appId" label="小程序Appid" labelCol={{ span: 24 }} rules={[{ required: true }]}>
              <Input placeholder="请输入" disabled={status === UpgradeStatusEnum.UPGRADE} allowClear />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="appSecret"
              label="AppSecret(小程序密钥)"
              labelCol={{ span: 24 }}
              rules={[{ required: true, min: 2, max: 50 }]}
            >
              <Input placeholder="请输入" allowClear />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="uploadSecret"
              label="小程序代码上传密钥"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  validator: _ => {
                    if (isEmpty(fileList)) {
                      return Promise.reject('请上传小程序密钥');
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Upload
                method="POST"
                action={`/api/file-center/upload?filename=${fileName}`}
                accept=".key"
                maxCount={1}
                beforeUpload={beforeUploadFile}
                onChange={onChange}
                data={{ fileName }}
                fileList={fileList}
              >
                <Button icon={<UploadOutlined />}>点击上传</Button>
              </Upload>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="version"
              label="小程序版本"
              labelCol={{ span: 24 }}
              initialValue={prevVersion}
              rules={[{ required: true, validator: validateVersion }]}
            >
              <Input placeholder="请输入" allowClear />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="body"
              label="小程序主体"
              labelCol={{ span: 24 }}
              rules={[{ required: true, min: 1, max: 20 }]}
            >
              <Input placeholder="请输入" allowClear />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="description" label="说明" labelCol={{ span: 24 }} rules={[{ min: 1, max: 200 }]}>
              <TextArea placeholder="请输入" className={styles.textArea} allowClear />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {contextHolder}
    </>
  );
};

export default BasicInfo;
