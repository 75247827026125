import React, { useCallback } from 'react';
import { Modal, List, Typography } from 'antd';
import dayjs from 'dayjs';
import { useQuery } from '@/shared/utils/utils';
import { getApplyLog } from '../../../api/gateway';

import styles from './index.module.scss';

const { Text } = Typography;

export interface ApplyLogModalProps {
  id?: number;
  visible: boolean;
  onCancel: () => void;
}

const ApplyLogModal: React.FC<ApplyLogModalProps> = ({ id, visible, onCancel }) => {
  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        id && visible
          ? getApplyLog(id).then(res => {
              return res;
            })
          : Promise.resolve([]),
      [id, visible]
    )
  );

  return (
    <Modal destroyOnClose width={700} title="下发记录" open={visible} footer={null} onCancel={onCancel}>
      <div className={styles.applyLog}>
        <List
          loading={isLoading}
          dataSource={data}
          renderItem={item => (
            <List.Item>
              <span>【{item.updateByUsername}】</span>
              在&nbsp;&nbsp;<span>{dayjs(item.time).format('YYYY-MM-DD HH:mm:ss')}</span>&nbsp;&nbsp;下发了配置，
              下发状态：<Text type={item.state ? 'success' : 'danger'}>{item.state ? '成功' : '失败'}</Text>
            </List.Item>
          )}
        />
      </div>
    </Modal>
  );
};

export default ApplyLogModal;
