import { fetch } from '@maxtropy/components';

export interface GetMenusListResType {
  id: number;
  name: string;
  mirrorCategoryId: number;
  mirrorCategoryName: string;
  mirrorCategorySorting: number;
  gitRepoUrl: string;
  icon: string;
  sorting: number;
}

export enum MenuType {
  // 1 自定义 2 卡片首页 3 工具中心
  CUSTOM = 1,
  CARD = 2,
  TOOL = 3,
}

export enum MenuVisibilityType {
  // 0 隐藏 1 显示 （隐藏不参与打包）
  HIDDEN = 0,
  SHOW = 1,
}

export interface SortCateType {
  mirrorCategoryName?: string;
  id?: string;
  mirrorCategoryId?: string;
  children?: Child[];
  mirrorCategorySorting?: number | null;
}

export interface Child {
  id?: number;
  name?: string;
  originalId?: number;
  mirrorCategoryId?: number;
  mirrorCategoryName?: string;
  mirrorCategorySorting?: number | null;
  gitRepoUrl?: string;
  icon?: string;
  sorting?: number;
}

interface ToolPageRequsetType {
  appId?: string;
  categoryId?: string | number;
  categoryName?: string;
  page?: string | number;
  size?: string | number;
}

export interface ToolPageResType {
  page: number;
  size: number;
  total: number;
  list: List[];
}

export interface List {
  id: number;
  name: string;
  categoryId: number;
  categoryName: string;
  gitRepoUrl: string;
  icon: string;
  isDisable: boolean;
  type: number;
  vendorId: number;
  integratedAppId: number;
  description: string;
  cardSupport: number;
  createTime: string;
  updateTime: string;
}

export interface MenuInfoType {
  id?: number;
  name?: string;
  icon?: string;
  /**
   * 卡片首页固定排序4、工具中心固定排序5
   */
  sorting?: number;
  /**
   * 1 自定义 2 卡片首页 3 工具中心
   */
  type?: number;
  /**
   * 0 隐藏 1 显示 （隐藏不参与打包）
   */
  visibility?: number;
}

function toQueryString(params: any) {
  return Object.keys(params)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&');
}
export function apiMiniAppToolTagPagePost(params: ToolPageRequsetType) {
  const queryString = toQueryString(params);
  return fetch<ToolPageResType>(`/api/mini/app/tool/tagPage?${queryString}`, {
    method: 'POST',
  });
}
