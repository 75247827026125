import { useCallback, useMemo, useState, FC, Key } from 'react';
import { Button, Col, Empty, Form, Input, Row, Select, Space, Spin, Tabs, TabsProps, Tree } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';

import { usePaging, Wrapper, Paging, useUpdate } from '@maxtropy/components';
import styles from './index.module.scss';
import ParentNodesTitle from './components/ParentNodesTitle';
import ChildNodeTitle from './components/ChildNodeTitle';
import PropsGroupModal from './components/PropsGroupModal';
import ListCard from './components/ListCard';
import { useRequest } from 'ahooks';
import { apiDataPropertyGroupTreeGet } from '../../ytt/types/dataPropertyGroup/tree';
import { ChildNodeData, ParentNodeData, formateTreeNode, numberToString } from './utils';
import { apiDataPropertyGroupIdDelete } from '../../ytt/types/dataPropertyGroup/id';
import { apiDataRulePageGet } from '../../ytt/types/dataRule/page';
import { templateStatusDisplay, TemplateType, templateTypeDisplay } from '../../api/type';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);
const { useForm } = Form;

const routes = [{ name: '数据运营工具' }, { name: '数据模拟' }, { name: '数据值模板管理' }];

type FilterParams = {
  name?: string;
  type?: string;
};

const TemplateManage: FC = () => {
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useState<FilterParams>({});
  const [form] = useForm();
  const [propsModalShow, setPropsModalShow] = useState(false);
  const [currentParentNodes, setParentSelectNodes] = useState<ParentNodeData>();
  const [currentChildNodes, setChildSelectNodes] = useState<ChildNodeData>();
  const [currentParentNodeId, setCurrentParentNodeId] = useState('');
  const [currentChildNodeId, setCurrentChildNodeId] = useState('');
  const [currentTab, setCurrentTab] = useState('');
  const [opType, setOpType] = useState('add');
  const [treeUpdate, setTreeUpdate] = useUpdate();
  const [pageUpdate, setPageUpdate] = useUpdate();

  // 查询树列表
  const { data: treeList } = useRequest(() => apiDataPropertyGroupTreeGet(), { refreshDeps: [treeUpdate] });
  // 查询模板列表
  const { data: pageList, loading } = useRequest(
    () => {
      return apiDataRulePageGet({
        sceneId: currentParentNodeId,
        dataPropertyGroupId: currentChildNodeId,
        status: currentTab,
        page: numberToString(pageOffset),
        size: numberToString(pageSize),
        ...searchParams,
      }).then(res => {
        setTotalCount(res.total!);
        return res.list;
      });
    },
    {
      refreshDeps: [
        pageOffset,
        pageSize,
        searchParams,
        currentParentNodeId,
        currentChildNodeId,
        currentTab,
        pageUpdate,
      ],
    }
  );
  const setParentTreeNode = useCallback((parentNodeData?: ParentNodeData) => {
    return (
      <ParentNodesTitle
        label={parentNodeData?.sceneName}
        onClick={() => {
          setOpType('add');
          setParentSelectNodes(parentNodeData);
          setPropsModalShow(true);
        }}
      />
    );
  }, []);
  const setChildTreeNode = useCallback((parentNodeData?: ParentNodeData, childNodeData?: ChildNodeData) => {
    return (
      <ChildNodeTitle
        label={childNodeData?.name}
        edit={() => {
          setOpType('edit');
          setParentSelectNodes(parentNodeData);
          setChildSelectNodes(childNodeData);
          setPropsModalShow(true);
        }}
        remove={() => {
          let id = childNodeData?.id;
          if (id) {
            apiDataPropertyGroupIdDelete({ id: id.toString() }).then(_ => setTreeUpdate());
          }
        }}
      />
    );
  }, []);
  // 格式化tree
  const formateTreeData = useMemo(() => {
    return [
      {
        key: 'all',
        title: '全部',
        children: formateTreeNode(treeList, setParentTreeNode, setChildTreeNode),
      },
    ];
  }, [treeList]);
  // 格式化tabs
  const items: TabsProps['items'] = useMemo(
    () =>
      Object.entries(templateStatusDisplay)
        .sort((a, b) => +a[0] - +b[0])
        .map(([k, v]) => ({ key: k, label: v })),
    []
  );
  const onSearch = (v: FilterParams) => {
    setSearchParams(v);
  };
  const onReset = () => {
    form.resetFields();
    setSearchParams({});
    setPageOffset(1);
  };
  const onTreeSelect = (selectedKeys: Key[], info: any) => {
    let nodes = info.selectedNodes[0];
    if (!nodes) return;
    if (nodes.key === 'all') {
      setCurrentChildNodeId('');
      setCurrentParentNodeId('');
      return;
    }
    if (nodes.children) {
      setCurrentParentNodeId(nodes.key);
      setCurrentChildNodeId('');
      return;
    }
    setCurrentChildNodeId(nodes.key);
  };
  return (
    <Wrapper routes={routes} className={styles.outer}>
      <div className={styles.container}>
        <div className={styles.tree_box}>
          <Tree
            showLine
            switcherIcon={<DownOutlined />}
            defaultExpandAll
            blockNode
            onSelect={onTreeSelect}
            treeData={formateTreeData}
          />
        </div>
        <div className={styles.card_box}>
          <div className={styles.filter}>
            <Form form={form} onFinish={onSearch}>
              <Row gutter={10}>
                <Col span={8}>
                  <Form.Item name="name">
                    <Input placeholder="请输入搜索关键字"></Input>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="类型" name="type">
                    <Select
                      options={Object.entries(templateTypeDisplay)
                        .reverse()
                        .map(([k, v]) => ({ label: v, value: k }))}
                    ></Select>
                  </Form.Item>
                </Col>
                <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Form.Item>
                    <Space size={12}>
                      <Button type="primary" htmlType="submit">
                        查询
                      </Button>
                      <Button onClick={onReset}>重置</Button>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
          <div className={styles.tabs}>
            <Tabs
              defaultActiveKey=""
              tabBarExtraContent={{
                right: (
                  <Space size={12}>
                    <Button
                      type="primary"
                      onClick={() => navigate(`/data-tools/shaping/templatemanage/values/${TemplateType.VALUE}`)}
                    >
                      创建数值型模板
                    </Button>
                  </Space>
                ),
              }}
              items={items}
              onChange={key => setCurrentTab(key)}
            />
          </div>
          <div className={cx({ list: !pageList || pageList.length === 0 })}>
            <Spin spinning={loading}>
              {pageList && pageList.length > 0 ? (
                <Row gutter={[15, 15]}>
                  {(pageList ?? []).map(item => (
                    <Col xxl={6} xl={8} key={item.id}>
                      <ListCard data={item} copy={setPageUpdate} delete={setPageUpdate} />
                    </Col>
                  ))}
                </Row>
              ) : (
                <Empty />
              )}
            </Spin>
          </div>
          <Paging pagingInfo={pagingInfo} />
        </div>
      </div>
      {propsModalShow && (
        <PropsGroupModal
          opType={opType}
          parentNodeData={currentParentNodes}
          childNodeData={currentChildNodes}
          onConfirm={() => {
            setPropsModalShow(false);
            setTreeUpdate();
          }}
          onCancel={() => setPropsModalShow(false)}
        />
      )}
    </Wrapper>
  );
};

export default TemplateManage;
