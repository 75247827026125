import qs from 'qs';
import { fetch } from '@maxtropy/components';
export interface CusNavListResponse {
  id: number;
  name: string; // 导航方案名称
  code: string; // 导航方案编号
}

export enum TenantMenuLevel {
  ONE = 1,
  TWO = 2,
  THREE = 3,
}

export const tenantMenuLevelDisplay: Record<TenantMenuLevel, string> = {
  [TenantMenuLevel.ONE]: '一级导航',
  [TenantMenuLevel.TWO]: '二级导航',
  [TenantMenuLevel.THREE]: '三级导航',
};

// 打开方式
export enum TenantMenuOpenType {
  CurrentPage = 0,
  NewPage = 1,
  CurrentPageForce = 2,
}

// 打开方式的值
export const tenantMenuOpenTypeDisplay: Record<TenantMenuOpenType, string> = {
  [TenantMenuOpenType.CurrentPage]: '当前标签页打开',
  [TenantMenuOpenType.NewPage]: '新建标签页打开',
  [TenantMenuOpenType.CurrentPageForce]: '当前标签页打开(强制URL跳转)',
};

// 页面跳转方式
export enum TenantMenuJumpType {
  WITHINSYSTEM = 1,
  CUSTOMIZATIONWITHINSYSTEM = 2,
  OUTSIDESYSTEM = 3,
}

// 页面跳转方式的值
export const tenantMenuJumpTypeDisplay: Record<TenantMenuJumpType, string> = {
  [TenantMenuJumpType.WITHINSYSTEM]: '选择系统内链接',
  [TenantMenuJumpType.CUSTOMIZATIONWITHINSYSTEM]: '自定义系统内链接',
  [TenantMenuJumpType.OUTSIDESYSTEM]: '系统外链接',
};

// 权限类型
export enum TenantMenuPermissionType {
  PLATFORM = 1,
  INTEGRATEDAPP = 2,
  NOPERMISSION = 3,
}

export const tenantMenuPermissionTypeDisplay: Record<TenantMenuPermissionType, string> = {
  [TenantMenuPermissionType.PLATFORM]: '平台权限',
  [TenantMenuPermissionType.INTEGRATEDAPP]: '第三方权限',
  [TenantMenuPermissionType.NOPERMISSION]: '无权限校验',
};

// 导航方案详情
export interface TenantMenuNavigationResponse {
  integratedAppPermissionCode?: string | string[];
  currentId: number;
  id: number;
  name: string;
  navigationProgramId: number;
  parentId: number;
  rootId: number;
  level: TenantMenuLevel;
  weighting: number;
  openType?: TenantMenuOpenType;
  jumpType?: TenantMenuJumpType;
  permissionType?: TenantMenuPermissionType;
  permissionCode?: string[] | string;
  moduleOriginId?: number;
  path?: string;
  integratedAppId?: number;
  trees?: TenantMenuNavigationResponse[];
}

export interface NavigationModuleOriginsResponse {
  id: number;
  name: string;
  value: string;
}

export interface NavigationPermissionApplicationResponse {
  id: number;
  code: string;
  parentId: number;
  rootId: number;
  name: string;
  level: number;
  checkPermission: boolean;
  description: string;
  pic: string;
  standard: boolean;
  originId: number;
  path: string;
}

export interface NavigationPermissionIntegratedAppResponse {
  id: number;
  code: string;
  parentId: number;
  rootId: number;
  name: string;
  level: number;
  description?: string;
  pic?: string;
  integratedAppId?: number;
  originId?: number;
  path?: string;
  textGray?: boolean;
}

// 获取导航方案列表
export function getNavigationList(): Promise<CusNavListResponse[]> {
  return fetch('/api/navigation/list');
}

// 复制导航方案
export function copyNavigation(navigationId: string): Promise<boolean> {
  return fetch(`/api/navigation/copy/${navigationId}`);
}

// 修改导航名称
export function updateNavgationName(params: { id: number; name: string }): Promise<void> {
  return fetch(`/api/navigation/name`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });
}

// 获取导航方案详情
export function getNavigationDetail(navigationId: string): Promise<TenantMenuNavigationResponse[]> {
  return fetch(`/api/navigation/detail/${navigationId}`);
}

// 删除详情信息（单条）
export function deleteNavgationDetail(detailId: number): Promise<boolean> {
  return fetch(`/api/navigation/detail/${detailId}`, {
    method: 'DELETE',
  });
}

// 修改详情信息（单条）
export function updateNavgationDetail(params: TenantMenuNavigationResponse): Promise<void> {
  return fetch(`/api/navigation/detail`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });
}

// 新增详情信息（单条）
export function createNavgationDetail(params: Partial<TenantMenuNavigationResponse>): Promise<void> {
  return fetch(`/api/navigation/detail`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

// 获取模块域名信息
export function getNavigationModuleOriginsList(): Promise<NavigationModuleOriginsResponse[]> {
  return fetch('/api/navigation/module/origins');
}

// 获取租户端权限导航相关信息
export function getPermissionApplicationList(): Promise<NavigationPermissionApplicationResponse[]> {
  return fetch('/api/navigation/permission/application');
}

// 获取第三方权限导航相关信息
export function getPermissionIntegratedAppList(appId: string): Promise<NavigationPermissionIntegratedAppResponse[]> {
  return fetch(`/api/navigation/permission/integratedApp?${qs.stringify({ appIds: appId }, { indices: false })}`);
}
// 获取第三方权限导航相关信息

export function getPermissionIntegratedAppListUsed(
  appId: string,
  navId: string
): Promise<NavigationPermissionIntegratedAppResponse[]> {
  return fetch(`/api/navigation/permission/integratedApp/used?${qs.stringify({ appId, navId }, { indices: false })}`);
}
