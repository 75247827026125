import { Form, Input, Space } from 'antd';
import React, { Key, useCallback, useMemo, useState } from 'react';
import Filter from '@/shared/components/Filter';
import { Paging, Table, usePaging } from '@maxtropy/components';
import { useQuery } from '../../../utils/utils';
import { EdgeTemplateTenantRequest, getEdgeTemplateTenant } from '../../../api/edgeTemplate';
import { selectTenantColumns } from '../utils';
import { TemplateListRes } from '../../../api/tenantAttribute';

interface TenantModalProps {
  value?: TemplateListRes[];
  onChange?: (value: TemplateListRes[]) => void;
  disabledIds?: Key[];
}

const TenantModal: React.FC<TenantModalProps> = ({ value, onChange, disabledIds }) => {
  const [form] = Form.useForm();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<EdgeTemplateTenantRequest>();
  const [tenants, setTenants] = useState<TemplateListRes[]>([]);

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        getEdgeTemplateTenant({
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          setTotalCount(res.total);
          const list: TemplateListRes[] = res.list.map(i => {
            return {
              ...i,
              mcid: i.rootMcid,
            };
          });
          setTenants(list);
          return list;
        }),
      [pageOffset, pageSize, searchParams, setTotalCount]
    )
  );

  const tenantIds = useMemo(() => {
    return (value ?? []).map(i => i.mcid);
  }, [value]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: TemplateListRes[]) => {
      const tenantsId = tenants.map(i => i.mcid);
      const buildDataMap = new Map<string, TemplateListRes>();
      (value ?? [])
        .filter(i => !tenantsId.includes(i.mcid))
        .forEach(i => {
          if (!buildDataMap.has(i.mcid)) {
            buildDataMap.set(i.mcid, i);
          }
        });
      selectedRows.forEach(i => {
        if (!buildDataMap.has(i.mcid)) {
          buildDataMap.set(i.mcid, i);
        }
      });
      onChange && onChange(Array.from(buildDataMap.values()));
    },
    getCheckboxProps: (record: TemplateListRes) => ({
      disabled: (disabledIds ?? []).includes(record.mcid),
    }),
    selectedRowKeys: tenantIds,
  };

  const onReset = () => {
    const params = {
      ...searchParams,
      name: undefined,
      tenantCode: undefined,
    };
    setSearchParams(params as EdgeTemplateTenantRequest);
    setPageOffset(1);
  };

  const onFinish = (val: EdgeTemplateTenantRequest) => {
    setSearchParams({ ...val });
    setPageOffset(1);
  };

  const filters = (
    <Filter form={form} onFinish={val => onFinish(val as EdgeTemplateTenantRequest)} onReset={onReset}>
      <Space size={15}>
        <Form.Item label="租户名称" name="name">
          <Input placeholder="请输入租户名称查询" />
        </Form.Item>
        <Form.Item label="租户code" name="tenantCode">
          <Input placeholder="请输入租户code查询" />
        </Form.Item>
      </Space>
    </Filter>
  );

  return (
    <>
      {filters}
      <Table
        loading={isLoading}
        sticky
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        rowKey="mcid"
        scroll={{ y: 300 }}
        columns={selectTenantColumns}
        dataSource={data}
      />
      <Paging pagingInfo={pagingInfo} />
    </>
  );
};

export default TenantModal;
