import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, InputNumber, Space, Modal, Spin } from 'antd';
import { isNil, debounce } from 'lodash-es';
import { FormContent, Wrapper } from '@maxtropy/components';
import AddressCascader from '../components/AddressCascader';
import styles from './index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import ResultModal from '../components/ResultModal';
import { getOptimalAngles, getPvEstimation, getPvPower, PvIrradiationRes, PvPowerReq } from '../../../api/tools';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const routes = [{ name: '工具中心' }, { name: '光伏测算工具' }, { name: '光伏发电量测算' }];

interface formVals {
  remark?: string;
  capacity: number;
  surfaceTilt: number;
  surfaceAzimuth: number;
  acOfFirstYear: number;
  acOfLater: number;
  cOfCarbonDust: number;
  cOfCo2: number;
  cOfSo2: number;
  cOfNox: number;
}

const Power: React.FC = () => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState<boolean>(false);
  const [position, setPosition] = useState<number[]>([]);
  const [pvEstimation, setPvEstimation] = useState<PvIrradiationRes>();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalApi, modalContextHolder] = Modal.useModal();

  useEffect(() => {}, [form]);

  const onFinish = (values: formVals) => {
    const param = {
      ...values,
      ...pvEstimation,
    };
    setLoading(true);
    getPvPower(param as PvPowerReq)
      .then(res => {
        modalApi.success({
          content: '计算完成，请在计算结果列表中查看。',
          onOk: () => {
            form.resetFields();
            setPosition([]);
            setPvEstimation(undefined);
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const calcIrradiation = debounce(() => {
    let { altitude, surfaceTilt, surfaceAzimuth, capacity } = form.getFieldsValue();
    let [longitude, latitude] = position;
    const params = {
      altitude,
      surfaceTilt,
      surfaceAzimuth,
      ratedPower: capacity,
      longitude,
      latitude,
    };
    if (Object.values(params).some(k => isNil(k))) return;
    setLoading(true);
    getPvEstimation(params)
      .then(res => {
        setPvEstimation(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, 200);

  const getLngLat = (lngLat: number[]) => {
    if (lngLat.length !== 2) {
      form.setFieldsValue({
        surfaceTilt: '',
        surfaceAzimuth: '',
      });
      setPosition([]);
      return;
    }
    let [longitude, latitude] = lngLat;
    setPosition(lngLat);
    setLoading(true);
    getOptimalAngles({ longitude, latitude })
      .then(res => {
        const { tilt, azimuth } = res;
        form.setFieldsValue({
          surfaceTilt: tilt,
          surfaceAzimuth: azimuth,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Wrapper routes={routes} className={styles.wrapper}>
      <div className={styles.titleBox}>
        <div className={styles.leftLine}></div>
        <span className={styles.rightText}>光伏发电量测算工具</span>
      </div>
      <Spin spinning={loading}>
        <FormContent style={{ paddingTop: 10 }}>
          <Form
            layout="vertical"
            form={form}
            {...formLayout}
            initialValues={{
              acOfFirstYear: 2.5,
              acOfLater: 0.7,
              cOfCarbonDust: 0.272,
              cOfCo2: 0.997,
              cOfSo2: 0.03,
              cOfNox: 0.015,
            }}
            onFinish={onFinish}
          >
            <Row>
              <Col span={12}>
                <Form.Item name="remark" label="发电量测算备注信息" rules={[{ max: 50, message: '最多输入五十个字' }]}>
                  <Input placeholder="可以不填写，不填写则系统默认生成" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="capacity" label="装机容量（MW）" rules={[{ required: true, message: '请输入' }]}>
                  <InputNumber
                    style={{ width: '100%' }}
                    max={999999}
                    min={0}
                    placeholder="请输入"
                    onChange={calcIrradiation}
                    precision={3}
                  />
                </Form.Item>
              </Col>
            </Row>
            <AddressCascader form={form} getLngLat={getLngLat} />
            <Row>
              <Col span={12}>
                <Form.Item
                  name="altitude"
                  label="海拔高度（m）"
                  tooltip={{
                    placement: 'right',
                    title:
                      '海拔高度也会影响发电量计算，楼高也算入到海拔高中，地区的海拔高度请自行查询。如果有楼高请在海报高度的基础上增加。',
                    icon: <InfoCircleOutlined style={{ color: 'var(--primary-color)' }} />,
                  }}
                  rules={[{ required: true, message: '请输入' }]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    max={999999}
                    min={0}
                    placeholder="请输入"
                    onChange={calcIrradiation}
                    precision={3}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  label="倾角（&deg;）"
                  required
                  tooltip={{
                    placement: 'right',
                    title: '该倾角默认值为最佳，可根据实际安装的情况调整，安装倾角为光伏板与水平面的夹角，单位为度。',
                    icon: <InfoCircleOutlined style={{ color: 'var(--primary-color)' }} />,
                  }}
                >
                  <Form.Item name="surfaceTilt" rules={[{ required: true, message: '请输入' }]}>
                    <InputNumber
                      style={{ width: '100%' }}
                      max={89}
                      min={0}
                      placeholder="请输入"
                      onChange={calcIrradiation}
                      precision={3}
                    />
                  </Form.Item>
                  <div className={styles.irradiationVal}>
                    倾角辐照量：{pvEstimation?.effectiveIrradiance ? pvEstimation.effectiveIrradiance.toFixed(2) : '--'}{' '}
                    kWh/㎡
                  </div>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item
                  name="surfaceAzimuth"
                  label="方位角（&deg;）"
                  tooltip={{
                    placement: 'right',
                    title:
                      '该方位角默认值为最佳，可根据实际安装的情况调整，安装方位角为光伏板与正北方向的夹角，单位为度，0度为正北，180度为正南，90度为正东，270度为正西。',
                    icon: <InfoCircleOutlined style={{ color: 'var(--primary-color)' }} />,
                  }}
                  rules={[{ required: true, message: '请输入' }]}
                >
                  <InputNumber
                    style={{ width: '100%' }}
                    max={360}
                    min={0}
                    placeholder="请输入"
                    onChange={calcIrradiation}
                    precision={3}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item name="acOfFirstYear" label="首年衰减系数" rules={[{ required: true, message: '请输入' }]}>
                  <InputNumber
                    style={{ width: '100%' }}
                    max={100}
                    min={0}
                    placeholder="请输入"
                    precision={3}
                    addonAfter="%"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="acOfLater" label="以后衰减系数" rules={[{ required: true, message: '请输入' }]}>
                  <InputNumber
                    style={{ width: '100%' }}
                    max={100}
                    min={0}
                    placeholder="请输入"
                    precision={3}
                    addonAfter="%"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  name="cOfCarbonDust"
                  label="碳粉尘系数（kg/kWh）"
                  rules={[{ required: true, message: '请输入' }]}
                >
                  <InputNumber style={{ width: '100%' }} max={10} min={0} placeholder="请输入" precision={3} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="cOfCo2" label="二氧化碳系数（kg/kWh）" rules={[{ required: true, message: '请输入' }]}>
                  <InputNumber style={{ width: '100%' }} max={10} min={0} placeholder="请输入" precision={3} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item name="cOfSo2" label="二氧化硫系数（kg/kWh）" rules={[{ required: true, message: '请输入' }]}>
                  <InputNumber style={{ width: '100%' }} max={10} min={0} placeholder="请输入" precision={3} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="cOfNox" label="氮氧化物系数（kg/kWh）" rules={[{ required: true, message: '请输入' }]}>
                  <InputNumber style={{ width: '100%' }} max={10} min={0} placeholder="请输入" precision={3} />
                </Form.Item>
              </Col>
            </Row>
            <Space className={styles.footer}>
              <Button type="primary" htmlType="submit" disabled={loading}>
                计算
              </Button>
              <Button type="primary" onClick={() => setOpen(true)}>
                计算结果列表
              </Button>
            </Space>
          </Form>
        </FormContent>
        <Modal
          className={styles.modal}
          title="计算结果列表"
          destroyOnClose
          onCancel={() => setOpen(false)}
          open={open}
          footer={[
            <Button key={'cancel'} onClick={() => setOpen(false)}>
              取消
            </Button>,
          ]}
          width={700}
        >
          <ResultModal type={'1'} />
        </Modal>
      </Spin>
      {modalContextHolder}
    </Wrapper>
  );
};

export default Power;
