import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { Table, Paging, usePaging, EllipsisSpan, useUpdate } from '@maxtropy/components';
import DetailModal from './DetailModal';
import dayjs from 'dayjs';
import { Content, OperationLogTypeDispaly, OperationLogType } from '../../../api/cc-operationLog';
import { apiNavigationLogPageGet, NavigationLogPageGetRequest } from '@maxtropy/cc-mgmt-apis';
import { useParams } from 'react-router-dom';

const otherColumns = [
  {
    title: '类型',
    dataIndex: 'operationType',
    ellipsis: { showTitle: true },
    render: (v: OperationLogType) => <EllipsisSpan value={OperationLogTypeDispaly[v]} />,
  },
  {
    title: '导航名称',
    dataIndex: 'navigationName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '操作人',
    dataIndex: 'operatingUsername',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '操作时间',
    dataIndex: 'operatingTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
];

interface Props {
  searchParams?: NavigationLogPageGetRequest;
}
const TableList: React.FC<Props> = ({ searchParams }) => {
  const { navigationProgramId } = useParams<{ navigationProgramId: string }>();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [contents, setContents] = useState<Content[]>([]);

  const [updateState, update] = useUpdate();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [currentRowData, setCurrentRowData] = useState<Content>();

  const getList = () => {
    setLoading(true);
    apiNavigationLogPageGet({
      ...searchParams,
      offset: String(pageOffset),
      count: String(pageSize),
      navigationProgramId,
    })
      .then(res => {
        if (res) {
          setContents(res.contents as Content[]);
          setTotalCount(res.total as number);
          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (updateState) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState, searchParams]);

  useEffect(() => {
    setPageOffset(1);
    if (pageOffset === 1) {
      update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, setPageOffset]);

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, pageSize, searchParams]);

  const columns = [
    ...otherColumns,
    {
      title: '操作',
      fixed: 'right' as const,
      width: 100,
      render: (v: Content) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                setModalOpen(true);
                setCurrentRowData(v);
              }}
            >
              详情
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table rowKey="id" sticky loading={loading} columns={columns} dataSource={contents} pagination={false} />
      <Paging pagingInfo={pagingInfo} />

      <DetailModal modalOpen={modalOpen} setModalOpen={setModalOpen} currentRowData={currentRowData} />
    </>
  );
};

export default TableList;
