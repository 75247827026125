import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { Key } from 'react';
import { PageRequest, PageResponse } from './entities';

export interface FormTemplatePageRequest extends PageRequest {
  name?: string;
  nameOrDescription?: string;
  status?: string;
}

export interface FormTemplateListRes {
  id: number;
  name: string;
  description: string;
  status: number;
  paramCount: number;
  updateCropUserId: string;
  updateCropUserName: string;
  updateTime: string;
}

export function getFormTemplatePage(query: FormTemplatePageRequest): Promise<PageResponse<FormTemplateListRes>> {
  return fetch(`/api/formTemplate/page?${qs.stringify(query, { indices: false })}`);
}
export enum FormTemplateStatus {
  ENABLE = 1,
  DISABLE = 2,
}
export const formTemplateStatusFormat = {
  [FormTemplateStatus.ENABLE]: '启用',
  [FormTemplateStatus.DISABLE]: '禁用',
};

// 修改模板状态
export function updateFormTemplateStatus(id: number, status: FormTemplateStatus) {
  return fetch(`/api/formTemplate/${id}/status`, {
    method: 'PUT',
    body: JSON.stringify({ status }),
  });
}

// 删除表单模板
export function deleteFormTemplate(id: Key) {
  return fetch(`/api/formTemplate/${id}`, {
    method: 'DELETE',
  });
}

export interface AddFormTemplateReq {
  name: string; //名称
  description?: string; //说明
  url: string; //接口URL
  body: string; //接口body
}

// 新增表单模板
export function createFormTemplate(data: AddFormTemplateReq) {
  return fetch(`/api/formTemplate`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

// 修改表单模板
export function updateFormTemplate(id: Key, data: AddFormTemplateReq) {
  return fetch(`/api/formTemplate/${id}`, {
    method: 'PUT',
    body: JSON.stringify(data),
  });
}

export interface FormTemplateInfoRes {
  id: number;
  name: string;
  description: string;
  url: string;
  body: string;
  status: number;
  params: string[];
  createCropUserId: number;
  createCropUserName: string;
  updateCropUserId: number;
  updateCropUserName: string;
  createTime: string;
  updateTime: string;
}

// 表单模板详情
export function getFormTemplateInfo(id: Key): Promise<FormTemplateInfoRes> {
  return fetch(`/api/formTemplate/${id}`);
}
