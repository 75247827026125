import React, { FC, ReactNode } from 'react';

type GridProps = {
  columns: number;
  children: ReactNode;
};

const Grid: FC<GridProps> = ({ children, columns }) => {
  return (
    <div
      style={{
        marginLeft: '8px',
        display: 'grid',
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        gridGap: 10,
        maxWidth: '200px',
      }}
    >
      {children}
    </div>
  );
};

export default Grid;
