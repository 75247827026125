import React, { useRef } from 'react';
import { Tabs } from 'antd';
import { TabsProps } from 'antd/es/tabs';
import classNames from 'classnames';
import { uniqBy } from 'lodash-es';
import styles from './index.module.scss';
import ResizeObserver from 'rc-resize-observer';

const { TabPane } = Tabs;

export interface TabDataType {
  key: string;
  name: string;
}

export interface HistoryTabProps extends Partial<TabsProps> {
  onTabChange?: (tabTile: string) => void;
  tabData: TabDataType[];
  activeKey?: string;
  className?: string;
  extral?: React.ReactNode;
}

const HistoryTab: React.FC<HistoryTabProps> = props => {
  const { onTabChange, tabData = [], activeKey, className, extral } = props;
  const itemsRef = useRef<(HTMLSpanElement | null)[]>([]);
  return (
    <ResizeObserver onResize={({ width }) => {}}>
      <div className={classNames(styles.tabs, className)}>
        <div className={styles.tab_box}>
          <Tabs onChange={onTabChange} activeKey={activeKey}>
            {uniqBy(tabData, 'key').map((item, index) => (
              <TabPane tab={<span ref={el => (itemsRef.current[index] = el)}>{item.name}</span>} key={item.key} />
            ))}
          </Tabs>
        </div>

        <div className={styles.exportBtn}>{extral}</div>
      </div>
    </ResizeObserver>
  );
};

export default HistoryTab;
