import { fetch } from '@maxtropy/components';

export interface MiniAppPermissionFunctionTreeItem {
  id?: number;
  code?: string;
  parentId?: number;
  rootId?: number;
  name?: string;
  level?: number;
  description?: string;
  pic?: string;
  miniAppId?: number;
  children?: MiniAppPermissionFunctionTreeItem[];
}

export interface ISvListResponse {
  id: number;
  name: string;
}

export interface PermissionData {
  code: string;
  name: string;
  miniAppToolId?: number;
  parentPermissionId?: number;
  id?: number;
}

export function getMiniAppPermissionFunctionTree() {
  return fetch<MiniAppPermissionFunctionTreeItem[]>(`/api/mini/app/permission/functional/tree`);
}

export function getIsvList(): Promise<ISvListResponse[]> {
  return fetch('/api/mini/app/isv/list');
}

export function getPermissionTree(id: number) {
  return fetch<MiniAppPermissionFunctionTreeItem[]>(`/api/mini-app/permission/tree?miniAppToolId=${id}`, {
    method: 'POST',
  });
}

export function deletePermission(id: number) {
  return fetch(`/api/mini-app/permission/delete?miniAppPermissionId=${id}`, {
    method: 'POST',
  });
}

export function createPermission(params: PermissionData) {
  return fetch(`/api/mini-app/permission/create`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export function updatePermission(params: PermissionData) {
  return fetch(`/api/mini-app/permission/update?miniAppPermissionId=${params.id}`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}
