import React, { FC, useEffect } from 'react';
import { Form, Row, Col } from 'antd';
import { FormContent } from '@maxtropy/components';
import ShowInput from '@/shared/components/ShowInput';
import { DevicePropertyResponse } from '../../../../api/device';

const formLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 18 },
};
interface BaseInfoProps {
  data?: DevicePropertyResponse;
}

const BaseInfo: FC<BaseInfoProps> = ({ data }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
      });
    }
  }, [data, form]);

  return (
    <Form form={form} {...formLayout} labelAlign="left">
      <FormContent title="设备信息">
        <Row>
          <Col span={12}>
            <Form.Item name="name" label="设备名称">
              <ShowInput />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="sn" label="设备S/N码">
              <ShowInput />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="typeName" label="所属类目">
              <ShowInput />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="physicalModelNo" label="物模型型号">
              <ShowInput />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="modelName" label="所属型号">
              <ShowInput />
            </Form.Item>
          </Col>
        </Row>
      </FormContent>
    </Form>
  );
};

export default BaseInfo;
