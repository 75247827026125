import { FC, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { TenantBaseInfoResponse } from '../../../api/cc-tenant';
import { TabsBaseProps } from '../util';
import { FormContent, getRealUrl, useUpdate } from '@maxtropy/components';

import styles from './index.module.scss';
import { Button, Col, List, Row, Space, Statistic } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';

import {
  getUniversalResourcesCount,
  UniversalResourcesCount,
  getIntegratedAppList,
  IntegratedAppResponse,
  unsubscribeIntegratedApp,
  subscribeIntegratedApp,
} from '../../../api/cc-integrated-app';
import { useHasPermission } from '../../../utils/hooks';
import { PermissionsType } from '../../../common/permissionsConst';

interface IntegratedAppProps extends TabsBaseProps {
  tenant?: TenantBaseInfoResponse;
  disabled?: boolean;
}

const IntegratedApp: FC<IntegratedAppProps> = props => {
  const { onBreacrumbChange, tenant, disabled = false } = props;

  const hasIntegratedApplicationSubscriptionPermissions = useHasPermission(
    PermissionsType.B_INTEGRATED_APPLICATION_SUBSCRIPTION
  );

  const [updateState, update] = useUpdate();

  const [universalResourcesCount, setUniversalResourcesCount] = useState<UniversalResourcesCount>();
  const [apps, setApps] = useState<IntegratedAppResponse[]>([]);

  const account = useMemo(() => {
    return !!(tenant && tenant.accountOpenStatus);
  }, [tenant]);

  useEffect(() => {
    if (tenant?.mcid) {
      getUniversalResourcesCount(tenant.mcid).then(setUniversalResourcesCount);
    }
  }, [tenant]);

  useEffect(() => {
    if (tenant?.mcid) {
      getIntegratedAppList(tenant.mcid).then(res => {
        if (disabled) {
          setApps(res.filter(i => i.subscribe));
        } else {
          setApps(res);
        }
      });
    }
  }, [tenant, updateState, disabled]);

  const routes = useMemo(() => {
    return [
      { name: '租户管理' },
      { name: '租户组织权限和账户' },
      { name: `${disabled ? '查看' : '编辑'}租户` },
      { name: '集成应用订阅' },
    ];
  }, [disabled]);

  useEffect(() => {
    onBreacrumbChange && onBreacrumbChange(routes);
  }, [onBreacrumbChange, routes]);

  const onUnSubscribe = (id: number) => {
    if (tenant?.mcid) {
      unsubscribeIntegratedApp(id, tenant?.mcid).then(res => {
        if (res) {
          update();
        }
      });
    }
  };

  const onSubscribe = (id: number) => {
    if (tenant?.mcid) {
      subscribeIntegratedApp(id, tenant?.mcid).then(res => {
        if (res) {
          update();
        }
      });
    }
  };

  return (
    <div className={styles.container}>
      <FormContent title="通用资源投放">
        <Row style={{ padding: '15px 0 30px 0' }}>
          <Col span={8}>
            <Statistic
              className={styles.statistic}
              title="设备模板个数"
              value={universalResourcesCount?.deviceTemplateCount ?? 0}
              suffix="个"
            />
            {!disabled && (
              <Button
                disabled={!account}
                type="link"
                style={{ paddingLeft: 0, paddingRight: 0 }}
                target="_blank"
                href={'/device/info/distribution'}
              >
                前往编辑设备模板投放情况
                <DoubleRightOutlined style={{ color: 'rgba(0, 0, 0, 0.65)' }} />
              </Button>
            )}
          </Col>
          <Col span={8}>
            <Statistic
              className={styles.statistic}
              title="网关个数"
              value={universalResourcesCount?.gatewayCount ?? 0}
              suffix="个"
            />
            {!disabled && (
              <Button
                disabled={!account}
                type="link"
                style={{ paddingLeft: 0, paddingRight: 0 }}
                target="_blank"
                href={'/device/config/gateway'}
              >
                前往编辑网关投放情况
                <DoubleRightOutlined style={{ color: 'rgba(0, 0, 0, 0.65)' }} />
              </Button>
            )}
          </Col>
          <Col span={8}>
            <Statistic
              className={styles.statistic}
              title="数采模板个数"
              value={universalResourcesCount?.edgeDeviceCountTemplate ?? 0}
              suffix="个"
            />
            {!disabled && (
              <Button
                disabled={!account}
                type="link"
                style={{ paddingLeft: 0, paddingRight: 0 }}
                target="_blank"
                href={'/device/edge/distribution'}
              >
                前往数采模板投放情况
                <DoubleRightOutlined style={{ color: 'rgba(0, 0, 0, 0.65)' }} />
              </Button>
            )}
          </Col>
        </Row>
        <List
          size="large"
          // split={false}
          dataSource={apps}
          renderItem={item => (
            <List.Item
              extra={
                !disabled ? (
                  <Space>
                    {item.subscribe ? (
                      <>
                        <Button type="link" disabled={!hasIntegratedApplicationSubscriptionPermissions || !account}>
                          <Link to={`/tenant/customer/manage/integrade/${tenant?.mcid}/${item.id}`}>
                            编辑应用授权配置
                          </Link>
                        </Button>
                        <Button
                          disabled={!hasIntegratedApplicationSubscriptionPermissions || !account}
                          type="link"
                          style={{ color: 'var(--disabled-color)' }}
                          onClick={() => onUnSubscribe(item.id)}
                        >
                          取消投放
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          disabled={!hasIntegratedApplicationSubscriptionPermissions || !account}
                          type="link"
                          onClick={() => onSubscribe(item.id)}
                        >
                          投放
                        </Button>
                      </>
                    )}
                  </Space>
                ) : null
              }
            >
              <Space size={30} className={styles.listItem}>
                {item.picUrl && <img className={styles.img} alt="logo" src={getRealUrl(item.picUrl)} />}
                <div>
                  <div className={styles.listTitle}>{item.name}</div>
                  <Space>
                    <div className={styles.listContent}>开发者APP ID：{item.appKey}</div>
                    <div className={styles.listContent}>集成应用ID：{item.code}</div>
                  </Space>
                </div>
              </Space>
            </List.Item>
          )}
        />
      </FormContent>
    </div>
  );
};

export default IntegratedApp;
