import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Form, Input, Select } from 'antd';
import { Wrapper, Filter } from '@maxtropy/components';
import { OperationLogType, OperationLogTypeDispaly } from '../../../api/cc-operationLog';
import TableList from './Table';
import { NavigationLogPageGetRequest } from '@maxtropy/cc-mgmt-apis';

const routes = [{ name: '系统设置' }, { name: '导航设置' }, { name: '导航方案' }, { name: '操作日志' }];

interface Props {}
const ListDetail: React.FC<Props> = () => {
  const { navigationProgramId } = useParams<{ navigationProgramId: string }>();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<NavigationLogPageGetRequest>();

  const onFinish = (val: NavigationLogPageGetRequest) => {
    setSearchParams({ ...val, navigationProgramId });
    navigate('?');
  };

  const onReset = () => {
    form.resetFields();
    setSearchParams(undefined);
    navigate('?');
  };

  const filters = (
    <Filter form={form} onFinish={val => onFinish(val as NavigationLogPageGetRequest)} onReset={onReset} hasClear>
      <>
        <Col span={6}>
          <Form.Item label="类型" name="operationType">
            <Select placeholder="全部" allowClear={true}>
              <Select.Option value={OperationLogType.ADD}>
                {OperationLogTypeDispaly[OperationLogType.ADD]}
              </Select.Option>
              <Select.Option value={OperationLogType.UPDATE}>
                {OperationLogTypeDispaly[OperationLogType.UPDATE]}
              </Select.Option>
              <Select.Option value={OperationLogType.DELETE}>
                {OperationLogTypeDispaly[OperationLogType.DELETE]}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="名称" name="navigationName">
            <Input placeholder="请输入导航名称搜索" />
          </Form.Item>
        </Col>
      </>
    </Filter>
  );

  return (
    <>
      <Wrapper routes={routes} filters={filters}>
        <TableList searchParams={searchParams} />
      </Wrapper>
    </>
  );
};

export default ListDetail;
