import { FC } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Space, Button } from 'antd';
import { Wrapper } from '@maxtropy/components';
import PropertyDetail from './PropertyDetail';
import AttributeDetail from './AttributeDetail';

import styles from './index.module.scss';
import EdgeTemplateDetail from './EdgeTemplateDetail';

const routes = [{ name: 'Iot配置' }, { name: '设备管理' }, { name: '批量导入设备' }, { name: '模板详情' }];

const DeviceDetail: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const tabs = urlSearchParams.get('tabs') || '1';

  return (
    <Wrapper routes={routes} className={styles.wrapper}>
      {id && (
        <>
          <PropertyDetail id={id} />
          <AttributeDetail id={id} />
          <EdgeTemplateDetail id={id} />
        </>
      )}
      <Space className="sticky-footer">
        <Button
          onClick={() => {
            navigate(`/device/manage/batch?tabs=${tabs}`);
          }}
        >
          返回
        </Button>
      </Space>
    </Wrapper>
  );
};

export default DeviceDetail;
