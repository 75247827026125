import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { Button, Col, DatePicker, Form, Input, message, Modal, Radio, Row, Select } from 'antd';
import { commonMobilePhoneRegex } from '../../../../utils/regex';
import {
  checkCellphone,
  createCustomerUser,
  PostCustomerUserRequest,
  TenantUserDetailResponse,
} from '../../../../api/cc-customer';
import dayjs, { Dayjs } from 'dayjs';
import { Gender, LeftNavPreference, UserStatus } from '../../../../api/cc-const';
import { checkUserName, emailFormLayout } from '../TenantUserManagement/Create';
import { EmailFormItem } from '../../../../components/FormComponent';
import {
  AttachmentUpload,
  SearchInput as CustomSearchInput,
  SearchInputOptionProps,
  Wrapper,
  FormContent,
} from '@maxtropy/components';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;
const routes = [
  { name: '租户管理' },
  { name: '租户组织权限和账户' },
  { name: '租户员工工号管理', path: '/tenant/customer/employee' },
  { name: '新建企业员工工号' },
];

const formLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const TenantEmployeeCreate: React.FC = () => {
  const [messageApi, messageContextHolder] = message.useMessage();
  const [modalApi, modalContextHolder] = Modal.useModal();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [searchPatternPhoneValue, setSearchPatternPhoneValue] = useState<string>('');
  const [cellphoneRes, setCellphoneRes] = useState<TenantUserDetailResponse>();
  const [option, setOption] = useState<SearchInputOptionProps[]>([]);
  const [disabled, setDisabled] = useState<boolean>(false);

  const onPhoneSearch = (value: string) => {
    if (commonMobilePhoneRegex.test(value)) {
      setSearchPatternPhoneValue(value);
    } else {
      form.validateFields(['cellphone']);
    }
  };

  useEffect(() => {
    if (commonMobilePhoneRegex.test(searchPatternPhoneValue)) {
      setCellphoneRes(undefined);
      checkCellphone(searchPatternPhoneValue).then(res => {
        setCellphoneRes(res);
        if (!res) {
          form.setFieldsValue({
            cellphone: searchPatternPhoneValue,
          });
        }
      });
    }
  }, [searchPatternPhoneValue, form]);

  useEffect(() => {
    if (cellphoneRes) {
      setOption([
        {
          name: `${cellphoneRes.cellphone} ${cellphoneRes.name}`,
          value: cellphoneRes.cellphone,
          label: cellphoneRes.cellphone,
        },
      ]);
    } else {
      if (commonMobilePhoneRegex.test(searchPatternPhoneValue)) {
        setOption([]);
        form.setFieldsValue({
          name: undefined,
          status: UserStatus.ENABLE,
          username: undefined,
          gender: undefined,
          birthday: undefined,
          email: undefined,
          wechat: undefined,
          qq: undefined,
          headPic: undefined,
        });
        setDisabled(false);
      }
    }
  }, [searchPatternPhoneValue, cellphoneRes, form]);

  const onCellphoneChange = (value: string) => {
    if (value === cellphoneRes?.cellphone) {
      form.setFieldsValue({
        ...cellphoneRes,
        birthday: cellphoneRes.birthday ? dayjs(cellphoneRes.birthday) : undefined,
      });
      setDisabled(true);
    }
  };

  const onFinish = (values: PostCustomerUserRequest) => {
    values.birthday = values.birthday ? dayjs(values.birthday).format('YYYY-MM-DD') : '';
    createCustomerUser(values).then(data => {
      messageApi.success('用户创建成功');
      navigate(`/tenant/customer/employee/createStaff/${data.muid}`);
    });
  };

  const startCreateAction = () => {
    if (!cellphoneRes) {
      form.submit();
    } else {
      messageApi.success('开始创建员工！');
      navigate(`/tenant/customer/employee/createStaff/${cellphoneRes.muid}`);
    }
  };

  return (
    <Wrapper routes={routes}>
      <div className={styles.container}>
        <Form
          {...formLayout}
          form={form}
          validateTrigger={['onBlur']}
          initialValues={{ status: UserStatus.ENABLE }}
          onFinish={onFinish}
          layout="vertical"
        >
          <FormContent title="用户信息">
            <Row>
              <Col span={8}>
                <Form.Item
                  name="cellphone"
                  label="手机号码"
                  validateFirst
                  extra="登录密码将通过手机号发送，请确保手机号可用"
                  rules={[{ required: true }]}
                >
                  <CustomSearchInput
                    timer={300}
                    onSearch={onPhoneSearch}
                    placeholder="请输入手机号"
                    option={option}
                    onChange={onCellphoneChange}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="姓名"
                  name="name"
                  rules={[
                    { required: true, message: '请输入姓名' },
                    { pattern: /^[\u4e00-\u9fa5]+$/g, message: '姓名必须为汉字' },
                    { max: 8, message: '员工姓名最多8个字符' },
                  ]}
                >
                  <Input placeholder="请输入姓名" disabled={disabled} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="用户状态" name="status">
                  <Select disabled={disabled}>
                    <Option value={UserStatus.ENABLE}>启用</Option>
                    <Option value={UserStatus.DISABLE}>禁用</Option>
                    <Option value={UserStatus.FREEZE}>冻结</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="登录账号"
                  required
                  name="username"
                  rules={[{ validator: checkUserName }]}
                  validateTrigger={['onBlur']}
                >
                  <Input placeholder="请输入登录账号" disabled={disabled} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="navigationPreference"
                  label="左侧导航栏"
                  rules={[{ required: true, message: '请选择' }]}
                >
                  <Radio.Group style={{ width: '180%' }} disabled={disabled}>
                    <Radio value={LeftNavPreference.OPEN_CLOSE}>收起/展开</Radio>
                    <Radio value={LeftNavPreference.ALL_OPEN}>一直展开</Radio>
                    <Radio value={LeftNavPreference.ALL_CLOSE}>直接隐藏</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="gender" label="性别">
                  <Radio.Group disabled={disabled}>
                    <Radio value={Gender.MALE}>男</Radio>
                    <Radio value={Gender.FEMALE}>女</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="birthday" label="生日">
                  <DatePicker
                    disabled={disabled}
                    style={{ width: '100%' }}
                    disabledDate={(currentDate: Dayjs) => {
                      return currentDate.isAfter(dayjs(), 'date');
                    }}
                    placeholder="请输入生日"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="email"
                  label="邮箱"
                  {...emailFormLayout}
                  validateTrigger={['onChange']}
                  rules={[{ type: 'email', message: '请输入正确的邮箱' }]}
                >
                  <EmailFormItem disabled={disabled} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="wechat"
                  label="微信"
                  rules={[{ pattern: /^[A-Za-z0-9_-]{6,20}$/, message: '请输入6-20位字母、数字、下划线或减号的组合' }]}
                >
                  <Input placeholder="请输入微信" disabled={disabled} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="qq" label="QQ" rules={[{ pattern: /^[0-9]{4,15}$/, message: '请输入4-15位数字' }]}>
                  <Input placeholder="请输入QQ" disabled={disabled} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="headPic" label="头像">
                  <AttachmentUpload disabled={disabled} />
                </Form.Item>
              </Col>
            </Row>
            <Button type="primary" onClick={startCreateAction}>
              开始创建员工工号
            </Button>
            <Button
              onClick={() => {
                modalApi.confirm({
                  title: '操作确认',
                  icon: <ExclamationCircleOutlined />,
                  content: '是否放弃所有未保存信息并返回列表？',
                  onOk: () => navigate('/tenant/customer/employee'),
                });
              }}
              style={{ marginLeft: 20 }}
            >
              关闭
            </Button>
          </FormContent>
        </Form>
      </div>
      {messageContextHolder}
      {modalContextHolder}
    </Wrapper>
  );
};

export default TenantEmployeeCreate;
