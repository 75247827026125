import React, { useContext } from 'react';
import { EllipsisSpan, FieldTable, MxColumnsType, Modal, MxColumnType, Table } from '@maxtropy/components';
import { DataPointType, DataProperty } from '../../../../../types';
import { PointContext, PointContextProps } from '../../../contextTypes';
import { getColumns } from './columns';
import { EdgeDeviceTemplatePoint } from './index';
import { IEnum } from '../utils';
import { uniqueId } from 'lodash-es';

interface IEC104Props {
  loading?: boolean;
  editColumns?: MxColumnsType<EdgeDeviceTemplatePoint>;
  dataPropertiesAll?: DataProperty[];
}

const indexColumn: MxColumnType<EdgeDeviceTemplatePoint> = {
  title: '序号',
  width: 70,
  fixed: 'left',
  render: (_, record, index) => index + 1,
};

const columns = [
  {
    title: '枚举',
    dataIndex: 'enum',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ?? '--'} />,
  },
  {
    title: '枚举值名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ?? '--'} />,
  },
  {
    title: '采集（原始）值',
    dataIndex: 'value',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ?? '--'} />,
  },
];

const IEC104PointBase: React.FC<IEC104Props> = props => {
  const { loading, editColumns, dataPropertiesAll } = props;

  const { dataSource, canAdjustField } = useContext(PointContext) as PointContextProps<EdgeDeviceTemplatePoint>;

  const showMappingInfo = (values: IEnum[]) => {
    Modal.info({
      title: '枚举映射',
      width: 640,
      content: (
        <Table
          style={{ marginBottom: '10px' }}
          rowKey="enum"
          dataSource={values}
          columns={columns}
          pagination={false}
        />
      ),
      onOk() {},
    });
  };

  return (
    <>
      <FieldTable
        sticky
        adjustField={canAdjustField}
        adjustWidth={canAdjustField}
        cacheKey="IEC104PointBase"
        scroll={{ y: 500 }}
        loading={loading}
        rowKey={uniqueId()}
        dataSource={dataSource?.filter(item => item.pointType === DataPointType.BASE_POINT)}
        columns={[
          indexColumn,
          ...getColumns(dataPropertiesAll, showMappingInfo, !canAdjustField ? [] : editColumns || []),
          ...(canAdjustField ? [] : editColumns || []),
        ]}
      />
    </>
  );
};

export default IEC104PointBase;
