import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import { RoleSteps } from '../../../components/CommonSteps';
import { AppFunctionPermissionVo, getAppPermissionTree } from '../../../api/cc-app';
import { Button, Col, Modal, Row, Tabs } from 'antd';
import {
  findRoleByCode,
  getRoleIntegratedAppList,
  IntegratedApp,
  IntegratedAppPermission,
  queryPermissionList,
  RoleInfo,
  updateMiniAppPermission,
  updatePermission,
} from '../../../api/cc-role';
import { useNavigate, useParams } from 'react-router-dom';
import usePageStatus, { PageStatus, PageStatusDisplay } from './usePageStatus';
import { Wrapper, RoleTree, TreeDataNode as DataNode, Key, getRealUrl, getRolePermissions } from '@maxtropy/components';
import { ProfileOutlined, UserOutlined } from '@ant-design/icons';

import EditIntegratedPermissions, { RefProps } from './EditIntegratedPermissions';
import MiniAppPermissions, { RefMiniAppPermissionsProps } from './MiniAppPermissions';

interface EditPermissionsProps {}

export function dig(data: AppFunctionPermissionVo[], level = 0): DataNode[] {
  return data.map(item => {
    const isLeaf = !(Array.isArray(item.children) && item.children.length > 0);
    const node = {
      children: [] as DataNode[],
      key: item.code,
      title: item.name,
      customized: !item.standard,
      type: item.type,
      pic: getRealUrl(item.pic),
      description: item.description,
    };
    if (!isLeaf) {
      node.children = dig(item.children, level + 1);
    }
    return node;
  });
}

const EditPermissions: React.FC<EditPermissionsProps> = props => {
  const [modalApi, modalContextHolder] = Modal.useModal();
  const [checkedKeys, setCheckedKeys] = useState<Key[]>([]);
  const [autoSyncedKeys, setAutoSyncedKeys] = useState<Key[]>([]);
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const { code } = useParams<{ code: string }>();
  const pageStatus = usePageStatus();
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState(false);
  const originalData = useRef<{ originalCheckedKeys: Key[]; originalAutoSyncedKeys: Key[] }>();
  const miniAppPermissionsRef = useRef<RefMiniAppPermissionsProps>(null);

  /* 角色名称，模版编号相关*/
  const [role, setRole] = useState<RoleInfo>();
  useEffect(() => {
    if (!code) return;
    if (pageStatus !== PageStatus.CREATE) {
      findRoleByCode(code).then(data => {
        setRole(data);
      });
    }
  }, [code, pageStatus]);
  /* 角色名称，模版编号相关*/

  const refMap = React.useRef(new Map<number, RefProps>());
  const [integratedApps, setIntegratedApps] = useState<IntegratedApp[]>([]);

  useEffect(() => {
    if (!code) return;
    getRoleIntegratedAppList(code).then(res => {
      setIntegratedApps(res);
    });
  }, [code]);

  useEffect(() => {
    if (!code) return;
    getAppPermissionTree().then(data => {
      const treeData = dig(data);
      setTreeData(treeData);
      queryPermissionList(code).then(data => {
        const { permissions = [], syncs = [] } = data;
        setCheckedKeys(permissions);
        setAutoSyncedKeys(syncs);
        originalData.current = { originalCheckedKeys: permissions, originalAutoSyncedKeys: syncs };
      });
    });
  }, [code]);

  const submit = () => {
    setSubmitLoading(true);

    let reqList = [];

    const integratedAppPermission = Array.from(refMap.current.entries()).reduce((previousValue, [key, ref]) => {
      previousValue[key] = ref.submit();
      return previousValue;
    }, {} as { [key: number]: IntegratedAppPermission });
    const { permissions, syncs } = getRolePermissions({
      treeData,
      checkedKeys,
      autoSyncedKeys,
      ...originalData.current!,
    });
    reqList.push(
      updatePermission({
        roleCode: code!,
        addPermissions: permissions[0],
        deletePermission: permissions[1],
        addSyncs: syncs[0],
        deleteSyncs: syncs[1],
        integratedAppPermission,
      })
    );

    const miniAppPermissionsData = miniAppPermissionsRef.current?.getSubmitData();
    if (miniAppPermissionsData) {
      reqList.push(updateMiniAppPermission(miniAppPermissionsData));
    }

    Promise.all(reqList)
      .then(() => {
        navigate(`/tenant/customer/prefab-role`);
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const routes = [
    { name: '租户管理' },
    { name: '租户组织权限和账户' },
    { name: '预置角色管理' },
    {
      name: `${
        pageStatus !== PageStatus.DETAIL ? PageStatusDisplay[PageStatus.UPDATE] : PageStatusDisplay[PageStatus.DETAIL]
      }角色权限`,
    },
  ];

  const disabled = pageStatus === PageStatus.DETAIL;

  const onClose = () => {
    if (!disabled) {
      modalApi.confirm({
        icon: null,
        content: '确认放弃未保存的修改返回列表吗?',
        onOk: () => {
          navigate(`/tenant/customer/prefab-role`);
        },
      });
    } else {
      navigate(`/tenant/customer/prefab-role`);
    }
  };

  return (
    <Wrapper routes={routes} className={styles.permissionsWrapper}>
      <RoleSteps current={1} />
      <Row gutter={[16, 16]} className={styles.desc}>
        <Col>
          <UserOutlined />
          角色名称：{role?.name}
        </Col>
        <Col offset={1}>
          <ProfileOutlined />
          模版编号： {role?.code}
        </Col>
      </Row>

      <Tabs defaultActiveKey="pc" style={{ width: 1000 }}>
        <Tabs.TabPane tab="PC端" key="pc">
          <Tabs defaultActiveKey="platform" style={{ width: 1000 }}>
            <Tabs.TabPane tab="平台权限" key="platform">
              <div className={styles.treeContainer}>
                <h3>权限功能管理</h3>
                <RoleTree
                  treeData={treeData}
                  className={styles.treeWrapper}
                  checkedKeys={checkedKeys}
                  onCheck={setCheckedKeys}
                  autoSyncedKeys={autoSyncedKeys}
                  onAutoSync={setAutoSyncedKeys}
                  autoSyncable
                  showDetail
                  disabled={disabled}
                />
              </div>
            </Tabs.TabPane>
            {integratedApps.map(i => (
              <Tabs.TabPane tab={i.name} key={i.id}>
                <EditIntegratedPermissions
                  ref={(ref: RefProps) => {
                    refMap.current.set(i.id, ref);
                  }}
                  integratedAppId={i.id}
                  roleCode={code!}
                  disabled={disabled}
                />
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Tabs.TabPane>
        <Tabs.TabPane tab="小程序端" key="miniApp">
          <div className={styles.treeContainer}>
            <h3>小程序权限功能管理</h3>
            <MiniAppPermissions disabled={disabled} ref={miniAppPermissionsRef}></MiniAppPermissions>
          </div>
        </Tabs.TabPane>
      </Tabs>

      <div className={styles.buttonContainer}>
        <Button
          loading={submitLoading}
          style={{ marginRight: 20 }}
          onClick={() => {
            navigate(`/tenant/customer/prefab-role/role/${pageStatus}/${code}`);
          }}
        >
          上一步
        </Button>
        {!disabled && (
          <Button type="primary" style={{ marginRight: 20 }} onClick={submit}>
            提交
          </Button>
        )}
        <Button onClick={onClose}>关闭</Button>
      </div>
      {modalContextHolder}
    </Wrapper>
  );
};

export default EditPermissions;
