import { useState, FC, useEffect, useContext } from 'react';
import { Button, Col, Input, message, Row, Space, Spin } from 'antd';
import { usePaging, Paging } from '@maxtropy/components';
import styles from '../index.module.scss';

import ListCard from '../../TemplateManage/components/ListCard';
import { SearchOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { apiDataRulePageGet } from '../../../ytt/types/dataRule/page';
import { TemplateStatus } from '../../../api/type';
import { numberToString } from '../../TemplateManage/utils';
import { RulesGroupContext } from '..';
import { useNavigate } from 'react-router-dom';

const TemplateManage: FC = () => {
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;
  const [messageApi, messageContextHolder] = message.useMessage();
  const [searchStr, setSearchStr] = useState<string>();
  const { data: details, saveForm, saveLoading, nextStep, prevStep } = useContext(RulesGroupContext);
  const [currentSelectedId, setCurrentSelectedId] = useState<number>();
  const navigate = useNavigate();
  const { data: pageList, loading } = useRequest(
    () => {
      return apiDataRulePageGet({
        dataPropertyGroupId: numberToString(details?.dataPropertyGroupId),
        name: searchStr,
        status: TemplateStatus.PUBLISH,
        page: numberToString(pageOffset),
        size: numberToString(pageSize),
      }).then(res => {
        setTotalCount(res.total!);
        return res.list;
      });
    },
    {
      debounceWait: 300,
      refreshDeps: [pageOffset, pageSize, searchStr, details],
    }
  );

  const outerDomOnClick = (id?: number) => {
    setCurrentSelectedId(id);
  };
  const nextBtn = (save = true) => {
    if (!currentSelectedId) {
      messageApi.warning('请选择模板');
      return;
    }
    saveForm?.({ dataRuleId: currentSelectedId, save }).then(id => {
      if (id) {
        // 点击保存草稿后跳转到列表页
        if (!save) {
          navigate(-1);
          return;
        }
        nextStep?.();
      }
    });
  };
  useEffect(() => {
    if (details?.dataRuleId) {
      setCurrentSelectedId(details?.dataRuleId);
    }
  }, [details]);
  return (
    <>
      <div className={styles.input_box}>
        <Input
          onChange={e => {
            setSearchStr(e.target.value);
          }}
          placeholder="请输入搜索关键字"
          suffix={<SearchOutlined className={styles.search_icon} />}
        ></Input>
      </div>
      <div className={styles.list}>
        <Spin spinning={loading}>
          <Row gutter={[15, 15]}>
            {(pageList ?? []).map(item => (
              <Col xxl={6} xl={8} key={item.id}>
                <div onClick={() => outerDomOnClick(item.id)}>
                  <ListCard hover={false} data={item} selectedId={currentSelectedId} />
                </div>
              </Col>
            ))}
          </Row>
        </Spin>
      </div>
      <Paging pagingInfo={pagingInfo} />
      <div style={{ paddingTop: 10 }}>
        <Space>
          <Button onClick={() => nextBtn(false)} loading={saveLoading}>
            保存草稿
          </Button>
          <Button onClick={() => prevStep?.()}>上一步</Button>
          <Button type="primary" loading={saveLoading} onClick={() => nextBtn(true)}>
            下一步
          </Button>
        </Space>
      </div>
      {messageContextHolder}
    </>
  );
};

export default TemplateManage;
