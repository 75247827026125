import React, { FC, useEffect, useState, useMemo } from 'react';
import { Space, Table, Empty, Flex, message } from 'antd';
import { Button, PopConfirm, EllipsisSpan } from '@maxtropy/components';
import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { TabsBaseProps } from '../util';
import {
  apiV2TenantOpenChannelPost,
  apiV2TenantGetChannelMcidPost,
  apiV2TenantDelChannelMcidPost,
  V2TenantGetChannelMcidPostResponse,
} from '@maxtropy/tody-mgmt-apis-v2';
import { TenantBaseInfoResponse } from '../../../api/cc-tenant';

import styles from './index.module.scss';
import AddTenant from './AddTenant';

interface ChannelInfoProps extends TabsBaseProps {
  tenant?: TenantBaseInfoResponse;
  disabled?: boolean;
}

export type BindTenantListProps = V2TenantGetChannelMcidPostResponse['list'];

const ChannelInfo: FC<ChannelInfoProps> = props => {
  const { onBreacrumbChange, tenant, updateFn, disabled = false } = props;
  const [openChannel, setOpenChannel] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<BindTenantListProps>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const routes = useMemo(() => {
    return [
      { name: '租户管理' },
      { name: '租户组织权限和账户' },
      { name: `${disabled ? '查看' : '编辑'}租户` },
      { name: '渠道端设置' },
    ];
  }, [disabled]);

  useEffect(() => {
    onBreacrumbChange && onBreacrumbChange(routes);
  }, [onBreacrumbChange, routes]);

  useEffect(() => {
    setOpenChannel(tenant?.tenantType === 2);
  }, [tenant]);

  const getChannelMcId = () => {
    setLoading(true);
    apiV2TenantGetChannelMcidPost({ tenantMcid: tenant?.mcid })
      .then(res => {
        setDataSource(res.list ?? []);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (openChannel) {
      getChannelMcId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openChannel]);

  const handleOpenChannel = () => {
    apiV2TenantOpenChannelPost({ mcid: tenant?.mcid }).then(() => {
      setOpenChannel(true);
      updateFn && updateFn();
    });
  };

  const handleDel = (channelLinkTableId: number) => {
    apiV2TenantDelChannelMcidPost({ ids: [channelLinkTableId] }).then(() => {
      message.success('删除成功');
      getChannelMcId();
    });
  };

  const columns = [
    {
      title: '租户Code',
      dataIndex: 'tenantCode',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '租户名称',
      dataIndex: 'name',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '操作',
      dataIndex: 'channelLinkTableId',
      render: (v: number) =>
        disabled ? (
          <Button type="link" disabled={disabled}>
            移除{' '}
          </Button>
        ) : (
          <PopConfirm title="是否移除此租户？" onConfirm={() => handleDel(v)}>
            <Button type="link">移除</Button>
          </PopConfirm>
        ),
    },
  ];

  const renderChannelTenant = () => (
    <div>
      <div className={styles.channelInfo}>
        <span className={styles.channelLabel}>当前租户:</span>
        {tenant?.name}
      </div>
      <Flex justify="space-between" align="center">
        <span className={styles.channelLabel}>可访问的租户</span>
        <Button type="primary" icon={<PlusOutlined />} onClick={() => setOpenModal(true)} disabled={disabled}>
          添加租户
        </Button>
      </Flex>
      <Table
        rowKey="channelLinkTableId"
        dataSource={dataSource}
        pagination={false}
        columns={columns}
        loading={loading}
        className={styles.channelTable}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <span>
                  暂未添加任何租户，请
                  <Button type="link" disabled={disabled} onClick={() => setOpenModal(true)}>
                    添加租户
                  </Button>
                </span>
              }
            />
          ),
        }}
      />
    </div>
  );

  const onOk = () => {
    setOpenModal(false);
    getChannelMcId();
  };

  return (
    <div className={styles.container}>
      {openChannel ? (
        renderChannelTenant()
      ) : (
        <Space size={16}>
          <PopConfirm title="是否升级为渠道端，升级为渠道端后可查看关联租户的数据。" onConfirm={handleOpenChannel}>
            <Button type="primary" disabled={disabled || !tenant?.accountOpenStatus}>
              升级为渠道端
            </Button>
          </PopConfirm>
          <div>
            <ExclamationCircleOutlined />
            <span className={styles.tip}>注意：升级为渠道端租户后，将能查看关联租户的数据，主要用于资产的场景！</span>
          </div>
        </Space>
      )}
      {openModal && (
        <AddTenant tenant={tenant} bindTenantList={dataSource} onCancel={() => setOpenModal(false)} onOk={onOk} />
      )}
    </div>
  );
};

export default ChannelInfo;
