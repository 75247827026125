import { OBJECT_TYPE, VALUE_TYPE, LENGTH, BIT_MODE, BYTE_NUM, BIT_NUM } from '../../../../types';

export const objectFormatter = {
  [OBJECT_TYPE.COILSTATUS]: '线圈状态[01]',
  [OBJECT_TYPE.INPUTSTATUS]: '输入状态[02]',
  [OBJECT_TYPE.SAVEREGISTER]: '保持寄存器[03]',
  [OBJECT_TYPE.INPUTREGISTER]: '输入寄存器[04]',
};

export const lengthFormatter = {
  [LENGTH.ONE]: '1',
  [LENGTH.TWO]: '2',
  [LENGTH.FOUR]: '4',
};

export const bitModeFormatter = {
  [BIT_MODE.NULL]: '无',
  [BIT_MODE.BIT]: '按位读取',
  [BIT_MODE.BYTE]: '按字节读取',
};

export const byteNumFormatter = {
  [BYTE_NUM.LOWBYTE]: '低字节',
  [BYTE_NUM.HIGHBYTE]: '高字节',
};

export const bitNumFormatter = {
  [BIT_NUM.BIT_0]: '操作位-0',
  [BIT_NUM.BIT_1]: '操作位-1',
  [BIT_NUM.BIT_2]: '操作位-2',
  [BIT_NUM.BIT_3]: '操作位-3',
  [BIT_NUM.BIT_4]: '操作位-4',
  [BIT_NUM.BIT_5]: '操作位-5',
  [BIT_NUM.BIT_6]: '操作位-6',
  [BIT_NUM.BIT_7]: '操作位-7',
  [BIT_NUM.BIT_8]: '操作位-8',
  [BIT_NUM.BIT_9]: '操作位-9',
  [BIT_NUM.BIT_10]: '操作位-10',
  [BIT_NUM.BIT_11]: '操作位-11',
  [BIT_NUM.BIT_12]: '操作位-12',
  [BIT_NUM.BIT_13]: '操作位-13',
  [BIT_NUM.BIT_14]: '操作位-14',
  [BIT_NUM.BIT_15]: '操作位-15',
};

export const valueTypeFormatter = {
  [VALUE_TYPE.BOOLEAN]: '布尔',
  [VALUE_TYPE.INTERGER]: '有符号整型数-原码',
  [VALUE_TYPE.COMPLEMENTINTERGER]: '有符号整型数-补码',
  [VALUE_TYPE.UNSIGNEDINTERGER]: '无符号整型数',
  [VALUE_TYPE.FLOAT]: '单精度浮点数',
  [VALUE_TYPE.DOBULE]: '双精度浮点数',
};

export const valueTypeFormatter1 = {
  [VALUE_TYPE.BOOLEAN]: valueTypeFormatter[VALUE_TYPE.BOOLEAN],
};

export const valueTypeFormatter2 = {
  [VALUE_TYPE.INTERGER]: valueTypeFormatter[VALUE_TYPE.INTERGER],
  [VALUE_TYPE.COMPLEMENTINTERGER]: valueTypeFormatter[VALUE_TYPE.COMPLEMENTINTERGER],
  [VALUE_TYPE.UNSIGNEDINTERGER]: valueTypeFormatter[VALUE_TYPE.UNSIGNEDINTERGER],
};

export const valueTypeFormatter3 = {
  [VALUE_TYPE.INTERGER]: valueTypeFormatter[VALUE_TYPE.INTERGER],
  [VALUE_TYPE.COMPLEMENTINTERGER]: valueTypeFormatter[VALUE_TYPE.COMPLEMENTINTERGER],
  [VALUE_TYPE.UNSIGNEDINTERGER]: valueTypeFormatter[VALUE_TYPE.UNSIGNEDINTERGER],
  [VALUE_TYPE.FLOAT]: valueTypeFormatter[VALUE_TYPE.FLOAT],
};

export const valueTypeFormatter4 = {
  [VALUE_TYPE.INTERGER]: valueTypeFormatter[VALUE_TYPE.INTERGER],
  [VALUE_TYPE.COMPLEMENTINTERGER]: valueTypeFormatter[VALUE_TYPE.COMPLEMENTINTERGER],
  [VALUE_TYPE.UNSIGNEDINTERGER]: valueTypeFormatter[VALUE_TYPE.UNSIGNEDINTERGER],
  [VALUE_TYPE.FLOAT]: valueTypeFormatter[VALUE_TYPE.FLOAT],
  [VALUE_TYPE.DOBULE]: valueTypeFormatter[VALUE_TYPE.DOBULE],
};

export const handleModeFormatter1 = {
  0: '1,2',
  1: '2,1',
};

export const handleModeFormatter2 = {
  0: '1,2,3,4',
  1: '2,1,4,3',
  2: '3,4,1,2',
  3: '4,3,2,1',
};

export const handleModeFormatter3 = {
  0: '1,2,3,4,5,6,7,8',
  1: '2,1,4,3,6,5,8,7',
  2: '3,4,1,2,7,8,5,6',
  3: '4,3,2,1,8,7,6,5',
  4: '5,6,7,8,1,2,3,4',
  5: '6,5,8,7,2,1,4,3',
  6: '7,8,5,6,3,4,1,2',
  7: '8,7,6,5,4,3,2,1',
};

export const modeOptions = [
  bitModeFormatter[BIT_MODE.NULL],
  byteNumFormatter[BYTE_NUM.LOWBYTE],
  byteNumFormatter[BYTE_NUM.HIGHBYTE],
  ...Object.values(bitNumFormatter),
];
/**
 * 乘积系数k和偏移系数b校验用
 */
export const checkNumCoefficient = (_: unknown, value: string) => {
  if (value) {
    const num = Number(value);
    if (isNaN(num)) {
      return Promise.reject(new Error('请输入数字'));
    }
    if (num > 1e9 - 1 || num < -(1e9 - 1)) {
      return Promise.reject(new Error('请输入-999999999~999999999的数字'));
    }
  }
  return Promise.resolve();
};

export const checkNum = (_: unknown, value: string) => {
  if (value) {
    const num = Number(value);
    if (isNaN(num)) {
      return Promise.reject(new Error('请输入数字'));
    }
    if (num > 1e9 - 1 || num < -(1e9 - 1)) {
      return Promise.reject(new Error('请输入-999999999~999999999的数字'));
    } else if (((num + '').split('.')[1] ?? []).length > 7) {
      return Promise.reject(new Error('最多输入7位小数'));
    }
  }
  return Promise.resolve();
};

export const limitedIntegerValidatorBuilder =
  (max: number, min = 1) =>
  (_: any, value: number | string) => {
    const num = Number(value);
    const isOk = num >= min && num <= max;
    const isFloat = String(num).indexOf('.') >= 0 ? true : false;
    if ((!isOk || isFloat) && value !== undefined) {
      return Promise.reject(new Error(`输入${min}~${max}内的整数`));
    }
    return Promise.resolve();
  };

export const identifierValidatorBuilder = (usedIdentifier?: string[]) => (rule: any, value: string) => {
  if (!value) {
    return Promise.reject('请填写非建模采集点标识符');
  }
  if (value.length > 30) {
    return Promise.reject('长度不能超过30个字符');
  }
  if (!/^[a-zA-Z]/.test(value)) {
    return Promise.reject(new Error('开头必须是英文字母，请修改'));
  }
  if (!/^[a-zA-Z][a-zA-Z0-9]*$/.test(value)) {
    return Promise.reject(new Error('只能输入英文和数字'));
  }
  if (usedIdentifier?.includes(value)) {
    return Promise.reject(new Error('标识符重复，请修改'));
  }
  return Promise.resolve();
};
