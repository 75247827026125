import { CSSProperties, useCallback, useMemo, useState, FC, useEffect } from 'react';
import { deleteRuleSet, disableRuleSet, enableRuleSet, getRuleSets } from '../../api/mockRule-set';
import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Descriptions,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { listDataTimeRules, listDataValueRules, listUploadTimeRules } from '../../api/mockRule';
import { PointSetV2, Rule, RuleSet, Tenant } from '../../api/entities';
import { Link, useNavigate } from 'react-router-dom';
import { RuleType } from '../../api/constants';
import { CopyOutlined, EditOutlined, MoreOutlined, SearchOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import { getPointSetByRuleSetIdV2 } from '../../api/mockPoint-set';
import PointSetModal from './PointSetModal';
import Code from '../../components/Code';
import { useHasPermission } from '../../utils/utils';
import { PermissionsType } from '../../common/permissionsConst';
import { usePaging, Wrapper, Filter, Paging, useAsync } from '@maxtropy/components';
import { getRoot } from '../../api/mockCustomer';
const { useForm } = Form;

const { Text, Title } = Typography;

const RuleBrief: FC<{ type: RuleType; rule?: Rule; relatedRuleId: number }> = ({ type, rule, relatedRuleId }) =>
  rule ? (
    <>
      <Link to={`/data-tools/shaping/${type}/${relatedRuleId}/update`}>{rule.name}</Link>
      <br />
      <Code>{rule.expression}</Code>
    </>
  ) : (
    <Tag color="error">未找到规则</Tag>
  );

interface DataExportFormData {
  start?: Dayjs;
  end?: Dayjs;
  limit?: number;
}

export const DataExport: FC<{ ruleSetId: number }> = ({ ruleSetId }) => {
  const [form] = useForm<DataExportFormData>();
  const onFinish = (data: DataExportFormData) => {
    window.location.assign(
      `/api/rule-set/${ruleSetId}/export/excel?` +
        `start=${data.start?.millisecond(0).valueOf() || ''}&end=${data.end?.millisecond(0).valueOf() || ''}&limit=${
          data.limit || ''
        }`
    );
  };
  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={{ limit: 1440, start: dayjs().startOf('date') }}
    >
      <Alert
        message="由于实际生成数据时的调度延迟等原因，预览数据和实际生成数据不会完全一致，仅供参考"
        type="warning"
      />
      <Form.Item name="start" label="开始时间">
        <DatePicker showTime placeholder="默认为当前时间" />
      </Form.Item>
      <Form.Item name="end" label="结束时间">
        <DatePicker showTime />
      </Form.Item>
      <Form.Item name="limit" label="最多导出条数">
        <InputNumber precision={0} min={1} />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          导出
        </Button>
      </Form.Item>
    </Form>
  );
};

interface RuleSetCardProps {
  ruleSet: RuleSet;
  dataTimeRule?: Rule;
  uploadTimeRule?: Rule;
  dataValueRule?: Rule;
  doReloadRuleSets: () => void;
  tenantName?: string;
}

const tableCellStyle: CSSProperties = {
  border: '1px solid #ccc',
  padding: '10px 5px',
};

// prettier-ignore
export const renderPointKey = (pointSet: PointSetV2, tenantMcid: string) => {// NOSONAR
  return (
    <>
      <div>
        {pointSet.devices.length ? (
          pointSet.devices.length > 3 ? (
            <Tooltip title={pointSet.devices.map(d => d.name).join('、')}>
              <Typography.Link>{pointSet.devices[0].name}</Typography.Link>等{pointSet.devices.length}
              个设备
            </Tooltip>
          ) : (
            pointSet.devices.map((d, i, a) => (
              <>
                <Typography.Link
                  key={d.id}
                  href={`/data/history/device?tenantMcid=${tenantMcid}&deviceId=${d.id}`}
                  target="_blank"
                >
                  {d.name}
                </Typography.Link>
                {i !== a.length - 1 ? '、' : ''}
              </>
            ))
          )
        ) : null}
      </div>
      <div>
        {pointSet.dataPropertys.length ? (
          pointSet.dataPropertys.length > 3 ? (
            <Tooltip title={pointSet.dataPropertys.map(p => p.name).join('、')}>
              <Typography.Link>{pointSet.dataPropertys[0].name}</Typography.Link>等{pointSet.dataPropertys.length}
              个数据属性
            </Tooltip>
          ) : (
            pointSet.dataPropertys.map((p, i, a) => (
              <>
                <Typography.Link key={p.id}>{p.name}</Typography.Link>
                {i !== a.length - 1 ? '、' : ''}
              </>
            ))
          )
        ) : null}
      </div>
      <div>
        {pointSet.nonModelingPointNames.length ? (
          pointSet.nonModelingPointNames.length > 3 ? (
            <Tooltip title={pointSet.nonModelingPointNames.join('、')}>
              <Typography.Link>{pointSet.nonModelingPointNames[0]}</Typography.Link>等
              {pointSet.nonModelingPointNames.length}个数据属性
            </Tooltip>
          ) : (
            pointSet.nonModelingPointNames.map((n, i, a) => (
              <>
                <Typography.Link key={i}>{n}</Typography.Link>
                {i !== a.length - 1 ? '、' : ''}
              </>
            ))
          )
        ) : null}
      </div>
    </>
  );
};

const RuleSetCard: FC<RuleSetCardProps> = props => {
  const [modalApi, modalContextHolder] = Modal.useModal();
  const { ruleSet, dataTimeRule, uploadTimeRule, dataValueRule, doReloadRuleSets, tenantName } = props;

  const navigate = useNavigate();
  const [counter, setCounter] = useState(0);
  const [pointSets, setPointSets] = useState<PointSetV2[]>([]);
  const hasInvalidComputeOnce = useMemo(() => {
    return ruleSet.computeOnce && pointSets.some(ps => !ps.computeOnce);
  }, [pointSets]);
  const toggle = useCallback(
    (id: number, checked: boolean) => (checked ? enableRuleSet(id) : disableRuleSet(id)).then(doReloadRuleSets),
    [doReloadRuleSets]
  );
  const doDelete = useCallback(
    (id: number, name: string) => {
      modalApi.confirm({
        content: `确定删除规则：${name} 吗？`,
        onOk: () => deleteRuleSet(id).then(doReloadRuleSets),
      });
    },
    [doReloadRuleSets]
  );
  const [showPointSetModal, setShowPointSetModal] = useState(false);
  const doEditPointSets = useCallback(() => setShowPointSetModal(true), []);
  const onFinishEditPointSets = useCallback(() => {
    setShowPointSetModal(false);
    setCounter(i => i + 1);
  }, []);

  useEffect(() => {
    getPointSetByRuleSetIdV2(ruleSet.id, ruleSet.tenantMcid).then(res => {
      setPointSets(res);
    });
  }, [counter, ruleSet.id, ruleSet.tenantMcid]);

  return (
    <>
      <Card
        title={
          <>
            <div>
              <small>
                <strong>{tenantName}</strong>
              </small>
            </div>
            {ruleSet.name}&emsp;
            {ruleSet.computeOnce ? (
              <Tag color="lime">各数据点统一计算</Tag>
            ) : (
              <Tag color="geekblue">各数据点分别计算</Tag>
            )}
          </>
        }
        extra={
          <>
            {useHasPermission(PermissionsType.B_ENABLE_RULE_CONFIGURATION) && (
              <>
                <Switch size="small" checked={ruleSet.enabled} onClick={checked => toggle(ruleSet.id, checked)} />
                &emsp;
              </>
            )}
            {useHasPermission(PermissionsType.P_RULE_CONFIGURATION) && (
              <>
                &ensp;
                <Button
                  type="link"
                  icon={<CopyOutlined />}
                  size="small"
                  onClick={() => navigate(`/data-tools/shaping/rule-set/create?copyFrom=${ruleSet.id}`)}
                />
              </>
            )}
            {useHasPermission(PermissionsType.B_EDIT_RULE_CONFIGURATION) && (
              <>
                &ensp;
                <Button
                  type="link"
                  icon={<EditOutlined />}
                  size="small"
                  onClick={() => navigate(`/data-tools/shaping/rule-set/${ruleSet.id}/update`)}
                />
              </>
            )}
            &ensp;
            <Dropdown
              overlay={
                <Menu
                  items={[
                    {
                      label: (
                        <span
                          onClick={() => modalApi.info({ icon: null, content: <DataExport ruleSetId={ruleSet.id} /> })}
                        >
                          预览数据
                        </span>
                      ),
                      key: 'item-1',
                    },
                    ...(useHasPermission(PermissionsType.B_DELETE_RULE_CONFIGURATION)
                      ? [{ label: <span onClick={() => doDelete(ruleSet.id, ruleSet.name)}>删除</span>, key: 'item-2' }]
                      : []),
                  ]}
                />
              }
            >
              <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                <MoreOutlined />
              </a>
            </Dropdown>
          </>
        }
      >
        <p>{ruleSet.description}</p>
        <Descriptions bordered column={2}>
          <Descriptions.Item label="上传时间规则" labelStyle={{ width: 140 }}>
            <RuleBrief type={RuleType.UPLOAD_TIME} rule={uploadTimeRule} relatedRuleId={ruleSet.uploadTimeRuleId} />
          </Descriptions.Item>
          <Descriptions.Item label="上传时间规则参数" labelStyle={{ width: 170 }}>
            {uploadTimeRule && ruleSet.uploadTimeRuleConstants.length !== uploadTimeRule.constants && (
              <Tag color="error">参数数量不匹配</Tag>
            )}
            {ruleSet.uploadTimeRuleConstants.map((n, i) => (
              <Tag key={i}>
                c{i + 1}={n}
              </Tag>
            ))}
          </Descriptions.Item>
          <Descriptions.Item label="数据时间规则">
            <RuleBrief type={RuleType.DATA_TIME} rule={dataTimeRule} relatedRuleId={ruleSet.dataTimeRuleId} />
          </Descriptions.Item>
          <Descriptions.Item label="数据时间规则参数">
            {dataTimeRule && ruleSet.dataTimeRuleConstants.length !== dataTimeRule.constants && (
              <Tag color="error">参数数量不匹配</Tag>
            )}
            {ruleSet.dataTimeRuleConstants.map((n, i) => (
              <Tag color="purple" key={i}>
                c{i + 1}={n}
              </Tag>
            ))}
          </Descriptions.Item>
          <Descriptions.Item label="数据值规则">
            <RuleBrief type={RuleType.DATA_VALUE} rule={dataValueRule} relatedRuleId={ruleSet.dataValueRuleId} />
          </Descriptions.Item>
          <Descriptions.Item label="数据值规则参数">
            {dataValueRule && ruleSet.dataValueRuleConstants.length !== dataValueRule.constants && (
              <Tag color="error">参数数量不匹配</Tag>
            )}
            {ruleSet.dataValueRuleConstants.map((n, i) => (
              <Tag color="purple" key={i}>
                c{i + 1}={n}
              </Tag>
            ))}
          </Descriptions.Item>
          {ruleSet.pointKeys.length > 0 && (
            <Descriptions.Item label="数据点(legacy)" span={2}>
              {ruleSet.pointKeys.map((k, i) => (
                <>
                  <Tag key={i}>{k}</Tag>&ensp;
                </>
              ))}
            </Descriptions.Item>
          )}
          <Descriptions.Item
            span={2}
            label={
              <span style={{ position: 'sticky', top: 100, bottom: 100 }}>
                数据点
                {useHasPermission(PermissionsType.B_EDIT_DTA_POINTS) && (
                  <Button type="link" icon={<EditOutlined />} size="small" onClick={doEditPointSets} />
                )}
              </span>
            }
          >
            <table>
              <thead>
                <tr>
                  <th style={tableCellStyle}>数据点Key</th>
                  <th style={tableCellStyle}>数据时间规则参数覆盖</th>
                  <th style={tableCellStyle}>数据值规则参数覆盖</th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <td colSpan={3}>
                    {pointSets.length === 0 && <p>暂无数据</p>}
                    {hasInvalidComputeOnce && (
                      <p>
                        <Text type="danger">规则集为统一计算时，数据点集的分别计算将不起作用</Text>
                      </p>
                    )}
                  </td>
                </tr>
              </tfoot>
              <tbody>
                {pointSets.map(ps => (
                  <tr key={ps.id}>
                    <td style={tableCellStyle}>
                      {ps.computeOnce ? <Tag color="lime">统一计算</Tag> : <Tag color="geekblue">分别计算</Tag>}
                      {renderPointKey(ps, ruleSet.tenantMcid)}
                    </td>
                    <td style={tableCellStyle}>
                      {ps.dataTimeRuleConstants.map((v, i) => (
                        <Tag color="purple" key={i}>
                          c{i + 1}={v}
                        </Tag>
                      ))}
                    </td>
                    <td style={tableCellStyle}>
                      {ps.dataValueRuleConstants.map((v, i) => (
                        <Tag color="purple" key={i}>
                          c{i + 1}={v}
                        </Tag>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Descriptions.Item>
        </Descriptions>
      </Card>
      <Modal
        destroyOnClose={true}
        footer={null}
        style={{ top: 50 }}
        bodyStyle={{ overflowY: 'auto', minHeight: 300, maxHeight: 700 }}
        width="80vw"
        open={showPointSetModal}
        onCancel={onFinishEditPointSets}
      >
        <PointSetModal ruleSetId={ruleSet.id} tenantMcid={ruleSet.tenantMcid} />
      </Modal>
      {modalContextHolder}
    </>
  );
};

const routes = [{ name: '数据运营工具' }, { name: '数据模拟' }, { name: '规则集配置' }];

type FilterParams = {
  ruleSetName: string;
  tenantMcid: string;
};

const RuleSets: FC = () => {
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [counter, setCounter] = useState(0);
  const navigate = useNavigate();
  const [ruleSets, setRuleSets] = useState<RuleSet[]>([]);
  const [searchParams, setSearchParams] = useState<FilterParams>({} as FilterParams);
  const [loading, setLoading] = useState<boolean>(false);
  const uploadTimeRules = useAsync(listUploadTimeRules, []);
  const dataTimeRules = useAsync(listDataTimeRules, []);
  const dataValueRules = useAsync(listDataValueRules, []);
  const doReloadRuleSets = useCallback(() => setCounter(i => i + 1), []);
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [searchForm] = useForm();

  useEffect(() => {
    getRoot({}).then(res => {
      setTenants(res);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    getRuleSets({ ...searchParams, page: pageOffset, size: pageSize })
      .then(res => {
        if (res) {
          setRuleSets(res.list);
          setTotalCount(res.total);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pageOffset, pageSize, searchParams, setTotalCount, counter]);

  const filters = (
    <Filter<FilterParams>
      form={searchForm}
      onFinish={values => {
        setPageOffset(1);
        setSearchParams(values);
      }}
      onReset={() => {
        setPageOffset(1);
        setSearchParams({} as FilterParams);
      }}
      hasClear
    >
      <>
        <Col span={6}>
          <Form.Item name="name" label="名称">
            <Input prefix={<SearchOutlined />} placeholder="请输入规则集名称" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="tenantMcid" label="所属租户">
            <Select
              showSearch
              placeholder="请选择租户"
              optionFilterProp="label"
              options={tenants.map(v => ({ label: v.name, value: v.mcid }))}
            />
          </Form.Item>
        </Col>
      </>
    </Filter>
  );

  return (
    <Wrapper routes={routes} filters={filters}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
        <Title level={2}>规则集列表</Title>
        <Row gutter={10}>
          {useHasPermission(PermissionsType.B_CREATE_RULE_CONFIGURATION) && (
            <Col span={3}>
              <Button type="primary" onClick={() => navigate('/data-tools/shaping/rule-set/create')}>
                创建规则集
              </Button>
            </Col>
          )}
        </Row>
        <Spin spinning={loading}>
          {ruleSets.map(ruleSet => {
            const dataTimeRule = dataTimeRules.find(r => r.id === ruleSet.dataTimeRuleId);
            const uploadTimeRule = uploadTimeRules.find(r => r.id === ruleSet.uploadTimeRuleId);
            const dataValueRule = dataValueRules.find(r => r.id === ruleSet.dataValueRuleId);
            return (
              <RuleSetCard
                tenantName={tenants.find(v => v.mcid === ruleSet.tenantMcid)?.name}
                key={ruleSet.id}
                ruleSet={ruleSet}
                dataTimeRule={dataTimeRule}
                uploadTimeRule={uploadTimeRule}
                dataValueRule={dataValueRule}
                doReloadRuleSets={doReloadRuleSets}
              />
            );
          })}
        </Spin>
        <Paging pagingInfo={pagingInfo} />
      </div>
    </Wrapper>
  );
};

export default RuleSets;
