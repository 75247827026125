import { FC, useEffect, useState } from 'react';
import { Modal } from 'antd';
import SelectRuleGroupModalChildren from './ModalChildren';
import { getDeviceRuleGroupId, updateDeviceRuleGroupId, unBindDeviceRuleGroup } from '../../../../api/ruleGroup';
import { getEdgeDeviceInfoSimple, EdgeDeviceDetailSimple } from '../../../../api/edgeDevice';
import { Device } from '../../../../api/device';
import styles from './index.module.scss';

interface SelectRuleGroupModalProps {
  device?: Device;
  onClose: () => void;
  updateFn?: () => void;
  visible?: boolean;
  physicalModelId?: number;
}

const SelectRuleGroupModal: FC<SelectRuleGroupModalProps> = ({
  device,
  onClose,
  updateFn,
  visible = false,
  physicalModelId,
}) => {
  const [ruleGroupId, setRuleGroupId] = useState<number>();
  const [info, setInfo] = useState<EdgeDeviceDetailSimple>();
  const [modalApi, modalContextHolder] = Modal.useModal();

  useEffect(() => {
    if (device) {
      getDeviceRuleGroupId(device.id.toString()).then(res => {
        if (res) {
          setRuleGroupId(res.ruleGroupId);
        }
      });
    } else {
      setInfo(undefined);
    }
  }, [device]);

  useEffect(() => {
    if (device) {
      getEdgeDeviceInfoSimple(device.id.toString()).then(res => {
        if (res) {
          setInfo(res);
        }
      });
    }
  }, [device]);

  const onOk = () => {
    if (ruleGroupId && device) {
      updateDeviceRuleGroupId(
        {
          deviceId: device.id,
          ruleGroupId,
        },
        device.rootMcid
      ).then(res => {
        if (res) {
          onClose();
          updateFn?.();
        }
      });
    }
  };

  const unBind = () => {
    if (ruleGroupId && device) {
      modalApi.confirm({
        title: '确定解绑？',
        content: null,
        okText: '继续',
        onOk: () => {
          unBindDeviceRuleGroup(device.id).then(res => {
            if (res) {
              onClose();
              updateFn?.();
            }
          });
        },
      });
    }
  };

  return (
    <>
      <Modal
        className={styles.modal}
        title="规则组选择"
        open={visible}
        destroyOnClose
        onOk={onOk}
        onCancel={onClose}
        width={800}
      >
        {device && info && (
          <SelectRuleGroupModalChildren
            value={ruleGroupId}
            onChange={setRuleGroupId}
            unBind={unBind}
            iotProtocol={info.iotProtocol}
            deviceTypeId={info.deviceTypeId}
            rootMcid={device.rootMcid}
            deviceId={device.id}
            physicalModelId={physicalModelId}
          />
        )}
      </Modal>
      {modalContextHolder}
    </>
  );
};

export default SelectRuleGroupModal;
