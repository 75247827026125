import { CopyOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, message } from 'antd';
import React, { FC, useMemo, useState } from 'react';

interface IAppSecret {
  value?: string;
}

const AppSecret: FC<IAppSecret> = props => {
  const { value } = props;
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const appSecretNumber = useMemo(() => {
    let temp: string[] = [];
    [...new Array(value?.length)].forEach(_ => {
      temp.push('*');
    });
    return temp;
  }, [value]);

  return (
    <div>
      {contextHolder}
      {value ? (
        <>
          <span>{passwordVisible ? value : appSecretNumber}</span>
          <Button
            type="link"
            icon={passwordVisible ? <EyeInvisibleOutlined /> : <EyeTwoTone />}
            style={{ padding: 0, paddingLeft: 6 }}
            onClick={() => {
              setPasswordVisible(prevState => !prevState);
            }}
          />
          <Button
            type="link"
            icon={<CopyOutlined />}
            onClick={() => {
              window.navigator.clipboard.writeText(value ?? '');
              messageApi.success('复制成功');
            }}
          />
        </>
      ) : (
        '--'
      )}
    </div>
  );
};

export default AppSecret;
