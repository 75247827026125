import { Form, Input, Modal, Radio, Select, Space } from 'antd';
import React, { useContext, useMemo, useState } from 'react';
import { identifierValidatorBuilder } from '../../EdgeDevicePointInfo/mockingbird/Point/entity';
import { isNil } from 'lodash-es';
import { DataPointType, DataProperty, DataPropertyType } from '../../../types';
import { PointContext } from '../../EdgeDevicePointInfo/contextTypes';
import { Button } from '@maxtropy/components';

const formLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 13 },
};

interface AddIdentifierProps {
  usedIdentifier: string[];
  dataProperties: DataProperty[];
  submit?: (values: any, shouldContinue?: boolean) => void;
}

const AddDeletePoint: React.FC<AddIdentifierProps> = props => {
  const { usedIdentifier, dataProperties, submit } = props;
  const [form] = Form.useForm();
  const { row, setRow } = useContext(PointContext);

  const pointName = useMemo(() => {
    let name = '';
    switch ((row as any)?.pointType) {
      case DataPointType.BASE_POINT:
        name = '采集点';
        break;
      case DataPointType.VIRTUAL_POINT:
        name = '虚拟点';
        break;
      case DataPointType.STATIC_POINT:
        name = '静态点';
        break;
    }
    return name;
  }, [row]);

  const onCancel = () => {
    form.resetFields();
    setRow?.(undefined);
  };

  const onOk = (shouldContinue?: boolean) => {
    form
      .validateFields()
      .then(values => {
        submit?.(values, shouldContinue);
        form.resetFields();
      })
      .catch(console.error);
  };

  return (
    <>
      <Modal
        width={600}
        open={!!row}
        title="添加"
        centered={true}
        maskClosable={false}
        onCancel={onCancel}
        footer={
          <Space>
            <>
              <Button type="primary" onClick={() => onOk(true)}>
                确定并继续添加
              </Button>
              <Button type="primary" onClick={() => onOk()}>
                确定并完成
              </Button>
            </>
            <Button type="default" onClick={onCancel}>
              取消
            </Button>
          </Space>
        }
      >
        <Form form={form} {...formLayout} initialValues={row}>
          <Form.Item noStyle name="pointType" />
          <Form.Item noStyle name="dataPropertyName" />

          {(row as any)?.pointType !== DataPointType.STATIC_POINT && (
            <Form.Item
              name="hasProperty"
              label={`${pointName}类型`}
              rules={[{ required: true, message: `请选择${pointName}类型` }]}
              initialValue={true}
            >
              <Radio.Group
                onChange={() => {
                  form.setFieldsValue({
                    dataPropertyId: undefined,
                    dataPropertyName: '',
                    physicalUnitId: undefined,
                    physicalUnitGeneralName: '',
                    identifier: '',
                  });
                }}
              >
                <Radio value={true}>{`建模${pointName}`}</Radio>
                <Radio value={false}>{`非建${pointName}`}</Radio>
              </Radio.Group>
            </Form.Item>
          )}

          <Form.Item noStyle shouldUpdate>
            {({ getFieldsValue }) => {
              const { hasProperty, pointType } = getFieldsValue();
              return hasProperty || pointType === DataPointType.STATIC_POINT ? (
                <Form.Item
                  name="dataPropertyId"
                  label="数据属性"
                  rules={[{ required: true, message: '请选择数据属性' }]}
                >
                  <Select
                    onChange={value => {
                      form.setFieldsValue({
                        dataPropertyName: dataProperties?.find(item => item.id === value)?.name,
                      });
                    }}
                    placeholder="请输入/选择"
                    showSearch
                    filterOption={(input, option) =>
                      (option!.children as unknown as string).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataProperties?.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <Form.Item
                  name="identifier"
                  label={`非建模${pointName}标识符`}
                  validateFirst
                  required
                  rules={[
                    {
                      validator: identifierValidatorBuilder(usedIdentifier),
                    },
                  ]}
                >
                  <Input placeholder="请输入" />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddDeletePoint;
