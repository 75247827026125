import { fetch } from '@maxtropy/components';

export interface DeviceTypesProps {
  allowAttribute: boolean;
  children: DeviceTypesProps[];
  id: number;
  name: string;
  parentId?: number;
  parentName?: string;
  rootId: number;
  sceneNames?: string;
  key: string;
}

export interface TreeProps {
  deviceTypes: DeviceTypesProps[];
  id: number;
  name: string;
  key: string;
}

export interface TypeTreeProps {
  aliasName: string[];
  tree: TreeProps[];
}

export function getTypeTree(): Promise<TypeTreeProps> {
  return fetch(`/api/deviceType/tree`);
}

export enum TemplateType {
  VALUE = 1, // 数值型
  PARSE, // 解析型
}
// 属性类型
export enum PropertyType {
  INSTANCE = 2, // 瞬时
  ACCUMULATE = 1, // 累计
}
export const propertyDisplay = {
  [PropertyType.INSTANCE]: '瞬时值',
  [PropertyType.ACCUMULATE]: '累积值',
};
export enum TemplateStatus {
  ALL = '',
  PUBLISH = '1',
  DRAFT = '2',
}
export const templateStatusDisplay = {
  [TemplateStatus.ALL]: '全部',
  [TemplateStatus.PUBLISH]: '已发布',
  [TemplateStatus.DRAFT]: '草稿',
};
export const templateTypeDisplay = {
  [TemplateType.VALUE]: '数值型',
  // [TemplateType.PARSE]: '解析型',
};
export enum RulesStatus {
  DRAFT = 1,
  ENABLE = 2,
  STOP = 3,
}
export enum RulesGroupStatus {
  ALL = '',
  DRAFT = '1',
  ENABLE = '2',
  STOP = '3',
}
export const rulesGroupStatusDisplay = {
  [RulesGroupStatus.ALL]: '全部',
  [RulesGroupStatus.ENABLE]: '启用',
  [RulesGroupStatus.STOP]: '停用',
  [RulesGroupStatus.DRAFT]: '草稿',
};
export enum DataPointPeriod {
  DAY = 1,
  WEEK,
  YEAR,
}
export const dataPointPeriodDisplay = {
  [DataPointPeriod.DAY]: '1天',
  [DataPointPeriod.WEEK]: '1周',
  [DataPointPeriod.YEAR]: '1年',
};

export enum DataPointInterval {
  MINUTE_1 = 1,
  MINUTE_15 = 2,
  MINUTE_30 = 3,
  MINUTE_1H = 4,
  MINUTE_2H = 5,
  MINUTE_6H = 6,
  MINUTE_12H = 7,
  MINUTE_24H = 8,
}

export const dataPointIntervalDisplay = {
  [DataPointInterval.MINUTE_1]: '1分钟',
  [DataPointInterval.MINUTE_15]: '15分钟',
  [DataPointInterval.MINUTE_30]: '30分钟',
  [DataPointInterval.MINUTE_1H]: '1小时',
  [DataPointInterval.MINUTE_2H]: '3小时',
  [DataPointInterval.MINUTE_6H]: '6小时',
  [DataPointInterval.MINUTE_12H]: '12小时',
  [DataPointInterval.MINUTE_24H]: '24小时',
};

export enum FieldType {
  TEXT = 1, // 文本
  OBJECT, // 引用值
  FUNCTION, // 函数
}

export const fieldTypeDisplay = {
  [FieldType.TEXT]: '文本',
  [FieldType.OBJECT]: '引用值',
  [FieldType.FUNCTION]: '函数',
};

export enum DataSupplementStatusType {
  PROFRESS = 1, // 进行中
  FINISHED = 2, // 已完成
  FAILED = 3, // 失败
}

export const DataSupplementStatusTypeDisplay = {
  [DataSupplementStatusType.PROFRESS]: '进行中',
  [DataSupplementStatusType.FINISHED]: '已完成',
  [DataSupplementStatusType.FAILED]: '失败',
};
