export enum Gender {
  MALE = 0,
  FEMALE = 1,
}

export const GenderDisplay = {
  [Gender.MALE]: '男',
  [Gender.FEMALE]: '女',
};

export enum UserStatus {
  ENABLE = 0,
  DISABLE = 1,
  FREEZE = 2,
}

export const UserStatusDisplay = {
  [UserStatus.ENABLE]: '启用',
  [UserStatus.DISABLE]: '禁用',
  [UserStatus.FREEZE]: '冻结',
};

export enum TenantStatus {
  OPENACCOUNT = 1,
  NOACCOUNT = 0,
}

export const TenantStatusDisplay = {
  [TenantStatus.OPENACCOUNT]: '开户',
  [TenantStatus.NOACCOUNT]: '未开户',
};

export enum RoleStatus {
  ENABLE = 0,
  FREEZE = 1,
  INVALID = 2,
}

export const RoleStatusDisplay = {
  [RoleStatus.ENABLE]: '启用',
  [RoleStatus.FREEZE]: '冻结',
  [RoleStatus.INVALID]: '作废',
};

export enum RoleType {
  GENERAL, //通用
  SPECIALPURPOSE, //专用
}

export const RoleTypeDisplay = {
  [RoleType.GENERAL]: '通用',
  [RoleType.SPECIALPURPOSE]: '专用',
};

export enum PermissionType {
  MENU = 'u',
  BUTTON = 'b',
}

export const PermissionTypeDisplay = {
  [PermissionType.MENU]: '菜单',
  [PermissionType.BUTTON]: '按钮',
};

export enum HomePageType {
  DEFAULT1 = 1,
  DEFAULT2 = 2,
  CUSTOM = 0,
}

export const HomePageTypeDisplay = {
  [HomePageType.DEFAULT1]: '默认首页图1',
  [HomePageType.DEFAULT2]: '默认首页图2',
  [HomePageType.CUSTOM]: '自定义',
};

export const isSetHomePageOptions = [
  { label: HomePageTypeDisplay[HomePageType.DEFAULT1], value: HomePageType.DEFAULT1 },
  { label: HomePageTypeDisplay[HomePageType.DEFAULT2], value: HomePageType.DEFAULT2 },
  { label: HomePageTypeDisplay[HomePageType.CUSTOM], value: HomePageType.CUSTOM },
];

export enum TitleType {
  PLATFORM = 1,
  NAVIGATE = 2,
  PLATFORMNAVIGATE = 3,
}

export const TitleTypeDisplay = {
  [TitleType.PLATFORM]: '平台名称',
  [TitleType.NAVIGATE]: '导航名称',
  [TitleType.PLATFORMNAVIGATE]: '平台名称-导航名称',
};

export const titleOptions = [
  { label: TitleTypeDisplay[TitleType.PLATFORM], value: TitleType.PLATFORM },
  { label: TitleTypeDisplay[TitleType.NAVIGATE], value: TitleType.NAVIGATE },
  { label: TitleTypeDisplay[TitleType.PLATFORMNAVIGATE], value: TitleType.PLATFORMNAVIGATE },
];

export enum LeftNavPreference {
  OPEN_CLOSE = 1,
  ALL_OPEN,
  ALL_CLOSE,
}

export const LeftNavPreferenceDisplay = {
  [LeftNavPreference.OPEN_CLOSE]: '收起/展开',
  [LeftNavPreference.ALL_OPEN]: '一直展开',
  [LeftNavPreference.ALL_CLOSE]: '直接隐藏',
};
