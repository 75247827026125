import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Descriptions, Skeleton } from 'antd';
import DescriptionsItem from 'antd/es/descriptions/Item';
import { Wrapper } from '@maxtropy/components';
import styles from './index.module.scss';

import { getStaffInfo, StaffResponse } from '../../../../api/cc-staff';
import { UserStatusDisplay } from '../../../../api/cc-const';

const routes = [
  { name: '租户管理' },
  { name: '租户组织权限和账户' },
  { name: '租户员工工号管理', path: '/tenant/customer/employee' },
  { name: '员工详情' },
];

const TenantEmployeeDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [staffInfo, setStaffInfo] = useState<StaffResponse>();

  useEffect(() => {
    setLoading(true);
    getStaffInfo(Number(id))
      .then(res => {
        setStaffInfo(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  return (
    <Wrapper className={styles.container} routes={routes}>
      <Skeleton active={loading} loading={loading} paragraph={{ rows: 5 }} className={styles.description}>
        <Descriptions className={styles.description}>
          {staffInfo && (
            <>
              <DescriptionsItem label="用户ID">{staffInfo.customerUserHumanCode}</DescriptionsItem>
              <DescriptionsItem label="用户登录账号">{staffInfo.customerUserUsername}</DescriptionsItem>
              <DescriptionsItem label="手机号码">{staffInfo.customerUserCellphone}</DescriptionsItem>
              <DescriptionsItem label="用户姓名">{staffInfo.customerUserName}</DescriptionsItem>
              <DescriptionsItem label="用户状态">{UserStatusDisplay[staffInfo.customerUserStatus]}</DescriptionsItem>
              <DescriptionsItem label="员工工号">{staffInfo.staffCode}</DescriptionsItem>
              <DescriptionsItem label="工号状态">{UserStatusDisplay[staffInfo.status]}</DescriptionsItem>
              <DescriptionsItem label="所在组织简称">{staffInfo.currentCustomerName}</DescriptionsItem>
              <DescriptionsItem label="所在组织全称">{staffInfo.currentCustomerFullName}</DescriptionsItem>
              <DescriptionsItem label="组织ID">{staffInfo.currentCustomerCode}</DescriptionsItem>
              <DescriptionsItem label="上级组织">{staffInfo.parentCustomerFullName}</DescriptionsItem>
              <DescriptionsItem label="上级组织ID">{staffInfo.parentCustomerCode}</DescriptionsItem>
              <DescriptionsItem label="所属企业">{staffInfo.rootTenantName}</DescriptionsItem>
              <DescriptionsItem label="企业ID">{staffInfo.rootTenantCode}</DescriptionsItem>
              {staffInfo.mgmtRoleName && (
                <DescriptionsItem label="角色">
                  <>
                    {staffInfo.mgmtRoleName}
                    <Link
                      target={'blank'}
                      to={`/tenant/customer/prefab-role/role/detail/${staffInfo?.mgmtRoleCode}`}
                      className={styles.link}
                    >
                      查看角色权限
                    </Link>
                  </>
                </DescriptionsItem>
              )}
            </>
          )}
        </Descriptions>
      </Skeleton>
      <Button className={styles.button} type="primary">
        <Link to="/tenant/customer/employee">返回</Link>
      </Button>
    </Wrapper>
  );
};

export default TenantEmployeeDetail;
