import { CopyOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Card, Modal, Tag } from 'antd';
import styles from '../index.module.scss';
import imageInfo from '../../../assets/image/no-cover.png';
import { DataRulePageGetResponse } from '../../../ytt/types/dataRule/page';
import { TemplateStatus } from '../../../api/type';
import { useMemo } from 'react';
import { apiDataRuleIdCopyPost } from '../../../ytt/types/id/copy';
import { numberToString } from '../utils';
import { apiDataRuleIdDelete } from '../../../ytt/types/dataRule/id';
import { useNavigate } from 'react-router-dom';
type Data = Exclude<DataRulePageGetResponse['list'], undefined>[number];
interface Iprops {
  data?: Data;
  copy?: () => void;
  edit?: () => void;
  delete?: () => void;
  hover?: boolean;
  selectedId?: number;
}
const ListCard = ({ hover = true, selectedId, ...props }: Iprops) => {
  const [modalApi, modalContextHolder] = Modal.useModal();
  const isSelected = useMemo(() => selectedId === props.data?.id, [selectedId, props.data]);
  const navigate = useNavigate();
  return (
    <>
      <div
        className={`${styles.mask_outer} ${isSelected ? styles.mask_selected : ''} ${!hover ? styles.mask_hidden : ''}`}
      >
        <div className={`${styles.mask}`}>
          <div className={styles.icons}>
            {/* <div className={styles.icon_block}>
              <EyeOutlined />
              预览
            </div> */}
            <div
              className={styles.icon_block}
              onClick={() => {
                modalApi.confirm({
                  title: '您是否确认复制此模板?',
                  icon: <ExclamationCircleOutlined />,
                  onOk() {
                    apiDataRuleIdCopyPost({
                      id: numberToString(props.data?.id)!,
                    }).then(() => {
                      props.copy?.();
                    });
                  },
                });
              }}
            >
              <CopyOutlined />
              复制
            </div>
            <div
              className={styles.icon_block}
              onClick={() => {
                navigate(`/data-tools/shaping/templatemanage/values/${props.data?.type}/${props.data?.id}`);
              }}
            >
              <EditOutlined />
              编辑
            </div>
            <div
              className={styles.icon_block}
              onClick={() => {
                modalApi.confirm({
                  title: '您是否确认删除此模板?',
                  icon: <ExclamationCircleOutlined />,
                  onOk() {
                    apiDataRuleIdDelete({
                      id: numberToString(props.data?.id)!,
                    }).then(() => {
                      props.delete?.();
                    });
                  },
                });
              }}
            >
              <DeleteOutlined />
              删除
            </div>
          </div>
        </div>
        <Card
          title={
            <>
              {props.data?.name ?? '--'}
              {props.data?.status === TemplateStatus.DRAFT && <Tag style={{ marginLeft: 10 }}>草稿</Tag>}
            </>
          }
          extra={<Tag color="purple">{props.data?.constants}参数</Tag>}
        >
          <div className={styles.inner_content}>
            <div className={styles.no_cover}>
              <img src={imageInfo} alt="nodata" />
              <p>暂未生成封面</p>
            </div>
          </div>
        </Card>
      </div>
      {modalContextHolder}
    </>
  );
};

export default ListCard;
