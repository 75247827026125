import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Wrapper } from '@maxtropy/components';
import { Button, Col, Form, Input, Modal, Row, Space } from 'antd';
import React, { FC, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AddFormTemplateReq,
  createFormTemplate,
  getFormTemplateInfo,
  updateFormTemplate,
} from '../../../api/mockFormTemplate';

export interface IFormTemplateCreate {
  isEdit?: boolean;
}

const baseRoutes = [{ name: '数据运营工具' }, { name: '业务数据模拟' }, { name: '表单规则' }];

const FormTemplateCreate: FC<IFormTemplateCreate> = props => {
  const { isEdit } = props;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();
  const [modalApi, modalContextHolder] = Modal.useModal();

  const routes = useMemo(() => {
    return [...baseRoutes, { name: `${isEdit ? '编辑' : '新建'}表单模板` }];
  }, [isEdit]);

  useEffect(() => {
    if (id) {
      getFormTemplateInfo(id).then(res => {
        form.setFieldsValue({
          name: res.name,
          description: res.description,
          url: res.url,
          body: res.body,
        });
      });
    }
  }, [id]);

  const onFinish = (values: AddFormTemplateReq) => {
    if (isEdit && id) {
      updateFormTemplate(id, values).then(_ => {
        navigate(`/data-tools/business/formTemplateManagement`);
      });
    } else {
      createFormTemplate(values).then(_ => {
        navigate(`/data-tools/business/formTemplateManagement`);
      });
    }
  };

  const onCancel = () => {
    modalApi.confirm({
      title: '您是否确定不保存数据并返回列表界面?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        navigate(`/data-tools/business/formTemplateManagement`);
      },
    });
  };

  return (
    <Wrapper routes={routes}>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item
              label="模板名称"
              name="name"
              rules={[
                { required: true, message: '请输入模板名称' },
                { min: 2, max: 50 },
              ]}
            >
              <Input placeholder="请输入模板名称" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item label="模板说明" name="description" rules={[{ max: 200 }]}>
              <Input.TextArea placeholder="请输入模板说明" autoSize={{ minRows: 8 }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item
              label="接口URL"
              name="url"
              extra="仅支持post的接口"
              rules={[
                { required: true, message: '请输入接口URL' },
                { min: 1, max: 200 },
              ]}
            >
              <Input placeholder="请输入接口URL" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item
              label="接口body"
              name="body"
              extra="占位符的格式是：<占位符>，其中<>里面只支持汉字，大小写字母和数字"
              rules={[
                { required: true, message: '请输入接口body' },
                { min: 1, max: 2000 },
              ]}
            >
              <Input.TextArea placeholder="请输入接口body" autoSize={{ minRows: 9 }} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Space>
            <Button htmlType="submit" type="primary">
              保存
            </Button>
            <Button onClick={() => onCancel()}>取消</Button>
          </Space>
        </Form.Item>
      </Form>
      {modalContextHolder}
    </Wrapper>
  );
};

export default FormTemplateCreate;
