import { useCallback, useMemo, useState } from 'react';
import { useQuery } from '../../../../utils/utils';
import { getPhysicalUnits } from '../../../../api/options';
import { DataProperty } from '../../../../types';

export const useDataProperties = (dataPropertiesAll?: DataProperty[], usedProperties?: number[]) => {
  const [dataPropertyId, setDataPropertyId] = useState<number>();

  const dataProperties = useMemo(
    () => dataPropertiesAll?.filter(item => !usedProperties?.includes(item.id)),
    [dataPropertiesAll, usedProperties]
  );

  const dataPropertyType = useMemo(
    () => dataProperties?.find(item => item.id === dataPropertyId)?.type,
    [dataProperties, dataPropertyId]
  );

  const { data: units } = useQuery(
    useCallback(() => (dataPropertyId ? getPhysicalUnits(dataPropertyId) : Promise.resolve([])), [dataPropertyId])
  );

  return {
    dataProperties,
    units,
    dataPropertyType,
    dataPropertyId,
    setDataPropertyId,
  };
};
