import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { Key } from 'react';
import { DataProperty, DeviceModel, DeviceType, OU, OUPageRequest, OUPageResponse, PhysicalUnit } from '../types';

export const getDeviceTypeTree = () => fetch<{ tree: DeviceType[]; aliasName: Array<string> }>(`/api/deviceType/tree`);

export const getDeviceTypeTreeWithoutGatewayType = () =>
  fetch<{ tree: DeviceType[]; aliasName: Array<string> }>(`/api/deviceType/treeWithoutGatewayType`);

export const getDataProperties = (iotProtocol: number, deviceTypeId?: number, physicalModelId?: Key) =>
  fetch<DataProperty[]>(
    `/api/dataProperty/list?${qs.stringify(
      {
        deviceTypeId,
        iotProtocol,
        physicalModelId,
      },
      { indices: false }
    )}`
  );
export interface CrossDataPropertiesResponse {
  deviceName: string;
  deviceId: number;
  datapropertyName: string;
  datapropertyId: number;
  hasProperty: boolean;
  pointType: 1 | 2 | 3;
}
export function getCrossDataProperties(data: { id: number | string }) {
  return fetch<{ list: CrossDataPropertiesResponse[] }>(
    `/api/v2/edgeDevice/v2/cross-device/dataproperty`,
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
    true
  ).then(res => res.list);
}

// 临时方法，后续迭代会修改
export const getDataPropertiesTemp = (iotProtocol: number, deviceTypeId?: number, physicalModelId?: Key) =>
  fetch<DataProperty[]>(
    `/api/dataProperty/listWithChildren?${qs.stringify(
      {
        deviceTypeId,
        iotProtocol,
        physicalModelId,
      },
      { indices: false }
    )}`
  );

export const getPhysicalUnits = (dataPropertyId?: number) =>
  fetch<PhysicalUnit[]>(`/api/physicalUnit/list?dataPropertyId=${dataPropertyId}`);

export const getModels = (typeId: number) => fetch<DeviceModel[]>(`/api/model?typeId=${typeId}`);

interface IotProtocol {
  id: number;
  name: string;
}

export const getIotProtocols = () => fetch<IotProtocol[]>(`/api/iotProtocol/list`);

export const getMockingbirdIotProtocols = () => fetch<IotProtocol[]>(`/api/iotProtocol/jishang/list`);

export function getOUPage(params: OUPageRequest): Promise<OUPageResponse> {
  return fetch(`/api/ou/device/addOu/managePage?${qs.stringify(params, { indices: false })}`);
}

interface OuListRequest {
  ids: number[];
}

export function getOUList(params: OuListRequest): Promise<Array<OU>> {
  return fetch(`/api/ou/list?${qs.stringify(params, { indices: false })}`);
}

export interface OuType {
  id: number;
  name: string;
}

// 获取类型列表
export const getOuTypeList = () => fetch<OuType[]>(`/api/ou/ouType/list`);

export const queryGatewayChildren = () => fetch<number[]>('/api/deviceType/gateway');

export interface Tenant {
  id: number;
  mcid: string;
  name: string;
  tenantCode: string;
  address: string;
  adCode: string;
  accountOpenStatus: boolean;
}

export function getRoot(): Promise<Tenant[]> {
  return fetch(`/api/customer/getRoot?check=true`);
}

export interface PhysicalModelRes {
  id: Key;
  modelNo: string; // 型号
  manufacturerId: Key; // 厂商id
  deviceTypeId: Key; // 设备类目id
  createTime: string;
  updateTime: string;
  manufacturerName: string; // 厂商名称
}

//  获取all 物模型型号
export function getPhysicalModelAllList() {
  return fetch<PhysicalModelRes[]>('/api/physical-model/getPhysicalModelAllList');
}

export interface PhysicalParams {
  deviceTypeIds?: Key[]; // 设备类目id
  manufacturerId?: Key; // 厂商id
}

// 根据设备类目和厂商查询物模型列表

export function getPhysicalModelList(params: PhysicalParams) {
  return fetch<PhysicalModelRes[]>(`/api/physical-model/list?${qs.stringify(params, { indices: false })}`);
}
