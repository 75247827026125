import { PermissionsType } from '@/common/permissionsConst';
import { useMgmtPermissions } from '@/components/Layout/hooks';

const useIsGodRole = () => {
  const permissions = useMgmtPermissions();
  return !!(
    permissions?.includes(PermissionsType.B_CREATEAPP) && permissions?.includes(PermissionsType.B_CREATEDEVELOPER)
  );
};

export default useIsGodRole;
