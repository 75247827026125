import React, { useEffect, useMemo, useState } from 'react';
import {
  Wrapper,
  Table,
  Paging,
  usePaging,
  EllipsisSpan,
  useUpdate,
  FormContent,
  getRealUrl,
} from '@maxtropy/components';
import {
  Form,
  Select,
  Col,
  Divider,
  Space,
  Button,
  DatePicker,
  Modal,
  Row,
  Upload,
  UploadFile,
  Popconfirm,
} from 'antd';
import Filter from '@/shared/components/Filter';
import { Link } from 'react-router-dom';
import {
  addAnalysisReport,
  delAnalysisReport,
  getAnalysisReportPage,
  getAnalysisReportTypeList,
  PageSearchParams,
  ReportsUploadListRes,
  ReportTypeDetail,
} from '../../api/reportsUpload';
import { getRoot, Tenant } from '@/shared/api/options';
import dayjs, { Dayjs } from 'dayjs';
import { UploadOutlined } from '@ant-design/icons';
import { PermissionsType } from '../../common/permissionsConst';
import { useMgmtPermissions } from '@/components/Layout/hooks';

function onDownload(url: string, fileName: string) {
  let x = new XMLHttpRequest();
  x.open('GET', `${url}`, true);
  x.responseType = 'blob';
  x.onload = function () {
    let url = window.URL.createObjectURL(x.response);
    let a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.pdf`;
    a.click();
  };
  x.send();
}

const routes = [{ name: '数据中心' }, { name: '信息管理' }, { name: '报告管理' }];

// 时间格式化
const dateFormat = 'YYYY-MM-DD';
const displayDateFormat = 'YYYY-MM-DD HH:mm:ss';

// 查询参数类型
type SearchParams = Omit<PageSearchParams, 'page' | 'size'>;

// 表格：列名
const columns = [
  {
    title: '报告名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报告类型',
    dataIndex: 'typeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属租户',
    dataIndex: 'tenantName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '上传时间',
    dataIndex: 'createTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format(displayDateFormat)} />,
  },
  {
    title: '报告时间',
    dataIndex: 'reportTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format(dateFormat)} />,
  },
];

const ReportsUpload: React.FC = () => {
  const [form] = Form.useForm();
  const [updateState, updateFn] = useUpdate();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const [reportsList, setReportsList] = useState<ReportsUploadListRes[]>([]);
  const [tenant, setTenant] = useState<Tenant[]>([]);
  const [uploadOpen, setUploadOpen] = useState<boolean>(false);
  const [uploadForm] = Form.useForm();
  const [tenantMcid, setTenantMcid] = useState<string>();
  const [reportTypeList, setReportTypeList] = useState<ReportTypeDetail[]>([]);
  const permission = useMgmtPermissions();
  console.log((permission ?? []).includes(PermissionsType.B_ANALYSISREPORTUPLOAD));
  const [modalApi, modalContextHolder] = Modal.useModal();

  useEffect(() => {
    getRoot().then(setTenant);
  }, []);

  useEffect(() => {
    if (tenantMcid) {
      getAnalysisReportTypeList({ tenantMcid }).then(setReportTypeList);
    }
  }, [tenantMcid]);

  const tenantOptions = useMemo(() => {
    return tenant.map(i => ({ label: `【${i.tenantCode}】${i.name}`, value: i.mcid }));
  }, [tenant]);

  const reportTypeOptions = useMemo(() => {
    return reportTypeList.map(i => ({ label: i.name, value: i.id }));
  }, [reportTypeList]);

  // 列表查询
  useEffect(() => {
    setLoading(true);
    getAnalysisReportPage({
      ...searchParams,
      page: pageOffset,
      size: pageSize,
    })
      .then(res => {
        if (res) {
          setTotalCount(res.total);
          setReportsList(res.list);
        }
      })
      .finally(() => setLoading(false));
  }, [pageOffset, pageSize, searchParams, setTotalCount, updateState]);

  const onFinish = (value: SearchParams) => {
    setSearchParams(value);
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams(undefined);
    setPageOffset(1);
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 200,
      // fixed: "right" as const,
      render: (value: undefined, record: ReportsUploadListRes) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => {
                if (record.resource) {
                  onDownload(getRealUrl(record.resource) as string, record.name);
                }
              }}
            >
              下载
            </Button>
            {(permission ?? []).includes(PermissionsType.B_ANALYSISREPORTDELETE) && (
              <Popconfirm
                title={`确认删除【${record.name}】？`}
                onConfirm={() => {
                  delAnalysisReport(record.id).then(() => {
                    updateFn();
                  });
                }}
                okText="确定"
                cancelText="取消"
              >
                <Button type="link">删除</Button>
              </Popconfirm>
            )}
          </Space>
        );
      },
    },
  ];

  const filters = (
    <Filter
      form={form}
      onFinish={val => {
        onFinish(val as SearchParams);
      }}
      onReset={onReset}
    >
      <>
        <Col span={8}>
          <Form.Item name="tenantMcid" label="所属租户">
            <Select
              style={{ width: '100%' }}
              placeholder="请选择"
              onChange={() => {
                form.setFieldsValue({ typeId: undefined });
                setTenantMcid(form.getFieldValue('tenantMcid'));
              }}
              options={tenantOptions}
              optionFilterProp="label"
              showSearch
            />
          </Form.Item>
        </Col>
        <Form.Item noStyle dependencies={['tenantMcid']}>
          {({ getFieldValue }) => {
            const tenantMcid = !getFieldValue('tenantMcid');
            return (
              <Col span={8}>
                <Form.Item name="typeId" label="报告类型">
                  <Select
                    disabled={tenantMcid}
                    style={{ width: '100%' }}
                    placeholder={tenantMcid ? '请先选择所属租户' : '请选择报告类型'}
                    options={reportTypeOptions}
                    optionFilterProp="label"
                    showSearch
                  />
                </Form.Item>
              </Col>
            );
          }}
        </Form.Item>
      </>
    </Filter>
  );

  const onModalOk = async () => {
    const values = await uploadForm.validateFields();
    // 截取名字
    const name = values.resource.file.name.slice(
      0,
      values.resource.file.name.split('').findLastIndex((v: string) => v === '.')
    );
    const body = {
      ...values,
      reportTime: dayjs(values.reportTime).format(dateFormat),
      resource: values.resource.file.response.key,
      name,
    };
    addAnalysisReport(body).then(() => {
      setUploadOpen(false);
      uploadForm.resetFields();
      updateFn();
    });
  };

  const beforeUploadFile = (file: UploadFile, fileList: UploadFile[]) => {
    const { size, type } = file;
    if ((size as number) > 52428800) {
      modalApi.error({
        content: `上传文件超过50MB`,
      });
      return Upload.LIST_IGNORE;
    }
    if (type !== 'application/pdf') {
      modalApi.error({
        content: `仅支持PDF格式文件`,
      });
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  return (
    <>
      <Wrapper routes={routes} filters={filters}>
        <Space size={12}>
          {(permission ?? []).includes(PermissionsType.B_ANALYSISREPORTTYPEMANAGE) && (
            <Button type="primary">
              <Link to={'/data/info/report/reportTypeList'}>类型管理</Link>
            </Button>
          )}
          {(permission ?? []).includes(PermissionsType.B_ANALYSISREPORTUPLOAD) && (
            <Button type="primary" onClick={() => setUploadOpen(true)}>
              上传报告
            </Button>
          )}
        </Space>
        <FormContent style={{ padding: 0 }}>
          <Table
            sticky
            rowKey="id"
            loading={loading}
            scroll={{ x: 1500 }}
            columns={buildColumns}
            dataSource={reportsList}
          />
          <Paging pagingInfo={pagingInfo} />
        </FormContent>
      </Wrapper>
      <Modal
        width={600}
        open={uploadOpen}
        title="上传报告"
        onCancel={() => {
          setUploadOpen(false);
          uploadForm.resetFields();
        }}
        onOk={onModalOk}
        okText="确认上传"
      >
        <Form style={{ width: 550 }} labelAlign="right" labelCol={{ flex: '140px' }} form={uploadForm}>
          <Row>
            <Col span={20}>
              <Form.Item name="tenantMcid" label="所属租户" rules={[{ required: true, message: '请选择所属租户' }]}>
                <Select
                  style={{ width: '100%' }}
                  placeholder="请选择"
                  onChange={() => {
                    setTenantMcid(uploadForm.getFieldValue('tenantMcid'));
                    uploadForm.setFieldsValue({ typeId: undefined });
                  }}
                  options={tenantOptions}
                  optionFilterProp="label"
                  showSearch
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item noStyle dependencies={['tenantMcid']}>
            {({ getFieldValue }) => {
              const tenantMcid = !getFieldValue('tenantMcid');
              return (
                <Row>
                  <Col span={20}>
                    <Form.Item name="typeId" label="报告类型" rules={[{ required: true, message: '请选择报告类型' }]}>
                      <Select
                        dropdownStyle={{ maxHeight: 200, overflow: 'auto' }}
                        disabled={tenantMcid}
                        style={{ width: '100%' }}
                        placeholder={tenantMcid ? '请先选择所属租户' : '请选择报告类型'}
                        options={reportTypeOptions}
                        optionFilterProp="label"
                        showSearch
                      />
                    </Form.Item>
                  </Col>
                </Row>
              );
            }}
          </Form.Item>
          <Row>
            <Col span={20}>
              <Form.Item name="reportTime" label="报告时间" rules={[{ required: true, message: '请选择报告时间' }]}>
                <DatePicker
                  style={{ width: '100%' }}
                  picker="date"
                  disabledDate={(currentDate: Dayjs) => {
                    return currentDate.isAfter(dayjs().endOf('day'));
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                name="resource"
                label="选择文件"
                rules={[{ required: true, message: '请选择文件' }]}
                extra={'支持PDF格式文件，限制50MB以内。'}
              >
                <Upload
                  action="/api/file-center/upload"
                  accept=".pdf"
                  // onChange={handleChange}
                  maxCount={1}
                  beforeUpload={beforeUploadFile}
                >
                  <Button icon={<UploadOutlined />}>点击上传</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      {modalContextHolder}
    </>
  );
};

export default ReportsUpload;
