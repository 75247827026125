import { CascadingMultipleData } from './index';

export interface DeviceTypes {
  id: number;
  name: string;
  allowAttribute?: boolean;
  children?: Array<DeviceTypes>;
  deviceTypes?: Array<DeviceTypes>;
  parentId?: number;
  parentName?: string;
  rootId?: number;
}

export interface DeviceTypeTree {
  aliasName: Array<string>;
  tree: Array<DeviceTypes>;
}

export function getCascadingNames(val: Array<number>, option: Array<CascadingMultipleData>): string {
  let result = '';
  const getName = (lists: Array<CascadingMultipleData>, index: number) => {
    const target = lists.find(v => v.value === val[index]);
    if (target) {
      result += target?.label;
      if (target.child) {
        getName(target?.child, ++index);
      }
    }
  };
  getName(option, 0);
  return result;
}

export function getAllChild(
  val: Array<Array<number>> | undefined,
  option: Array<CascadingMultipleData> | undefined
): Array<number> {
  if (val && option) {
    let parentNode: Array<CascadingMultipleData> | undefined = option;
    const valueArray = val.map(item => {
      const target = parentNode!.filter(op => item.includes(op.value));
      parentNode = target
        .filter(item => item.child)
        .map(item => item.child!)
        .flat();
      return target;
    });
    const valueMap = new Map<string, Array<CascadingMultipleData>>();
    Object.entries(valueArray).forEach(([key, value]) => valueMap.set(key, value!));
    const theLastNode = valueMap.get(Math.max(...Array.from(valueArray.keys()).map(str => Number(str))).toString());
    const result: Array<number> = [];
    const loop = (data: Array<CascadingMultipleData> | undefined) => {
      if (!data) return undefined;
      data.forEach(item => (item.child ? loop(item.child) : result.push(item.value)));
    };
    loop(theLastNode);
    return result;
  }
  return [];
}

export function formatOptionData(response: DeviceTypeTree | undefined): Array<CascadingMultipleData> | undefined {
  const loop = (data: Array<DeviceTypes>): Array<CascadingMultipleData> => {
    return data.map(item => ({
      label: item.name,
      value: item.id,
      child: item.deviceTypes || item.children ? loop(item.deviceTypes || item.children!) : undefined,
    }));
  };
  if (!response) return undefined;
  return loop(response.tree);
}
export const getLastChildrenIds = (sourceData: DeviceTypes[]) => {
  let ids: number[] = [];
  const loop = (data: DeviceTypes[]) =>
    data.forEach(item => {
      if (!item.children) {
        ids.push(item.id);
        return;
      }
      loop(item.children);
    });

  loop(sourceData);
  return ids;
};
export const getChildNodesByParentIds = (treeData?: number[][], sourceData?: DeviceTypes[]) => {
  let ids: number[] = [];
  if (!treeData || !sourceData || sourceData.length === 0) return;
  treeData.forEach(nodes => {
    let tempData = sourceData.find(item => nodes.includes(item.id))?.deviceTypes;
    if (!tempData) return;

    const findData = (data: DeviceTypes[]) => {
      return data.some(item => {
        if (nodes.includes(item.id)) {
          if (item.children) {
            let flag = findData(item.children);
            if (!flag) {
              let temp = getLastChildrenIds(item.children);
              ids.push(...temp);
            }
          } else {
            ids.push(item.id);
          }
          return true;
        }
        return false;
      });
    };

    let flag = findData(tempData);
    if (!flag) {
      let temp = getLastChildrenIds(tempData);
      ids.push(...temp);
    }
  });
  return ids;
};
