import { DeleteOutlined, ExclamationCircleOutlined, FormOutlined } from '@ant-design/icons';
import { Modal, Tooltip } from 'antd';
import styles from '../index.module.scss';
interface Iprops {
  label?: string;
  edit?: () => void;
  remove?: () => void;
}
const ChildNodeTitle = (props: Iprops) => {
  const [modalApi, modalContextHolder] = Modal.useModal();
  return (
    <div className={styles.child_node}>
      <div className={styles.label}>{props.label}</div>
      <div className={styles.op}>
        <span
          className={styles.edit}
          onClick={e => {
            e.stopPropagation();
            props.edit?.();
          }}
        >
          <Tooltip placement="top" title="编辑">
            <FormOutlined />
          </Tooltip>
        </span>
        <span style={{ padding: '0px 6px' }}></span>
        <span
          className={styles.remove}
          onClick={e => {
            e.stopPropagation();
            modalApi.confirm({
              title: '您确定删除吗?',
              icon: <ExclamationCircleOutlined />,
              onOk() {
                props.remove?.();
              },
            });
          }}
        >
          <Tooltip placement="top" title="删除">
            <DeleteOutlined />
          </Tooltip>
        </span>
      </div>
      {modalContextHolder}
    </div>
  );
};

export default ChildNodeTitle;
