import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

export enum PageStatus {
  CREATE = 'create',
  UPDATE = 'update',
  DETAIL = 'detail',
}

export const PageStatusDisplay = {
  [PageStatus.CREATE]: '新建',
  [PageStatus.UPDATE]: '编辑',
  [PageStatus.DETAIL]: '查看',
};

const usePageStatus = () => {
  const location = useLocation();

  const status = useMemo(() => {
    const paths = location.pathname.split('/');
    if (paths.includes(PageStatus.CREATE)) {
      return PageStatus.CREATE;
    } else if (paths.includes(PageStatus.UPDATE)) {
      return PageStatus.UPDATE;
    } else if (paths.includes(PageStatus.DETAIL)) {
      return PageStatus.DETAIL;
    }
  }, [location.pathname]);

  return status;
};

export default usePageStatus;
