import { Button, getRealUrl } from '@maxtropy/components';
import { Avatar, Card } from 'antd';
import Meta from 'antd/lib/card/Meta';
import { useNavigate } from 'react-router-dom';
import { ServiceListRes, ServiceStatus } from '../../../api/openPlatform-serviceManage';
import styles from './index.module.scss';

interface Props {
  data: ServiceListRes;
}

const ServiceCard: React.FC<Props> = ({ data }) => {
  const navigate = useNavigate();
  return (
    <Card
      style={{ marginBottom: 25 }}
      actions={
        data.status === ServiceStatus.ENABLE
          ? [
              <Button
                key={data.id}
                type="link"
                onClick={() => navigate(`/open-platform/open-platform/service-management/edit/${data.id}`)}
              >
                编辑
              </Button>,
              <Button
                key={data.id + '1'}
                type="link"
                onClick={() => navigate(`/open-platform/open-platform/service-management/detail/${data.id}`)}
              >
                查看
              </Button>,
            ]
          : [
              <Button
                key={data.id + '2'}
                type="link"
                onClick={() => navigate(`/open-platform/open-platform/service-management/detail/${data.id}`)}
              >
                查看
              </Button>,
            ]
      }
    >
      <Meta
        avatar={<Avatar size={48} alt="头像" src={getRealUrl(data.logoResource)} />}
        title={<span className={styles.name}>{data.name}</span>}
        description={
          <>
            <p className={styles.companyName}>{data.companyName}</p>
            <p className={styles.companyName}>
              应用个数：
              <span style={{ color: 'var(--primary-color)' }}>{data.applications ? data.applications.length : 0} </span>
              个
            </p>
          </>
        }
      />
    </Card>
  );
};

export default ServiceCard;
