import React, { Key, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EllipsisSpan, getRealUrl, Paging, Table, usePaging, useUpdate } from '@maxtropy/components';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import {
  apiMiniAppCategoryListPost,
  apiMiniAppMenuEditPost,
  MiniAppCategoryListPostResponse,
  MiniAppToolPagePostRequest,
} from '@maxtropy/cc-mgmt-apis';
import IconGroup, { miniAppIconMap } from './IconGroup';
import { apiMiniAppToolTagPagePost, List, MenuType, MenuVisibilityType } from '../types';
import styles from '../index.module.scss';
import {
  V2MiniAppToolGetListPostResponse,
  apiV2MiniAppToolEditPost,
  apiV2MiniAppToolGetListPost,
} from '@maxtropy/cc-mgmt-apis-v2';
import { isNil } from 'lodash-es';

interface Props {
  listId?: number;
  toolModalOpen: boolean;
  isWithMenu?: boolean;
  currentMenuId?: number;
  currentMenuSorting?: number;
  currentCenterId?: number;
  forceUpdate?: () => void;
  update: () => void;
  setToolModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentHomePageInfo?: React.Dispatch<React.SetStateAction<V2MiniAppToolGetListPostResponse['list'] | undefined>>;
}

const AddToolsAndMenusModal: React.FC<Props> = ({
  listId,
  toolModalOpen,
  isWithMenu,
  currentMenuId,
  currentCenterId,
  forceUpdate,
  update,
  setToolModalOpen,
  setCurrentHomePageInfo,
}) => {
  const [toolsForm] = Form.useForm();
  const [menusForm] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [updateState, updateFn] = useUpdate();
  const [categoryList, setCategoryList] = useState<MiniAppCategoryListPostResponse>([]);
  const [toolPage, setToolPage] = useState<List[]>();
  const [searchParams, setSearchParams] = useState<MiniAppToolPagePostRequest>();
  const [selectedRows, setSelectedRows] = useState<List[]>();
  const [menuModalOpen, setMenuModalOpen] = useState<boolean>(false);
  const [selectionType] = useState<'checkbox' | 'radio'>(() => (isWithMenu ? 'radio' : 'checkbox'));
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const getListInfo = () => {
    if (isNil(currentMenuId)) return;

    if (currentMenuId === currentCenterId) {
      update();
    } else {
      apiV2MiniAppToolGetListPost({ id: +currentMenuId }).then(res => {
        res.list && setCurrentHomePageInfo?.(res.list);
        forceUpdate?.();
      });
    }
  };

  const toolModalOk = () => {
    const selectedTools = toolPage?.filter(item => selectedRowKeys.includes(item.id as Key));
    const selectedToolsInfo = selectedTools?.map(item => {
      return {
        id: item.id,
        name: item.name,
        categoryId: item.categoryId,
        isDisable: item.isDisable,
      };
    });
    const newSelectedToolsInfo = selectedToolsInfo?.filter(item => item.isDisable === false);

    // 选中的工具名称
    const selectedToolsName = selectedTools?.map(item => item?.name?.substring(0, 4))[0];
    if (!isWithMenu) {
      apiV2MiniAppToolEditPost({
        menuId: currentMenuId,
        toolAddRequests: newSelectedToolsInfo,
      }).then(_res => {
        getListInfo();
        toolsForm.resetFields();
        setToolModalOpen(false);
      });
    } else if (isWithMenu) {
      apiV2MiniAppToolEditPost({
        menuId: currentMenuId,
        toolAddRequests: newSelectedToolsInfo,
      }).then(_res => {
        toolsForm.resetFields();
        setToolModalOpen(false);
        setMenuModalOpen(true);
        menusForm.setFieldsValue({
          toolName: selectedToolsName,
          name: selectedToolsName,
          icon: 'alert',
        });
      });
    }
  };

  const onMenuModalOk = () => {
    menusForm.validateFields().then(val => {
      apiMiniAppMenuEditPost({
        id: listId,
        editRequests: [
          {
            id: currentMenuId,
            ...val,
            type: MenuType.CUSTOM,
            visibility: MenuVisibilityType.SHOW,
          },
        ],
      }).then(_res => {
        getListInfo();
        menusForm.resetFields();
        setMenuModalOpen(false);
      });
    });
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[], rows: List[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
    const newRows = rows.map((item: List) => {
      return {
        ...item,
        id: item.id,
      };
    });
    setSelectedRows(newRows.filter((item: List) => item));
  };

  const rowSelection = {
    selectedRowKeys: selectedRows?.map(item => item.id),
    onChange: onSelectChange,
    type: selectionType,
    getCheckboxProps: (record: List) => {
      return {
        disabled: record.isDisable,
      };
    },
  };

  useEffect(() => {
    if (isWithMenu) return;
    const disableToolList = toolPage?.filter(item => item.isDisable);
    setSelectedRows(disableToolList);
  }, [toolPage, isWithMenu]);

  const getList = async () => {
    setLoading(true);

    const { toolName, categoryId } = searchParams || {};
    const res = await apiMiniAppToolTagPagePost({
      appId: listId ? String(listId) : String(id),
      page: String(pageOffset),
      size: String(pageSize),
      ...(toolName && { toolName }),
      ...(categoryId && { categoryId }),
    });
    if (res) {
      const { list, total } = res;
      setToolPage(list);
      setTotalCount(total as number);
      setLoading(false);
    }
  };

  useEffect(() => {
    setPageOffset(1);
    updateState && getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState]);

  useEffect(() => {
    toolModalOpen && updateFn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, pageSize, searchParams, toolModalOpen, listId]);

  useEffect(() => {
    if (!toolModalOpen) {
      setSelectedRows([]);
      setSelectedRowKeys([]);
      return;
    }
    apiMiniAppCategoryListPost().then(setCategoryList);
  }, [toolModalOpen]);

  const columns = [
    {
      title: '工具',
      dataIndex: 'name',
      render: (text: string, record: List) => (
        <div>
          <img src={getRealUrl(record.icon)} alt="" style={{ marginRight: '10px' }} width="44" />
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: '类型',
      dataIndex: 'categoryName',
      render: (text: string) => <EllipsisSpan value={text} />,
    },
  ];

  return (
    <>
      <Modal
        open={toolModalOpen}
        destroyOnClose
        title={'添加工具'}
        width={800}
        onCancel={() => {
          setToolModalOpen(false);
        }}
        okText={isWithMenu ? '下一步' : '确定添加'}
        onOk={toolModalOk}
      >
        <Form
          form={toolsForm}
          onFinish={(val: MiniAppToolPagePostRequest) => {
            setSearchParams(val);
          }}
        >
          <Row gutter={[20, 10]}>
            <Col span={10}>
              <Form.Item name="categoryId" label="类型">
                <Select allowClear placeholder="请选择">
                  {categoryList.map(category => (
                    <Select.Option key={category.id} value={category.id}>
                      {category.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={10}>
              <Form.Item name="toolName" label="工具">
                <Input placeholder="请输入" allowClear></Input>
              </Form.Item>
            </Col>

            <Col span={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
            </Col>
          </Row>
        </Form>

        <Table rowSelection={rowSelection} columns={columns} dataSource={toolPage} loading={loading} rowKey="id" />
        <Paging pagingInfo={pagingInfo} />
      </Modal>

      {/*  菜单设置弹框 */}
      <Modal
        title="菜单设置"
        open={menuModalOpen}
        width={600}
        centered={true}
        destroyOnClose
        onCancel={() => setMenuModalOpen(false)}
        onOk={onMenuModalOk}
      >
        <Form form={menusForm} className={styles.menusForm}>
          <Col span={24}>
            <Form.Item label="工具名称" name="toolName" labelCol={{ span: 24 }}>
              <Input disabled={true}></Input>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="菜单名称"
              name="name"
              labelCol={{ span: 24 }}
              rules={[{ required: true, min: 1, max: 4 }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="菜单图标"
              name="icon"
              labelCol={{ span: 24 }}
              rules={[{ required: true, message: '请选择菜单图标' }]}
            >
              <IconGroup data={miniAppIconMap} />
            </Form.Item>
          </Col>
        </Form>
      </Modal>
    </>
  );
};

export default AddToolsAndMenusModal;
