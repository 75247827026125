import { fetch } from '@maxtropy/components';

export interface LoginRequest {
  username: string;
  password: string;
}

export interface LoginResp {
  jwtToken: string;
}

export function login(params: LoginRequest): Promise<LoginResp> {
  return fetch(
    `/api/v2/account/login`,
    {
      method: 'POST',
      body: JSON.stringify(params),
    },
    true
  );
}

export interface LogoutResp {
  resultFlag: boolean;
}

export function logout(): Promise<LogoutResp> {
  return fetch(
    `/api/v2/account/logout`,
    {
      method: 'POST',
    },
    true
  );
}
