import { EllipsisSpan, Paging, useSearchParams, useUpdate, Wrapper } from '@maxtropy/components';
import { Button, Col, Form, Input, message, Modal, Popconfirm, Row, Select, Space, Table } from 'antd';
import styles from './index.module.scss';
import React, { Key, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  copyDataGenertorForm,
  DataGeneratorPageRes,
  DataGeneratorStatus,
  dataGeneratorStatusFormat,
  deleteDataGenerator,
  getDataGeneratorPage,
  updateDataGeneratorStatus,
} from '../../api/dataGenerator';
import dayjs from 'dayjs';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { set } from 'lodash-es';

const routes = [{ name: '数据运营工具' }, { name: '业务数据模拟' }, { name: '业务数据生成器' }];

const statusArr = [
  {
    value: '0',
    label: '全部',
  },
  {
    value: '1',
    label: '启用',
  },
  {
    value: '2',
    label: '禁用',
  },
];

type SearchParams = {
  name?: string;
  status?: string;
};

const columns = [
  {
    title: '业务名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (v: DataGeneratorStatus) => <EllipsisSpan value={dataGeneratorStatusFormat[v]} />,
  },
  {
    title: '所属租户',
    dataIndex: 'tenantName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '开始时间',
    dataIndex: 'beginTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'} />,
  },
  {
    title: '结束时间',
    dataIndex: 'endTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'} />,
  },
  {
    title: '周期（秒）',
    dataIndex: 'period',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '节点数',
    dataIndex: 'nodeCount',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '更新人',
    dataIndex: 'updateCropUserName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '更新时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'} />,
  },
];

const DataGeneratorManagement = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const pagingInfo = useSearchParams<SearchParams>();
  // 解构出searchParams以及分页相关的属性或方法
  const { searchParams, finish, reset, setTotalCount } = pagingInfo;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<DataGeneratorPageRes[]>([]);
  const [updateState, updateFn] = useUpdate();
  const [modalApi, modalContextHolder] = Modal.useModal();

  useEffect(() => {
    setLoading(true);
    getDataGeneratorPage({
      ...searchParams,
      status: searchParams.status ?? '0',
      page: searchParams.pageOffset,
      size: searchParams.pageSize,
    })
      .then(res => {
        setDataSource(res.list ?? []);
        setTotalCount(res.total ?? 0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, updateState]);

  const deleteDataGen = (id: Key) => {
    deleteDataGenerator(id).then(_ => {
      updateFn();
    });
  };

  const modifyStatus = (status: number, id: number) => {
    const statusParams =
      status === DataGeneratorStatus.DISABLE ? DataGeneratorStatus.ENABLE : DataGeneratorStatus.DISABLE;
    updateDataGeneratorStatus(id, statusParams).then(_ => {
      message.success(statusParams === DataGeneratorStatus.ENABLE ? '启用成功！' : '禁用成功');
      updateFn();
    });
  };

  const copyForm = (id: number) => {
    modalApi.confirm({
      title: '是否复制此表单?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        copyDataGenertorForm(id).then(_ => {
          updateFn();
        });
      },
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as const,
      width: 200,
      render: (_: undefined, record: DataGeneratorPageRes) => {
        return (
          <Space size={16}>
            <Button type="link" style={{ padding: 0 }} onClick={() => modifyStatus(record.status, record.id)}>
              <span style={{ color: record.status === DataGeneratorStatus.DISABLE ? 'unset' : 'red' }}>
                {record.status === DataGeneratorStatus.DISABLE
                  ? dataGeneratorStatusFormat[DataGeneratorStatus.ENABLE]
                  : dataGeneratorStatusFormat[DataGeneratorStatus.DISABLE]}
              </span>
            </Button>
            <Button style={{ padding: 0 }} type="link" onClick={() => copyForm(record.id)}>
              复制
            </Button>
            <Button
              style={{ padding: 0 }}
              type="link"
              onClick={() => navigate(`/data-tools/business/dataGeneratorCreate/${record.id}`)}
            >
              编辑
            </Button>
            <Popconfirm
              title={'您是否要删除此数据生成器，删除后不可恢复'}
              onConfirm={() => deleteDataGen(record.id)}
              okText="确定"
              cancelText="取消"
            >
              <Button style={{ padding: 0 }} type="link">
                删除
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <Wrapper
      routes={routes}
      className={styles.outer}
      filters={
        <div className={styles.filter}>
          <Form form={form} onFinish={val => finish({ ...val })} onReset={() => reset()}>
            <Row gutter={10}>
              <Col span={6}>
                <Form.Item label="名称" name="name">
                  <Input placeholder="请输入名称搜索"></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="状态" name="status" initialValue={'0'}>
                  <Select placeholder="请选择状态搜索" options={statusArr} />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item>
                  <Space size={12}>
                    <Button type="primary" htmlType="submit">
                      搜索
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
              <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type="primary" onClick={() => navigate(`/data-tools/business/dataGeneratorCreate`)}>
                  新建数据生成器
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      }
    >
      <Table
        loading={isLoading}
        dataSource={dataSource}
        sticky
        rowKey="id"
        scroll={{ y: 600 }}
        columns={buildColumns}
        pagination={false}
      />
      <Paging pagingInfo={pagingInfo} />
      {modalContextHolder}
    </Wrapper>
  );
};

export default DataGeneratorManagement;
