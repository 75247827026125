/* eslint-disable no-useless-escape */
import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Row, Col, Button, DatePicker, Radio, Select, Divider, Space } from 'antd';
import { commonMobilePhoneRegex } from '../../utils/regex';
import { Wrapper, FormContent, useAsync } from '@maxtropy/components';
import dayjs, { Dayjs } from 'dayjs';

import { Gender, UserStatus, UserStatusDisplay } from '../../api/cc-const';
import { createPlatformUser, CreatePlatformUserRequest, getAllUser } from '../../api/cc-user';

import styles from './index.module.scss';
import { getDefaultPassword } from '../../api/cc-customer';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

const dateFormat = 'YYYY-MM-DD';

const routes = [{ name: '系统设置' }, { name: '平台组织权限和账户' }, { name: '员工管理' }, { name: '新增运营人员' }];

const formLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 2, span: 16 },
};

const CreatePlateformEmployee: FC = () => {
  const navigate = useNavigate();
  const parents = useAsync(getAllUser);

  const parentsOption = useMemo(() => {
    if (!parents) return [];
    return parents.list.map(i => ({
      value: i.id,
      label: i.name,
    }));
  }, [parents]);

  const onFinish = (value: CreatePlatformUserRequest) => {
    createPlatformUser({
      ...value,
      birthday: dayjs(value.birthday).format(dateFormat),
    }).then(res => {
      navigate(`/platform/account/employee/function/create/${res.id}`);
    });
  };

  const defaultPassword = useAsync(useCallback(() => getDefaultPassword(), []));

  return (
    <Wrapper routes={routes}>
      <div className={styles.container}>
        <Form layout="vertical" {...formLayout} onFinish={onFinish}>
          <FormContent title="员工基础信息">
            <Row>
              <Col span={8}>
                <Form.Item
                  name="name"
                  label="员工姓名"
                  rules={[
                    { required: true, message: '请输入员工姓名' },
                    { pattern: /^[\u4e00-\u9fa5]+$/g, message: '姓名必须为汉字' },
                    { max: 8, message: '员工姓名最多8个字符' },
                  ]}
                  validateFirst={true}
                >
                  <Input placeholder="请输入员工姓名" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="username"
                  label="账号"
                  rules={[
                    { required: true, message: '请输入账号' },
                    {
                      pattern: /^(?!\d+$)[\dA-Za-z.!@#$%^&*()_+]{5,20}$/,
                      message: '请输入长度5~20个字符，英文、数字、特殊字符并且不能为纯数字的组合',
                    },
                  ]}
                  validateFirst={true}
                >
                  <Input placeholder="请输入账号" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="cellphone"
                  label="手机号码"
                  rules={[
                    { required: true, message: '请输入手机号码' },
                    {
                      required: true,
                      pattern: commonMobilePhoneRegex,
                      message: '请输入正确的手机号码',
                    },
                  ]}
                  validateFirst={true}
                >
                  <Input placeholder="请输入手机号码" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="email"
                  label="邮箱"
                  rules={[
                    { required: true, message: '请输入邮箱' },
                    {
                      type: 'email',
                      message: '请输入正确的邮箱',
                    },
                  ]}
                  validateFirst={true}
                >
                  <Input placeholder="请输入邮箱" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="birthday" label="生日">
                  <DatePicker
                    style={{ width: '100%' }}
                    disabledDate={(currentDate: Dayjs) => {
                      return currentDate.isAfter(dayjs(), 'date');
                    }}
                    placeholder="请输入生日"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="gender" label="性别" rules={[{ required: true, message: '请选择性别' }]}>
                  <Radio.Group>
                    <Radio value={Gender.MALE}>男</Radio>
                    <Radio value={Gender.FEMALE}>女</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="status" label="用户状态" rules={[{ required: true, message: '请选择用户状态' }]}>
                  <Select style={{ width: '100%' }}>
                    <Option value={UserStatus.ENABLE}>{UserStatusDisplay[UserStatus.ENABLE]}</Option>
                    <Option value={UserStatus.DISABLE}>{UserStatusDisplay[UserStatus.DISABLE]}</Option>
                    <Option value={UserStatus.FREEZE}>{UserStatusDisplay[UserStatus.FREEZE]}</Option>
                  </Select>
                </Form.Item>
              </Col>
              {/*<Col span={8}>*/}
              {/*  <Form.Item name="headPic" label="用户头像">*/}
              {/*    <AttachmentUpload />*/}
              {/*  </Form.Item>*/}
              {/*</Col>*/}
            </Row>
            <div style={{ fontSize: 14, color: 'gray' }}>
              <InfoCircleOutlined className={styles.warnIcon} />
              默认密码: {defaultPassword?.defaultPassword}
            </div>
          </FormContent>
          <Divider />
          <FormContent title="组织关系维护">
            <Row>
              <Col span={8}>
                <Form.Item name="parentId" label="上级">
                  <Select
                    style={{ width: '100%' }}
                    options={parentsOption}
                    placeholder="请选择"
                    showSearch
                    filterOption={(inputValue, option) => {
                      return (option?.label ?? '').includes(inputValue);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </FormContent>
          <Row className={styles.buttonWrapper}>
            <Col span={7}>
              <Form.Item {...tailLayout}>
                <Space>
                  <Button
                    onClick={() => {
                      navigate('/platform/account/employee');
                    }}
                  >
                    放弃
                  </Button>
                  <Button htmlType="submit" type="primary">
                    下一步
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Wrapper>
  );
};

export default CreatePlateformEmployee;
