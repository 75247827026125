import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Wrapper, useUpdate } from '@maxtropy/components';
import { Button, Form, message, Space, Steps } from 'antd';
import { usePrevious } from 'ahooks';
import {
  apiMiniAppAddOrUpdatePartOnePost,
  apiMiniAppAddOrUpdatePartTwoPost,
  apiMiniAppPackagingPost,
  apiMiniAppPartOneTwoDetailPost,
  apiMiniAppPublishPost,
  apiMiniAppToolCustomizationJudgePost,
  MiniAppAddOrUpdatePartOnePostRequest,
  MiniAppAddOrUpdatePartTwoPostRequest,
  MiniAppPartOneTwoDetailPostResponse,
} from '@maxtropy/cc-mgmt-apis';
import { StatusEnum } from '../List/types';
import BasicInfo from './BasicInfo/BasicInfo';
import FunctionSet from './FunctionSet';
import PreRelease from './PreRelease';
import Entrance, { DEFAULT_TEXT } from './Entrance/index';
import styles from './index.module.scss';
import { apiV2MiniAppToolEditPost } from '@maxtropy/cc-mgmt-apis-v2';
import { CardLayout } from './FunctionSet/CardHomePage/types';

interface Props {
  update?: boolean;
}

const routes = [{ name: '系统设置' }, { name: '小程序' }, { name: '小程序管理' }, { name: '小程序打包' }];

const MiniProgramPack: React.FC<Props> = ({ update }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();

  const [current, setCurrent] = useState<number>(0);
  const previous = usePrevious(current);
  const [basicForm] = Form.useForm<MiniAppAddOrUpdatePartOnePostRequest>();
  const [partTwoParams, setPartTwoParams] = useState<MiniAppAddOrUpdatePartTwoPostRequest>();
  const [partOneTwoData, setPartOneTwoData] = useState<MiniAppPartOneTwoDetailPostResponse>();
  const [containerHeight, setContainerHeight] = useState<string>('');
  const [listId, setListId] = useState<number>();
  const [isNext, setIsNext] = useState<boolean>(false);
  const [refresh, refreshFn] = useUpdate();

  const saveCardLayoutData = () => {
    const cardLayoutData = sessionStorage.getItem('cardLayoutData');
    const cardPageId = sessionStorage.getItem('cardPageId');

    if (cardLayoutData && cardPageId && current === 2) {
      const cardLayoutDataObj = JSON.parse(cardLayoutData).map((item: CardLayout) => ({
        id: +item.i,
        rowNumber: item.y,
        columnNumber: item.x,
      }));

      apiV2MiniAppToolEditPost({
        menuId: +cardPageId,
        toolEditRequests: cardLayoutDataObj,
      });
    }
  };

  const next = () => {
    saveCardLayoutData();
    setCurrent(current + 1);
  };

  const prev = () => {
    saveCardLayoutData();
    setCurrent(current - 1);
  };

  const onNext = async () => {
    try {
      switch (current) {
        case 0: {
          const val = await basicForm.validateFields();
          const params = {
            ...val,
            id: previous === 1 ? Number(listId) : id ? Number(id) : undefined,
          };
          const res = await apiMiniAppAddOrUpdatePartOnePost(params);
          setListId(res);
          res && next();
          break;
        }
        case 1: {
          await apiMiniAppAddOrUpdatePartTwoPost({
            welcomeText: '欢迎进入\n熵博士AI能源管理助手',
            ...partTwoParams,
            id: listId,
          });
          next();
          break;
        }
        case 2: {
          const state = await apiMiniAppToolCustomizationJudgePost({ id: String(listId) });
          if (!state) throw new Error('Response from apiMiniAppToolCustomizationJudgePost is falsy');
          const res = await apiMiniAppPackagingPost({ id: String(listId) });
          res && next();
          break;
        }
        default:
          break;
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setContainerHeight(current === 3 ? '964px' : 'auto');
  }, [current]);

  useEffect(() => {
    if (update && id) {
      apiMiniAppPartOneTwoDetailPost({ id }).then(res => {
        basicForm.setFieldsValue(res);
        setPartOneTwoData(res);
        setPartTwoParams({
          id: listId,
          icon: res.icon,
          welcomeText: res.welcomeText ?? DEFAULT_TEXT,
          bg: res.bg,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update, id, refresh]);

  // 刷新页面返回列表
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (location.pathname !== '/platform/miniProgram/manage') {
        sessionStorage.setItem('shouldRedirect', 'true');
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [navigate, location]);

  useEffect(() => {
    const shouldRedirect = sessionStorage.getItem('shouldRedirect');

    if (shouldRedirect) {
      sessionStorage.removeItem('shouldRedirect');
      navigate('/platform/miniProgram/manage');
    }
  }, [navigate]);

  const steps = [
    {
      title: '基本信息',
      content: <BasicInfo form={basicForm} partOneTwoData={partOneTwoData} update={update} />,
    },
    {
      title: '入口页设置',
      content: (
        <Entrance partOneTwoData={partOneTwoData} setPartTwoParams={setPartTwoParams} partTwoParams={partTwoParams} />
      ),
    },
    {
      title: '功能设置',
      content: <FunctionSet listId={listId} />,
    },
    {
      title: '预览发布',
      content: <PreRelease isNext={isNext} listId={listId} partOneTwoData={partOneTwoData} />,
    },
  ];
  const items = steps.map(item => ({ key: item.title, title: item.title }));

  return (
    <>
      <Wrapper routes={routes}>
        <Steps current={current} items={items} className={styles.steps} />

        <div className={styles.bgContainer} style={{ height: containerHeight }}>
          <div className={styles.contentStyle}>{steps[current].content}</div>

          <Space style={{ marginTop: 20 }} className={styles.button}>
            {current !== 2 && current !== 3 && (
              <Button type="primary" onClick={onNext}>
                下一步
              </Button>
            )}
            {current === 2 && !partOneTwoData?.packFlag && (
              <Button type="primary" onClick={onNext}>
                确认打包
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button
                type="primary"
                onClick={() => {
                  apiMiniAppPublishPost({ id: String(listId) }).then(_res => {
                    message.success('发布成功');
                    navigate('/platform/miniProgram/manage');
                  });
                }}
              >
                {partOneTwoData?.status === StatusEnum.PUBLISHED ? '升级' : '发布'}
              </Button>
            )}
            {current === 2 && partOneTwoData?.packFlag && (
              <>
                <Button
                  type="primary"
                  onClick={() => {
                    next();
                    setIsNext(true);
                  }}
                >
                  下一步
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    onNext();
                  }}
                >
                  重新打包
                </Button>
              </>
            )}
            {current > 0 && (
              <Button
                style={{ margin: '0 8px' }}
                onClick={() => {
                  prev();
                  if (current === 3) {
                    setIsNext(false);
                    refreshFn();
                  }
                }}
              >
                上一步
              </Button>
            )}
          </Space>
        </div>
      </Wrapper>
    </>
  );
};

export default MiniProgramPack;
