import React, { useState, useMemo, useEffect } from 'react';
import { Tree, Form, Input, Space, Popconfirm, FormInstance } from 'antd';
import { DataNode } from 'antd/es/tree';
import { v4 as uuid } from 'uuid';
import listToTree from './listToTree';
import { Button } from '@maxtropy/components';

export interface CustomAttribute {
  key: string;
  isGroup: boolean;
  label: string;
  value: string;
  parentKey?: string;
  depth: number;
}
export interface CustomAttributesEditorProps {
  form: FormInstance;
  customAttributes: CustomAttribute[];
  setCustomAttributes: (attributes: CustomAttribute[]) => void;
}

const cnNumbers = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'];

const CustomAttributesEditor: React.FC<CustomAttributesEditorProps> = ({
  form,
  customAttributes,
  setCustomAttributes,
}) => {
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  useEffect(() => {
    if (!customAttributes.length) {
      setCustomAttributes([{ key: uuid(), isGroup: true, label: '', value: '', depth: 0 }]);
    } else if (!expandedKeys.length) {
      setExpandedKeys(customAttributes.map(item => item.key));
    }
  }, [customAttributes]);

  const generateTreeNode = ({ key, label, value, depth, isGroup, parentKey }: CustomAttribute): DataNode => {
    return {
      key,
      title: (
        <Space size={15} align="start" className="action-buttons" style={{ marginBottom: 10, fontSize: 12 }}>
          <Form.Item
            style={{ marginBottom: 0 }}
            name={key + '_label'}
            label={isGroup ? `${cnNumbers[depth] || depth}级属性组名称` : '属性名称'}
            initialValue={label}
            rules={[{ required: true }]}
          >
            <Input placeholder="请输入" />
          </Form.Item>
          {isGroup ? (
            <>
              <Button
                size="small"
                type="link"
                onClick={() => {
                  const index = customAttributes.findIndex(item => item.key === key);
                  const copy = customAttributes.slice();
                  copy.splice(index + 1, 0, {
                    label: '',
                    value: '',
                    key: uuid(),
                    isGroup: true,
                    parentKey,
                    depth,
                  });
                  setCustomAttributes(copy);
                }}
              >
                添加同级属性组
              </Button>
              <Button
                size="small"
                type="link"
                onClick={() => {
                  setCustomAttributes([
                    ...customAttributes,
                    {
                      label: '',
                      value: '',
                      key: uuid(),
                      isGroup: true,
                      parentKey: key,
                      depth: depth + 1,
                    },
                  ]);
                  setExpandedKeys([...expandedKeys, key]);
                  setAutoExpandParent(true);
                }}
              >
                添加子属性组
              </Button>
              <Button
                size="small"
                type="link"
                onClick={() => {
                  // 属性放到属性组前面
                  const branch = customAttributes.filter(item => item.parentKey === key);
                  const index = branch.filter(item => !item.isGroup).length;
                  const copy = [...customAttributes];
                  copy.splice(index, 0, {
                    label: '',
                    value: '',
                    key: uuid(),
                    isGroup: false,
                    parentKey: key,
                    depth: depth + 1,
                  });
                  setCustomAttributes(copy);
                  setExpandedKeys([...expandedKeys, key]);
                  setAutoExpandParent(true);
                }}
              >
                添加属性
              </Button>
            </>
          ) : (
            <>
              <Form.Item style={{ marginBottom: 0 }} name={key + '_value'} label="属性值" initialValue={value}>
                <Input placeholder="请输入" />
              </Form.Item>
            </>
          )}
          <Popconfirm
            disabled={!parentKey && customAttributes.filter(item => !item.parentKey).length === 1}
            placement="topRight"
            title={
              <>
                <div>确定删除？</div>
                <div style={{ color: '#f5222d' }}>
                  子属性组及属性都会被删除
                  <span style={{ color: 'var(--disabled-color)' }}>，你还要继续吗？</span>
                </div>
              </>
            }
            onConfirm={() => {
              const copy = customAttributes.slice();
              const del = (items: CustomAttribute[]) => {
                const childs = items.reduce(
                  (acc, curr) => [...acc, ...copy.filter(item => item.parentKey === curr.key)],
                  [] as CustomAttribute[]
                );
                childs.length && del(childs);
                items.forEach(item => copy.splice(copy.indexOf(item), 1));
              };
              del(copy.filter(item => item.parentKey === key));
              copy.splice(
                customAttributes.findIndex(item => item.key === key),
                1
              );
              setCustomAttributes(copy);
            }}
          >
            <Button
              size="small"
              type="link"
              danger
              disabled={!parentKey && customAttributes.filter(item => !item.parentKey).length === 1}
            >
              删除
            </Button>
          </Popconfirm>
        </Space>
      ),
    };
  };

  const treeData = useMemo(() => {
    return listToTree(
      customAttributes.map(item => ({
        parentKey: item.parentKey,
        ...generateTreeNode(item),
      }))
    );
  }, [customAttributes]);

  return (
    <Form form={form} style={{ padding: '20px 0 60px' }}>
      <Tree
        selectable={false}
        showLine={{ showLeafIcon: true }}
        treeData={treeData}
        expandedKeys={expandedKeys}
        onExpand={keys => {
          setExpandedKeys(keys as string[]);
          setAutoExpandParent(false);
        }}
        autoExpandParent={autoExpandParent}
      />
    </Form>
  );
};

export default CustomAttributesEditor;
