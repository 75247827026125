import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AttributeCanvas from './AttributeCanvas';
import AttributeList from './AttributeList';
import { Button, Cascader, Col, Form, Input, Row, Select, Space, Spin } from 'antd';
import styles from './index.module.scss';
import { Breadcrumb } from '@maxtropy/components';
import { DeviceAttribute, DeviceType } from '@/shared/types';
import { useQuery } from '../../utils/utils';
import {
  addTemplate,
  getDeviceTypeTree,
  getTemplate,
  getTypeAttributeListBydeviceType,
  getTypeAttributeListByPhysicalModel,
  updateTemplate,
} from '../../api/template';
import { useForm } from 'antd/es/form/Form';
import { useNavigate, useParams } from 'react-router-dom';
import CustomAttributesEditor, { CustomAttribute } from '@/shared/components/CustomAttributes/Editor';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { formatDeviceTypeToDataNode } from '@/shared/utils/utils';

import { getPhysicalModelList, PhysicalModelRes } from '../../api/device';

export interface TemplateFormProps {
  isEdit?: boolean;
  isCopy?: boolean;
}

const TemplateForm: React.FC<TemplateFormProps> = ({ isEdit = false, isCopy = false }) => {
  const navigate = useNavigate();
  const [attributes, setAttributes] = useState<DeviceAttribute[]>([]);
  const [showDesc, setShowDesc] = useState(false);

  const routes = useMemo(() => {
    return [
      { name: 'Iot配置' },
      { name: '设备信息模板库' },
      { name: '固定属性模板' },
      { name: `${isEdit ? '编辑' : '新建'}固定属性模板` },
    ];
  }, [isEdit]);

  const usedAttributeIds = useMemo(() => attributes.map(item => item.id), [attributes]);
  const [customAttributes, setCustomAttributes] = useState<CustomAttribute[]>([]);
  const { data: deviceTypes } = useQuery(useCallback(() => getDeviceTypeTree(), []));
  const [deviceType, setDeviceType] = useState<number>();

  const [form] = useForm();
  const [descriptionForm] = useForm();

  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useQuery(
    useCallback(() => (isEdit || isCopy ? getTemplate(+id!) : Promise.resolve(undefined)), [id, isCopy, isEdit])
  );

  const physicalModelId = Form.useWatch('physicalModelId', form);

  const { data: availableAttributes, isLoading: listLoading } = useQuery(
    useCallback(() => {
      if (deviceType && physicalModelId) {
        return getTypeAttributeListByPhysicalModel(physicalModelId);
      }
      if (deviceType) {
        return getTypeAttributeListBydeviceType(deviceType);
      }
      return Promise.resolve(undefined);
    }, [deviceType, physicalModelId])
  );

  useEffect(() => {
    if (data) {
      form.resetFields();
      setDeviceType(data.template.deviceTypeId);
      setAttributes(data.template.deviceAttributes);
      const desc = data.template.description;
      setCustomAttributes(desc ? JSON.parse(desc) : []);
      setShowDesc(!!desc);
    }
  }, [data, form]);

  useEffect(() => {
    if (isCopy && data && deviceTypes) {
      let second: DeviceType;
      const first = deviceTypes.tree.find(item => {
        const mid = item.deviceTypes!.find(
          type => !!type.children!.find(child => child.id === data.template.deviceTypeId)
        );
        if (mid) {
          second = mid;
          return true;
        }
        return false;
      });
      form.setFieldsValue({
        deviceTypeId: [first?.id, second!.id, data.template.deviceTypeId],
      });
    } else if (data) {
      form.setFieldsValue({
        deviceTypeId: [data.template.deviceTypeId],
      });
    }
    form.setFieldsValue({
      physicalModelId: data?.template.physicalModelId,
    });
  }, [data, deviceTypes, form, isCopy]);

  const showDescCheckbox = useMemo(
    () => availableAttributes && availableAttributes.attributes.length > 0,
    [availableAttributes]
  );

  const [submitting, setSubmitting] = useState(false);
  const onSubmit = (values: any) => {
    const submit = (description?: string) => {
      const params = {
        name: values.name,
        deviceTypeId: deviceType!,
        remark: values.remark,
        attributeList: attributes.map(item => item.id),
        description: description || '',
        physicalModelId: values.physicalModelId,
      };
      const request = isEdit ? () => updateTemplate({ ...params, id: +id! }) : () => addTemplate(params);

      setSubmitting(true);
      request()
        .then(() => {
          navigate(`/device/info/template${window.location.search}`, {
            replace: true,
          });
        })
        .finally(() => setSubmitting(false));
    };

    if (!showDescCheckbox || showDesc) {
      descriptionForm.validateFields().then(descValues => {
        const description = JSON.stringify(
          customAttributes.map(item => ({
            ...item,
            label: descValues[item.key + '_label'],
            value: descValues[item.key + '_value'],
          }))
        );
        submit(description);
      });
    } else {
      submit();
    }
  };
  const [physicalModelList, setPhysicalModelList] = useState<PhysicalModelRes[]>([]); // all 物模型型号列表

  useEffect(() => {
    if (deviceType) {
      // 物模型型号
      getPhysicalModelList({ deviceTypeIds: [deviceType] }).then(setPhysicalModelList);
    }
  }, [deviceType]);

  const objectModalTypeOptions = useMemo(() => {
    if (physicalModelList && physicalModelList.length > 0) {
      return physicalModelList.map(i => ({ label: `${i.modelNo}-${i.manufacturerName}`, value: i.id }));
    }
  }, [physicalModelList]);
  const onObjectModalTypeChange = () => {
    setAttributes([]);
    setCustomAttributes([]);
    setShowDesc(false);
  };
  return (
    <Spin spinning={isLoading}>
      <div className={styles.container}>
        <div className={styles.breadcrumb}>
          <Breadcrumb routes={routes} />
        </div>

        <div className={styles.form}>
          <Form
            form={form}
            initialValues={{
              name: data?.template.name,
              remark: data?.template.remark,
            }}
            onFinish={onSubmit}
          >
            <Row gutter={15}>
              <Col span={6}>
                <Form.Item required label="模板名称" name="name" rules={[{ required: true, max: 20 }]}>
                  <Input placeholder="请输入" />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  required
                  label="所属类目"
                  name="deviceTypeId"
                  rules={[{ required: true, message: '请选择所属类目' }]}
                >
                  {isEdit ? (
                    <div>{data?.template.deviceTypeName}</div>
                  ) : (
                    <Cascader
                      allowClear={false}
                      options={formatDeviceTypeToDataNode(deviceTypes?.tree ?? [])}
                      fieldNames={{ label: 'name', value: 'id' }}
                      onChange={(e: any) => {
                        if (e.length < 3) {
                          form.setFieldsValue({ deviceTypeId: undefined });
                        }
                        setDeviceType(e[2] as number | undefined);
                        setAttributes([]);
                        setCustomAttributes([]);
                        setShowDesc(false);
                        form.setFieldsValue({
                          physicalModelId: undefined,
                        });
                      }}
                      placeholder="请选择"
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item name="physicalModelId" label="物模型型号">
                  {isEdit ? (
                    <div>{data?.template.modelNo}</div>
                  ) : (
                    <Select
                      allowClear
                      optionFilterProp="label"
                      showSearch
                      onChange={onObjectModalTypeChange}
                      options={objectModalTypeOptions}
                      disabled={!form.getFieldValue('deviceTypeId')}
                      placeholder={form.getFieldValue('deviceTypeId') ? '请选择' : '请先选择所属类目'}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="版本号">
                  <div className={styles.version}>{(!isCopy && data?.template.lastVersion) || 1}</div>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="模板备注" name="remark" style={{ marginLeft: 10 }} rules={[{ max: 100 }]}>
              <Input.TextArea style={{ width: 487, resize: 'none' }} placeholder="请输入" rows={4} />
            </Form.Item>
          </Form>
        </div>

        <div className={styles.body}>
          {availableAttributes?.attributes?.length ? (
            <AttributeList
              usedAttributeIds={usedAttributeIds}
              deviceType={deviceType}
              data={availableAttributes}
              isLoading={listLoading}
            />
          ) : (
            <div />
          )}
          <div className={styles.attributeCanvas}>
            <AttributeCanvas
              attributes={attributes}
              setAttributes={setAttributes}
              style={showDescCheckbox ? { minHeight: 20 } : undefined}
            />

            <div>
              {showDescCheckbox && (
                <>
                  <div
                    style={{
                      borderTop: '1px dashed #D9D9D9',
                      marginBottom: 20,
                    }}
                  />
                  <Checkbox
                    style={!showDesc ? { marginBottom: 50 } : undefined}
                    checked={showDesc}
                    onChange={e => setShowDesc(e.target.checked)}
                  >
                    添加描述信息
                  </Checkbox>
                </>
              )}
              {deviceType && (!showDescCheckbox || showDesc) && (
                <CustomAttributesEditor
                  form={descriptionForm}
                  customAttributes={customAttributes}
                  setCustomAttributes={setCustomAttributes}
                />
              )}
            </div>

            <Space size={10} className={styles.footer}>
              <Button type="primary" onClick={() => form.submit()} loading={submitting}>
                确定
              </Button>
              <Button
                onClick={() =>
                  navigate(`/device/info/template${window.location.search}`, {
                    replace: true,
                  })
                }
              >
                返回
              </Button>
            </Space>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default TemplateForm;
