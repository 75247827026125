import { FC, useCallback, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Wrapper, FormContent, useAsync } from '@maxtropy/components';
import FormDataPermission, { ContentProps } from '../components/FormDataPermission';

import { getAllTenant } from '../../../api/cc-tenant';
import {
  getCorpUserDataPermission,
  upsertCropUserDataPermissions,
  UpsertCropUserDataPermissionRequest,
} from '../../../api/cc-corp';

import styles from '../index.module.scss';

import { Button, Col, Form, Row, Space } from 'antd';

const { useForm } = Form;

const tailLayout = {
  wrapperCol: { offset: 2, span: 16 },
};

const routes = [{ name: '系统设置' }, { name: '平台组织权限和账户' }, { name: '员工管理' }, { name: '数据权限分配' }];

const DataPermissionAssignment: FC<{ isEdit?: boolean }> = ({ isEdit = true }) => {
  const [form] = useForm();
  const { corpUserId } = useParams<{ corpUserId: string }>();
  const navigate = useNavigate();

  const tenantCallback = useCallback(() => getAllTenant({}, false), []);

  const tenants = useAsync(tenantCallback);

  const getCorpUserPermissionCallback = useCallback(() => {
    return getCorpUserDataPermission(Number(corpUserId));
  }, [corpUserId]);
  const dataPermissions = useAsync(getCorpUserPermissionCallback);

  useEffect(() => {
    const values = (dataPermissions ?? []).map(i => i.mcid);
    form.setFieldsValue({ mcids: values });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPermissions]);

  const content = useMemo<ContentProps[]>(() => {
    if (!tenants) return [];
    return tenants.map(i => ({
      id: i.mcid,
      name: `${i.name}`,
    }));
  }, [tenants]);

  const onFinish = (value: Omit<UpsertCropUserDataPermissionRequest, 'corpUserId'>) => {
    const data: UpsertCropUserDataPermissionRequest = {
      ...value,
      corpUserId: Number(corpUserId),
    };
    upsertCropUserDataPermissions(data).then(() => {
      navigate('/platform/account/employee');
    });
  };

  return (
    <Wrapper routes={routes}>
      <div className={styles.container}>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <FormContent title="租户项目数据权限范围">
            <Form.Item name="mcids" label="租户选择">
              <FormDataPermission content={content} disabled={!isEdit} />
            </Form.Item>
          </FormContent>
          <Row className={styles.buttonWrapper}>
            <Col span={7}>
              <Form.Item {...tailLayout}>
                <Space>
                  {isEdit ? (
                    <>
                      <Button
                        onClick={() => {
                          navigate(`/platform/account/employee/function/edit/${corpUserId}`);
                        }}
                      >
                        上一步
                      </Button>
                      <Button htmlType="submit" type="primary">
                        完成
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        type="primary"
                        onClick={e => {
                          e.preventDefault();
                          navigate(`/platform/account/employee`);
                        }}
                      >
                        关闭
                      </Button>
                      <Button
                        onClick={e => {
                          e.preventDefault();
                          navigate(`/platform/account/employee/function/show/${corpUserId}`);
                        }}
                      >
                        上一步
                      </Button>
                    </>
                  )}
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Wrapper>
  );
};

export default DataPermissionAssignment;
