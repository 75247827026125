import { Wrapper, FormContent, EllipsisSpan, Paging, usePaging, Table } from '@maxtropy/components';
import { Form, Button, Checkbox, Badge, Popconfirm, Spin, Space } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import EdgeDeviceTemplateDisplay from '@/shared/components/EdgeDeviceTemplateForm/EdgeDeviceTemplateDisplay';
import { EdgeDeviceTemplateStatus, EdgeDeviceTemplateStatusDisplay, EdgeDeviceTemplateVersion } from '@/shared/types';
import {
  changeEdgeTemplateVersionStatus,
  EdgeTemplateVersionPageRequest,
  getEdgeTemplate,
  getEdgeTemplateVersionPage,
} from '../../api/edgeTemplate';
import { useHasPermission, useQuery } from '../../utils/utils';
import styles from './index.module.scss';
import { PermissionsType } from '@/common/permissionsConst';

export interface EdgeDeviceVersionListProps {}

const routes = [{ name: 'Iot配置' }, { name: '数采配置' }, { name: '数采模板' }, { name: '历史版本' }];

const columns = [
  {
    title: '版本号',
    dataIndex: 'version',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '使用中的设备数量',
    dataIndex: 'usedByDevice',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={String(v)} />,
  },
  {
    title: '版本状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (v: EdgeDeviceTemplateStatus) => (
      <Badge
        status={v === EdgeDeviceTemplateStatus.USED ? 'success' : 'error'}
        text={EdgeDeviceTemplateStatusDisplay[v]}
      />
    ),
  },
  {
    title: '版本备注',
    dataIndex: 'remark',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '最后操作人',
    dataIndex: 'updateByUsername',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

type SearchParams = Omit<EdgeTemplateVersionPageRequest, 'page' | 'size'>;

const EdgeDeviceVersionList: React.FC<EdgeDeviceVersionListProps> = () => {
  const { id } = useParams<{ id: string }>();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchParams>({
    displayAbolished: false,
  });

  const hasExport = useHasPermission(PermissionsType.B_EXPORTDEVICECONFIG);

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        getEdgeTemplateVersionPage(+id!, {
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) {
            setTotalCount(res.total);
          }
          return res.list;
        }),
      [id, pageOffset, pageSize, searchParams, setTotalCount]
    )
  );

  const { data: info, isLoading: isInfoLoading } = useQuery(useCallback(() => getEdgeTemplate(+id!), [id]));

  const changeStatus = (id: number, status: EdgeDeviceTemplateStatus) => {
    changeEdgeTemplateVersionStatus(id, status).then(() => setSearchParams({ ...searchParams }));
  };

  const render = (record: EdgeDeviceTemplateVersion) => (
    <Space size={16} className="action-buttons">
      {record.status !== EdgeDeviceTemplateStatus.ABOLISHED && (
        <>
          <Popconfirm
            okText="继续"
            placement="topRight"
            title={
              <>
                <div>确定作废？</div>
                <div style={{ color: '#f5222d' }}>
                  作废后不可恢复
                  <span style={{ color: 'rgba(0, 0, 0, 0.3)' }}>，你还要继续吗？</span>
                </div>
              </>
            }
            onConfirm={() => changeStatus(record.id, EdgeDeviceTemplateStatus.ABOLISHED)}
          >
            <Button type="link">作废</Button>
          </Popconfirm>
          <Button
            type="link"
            onClick={() =>
              changeStatus(
                record.id,
                record.status === EdgeDeviceTemplateStatus.USED
                  ? EdgeDeviceTemplateStatus.UNUSED
                  : EdgeDeviceTemplateStatus.USED
              )
            }
          >
            {record.status === EdgeDeviceTemplateStatus.USED ? '禁用' : '启用'}
          </Button>
        </>
      )}
      <Button type="link">
        <Link to={`/device/edge/template/${id}?versionId=${record.id}`}>查看</Link>
      </Button>
      {hasExport && (
        <Button
          type="link"
          onClick={() => {
            window.open(
              `/api/v2/edgeTemplate/download/point?templateId=${record.templateId}&templateVersionId=${record.id}&pointType=1`
            );
          }}
        >
          导出
        </Button>
      )}
    </Space>
  );

  return (
    <Wrapper className={styles.wrapper} routes={routes}>
      <FormContent title="基础信息">
        <Spin spinning={isInfoLoading}>
          <div className={styles.info}>
            {info && <EdgeDeviceTemplateDisplay type={info.iotProtocol} row={info} />}

            <Form.Item label="最新版本号">
              {info?.latestVersion ? <div className={styles.version}>{info?.latestVersion}</div> : '-'}
            </Form.Item>
          </div>
        </Spin>
      </FormContent>
      <FormContent title="版本信息">
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginBottom: 15,
          }}
        >
          <Checkbox
            checked={searchParams.displayAbolished}
            onChange={e =>
              setSearchParams({
                ...searchParams,
                displayAbolished: e.target.checked,
              })
            }
          >
            显示已作废模板
          </Checkbox>
        </div>
        <div style={{ paddingBottom: 20 }}>
          <Table
            sticky
            rowKey="id"
            dataSource={data}
            loading={isLoading}
            scroll={{ x: 1900 }}
            columns={[...columns, { title: '操作', fixed: 'right', width: 180, render }]}
          />
          <Paging pagingInfo={pagingInfo} />
        </div>
      </FormContent>
    </Wrapper>
  );
};

export default EdgeDeviceVersionList;
