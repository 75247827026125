import { V2RuleInfoPostResponse } from '@maxtropy/device-mgmt-apis-v2';
import { Descriptions } from 'antd';

export const LongShineDetailItem = (info?: V2RuleInfoPostResponse) => {
  return (
    <>
      <Descriptions.Item label="三方报警码">{info?.thirdAlarmCode} </Descriptions.Item>
    </>
  );
};
