import {
  DeviceTypeWithNestedChildren,
  TreeWithNestedDeviceTypes,
} from "./types";

// 处理类目树数据
export function transformDataForCascader(
  treeData: TreeWithNestedDeviceTypes[]
) {
  if (!treeData || !treeData.length) {
    return [];
  }

  return treeData.map((node) => ({
    label: node.name || "",
    value: node.id !== undefined ? String(node.id) : "",
    children: node.deviceTypes
      ? transformDataForDeviceTypes(node.deviceTypes)
      : [],
  }));
}

function transformDataForDeviceTypes(
  deviceTypes: DeviceTypeWithNestedChildren[]
) {
  if (!deviceTypes || !deviceTypes.length) {
    return [];
  }

  return deviceTypes.map((deviceType) => ({
    label: deviceType.name || "",
    value: deviceType.id !== undefined ? String(deviceType.id) : "",
    children: deviceType.children
      ? transformDataForChildren(deviceType.children)
      : [],
  }));
}

function transformDataForChildren(children: any) {
  if (!children || !children.length) {
    return [];
  }

  return children.map((child: any) => ({
    label: child.name || "",
    value: child.id !== undefined ? String(child.id) : "",
    children: child.children ? transformDataForChildren(child.children) : [],
  }));
}
