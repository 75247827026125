import { fetch, PromiseWithErrorHandler } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import qs from 'qs';
import { AlarmStatus, IotProtocolType, Operator, AlarmChannel } from '@/shared/types';
import { Key } from 'react';
import { RulePageProps } from './rule';

export interface RuleGroupPageRequest extends PageRequest {
  nameOrCode?: string;
  status?: AlarmStatus;
  rootMcid?: string;
}

export interface RuleGroupPageProps {
  id: number;
  code: string;
  name: string;
  status: AlarmStatus;
  channel: AlarmChannel;
  rootMcid: string;
  rootName: string;
  rootFullName: string;
  tenantName: string;
  tenantCode?: string;
  useDeviceCount?: number;
  iotProtocol: IotProtocolType;
  updateSource: Operator;
  updateByUserId: number;
  updateByUsername: string;
  updateTime: string;
  deviceTypeName?: string;
  deviceTypeId?: number;
}

export function getRuleGroupPage(query: RuleGroupPageRequest): Promise<PageResponse<RuleGroupPageProps>> {
  return fetch(`/api/rule/group/page?${qs.stringify(query, { indices: false })}`);
}

export function deleteRuleGroupById(id: number) {
  return fetch(`/api/rule/group/${id}`, {
    method: 'DELETE',
  });
}

export interface CreateRuleGroupRequest {
  name: string;
  rootMcid: string;
  iotProtocol: IotProtocolType;
  ruleIds: number[];
  channel?: AlarmChannel;
  deviceTypeId?: number;
}

export function createRuleGroup(params: CreateRuleGroupRequest): Promise<RuleGroupPageProps> {
  return fetch(`/api/rule/group/add`, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'x-tenant-mcid': `${params.rootMcid}`,
    },
  });
}

export interface UpdateRuleGroupRequest extends Omit<CreateRuleGroupRequest, 'rootMcid' | 'iotProtocol' | 'ruleIds'> {
  id: number;
  deleteRuleIds: number[];
  addRuleIds: number[];
  deviceTypeId?: number;
  channel?: AlarmChannel;
  rootMcid?: string;
}

export function updateRuleGroup(params: UpdateRuleGroupRequest): Promise<RuleGroupPageProps> {
  return fetch(`/api/rule/group/update`, {
    method: 'PUT',
    body: JSON.stringify(params),
    headers: {
      'x-tenant-mcid': `${params.rootMcid}`,
    },
  });
}

export function getRuleGroupInfo(id: Key): Promise<RuleGroupPageProps> {
  return fetch(`/api/rule/group/info?id=${id}`);
}

export function disableRuleGroup(id: number) {
  return fetch(`/api/rule/group/disable/${id}`, {
    method: 'PUT',
  });
}

export function enableRuleGroup(id: number) {
  return fetch(`/api/rule/group/enable/${id}`, {
    method: 'PUT',
  });
}

// export interface RulesListByIdProps {
//   id: number;
//   code: string;
//   name: string;
//   status: AlarmStatus;
//   type: AlarmType;
//   alarmName: string;
//   alarmType: AlarmLevel;
//   rootMcid: string;
//   iotProtocol: IotProtocolType;
//   updateSource: Operator;
//   updateByUserId: number;
//   updateByUsername: string;
// }

export function getRulesListById(id: Key): Promise<RulePageProps[]> {
  return fetch(`/api/rule/group/getRules/list?id=${id}`);
}

export interface RulesPageByIdRequest extends PageRequest {
  id: number;
}

export function getRulesPageById(query: RulesPageByIdRequest): PromiseWithErrorHandler<PageResponse<RulePageProps>> {
  return fetch(`/api/rule/group/getRules/page?${qs.stringify(query, { indices: false })}`);
}

export interface DeviceRuleGroupPageRequest extends PageRequest {
  nameOrCode?: string;
  deviceId?: string;
}

export interface DeviceRuleGroupPageResponse {
  id: number;
  code: string;
  name: string;
  rulleCount: number;
}

export function getDeviceRuleGroupPage(
  query: DeviceRuleGroupPageRequest
): Promise<PageResponse<DeviceRuleGroupPageResponse>> {
  return fetch(`/api/rule/group/device/page?${qs.stringify(query, { indices: false })}`);
}

export interface DeviceRuleGroupIdResponse {
  deviceId: number;
  ruleGroupId: number;
}

export function getDeviceRuleGroupId(deviceId: string): Promise<DeviceRuleGroupIdResponse> {
  return fetch(`/api/rule/group/device/${deviceId}`);
}

export function updateDeviceRuleGroupId(params: DeviceRuleGroupIdResponse, tenantMcid: string): Promise<boolean> {
  return fetch(`/api/rule/group/device/assign`, {
    method: 'PUT',
    body: JSON.stringify(params),
    headers: {
      'x-tenant-mcid': tenantMcid,
    },
  });
}

export function unBindDeviceRuleGroup(deviceId?: number): Promise<boolean> {
  return fetch(`/api/rule/group/device/unbinding-rule-group/${deviceId}`, {
    method: 'PUT',
  });
}

export interface BatchRuleGroupDeviceRequest extends PageRequest {
  name?: string;
  rootMcid: string;
  deviceTypeId?: number;
  iotProtocol: IotProtocolType;
  deviceType: Array<number>;
  physicalModelId?: Key;
}

export interface BatchRuleGroupDeviceResponse {
  id: number;
  code: string;
  name: string;
  typeName: string;
  customerName: string;
}

export function getBatchRuleGroupDevice(
  query: BatchRuleGroupDeviceRequest
): Promise<PageResponse<BatchRuleGroupDeviceResponse>> {
  return fetch(`/api/rule/group/device/page-device-binding?${qs.stringify(query, { indices: false })}`);
}

export interface BatchRuleGroupPageRequest extends PageRequest {
  nameOrCode?: string;
  rootMcid: string;
  deviceTypeId?: number;
  iotProtocol: IotProtocolType;
}

export interface BatchRuleGroupPageResponse {
  id: number;
  code: string;
  name: string;
  ruleCount: number;
}

export function getBatchRuleGroupPage(
  query: BatchRuleGroupPageRequest
): Promise<PageResponse<BatchRuleGroupPageResponse>> {
  return fetch(`/api/rule/group/device/page-rule-group?${qs.stringify(query, { indices: false })}`);
}

export interface BatchDeviceBindingRuleGroupRequest {
  deviceIdList: number[];
  ruleGroupId: number;
}

export function batchDeviceBindingRuleGroup(
  params: BatchDeviceBindingRuleGroupRequest,
  tenantMcid: string
): Promise<boolean> {
  return fetch(`/api/rule/group/device/batch-binding`, {
    method: 'PUT',
    body: JSON.stringify(params),
    headers: {
      'x-tenant-mcid': tenantMcid,
    },
  });
}
