import React, { FC, useEffect, useState } from 'react';
import { Col, Form, message, Row, Space, Spin } from 'antd';
import { getRealUrl, Modal, Button, Tag, useUpdate } from '@maxtropy/components';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import { Status, StatusColorMap, StatusDisplay, Type } from '../List/listTypes';
import {
  apiV2ValueDelRecoveryPost,
  apiV2ValueDelDetailPost,
  V2ValueDelDetailPostResponse,
} from '@maxtropy/tody-mgmt-apis-v2';

const { Item } = Form;
const dateFormat = 'YYYY-MM-DD HH:mm:ss';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  id: number;
  updateFn: () => void;
}

const DeleteDetailModal: FC<Props> = props => {
  const { open, setOpen, id, updateFn } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [detailData, setDetailData] = useState<V2ValueDelDetailPostResponse>();
  const [updateCur, updateCurFn] = useUpdate();

  useEffect(() => {
    if (!open) {
      setDetailData(undefined);
    }

    if (open && id) {
      setLoading(true);
      apiV2ValueDelDetailPost({ id })
        .then(res => {
          if (res.id !== id) return;
          setDetailData(res);
        })
        .finally(() => setLoading(false));
    }
  }, [open, id, updateCur]);

  const onDownload = () => {
    window.open(getRealUrl(detailData?.key));
  };

  const onRecovery = () => {
    apiV2ValueDelRecoveryPost({ ids: [detailData?.id!] }).then(res => {
      message.info('操作成功');
      updateFn();
      updateCurFn();
    });
  };

  return (
    <Modal
      title="删除数据详情"
      open={open}
      onCancel={() => setOpen(false)}
      footer={
        <Button type="primary" onClick={() => setOpen(false)}>
          关闭
        </Button>
      }
    >
      <Spin spinning={loading}>
        <Form layout="vertical" className={styles.form}>
          <Row>
            <Col span={12}>
              <Item label="选择租户">
                <div>{detailData?.tenantName}</div>
              </Item>
            </Col>
            <Col span={12}>
              <Item label="状态">
                <Tag border="solid" color={StatusColorMap[detailData?.status as Status]}>
                  {StatusDisplay[detailData?.status as Status]}
                </Tag>
              </Item>
            </Col>
          </Row>
          <Item label="选择类目">
            <div>{detailData?.deviceTypeName}</div>
          </Item>
          <Item label="选择设备">
            <div>{detailData?.deviceName}</div>
          </Item>
          <Item label="选择属性">
            <div>{detailData?.propertyName}</div>
          </Item>
          <Item label="数据删除区间">
            <div>
              {`${dayjs(detailData?.deleteStartTime as string).format(dateFormat)} ~ ${dayjs(
                detailData?.deleteEndTime as string
              ).format(dateFormat)}`}
            </div>
          </Item>
          <Item label="删除数据">
            <Space size={8}>
              {detailData?.type === Type.EXCEPTION_FILTERING_DELETED ? (
                detailData?.deletedValue
              ) : (
                <Button type="link" onClick={onDownload}>
                  下载
                </Button>
              )}
              <Button
                type="link"
                disabled={detailData?.status === Status.RECOVERED || detailData?.type === Type.MANUALLY_DELETED}
                onClick={onRecovery}
              >
                恢复数据
              </Button>
            </Space>
          </Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export default DeleteDetailModal;
