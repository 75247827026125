import qs from 'qs';
import { Key } from 'react';
import { PageRequest, PageResponse } from './page';
import { fetch } from '@maxtropy/components';

export interface AIReportList {
  display: boolean; // 呈现状态
  fileKey: string; // 附件阿里云key
  id: Key;
  name: string; // 报告类型名称
  tenantMcid: string; // 呈现租户
  tenantName: string; // 呈现租户名称
  analysisDate: string; // 分析日期
  createTime: string; // 上传时间
}

export interface PageSearchParams extends PageRequest {
  tenantMcid?: string;
  name?: string;
}

export enum AIReportStatus {
  HIDDEN = 1, // 隐藏
  SHOW = 2, // 显示
}

export const AIReportStatusValue = {
  [AIReportStatus.HIDDEN]: '隐藏', // 隐藏
  [AIReportStatus.SHOW]: '显示', // 显示
};

// 获取AI报告分页查询
export function getAIReportPage(query: PageSearchParams): Promise<PageResponse<AIReportList>> {
  return fetch(`/api/aiReport/page?${qs.stringify(query, { indices: false })}`);
}

// 删除报告
export function delAIReport(aIReportId: Key) {
  return fetch(`/api/aiReport/${aIReportId}`, {
    method: 'DELETE',
  });
}

// 根据AI报告id进行状态变更
export function updateAIReportStatus(aIReportId: number, params: { display: boolean }): Promise<void> {
  return fetch(`/api/aiReport/${aIReportId}/display`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });
}

export interface AddAIReportBody {
  name: string; // 报告名称
  analysisDate: string; // 分析时间
  tenantMcid: string; // 所属租户
  resource: string; // 附件阿里云key
  // reportTime: string; // 报告时间
}

// 新增AI报告
export function addAIReport(body: AddAIReportBody) {
  return fetch(`/api/aiReport`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
}
