import { FC, useCallback, useEffect, useState } from 'react';
import { Col, DatePicker, Descriptions, Form, InputNumber, Modal, Row, Space } from 'antd';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  DataZoomComponent,
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

import { PreviewRuleContent, PreviewRuleRequest, RuleContent } from '../../api/entities';
import dayjs, { Dayjs } from 'dayjs';
import { previewRule } from '../../api/mockRule';
import { isNil } from 'lodash-es';

const { useForm, useWatch } = Form;

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  DataZoomComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
]);

interface PreviewRuleModalProps {
  previewValue?: RuleContent;
  onCancel?: () => void;
}

interface FormContent extends Omit<PreviewRuleRequest, 'startTime'> {
  startTime: Dayjs;
  totalDuration: number;
}

function getOption(data: PreviewRuleContent[]) {
  return {
    tooltip: {
      trigger: 'axis',
      position: function (pt: Array<any>) {
        return [pt[0], '10%'];
      },
    },
    title: {
      left: 'center',
      text: '预览数据',
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: 'none',
        },
        restore: {},
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'time',
      boundaryGap: false,
    },
    yAxis: {
      type: 'value',
      scale: true,
      boundaryGap: [0, '100%'],
    },
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100,
      },
      {
        start: 0,
        end: 100,
      },
    ],
    series: [
      {
        name: '数据',
        type: 'line',
        // smooth: true,
        symbol: data.length < 288 ? 'emptyCircle' : 'none',
        areaStyle: {},
        data: data.map(i => [i.time, i.value]),
      },
    ],
  };
}

const PreviewRuleModal: FC<PreviewRuleModalProps> = ({ previewValue, onCancel }) => {
  const [form] = useForm<FormContent>();

  const [loading, setLoading] = useState<boolean>(false);

  const [data, setData] = useState<PreviewRuleContent[]>([]);

  const [hasChange, setHasChange] = useState<boolean>(false);

  const resetValue = useCallback(() => {
    form.setFieldsValue({
      cycle: 300,
      count: 288,
      startTime: dayjs().startOf('date'),
      totalDuration: 24,
    });
  }, [form]);

  const cycle = useWatch('cycle', form);
  const count = useWatch('count', form);

  useEffect(() => {
    if (cycle && count) {
      form.setFieldsValue({
        totalDuration: (cycle * count) / 3600,
      });
    }
  }, [cycle, form]);

  const preViewValue = useCallback(
    (value: FormContent) => {
      setLoading(true);
      if (isNil(previewValue)) return;
      previewRule({
        ...previewValue,
        ...value,
        params: value.params ?? [],
        startTime: value.startTime.valueOf(),
      })
        .then(res => {
          setData(res);
          setHasChange(false);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [previewValue]
  );

  const onBlur = () => {
    if (hasChange && form.getFieldsError().every(i => i.errors.length === 0)) {
      form.validateFields().then(v => {
        preViewValue(v);
      });
    }
  };

  const onTotalDurationChange = (v: number | null) => {
    if (v && cycle) {
      const newCount = Math.ceil((v * 3600) / cycle);
      form.setFieldsValue({
        count: newCount,
        totalDuration: v,
      });
    }
  };

  const onCountChange = (v: number | null) => {
    if (v && cycle) {
      const newTotalDuration = (cycle * v) / 3600;
      form.setFieldsValue({
        count: v,
        totalDuration: newTotalDuration,
      });
    }
  };

  useEffect(() => {
    if (previewValue) {
      form.setFieldsValue({
        params: previewValue ? new Array(previewValue.constants).fill(undefined) : [],
        startTime: dayjs().startOf('date'),
      });
      setTimeout(() => {
        if (previewValue.constants === 0 && form.getFieldsError().every(i => i.errors.length === 0)) {
          form.validateFields().then(v => {
            preViewValue(v);
          });
        }
      }, 0);
    }
  }, [previewValue, form]);

  const afterClose = () => {
    form.resetFields();
    setData([]);
    resetValue();
  };

  return (
    <Modal
      title="规则预览"
      open={!!previewValue}
      footer={null}
      width={1200}
      destroyOnClose
      afterClose={afterClose}
      onCancel={onCancel}
    >
      <Descriptions>
        <Descriptions.Item label="表达式">{previewValue?.expression}</Descriptions.Item>
      </Descriptions>
      <Form
        form={form}
        onValuesChange={() => {
          setHasChange(true);
        }}
        initialValues={{
          cycle: 300,
          count: 288,
          totalDuration: 24,
        }}
      >
        <Row>
          {previewValue && previewValue.constants > 0 && (
            <Col span={4}>
              <Form.List name="params">
                {fields => {
                  return (
                    <>
                      {fields.map((field, index) => (
                        <Form.Item
                          {...field}
                          key={index}
                          label={`c${index + 1}`}
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <InputNumber onBlur={onBlur} placeholder="请输入" style={{ width: '60%' }} />
                        </Form.Item>
                      ))}
                    </>
                  );
                }}
              </Form.List>
            </Col>
          )}
          <Col span={previewValue && previewValue.constants > 0 ? 20 : 24}>
            <Space direction="vertical" style={{ width: '100%' }}>
              <Space style={{ width: '100%' }}>
                <Form.Item name="cycle" label="周期" rules={[{ required: true }]}>
                  <InputNumber onBlur={onBlur} min={1} precision={0} max={86400} addonAfter="秒" />
                </Form.Item>
                <Form.Item name="startTime" label="起始时间" rules={[{ required: true }]}>
                  <DatePicker onBlur={onBlur} showTime />
                </Form.Item>
                <Form.Item name="totalDuration" label="总时长">
                  <InputNumber
                    max={(cycle * 10000) / 3600}
                    min={cycle / 3600}
                    onBlur={onBlur}
                    onChange={onTotalDurationChange}
                    addonAfter="小时"
                  />
                </Form.Item>
                <Form.Item name="count" label="总点数" rules={[{ required: true }]}>
                  <InputNumber onBlur={onBlur} onChange={onCountChange} width={100} min={1} precision={0} max={10000} />
                </Form.Item>
              </Space>
              {!!previewValue && data.length > 0 && (
                <ReactEChartsCore
                  echarts={echarts}
                  showLoading={loading}
                  notMerge
                  lazyUpdate={false}
                  style={{ height: 500 }}
                  option={getOption(data ?? [])}
                />
              )}
            </Space>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default PreviewRuleModal;
