import { Wrapper } from '@maxtropy/components';
import { Button, Col, Form, Row, Space, Tabs } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ApiAuthoritiesProps,
  getApplicationDetail,
  TenantAuthoritiesProps,
  updateApplicationAuthority,
} from '../../../../api/openPlatform-application';
import ShowInput from '../../../../components/ShowInput';
import { ApplicationSteps } from '../../CommonSteps';
import CustomerAuth, { VisibleRangeType } from './components/CustomerAuth';
import InterfaceAuth from './components/InterfaceAuth';

const routes = [
  { name: '开发者平台' },
  { name: '应用开发开放平台' },
  { name: '集成应用列表' },
  { name: '创建应用' },
  { name: '应用授权' },
];

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const AppAuthStepTwo: FC = () => {
  let { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [tenantSelectedValues, setTenantSelectedValues] = useState<TenantAuthoritiesProps[]>([]);
  const [interfaceSelectedValues, setInterfaceSelectedValues] = useState<ApiAuthoritiesProps[]>([]);
  const [visibleRangeValue, setVisibleRangeValue] = useState<VisibleRangeType>();
  const [visibleInterfaceRangeValue, seVisibleInterfaceRangeValue] = useState<VisibleRangeType>();

  // 回显应用商和名称
  useEffect(() => {
    if (id) {
      getApplicationDetail(id).then(res => {
        form.setFieldsValue({
          independentSoftwareVendorName: res.independentSoftwareVendorName,
          name: res.name,
        });
        setTenantSelectedValues(res.tenantAuthorities ?? []);
        setInterfaceSelectedValues(res.apiAuthorities ?? []);
        setVisibleRangeValue(res.tenantAuthorityType ?? VisibleRangeType.PART);
        seVisibleInterfaceRangeValue(res.apiAuthorityType ?? VisibleRangeType.PART);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onFinish = () => {
    if (id) {
      updateApplicationAuthority(id, {
        tenantAuthority: {
          tenantAuthorityType: visibleRangeValue,
          tenantMcids: tenantSelectedValues.map(i => i.tenantMcid),
        },
        apiAuthority: {
          apiAuthorityType: visibleInterfaceRangeValue,
          requestInfos: interfaceSelectedValues.map(i => i),
        },
      }).then(res => {
        console.log(res);
        navigate(`/open-platform/open-platform/app/updateFinish/${id}`);
      });
    }
  };
  const returnStepOne = () => {
    if (id) {
      navigate(`/open-platform/open-platform/app/updateBasicInfo/${id}`);
    }
  };

  const tabs = [
    {
      key: '1',
      label: '租户权限',
      children: (
        <CustomerAuth
          visibleRangeValue={visibleRangeValue}
          selectedVisibleRange={visibleRange => setVisibleRangeValue(visibleRange)}
          tenantSelectedValues={tenantSelectedValues}
          setTenantSelectedValues={setTenantSelectedValues}
        />
      ),
    },
    {
      key: '2',
      label: '接口权限',
      children: (
        <InterfaceAuth
          visibleRangeValue={visibleInterfaceRangeValue}
          selectedVisibleRange={visibleRange => seVisibleInterfaceRangeValue(visibleRange)}
          interfaceSelectedValues={interfaceSelectedValues}
          setInterfaceSelectedValues={setInterfaceSelectedValues}
        />
      ),
    },
  ];

  return (
    <Wrapper routes={routes} className="open-platform-common-wrapper">
      <ApplicationSteps current={1} />
      <Form {...layout} name="app-form" form={form} layout="vertical" onFinish={onFinish}>
        <Space direction="vertical" style={{ marginLeft: '20%', width: '60%' }}>
          <Row>
            <Col span={12}>
              <Form.Item name="independentSoftwareVendorName" label="服务商">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="name" label="应用名称">
                <ShowInput />
              </Form.Item>
            </Col>
          </Row>
        </Space>
      </Form>
      <Space direction="vertical" style={{ marginLeft: '20%', width: '60%' }}>
        <Tabs size="small" type="card" items={tabs} />
        <div style={{ marginTop: 20 }}>
          <Button type="primary" onClick={() => form.submit()}>
            提交&下一步
          </Button>
          <Button style={{ marginLeft: 20 }} onClick={returnStepOne}>
            上一步
          </Button>
        </div>
      </Space>
    </Wrapper>
  );
};

export default AppAuthStepTwo;
