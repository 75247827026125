interface DeviceTypes {
  id: number;
  name: string;
  allowAttribute?: boolean;
  children?: Array<DeviceTypes>;
  deviceTypes?: Array<DeviceTypes>;
  parentId?: number;
  parentName?: string;
  rootId?: number;
}

interface DeviceTypesProps extends DeviceTypes {
  key: string;
  children?: DeviceTypesProps[];
  deviceTypes?: DeviceTypesProps[];
}

export interface DataNode {
  key: string;
  label: string;
  value: number;
  children: DataNode[];
}

export function formatToDataNode(tree: DeviceTypesProps[]): DataNode[] {
  if (!tree) return [];
  const loop = (data: DeviceTypesProps[]): DataNode[] => {
    return data.map(item => ({
      key: item.key,
      label: item.name,
      value: item.id,
      children: item.deviceTypes || item.children ? loop(item.deviceTypes || item.children!) : [],
    }));
  };
  return loop(tree);
}

export interface DataEntity {
  node: DataNode;
  key: string;
  parentKey: string;
  parent?: DataEntity;
  children?: DataEntity[];
  level: number;
}

export function convertDataToEntities(dataNodes: DataNode[]) {
  const keyEntities = {};

  traverseDataNodes(dataNodes, item => {
    const { node, key, parentKey, level } = item;
    const entity: DataEntity = { node, key, parentKey, level };

    // @ts-ignore
    keyEntities[key] = entity;

    // Fill children
    // @ts-ignore
    entity.parent = keyEntities[parentKey];
    if (entity.parent) {
      entity.parent.children = entity.parent.children || [];
      entity.parent.children.push(entity);
    }
  });

  return keyEntities;
}

export function traverseDataNodes(
  dataNodes: DataNode[],
  callback: (data: { node: DataNode; key: string; parentKey: string; level: number }) => void
) {
  const externalGetKey = 'key';
  const childrenPropName = 'children';

  // Get keys
  let syntheticGetKey = (node: DataNode) => node[externalGetKey];

  // Process
  function processNode(node: DataNode, index = 0, parent?: { node: DataNode; level: number }) {
    const children = node ? node[childrenPropName] : dataNodes;

    // Process node if is not root
    if (node) {
      const key: string = syntheticGetKey(node);
      const data = {
        node,
        index,
        key,
        parentKey: parent?.node ? parent.node.key : (null as unknown as string),
        level: (parent?.level ?? 0) + 1,
      };

      callback(data);
    }

    // Process children node
    if (children) {
      children.forEach((subNode, subIndex) => {
        processNode(subNode, subIndex, {
          node,
          level: parent ? parent.level + 1 : -1,
        });
      });
    }
  }

  processNode(null as unknown as DataNode);
}

export const getDeviceType = (keyEntities: Record<string, DataEntity>, id: number) => {
  const levelEntities = new Map<number, Set<DataEntity>>();
  let maxLevel = 0;

  // Convert entities by level for calculation
  Object.keys(keyEntities).forEach(key => {
    const entity = keyEntities[key];
    const { level } = entity;

    let levelSet: Set<DataEntity> = levelEntities.get(level)!;
    if (!levelSet) {
      levelSet = new Set();
      levelEntities.set(level, levelSet);
    }

    levelSet.add(entity);
    maxLevel = Math.max(maxLevel, level);
  });

  const entities = levelEntities.get(maxLevel) || new Set();
  let entity = Array.from(entities).find(entity => entity.node.value === id);
  const result = [];
  if (entity) {
    result.unshift(entity.node.value);
    while (entity.parent) {
      result.unshift(entity.parent.node.value);
      entity = entity.parent;
    }
  }
  return result;
};
