import React, { useEffect, useMemo, useState } from 'react';
import { FormContent, Wrapper } from '@maxtropy/components';
import { Button, Form, Input, Radio, Select, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import Footer from '../../../components/Footer';
import { InfoCircleOutlined } from '@ant-design/icons';
import { RoleType, RoleTypeDisplay, StateType } from '../../../api/openPlatform-const';
import { createDevelopAccount } from '../../../api/openPlatform-account';
import { queryIsvList, QueryIsvListProps } from '../../../api/openPlatform-application';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const routes = [{ name: '开发者平台' }, { name: '应用开发开放平台' }, { name: '开发者账号管理' }, { name: '创建账号' }];

type FormValues = {
  independentSoftwareVendorId: number;
  username: string;
  roleType: number;
  cellphone: string;
  description: string;
};

const CreateDeveloperAccount: React.FC = () => {
  const [form] = Form.useForm<FormValues>();
  const [loading, setLoading] = useState<boolean>(false);
  const [serverProviderList, setServerProviderList] = useState<QueryIsvListProps[]>([]);

  // 查询服务商list
  useEffect(() => {
    queryIsvList().then(setServerProviderList);
  }, []);

  const serverProviderOptions = useMemo(() => {
    if (serverProviderList && serverProviderList.length !== 0) {
      return serverProviderList.filter(k => k.status === StateType.ENABLE).map(i => ({ label: i.name, value: i.id }));
    }
  }, [serverProviderList]);

  const navigate = useNavigate();
  const onSave = () => {
    form.validateFields().then(() => {
      setLoading(true);
      let values = form.getFieldsValue();
      createDevelopAccount(values)
        .then(res => {
          navigate('/open-platform/open-platform/developer-account');
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <Wrapper routes={routes} className={classnames('open-platform-common-wrapper')}>
      <Form {...layout} name="account-form" form={form} layout="vertical" initialValues={{ roleType: RoleType.ADMIN }}>
        <FormContent title={'创建账号'}>
          <div style={{ marginLeft: 30 }}>
            <Form.Item
              name="independentSoftwareVendorId"
              label="服务商"
              rules={[{ required: true, message: '请选择服务商' }]}
            >
              <Select
                style={{ width: 300 }}
                placeholder="请选择服务商"
                options={serverProviderOptions}
                showSearch
                optionFilterProp="label"
              />
            </Form.Item>
            <Form.Item name="roleType" label="角色" rules={[{ required: true, message: '请选择角色' }]}>
              <Radio.Group>
                <Radio value={RoleType.ADMIN}>{RoleTypeDisplay[RoleType.ADMIN]}</Radio>
                <Radio value={RoleType.COMMON}>{RoleTypeDisplay[RoleType.COMMON]}</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="账号"
              name="username"
              validateTrigger="onBlur"
              rules={[{ required: true, message: '请输入正确的账号', pattern: /^[a-zA-Z0-9]{4,10}$/ }]}
              extra={
                <div style={{ marginTop: 4, color: 'rgba(0,0,0,0.35)' }}>
                  <InfoCircleOutlined style={{ color: 'var(--primary-color)' }} /> 英文+数字组合，长度4位到10位之间
                </div>
              }
            >
              <Input style={{ width: 300 }} placeholder="请输入账号" />
            </Form.Item>

            <Form.Item
              label="手机号"
              name="cellphone"
              validateTrigger="onBlur"
              rules={[{ required: true, message: '请输入正确的手机号', pattern: /^1[3456789]\d{9}$/ }]}
              extra={
                <div style={{ marginTop: 4, color: 'rgba(0,0,0,0.35)' }}>
                  <InfoCircleOutlined style={{ color: 'var(--primary-color)' }} />{' '}
                  请填写正确的手机号，提交后登录密码将发送到该手机号
                </div>
              }
            >
              <Input style={{ width: 300 }} placeholder="请输入手机号" />
            </Form.Item>
            <Form.Item name="description" label="备注">
              <Input.TextArea rows={4} maxLength={200} style={{ width: 460 }} placeholder="请输入200字" />
            </Form.Item>
          </div>
        </FormContent>
      </Form>

      <Footer>
        <Space>
          <Button type="primary" onClick={onSave} loading={loading}>
            提交
          </Button>
          <Button onClick={() => navigate('/open-platform/open-platform/developer-account')}>取消</Button>
        </Space>
      </Footer>
    </Wrapper>
  );
};

export default CreateDeveloperAccount;
