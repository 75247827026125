import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, InputNumber, Space, Modal, Radio, Select, Upload } from 'antd';
import { debounce } from 'lodash-es';
import { FormContent, Wrapper } from '@maxtropy/components';
import styles from './index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import ResultModal from '../components/ResultModal';
import { getPvEstimationResultList, Result, getPvProfit, PvProfitReq } from '../../../api/tools';

import qs from 'qs';
import { RcFile } from 'antd/es/upload';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

export enum EnergyInfoType {
  IMPORT = 1,
  SELECT = 2,
}

enum EstimationType {
  ONESELF = 1,
  THIRDPARTY = 2,
}

export interface PvProfitFormReq extends Omit<PvProfitReq, 'municipalElecPriceFileKey' | 'gcEstimateTaskFileKey'> {
  gcEstimateTaskFile: RcFile[];
  municipalElecPriceFile: RcFile[];
}

const routes = [{ name: '工具中心' }, { name: '光伏测算工具' }, { name: '光伏收益估算' }];

const Profit: React.FC = () => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState<boolean>(false);
  const [options, setOptions] = useState<Result[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [searchVal, setSearchVal] = useState<string>();
  const [modalApi, modalContextHolder] = Modal.useModal();

  useEffect(() => {
    searchOnline();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollEnd = (e: any) => {
    e.persist();
    const { target } = e;
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      if (page * 10 < total) {
        getSearchPage(page + 1, searchVal, true);
      }
    }
  };

  const getSearchPage = (page: number = 1, remark: string = '', isConcat: boolean = false) => {
    const param = {
      size: 10,
      taskSource: '1',
      remark,
      page,
    };
    getPvEstimationResultList(param).then(res => {
      if (isConcat) {
        let records = options.concat(res?.records ?? []);
        setOptions(records);
        setPage(page + 1);
      } else {
        setOptions(res?.records ?? []);
        setTotal(res.total);
      }
    });
  };

  const searchOnline = (value?: string) => {
    setSearchVal(value);
    getSearchPage(1, value);
  };

  const downLoadTemplate = (type: number) => {
    const params = { templateNo: type };
    window.open(`/api/pvestimate/templateDownload?${qs.stringify(params, { indices: false })}`);
  };

  const onFinish = (values: PvProfitFormReq) => {
    const param = { ...values };

    getPvProfit(param).then(res => {
      modalApi.success({
        content: '计算完成，请在计算结果列表中查看。',
        onOk: () => {
          form.resetFields();
        },
      });
    });
  };

  return (
    <Wrapper routes={routes} className={styles.wrapper}>
      <div className={styles.titleBox}>
        <div className={styles.leftLine}></div>
        <span className={styles.rightText}>发电收益估算工具</span>
      </div>
      <FormContent style={{ paddingTop: 10 }}>
        <Form
          layout="vertical"
          form={form}
          {...formLayout}
          initialValues={{
            gcInfoSource: EnergyInfoType.SELECT,
            estimationType: EstimationType.ONESELF,
          }}
          onFinish={onFinish}
        >
          <Row>
            <Col span={12}>
              <Form.Item name="remark" label="收益估算备注信息" rules={[{ max: 50, message: '最多输入五十个字' }]}>
                <Input placeholder="可以不填写，不填写则系统默认生成" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="gcInfoSource" label="发电量信息" rules={[{ required: true, message: '请输入' }]}>
                <Radio.Group>
                  <Radio value={EnergyInfoType.IMPORT}>导入发电量信息表</Radio>
                  <Radio value={EnergyInfoType.SELECT}>选择光伏发电量工具中的计算结果</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item noStyle dependencies={['gcInfoSource']}>
                {({ getFieldValue }) => {
                  if (getFieldValue('gcInfoSource') === EnergyInfoType.IMPORT) {
                    return (
                      <Form.Item label="计算结果" required>
                        <Form.Item
                          name="gcInfoFileKey"
                          valuePropName="file"
                          noStyle
                          getValueFromEvent={e => {
                            if (e && e.file && e.file.response) {
                              return e.file.response.key;
                            }
                            return e && e.file;
                          }}
                          rules={[{ required: true, message: '请导入' }]}
                        >
                          <Upload
                            accept=".xlsx"
                            action="/api/file-center/upload"
                            className={styles.loadTxt}
                            maxCount={1}
                          >
                            <Button type="link">导入</Button>
                          </Upload>
                        </Form.Item>
                        <Button type="link" className={styles.btnTxt} onClick={() => downLoadTemplate(1)}>
                          下载模版
                        </Button>
                      </Form.Item>
                    );
                  } else {
                    return (
                      <Form.Item
                        name="gcEstimateTaskFileKey"
                        label="计算结果"
                        rules={[{ required: true, message: '请输入或选择' }]}
                      >
                        <Select
                          showSearch={true}
                          fieldNames={{ label: 'remark', value: 'fileKey' }}
                          options={options}
                          filterOption={() => {
                            return true;
                          }}
                          placeholder="请选择"
                          onPopupScroll={scrollEnd}
                          onSearch={debounce(searchOnline, 300)}
                        ></Select>
                      </Form.Item>
                    );
                  }
                }}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="surplusElecInternetRatio"
                label="余电上网比例（%）"
                rules={[{ required: true, message: '请输入' }]}
              >
                <InputNumber style={{ width: '100%' }} max={100} min={0} placeholder="请输入" precision={3} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                name="internetElecPrice"
                label="上网电价（元）"
                rules={[{ required: true, message: '请输入上网电价' }]}
              >
                <InputNumber style={{ width: '100%' }} max={360} min={0} placeholder="请输入" precision={3} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="市电电价" required>
                <Form.Item
                  name="municipalElecPriceFileKey"
                  valuePropName="file"
                  noStyle
                  getValueFromEvent={e => {
                    if (e && e.file && e.file.response) {
                      return e.file.response.key;
                    }
                    return e && e.file;
                  }}
                  rules={[{ required: true, message: '请导入' }]}
                >
                  <Upload action="/api/file-center/upload" accept=".xlsx" className={styles.loadTxt} maxCount={1}>
                    <Button type="link">导入</Button>
                  </Upload>
                </Form.Item>
                <Button type="link" className={styles.btnTxt} onClick={() => downLoadTemplate(2)}>
                  下载模版
                </Button>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item name="totalPackageElecPrice" label="总包电价" rules={[{ required: true, message: '请输入' }]}>
                <InputNumber
                  style={{ width: '100%' }}
                  max={100}
                  min={0}
                  placeholder="请输入"
                  precision={2}
                  addonAfter="元/瓦"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="estimationType" label="估算类型" rules={[{ required: true, message: '请输入' }]}>
                <Radio.Group>
                  <Radio value={EstimationType.ONESELF}>自建光伏</Radio>
                  <Radio value={EstimationType.THIRDPARTY}>第三方投资</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item noStyle dependencies={['estimationType']}>
                {({ getFieldValue }) => {
                  if (getFieldValue('estimationType') === EstimationType.THIRDPARTY) {
                    return (
                      <Form.Item
                        name="saleElecPriceDiscount"
                        label="售卖电价折扣"
                        tooltip={{
                          placement: 'right',
                          title: '如输入9，则为在市电电价的基础上，打九折。',
                          icon: <InfoCircleOutlined style={{ color: 'var(--primary-color)' }} />,
                        }}
                        rules={[{ required: true, message: '请输入' }]}
                      >
                        <InputNumber style={{ width: '100%' }} max={10} min={0} placeholder="请输入" precision={2} />
                      </Form.Item>
                    );
                  }
                }}
              </Form.Item>
            </Col>
          </Row>
          <Space className={styles.footer}>
            <Button type="primary" htmlType="submit">
              计算
            </Button>
            <Button type="primary" onClick={() => setOpen(true)}>
              计算结果列表
            </Button>
          </Space>
        </Form>
      </FormContent>
      <Modal
        className={styles.modal}
        title="计算结果列表"
        destroyOnClose
        onCancel={() => setOpen(false)}
        open={open}
        footer={[
          <Button key={'cancel'} onClick={() => setOpen(false)}>
            取消
          </Button>,
        ]}
        width={700}
      >
        <ResultModal type={'2'} />
      </Modal>
      {modalContextHolder}
    </Wrapper>
  );
};

export default Profit;
