import { useCallback, useEffect, FC, useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { createRuleSet, getRuleSet, updateRuleSet } from '../../api/mockRule-set';
import { Rule, RuleSetContent, Tenant } from '../../api/entities';
import { Button, Checkbox, Form, Input, message, Select, Space, Tag, Typography } from 'antd';
import { listDataTimeRules, listDataValueRules, listUploadTimeRules } from '../../api/mockRule';
import { cssMonospaceFont, str2doubleList, str2LongStrList } from './util';
import { Wrapper, useAsync } from '@maxtropy/components';
import { getRoot } from '../../api/mockCustomer';
import { isNil } from 'lodash-es';

const { TextArea } = Input;
const { Title, Text } = Typography;

export interface RuleSetFormProps {
  isUpdate: boolean;
}

export interface RuleSetFormParams {
  id?: string;
}

function ruleOption(rule: Rule) {
  return {
    value: rule.id,
    label: (
      <>
        <Space>
          <Text strong>{rule.name}</Text>&ensp;
          <Tag color="purple">{rule.constants}</Tag>
          <Text code>{rule.expression}</Text>
        </Space>
      </>
    ),
  };
}

const RuleSetForm: FC<RuleSetFormProps> = ({ isUpdate }) => {
  const [messageApi, messageContextHolder] = message.useMessage();
  const { id } = useParams<{
    [key in keyof RuleSetFormParams]?: string;
  }>();
  const navigate = useNavigate();
  const location = useLocation();
  const fromId = new URLSearchParams(location.search).get('copyFrom');
  const shouldLoad: boolean = !!((isUpdate && id) || fromId);
  const loadId: number = fromId ? +fromId : id ? +id : NaN;
  const uploadTimeRules = useAsync(listUploadTimeRules, []);
  const dataTimeRules = useAsync(listDataTimeRules, []);
  const dataValueRules = useAsync(listDataValueRules, []);
  const [form] = Form.useForm<RuleSetFormObj>();
  const [tenants, setTenants] = useState<Tenant[]>([]);

  useEffect(() => {
    if (shouldLoad) {
      getRuleSet(loadId).then(s => form.setFieldsValue(entity2form(s)));
    }
  }, [form, shouldLoad, loadId]);

  useEffect(() => {
    getRoot({}).then(res => {
      setTenants(res);
    });
  }, []);

  const onFinish = useCallback(
    (formValue: RuleSetFormObj) => {
      const ruleSet = form2entity(formValue);
      (isUpdate && !isNil(id) ? updateRuleSet(+id, ruleSet) : createRuleSet(ruleSet))
        .then(() => navigate('/data-tools/shaping/rule-set'))
        .catch(e => {
          messageApi.error('保存失败');
        });
    },
    [isUpdate, id, navigate]
  );

  const routes = useMemo(() => {
    return [
      { name: '数据运营工具' },
      { name: '数据模拟' },
      { name: '规则集配置' },
      { name: isUpdate ? '更新规则集' : '创建规则集' },
    ];
  }, [isUpdate]);

  return (
    <Wrapper routes={routes}>
      <Title level={2}>{isUpdate ? '更新规则集' : '创建规则集'}</Title>
      <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{ enabled: false, computeOnce: true }}>
        <Form.Item name="name" label="名称" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="tenantMcid" label="所属租户" rules={[{ required: true }]}>
          <Select
            disabled={isUpdate}
            showSearch
            placeholder="请选择租户"
            optionFilterProp="label"
            options={tenants.map(v => ({ label: v.name, value: v.mcid }))}
          />
        </Form.Item>
        <Form.Item name="description" label="说明">
          <TextArea rows={6} />
        </Form.Item>
        <Form.Item name="enabled" label="保存后立刻启用" valuePropName="checked">
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="computeOnce"
          label="数据点统一计算"
          valuePropName="checked"
          tooltip={{ title: '勾选后，对于规则集所有数据点每次上传只进行一次计算，即所有数据点将同时上传同样的数据' }}
        >
          <Checkbox />
        </Form.Item>
        <Form.Item name="uploadTimeRuleId" label="上传时间规则" rules={[{ required: true }]}>
          <Select placeholder="请选择" allowClear options={uploadTimeRules.map(ruleOption)} />
        </Form.Item>
        <Form.Item name="uploadTimeRuleConstants" label="上传时间规则参数">
          <Input style={cssMonospaceFont} placeholder="参数数量需与所选规则一致，多个参数用逗号分隔" />
        </Form.Item>
        <Form.Item name="dataTimeRuleId" label="数据时间规则" rules={[{ required: true }]}>
          <Select placeholder="请选择" allowClear options={dataTimeRules.map(ruleOption)} />
        </Form.Item>
        <Form.Item name="dataTimeRuleConstants" label="数据时间规则参数">
          <Input style={cssMonospaceFont} placeholder="参数数量需与所选规则一致，多个参数用英文逗号分隔" />
        </Form.Item>
        <Form.Item name="dataValueRuleId" label="数据值规则" rules={[{ required: true }]}>
          <Select placeholder="请选择" allowClear options={dataValueRules.map(ruleOption)} />
        </Form.Item>
        <Form.Item name="dataValueRuleConstants" label="数据值规则参数">
          <Input style={cssMonospaceFont} placeholder="参数数量需与所选规则一致，多个参数用英文逗号分隔" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            保存
          </Button>
        </Form.Item>
      </Form>
      {messageContextHolder}
    </Wrapper>
  );
};

interface RuleSetFormObj {
  name: string;
  description?: string;
  enabled: boolean;
  computeOnce: boolean;
  pointKeys: string;
  dataTimeRuleConstants: string;
  dataValueRuleConstants: string;
  uploadTimeRuleConstants: string;
  dataTimeRuleId: number;
  dataValueRuleId: number;
  uploadTimeRuleId: number;
  tenantMcid: string;
}

function form2entity(formValue: RuleSetFormObj): RuleSetContent {
  return {
    name: formValue.name,
    description: formValue.description,
    pointKeys: str2LongStrList(formValue.pointKeys),
    enabled: formValue.enabled,
    computeOnce: formValue.computeOnce,
    dataTimeRuleId: formValue.dataTimeRuleId,
    dataValueRuleId: formValue.dataValueRuleId,
    uploadTimeRuleId: formValue.uploadTimeRuleId,
    dataTimeRuleConstants: str2doubleList(formValue.dataTimeRuleConstants),
    dataValueRuleConstants: str2doubleList(formValue.dataValueRuleConstants),
    uploadTimeRuleConstants: str2doubleList(formValue.uploadTimeRuleConstants),
    tenantMcid: formValue.tenantMcid,
  };
}

function entity2form(content: RuleSetContent): RuleSetFormObj {
  return {
    name: content.name,
    description: content.description,
    pointKeys: content.pointKeys.join(','),
    enabled: content.enabled,
    computeOnce: content.computeOnce,
    dataTimeRuleId: content.dataTimeRuleId,
    dataValueRuleId: content.dataValueRuleId,
    uploadTimeRuleId: content.uploadTimeRuleId,
    dataTimeRuleConstants: content.dataTimeRuleConstants.join(', '),
    dataValueRuleConstants: content.dataValueRuleConstants.join(', '),
    uploadTimeRuleConstants: content.uploadTimeRuleConstants.join(', '),
    tenantMcid: content.tenantMcid,
  };
}

export default RuleSetForm;
