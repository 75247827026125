import React from 'react';
import { Table } from '@maxtropy/components';
import { Modal } from 'antd';
import { Content, CurrentDataClass } from '../../../api/cc-operationLog';

interface Props {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentRowData?: Content;
}

const DetailModal: React.FC<Props> = ({ modalOpen, setModalOpen, currentRowData }) => {
  const columns = [
    {
      title: '字段',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: '操作前',
      dataIndex: 'before',
      key: 'before',
    },
    {
      title: '操作后',
      dataIndex: 'after',
      key: 'after',
    },
  ];

  const getColumnData = (key: string, field: string) => ({
    key,
    field,
    before: (currentRowData?.originalData as CurrentDataClass)?.[key] ?? '--',
    after: (currentRowData?.currentData as CurrentDataClass)?.[key] ?? '--',
  });

  const dataSource = [
    getColumnData('name', '导航名称'),
    getColumnData('parentName', '父导航'),
    getColumnData('weighting', '排序权重'),
    getColumnData('openTypeDesc', '打开方式'),
    getColumnData('jumpTypeDesc', '页面跳转方式'),
    getColumnData('url', '链接地址'),
    getColumnData('permissionDesc', '权限类型'),
    getColumnData('permissionCode', '权限配置'),
    getColumnData('moduleDesc', '模块'),
    getColumnData('path', '相对路径'),
    getColumnData('integratedAppDesc', '集成应用'),
  ];

  return (
    <Modal title="日志详情" open={modalOpen} onCancel={() => setModalOpen(false)} width={1024} footer={null}>
      <Table columns={columns} dataSource={dataSource} />
    </Modal>
  );
};

export default DetailModal;
