import React from 'react';
import { Table } from 'antd';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { flatComponents, OperationObject, ProcessSpecResult } from './utils/swagger-parse';
import { generateExample, schemaToModel } from './utils/schema-utils';
import RequestTable from './components/table/RequestTable';
import ResponseTable from './components/table/ResponseTable';
import { RequestParam, ResponseParam, ResponseParamHeader } from './components/interface';

const cx = classNames.bind(styles);

const columns = [
  {
    title: '接口路径',
    dataIndex: 'path',
    render: (text: any, record: any) => {
      return (
        <>
          <span className={cx('summary-method')}>{record.method}</span>
          <span>{record.path}</span>
        </>
      );
    },
  },
  { title: '接口描述', dataIndex: 'summary', key: 'summary' },
  { title: 'tag', dataIndex: 'tags', key: 'tags' },
];

// prettier-ignore
const InterfaceTable: React.FC<ProcessSpecResult> = props => {// NOSONAR
  console.log('props', props);
  // prettier-ignore
  const expandedRowRender = (record: OperationObject) => {// NOSONAR
    const { parameters = [], requestBody = { content: {} }, responses = {} } = record;
    let requestParams: RequestParam[] = [];
    parameters.forEach(parameter => {
      const { name, in: _in, required, schema = {}, description = '' } = parameter;
      requestParams.push({
        name,
        in: _in,
        required,
        description,
        type: schema.type,
        format: schema.format,
      });
    });
    if (requestBody.content) {
      Object.values(requestBody.content).forEach(content => {
        const { schema, examples, example } = content;
        const { required, description } = requestBody;

        let tempSchema = schema;
        let str = tempSchema?.$ref;
        // 根据$ref:#/components/schemas/PvStationElectricityPriceVO找到对应的数据源
        if (str) {
          let arr = str.split('/');
          let key = arr[arr.length - 1];
          // 扁平化处理
          let originSchemas = props.components?.schemas;
          if (originSchemas) {
            flatComponents(originSchemas, originSchemas);
            tempSchema = originSchemas[key];
          }
        }
        if (!tempSchema) return;

        const schemaTree = schemaToModel(tempSchema, {});
        const _example = generateExample(examples, example, tempSchema, 'text');
        requestParams.push({
          type: tempSchema.type,
          format: tempSchema.format,
          name: 'body',
          required,
          in: 'body',
          description: description ?? tempSchema.description ?? '',
          schemaTree,
          example: _example[0] && _example[0].exampleValue ? _example[0].exampleValue : '',
        });
      });
    }
    let responseParams: ResponseParam[] = Object.keys(responses).map(name => {
      const response = responses[name];
      const { description = '', headers = {} } = response;
      let _headers: ResponseParamHeader[] = [];
      if (headers) {
        _headers = Object.keys(headers).map(key => {
          return {
            name: key,
            ...headers[key],
          };
        });
      }
      if (response.content) {
        for (let content of Object.values(response.content)) {
          const { schema, examples, example } = content;
          if (schema) {
            console.log(schema);
            const schemaTree = schemaToModel(schema, {});
            const _example = generateExample(examples, example, schema, 'text');
            return {
              name,
              description,
              schemaTree,
              example: _example[0] && _example[0].exampleValue ? _example[0].exampleValue : '',
              headers: _headers,
            };
          }
        }
      }
      return {
        name,
        description,
        headers: _headers,
      };
    });
    console.log('*******************');
    console.log(responseParams);
    return (
      <>
        <div className={cx('block-section')}>
          <div className={cx('block-description-wrapper')}>
            <div className={cx('block-description')}>
              <div className={cx('markdown')}>
                <p>{record.description}</p>
              </div>
            </div>
          </div>
          <p className={cx('block-section-header')}>
            <span>Parameters</span>
          </p>
          <RequestTable data={requestParams} />
          <p className={cx('block-section-header')}>
            <span>Responses</span>
          </p>
          <ResponseTable data={responseParams} />
        </div>
      </>
    );
  };

  return (
    <>
      <Table
        dataSource={props.paths}
        columns={columns}
        expandable={{
          expandedRowRender,
          rowExpandable: () => true,
        }}
        pagination={false}
        expandRowByClick
        rowKey="uuid"
      />
    </>
  );
};

export default InterfaceTable;
