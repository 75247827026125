import { FC } from 'react';
import { Space } from 'antd';
import { Button } from '@maxtropy/components';

interface FormSubmitProps {
  cancel: () => void;
  submit: (shouldContinue?: boolean) => void;
  alterMode?: boolean;
}

const FormSubmit: FC<FormSubmitProps> = props => {
  const { cancel, submit, alterMode = false } = props;
  return (
    <div style={{ textAlign: 'right' }}>
      <Space>
        <>
          {!alterMode && (
            <Button type="primary" onClick={() => submit(true)}>
              保存并继续添加
            </Button>
          )}
          <Button type="primary" onClick={() => submit()}>
            保存并完成
          </Button>
        </>
        <Button type="default" onClick={cancel}>
          取消
        </Button>
      </Space>
    </div>
  );
};

export default FormSubmit;
