import { Paging, Table, usePaging, useUpdate } from '@maxtropy/components';
import { Button, Divider, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { selectTenantColumns } from './utils';
import { EdgeTemplateTenantRes, getEdgeTemplateTenant } from '../../api/edgeTemplate';
import { ParamsProps } from './index';
import { PermissionsType } from '../../common/permissionsConst';

interface TenantListProps {
  searchParams?: ParamsProps;
  tabsKey?: string;
  permission?: string[];
}

const TenantList: React.FC<TenantListProps> = ({ searchParams, tabsKey, permission }) => {
  const { search } = useLocation();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [updateState, update] = useUpdate();
  const [loading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<EdgeTemplateTenantRes[]>([]);

  const getList = () => {
    setLoading(true);
    getEdgeTemplateTenant({ ...searchParams, page: pageOffset, size: pageSize }).then(res => {
      if (res) setTotalCount(res.total);
      setList(res.list);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (updateState) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState]);

  useEffect(() => {
    setPageOffset(1);
    if (pageOffset === 1) {
      update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, setPageOffset]);

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, pageSize]);

  const columns = [
    ...selectTenantColumns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 200,
      render: (v: EdgeTemplateTenantRes) => (
        <Space size={16}>
          {(permission ?? []).includes(PermissionsType.P_ATTRIBUTETEMPLATEASSIGNTENANT) && (
            <Button style={{ paddingLeft: 0, paddingRight: 0 }} type="link">
              <Link to={`/device/info/distribution/template/create/${v.rootMcid}${search}`}>属性信息模板分配</Link>
            </Button>
          )}
          <Button style={{ paddingLeft: 0, paddingRight: 0 }} type="link">
            <Link to={`/device/info/distribution/template/detail/${v.rootMcid}${search}`}>查看</Link>
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table sticky scroll={{ x: 1900 }} loading={loading} columns={columns} dataSource={list} />
      <Paging pagingInfo={pagingInfo} />
    </>
  );
};

export default TenantList;
