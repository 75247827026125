export const updateCurrentMenuIndex = (
  isCurrentSourceSelect: boolean,
  isCurrentDestinationSelect: boolean,
  sourceIndex: number,
  destinationIndex: number,
  currentMenuIndex: number,
  setCurrentMenuIndex: React.Dispatch<React.SetStateAction<number>>
) => {
  // 如果当前菜单是源菜单，更新当前菜单索引为目标索引
  if (isCurrentSourceSelect) {
    setCurrentMenuIndex(destinationIndex);
    return;
  }

  // 如果目标索引等于当前菜单索引，将当前菜单索引设置为源索引并返回
  if (destinationIndex === currentMenuIndex) {
    setCurrentMenuIndex(sourceIndex);
    return;
  }

  // 如果当前菜单既不是源菜单也不是目标菜单，直接返回
  if (currentMenuIndex !== sourceIndex && currentMenuIndex !== destinationIndex) {
    return;
  }

  // 如果当前菜单是目标菜单
  if (isCurrentDestinationSelect) {
    // 如果源索引小于目标索引，当前菜单索引减1
    if (sourceIndex < destinationIndex) {
      setCurrentMenuIndex(currentMenuIndex - 1);
    }
    // 如果源索引大于目标索引，当前菜单索引加1
    if (sourceIndex > destinationIndex) {
      setCurrentMenuIndex(currentMenuIndex + 1);
    }
  }

  // 如果当前菜单既不是源菜单也不是目标菜单
  if (!isCurrentSourceSelect && !isCurrentDestinationSelect) {
    // 如果源索引小于目标索引，且当前菜单索引在它们之间，当前菜单索引减1
    if (sourceIndex < destinationIndex) {
      if (currentMenuIndex > sourceIndex && currentMenuIndex <= destinationIndex) {
        setCurrentMenuIndex(currentMenuIndex - 1);
      }
    }
    // 如果源索引大于目标索引，且当前菜单索引在它们之间，当前菜单索引加1
    if (sourceIndex > destinationIndex) {
      if (currentMenuIndex < sourceIndex && currentMenuIndex >= destinationIndex) {
        setCurrentMenuIndex(currentMenuIndex + 1);
      }
    }
  }
};
