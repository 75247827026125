import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Space } from 'antd';
import { AttachmentUpload, Wrapper } from '@maxtropy/components';
import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import { commonApplicationNameRegex } from '../../../../utils/regex';
import { useNavigate, useParams } from 'react-router-dom';
import DevGroupSelect from '../../../components/DevGroupSelect';
import {
  addApplication,
  ApplicationBody,
  ApplicationProps,
  checkUnique,
  getApplicationDetail,
  updateApplicationBasicInfo,
} from '../../../../api/openPlatform-application';
import { ApplicationSteps } from '../../CommonSteps';

const { TextArea } = Input;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const nameValidator = (_: RuleObject, name: StoreValue) => {
  if (!commonApplicationNameRegex({ start: 4, end: 50 }).test(name)) {
    return Promise.reject(new Error('提示：中文+英文+数字组合，长度4位到50位之间。'));
  } else {
    return Promise.resolve();
  }
};

export const nameUniquenessValidator = (_: RuleObject, name: StoreValue) => {
  return checkUnique(name)
    .onError(error => {
      throw error;
    })
    .then(response => {
      if (!response) {
        return Promise.resolve();
      } else {
        return Promise.reject(new Error('该名称已存在'));
      }
    });
};

const routes = [
  { name: '开发者平台' },
  { name: '应用开发开放平台' },
  { name: '集成应用列表' },
  { name: '创建应用' },
  { name: '基本信息' },
];

interface CreateAppProps {}

const CreateAppStepOne: React.FC<CreateAppProps> = props => {
  let { id } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [detail, setDetail] = useState<ApplicationProps>();

  useEffect(() => {
    if (!id) return;
    getApplicationDetail(id).then(data => {
      setDetail(data);
      form.setFieldsValue({
        independentSoftwareVendorId: data.independentSoftwareVendorId,
        name: data.name,
        logoResource: data.logoResource,
        memo: data.memo,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const _nameUniquenessValidator = (_: RuleObject, name: StoreValue) => {
    if (name === detail?.name) {
      return Promise.resolve();
    }
    return nameUniquenessValidator(_, name);
  };

  const onFinish = (values: ApplicationBody) => {
    if (id) {
      form.validateFields().then(() => {
        const values = form.getFieldsValue();
        let { ...restValues } = values;
        restValues = { ...restValues, logoResource: restValues.logoResource ?? null, memo: restValues.memo ?? null };
        updateApplicationBasicInfo(id!, restValues).then(() => {
          navigate(`/open-platform/open-platform/app/updateAuth/${id}`);
        });
      });
    } else {
      addApplication(values).then(res => {
        if (res) {
          navigate(`/open-platform/open-platform/app/updateAuth/${res.id}`);
        }
      });
    }
  };

  return (
    <Wrapper routes={routes} className="open-platform-common-wrapper">
      <ApplicationSteps current={0} />
      <Form {...layout} name="app-form" form={form} layout="vertical" onFinish={onFinish}>
        <Space direction="vertical" style={{ marginLeft: '20%' }}>
          <Form.Item name="independentSoftwareVendorId" label="服务商" rules={[{ required: true }]}>
            {id ? detail?.independentSoftwareVendorName : <DevGroupSelect />}
          </Form.Item>
          <Form.Item
            name="name"
            label="应用名称"
            validateFirst
            rules={[
              { required: true },
              // { type: 'string', max: 16 },
              { validator: nameValidator },
              { validator: id ? _nameUniquenessValidator : nameUniquenessValidator },
            ]}
          >
            <Input style={{ width: 300 }} placeholder="请输入应用名称" />
          </Form.Item>
          <Form.Item name="logoResource" label="图片上传" rules={[]}>
            <AttachmentUpload />
          </Form.Item>
          <Form.Item name="memo" label="备注" rules={[{ type: 'string', max: 100, message: '不能超过100个字符' }]}>
            <TextArea rows={4} maxLength={100} autoSize={false} style={{ width: 463 }} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              提交&下一步
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default CreateAppStepOne;
