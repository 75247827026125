import { FC, ReactNode } from 'react';

const ShowInput: FC<{
  value?: ReactNode;
  addonAfter?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}> = ({ value, addonAfter, children, style }) => (
  <div style={style}>
    {(value ?? '--') + ' ' + (addonAfter ?? '')}
    {children}
  </div>
);

export default ShowInput;
