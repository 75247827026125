import { InfoCircleOutlined } from '@ant-design/icons';
import { EllipsisSpan, Filter } from '@maxtropy/components';
import { Form, Input, Modal, PaginationProps, Table } from 'antd';
import React, { Key, Ref, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import {
  queryTenantList,
  TenantAuthoritiesProps,
  TenantListProps,
} from '../../../../../../api/openPlatform-application';
import styles from './index.module.scss';

interface CustomerModalProps {
  visible?: boolean;
  onOk?: () => void;
  onCancel?: () => void;
  selectedData?: TenantAuthoritiesProps[];
}

interface FilterProps {
  codeOrName?: string;
}

export interface CustomerModalRef {
  // selectedRowKeys: Array<number> | undefined;
  selectedRows: TenantListProps[];
}
const columns = [
  {
    title: '租户Code',
    dataIndex: 'tenantCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '租户名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const CustomerModal = React.forwardRef((props: CustomerModalProps, ref: Ref<CustomerModalRef>) => {
  const { visible, onOk, onCancel, selectedData } = props;
  const [selectedRows, setSelectedRows] = useState<TenantListProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<FilterProps>();
  const [data, setData] = useState<TenantListProps[]>([]);

  useImperativeHandle(ref, () => ({ selectedRows }));

  useEffect(() => {
    setIsLoading(true);
    queryTenantList().then(res => {
      if (JSON.stringify(searchParams) === '{}' || !searchParams?.codeOrName) {
        setData(res);
        setIsLoading(false);
      } else {
        let searchArr: TenantListProps[] = [];
        res.forEach(i => {
          if (i.name.includes(searchParams.codeOrName!) || i.tenantCode.includes(searchParams.codeOrName!)) {
            searchArr.push(i);
          }
        });
        setData(searchArr);
        setIsLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const onReset = () => {
    const params = {
      codeOrName: undefined,
    };
    setSearchParams(params);
  };

  const onFinish = (v: FilterProps) => {
    setSearchParams({ ...v });
  };

  const filters = (
    <Filter onFinish={v => onFinish(v as FilterProps)} form={form} onReset={onReset}>
      <Form.Item name="codeOrName" label="租户名称/Code" style={{ width: '40%', marginLeft: 10 }}>
        <Input placeholder="请输入租户名称/Code搜索" />
      </Form.Item>
    </Filter>
  );

  const onSelectChange = (rowKeys: Key[], rows: any[]) => {
    const data = rows.filter(item => !!item).filter(i => !selectedData?.map(s => s.tenantMcid).includes(i.mcid));
    setSelectedRows(data);
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({
      disabled: (selectedData ?? []).map(i => i.tenantMcid).includes(record.mcid),
    }),
    selectedRowKeys: (selectedRows ?? []).map(i => i.mcid).concat((selectedData ?? []).map(item => item.tenantMcid)),
  };

  const length = useMemo(() => {
    return [...new Set(selectedData?.map(i => i.tenantMcid).concat((selectedRows ?? []).map(i => i.mcid)))].length;
  }, [selectedData, selectedRows]);

  const showTotal: PaginationProps['showTotal'] = (total: any) => `共 ${total} 条`;

  return (
    <>
      <Modal
        className={styles.ModalStyle}
        open={visible}
        title="选择租户"
        width={900}
        destroyOnClose
        onOk={() => {
          onOk?.();
        }}
        onCancel={() => {
          onCancel?.();
          setSelectedRows([]);
        }}
      >
        {filters}
        <span>
          已选择<span style={{ color: '#d62500', padding: '0 5px', fontWeight: 500 }}>{length}</span>项
          <InfoCircleOutlined style={{ margin: '0 4px 0 20px' }} />
          <span>如需更改请移除后重新选择</span>
        </span>
        <Table
          loading={isLoading}
          sticky
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          dataSource={data}
          rowKey="mcid"
          scroll={{ y: 300 }}
          columns={columns}
          pagination={{
            defaultPageSize: 20,
            showTotal,
            showSizeChanger: true,
          }}
        />
      </Modal>
    </>
  );
});

export default CustomerModal;
