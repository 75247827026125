import React, { PropsWithChildren, useState, ReactNode } from 'react';
import { Form, Space, FormInstance, Row, Col } from 'antd';
import { Button } from '@maxtropy/components';
// import styles from "./index.module.scss";

export interface Filter<T> {
  onFinish: (val: object) => void;
  children: ReactNode;
  initialValues?: T;
  onReset?: () => void;
  form: FormInstance;
  collapseItems?: React.ReactNode;
  collapseOpen?: boolean;
}

function Filter<T>(props: PropsWithChildren<Filter<T>>): React.ReactElement {
  const { onFinish, onReset, children, initialValues, form, collapseItems, collapseOpen } = props;

  const _onReset = () => {
    onReset && onReset();
    form.resetFields();
  };

  const [open, setOpen] = useState(collapseOpen ?? false);

  return (
    <Form
      name="customized_form_controls"
      form={form}
      // layout="inline"
      onFinish={onFinish}
      initialValues={initialValues as any}
    // className={styles.filter}
    >
      <Row gutter={20} style={{ marginTop: 15 }}>
        {children}
        {open && collapseItems}
        <Col
          span={4}
          style={{
            marginRight: 0,
            minWidth: 150,
            display: 'flex',
            flexDirection: 'row-reverse',
            marginLeft: 'auto',
          }}
        >
          <Form.Item>
            <Space size={12}>
              {collapseItems && (
                <Button type="link" onClick={() => setOpen(!open)}>
                  {open ? '收起' : '展开'}
                </Button>
              )}
              <Button type="primary" htmlType="submit">
                查询
              </Button>
              <Button onClick={_onReset}>重置</Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default Filter;
