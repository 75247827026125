import { Form, Select } from 'antd';
import { AppFunctionPermissionVo } from '../../api/cc-app';
import { PermissionsType } from '../../common/permissionsConst';
export const routes = [
  { name: '租户组织权限和账号' },
  { name: '租户管理' },
  { name: '轻流开户' },
  { name: '轻流开户设置' },
];

export const formLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

export const tailLayout = {
  wrapperCol: { offset: 2, span: 16 },
};

export interface AppPackage {
  oneMenu: string; // 一级菜单
  secondMenu: string; // 二级菜单
  thirdMenu: string; // 三级菜单(页面)
  thirdMenuCode: string; // 三级权限编码
  fourMenuCode: string;
  fourMenu: any; // 四级权限
  qflowPage: any; // 三方应用
}
export interface AppModules {
  appPermssion?: string[];
  fourLevelPermssion?: string;
  integratedApps?: number;
  qflowPage?: string;
  qflowTag?: string;
}
// 工单管理应用包ID配置数据
export const workOrderConfigData: AppPackage[] = [
  {
    oneMenu: '工单管理',
    secondMenu: '工单管理',
    thirdMenu: '报警工单',
    thirdMenuCode: PermissionsType.P_QL_WORK_ALARM,
    fourMenuCode: PermissionsType.P_QL_WORK_ALARM,
    fourMenu: null,
    qflowPage: null,
  },
];

// 定制轻流模块表头模板
export const qflowModuleData = [
  {
    integratedApps: '', // 集成应用
    appPermssion: [], // 应用功能权限
    fourLevelPermssion: '', // 四级权限
    qflowTag: '', // 轻流应用包
    qflowPage: '', // 对应轻流应用
  },
];

interface RenderColsProps {
  record: any;
  name: string;
  options: React.ReactElement;
  required?: boolean;
}
interface DependentColsProps {
  record: any;
  name: string;
  dependentName: string;
  required?: (dependentData: any) => boolean;
  optionsRender?: (dependentData: any, rowData: any) => JSX.Element; // 下拉选项
}

// 渲染列
export const renderCols = ({ record, name, options, required }: RenderColsProps) => {
  return (
    <Form.Item {...record} name={[record.name, name]} rules={[{ required, message: '请选择' }]}>
      <Select placeholder="请选择" style={{ width: '100%' }} allowClear>
        {options}
      </Select>
    </Form.Item>
  );
};

// 渲染有副作用(依赖项列)
export const dependentRenderCols = ({ record, name, dependentName, required, optionsRender }: DependentColsProps) => {
  const fieldRow = ['moduleItems', record.name];
  const fieldNames = ['moduleItems', record.name, dependentName];
  return (
    <Form.Item noStyle dependencies={[fieldNames]}>
      {({ getFieldValue }) => {
        let rowData = getFieldValue(fieldRow);
        let dependentData = getFieldValue(fieldNames);
        return (
          <>
            <Form.Item
              {...record}
              rules={[{ required: required?.(dependentData), message: '请选择' }]}
              name={[record.name, name]}
            >
              {optionsRender && optionsRender(dependentData, rowData)}
            </Form.Item>
          </>
        );
      }}
    </Form.Item>
  );
};

// 将级联数据添加disabled属性
export const formateTreePemission = (hasSelects: string[], treeData: AppFunctionPermissionVo[]) => {
  treeData.forEach(item => {
    if (hasSelects.includes(item.code)) {
      item.disabled = true;
    } else {
      item.disabled = false;
      if (item.children && item.children.length > 0) {
        formateTreePemission(hasSelects, item.children);
      }
    }
  });
};
