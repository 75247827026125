import { FC, Key, useCallback } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

export interface ContentProps {
  id: string;
  name: string;
}

interface FormDataPermissionProps {
  value?: Key[];
  onChange?: (value: Key[]) => void;
  content: ContentProps[];
  disabled?: boolean;
}

const FormDataPermission: FC<FormDataPermissionProps> = props => {
  const { value, onChange, content, disabled = false } = props;

  const isChecked = useCallback(
    (id: Key) => {
      return (value ?? []).includes(id);
    },
    [value]
  );

  const onClick = (id: Key, checked: boolean) => {
    if (disabled) return;
    const newValue = [...(value ?? [])];
    if (checked) {
      newValue.push(id);
    } else {
      const index = newValue.findIndex(i => i === id);
      if (index !== -1) {
        newValue.splice(index, 1);
      }
    }
    onChange && onChange(newValue);
  };

  return (
    <div className={styles.row}>
      {content.map(i => {
        const checked = isChecked(i.id);
        const className = classNames(styles.col, { [styles.checked]: checked });
        return (
          <div
            onClick={() => {
              onClick(i.id, !checked);
            }}
            key={i.id}
            className={className}
          >
            {i.name}
          </div>
        );
      })}
    </div>
  );
};

export default FormDataPermission;
