import { Button, EllipsisSpan, Filter, Key, Wrapper, useUpdate } from '@maxtropy/components';
import { Col, Divider, Form, Input, Popconfirm, Table, Select, Space, Tag, Tooltip } from 'antd';
import {
  ApplicationServiceType,
  ApplicationServiceTypeDisplay,
  ColorTypeDisplay,
  MaxAbilityListRes,
  MenuLabelListRes,
  PermissionType,
  PermissionTypeDisplay,
  getAbilityList,
  getMenuLabelList,
  updateMenuLabel,
} from '../../api/cc-MaxtropyAbilityList';
import { DefaultOptionType } from 'antd/es/select';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { isNil, uniqueId } from 'lodash-es';
import AddLabelModal from './AddLabelModal';
import styles from './index.module.scss';

const routes = [{ name: '系统设置' }, { name: '熵云平台资料' }, { name: '熵云能力清单' }];

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  record: MaxAbilityListRes;
  index: number;
  children: React.ReactNode;
  selectOptions: DefaultOptionType[];
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  selectOptions,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }}>
          <Select
            maxTagCount={1}
            options={selectOptions}
            labelInValue
            mode="multiple"
            showSearch
            optionFilterProp="label"
          />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

interface FilterParams {
  abilityMenuName?: string;
  tenantMenuName?: string;
  preSalesBundleLabelIds?: number[];
  permissionCode?: string;
}

interface TableItem extends Omit<MaxAbilityListRes, 'preSalesBundleLabels'> {
  preSalesBundleLabels: { value: Key; label: string }[];
}

const MaxtropyAbilityList: React.FC = () => {
  const [form] = Form.useForm();
  const [filterForm] = Form.useForm();
  const [data, setData] = useState<TableItem[]>([]);
  const [originData, setOriginData] = useState<TableItem[]>([]);
  const [menuLabelList, setMenuLabelList] = useState<MenuLabelListRes[]>([]);
  const [editingKey, setEditingKey] = useState<Key>();
  const [filterParams, setFilterParams] = useState<FilterParams>({});
  const [open, setOpen] = useState<boolean>(false);
  const [update, updateFn] = useUpdate();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    // 获取售前套餐标签
    getMenuLabelList().then(setMenuLabelList);
  }, [update]);

  useEffect(() => {
    setLoading(true);
    getAbilityList(filterParams.preSalesBundleLabelIds ?? [])
      .then(res => {
        let result = (res ?? []).map(i => ({
          ...i,
          key: `${i.permissionId}-${i.permissionType}-${i.applicationServiceType}`,
          preSalesBundleLabels: (i.preSalesBundleLabels ?? []).map((j: { id: Key; name: string }) => ({
            value: j.id,
            label: j.name,
          })),
        }));
        setOriginData(result);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filterParams.preSalesBundleLabelIds, update]);

  useEffect(() => {
    let filterData = originData;
    // 按功能菜单名称过滤
    if (!isNil(filterParams.abilityMenuName) && filterParams.abilityMenuName !== '') {
      filterData = filterData.filter(i => i.functionMenuName.includes(filterParams.abilityMenuName ?? ''));
    }
    // 按演示租户导航过滤
    if (!isNil(filterParams.tenantMenuName) && filterParams.tenantMenuName !== '') {
      filterData = filterData.filter(i =>
        (i.tenantNavigationName ?? []).join('、').includes(filterParams.tenantMenuName ?? '')
      );
    }
    // 按功能代码过滤
    if (!isNil(filterParams.permissionCode) && filterParams.permissionCode !== '') {
      filterData = filterData.filter(i => i.permissionCode.includes(filterParams.permissionCode ?? ''));
    }
    setData(filterData);
  }, [filterParams?.abilityMenuName, filterParams?.tenantMenuName, filterParams.permissionCode, update, originData]);

  const menuLabelOptions = useMemo(() => {
    return (menuLabelList ?? []).map(i => ({ label: i.name, value: i.id }));
  }, [menuLabelList]);

  const isEditing = (record: TableItem) => record.key === editingKey;

  const edit = (record: Partial<TableItem> & { key: Key }) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey(undefined);
  };

  const save = (record: TableItem) => {
    const row = form.getFieldsValue();
    const newData = [...data];
    const index = newData.findIndex(item => record.key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      // 更新售前套餐标签
      updateMenuLabel({
        permissionType: record.permissionType,
        permissionId: record.permissionId,
        preSaleBundleLabelIds: (row.preSalesBundleLabels ?? []).map((i: any) => i.value),
      });
      setData(newData);
      setEditingKey(undefined);
    }
  };

  const renderWrap = (value: string | ReactNode) =>
    value ? (
      <div className={styles.textWrap}>
        <Tooltip title={value} placement="topLeft">
          {value}
        </Tooltip>
      </div>
    ) : (
      '--'
    );

  const columns = [
    {
      title: '功能代码',
      dataIndex: 'permissionCode',
      ellipsis: { showTitle: true },
      fixed: 'left' as 'left',
      width: 100,
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '功能菜单',
      dataIndex: 'functionMenuName',
      ellipsis: { showTitle: true },
      fixed: 'left' as 'left',
      width: 250,
      render: (v: string) => renderWrap(v),
    },
    {
      title: '演示租户导航',
      dataIndex: 'tenantNavigationName',
      ellipsis: { showTitle: true },
      fixed: 'left' as 'left',
      width: 250,
      render: (v: string[]) => {
        return <>{v?.length > 0 ? v.map(i => renderWrap(i)) : '--'}</>;
      },
    },
    {
      title: '售前套餐标签',
      dataIndex: 'preSalesBundleLabels',
      ellipsis: { showTitle: true },
      width: 300,
      editable: true,
      render: (v: { value: Key; label: string }[]) => {
        return v && v.length > 0 ? (
          <>
            <div className={styles.tagDisplay}>
              {v.map(i => (
                <Tooltip title={i.label} placement="topLeft">
                  <Tag color={ColorTypeDisplay[menuLabelList.find(item => item.id === i.value)?.colorType ?? 0]}>
                    <span key={uniqueId()} style={{ fontSize: 12, color: 'rgba(0,0,0,0.65)', fontWeight: 400 }}>
                      {i.label}
                    </span>
                  </Tag>
                </Tooltip>
              ))}
            </div>
          </>
        ) : (
          '--'
        );
      },
    },
    {
      title: '应用服务',
      dataIndex: 'applicationServiceType',
      ellipsis: { showTitle: true },
      width: 80,
      render: (v: ApplicationServiceType) => <EllipsisSpan value={ApplicationServiceTypeDisplay[v]} />,
    },
    {
      title: '权限类型',
      dataIndex: 'permissionType',
      ellipsis: { showTitle: true },
      width: 100,
      render: (v: PermissionType) => <EllipsisSpan value={PermissionTypeDisplay[v]} />,
    },
    {
      title: '集成应用',
      dataIndex: 'integratedApplicationName',
      ellipsis: { showTitle: true },
      width: 100,
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: 'URL',
      dataIndex: 'routeLink',
      width: 100,
      ellipsis: { showTitle: true },
      render: (v: string[]) => {
        return v && v.length > 0 ? (
          <Tooltip title={v.join('、')} placement="topLeft">
            {v.map((i, index) => (
              <Button type="link" onClick={() => window.open(i, '_blank')}>
                {v}
              </Button>
            ))}
          </Tooltip>
        ) : (
          <EllipsisSpan value={undefined} />
        );
      },
    },
    {
      title: '亮点(控标项)',
      dataIndex: 'keyFeatures',
      width: 200,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '功能简述',
      dataIndex: 'featureOverview',
      width: 200,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      fixed: 'right' as 'right',
      width: 150,
      render: (_: any, record: TableItem) => {
        const editable = isEditing(record);
        return editable ? (
          <Space size={16}>
            <Button type="link" onClick={() => save(record)} style={{ marginRight: 8 }}>
              保存
            </Button>
            <Popconfirm title="确定取消？" onConfirm={cancel}>
              <Button type="link">取消</Button>
            </Popconfirm>
          </Space>
        ) : (
          <Button type="link" disabled={!isNil(editingKey)} onClick={() => edit(record)}>
            编辑
          </Button>
        );
      },
    },
  ];

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: TableItem) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        selectOptions: menuLabelOptions,
      }),
    };
  });

  const onFinish = (values: FilterParams) => {
    setFilterParams(values);
  };

  const onReset = () => {
    filterForm.resetFields();
    setFilterParams({});
  };

  const filters = (
    <Filter
      form={filterForm}
      onFinish={(v: FilterParams) => {
        onFinish(v);
      }}
      onReset={onReset}
    >
      <Col span={6}>
        <Form.Item label="功能菜单名称" name="abilityMenuName">
          <Input placeholder="请输入" />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="演示租户导航" name="tenantMenuName">
          <Input placeholder="请输入" />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="售前套餐标签" name="preSalesBundleLabelIds">
          <Select
            maxTagCount={1}
            placeholder="请选择"
            options={menuLabelOptions}
            mode="multiple"
            showSearch
            optionFilterProp="label"
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="功能代码" name="permissionCode">
          <Input placeholder="请输入" />
        </Form.Item>
      </Col>
    </Filter>
  );
  return (
    <Wrapper routes={routes} filters={filters}>
      <div
        style={{
          marginBottom: 20,
        }}
      >
        <Button type="primary" onClick={() => setOpen(true)}>
          套餐标签管理
        </Button>
      </div>
      <Form form={form} component={false}>
        <Table
          rowKey="key"
          sticky
          scroll={{ x: 1300, y: 'calc(100vh - 340px)' }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          loading={loading}
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
        />
      </Form>
      {open && <AddLabelModal open={open} setOpen={setOpen} listUpdateFn={updateFn} />}
    </Wrapper>
  );
};
export default MaxtropyAbilityList;
