/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Space, Tabs, Select, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Wrapper, Filter, Table, Paging, usePaging, EllipsisSpan } from '@maxtropy/components';
import styles from './index.module.scss';
import {
  disableUser,
  enableUser,
  freezeUser,
  getAllUser,
  getUserList,
  GetUserListRequest,
  OperateUserResponse,
  resetPassword,
} from '../../../../api/cc-user';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

const { TabPane } = Tabs;

const routes = [{ name: '系统设置' }, { name: '平台组织权限和账户' }, { name: '员工管理' }];

const statusTabs = [
  {
    tab: '启用',
    key: 0,
  },
  {
    tab: '冻结',
    key: 2,
  },
  {
    tab: '禁用',
    key: 1,
  },
];

enum OperateEnum {
  启用,
  禁用,
  冻结,
  重置密码,
}

export type FilterParams = Omit<GetUserListRequest, 'count' | 'offset' | 'status'>;

const StaffList: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [opened, setOpened] = useState<boolean>(false);
  const [resultModalOpened, setResultModalOpened] = useState<boolean>(false);
  const [resultModalChildren, setResultModalChildren] = useState<React.ReactNode>();
  const [operate, setOperate] = useState<number>(0);
  const [operateUser, setOperateUser] = useState<OperateUserResponse>();
  const [dataSource, setDataSource] = useState<OperateUserResponse[]>([]);
  const [allUserOptions, setAllUserOptions] = useState<OperateUserResponse[]>([]);
  const [searchParams, setSearchParams] = useState<GetUserListRequest>();
  const [statusQuery, setStatusQuery] = useState<{ status: number }>({ status: 0 });
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const showModal = (s: number, v: OperateUserResponse) => {
    setOpened(true);
    setOperate(s);
    setOperateUser(v);
  };

  const statusButtonArray = (v: OperateUserResponse) => [
    <>
      <a href="#" onClick={() => showModal(1, v)}>
        禁用
      </a>
      <a href="#" onClick={() => showModal(2, v)}>
        冻结
      </a>
    </>,
    <>
      <a href="#" onClick={() => showModal(0, v)}>
        启用
      </a>
      <a href="#" onClick={() => showModal(2, v)}>
        冻结
      </a>
    </>,
    <>
      <a href="#" onClick={() => showModal(0, v)}>
        启用
      </a>
      <a href="#" onClick={() => showModal(1, v)}>
        禁用
      </a>
    </>,
  ];

  const staffColumns = [
    {
      title: '员工ID',
      dataIndex: 'id',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '员工姓名',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '员工手机',
      dataIndex: 'cellphone',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '上级',
      dataIndex: 'parentName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '最后修改时间',
      dataIndex: 'updateTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '操作',
      width: 300,
      fixed: 'right',
      render: (v: OperateUserResponse) => {
        return (
          <Space size={16}>
            <Link to={`/platform/account/employee/edit/${v.id}`}>编辑</Link>
            {statusButtonArray(v)[v.status]}
            <a href="#" onClick={() => showModal(3, v)}>
              重置密码
            </a>
            <Link to={`/platform/account/employee/show/${v.id}`}>查看详情</Link>
          </Space>
        );
      },
    },
  ];

  const loadData = (count: number, offset: number) => {
    setLoading(true);
    getUserList({ ...searchParams, count, offset, ...statusQuery }).then(res => {
      if (res) {
        setTotalCount(res.total);
        setPageOffset(res.offset);
        setDataSource(
          res.contents.map(item => {
            return {
              ...item,
              updateTime: dayjs(item.updateTime).format('YYYY-MM-DD HH:mm:ss'),
              createTime: dayjs(item.createTime).format('YYYY-MM-DD HH:mm:ss'),
            };
          })
        );
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData(pageSize, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusQuery, searchParams, pageSize]);

  useEffect(() => {
    loadData(pageSize, pageOffset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset]);

  useEffect(() => {
    getAllUser().then(res => {
      if (res) {
        setAllUserOptions(res.list);
      }
    });
  }, []);

  const onFilter = (query: GetUserListRequest) => {
    setSearchParams(query);
  };

  // prettier-ignore
  const onOk = async () => {// NOSONAR
    if (operateUser) {
      if (operate === 0) {
        const res = await enableUser({ corpUserId: operateUser.id });
        if (res) {
          setOpened(false);
          setResultModalOpened(true);
          setResultModalChildren(<p>已启用用户{operateUser?.name}</p>);
          loadData(pageSize, pageOffset);
        }
      } else if (operate === 1) {
        const res = await disableUser({ corpUserId: operateUser.id });
        if (res) {
          setOpened(false);
          setResultModalOpened(true);
          setResultModalChildren(<p>已禁用用户{operateUser?.name}</p>);
          loadData(pageSize, pageOffset);
        }
      } else if (operate === 2) {
        const res = await freezeUser({ corpUserId: operateUser.id });
        if (res) {
          setOpened(false);
          setResultModalOpened(true);
          setResultModalChildren(<p>已冻结用户{operateUser?.name}</p>);
          loadData(pageSize, pageOffset);
        }
      } else if (operate === 3) {
        setOpened(false);
        const res = await resetPassword({ corpUserId: operateUser.id });
        if (res) {
          setOpened(false);
          setResultModalOpened(true);
          setResultModalChildren(<p>已重置用户{operateUser?.name}的密码并发送到用户手机</p>);
          loadData(pageSize, pageOffset);
        }
      }
    }
  };

  const onReset = () => {
    setSearchParams({});
  };

  const [searchForm] = Form.useForm();

  const handleTabChange = (key: string) => {
    setStatusQuery({ status: Number(key) });
    onReset();
    searchForm.resetFields();
  };

  const filters = (
    <Filter<FilterParams> form={searchForm} onFinish={onFilter} onReset={onReset} hasClear>
      <>
        <Col span={4}>
          <Form.Item name="id" label="员工ID" rules={[{ pattern: /^\d+$/, message: '格式不正确' }]}>
            <Input placeholder="请输入员工ID" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="name" label="员工姓名">
            <Input placeholder="请输入员工姓名" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="cellphone" label="员工手机号">
            <Input placeholder="请输入员工手机号" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="parentId" label="上级">
            <Select
              placeholder="请选择"
              showSearch
              allowClear
              options={allUserOptions.map(i => ({ value: i.id, label: i.name }))}
              filterOption={(input, option) => option!.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            />
          </Form.Item>
        </Col>
      </>
    </Filter>
  );

  const filtersWrapper = (
    <Tabs defaultActiveKey={`0`} className={styles.filterNav} onChange={handleTabChange}>
      {statusTabs.map(item => (
        <TabPane tab={item.tab} key={item.key}>
          {filters}
        </TabPane>
      ))}
    </Tabs>
  );

  return (
    <Wrapper routes={routes} filters={filtersWrapper} filtersStyle={{ paddingTop: 0 }}>
      <div className={styles.operationArea}>
        <Button type="primary">
          <Link to="/platform/account/employee/create">
            <PlusOutlined />
            新建用户
          </Link>
        </Button>
      </div>
      <Table
        rowKey="id"
        sticky
        scroll={{ x: 1500 }}
        loading={loading}
        dataSource={dataSource}
        columns={staffColumns as any}
      />
      <Paging pagingInfo={pagingInfo} />
      <Modal onOk={onOk} onCancel={() => setOpened(false)} title="确认执行操作" open={opened}>
        <p>即将执行【{OperateEnum[operate]}】操作，本操作需要请您再次确认后才会执行，点击【取消】将放弃此次操作</p>
      </Modal>
      <Modal
        title="操作成功"
        children={resultModalChildren}
        open={resultModalOpened}
        onOk={() => setResultModalOpened(false)}
        onCancel={() => setResultModalOpened(false)}
      />
    </Wrapper>
  );
};

export default StaffList;
