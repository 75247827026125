import qs from 'qs';
import { Key } from 'react';
import { fetch } from '@maxtropy/components';
import { AppFunctionPermissionVo } from './cc-app';

export interface Paged<T> {
  total: number;
  offset: number;
  count: number;
  contents: T[];
}

export interface PageRequest {
  offset?: number;
  count?: number;
}

export interface QlTenantRequest extends PageRequest {
  tenantCode?: string;
  tenantName?: string;
  qFlowAccountOpenEnable?: boolean;
}
export interface QlTenantPageResponse {
  tenantCode?: string;
  tenantId?: number;
  tenantMcid?: string;
  tenantName?: string;
  qflowAccountOpenEnable?: boolean;
  qFlowDeptId?: number;
  userQuota?: number;
  usedUserQuota?: number;
  // 轻流工作区Id 编码
  qingFlowWorkspaceIdCode?: string;
  // 工作区名称
  qingFlowWorkspaceName?: string;
}
// 轻流开户租户列表
export function getQlTenantList(query: QlTenantRequest): Promise<Paged<QlTenantPageResponse>> {
  return fetch(`/api/qing-flow/account-open/tenant-page?${qs.stringify(query, { indices: false })}`);
}

export interface QlParams {
  tenantId?: Key;
  tenantMcid?: Key;
  userQuota?: Key;
  workOrder?: AppProps;
  integrateAppModules?: IntegratedAppProps[];
}
// 工单应用包
export interface AppProps {
  tagId?: number;
  tagName?: string;
  permissionCode?: string;
  permissionName?: string;
  childPermissions?: ChildPermissionsProps[];
}
// 定制轻流模块
export interface IntegratedAppProps {
  integrateAppId?: number;
  permissionCode?: string[];
  permissionFourCode?: string;
  tagId?: number;
  appId?: number;
  id?: number;
}
export interface ChildPermissionsProps {
  appId?: string;
  appName?: string;
  permissionCode?: string;
  permissionName?: string;
  childPermissionCode?: string; // 四级权限code
}

// 轻流开户创建
export function createQl(params: QlParams & { qingFlowWorkspaceId: string }): Promise<boolean> {
  return fetch('/api/qing-flow/account-open/create', {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'x-tenant-mcid': `${params.tenantMcid}`,
    },
  });
}
// 轻流开户详情
export interface QlDetailProps {
  tenantId: number;
  tenantCode: string;
  tenantMcid: string;
  tenantName: string;
  qingFlowDeptId: number;
  userQuota: number;
  workOrder: AppProps;
  integrateAppModules: IntegratedAppProps[];
  // 轻流工作区 Id 编码
  qingFlowWorkspaceIdCode?: string | null;
}
export function getQlDetail(code: string) {
  return fetch<QlDetailProps>(`/api/qing-flow/account-open/detail?tenantCode=${code}`);
}

// 清流开户编辑
export function updateQl(params: QlParams): Promise<boolean> {
  return fetch('/api/qing-flow/account-open/update', {
    method: 'PUT',
    body: JSON.stringify(params),
    headers: {
      'x-tenant-mcid': `${params.tenantMcid}`,
    },
  });
}
export interface QuotaProps {
  name?: string;
  code?: string;
  disable?: boolean;
}
// 轻流开户配额列表
export function getQlQuota(tenantMcid: string) {
  return fetch<QuotaProps[]>(`/api/qing-flow/account-open/list-user-quota?tenantMcid=${tenantMcid}`);
}
// 更新用户状态启用/禁用
/**
 *
 * @param userId // 用户id（staff code）
 * @param beingDisabled 是否是禁用（是，则禁用用户；否，则启用用户）
 * @param mcid // 租户id
 * @returns
 */
export function updateUserStatus(userId: string, mcid: string, beingDisabled: boolean) {
  return fetch<boolean>(`/api/qingflow/user/${userId}/status?tenantMcid=${mcid}&beingDisabled=${beingDisabled}`, {
    method: 'PUT',
    headers: {
      'x-tenant-mcid': `${mcid}`,
    },
  });
}

// 轻流应用包和应用列表
export interface TagItemProps {
  tagName?: string;
  tagId?: Key;
  tagIcon?: string;
  appList?: AppItemProps[];
}
export interface AppItemProps {
  appKey?: string;
  appName?: string;
}
export function getQlTagList(qingFlowWorkspaceId?: string | number) {
  return fetch<{ tagList?: TagItemProps[] }>(
    `/api/qing-flow/account-open/list-tag?${qs.stringify({ qingFlowWorkspaceId }, { indices: false })}`
  );
}

// 查询应用包和应用列表(包含当前租户), 开户设置时查询应用列表使用
export function getQlTagListWhenSet(tenantMcid: string) {
  return fetch<{ tagList?: TagItemProps[] }>(`/api/qing-flow/account-open/list-tag-update?tenantMcid=${tenantMcid}`);
}

// 查询子权限
export interface PemissionTreeProps {
  id: number;
  code: string;
  name: string;
  children?: PemissionTreeProps[];
}
export function getChildrenPermission(code: string) {
  return fetch<PemissionTreeProps[]>(`/api/qing-flow/account-open/childrenPermission?permissionCode=${code}`);
}

// 查询集成应用三级权限树
export function getThreePermissionTree(id: string | number): Promise<AppFunctionPermissionVo[]> {
  return fetch(`/api/qing-flow/account-open/treePermission?integrateAppId=${id}`);
}

// 查询集成应用下的四级权限
export function getFourPermissionTree(id: string | number, code: string): Promise<AppFunctionPermissionVo[]> {
  return fetch(`/api/qing-flow/account-open/integrateChildrenPermission?integrateAppId=${id}&permissionCode=${code}`);
}

// 轻流工作区
export interface Workspace {
  id: number;
  // 工作区 id
  spaceIdCode?: string;
  // 管理员accessToken
  adminAccessToken?: string;
  // 工作区管理员账号
  adminAccount?: string;
  // 工作区管理员密码
  adminPassword?: string;
  // 工作区名称
  spaceName?: string;
  // 创建时间
  createTime?: string;
  // 更新时间
  updateTime?: string;
  // 最后修改人
  updateByUsername?: string;
  userId?: string;
}

// 获取工作区列表
export function getWorkspaceList() {
  return fetch<Workspace[]>('/api/qingflow/workspace/list');
}

export interface WorkspacePagesRequest extends PageRequest {
  // 工作区 id
  spaceIdCode?: string;
  // 工作区名称
  spaceName?: string;
}

// 获取工作区分页
export function getWorkspacePages(query: WorkspacePagesRequest) {
  return fetch<Paged<Workspace>>(`/api/qingflow/workspace/page?${qs.stringify(query, { indices: false })}`);
}

// 获取工作区信息
export function getWorkspaceInfo(id: string | number) {
  return fetch<Workspace>(`/api/qingflow/workspace/info/${id}`);
}

export type CreateWorkspaceParams = Omit<Workspace, 'id' | 'createTime' | 'updateTime' | 'updateByUsername'>;

// 创建工作区
export function createWorkspace(params: CreateWorkspaceParams) {
  return fetch('/api/qingflow/workspace/insert', {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export type UpdateWorkspaceParams = Pick<
  Workspace,
  'spaceIdCode' | 'spaceName' | 'adminAccessToken' | 'adminAccount' | 'adminPassword' | 'userId'
>;

// 更新工作区
export function updateWorkspace(id: string | number, params: UpdateWorkspaceParams) {
  return fetch(`/api/qingflow/workspace/update/${id}`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

// 删除工作区
export function deleteWorkspace(id: string | number) {
  return fetch(`/api/qingflow/workspace/delete/${id}`, {
    method: 'DELETE',
  });
}
