import { FormContent } from '@maxtropy/components';
import { FC, useEffect, useMemo, useState } from 'react';
import { Form } from 'antd';

import styles from './index.module.scss';
import { getTemplateByVersion, Template } from '../../../api/template';
import { getBatchDeviceTemplateAttribute, BatchDeviceTemplateAttributeResponse } from '../../../api/batch';
import AttributeItem from '@/shared/components/AttributeItem';

import { FieldValue } from '@/shared/types';
import { buildValueFn } from '@/shared/components/FieldItem/util';
import { CustomAttribute } from '@/shared/components/CustomAttributes/Editor';
import CustomAttributesDisplay from '@/shared/components/CustomAttributes';

interface AttributeDetailProps {
  id: string;
}

const AttributeDetail: FC<AttributeDetailProps> = ({ id }) => {
  const [currentTemplateId, setTemplateId] = useState<number>();
  const [currentVersion, setCurrentVersion] = useState<number>();
  const [template, setTemplate] = useState<Template>();
  const [form] = Form.useForm();
  const [templateForm] = Form.useForm();

  const [customAttributes, setCustomAttributes] = useState<CustomAttribute[]>([]);

  useEffect(() => {
    setTemplate(undefined);
    if (currentTemplateId !== undefined && currentVersion !== undefined) {
      getTemplateByVersion(currentTemplateId, currentVersion).then(res => {
        setTemplate(res.template);
      });
    }
    templateForm.setFieldsValue({
      template: currentTemplateId,
    });
  }, [currentTemplateId, currentVersion, templateForm]);

  const [deviceAttribute, setDeviceAttribute] = useState<BatchDeviceTemplateAttributeResponse>();

  useEffect(() => {
    getBatchDeviceTemplateAttribute(id).then(res => {
      if (res) {
        setDeviceAttribute(res);
        if (res.templateId && res.templateVersion) {
          setTemplateId(res.templateId);
          setCurrentVersion(res.templateVersion);
        }
      }
    });
  }, [id]);

  useEffect(() => {
    if (template) {
      setCustomAttributes(template?.description ? JSON.parse(template?.description) : []);
    }
  }, [template]);

  const templateFlatten = useMemo(() => {
    if (template) {
      return template.deviceAttributes.map(i => i.deviceFields).flat();
    } else {
      return [];
    }
  }, [template]);

  useEffect(() => {
    if (deviceAttribute) {
      if (deviceAttribute.templateId) {
        setTemplateId(deviceAttribute.templateId);
        setCurrentVersion(deviceAttribute.templateVersion);
      }
      if (deviceAttribute.list && deviceAttribute.list.length) {
        const data = deviceAttribute.list.reduce((accumulator, currentValue) => {
          const findTemplate = templateFlatten.find(i => i.id === currentValue.fieldId);
          const buildValue = buildValueFn(currentValue.value!, findTemplate);
          accumulator[currentValue.fieldId] = { unitId: currentValue.unitId, value: buildValue };
          return accumulator;
        }, {} as Record<string, FieldValue>);
        form.setFieldsValue(data);
      }
    }
  }, [deviceAttribute, form, templateFlatten]);

  return (
    <>
      {template && (
        <FormContent title="设备固定信息">
          <div className={styles.body}>
            <div className={styles.list}>
              <Form form={form}>
                {template.deviceAttributes.map((item, index) => (
                  <AttributeItem key={index} disabled attribute={item} />
                ))}
              </Form>
            </div>
          </div>
          {!!customAttributes.length && (
            <CustomAttributesDisplay style={{ marginTop: 30 }} customAttributes={customAttributes} />
          )}
        </FormContent>
      )}
    </>
  );
};

export default AttributeDetail;
