import React, { CSSProperties } from 'react';
import { Form, Row, Col } from 'antd';
import styles from './index.module.scss';
import ShowInput from '../ShowInput';

export interface InfoDisplayProps {
  data?: any;
  className?: string;
  style?: CSSProperties;
}

function InfoDisplay(props: InfoDisplayProps) {
  const { data, className, style } = props;
  return (
    <Form className={className} style={style}>
      <Row className={styles.wrapper}>
        <Col span={12}>
          <Form.Item label="设备名称">{data?.name}</Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="设备S/N码">{data?.sn ?? '--'}</Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="所属类目">{data?.typeName}</Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="物模型型号">
            <ShowInput
              value={
                data?.physicalModelId
                  ? `${data?.physicalModelNo ?? data.modelNo}-${data?.manufacturerName}`
                  : data?.physicalModelId
              }
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="所属型号">{data?.modelName}</Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default InfoDisplay;
