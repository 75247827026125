import { useCallback, useEffect, useState, FC, useMemo } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { RuleContent } from '../../api/entities';
import { Button, Form, Input, InputNumber, message, Modal, Space, Typography, Row, Col } from 'antd';
import { createRule, getRule, updateRule } from '../../api/mockRule';
import { RuleNames, RuleType } from '../../api/constants';
import { cssMonospaceFont } from './util';
import PreviewRuleModal from './PreviewRuleModal';
import { Wrapper } from '@maxtropy/components';
import InterpolationModal from './InterpolationModal';
import { isNil } from 'lodash-es';

const { TextArea } = Input;
const { Title } = Typography;

export interface RuleFormProps {
  isUpdate: boolean;
  type: RuleType;
}

export interface RuleFormParams {
  id?: string;
}

// prettier-ignore
const RuleForm: FC<RuleFormProps> = ({ isUpdate, type }) => {// NOSONAR
  const [modalApi, modalContextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();
  const { id } = useParams<{
    [key in keyof RuleFormParams]?: string;
  }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm<RuleContent>();
  const fromId = new URLSearchParams(location.search).get('copyFrom');
  const shouldLoad: boolean = !!((isUpdate && id) || fromId);
  const loadId: number = fromId ? +fromId : id ? +id : NaN;

  const [previewValue, setPreviewValue] = useState<RuleContent>();
  const [interpolationModalOpen, setInterpolationModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (shouldLoad) {
      getRule(type, loadId).then(s => form.setFieldsValue(s));
    }
  }, [form, shouldLoad, loadId, type]);

  const onFinish = useCallback(
    (rule: RuleContent) => {
      (isUpdate && !isNil(id) ? updateRule(type, +id, rule) : createRule(type, rule))
        .then(() => navigate(`/data-tools/shaping/${type}`))
        .catch(e => {
          messageApi.error('保存失败');
        });
    },
    [isUpdate, id, type, navigate]
  );

  const preview = () => {
    form.validateFields().then(v => {
      if (!v.expression) {
        modalApi.error({
          content: '请填写表达式',
        });
        return;
      }
      setPreviewValue(v);
    });
  };

  const onCancel = () => {
    setPreviewValue(undefined);
  };

  const routes = useMemo(() => {
    return [
      { name: '数据运营工具' },
      { name: '数据模拟' },
      { name: `${RuleNames[type]}配置` },
      { name: `创建规则` },
    ];
  }, [type]);

  return (
    <Wrapper routes={routes}>
      <Title level={2}>{isUpdate ? `更新${RuleNames[type]}` : `创建${RuleNames[type]}`}</Title>
      <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{ constants: 0 }}>
        <Form.Item name="name" label="名称" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="description" label="说明">
          <Input />
        </Form.Item>
        <Form.Item label="参数个数" tooltip="需要与表达式中的参数个数一致">
          <Row gutter={8}>
            <Col>
              <Form.Item name="constants" noStyle>
                <InputNumber min={0} max={10} precision={0} />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item
          name="expression"
          label={`表达式（${type === RuleType.UPLOAD_TIME
              ? '距下次上传的时间间隔，单位：秒'
              : type === RuleType.DATA_TIME
                ? '数据发生时间到上传时间之间的间隔，单位：秒'
                : type === RuleType.DATA_VALUE
                  ? '上传数据值'
                  : ''
            }）`}
        >
          <TextArea
            rows={4}
            style={cssMonospaceFont}
            placeholder={`可使用参数t（${type === RuleType.UPLOAD_TIME
                ? '上次上传时间'
                : type === RuleType.DATA_TIME
                  ? '本次上传时间'
                  : type === RuleType.DATA_VALUE
                    ? '本次数据发生时间'
                    : ''
              }，格式为Unix毫秒时间戳）
可定义参数变量，从c1开始计，如参数个数为3个时可使用c1、c2、c3
例：t * 0.001 + c1`}
          />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              保存
            </Button>
            {type === RuleType.DATA_VALUE && (
              <Button type="primary" onClick={preview}>
                规则预览
              </Button>
            )}
          </Space>
        </Form.Item>
      </Form>
      {type === RuleType.DATA_VALUE && <PreviewRuleModal onCancel={onCancel} previewValue={previewValue} />}
      {type === RuleType.DATA_VALUE ? (
        <InterpolationModal
          open={interpolationModalOpen}
          onCancel={() => {
            setInterpolationModalOpen(false);
          }}
        />
      ) : null}
      {modalContextHolder}
      {messageContextHolder}
    </Wrapper>
  );
};

export default RuleForm;
