import React from 'react';
import { Input, InputNumber, Select } from 'antd';
import { InputProps } from 'antd/es/input';
import { SelectProps } from 'antd/es/select';
import { InputNumberProps } from 'antd/es/input-number';
import { AddrOffsetOptions, BuadRateOptions, DataBitOptions, StopBitOptions, DriveType } from '../../../types';
import { CheckTypeOptions, CrcOrderOptions, SerialPortOptions } from '../../DataAcquisitionForm/common';
import { limitedIntegerValidatorBuilder } from '../../DataAcquisitionForm/mockingbird/Form';

const CustomSelect = ({ options, ...restProps }: { options: any[]; restProps: SelectProps<any> }) => (
  <Select {...restProps}>
    {options.map(item => (
      <Select.Option key={item.value} value={item.value}>
        {item.label}
      </Select.Option>
    ))}
  </Select>
);

const placeholder = '需要从excel模版中录入';

const SerialPortField = {
  label: '串口地址',
  name: 'serialPort',
  placeholder,
  render: (props: any) => <CustomSelect options={SerialPortOptions} {...props} disabled />,
};

const StationNumField = {
  label: 'Modbus站号',
  name: 'stationNum',
  placeholder,
  render: (props: InputNumberProps) => <InputNumber {...props} disabled />,
};

const BaudrateField = {
  label: '波特率',
  name: 'baudRate',
  placeholder: '请选择波特率',
  rules: [{ required: true, message: '请选择波特率' }],
  render: (props: any) => <CustomSelect options={BuadRateOptions} {...props} />,
};

const CheckTypeField = {
  label: '校验',
  name: 'checkType',
  placeholder: '请选择校验',
  rules: [{ required: true, message: '请选择校验' }],
  render: (props: any) => <CustomSelect options={CheckTypeOptions} {...props} />,
};

const DataBitField = {
  label: '数据位',
  name: 'dataBit',
  placeholder: '请选择数据位',
  rules: [{ required: true, message: '请选择数据位' }],
  render: (props: any) => <CustomSelect options={DataBitOptions} {...props} />,
};

const StopBitField = {
  label: '停止位',
  name: 'stopBit',
  placeholder: '请选择停止位',
  rules: [{ required: true, message: '请选择停止位' }],
  render: (props: any) => <CustomSelect options={StopBitOptions} {...props} />,
};

const CrcOrderField = {
  label: 'CRC字节序',
  name: 'crcOrder',
  placeholder: '请选择CRC字节序',
  rules: [{ required: true, message: '请选择CRC字节序' }],
  render: (props: any) => <CustomSelect options={CrcOrderOptions} {...props} />,
};

const GroupWordsField = {
  label: '最大单次请求长度',
  name: 'groupWords',
  placeholder: '请输入最大单次请求长度',
  rules: [{ required: true, message: '请输入最大单次请求长度' }, { validator: limitedIntegerValidatorBuilder(60) }],
  render: (props: InputProps) => <Input addonAfter="字(word)" {...props} />,
};

const SamplingIntervalField = {
  label: '采集间隔',
  name: 'samplingInterval',
  placeholder: '请输入采集间隔',
  rules: [{ required: true, message: '请输入采集间隔' }, { validator: limitedIntegerValidatorBuilder(99999999) }],
  render: (props: InputProps) => <Input {...props} />,
};

const SamplingTimeoutField = {
  label: '采集超时',
  name: 'samplingTimeout',
  placeholder: '请输入采集超时',
  rules: [{ required: true, message: '请输入采集超时' }, { validator: limitedIntegerValidatorBuilder(25000) }],
  render: (props: InputProps) => <Input {...props} />,
};

const SamplingRetryField = {
  label: '重试次数',
  name: 'samplingRetry',
  placeholder: '请输入重试次数',
  rules: [{ required: true, message: '请输入重试次数' }, { validator: limitedIntegerValidatorBuilder(9) }],
  render: (props: InputNumberProps) => <InputNumber {...props} />,
};

const AddrOffsetField = {
  label: '首地址偏移',
  name: 'addrOffset',
  placeholder: '请选择首地址偏移',
  rules: [{ required: true, message: '请选择首地址偏移' }],
  render: (props: any) => <CustomSelect options={AddrOffsetOptions} {...props} />,
};

const IpField = {
  label: 'IP地址',
  name: 'ip',
  placeholder,
  render: (props: InputProps) => <Input {...props} disabled />,
};

const AddressField = {
  label: '表计地址域',
  name: 'address',
  placeholder,
  render: (props: InputProps) => <Input {...props} disabled />,
};

const LeadByteField = {
  label: '前导地址',
  name: 'leadByte',
  placeholder,
  render: (props: InputProps) => <Input {...props} disabled />,
};

const TCPPortField = {
  label: 'TCP端口',
  name: 'port',
  placeholder,
  render: (props: InputProps) => <Input {...props} disabled />,
};

const PortField = {
  label: '端口号',
  name: 'port',
  placeholder,
  render: (props: InputProps) => <Input {...props} disabled />,
};

const commonFormItems = [SamplingIntervalField, SamplingTimeoutField, SamplingRetryField];

const ModbusRTUFormItems = [
  SerialPortField,
  StationNumField,
  BaudrateField,
  CheckTypeField,
  DataBitField,
  StopBitField,
  CrcOrderField,
  GroupWordsField,
  AddrOffsetField,
  ...commonFormItems,
];

const ModbusTCPFormItems = [
  IpField,
  TCPPortField,
  StationNumField,
  CrcOrderField,
  GroupWordsField,
  AddrOffsetField,
  ...commonFormItems,
];

const DLT645FormItems = [
  SerialPortField,
  AddressField,
  LeadByteField,
  BaudrateField,
  CheckTypeField,
  DataBitField,
  StopBitField,
  ...commonFormItems,
];

const IEC104FormItems = [IpField, PortField, ...commonFormItems];

const formItemsByDriveType = {
  [DriveType.MODBUS_RTU]: ModbusRTUFormItems,
  [DriveType.MODBUS_TCP]: ModbusTCPFormItems,
  [DriveType.DLT645_2007]: DLT645FormItems,
  [DriveType.DLT645_1997]: DLT645FormItems,
  [DriveType.IEC104]: IEC104FormItems,
};

export default formItemsByDriveType;
