import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Form, Row, Col, Select, Tag } from 'antd';
import { FormContent } from '@maxtropy/components';

import styles from '../index.module.scss';
import ListTableForm from '../../../components/ListTableForm';
import classnames from 'classnames/bind';
import {
  getChildrenPermission,
  getQlTagList,
  getQlTagListWhenSet,
  PemissionTreeProps,
  QlDetailProps,
  TagItemProps,
} from '../../../api/cc-tenant-ql';
import { AppModules, AppPackage, workOrderConfigData } from '../common';
import { PermissionsType } from '../../../common/permissionsConst';
import QlModuleItem from '../QlModuleItem';
import QflowAppPageSelect from '../QlModuleItem/QflowAppPageSelect';
const { useForm } = Form;
const cx = classnames.bind(styles);

interface Iprops {
  isSet?: boolean; // 区分开户和开户设置
  initData?: QlDetailProps;
  mcid?: string;
  workspaceId?: string;
}

export interface AppConfigRef {
  getValues?: () => any;
  checkForm?: () => Promise<boolean>;
}
const AppConfig = forwardRef<AppConfigRef, Iprops>(({ isSet, initData, mcid, workspaceId }, ref) => {
  const [tagList, setTagList] = useState<TagItemProps[]>([]);
  const [form] = useForm();
  const [fourLevelList, setFourLevelList] = useState<PemissionTreeProps[]>([]);

  // 存储所有已经选择的appId, 防止轻流应用重复选择
  const [selectsAppIds, setSelectAppIds] = useState<string[]>([]);

  // 开户设置反填表单
  // prettier-ignore
  useEffect(() => {// NOSONAR
    if (isSet && initData) {
      let { workOrder, integrateAppModules } = initData;
      let workOrderChildPers = workOrder.childPermissions;
      let tempAppIds: string[] = [];
      if (workOrderChildPers && workOrderChildPers.length > 0) {
        workOrderConfigData.forEach(item => {
          let temp = workOrderChildPers?.find(child => child.permissionCode === item.thirdMenuCode);
          if (temp) {
            item.qflowPage = temp.appId;
            tempAppIds.push(temp.appId!);
          }
        });
      } else {
        workOrderConfigData.forEach(item => {
          item.fourMenu = item.qflowPage = null;
        });
      }
      let tempAppModules;
      if (integrateAppModules && integrateAppModules.length > 0) {
        integrateAppModules.forEach((item: any) => {
          if (tempAppIds.includes(item.appId)) return;
          tempAppIds.push(item.appId);
        });
        tempAppModules = integrateAppModules.map(item => ({
          id: item.id,
          integratedApps: item.integrateAppId,
          appPermssion: item.permissionCode,
          qflowTag: item.tagId,
          qflowPage: item.appId,
        }));
      }
      let workOrderTagId = workOrder.tagId;
      form.setFieldsValue({
        workOrderTagId,
        workOrder: workOrderConfigData,
        moduleItems: tempAppModules ?? [],
      });
      setSelectAppIds(tempAppIds);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSet, initData]);

  // 获取应用包列表
  useEffect(() => {
    let promiseRes = isSet ? getQlTagListWhenSet(mcid!) : getQlTagList(workspaceId);
    promiseRes.then(res => {
      if (res.tagList && res.tagList.length > 0) {
        setTagList(res.tagList);
      }
    });
  }, [isSet, mcid, workspaceId]);

  // 获取报警工单对应的四级权限
  useEffect(() => {
    let code = PermissionsType.P_QL_WORK_ALARM;
    getChildrenPermission(code).then(res => {
      setFourLevelList(res);
    });
  }, []);

  // 获取表单qflowPage所有值
  const appOnchange = () => {
    let allData = form.getFieldsValue();
    let workOrderList = allData?.workOrder ?? [];
    let moduleItemsList = allData?.moduleItems ?? [];
    let data = [...workOrderList, ...moduleItemsList];
    let tempIds: string[] = [];
    data?.forEach((item: any) => {
      let temp = item.qflowPage;
      if (temp) {
        if (tempIds.includes(temp)) return;
        tempIds.push(temp);
      }
    });
    setSelectAppIds(tempIds);
  };
  const checkForm = () =>
    form
      .validateFields()
      .then(val => true)
      .catch(err => false);
  // 获取工单中心值
  const getValues = () => {
    let configValues = form.getFieldsValue();
    let { workOrderTagId, workOrder, moduleItems } = configValues;
    let tempWorkOrder = workOrder.filter((item: AppPackage) => item.qflowPage);
    let tempAppModules = moduleItems?.filter((item: AppModules) => item.integratedApps && item.appPermssion);
    return {
      workOrder: {
        tagId: workOrderTagId,
        permissionCode: PermissionsType.P_QL_WORK_CENTER,
        childPermissions: tempWorkOrder.map((item: AppPackage) => ({
          appId: item.qflowPage,
          permissionCode: item.thirdMenuCode,
          permissionName: item.thirdMenu,
          childPermissionCode: item.fourMenuCode,
        })),
      },
      integrateAppModules: tempAppModules?.map((item: AppModules) => ({
        integrateAppId: item.integratedApps,
        permissionCode: item.appPermssion,
        tagId: item.qflowTag,
        appId: item.qflowPage,
      })),
    };
  };
  useImperativeHandle(ref, () => ({
    getValues,
    checkForm,
  }));
  const workOrderColumns = [
    {
      title: '二级功能权限',
      dataIndex: 'secondMenu',
      name: 'secondMenu',
    },
    {
      title: '页面权限',
      dataIndex: 'thirdMenu',
      name: 'thirdMenu',
    },
    {
      title: '四级权限',
      dataIndex: 'fourMenu',
      name: 'fourMenu',
      render: (v: string, record: any) => {
        return (
          <>
            {fourLevelList.map(item => (
              <Tag key={item.code}>{item.name}</Tag>
            ))}
          </>
        );
      },
    },
    {
      title: '对应轻流应用/门户',
      dataIndex: 'qflowPage',
      name: 'qflowPage',
      render: (v: string, record: any) => {
        return (
          <Form.Item noStyle dependencies={['workOrderTagId']}>
            {({ getFieldValue }) => {
              let dependentData = getFieldValue('workOrderTagId');
              let data = tagList.find(item => item.tagId === dependentData);
              return (
                <>
                  <Form.Item fieldKey={[record.fieldKey, 'qflowPage']} name={[record.name, 'qflowPage']}>
                    <QflowAppPageSelect
                      appId={dependentData}
                      isSet={isSet}
                      onChange={appOnchange}
                      hasSelects={selectsAppIds}
                      options={data?.appList ?? []}
                      disabled={!dependentData}
                    />
                  </Form.Item>
                </>
              );
            }}
          </Form.Item>
        );
      },
    },
  ];

  return (
    <Form
      form={form}
      initialValues={{
        workOrder: workOrderConfigData,
        // moduleItems: qflowModuleData
      }}
    >
      <FormContent title="应用配置">
        <div className={cx('formSubTitle')}>工单管理</div>
        <Row>
          <Col span={8}>
            <Form.Item name="workOrderTagId" label="应用包ID">
              <Select placeholder="请选择" allowClear>
                {tagList.map(item => (
                  <Select.Option key={item.tagId} value={item.tagId}>
                    {item.tagId + '-' + item.tagName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={16}></Col>
          <Col span={20}>
            <ListTableForm cls={workOrderColumns} formName="workOrder" />
          </Col>
        </Row>
        <div className={cx('formSubTitle', 'mg_t')}>定制轻流模块</div>
        <QlModuleItem
          appIdOnChange={appOnchange}
          hasSelectAppIds={selectsAppIds}
          initData={initData}
          isSet={isSet}
          mcid={mcid}
          tagList={tagList}
        />
      </FormContent>
    </Form>
  );
});

export default AppConfig;
