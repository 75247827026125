import React, { useCallback, useEffect, useMemo, useState } from 'react';
import EdgeDevicePointInfo, { EdgeDeviceTemplatePoint } from '@/shared/components/EdgeDevicePointInfo';
import EdgeDeviceTemplateDisplay from '@/shared/components/EdgeDeviceTemplateForm/EdgeDeviceTemplateDisplay';
import { Wrapper, FormContent, Modal } from '@maxtropy/components';
import styles from './index.module.scss';
import { Button, Form, Input, Popconfirm, Space, Spin } from 'antd';
import { ActionType } from '@/shared/types';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@/shared/utils/utils';
import { getEdgeTemplate, getLatestVersionPointList, updateEdgeTemplatePoints } from '../../api/edgeTemplate';
import { InfoCircleOutlined } from '@ant-design/icons';

export interface EdgeTemplatePointProps {}

const routes = [{ name: 'Iot配置' }, { name: '数采配置' }, { name: '数采模板' }, { name: '编辑采集信息' }];

const EdgeTemplatePoint: React.FC<EdgeTemplatePointProps> = () => {
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const query = Object.fromEntries(urlSearchParams.entries());

  const { id } = useParams<{ id: string }>();
  const { data: info, isLoading: isInfoLoading } = useQuery(useCallback(() => getEdgeTemplate(+id!), [id]));

  const { data, isLoading } = useQuery(useCallback(() => getLatestVersionPointList(+id!), [id]));

  const [dataSource, setDataSource] = useState<EdgeDeviceTemplatePoint[]>();

  useEffect(() => {
    if (data) setDataSource(data.map(item => ({ ...item, actionType: ActionType.NONE })));
  }, [data]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      return '正在编辑数采模板，跳转后会丢失相关信息，请保存后离开。';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const handlePopstate = () => {
    window.history.pushState(null, '', document.URL);
    Modal.confirm({
      title: '正在编辑数采模板，跳转后会丢失相关信息，请保存后离开。',
      onOk: () => {
        navigate('/device/edge/template', {
          replace: true,
        });
      },
    });
    };

    window.history.pushState(null, '', document.URL);
    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const canSubmit = useMemo(() => dataSource?.filter(item => item.actionType !== ActionType.NONE).length, [dataSource]);

  const [form] = Form.useForm();
  const remark = Form.useWatch('remark', form);
  const onSubmit = () => {
    updateEdgeTemplatePoints(+id!, {
      remark,
      points: dataSource!
        .filter(item => item.actionType !== ActionType.DELETE)
        .map(item => {
          return { ...item, iotProtocol: info!.iotProtocol };
        }),
    }).then(() =>
      navigate('/device/edge/template', {
        replace: true,
      })
    );
  };

  return (
    <Wrapper className={styles.wrapper} routes={routes}>
      <FormContent title="基础信息">
        <Spin spinning={isInfoLoading}>
          {info && (
            <div className={styles.info}>
              <EdgeDeviceTemplateDisplay row={info} type={info?.iotProtocol} />
            </div>
          )}
        </Spin>
      </FormContent>
      <FormContent title="采集信息" style={{ paddingBottom: 50 }}>
        <Form>
          <div>
            <Space align="start">
              <Form.Item label="版本备注" name="remark" className={styles.remarkItem}>
                <Input.TextArea style={{ width: 310, resize: 'none' }} placeholder="请输入" rows={4} />
              </Form.Item>

              {info && !info.latestVersion && (
                <span className={styles.hint}>
                  <InfoCircleOutlined style={{ color: '#FFD100', marginRight: 10 }} />
                  模板新建成功后，会生成第一个版本，该备注为第一个版本的备注信息
                </span>
              )}
            </Space>
          </div>
        </Form>
        {info && (
          <EdgeDevicePointInfo
            info={info}
            isBatch
            dataSource={dataSource}
            setDataSource={setDataSource}
            type={info.iotProtocol}
            editable
            loading={isLoading}
          />
        )}
      </FormContent>
      <Space className="sticky-footer" style={{ paddingLeft: 40 }}>
        {query.previous === 'true' && (
          <Button
            type="primary"
            onClick={() =>
              navigate(`/device/edge/template/${id}/edit?next=true`, {
                replace: true,
              })
            }
          >
            上一步
          </Button>
        )}
        <Popconfirm
          disabled={!canSubmit}
          okText="继续"
          placement="topRight"
          onConfirm={onSubmit}
          title={
            <>
              <div>确定保存？</div>
              <div style={{ color: '#f5222d' }}>保存后，会生成新的版本。</div>
            </>
          }
        >
          <Button type="primary" disabled={!canSubmit}>
            保存
          </Button>
        </Popconfirm>
        <Button
          onClick={() => {
            Modal.confirm({
              title: '正在编辑数采模板，跳转后会丢失相关信息，请保存后离开。',
              onOk: () => {
                navigate('/device/edge/template', {
                  replace: true,
                });
              },
            });
          }}
        >
          取消
        </Button>
      </Space>
    </Wrapper>
  );
};

export default EdgeTemplatePoint;
