import React, { useEffect, useState, useMemo } from 'react';
import { Form, Select, Row, Col, Space, Button, Modal, Typography } from 'antd';
import { Wrapper, FormContent } from '@maxtropy/components';
import styles from '../index.module.scss';
import { Workspace, getWorkspaceList } from '../../../api/cc-tenant-ql';
import { useNavigate, useParams, Link, useSearchParams } from 'react-router-dom';
import { formLayout, tailLayout } from '../common';
import ShowInput from '../../../components/ShowInput';

export const routes = [
  { name: '租户组织权限和账号' },
  { name: '租户管理' },
  { name: '轻流开户' },
  { name: '选择工作区' },
];

export default function ChooseWorkSpace() {
  const navigate = useNavigate();
  const { mcid } = useParams<{ mcid: string }>();
  const [workspaceForm] = Form.useForm();
  const [workspaceList, setWorkspaceList] = useState<Workspace[]>([]);
  const [searchParams] = useSearchParams();
  const tenantCode = searchParams.get('tenantCode') ?? undefined;
  const tenantName = searchParams.get('tenantName') ?? undefined;
  const [showModal, setShowModal] = useState(false);
  // workspace 主键 id
  const workspaceId = Form.useWatch('workspaceId', workspaceForm);
  const workspaceIdCode = useMemo(() => {
    if (workspaceId) {
      return workspaceList.filter(w => w.id.toString() === workspaceId.toString())[0].spaceIdCode;
    } else {
      return '';
    }
  }, [workspaceId, workspaceList]);

  useEffect(() => {
    getWorkspaceList().then(res => {
      setWorkspaceList(res);
    });
  }, []);

  return (
    <Wrapper routes={routes}>
      <div className={styles.container}>
        <Form layout="vertical" {...formLayout}>
          <FormContent title="租户基础信息">
            <Row>
              <Col span={8}>
                <Form.Item label="租户CODE">
                  <ShowInput value={tenantCode} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="租户名称">
                  <ShowInput value={tenantName} />
                </Form.Item>
              </Col>
            </Row>
          </FormContent>
        </Form>
        <Form form={workspaceForm}>
          <FormContent title="工作区配置">
            <Row>
              <Col span={24}>
                <Form.Item label="选择工作区" required>
                  <Space size="middle">
                    <Form.Item noStyle name="workspaceId" required>
                      <Select
                        options={workspaceList.map(w => ({ label: w?.spaceName, value: w?.id }))}
                        style={{ width: '300px' }}
                        placeholder="请选择工作区"
                      />
                    </Form.Item>
                    <Link to="/tenant/customer/oalite/workspace/manage">工作区管理</Link>
                    <Typography.Text type="secondary">如不知道工作区ID, 请联系轻流运管平台管理员</Typography.Text>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </FormContent>
        </Form>
        <Row className={styles.buttonWrapper}>
          <Col span={7}>
            <Form.Item {...tailLayout}>
              <Space>
                <Button
                  disabled={!workspaceId}
                  type="primary"
                  onClick={() => {
                    if (workspaceId) {
                      setShowModal(true);
                    }
                  }}
                >
                  下一步
                </Button>
                <Button
                  onClick={() => {
                    navigate('/tenant/customer/oalite');
                  }}
                >
                  关闭
                </Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </div>

      <Modal
        title="配置工作区"
        open={showModal}
        onOk={() => {
          navigate(
            `/tenant/customer/oalite/create/${mcid}/open?workspaceId=${workspaceId}&workspaceIdCode=${workspaceIdCode}`
          );
          setShowModal(false);
        }}
        onCancel={() => {
          setShowModal(false);
        }}
      >
        <Space direction="vertical">
          <div>
            您配置的工作区是:&nbsp;
            {workspaceList.filter(w => workspaceId === w?.id)[0]?.spaceName}
          </div>
          <div>请确认工作区是否正确的，确认后工作区将不能修改，并产生相应的费用。</div>
        </Space>
      </Modal>
    </Wrapper>
  );
}
