import React, { useImperativeHandle, useState } from 'react';
import { Form, FormInstance } from 'antd';
import { DriveType } from '../../../types';
import { MockingbirdDeviceProps } from '../../DataAcquisitionForm/types';
import CustomFormItem from './CustomFormItem';
import formItemsByDriveType from './Form';

const layout = {
  labelCol: { style: { width: 140 } },
  wrapperCol: { span: 12 },
};

interface DriveTypeFormProps {
  driveType: DriveType;
  disabled: boolean;
  updateCheckedItems?: (value: string[]) => void;
}

export const driveTypeLockItems = ['serialPort', 'stationNum', 'ip', 'address', 'leadByte', 'port'];

export interface DriveTypeFormRef {
  form: FormInstance<MockingbirdDeviceProps> | null;
  resetCheckbox?: () => void;
}

const DriveTypeForm = React.forwardRef(
  ({ driveType, disabled, updateCheckedItems }: DriveTypeFormProps, ref: React.Ref<DriveTypeFormRef>) => {
    const [form] = Form.useForm();
    const formRef = React.useRef(null);

    const [checkedNames, setCheckedNames] = useState<string[]>([]);
    const [clear, setClear] = useState<boolean>();

    useImperativeHandle(ref, () => ({
      form: formRef.current,
      resetCheckbox,
    }));

    const resetCheckbox = () => {
      setClear(true);
      updateCheckedItems?.([]);
    };

    const onCheck = (checked: boolean, name: string) => {
      form.resetFields([name]);
      const copyCheckedNames = [...checkedNames];
      const index = checkedNames.indexOf(name);
      if (checked && index === -1) {
        copyCheckedNames.push(name);
      } else if (!checked && index > -1) {
        copyCheckedNames.splice(index, 1);
      }
      setCheckedNames(copyCheckedNames);
      updateCheckedItems?.(copyCheckedNames);
    };

    const items = formItemsByDriveType[driveType];
    return (
      <Form style={{ width: '100%' }} {...layout} form={form} ref={formRef}>
        {(items || []).map((item: any) => (
          <Form.Item label={item.label} key={item.label} required>
            <CustomFormItem
              key={item.label}
              {...item}
              disabled={disabled}
              onCheck={onCheck}
              clear={clear}
              setClear={() => setClear(false)}
            />
          </Form.Item>
        ))}
      </Form>
    );
  }
);

export default DriveTypeForm;
