import { Filter, Paging, useSearchParams } from '@maxtropy/components';
import { Col, Form, Input, message, Modal, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiFormTemplatePageGet } from '../../../ytt/types/sprint64/formTemplate/page';
import { isNil } from 'lodash-es';
import { FormTemplateItem } from '../../../api/mockForm';
import { FormTemplateStatus } from '../../../api/mockFormTemplate';

interface Iprops {
  onCancel?: () => void;
  onConfirm?: () => void;
}

type SearchParams = {
  name?: string;
};

const columns = [
  {
    title: '模板名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '模板说明',
    dataIndex: 'description',
    key: 'description',
  },
];

const TemplateSelectModal = (props: Iprops) => {
  const { onCancel, onConfirm } = props;
  const [form] = Form.useForm();
  const pagingInfo = useSearchParams<SearchParams>();
  const { searchParams, finish, reset, setTotalCount } = pagingInfo;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<FormTemplateItem[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [messageApi, messageContextHolder] = message.useMessage();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    apiFormTemplatePageGet({
      ...searchParams,
      status: String(FormTemplateStatus.ENABLE),
      page: String(searchParams.pageOffset),
      size: String(searchParams.pageSize),
    })
      .then(res => {
        setDataSource(res.list ?? []);
        setTotalCount(res.total ?? 0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams]);

  const onFinish = (val: SearchParams) => {
    finish({ ...val });
  };
  // Filter组件的onReset重置事件
  const onReset = () => {
    reset();
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    if (newSelectedRowKeys.length === 0) {
      setSelectedRowKeys([]);
    } else {
      const checkedKey = newSelectedRowKeys.pop();
      if (isNil(checkedKey)) {
        setSelectedRowKeys([]);
      } else {
        setSelectedRowKeys([checkedKey]);
      }
    }
  };

  const createFormByTemplate = () => {
    if (selectedRowKeys.length === 0) {
      messageApi.warning('请先选择模板');
      return;
    }
    const templateId = selectedRowKeys[0];
    navigate(`/data-tools/business/formcreate/${templateId}`);
    onConfirm?.();
  };

  const rowSelection = { selectedRowKeys, onChange: onSelectChange, hideSelectAll: true };

  return (
    <Modal
      title="选择模板"
      open
      width={700}
      closable={false}
      onCancel={() => onCancel?.()}
      onOk={() => createFormByTemplate()}
    >
      <Filter form={form} onFinish={onFinish} onReset={onReset}>
        <Col span={12}>
          <Form.Item name="nameOrDescription" label="模板名称/说明">
            <Input placeholder="请输入模板名称与说明搜索" />
          </Form.Item>
        </Col>
      </Filter>
      <Table
        loading={isLoading}
        sticky
        rowSelection={rowSelection}
        scroll={{ y: 300 }}
        rowKey={record => `${record.id}`}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
      <Paging pagingInfo={pagingInfo} />
      {messageContextHolder}
    </Modal>
  );
};

export default TemplateSelectModal;
