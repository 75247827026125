import { FC } from 'react';
import { Form, Input, Row, Col, InputNumber } from 'antd';
import { FactorPropertyEnum } from '../emissionProperty';

const FormItem = Form.Item;

interface Props {}
const Gwp: FC<Props> = () => {
  return (
    <Row gutter={16}>
      <Col span={8}>
        <FormItem
          label="GWP值"
          name={['factorDetails', `${FactorPropertyEnum.GWPValue}_1`]}
          rules={[{ required: true }, { max: 50, message: '长度不能大于50个字符' }]}
        >
          <Input placeholder="请输入" />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem label="分子式" name={['factorDetails', `${FactorPropertyEnum.GWPMolecularFormula}_2`]}>
          <InputNumber placeholder="请输入数值" style={{ width: '100%' }} min={0} />
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem label="分子量" name={['factorDetails', `${FactorPropertyEnum.GWPMolecularWeight}_3`]}>
          <InputNumber placeholder="请输入数值" style={{ width: '100%' }} min={0} />
        </FormItem>
      </Col>
    </Row>
  );
};

export default Gwp;
