import { useCallback, useState } from 'react';

export type UpdateType = [number, () => void];

const useUpdate = (): UpdateType => {
  const [state, setState] = useState<number>(0);

  const update = useCallback(() => setState((num: number): number => num + 1), []);

  return [state, update];
};

export default useUpdate;
