import { Cascader, Form, FormInstance, Input, InputNumber, Radio, RadioChangeEvent, Select } from 'antd';
import _ from 'lodash';
import { SingleValueType } from 'rc-cascader/lib/Cascader';
import { FC, useEffect, useState } from 'react';
import { AppFunctionPermissionVo, getAppPermissionTree } from '../../../../../api/cc-app';
import { getAllIntegratedAppList, IntegratedAppResponse } from '../../../../../api/cc-integrated-app';
import {
  getNavigationModuleOriginsList,
  getPermissionApplicationList,
  getPermissionIntegratedAppList,
  NavigationModuleOriginsResponse,
  NavigationPermissionApplicationResponse,
  NavigationPermissionIntegratedAppResponse,
  TenantMenuJumpType,
  tenantMenuJumpTypeDisplay,
  TenantMenuLevel,
  tenantMenuLevelDisplay,
  TenantMenuNavigationResponse,
  TenantMenuOpenType,
  tenantMenuOpenTypeDisplay,
  TenantMenuPermissionType,
  tenantMenuPermissionTypeDisplay,
} from '../../../../../api/cc-navigation';

interface EditModalProps {
  form: FormInstance<any>;
  onFinish: (values: TenantMenuNavigationResponse) => void;
  current?: TenantMenuNavigationResponse;
  level?: TenantMenuLevel;
  isAdd?: boolean;
  editId?: string;
  disabled?: boolean;
}

const { TextArea } = Input;

const EditFormModal: FC<EditModalProps> = props => {
  const { form, onFinish, current, level, isAdd, disabled } = props;

  const [navigationModuleOriginsList, setNavigationModuleOriginsList] = useState<NavigationModuleOriginsResponse[]>([]); // 模块列表
  const [allIntegratedAppList, setAllIntegratedAppList] = useState<IntegratedAppResponse[]>([]); // 集成应用列表
  const [integratedAppId, setIntegratedAppId] = useState<number>(); // 第三方权限选择集成应用后启用权限配置
  const [options, setOptions] = useState<AppFunctionPermissionVo[]>([]); // cascader级联options
  // const [permissionCode, setPermissionCode] = useState<string[]>(); // 权限配置code
  const [permissionApplicationList, setPermissionApplicationList] = useState<NavigationPermissionApplicationResponse[]>(
    []
  ); // 获取租户端权限导航相关信息
  const [permissionIntegratedAppList, setPermissionIntegratedAppList] = useState<
    NavigationPermissionIntegratedAppResponse[]
  >([]);
  const [integratedAppPermissionDisabled, setIntegratedAppPermissionDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (current) {
      if (current.permissionType === TenantMenuPermissionType.INTEGRATEDAPP) {
        getPermissionIntegratedAppList(String(current.integratedAppId)).then(res => {
          let newArr = arrToTree(res);
          let finalArr = filter(newArr);
          setPermissionIntegratedAppList(finalArr);
          let arr = [];
          let find = res.find(item => item.code === current.integratedAppPermissionCode);
          arr.push(find!.code);
          let findParent = res.find(item => item.id === find!.parentId);
          arr.push(findParent!.code);
          let findRoot = res.find(item => item.id === findParent!.parentId);
          arr.push(findRoot!.code);
          let arrr = arr.reverse(); // NOSONAR
          form.setFieldsValue({
            integratedAppPermissionCode: arrr,
          });
        });
      } else {
        if (current.permissionCode) {
          getPermissionApplicationList().then(res => {
            // 获取租户端权限导航相关信息
            setPermissionApplicationList(res);
            let arr = [];
            let find = res.find(item => item.code === current.permissionCode);
            arr.push(find!.code);
            let findParent = res.find(item => item.id === find!.parentId);
            arr.push(findParent!.code);
            let findRoot = res.find(item => item.id === findParent!.parentId);
            arr.push(findRoot!.code);
            let arrr = arr.reverse(); // NOSONAR
            form.setFieldsValue({
              permissionCode: arrr,
            });
          });
        }
      }
    }
    // 如果是有id，不是新增，就是编辑
    if (current && !isAdd) {
      form.setFieldsValue({
        name: current.name,
        level: level,
        weighting: current.weighting,
        jumpType: current.jumpType,
        openType: current.openType,
        path: current.path,
        moduleOriginId: current.moduleOriginId,
        permissionType: current.permissionType,
        integratedAppId: current.integratedAppId,
        // permissionCode: current.permissionCode
      });
    } else if (current && isAdd) {
      // 有id，有新增，新增下一级
      getPermissionApplicationList().then(res => {
        // 获取租户端权限导航相关信息
        setPermissionApplicationList(res);
      });
      form.setFieldsValue({
        level: level,
        weighting: current.weighting + 10,
      });
    } else {
      // 只有新增，那就是新增一级导航
      form.setFieldsValue({
        level: level,
        weighting: 10,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current?.id]);

  useEffect(() => {
    if (level! > 2) {
      getAppPermissionTree().then(res => {
        let result = filter(res);
        setOptions(result);
      });
      getNavigationModuleOriginsList().then(setNavigationModuleOriginsList); // 获取模块域名信息
      getAllIntegratedAppList().then(setAllIntegratedAppList); // 获取所有的集成应用信息
    }
  }, [level]);

  useEffect(() => {
    if (integratedAppId) {
      getPermissionIntegratedAppList(String(integratedAppId)).then(res => {
        let newArr = arrToTree(res);
        let finalArr = filter(newArr);
        setPermissionIntegratedAppList(finalArr);
      }); // 获取第三方权限导航相关信息
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integratedAppId]);

  // 只选择到前三级
  const filter = (arr: any[]) => {
    let result = _.cloneDeepWith(arr);
    for (let firstLayer of result) {
      if (firstLayer.children) {
        for (let secondLayer of firstLayer.children) {
          if (secondLayer.children) {
            for (let thirdLayer of secondLayer.children) {
              delete thirdLayer.children;
            }
          }
        }
      }
    }
    return result;
  };

  const onPermissionCodeChange = (val: SingleValueType) => {
    let find = permissionApplicationList.find(item => item.code === val[2]);
    if (find) {
      form.setFieldsValue({
        moduleOriginId: find.originId,
        path: find.path,
      });
    }
  };

  // 数组转树
  const arrToTree = (arr: NavigationPermissionIntegratedAppResponse[], parentId = 0) => {
    let newArr: {
      children: any[];
      id: number;
      code: string;
      parentId: number;
      rootId: number;
      name: string;
      level: number;
      description?: string;
      pic?: string;
      IntegratedAppId?: number;
      moduleOriginId?: number;
      path?: string;
    }[] = [];
    arr.forEach(item => {
      if (item.parentId === parentId) {
        newArr.push({
          ...item,
          children: arrToTree(arr, item.id),
        });
      }
    });
    return newArr;
  };

  // 当页面跳转方式改变的时候重新渲染下面的权限配置，模块，相对路径
  const onJumpTypeChange = (e: RadioChangeEvent) => {
    form.setFieldsValue({
      permissionCode: undefined,
      moduleOriginId: undefined,
      path: undefined,
      integratedAppPermissionCode: undefined,
      permissionType: undefined,
      integratedAppId: undefined,
    });
  };

  // 当权限类型改变的时候，确认是平台权限还是第三方权限，然后进行查询渲染cascader
  const onPermissionTypeChange = (e: RadioChangeEvent) => {
    form.setFieldsValue({
      permissionCode: undefined,
      integratedAppPermissionCode: undefined,
      integratedAppId: undefined,
    });
    setIntegratedAppPermissionDisabled(true);
  };

  return (
    <Form
      style={{ width: 450 }}
      form={form}
      labelCol={{ flex: '110px' }}
      labelAlign="right"
      onFinish={onFinish}
      initialValues={{
        permissionType: TenantMenuPermissionType.PLATFORM,
      }}
    >
      {level === TenantMenuLevel.ONE || level === TenantMenuLevel.TWO ? (
        <>
          <Form.Item
            name="name"
            label="导航名称"
            rules={[
              { required: true, message: '请输入方案名称', whitespace: true, type: 'string' },
              { max: 20, message: '最多输入20个字' },
            ]}
          >
            <Input disabled={disabled} maxLength={20} placeholder="请输入" />
          </Form.Item>
          <Form.Item name="level" label="导航级别">
            <Form.Item noStyle dependencies={['level']}>
              {({ getFieldValue }) => {
                if (getFieldValue('level') === TenantMenuLevel.ONE) {
                  return <div>{tenantMenuLevelDisplay[TenantMenuLevel.ONE]}</div>;
                } else if (getFieldValue('level') === TenantMenuLevel.TWO) {
                  return <div>{tenantMenuLevelDisplay[TenantMenuLevel.TWO]}</div>;
                } else {
                  return <div>{tenantMenuLevelDisplay[TenantMenuLevel.THREE]}</div>;
                }
              }}
            </Form.Item>
          </Form.Item>
          <Form.Item name="weighting" label="排序权重" rules={[{ required: true, message: '请输入排序权重' }]}>
            <InputNumber disabled={disabled} min={0} max={1000} step={10} placeholder="请输入" />
          </Form.Item>{' '}
        </>
      ) : (
        <>
          <Form.Item
            name="name"
            label="导航名称"
            rules={[
              { required: true, message: '请输入方案名称', whitespace: true, type: 'string' },
              { max: 20, message: '最多输入20个字' },
            ]}
          >
            <Input disabled={disabled} maxLength={20} placeholder="请输入" />
          </Form.Item>
          <Form.Item name="level" label="导航级别">
            <Form.Item noStyle dependencies={['level']}>
              {({ getFieldValue }) => {
                if (getFieldValue('level') === TenantMenuLevel.ONE) {
                  return <div>{tenantMenuLevelDisplay[TenantMenuLevel.ONE]}</div>;
                } else if (getFieldValue('level') === TenantMenuLevel.TWO) {
                  return <div>{tenantMenuLevelDisplay[TenantMenuLevel.TWO]}</div>;
                } else {
                  return <div>{tenantMenuLevelDisplay[TenantMenuLevel.THREE]}</div>;
                }
              }}
            </Form.Item>
          </Form.Item>
          <Form.Item name="weighting" label="排序权重" rules={[{ required: true, message: '请输入排序权重' }]}>
            <InputNumber disabled={disabled} min={0} max={1000} step={10} placeholder="请输入" />
          </Form.Item>
          <Form.Item name="openType" label="打开方式" rules={[{ required: true, message: '请选择打开方式' }]}>
            <Radio.Group disabled={disabled}>
              <Radio value={TenantMenuOpenType.CurrentPage}>
                {tenantMenuOpenTypeDisplay[TenantMenuOpenType.CurrentPage]}
              </Radio>
              <Radio value={TenantMenuOpenType.NewPage}>{tenantMenuOpenTypeDisplay[TenantMenuOpenType.NewPage]}</Radio>
              <Radio value={TenantMenuOpenType.CurrentPageForce}>
                {tenantMenuOpenTypeDisplay[TenantMenuOpenType.CurrentPageForce]}
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name="jumpType" label="页面跳转方式" rules={[{ required: true, message: '请选择页面跳转方式' }]}>
            <Radio.Group onChange={val => onJumpTypeChange(val)} disabled={disabled}>
              {/* <Radio value={TenantMenuJumpType.WITHINSYSTEM}>{tenantMenuJumpTypeDisplay[TenantMenuJumpType.WITHINSYSTEM]}</Radio>
                <Radio value={TenantMenuJumpType.CUSTOMIZATIONWITHINSYSTEM}>{tenantMenuJumpTypeDisplay[TenantMenuJumpType.CUSTOMIZATIONWITHINSYSTEM]}</Radio> */}
              <Radio value={TenantMenuJumpType.OUTSIDESYSTEM}>
                {tenantMenuJumpTypeDisplay[TenantMenuJumpType.OUTSIDESYSTEM]}
              </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item noStyle dependencies={['jumpType']}>
            {({ getFieldValue }) => {
              if (getFieldValue('jumpType') === TenantMenuJumpType.WITHINSYSTEM) {
                return (
                  <>
                    <Form.Item
                      name="permissionCode"
                      label="权限配置"
                      rules={[{ required: true, message: '请选择权限配置' }]}
                    >
                      <Cascader
                        disabled={disabled}
                        allowClear={false}
                        style={{ width: '100%' }}
                        options={options}
                        fieldNames={{ label: 'name', value: 'code' }}
                        placeholder="请选择权限配置"
                        onChange={val => onPermissionCodeChange(val)}
                        // multiple
                      />
                    </Form.Item>
                    <Form.Item name="moduleOriginId" label="模块" rules={[{ required: true, message: '请选择模块' }]}>
                      <Select placeholder="请选择模块" disabled>
                        {navigationModuleOriginsList.map(i => (
                          <Select.Option key={i.id} value={i.id}>{`${i.name}`}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name="path" label="相对路径" rules={[{ required: true, message: '请输入相对路径' }]}>
                      <Input disabled placeholder="请输入相对路径" />
                    </Form.Item>
                  </>
                );
              } else if (getFieldValue('jumpType') === TenantMenuJumpType.CUSTOMIZATIONWITHINSYSTEM) {
                return (
                  <>
                    <Form.Item
                      name="permissionCode"
                      label="权限配置"
                      rules={[{ required: true, message: '请选择权限配置' }]}
                    >
                      <Cascader
                        disabled={disabled}
                        allowClear={false}
                        style={{ width: '100%' }}
                        options={options}
                        fieldNames={{ label: 'name', value: 'code' }}
                        placeholder="请选择权限配置"
                        onChange={val => onPermissionCodeChange(val)}
                      />
                    </Form.Item>
                    <Form.Item name="moduleOriginId" label="模块" rules={[{ required: true, message: '请选择模块' }]}>
                      <Select placeholder="请选择模块" disabled={disabled}>
                        {navigationModuleOriginsList.map(i => (
                          <Select.Option key={i.id} value={i.id}>{`${i.name}`}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name="path" label="相对路径" rules={[{ required: true, message: '请输入相对路径' }]}>
                      <Input disabled={disabled} placeholder="请输入相对路径" />
                    </Form.Item>
                  </>
                );
              } else if (getFieldValue('jumpType') === TenantMenuJumpType.OUTSIDESYSTEM) {
                return (
                  <>
                    <Form.Item name="path" label="链接地址" rules={[{ required: true, message: '请输入链接地址' }]}>
                      <TextArea />
                    </Form.Item>
                    <Form.Item
                      name="permissionType"
                      label="权限类型"
                      rules={[{ required: true, message: '请选择权限类型' }]}
                    >
                      <Radio.Group disabled={disabled} onChange={e => onPermissionTypeChange(e)}>
                        <Radio value={TenantMenuPermissionType.PLATFORM}>
                          {tenantMenuPermissionTypeDisplay[TenantMenuPermissionType.PLATFORM]}
                        </Radio>
                        <Radio value={TenantMenuPermissionType.INTEGRATEDAPP}>
                          {tenantMenuPermissionTypeDisplay[TenantMenuPermissionType.INTEGRATEDAPP]}
                        </Radio>
                        <Radio value={TenantMenuPermissionType.NOPERMISSION}>
                          {tenantMenuPermissionTypeDisplay[TenantMenuPermissionType.NOPERMISSION]}
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </>
                );
              }
            }}
          </Form.Item>

          <Form.Item noStyle dependencies={['permissionType', 'jumpType']}>
            {({ getFieldValue }) => {
              if (
                getFieldValue('permissionType') === TenantMenuPermissionType.PLATFORM &&
                getFieldValue('jumpType') === TenantMenuJumpType.OUTSIDESYSTEM
              ) {
                return (
                  <>
                    <Form.Item
                      name="permissionCode"
                      label="权限配置"
                      rules={[{ required: true, message: '请选择权限配置' }]}
                    >
                      <Cascader
                        disabled={disabled}
                        allowClear={false}
                        style={{ width: '100%' }}
                        options={options}
                        fieldNames={{ label: 'name', value: 'code' }}
                        placeholder="请选择"
                      />
                    </Form.Item>
                  </>
                );
              } else if (
                getFieldValue('permissionType') === TenantMenuPermissionType.INTEGRATEDAPP &&
                getFieldValue('jumpType') === TenantMenuJumpType.OUTSIDESYSTEM
              ) {
                return (
                  <>
                    <Form.Item
                      name="integratedAppId"
                      label="集成应用"
                      rules={[{ required: true, message: '请选择集成应用' }]}
                    >
                      <Select
                        placeholder="请选择集成应用"
                        disabled={disabled}
                        onChange={val => {
                          setIntegratedAppId(val);
                          form.setFieldsValue({ integratedAppPermissionCode: undefined });
                          setIntegratedAppPermissionDisabled(false);
                        }}
                      >
                        {allIntegratedAppList.map(i => (
                          <Select.Option key={i.id} value={i.id}>{`${i.name}`}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="integratedAppPermissionCode"
                      label="权限配置"
                      rules={[{ required: true, message: '请选择权限配置' }]}
                    >
                      <Cascader
                        disabled={!(!!integratedAppId || !!current?.integratedAppId) || integratedAppPermissionDisabled}
                        allowClear={false}
                        style={{ width: '100%' }}
                        options={permissionIntegratedAppList}
                        fieldNames={{ label: 'name', value: 'code' }}
                        placeholder="请选择"
                      />
                    </Form.Item>
                  </>
                );
              } else if (
                getFieldValue('permissionType') === TenantMenuPermissionType.NOPERMISSION &&
                getFieldValue('jumpType') === TenantMenuJumpType.OUTSIDESYSTEM
              ) {
                return <></>;
              }
            }}
          </Form.Item>
        </>
      )}
    </Form>
  );
};

export default EditFormModal;
