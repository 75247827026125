import { Button, Input, Popconfirm, Select, Form, App, Row, Col, Space } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { CSSProperties, FC, useState, forwardRef, useImperativeHandle, useMemo, useEffect } from 'react';
import { isNil, uniqBy } from 'lodash-es';
import { v4 } from 'uuid';
import DataLinkSetModal from './DataLinkSetModal';
import DragSortTable from '../components/DragSortTable';
import styles from '../index.module.scss';
import { FormInstance } from 'antd/es';
import { useReadonly } from '../hooks';
import { V2CarbonEmissionProcessDetailPostResponse } from '@maxtropy/device-mgmt-apis-v2';
import { Key } from '@maxtropy/components';
import {
  apiV2CarbonEmissionProcessGetFormFieldBusinessTypeListPost,
  V2CarbonEmissionProcessGetFormFieldBusinessTypeListPostResponse,
} from '@maxtropy/tody-mgmt-apis-v2';
import { editCarbonEmissionProcessFormField } from '@/api/carbonEmission';

export type Fields = NonNullable<V2CarbonEmissionProcessDetailPostResponse['fields']>;
export type Field = Fields[number];
export interface FieldWithKey extends Field {
  key: string;
  isEdit?: boolean;
}

enum FieldTypeValue {
  DATA_LINK = 1,
  NUMBER = 2,
  OUTPUT = 3,
}

const fieldTypeList = [
  { label: '数据关联', value: FieldTypeValue.DATA_LINK },
  { label: '数值', value: FieldTypeValue.NUMBER },
  { label: '输出', value: FieldTypeValue.OUTPUT },
];

export interface BasicInfoTableRef {
  getSubmitData: () => Fields | undefined;
}

interface BasicInfoTableProps {
  form: FormInstance;
  useFlag: boolean;
  originData: FieldWithKey[];
  setOriginData: (data: FieldWithKey[]) => void;
}

const BasicInfoTable = forwardRef<BasicInfoTableRef, BasicInfoTableProps>((props, ref) => {
  const { form, useFlag, originData, setOriginData } = props;
  const { message } = App.useApp();
  const readonly = useReadonly();
  const [dataLinkSetModalOpen, setDataLinkSetModalOpen] = useState(false);
  const [dataLinkSetModalIndex, setDataLinkSetModalIndex] = useState<number>(0);

  const data: FieldWithKey[] = Form.useWatch('fields', { form, preserve: true });
  const [editId, setEditId] = useState<Key>();
  const [businessTypeData, setBusinessTypeData] =
    useState<V2CarbonEmissionProcessGetFormFieldBusinessTypeListPostResponse['list']>();

  useEffect(() => {
    apiV2CarbonEmissionProcessGetFormFieldBusinessTypeListPost({}).then(res => {
      setBusinessTypeData(res.list ?? []);
    });
  }, []);

  const onAddRow = () => {
    const newData = data ? [...data] : [];
    newData.push({
      key: v4(),
      fieldOrder: newData.length + 1,
      fieldName: '',
      fieldType: FieldTypeValue.DATA_LINK,
      isEdit: true,
    });
    form.setFieldValue('fields', newData);
  };

  const validateWithoutRule = (): boolean => {
    if (!data || !data.length) {
      message.error('请添加表单');
      return false;
    }
    if (data.some(item => !item.fieldName)) {
      message.error('请完善字段名称');
      return false;
    }
    if (data.some(item => item!.fieldName!.length! < 2 || item!.fieldName!.length! > 50)) {
      message.error('请保证字段名称长度为2-50个字');
      return false;
    }
    if (uniqBy(data, 'fieldName').length !== data.length) {
      message.error('字段名称不能重复');
      return false;
    }
    return true;
  };

  const validate = (): boolean => {
    if (!validateWithoutRule()) return false;
    if (data!.some(item => item.fieldType === FieldTypeValue.DATA_LINK && !item.fieldPropertyType)) {
      message.error('请完善关联设置');
      return false;
    }
    if (
      data!.some(
        item => item.fieldType === FieldTypeValue.NUMBER && !validateNumericalExpression(item.numericalExpression ?? '')
      )
    ) {
      message.error('数值表达式不符合区间规则');
      return false;
    }
    if (
      data!.some(
        item =>
          (item.fieldType === FieldTypeValue.OUTPUT || item.fieldType === FieldTypeValue.NUMBER) &&
          isNil(item.decimalDigit)
      )
    ) {
      message.error('请完善小数位数');
      return false;
    }
    return true;
  };

  const getSubmitData = (): Fields | undefined => {
    if (!validate()) return undefined;
    return data;
  };

  useImperativeHandle(ref, () => ({
    getSubmitData,
  }));

  const otherColumns: ColumnsType<FieldWithKey> = [
    {
      title: '序号',
      dataIndex: 'fieldOrder',
      width: 80,
      align: 'center',
    },
    {
      title: '字段名称',
      dataIndex: 'fieldName',
      render: (text, record, index) => (
        <Form.Item
          className={styles.mb0}
          name={['fields', index, 'fieldName']}
          rules={[
            { required: true, message: '请输入字段名称' },
            { min: 2, max: 50, message: '2-50字' },
            {
              validator: (rule, value) => {
                if (!value) return Promise.resolve();
                let flag = data?.some((item, j) => {
                  return item.fieldName === value && j !== index;
                });
                if (flag) return Promise.reject('字段名称不能重复');
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input disabled={readonly || (useFlag && !record.isEdit)} placeholder="请输入字段名称，2-50个字" />
        </Form.Item>
      ),
    },
    {
      title: '类型',
      dataIndex: 'fieldType',
      width: 160,
      render: (text, record, index) => (
        <Form.Item name={['fields', index, 'fieldType']} className={styles.mb0}>
          <Select disabled={readonly || (useFlag && !record.isEdit)}>
            {fieldTypeList.map(item => (
              <Select.Option value={item.value} key={item.value}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ),
    },
    {
      title: '规则/格式',
      dataIndex: 'rule',
      width: 350,
      render: (text, record, index) => {
        if (record.fieldType === FieldTypeValue.DATA_LINK)
          return (
            <Button
              style={{ width: '100%' }}
              ghost={!record.fieldPropertyType}
              type={!record.fieldPropertyType ? 'primary' : undefined}
              onClick={() => {
                if (!validateWithoutRule()) return;
                setDataLinkSetModalOpen(true);
                setDataLinkSetModalIndex(index);
              }}
              disabled={readonly || (useFlag && !record.isEdit)}
            >
              {!record.fieldPropertyType ? '设置' : '编辑'}关联设置
            </Button>
          );
        else if (record.fieldType === FieldTypeValue.NUMBER)
          return (
            <div
              style={{
                width: '100%',
                display: 'flex',
                gap: 9,
              }}
            >
              <Form.Item
                name={['fields', index, 'decimalDigit']}
                rules={[{ required: true, message: '请选择小数位数' }]}
                className={styles.mb0}
              >
                <DecimalDigitSelect disabled={readonly || (useFlag && !record.isEdit)}></DecimalDigitSelect>
              </Form.Item>

              <Form.Item
                name={['fields', index, 'numericalExpression']}
                rules={[
                  {
                    required: true,
                    message: '请输入数值表达式',
                  },
                  {
                    validator: (rule, value) => {
                      if (!value) return Promise.resolve();
                      let flag = validateNumericalExpression(value ?? '');
                      if (!flag) return Promise.reject('数值表达式不符合区间规则');
                      return Promise.resolve();
                    },
                  },
                ]}
                className={styles.mb0}
                style={{ flex: 1 }}
              >
                <Input
                  placeholder="请输入区间，如(1,100]"
                  disabled={readonly || (useFlag && !record.isEdit)}
                  onChange={e => {
                    const newData = [...data!];
                    // 处理中文字符
                    newData[index].numericalExpression = e.target.value
                      .replace('，', ',')
                      .replace('（', '(')
                      .replace('）', ')')
                      .replace('【', '[')
                      .replace('】', ']');
                    form.setFieldValue('fields', newData);
                  }}
                ></Input>
              </Form.Item>
            </div>
          );
        else
          return (
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  name={['fields', index, 'decimalDigit']}
                  rules={[{ required: true, message: '请完善小数位数' }]}
                  className={styles.mb0}
                >
                  <DecimalDigitSelect
                    disabled={readonly || (useFlag && !record.isEdit)}
                    style={{ width: '100%' }}
                  ></DecimalDigitSelect>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  initialValue={1}
                  name={['fields', index, 'businessType']}
                  rules={[{ required: true, message: '请选择业务类型' }]}
                  className={styles.mb0}
                >
                  <Select
                    disabled={readonly || (useFlag && !record.isEdit)}
                    options={businessTypeData?.map(item => ({ label: item.name, value: item.type }))}
                  />
                </Form.Item>
              </Col>
            </Row>
          );
      },
    },
  ];

  const actionColumns: ColumnsType<FieldWithKey> = [
    {
      title: '操作',
      dataIndex: 'action',
      width: 140,
      align: 'center',
      render: (text, record, index) => (
        <Popconfirm
          title="是否移除此字段？"
          onConfirm={() => {
            const newData = form.getFieldValue('fields').filter((item: FieldWithKey) => item.key !== record.key);
            newData.forEach((item: Field, index: number) => {
              item.fieldOrder = index + 1;
            });
            form.setFieldValue('fields', newData);
          }}
        >
          <Button type="link">移除</Button>
        </Popconfirm>
      ),
    },
  ];

  const toggleEdit = (data: FieldWithKey[], record: FieldWithKey) => {
    return (data ?? []).map((item: FieldWithKey) => {
      if (item.key === record.key) {
        return {
          ...item,
          isEdit: !item.isEdit,
        };
      }
      return item;
    });
  };

  const hasEditedColumns: ColumnsType<FieldWithKey> = [
    {
      title: '操作',
      dataIndex: 'action',
      width: 140,
      align: 'center',
      render: (text, record, index) => {
        return record.isEdit ? (
          <Space>
            <Button
              type="link"
              onClick={() => {
                form.validateFields().then(res => {
                  const body = data.filter(item => item.id === record.id)[0];
                  if (body.fieldType === FieldTypeValue.DATA_LINK && !body.fieldPropertyType) {
                    message.error('请完善关联设置');
                    return Promise.resolve();
                  }
                  editCarbonEmissionProcessFormField(body).then(() => {
                    const newData = toggleEdit(data, record);
                    form.setFieldValue('fields', newData);
                    setOriginData(newData);
                    setEditId(undefined);
                  });
                });
              }}
            >
              保存
            </Button>
            <Button
              type="link"
              onClick={() => {
                const selectedItem = originData.find(i => i.key === record.key);
                const newData = data.map(i => {
                  if (i.key === selectedItem?.key) {
                    return selectedItem;
                  }
                  return i;
                });
                form.setFieldsValue({ fields: newData });
                setEditId(undefined);
              }}
            >
              取消
            </Button>
          </Space>
        ) : (
          <Button
            type="link"
            onClick={() => {
              const newData = toggleEdit(data, record);
              form.setFieldValue('fields', newData);
              setEditId(record.id);
            }}
            disabled={editId !== undefined && editId !== record.id}
          >
            编辑
          </Button>
        );
      },
    },
  ];

  const columns = readonly
    ? otherColumns
    : useFlag
    ? otherColumns.concat(hasEditedColumns)
    : otherColumns.concat(actionColumns);

  return (
    <>
      <DragSortTable
        rowKey="key"
        columns={columns}
        dataSource={data}
        setDataSource={value => {
          const newData = [...value];
          newData.forEach((item, index) => {
            item.fieldOrder = index + 1;
          });
          form.setFieldValue('fields', newData);
        }}
        pagination={false}
      ></DragSortTable>
      {!readonly &&
        (useFlag ? null : (
          <Button type="dashed" style={{ marginTop: 12, width: '100%' }} onClick={onAddRow}>
            新增一行
          </Button>
        ))}
      <DataLinkSetModal
        open={dataLinkSetModalOpen}
        setOpen={setDataLinkSetModalOpen}
        basicInfoTableData={data}
        basicInfoTableIndex={dataLinkSetModalIndex}
        confirm={dataLink => {
          const newRowData = { ...data![dataLinkSetModalIndex], ...dataLink };
          const newData = [...data!];
          newData[dataLinkSetModalIndex] = newRowData;
          form.setFieldValue('fields', newData);
        }}
      ></DataLinkSetModal>
    </>
  );
});
interface DecimalDigitSelectProps {
  value?: number | undefined;
  onChange?: (value: number) => void;
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
}

const DecimalDigitSelect: FC<DecimalDigitSelectProps> = props => {
  const { value, onChange, style, className, disabled } = props;

  let list = [];
  for (let i = 0; i <= 8; i++) {
    list.push(i);
  }

  return (
    <Select
      placeholder="小数位数"
      style={{ width: 100, ...style }}
      className={className}
      value={value}
      onChange={onChange}
      disabled={disabled}
    >
      {list.map(item => (
        <Select.Option value={item} key={item}>
          {item === 0 ? '整数' : `${item}位小数`}
        </Select.Option>
      ))}
    </Select>
  );
};

function validateNumericalExpression(numericalExpression: string): boolean {
  // 正则表达式解释:
  // ^: 开始
  // [\[\(]: 匹配 "[" 或 "("
  // \s*: 匹配 0 或多个空白符
  // -?\d+(\.\d+)?: 匹配一个可能带有负号的整数或小数
  // \s*,\s*: 匹配一个逗号，逗号的前后可能有0或多个空白符
  // -?\d+(\.\d+)?: 匹配另一个可能带有负号的整数或小数
  // [\]\)]: 匹配 "]" 或 ")"
  // $: 结束
  const regex = /^[\[\(]\s*-?\d+(\.\d+)?\s*,\s*-?\d+(\.\d+)?\s*[\]\)]$/;
  return regex.test(numericalExpression);
}

export default BasicInfoTable;
