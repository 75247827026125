import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Form, Row, Col, InputNumber } from 'antd';
import { FormContent } from '@maxtropy/components';

import styles from '../index.module.scss';
import { getQlQuota, QlDetailProps, QuotaProps } from '../../../api/cc-tenant-ql';
import AssignDetailModal from '../AssignDetailModal';
const { useForm } = Form;

interface Iprops {
  isSet?: boolean; // 区分开户和开户设置
  initData?: QlDetailProps;
  mcid?: string;
}
export interface QuotaRefProps {
  getValues?: () => number;
  checkForm?: () => Promise<boolean>;
}

const QuotaManage = forwardRef<QuotaRefProps, Iprops>(({ isSet, initData, mcid }, ref) => {
  const [assignModalVisible, setAssignModalVisible] = useState(false);
  const [quotaList, setQuotaList] = useState<QuotaProps[]>([]);
  const [form] = useForm();

  const checkForm = () =>
    form
      .validateFields()
      .then(val => true)
      .catch(err => false);
  const getValues = () => form.getFieldValue('userQuota');
  useImperativeHandle(ref, () => ({
    getValues,
    checkForm,
  }));
  // 用户数配额表单反填
  useEffect(() => {
    let tempNum = isSet ? initData?.userQuota : 5;
    form.setFieldsValue({
      userQuota: tempNum,
    });
  }, [isSet, initData, form]);
  // 开户设置查询配额列表
  useEffect(() => {
    if (mcid && isSet) {
      getQlQuota(mcid).then(res => {
        setQuotaList(res);
      });
    }
  }, [mcid, isSet]);
  // 关闭配额弹框
  const closeAssignModal = (list?: QuotaProps[]) => {
    setAssignModalVisible(false);
    if (list && list.length > 0) {
      setQuotaList(list);
    }
  };

  // 已启用的用户配额
  const hasEnableQuotaNum = useMemo(() => {
    return quotaList.filter(item => !item.disable).length;
  }, [quotaList]);

  return (
    <>
      <Form form={form}>
        <FormContent title="配额管理">
          <Row>
            <Col span={8}>
              <Form.Item name="userQuota" label="用户数配额" rules={[{ required: true, message: '请输入用户数配额' }]}>
                <InputNumber min={0} />
              </Form.Item>
            </Col>
          </Row>
          {isSet && (
            <Row>
              <Col span={8}>
                <div className={styles.lineBox}>
                  已启用<span className={styles.openCounts}>{hasEnableQuotaNum}</span>个
                  <span className={styles.assignDetail} onClick={() => setAssignModalVisible(true)}>
                    配额详情
                  </span>
                </div>
              </Col>
            </Row>
          )}
        </FormContent>
      </Form>
      {assignModalVisible && (
        <AssignDetailModal mcid={mcid} listData={quotaList} onOk={closeAssignModal} onCancel={closeAssignModal} />
      )}
    </>
  );
});

export default QuotaManage;
