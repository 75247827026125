import React, { FC, useEffect, useState } from 'react';
import { Space, Modal, Button } from 'antd';
import { AttributeTemplate } from '../../../../api/template';
import { ActionType } from '../index';
import TemplateModal from './TemplateModal';
import styles from './index.module.scss';

export interface SelectDevicesProps {
  value?: AttributeTemplate;
  onChange?: (value?: AttributeTemplate) => void;
  rootMcid?: string;
  deviceType?: number[];
  actionType?: ActionType;
  physicalModelId?: number;
}

const SelectDevices: FC<SelectDevicesProps> = ({
  value,
  onChange,
  rootMcid,
  deviceType,
  actionType,
  physicalModelId,
}) => {
  const [visible, setVisible] = useState<boolean>(false);

  const [dataSource, setDataSource] = useState<AttributeTemplate>();
  const [modalApi, modalContextHolder] = Modal.useModal();

  useEffect(() => {
    setDataSource(value);
  }, [value]);

  const onAdd = () => {
    if (rootMcid === undefined) {
      modalApi.warning({
        title: '请先选择所属租户',
      });
      return;
    }
    if (deviceType === undefined) {
      modalApi.warning({
        title: '请先选择所属类目',
      });
      return;
    }
    setVisible(true);
  };

  const onCancel = () => {
    setDataSource(value);
    setVisible(false);
  };

  const onOk = () => {
    onChange?.(dataSource);
    setVisible(false);
  };

  return (
    <>
      <Space>
        {value !== undefined && <span>{value?.name ?? ''}</span>}
        <Button type="link" onClick={onAdd}>
          {value === undefined ? '请' : '重新'}选择
        </Button>
      </Space>
      <Modal
        className={styles.modal}
        title="模板选择"
        destroyOnClose
        open={visible}
        onOk={onOk}
        onCancel={onCancel}
        width={1000}
      >
        <TemplateModal
          rootMcid={rootMcid}
          deviceType={deviceType}
          actionType={actionType}
          onChange={setDataSource}
          physicalModelId={physicalModelId}
          value={dataSource}
        />
      </Modal>
      {modalContextHolder}
    </>
  );
};

export default SelectDevices;
