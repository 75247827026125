export enum RuleType {
  UPLOAD_TIME = 'UPLOAD_TIME',
  DATA_TIME = 'DATA_TIME',
  DATA_VALUE = 'DATA_VALUE',
}

export const RuleNames = {
  [RuleType.UPLOAD_TIME]: '上传时间规则',
  [RuleType.DATA_TIME]: '数据时间规则',
  [RuleType.DATA_VALUE]: '数据值规则',
};
