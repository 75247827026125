import React, { useEffect, useState } from 'react';
import { Input, Select, Space } from 'antd';

const { Option } = Select;

export interface EmailFormItemProps {
  onChange?: (value: string) => void;
  value?: string;
  disabled?: boolean;
}

const domainList = ['.com', '.com.cn', '.cn', '.net', '.ac.cn', '.gov.cn', '.edu.cn'];

const EmailFormItem: React.FC<EmailFormItemProps> = ({ onChange, value, disabled = false }) => {
  const [first, setFirst] = useState<string>('');
  const [sec, setSec] = useState<string>('');
  const [third, setThird] = useState<string>('.com');
  const [other, setOther] = useState<string>('');

  useEffect(() => {
    if (value) {
      const [firstValue, restValue] = value.split('@');
      setFirst(firstValue ?? '');
      const firstDotIndex = restValue.indexOf('.');
      if (firstDotIndex !== -1) {
        const secondData = restValue.slice(0, firstDotIndex);
        const thirdData = restValue.slice(firstDotIndex);
        setSec(secondData ?? '');
        if (domainList.find(i => i === thirdData)) {
          setThird(thirdData ?? '');
          setOther('');
        } else {
          setThird('other');
          setOther(thirdData.slice(1) ?? '');
        }
      }
    }
  }, [value]);

  const onFirstChange = (v: string) => {
    onChange && onChange(v + '@' + sec + `${third === 'other' ? '.' + other : third}`);
  };

  const onSecondChange = (v: string) => {
    onChange && onChange(first + '@' + v + `${third === 'other' ? '.' + other : third}`);
  };

  const onThirdChange = (v: string) => {
    onChange && onChange(first + '@' + sec + `${v === 'other' ? '.' + other : v}`);
  };

  const onOtherChange = (v: string) => {
    onChange && onChange(first + '@' + sec + `${third === 'other' ? '.' + v : third}`);
  };

  return (
    <Space>
      <Input
        value={first}
        style={{ maxWidth: 150 }}
        onChange={v => {
          onFirstChange(v.target.value);
        }}
        disabled={disabled}
      />
      @
      <Input
        style={{ minWidth: 80 }}
        value={sec}
        onChange={v => {
          onSecondChange(v.target.value);
        }}
        disabled={disabled}
      />
      <Select
        style={{ maxWidth: 100 }}
        value={third}
        onChange={v => {
          onThirdChange(v);
        }}
        disabled={disabled}
      >
        {domainList.map(item => (
          <Option key={item} value={item}>
            {item}
          </Option>
        ))}
        <Option value="other">其他</Option>
      </Select>
      {third === 'other' && (
        <>
          <span>.</span>
          <Input
            style={{ minWidth: 80 }}
            value={other}
            onChange={v => {
              onOtherChange(v.target.value);
            }}
            disabled={disabled}
          />
        </>
      )}
    </Space>
  );
};

export default EmailFormItem;
