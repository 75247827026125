import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { AlarmLevel } from '@/shared/types';
import { PageRequest, PageResponse } from './page';

export interface AlarmLogRequest extends PageRequest {
  tenantMcid?: string;
  ouId?: number;
  codeOrName?: string;
  state?: number;
  alarmStartTime?: number;
  alarmEndTime?: number;
  sortValues?: any[];
}

export interface AlarmLogResponse {
  id: number;
  alarmName?: string;
  alarmLevel?: AlarmLevel;
  alarmTime?: string;
  recoveryTime?: string;
  readTime?: string;
  tenantCode?: string;
  tenantName?: string;
  deviceId?: string;
  deviceCode?: string;
  deviceName?: string;
}

// export function getAlarmLogList(query: AlarmLogRequest): Promise<PageResponse<AlarmLogResponse>> {
//   return fetch(`/api/rule/alarm/log/page?${qs.stringify(query, { indices: false })}`);
// }
export function getAlarmLogList(
  query: AlarmLogRequest
): Promise<PageResponse<AlarmLogResponse> & { useSearchAfter: boolean; sortValues: any[] }> {
  return fetch(
    `/api/v2/rule/alarm/log/page`,
    {
      method: 'POST',
      body: JSON.stringify({ ...query }),
    },
    true
  );
}

export interface AlarmLogDetailResponse extends AlarmLogResponse {
  ouId?: number;
  ouName?: string;
  ruleId?: number;
  ruleCode?: string;
  ruleName?: string;
}

export function getAlarmLogDetail(alarmId: string): Promise<AlarmLogDetailResponse> {
  return fetch(`/api/rule/alarm/log/details?alarmId=${alarmId}`);
}

export interface OuResponse {
  id: number;
  name: string;
}

export function getOuListByRootMcid(rootMcid: string): Promise<OuResponse[]> {
  return fetch(`/api/rule/alarm/log/ouListByRootMcid?rootMcid=${rootMcid}`);
}

// 获取平台网关类目的 id 值
export function getPlatformGatewayId() {
  return fetch<number>('/api/deviceType/platform/gateway/id');
}
