import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { EllipsisSpan, Wrapper } from '@maxtropy/components';
import { Button, Col, Form, Input, Modal, Row, Select, Space, Spin, Table } from 'antd';
import { FieldType, fieldTypeDisplay } from '../../api/type';
import { useNavigate, useParams } from 'react-router-dom';
import { apiFormTemplateIdGet } from '../../ytt/types/sprint64/formTemplate/id';
import { isNil } from 'lodash-es';
import { apiFormIdGet, apiFormIdPut } from '../../ytt/types/sprint64/form/id';
import { apiFormPost } from '../../ytt/types/sprint64/api/form';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { apiDataRuleListGet } from '../../ytt/types/dataRule/list';
import styles from './index.module.scss';

const { useForm } = Form;
const baseRoutes = [{ name: '数据运营工具' }, { name: '业务数据模拟' }, { name: '表单规则' }];

interface OptionItem {
  label?: string;
  key?: string | number;
}

interface IProps {
  isEdit?: boolean;
}

const funcOptions = [
  { label: '系统时间', value: 1 },
  { label: '系统日期', value: 2 },
];

const FormCreate: FC<IProps> = ({ isEdit = false }) => {
  // 新建时为模板id，编辑时为表单id
  const { id } = useParams<{ id?: string }>();
  const [form] = useForm();
  const navigate = useNavigate();
  const [modalApi, modalContextHolder] = Modal.useModal();
  const [objOptions, setObjOptions] = useState<OptionItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchRef = useRef<number>(0);

  useEffect(() => {
    apiDataRuleListGet({ name: '' }).then(res => {
      const options = (res ?? []).map(i => ({ label: i.name, value: i.id }));
      setObjOptions([...options]);
    });
  }, []);

  useEffect(() => {
    (async () => {
      if (isNil(id)) return;
      setLoading(true);
      let formData = isEdit ? await apiFormIdGet({ id }) : null;
      let templateData = await apiFormTemplateIdGet({ id: formData ? String(formData.formTemplateId!) : id });

      form.setFieldsValue({
        name: formData?.name ?? '',
        description: formData?.description ?? '',
        templateName: formData?.formTemplateName ?? templateData.name,
        paramvalues: templateData?.params?.map((item, index) => {
          const prevParam = formData?.paramValues?.find(i => i.param === item);
          return {
            id: index,
            index: index + 1,
            param: item,
            paramType: prevParam?.paramType ?? FieldType.TEXT,
            paramText: prevParam?.paramText,
            paramRuleId: prevParam?.paramRuleId,
            paramFunction: prevParam?.paramFunction,
          };
        }),
      });
      setLoading(false);
    })();
  }, []);

  const routes = useMemo(() => {
    return [...baseRoutes, { name: `${isEdit ? '编辑' : '创建'}表单` }];
  }, [isEdit]);

  const onFinish = (values: any) => {
    const params = {
      name: values.name,
      description: values.description,
      paramValues: values.paramvalues.map(
        (i: { param: any; paramType: FieldType; paramText: any; paramRuleId: any; paramFunction: any }) => ({
          param: i.param,
          paramType: i.paramType,
          paramText: i.paramType === FieldType.TEXT ? i.paramText : null,
          paramRuleId: i.paramType === FieldType.OBJECT ? i.paramRuleId : null,
          paramFunction: i.paramType === FieldType.FUNCTION ? i.paramFunction : null,
        })
      ),
    };
    if (isEdit) {
      apiFormIdPut({ id: id!, ...params }).then(_ => {
        navigate(`/data-tools/business/formmanage`);
      });
    } else {
      apiFormPost({ formTemplateId: Number(id!), ...params }).then(_ => {
        navigate(`/data-tools/business/formmanage`);
      });
    }
  };

  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      width: 100,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '名称',
      dataIndex: 'param',
      ellipsis: { showTitle: true },
      render: (v: any) => <EllipsisSpan value={v} />,
    },
    {
      title: '类型',
      dataIndex: 'paramType',
      render: (text: any, record: any, index: number) => {
        return (
          <Form.Item name={['paramvalues', index, 'paramType']} rules={[{ required: true, message: '请选择字段类型' }]}>
            <Select
              placeholder="请选择字段类型"
              options={Object.entries(fieldTypeDisplay).map(([k, v]) => ({
                label: v,
                value: +k,
              }))}
            ></Select>
          </Form.Item>
        );
      },
    },
    {
      title: '规则',
      dataIndex: 'paramRuleId',
      render: (text: any, record: any, index: number) => {
        if (record.paramType === FieldType.TEXT) {
          return (
            <Form.Item
              name={['paramvalues', index, 'paramText']}
              rules={[{ required: true, message: '请输入字段规则' }]}
            >
              <Input placeholder="请输入字段规则" />
            </Form.Item>
          );
        } else if (record.paramType === FieldType.OBJECT) {
          return (
            <Form.Item
              name={['paramvalues', index, 'paramRuleId']}
              rules={[{ required: true, message: '请选择引用模板' }]}
            >
              <Select
                showSearch
                placeholder="请选择引用模板"
                options={objOptions}
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              ></Select>
            </Form.Item>
          );
        } else {
          return (
            <Form.Item
              name={['paramvalues', index, 'paramFunction']}
              rules={[{ required: true, message: '请选择函数类型' }]}
            >
              <Select placeholder="请选择函数类型" options={funcOptions}></Select>
            </Form.Item>
          );
        }
      },
    },
  ];
  return (
    <Wrapper routes={routes}>
      <Spin spinning={loading}>
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item label="表单模板" dependencies={['templateName']}>
                {({ getFieldValue }) => {
                  return <div>{getFieldValue('templateName')}</div>;
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label="表单名称"
                name="name"
                rules={[
                  { required: true, message: '请输入表单名称' },
                  { min: 2, max: 50 },
                ]}
              >
                <Input placeholder="请输入表单名称" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item label="表单说明" name="description" rules={[{ max: 200 }]}>
                <Input.TextArea placeholder="请输入表单说明" autoSize={{ minRows: 8 }} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="表单字段" name="paramvalues" valuePropName="dataSource" required>
            <Table rowKey="id" bordered columns={columns} pagination={false} rowClassName={styles.tableRow} />
          </Form.Item>
          <Form.Item>
            <Space>
              <Button htmlType="submit" type="primary">
                保存
              </Button>
              <Button
                onClick={() => {
                  modalApi.confirm({
                    title: '您是否确定不保存此表单返回列表界面?',
                    icon: <ExclamationCircleOutlined />,
                    onOk() {
                      navigate(`/data-tools/business/formmanage`);
                    },
                  });
                }}
              >
                取消
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Spin>

      {modalContextHolder}
    </Wrapper>
  );
};

export default FormCreate;
