import { Form, Input, InputNumber, Radio, Row, Col } from 'antd';
import { FC } from 'react';
import { FactorPropertyEnum, fuelTypeOptions } from '../emissionProperty';
import ExtraFactor from './ExtraFactor';

const FormItem = Form.Item;

interface Props {}
const Fuel: FC<Props> = () => {
  return (
    <>
      <Row gutter={16}>
        <Col span={8}>
          <FormItem
            label="类型"
            name={['factorDetails', `${FactorPropertyEnum.FuelType}_1`]}
            rules={[{ required: true }]}
          >
            <Radio.Group options={fuelTypeOptions} />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <FormItem
            label="低位发热值"
            name={['factorDetails', `${FactorPropertyEnum.LowCalorificValue}_2`]}
            rules={[{ required: true }]}
          >
            <InputNumber placeholder="请输入数值" style={{ width: '100%' }} min={0} />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            label="单位"
            name={['factorDetails', `${FactorPropertyEnum.FuelUnit}_2`]}
            rules={[{ max: 50, message: '长度不能大于50个字符' }]}
          >
            <Input placeholder="请输入" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <FormItem
            label="单位热值含碳量"
            name={['factorDetails', `${FactorPropertyEnum.CarbonContentPerUnitCalorificValue}_3`]}
            rules={[{ required: true }]}
          >
            <InputNumber placeholder="请输入数值" style={{ width: '100%' }} min={0} />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            label="单位"
            name={['factorDetails', `${FactorPropertyEnum.FuelUnit}_3`]}
            rules={[{ max: 50, message: '长度不能大于50个字符' }]}
          >
            <Input placeholder="请输入" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <FormItem
            label="燃料碳氧化率"
            name={['factorDetails', `${FactorPropertyEnum.FuelCarbonOxidationRate}_4`]}
            rules={[{ required: true }]}
          >
            <InputNumber placeholder="请输入数值" style={{ width: '100%' }} min={0} />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            label="单位"
            name={['factorDetails', `${FactorPropertyEnum.FuelUnit}_4`]}
            rules={[{ max: 50, message: '长度不能大于50个字符' }]}
          >
            <Input placeholder="请输入" />
          </FormItem>
        </Col>
      </Row>
      <ExtraFactor start={5} />
    </>
  );
};

export default Fuel;
