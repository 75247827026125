import { fetch } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import qs from 'qs';
import { ConstraintValue } from '@/shared/types';

export interface Attribute {
  id: number;
  name: string;
  serialNumber: string;
  templateCount: number;
  deviceTypeList: string;
  modelNo: string;
  manufacturerName: string;
}

export type AttributeResponse = PageResponse<Attribute>;

export interface AttributeRequest extends PageRequest {
  name?: string;
  deviceType?: Array<number>;
  manufacturerId?: number;
  physicalModelId?: number;
}

export const getAttributeList = (params: AttributeRequest) =>
  fetch<AttributeResponse>(`/api/attribute/page?${qs.stringify(params, { indices: false })}`);

interface AttributeDetailResponse {
  attribute: AttributeDetail;
}

interface AttributeDetail {
  deviceFields: Array<DeviceField>;
  id: number;
  name: string;
  serialNumber: string;
  deviceTypeList: string;
}

export interface DeviceField {
  id: number;
  name: string;
  type: number;
  controlType: number;
  digitalAccuracy: number;
  inputType: number;
  numberType: number;
  physicalUnits?: Array<PhysicalUnits>;
  sequence: number;
  constraintValue: ConstraintValue;
}

interface PhysicalUnits {
  b: number;
  basePhysicalUnitId: number;
  br: number;
  chineseName: string;
  generalName: string;
  id: number;
  isBasic: boolean;
  k: number;
  kr: number;
}

export const getAttributeDetail = (id: string) => fetch<AttributeDetailResponse>(`/api/attribute/${id}`);

export interface DeviceTypes {
  id: number;
  name: string;
  allowAttribute?: boolean;
  children?: Array<DeviceTypes>;
  deviceTypes?: Array<DeviceTypes>;
  parentId?: number;
  parentName?: string;
  rootId?: number;
}

export interface DeviceTypeTree {
  aliasName: Array<string>;
  tree: Array<DeviceTypes>;
}

export const getDeviceTypeData = () => fetch<DeviceTypeTree>(`/api/deviceType/tree`);
