import { useState, useEffect, useMemo } from 'react';
import { isEmpty, uniqueId } from 'lodash';
import { Modal, Form, Input, message, Table, Space } from 'antd';
import { Button, EllipsisSpan } from '@maxtropy/components';
import {
  apiMiniAppCategoryAddPost,
  apiMiniAppCategoryDeletePost,
  apiMiniAppCategoryEditPost,
  apiMiniAppCategoryListPost,
} from '@maxtropy/cc-mgmt-apis';
import { CategoryListType } from '../types';

type ToolCategoryProps = {
  setOpenCategory: (open: boolean) => void;
  setRefreshCategory: (open: boolean) => void;
};

const ToolCategoryModal = ({ setOpenCategory, setRefreshCategory }: ToolCategoryProps) => {
  const [categoryList, setCategoryList] = useState<CategoryListType[]>([]);
  const [form] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    apiMiniAppCategoryListPost().then(res => {
      if (res) {
        setCategoryList(res as CategoryListType[]);
      }
    });
  }, []);

  const onCancel = () => {
    setOpenCategory(false);
    setRefreshCategory(true);
    form.resetFields();
  };

  const handleAdd = () => {
    if (!isEmpty(form.getFieldsValue())) {
      message.warning('请保存正在编辑的分类后再操作');
      return;
    }
    const testId = Number(uniqueId('100'));
    const newData: CategoryListType = {
      id: testId,
      isAdd: true,
      isEdit: true,
    };
    setCategoryList([...categoryList, newData]);
  };

  const onCancelEdit = (record: CategoryListType) => {
    let newList: CategoryListType[] = [];
    if (record.isAdd) {
      // 新建的分类点击取消。则删除当前条
      newList = categoryList.filter(item => item.id !== record.id);
    } else {
      // 编辑的分类点击取消，放弃本行修改的内容
      newList = categoryList.map(item => {
        if (item.id === record.id) {
          item.isEdit = false;
        }
        return item;
      });
    }

    setCategoryList(newList);

    form.resetFields();
  };

  const onSave = (record: CategoryListType) => {
    const name = form.getFieldValue('name');
    if (!name) {
      message.warning('请输入分类');
      return;
    }

    form.validateFields().then(async () => {
      let res: number;
      if (record.isAdd) {
        res = await apiMiniAppCategoryAddPost({ name });
      } else {
        res = await apiMiniAppCategoryEditPost({ id: record.id, name });
      }

      if (res) {
        const newList = categoryList.map(item => {
          const newItem = { ...item };
          if (item.id === record.id) {
            newItem.name = name;
            newItem.isEdit = false;
            newItem.isAdd = false;
            // 新增数据的时候，会返回插入数据库的id，前端用返回的id作为当前数据的id
            newItem.id = record.isAdd ? res : item.id;
          }
          return newItem;
        });
        setCategoryList(newList);
        form.resetFields();
      }
    });
  };

  const onDel = (record: CategoryListType) => {
    if (!isEmpty(form.getFieldsValue())) {
      message.warning('请保存正在编辑的分类后再操作');
      return;
    }

    modal.confirm({
      title: '是否删除此分类?',
      onOk() {
        apiMiniAppCategoryDeletePost({ id: String(record.id) }).then(res => {
          const newList = categoryList.filter(i => i.id !== record.id);
          setCategoryList(newList);
        });
      },
    });
  };

  const onEdit = (record: CategoryListType) => {
    if (!isEmpty(form.getFieldsValue())) {
      message.warning('请保存正在编辑的分类后再操作');
      return;
    }

    const newList = categoryList.map(item => {
      if (item.id === record.id) {
        item.isEdit = true;
      }
      return item;
    });

    form.setFieldValue('name', record.name);

    setCategoryList(newList);
  };

  const columns = useMemo(
    () => [
      {
        title: '工具分类',
        dataIndex: 'name',
        ellipsis: { showTitle: true },
        render: (v: string, record: CategoryListType) => {
          return record.isEdit ? (
            <Form.Item
              name="name"
              style={{ marginBottom: 0 }}
              rules={[
                { required: true, message: '请输入分类' },
                { max: 6, message: '最多输入6个字符' },
              ]}
            >
              <Input placeholder="请输入分类" />
            </Form.Item>
          ) : (
            <EllipsisSpan value={v} />
          );
        },
      },
      {
        title: '操作',
        render: (record: CategoryListType) => {
          return record.isEdit ? (
            <Space size={16}>
              <Button type="link" onClick={() => onSave(record)}>
                保存
              </Button>
              <Button type="link" onClick={() => onCancelEdit(record)}>
                取消
              </Button>
            </Space>
          ) : (
            <Space size={16}>
              <Button type="link" onClick={() => onEdit(record)}>
                编辑
              </Button>
              <Button type="link" onClick={() => onDel(record)}>
                删除
              </Button>
            </Space>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categoryList]
  );

  return (
    <Modal title="分类管理" open={true} onCancel={onCancel} width={800} footer={null}>
      <Form form={form}>
        <div>
          <Table bordered={false} dataSource={categoryList} rowKey="id" columns={columns} pagination={false} />
          <Button type="link" onClick={handleAdd}>
            新建分类
          </Button>
          {contextHolder}
        </div>
      </Form>
    </Modal>
  );
};

export default ToolCategoryModal;
