import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { apiRulesSetGetAppendDataPost, RulesSetGetAppendDataPostResponse } from '@maxtropy/device-mock-apis';
import { Button, Col, Form, Modal, Row, Switch } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { FC, useEffect, useState } from 'react';
import { PropertyType } from '../../../api/type';
import ShowInput from '../../../components/ShowInput';
import { RulesSetIdGetResponse } from '../../../ytt/types/rulesSet/id';
import styles from './index.module.scss';
import dayjs from 'dayjs';
import { getRealUrl } from '@maxtropy/components';

interface Iprops {
  detail: RulesSetIdGetResponse;
  deviceId?: number;
  onCancel?: () => void;
}

const ViewDataSupplementModal: FC<Iprops> = props => {
  const { detail, deviceId, onCancel } = props;
  const [supplementDetail, setSupplementDetail] = useState<RulesSetGetAppendDataPostResponse>();
  const [form] = useForm();

  useEffect(() => {
    if (deviceId) {
      apiRulesSetGetAppendDataPost({ id: deviceId }).then(setSupplementDetail);
    }
  }, [deviceId]);

  useEffect(() => {
    if (supplementDetail) {
      form.setFieldsValue({
        deviceTypeName: supplementDetail.deviceTypeName,
        deviceList: supplementDetail.deviceList?.map(i => i.name).join(),
        dataPropertyList: supplementDetail.dataPropertyList?.map(i => i.name).join(),
        cumulativeStartValue: supplementDetail.cumulativeStartValue,
        yMagnificationValue: supplementDetail.yMagnificationValue,
        unifiedCompute: supplementDetail.unifiedCompute,
        beginEndTime: `${
          // @ts-ignore
          supplementDetail.beginTime ? dayjs(supplementDetail.beginTime).format('YYYY-MM-DD HH:mm') : '--'
          // @ts-ignore
        }~${supplementDetail.beginTime ? dayjs(supplementDetail.endTime).format('YYYY-MM-DD HH:mm') : '--'}`,
      });
    }
  }, [supplementDetail]);

  const downloadData = () => {
    if (supplementDetail?.insertPointsFileKey) {
      window.open(getRealUrl(supplementDetail?.insertPointsFileKey));
    } else {
      Modal.error({
        title: '暂无可下载数据',
      });
    }
  };

  const downloadReplaceData = () => {
    if (supplementDetail?.deletePointsFileKey) {
      window.open(getRealUrl(supplementDetail?.deletePointsFileKey));
    } else {
      Modal.error({
        title: '暂无可下载数据',
      });
    }
  };

  return (
    <>
      <Modal
        title="数据补录"
        open
        width={600}
        closable={false}
        onCancel={() => {
          onCancel?.();
        }}
        footer={[<Button onClick={() => onCancel?.()}>取消</Button>]}
      >
        <div className={styles.modal_box}>
          <Form form={form} layout="vertical">
            <Form.Item label="选择类目" name="deviceTypeName" rules={[{ required: true }]}>
              <ShowInput />
            </Form.Item>
            <Form.Item label="选择设备" name="deviceList" rules={[{ required: true }]}>
              <ShowInput />
            </Form.Item>
            <Form.Item label="选择数据属性" name="dataPropertyList" rules={[{ required: true }]}>
              <ShowInput />
            </Form.Item>
            <Row>
              {/* 仅累积量显示, 瞬时量不显示 */}
              {detail.propertyType === PropertyType.ACCUMULATE && (
                <Col span={12}>
                  <Form.Item
                    label={detail.cumulativeStartName}
                    name="cumulativeStartValue"
                    rules={[{ required: true }]}
                  >
                    <ShowInput />
                  </Form.Item>
                </Col>
              )}
              <Col span={12}>
                <Form.Item label={detail.yMagnificationName} name="yMagnificationValue" rules={[{ required: true }]}>
                  <ShowInput />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item valuePropName="checked" label="统一计算" name="unifiedCompute">
              <Switch disabled checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
            </Form.Item>
            <Form.Item label="补录起止时间" name="beginEndTime">
              <ShowInput />
            </Form.Item>
            <Row>
              <Col span={12}>
                <Form.Item label="补录数据" name="downloadData">
                  <Button type="link" style={{ color: 'var(--primary-color)' }} onClick={downloadData}>
                    下载
                  </Button>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="被替换数据" name="downloadReplaceData">
                  <Button type="link" style={{ color: 'var(--primary-color)' }} onClick={downloadReplaceData}>
                    下载
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default ViewDataSupplementModal;
