import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Select, Form, Space, Modal, Cascader } from 'antd';
import { Wrapper, useAsync } from '@maxtropy/components';
import SelectDevices from './SelectDevices';
import SelectRuleGroup from './SelectRuleGroup';
import { batchDeviceBindingRuleGroup, BatchRuleGroupPageResponse } from '../../../api/ruleGroup';
// import {getDeviceTypeData} from "../../../api/template";

// import {formatOptionData} from "shared/components/CascadingMultipleSelector/utils";
import { IotProtocolType } from '@/shared/types';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';
import { getProtocol } from '../../../api/protocol';
import { getPhysicalModelList, getRoot, PhysicalModelRes } from '../../../api/device';
import { formatDeviceTypeToDataNode, useQuery } from '@/shared/utils/utils';
import { getDeviceTypeTreeWithoutScene } from '../../../api/template';

const { Option } = Select;

const routes = [{ name: 'Iot配置' }, { name: '设备管理' }, { name: '设备管理' }, { name: '批量配置报警规则组' }];

const layout = {
  labelCol: { style: { width: 120, alignItems: 'center', display: 'flex', justifyContent: 'flex-end', height: 32 } },
  wrapperCol: { span: 9 },
};

interface FormValues {
  iotProtocolType: number;
  deviceType: Array<number>;
  deviceIdList: Array<number>;
  iotProtocol: IotProtocolType;
  ruleGroup: BatchRuleGroupPageResponse;
  rootMcid: string;
}

const BatchBindRuleGroup: React.FC = () => {
  const navigate = useNavigate();

  // const deviceTypeData = useAsync(getDeviceTypeData);
  const iotProtocolData = useAsync(getProtocol);

  const [iotProtocolType, setIotProtocolType] = useState<IotProtocolType>();
  const [rootMcid, setRootMcid] = useState<string>();
  const [deviceType, setDeviceType] = useState<number>();
  const [objectModalType, setObjectModalType] = useState<number>();

  // const [deviceType, setDeviceType] = useState<number[]>();

  const [form] = Form.useForm();

  const customerData = useAsync(getRoot);

  const { data: deviceTypeTreeData } = useQuery(useCallback(() => getDeviceTypeTreeWithoutScene(), []));

  const [physicalModelList, setPhysicalModelList] = useState<PhysicalModelRes[]>([]); // all 物模型型号列表
  const [modalApi, modalContextHolder] = Modal.useModal();

  useEffect(() => {
    // 物模型型号
    if (deviceType) {
      getPhysicalModelList({ deviceTypeIds: [deviceType] }).then(setPhysicalModelList);
    }
  }, [deviceType]);

  const objectModalTypeOptions = useMemo(() => {
    if (physicalModelList && physicalModelList.length > 0) {
      return physicalModelList.map(i => ({ label: `${i.modelNo}-${i.manufacturerName}`, value: i.id }));
    }
    return [];
  }, [physicalModelList]);

  const onSubmit = (val: FormValues) => {
    batchDeviceBindingRuleGroup(
      {
        deviceIdList: val.deviceIdList,
        ruleGroupId: val.ruleGroup.id,
      },
      val.rootMcid
    ).then(res => {
      if (res) {
        goList();
      }
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      deviceIdList: [],
      ruleGroup: undefined,
    });
  }, [rootMcid, iotProtocolType, deviceType, objectModalType, form]);

  // useEffect(() => {
  //   form.setFieldsValue({
  //     deviceIdList: [],
  //   })
  // }, [deviceType, form])

  // const onDeviceTypeSelect = (val: Array<string | number>) => {
  //   setDeviceType(val as number[]);
  // };

  const goList = () => {
    navigate(`/device/manage/device`);
  };

  const onCacel = () => {
    modalApi.confirm({
      title: null,
      icon: null,
      content: <div>是否放弃所有未保存信息并返回列表？</div>,
      onOk: goList,
    });
  };

  return (
    <Wrapper routes={routes} className={styles.wrapper}>
      <Form<FormValues> {...layout} form={form} onFinish={onSubmit}>
        <Form.Item name="rootMcid" rules={[{ required: true, message: '请选择所属租户' }]} label="所属租户">
          <Select style={{ width: 175 }} placeholder={'请选择租户'} onChange={(v: string) => setRootMcid(v)}>
            {customerData?.map(item => (
              <Option key={item.id} value={item.mcid}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {/* <Form.Item
          name="deviceType"
          rules={[{required: true, message: "请选择所属类目"}]}
          label="所属类目">
          <Cascader options={formatOptionData(deviceTypeData)}
                    allowClear={false}
                    fieldNames={{children: 'child'}}
                    onChange={onDeviceTypeSelect}
                    placeholder={'请选择所属类目'}/>
        </Form.Item> */}
        <Form.Item name="iotProtocolType" rules={[{ required: true, message: '请选择物联层协议' }]} label="物联层协议">
          <Select<IotProtocolType>
            onChange={v => {
              setIotProtocolType(v);
              setDeviceType(undefined);
              setObjectModalType(undefined);

              form.setFieldsValue({
                deviceTypeId: undefined,
                objectModalType: undefined,
              });
            }}
            placeholder={'请选择物联层协议'}
          >
            {iotProtocolData?.map(item => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {iotProtocolType === IotProtocolType.MOCKINGBIRD && (
          <>
            <Form.Item
              name="deviceTypeId"
              label="适用设备类目"
              rules={[{ required: true, message: '请选择适用设备类目' }]}
            >
              <Cascader
                allowClear={false}
                style={{ width: '100%' }}
                options={formatDeviceTypeToDataNode(deviceTypeTreeData?.deviceTypes ?? [])}
                fieldNames={{ label: 'name', value: 'id' }}
                onChange={(e: any) => {
                  if (e.length < 2) {
                    form.setFieldsValue({ typeId: undefined });
                  }
                  setDeviceType(e[1] as number | undefined);
                  setObjectModalType(undefined);
                  form.setFieldsValue({ objectModalType: undefined });
                }}
                placeholder="请选择"
              />
            </Form.Item>
            <Form.Item name="objectModalType" label="物模型型号">
              <Select
                allowClear
                optionFilterProp="label"
                showSearch
                options={objectModalTypeOptions}
                onChange={v => {
                  setObjectModalType(v);
                }}
                placeholder={form.getFieldValue('deviceTypeId') ? '请选择' : '请先选择适用设备类目'}
                disabled={!form.getFieldValue('deviceTypeId')}
              />
            </Form.Item>
          </>
        )}

        <Form.Item name="deviceIdList" rules={[{ required: true, message: '请选择设备' }]} label="设备选择">
          <SelectDevices
            objectModalType={objectModalType}
            rootMcid={rootMcid}
            iotProtocol={iotProtocolType}
            deviceTypeId={deviceType}
          />
        </Form.Item>
        <Form.Item name="ruleGroup" rules={[{ required: true, message: '请选择规则组' }]} label="规则组">
          <SelectRuleGroup
            rootMcid={rootMcid}
            iotProtocol={iotProtocolType}
            deviceTypeId={deviceType}
            objectModalType={objectModalType}
          />
        </Form.Item>
        <Space className="sticky-footer">
          <Button htmlType="submit" type="primary">
            完成
          </Button>
          <Button onClick={onCacel}>取消</Button>
        </Space>
      </Form>
      {modalContextHolder}
    </Wrapper>
  );
};

export default BatchBindRuleGroup;
