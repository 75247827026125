import React from 'react';
import { Dropdown, Menu, Avatar, Space } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import { MenuEntry } from '@/api/menu';
import { logout } from '@/api/login';
import { Link } from 'react-router-dom';

export interface UserInfoProps {
  menuItems?: MenuEntry[];
  prefixCls?: string;
  avatarUrl?: string;
  name?: string;
  hasAvatar?: boolean;
}

const UserInfo: React.FC<UserInfoProps> = props => {
  const { menuItems, prefixCls = 'central-menu', avatarUrl, hasAvatar = true, name } = props;

  const logoutFun = () => {
    logout().then(() => {
      Cookies.remove('token');
      window.location.assign('/login');
    });
  };
  const menu = (
    <Menu
      theme="dark"
      className={`${prefixCls}-drop-menu`}
      items={[
        ...(menuItems ?? []).map(i => ({
          key: i.key,
          label: <Link to={i.url}>{i.name}</Link>,
          title: i.name,
        })),
        {
          key: 'logout',
          title: '退出登录',
          label: (
            <a href="#!" onClick={logoutFun}>
              <span>退出登录</span>
            </a>
          ),
        },
      ]}
    />
  );

  return (
    <Space size={5}>
      {hasAvatar ? <Avatar icon={avatarUrl ? null : <UserOutlined />} src={avatarUrl} /> : null}
      <Dropdown overlay={name ? menu : <></>} trigger={['click']} overlayClassName={`${prefixCls}-dropdown`}>
        <span style={{ cursor: 'pointer' }}>
          {name ?? '未登录'}
          <DownOutlined />
        </span>
      </Dropdown>
    </Space>
  );
};

export default UserInfo;
