import React from 'react';
import classNames from 'classnames/bind';
import styles from './index.module.scss';
import { isObject } from 'lodash-es';

interface BracketLeftProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  data: object | any[];
  isLastKey: boolean;
}

const cx = classNames.bind(styles);

const BracketLeft: React.FC<React.PropsWithChildren<BracketLeftProps>> = ({
  visible,
  setVisible,
  data,
  isLastKey,
  children,
}) => {
  const toggleBrackets = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setVisible(!visible);
  };

  const bracketsFormatter = (brackets: string) => {
    return isLastKey ? `${brackets},` : brackets;
  };

  const doubleBracketsGenerator = (data: object | any[]) => {
    const brackets =
      isObject(data) && (data as unknown as { type: 'array' | 'object' }).type === 'array' ? '[...]' : '{...}';
    return bracketsFormatter(brackets);
  };

  return (
    <div className={cx('sw-tree-bracket-wrapper')}>
      <span>
        {children}
        <span
          style={{ display: visible ? '' : 'none' }}
          className={cx('sw-tree-bracket-left')}
          onClick={toggleBrackets}
        >
          {/* {Array.isArray(data) ? "[" : "{"} */}
          {isObject(data) && (data as unknown as { type: 'array' | 'object' }).type === 'array' ? `[` : `{`}
        </span>
        <span
          style={{ display: !visible ? '' : 'none' }}
          className={cx('sw-tree-bracket-left')}
          onClick={toggleBrackets}
        >
          {doubleBracketsGenerator(data)}
        </span>
      </span>
      <span>{(data as unknown as { description?: string }).description as string}</span>
    </div>
  );
};

export default BracketLeft;
