import { fetch, ResponseError, triggerModal } from '@maxtropy/components';
import qs from 'qs';
import { EdgeDeviceTemplatePoint } from '../components/EdgeDevicePointInfo';
import { MockingbirdDeviceProps } from '../components/DataAcquisitionForm/types';
import { DriveType, IotProtocolType } from '../types';

export function defaultErrorHandling(err: any) {
  let rce = err;
  let defaultMessage = '未知错误！ 请联系管理员。';

  if (err instanceof ResponseCodeError) {
    rce = err;
  } else if (err instanceof ResponseError) {
    rce = err.cause;
    defaultMessage = err.message || err.cause.errorMessage || defaultMessage;
  }
  if (rce) {
    switch (rce.status) {
      case 400:
        triggerModal({
          type: 'error',
          props: {
            title: err.errorMessage || defaultMessage,
          },
        });
        // Modal.error({
        //   title: err.errorMessage || defaultMessage,
        // });
        break;
      case 500:
        triggerModal({
          type: 'error',
          props: {
            title: defaultMessage,
          },
        });
        // Modal.error({
        //   title: defaultMessage,
        // });
        break;
    }
  }
}

async function _fetch(input: RequestInfo, init?: RequestInit): Promise<Response> {
  const compoundInit: RequestInit = {
    credentials: 'include',
    ...init,
  };

  const response = await window.fetch(input, compoundInit);

  if (!response.ok) {
    let text = '';
    let body: any = undefined;
    try {
      text = await response.text();
      if (text) {
        body = JSON.parse(text);
      }
    } catch (error) {
      console.error(error);
    }
    const e = new ResponseCodeError(response.status, body?.errorType, body?.errorMessage, body);
    defaultErrorHandling(e);
    throw e;
  }
  return response;
}

export class ResponseCodeError extends Error {
  readonly status: number;
  readonly errorType?: string;
  readonly errorMessage?: string;
  readonly body?: any;

  constructor(status: number, errorType?: string, errorMessage?: string, body?: any) {
    super();
    this.status = status;
    this.errorType = errorType;
    this.errorMessage = errorMessage;
    this.body = body;
  }

  static getMessage(err: any): string | undefined;
  static getMessage(err: any, defaultMessage: string): string;
  static getMessage(err: any, defaultMessage?: string): string | undefined {
    return (err instanceof ResponseCodeError && err.errorMessage) || defaultMessage;
  }
}

interface PointLockBody {
  tenantMcid?: string;
  deviceTypeId: number;
  iotProtocol: number;
  driveType: number;
  deviceIds: number[];
  pointType?: number;
  hasProperty?: boolean;
  dataPropertyIds?: number[];
  parameter?: string[];
  points: EdgeDeviceTemplatePoint[];
  serial: string;
}

export const batchAddPointLock = (body: PointLockBody) =>
  fetch<{ serial: string }>('/api/batch/edgeDevice/point/add/lock', {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'x-tenant-mcid': body.tenantMcid ?? '',
    },
  }).onError(error => {
    defaultErrorHandling(error);
    throw error;
  });

export const batchAddPointDownload = (query: { serial: string }, tenantMcid: string) =>
  _fetch(`/api/batch/edgeDevice/point/add/download?${qs.stringify(query, { indices: false })}`, {
    headers: {
      'x-tenant-mcid': tenantMcid,
    },
  }).then(response => response.body);

export const bathAddPoint = (body: FormData): Promise<{ key: string }> => {
  const headers: Record<string, string> = {};
  const tenantMcid = body.get('tenantMcid');
  if (tenantMcid) {
    headers['x-tenant-mcid'] = (body.get('tenantMcid') ?? '') as string;
  }
  return _fetch(`/api/batch/edgeDevice/point/add/input`, {
    method: 'POST',
    body: body,
    headers,
  }).then(response => response.json());
};

interface UpdateLockBody extends MockingbirdDeviceProps {
  tenantMcid?: string;
  deviceTypeId: number;
  iotProtocol: IotProtocolType;
  deviceIds: number[];
  serial: string;
  driveType: DriveType;
  updateItems: string[];
}

export const batchDriveTypeFormLock = (body: UpdateLockBody, tenantMcid: string) =>
  fetch<{ serial: string }>('/api/batch/edgeDevice/update/lock', {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'x-tenant-mcid': tenantMcid,
    },
  }).onError(error => {
    defaultErrorHandling(error);
    throw error;
  });

export const batchDriveTypeFormDownload = (query: { serial: string }) =>
  _fetch(`/api/batch/edgeDevice/update/download?${qs.stringify(query, { indices: false })}`).then(
    response => response.body
  );

export const bathUpdateDriveTypeForm = (body: FormData): Promise<{ key: string }> => {
  const headers: Record<string, string> = {};
  const tenantMcid = body.get('tenantMcid');
  if (tenantMcid) {
    headers['x-tenant-mcid'] = (body.get('tenantMcid') ?? '') as string;
  }
  return _fetch(`/api/batch/edgeDevice/update/input`, {
    method: 'PUT',
    body: body,
    headers,
  }).then(response => response.json());
};

export const batchUpdatePointLock = (body: PointLockBody) =>
  fetch<{ serial: string }>('/api/batch/edgeDevice/point/update/lock', {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'x-tenant-mcid': body.tenantMcid ?? '',
    },
  }).onError(error => {
    defaultErrorHandling(error);
    throw error;
  });

export const batchUpdatePointDownload = (query: { serial: string }) =>
  _fetch(`/api/batch/edgeDevice/point/update/download?${qs.stringify(query, { indices: false })}`).then(
    response => response.body
  );

export const bathUpdatePoint = (body: FormData): Promise<{ key: string }> => {
  const headers: Record<string, string> = {};
  const tenantMcid = body.get('tenantMcid');
  if (tenantMcid) {
    headers['x-tenant-mcid'] = (body.get('tenantMcid') ?? '') as string;
  }
  return _fetch(`/api/batch/edgeDevice/point/update/input`, {
    method: 'PUT',
    body: body,
    headers,
  }).then(response => response.json());
};

export const bathDeletePoint = (body: FormData): Promise<{ key: string }> => {
  const headers: Record<string, string> = {};
  const tenantMcid = body.get('tenantMcid');
  if (tenantMcid) {
    headers['x-tenant-mcid'] = (body.get('tenantMcid') ?? '') as string;
  }

  return _fetch(`/api/batch/edgeDevice/point/delete`, {
    method: 'DELETE',
    body: body,
    headers,
  }).then(response => response.json());
};

export const getBatchDeviceResult = (key: string): Promise<{ hasResult: boolean }> =>
  _fetch(`/api/batch/edgeDevice/result?key=${key}`, {
    method: 'GET',
  }).then(response => response.json());
