import { useRequest } from 'ahooks';
import { Button, Form, Input, Select, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import { useContext, useEffect, useMemo, useState } from 'react';
import { DataValueContext } from '..';
import { propertyDisplay, TemplateStatus } from '../../../api/type';
import { apiDataPropertyGroupTreeGet } from '../../../ytt/types/dataPropertyGroup/tree';

const SceneFormItems = () => {
  const [currentSceneId, setCurrentSceneId] = useState<number>();
  const { data: scenes } = useRequest(apiDataPropertyGroupTreeGet);
  const { nextStep, saveLoading, saveForm, data: details } = useContext(DataValueContext);
  const [form] = useForm();
  const groups = useMemo(() => {
    if (scenes) {
      return scenes.find(item => item.sceneId === currentSceneId)?.dataPropertyGroupList;
    }
  }, [currentSceneId, scenes]);
  const nextBtn = () => {
    form.validateFields().then(values => {
      saveForm?.({ ...values, save: true }).then(id => {
        if (id) {
          nextStep?.();
        }
      });
    });
  };
  useEffect(() => {
    if (details) {
      if (details.sceneId) {
        setCurrentSceneId(details.sceneId);
      }
      form.setFieldsValue({
        name: details.name,
        sceneId: details.sceneId,
        dataPropertyGroupId: details.dataPropertyGroupId,
        propertyType: details.propertyType,
        detail: details.detail,
      });
    }
  }, [details]);
  // 发布状态下, 场景/属性组/属性类型 不允许编辑
  const formTtemDisable = useMemo(() => {
    return typeof details?.status === 'number' && details?.status !== +TemplateStatus.DRAFT;
  }, [details]);
  return (
    <>
      <Form layout="vertical" form={form}>
        <Form.Item
          label="名称"
          name="name"
          rules={[{ required: true }, { pattern: /^.{2,50}$/, message: '限制2-50个字之间' }]}
        >
          <Input maxLength={50}></Input>
        </Form.Item>
        <Form.Item label="场景" name="sceneId" rules={[{ required: true }]}>
          <Select
            showSearch
            disabled={formTtemDisable}
            placeholder="请选择场景"
            optionFilterProp="label"
            onChange={v => {
              setCurrentSceneId(v);
              form.setFieldsValue({
                dataPropertyGroupId: undefined,
              });
            }}
            options={(scenes ?? []).map(v => ({ label: v.sceneName, value: v.sceneId }))}
          />
        </Form.Item>
        <Form.Item label="属性组" name="dataPropertyGroupId" rules={[{ required: true }]}>
          <Select
            showSearch
            disabled={formTtemDisable}
            placeholder="请选择属性组"
            optionFilterProp="label"
            options={(groups ?? []).map(v => ({ label: v.name, value: v.id }))}
          />
        </Form.Item>
        <Form.Item label="属性类型" name="propertyType" rules={[{ required: true }]}>
          <Select
            disabled={formTtemDisable}
            options={Object.entries(propertyDisplay).map(([k, v]) => ({
              label: v,
              value: +k,
            }))}
          />
        </Form.Item>
        <Form.Item label="说明" name="detail">
          <TextArea showCount maxLength={200} style={{ height: 120, resize: 'none' }} />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type="primary" onClick={nextBtn} loading={saveLoading}>
              下一步
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};
export default SceneFormItems;
