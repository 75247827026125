import { Cascader, Col, Form, FormInstance, Select, Radio, message } from 'antd';
import { useAsync } from '@maxtropy/components';
import {
  getDataProperties,
  getDeviceTypeTreeWithoutGatewayType,
  getMockingbirdIotProtocols,
  getPhysicalModelList,
  getRoot,
  PhysicalModelRes,
  Tenant,
} from '../../api/options';
import { formatOptionData } from '../CascadingMultipleSelector/utils';
import { DataPointType, DataProperty, DriveType, DriveTypeLabels, IotProtocolType } from '../../types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import DeviceSelect from './DeviceSelect';
import { BatchEdgeContent } from './contentTypes';
import {
  DataPointTypeDisplay,
  OperateType,
  OperateTypeDisplay,
  PointOperateType,
  PointOperateTypeDisplay,
} from './interface';
import CheckGroupWithAll from './CheckGroupWithAll';
import { useQuery } from '../../utils/utils';
import { InfoCircleOutlined } from '@ant-design/icons';
import PointCheckboxGroup from './PointCheckboxGroup';
import { isNil } from 'lodash-es';

const { Option } = Select;

const layout = {
  labelCol: { style: { width: 140 } },
  wrapperCol: { span: 12 },
};

const longItemLayout = {
  labelCol: { style: { width: 220 } },
  wrapperCol: { span: 24 },
};

export interface BatchEdgeFormOptions {
  dataProperties: { label: string; value: number }[];
}

interface BatchEdgeFormProps {
  form: FormInstance;
  onValuesChange?: (changedValues: any, values: any, options: BatchEdgeFormOptions) => void;
  onFieldsChange?: () => void;
  disabled?: boolean;
}

const BatchEdgeForm: React.FC<BatchEdgeFormProps> = props => {
  const { form, onValuesChange, disabled } = props;
  const { isMgmt } = useContext(BatchEdgeContent);

  const [customerData, setCustomerData] = useState<Tenant[]>([]);
  const deviceTypeData = useAsync(getDeviceTypeTreeWithoutGatewayType);
  const iotProtocolData = useAsync(getMockingbirdIotProtocols);
  const { deviceType, iotProtocol, objectModalType } = form.getFieldsValue();
  const [physicalModelList, setPhysicalModelList] = useState<PhysicalModelRes[]>([]); // all 物模型型号列表
  const [messageApi, messageContextHolder] = message.useMessage();

  useEffect(() => {
    if (isMgmt) {
      getRoot().then(setCustomerData);
    }
  }, [isMgmt]);

  useEffect(() => {
    if (deviceType) {
      // 物模型型号
      getPhysicalModelList({ deviceTypeIds: [deviceType?.slice(-1)] }).then(setPhysicalModelList);
    }
  }, [deviceType]);

  const objectModalTypeOptions = useMemo(() => {
    if (physicalModelList && physicalModelList.length > 0) {
      return physicalModelList.map(i => ({ label: `${i.modelNo}-${i.manufacturerName}`, value: i.id }));
    }
  }, [physicalModelList]);

  const { data: dataProperties = [] } = useQuery(
    useCallback(() => {
      const deviceTypeId = deviceType?.slice(-1);
      return deviceTypeId && iotProtocol
        ? getDataProperties(iotProtocol, deviceTypeId, objectModalType).then(data =>
            data.map(({ id, name }) => ({ label: name, value: id }))
          )
        : Promise.resolve([]);
    }, [iotProtocol, deviceType, objectModalType])
  );

  const onSelect = async (show: () => void) => {
    const { deviceType, tenantMcid, iotProtocol, driveType } = form.getFieldsValue();
    if (isMgmt) {
      if (deviceType && tenantMcid && !isNil(iotProtocol) && !isNil(driveType)) {
        show();
      } else {
        await messageApi.info('请选择所属类目、所属租户、物联层协议和驱动类型');
      }
    } else {
      if (deviceType && !isNil(iotProtocol) && !isNil(driveType)) {
        show();
      } else {
        await messageApi.info('请选择所属类目、物联层协议和驱动类型');
      }
    }
  };

  return (
    <Form
      style={{ width: '100%' }}
      {...layout}
      form={form}
      initialValues={{}}
      onValuesChange={(changedValues, values) => onValuesChange?.(changedValues, values, { dataProperties })}
    >
      {isMgmt && (
        <Form.Item name="tenantMcid" rules={[{ required: true, message: '请选择所属租户' }]} label="所属租户">
          <Select placeholder={'请选择租户'} disabled={disabled}>
            {customerData?.map(item => (
              <Option key={item.id} value={item.mcid}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item name="deviceType" rules={[{ required: true, message: '请选择所属类目' }]} label="所属类目">
        <Cascader
          options={formatOptionData(deviceTypeData)}
          allowClear={false}
          fieldNames={{ children: 'child' }}
          placeholder={'请选择所属类目'}
          disabled={disabled}
        />
      </Form.Item>

      <Form.Item name="objectModalType" label="物模型型号">
        <Select
          allowClear
          optionFilterProp="label"
          showSearch
          disabled={!form.getFieldValue('deviceType') || disabled}
          placeholder={form.getFieldValue('deviceType') ? '请选择' : '请先选择所属类目'}
          options={objectModalTypeOptions}
        />
      </Form.Item>

      <Form.Item name="iotProtocol" rules={[{ required: true, message: '请选择协议' }]} label="物联层协议">
        <Select<IotProtocolType> placeholder={'请选择协议'} disabled={disabled}>
          {iotProtocolData?.map(item => (
            <Select.Option key={item.id} value={item.id}>
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item noStyle shouldUpdate>
        {({ getFieldsValue }) => (
          <Form.Item label="驱动类型" name="driveType" rules={[{ required: true, message: '请选择驱动类型' }]}>
            <Select<DriveType> placeholder="请选择" disabled={disabled}>
              {(getFieldsValue().iotProtocol === IotProtocolType.MOCKINGBIRD
                ? Object.entries(DriveTypeLabels)
                : []
              ).map(([key, value]) => (
                <Select.Option key={key} value={Number(key)}>
                  {value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item name="deviceIds" rules={[{ required: true, message: '请选择设备' }]} label="设备选择">
        <DeviceSelect
          options={{ isMgmt, ...form.getFieldsValue(), rootMcid: form.getFieldsValue()?.tenantMcid }}
          onSelect={onSelect}
          disabled={disabled}
        />
      </Form.Item>

      <Form.Item noStyle shouldUpdate>
        {({ getFieldsValue }) => {
          return (
            <>
              {getFieldsValue()['deviceIds']?.length > 0 && (
                <Form.Item
                  name="operateType"
                  rules={[{ required: true, message: '请选择批操作项' }]}
                  label="批操作项"
                  initialValue={OperateType.DATA_POINT}
                >
                  <Radio.Group disabled={disabled}>
                    {Object.entries(OperateTypeDisplay).map(([key, value]) => (
                      <Radio.Button key={key} value={Number(key)}>
                        {value}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </Form.Item>
              )}
              {getFieldsValue()['operateType'] === OperateType.DATA_POINT && (
                <Form.Item
                  name="pointOperateType"
                  rules={[{ required: true, message: '请选择批操作类型' }]}
                  label="批操作类型"
                  initialValue={PointOperateType.UPDATE}
                >
                  <Radio.Group disabled={disabled}>
                    {Object.entries(PointOperateTypeDisplay).map(([key, value]) => (
                      <Radio key={key} value={Number(key)}>
                        {value}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              )}
              {getFieldsValue()['operateType'] === OperateType.DATA_POINT &&
                getFieldsValue()['pointOperateType'] === PointOperateType.UPDATE && (
                  <Form.Item
                    name="pointType"
                    rules={[{ required: true, message: '请选择数据点类型' }]}
                    label="数据点类型"
                    initialValue={DataPointType.BASE_POINT}
                  >
                    <Radio.Group disabled={disabled}>
                      {Object.entries(DataPointTypeDisplay).map(([key, value]) => (
                        <Radio key={key} value={Number(key)}>
                          {value}
                        </Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>
                )}
              {getFieldsValue()['operateType'] === OperateType.DATA_POINT &&
                getFieldsValue()['pointOperateType'] === PointOperateType.UPDATE &&
                getFieldsValue()['pointType'] !== DataPointType.STATIC_POINT && (
                  <Form.Item
                    name="hasProperty"
                    rules={[{ required: true, message: '请选择是否建模' }]}
                    label="是否建模"
                    initialValue={true}
                  >
                    <Radio.Group disabled={disabled}>
                      <Radio value={true}>是</Radio>
                      <Radio value={false}>否</Radio>
                    </Radio.Group>
                  </Form.Item>
                )}
              {getFieldsValue()['deviceType']?.length > 0 && getFieldsValue()['hasProperty'] && (
                <Form.Item
                  {...longItemLayout}
                  name="dataPropertyIds"
                  rules={[{ required: true, message: '请选择需要修改的数据属性' }]}
                  label="选择需要修改的数据属性"
                  tooltip={{
                    title: (
                      <span>
                        1、数据点中的数据属性，为所选择类目下的全部数据属性。
                        <br />
                        2、若选择的设备中，现有的数采点，并没有使用所选择的数据属性，则该数据属性不会该设备产生影响。
                      </span>
                    ),
                    icon: <InfoCircleOutlined style={{ color: 'var(--primary-color)' }} />,
                  }}
                >
                  <CheckGroupWithAll options={dataProperties} disabled={disabled} />
                </Form.Item>
              )}
              {getFieldsValue()['driveType'] !== undefined &&
                getFieldsValue()['pointType'] === DataPointType.BASE_POINT && (
                  <Form.Item
                    {...longItemLayout}
                    name="parameter"
                    rules={[{ required: true, message: '请选择需要修改的数据点参数' }]}
                    label="需要修改的数据点参数"
                    tooltip={{
                      title: (
                        <span>
                          1、其他参数不通过excel导入修改，请在下方数据点列表中进行修改。
                          <br />
                          2、数据点参数与不同的驱动类型有关。
                          <br />
                          3、如果只选择了其他参数，则不需要下载模版。
                        </span>
                      ),
                      icon: <InfoCircleOutlined style={{ color: 'var(--primary-color)' }} />,
                    }}
                  >
                    <PointCheckboxGroup disabled={disabled} />
                  </Form.Item>
                )}
            </>
          );
        }}
      </Form.Item>
      {messageContextHolder}
    </Form>
  );
};

export default BatchEdgeForm;
