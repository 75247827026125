import { FC } from 'react';
import { Form, Input, Row, Col, InputNumber } from 'antd';
import { FactorPropertyEnum } from '../emissionProperty';
const FormItem = Form.Item;

interface Props {
  // 开始序号
  start: number;
}
const ExtraFactor: FC<Props> = ({ start }) => {
  return (
    <>
      <Row gutter={16}>
        <Col span={8}>
          <FormItem label="CH4排放因子" name={['factorDetails', `${FactorPropertyEnum.CH4}_${start}`]}>
            <InputNumber placeholder="请输入数值" style={{ width: '100%' }} />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            label="单位"
            name={['factorDetails', `${FactorPropertyEnum.CommonUnit}_${start}`]}
            rules={[{ max: 50, message: '长度不能大于50个字符' }]}
          >
            <Input placeholder="请输入" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <FormItem label="N2O排放因子" name={['factorDetails', `${FactorPropertyEnum.N2O}_${start + 1}`]}>
            <InputNumber placeholder="请输入数值" style={{ width: '100%' }} />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            label="单位"
            name={['factorDetails', `${FactorPropertyEnum.CommonUnit}_${start + 1}`]}
            rules={[{ max: 50, message: '长度不能大于50个字符' }]}
          >
            <Input placeholder="请输入" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <FormItem label="HFCs排放因子" name={['factorDetails', `${FactorPropertyEnum.HFCs}_${start + 2}`]}>
            <InputNumber placeholder="请输入数值" style={{ width: '100%' }} />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            label="单位"
            name={['factorDetails', `${FactorPropertyEnum.CommonUnit}_${start + 2}`]}
            rules={[{ max: 50, message: '长度不能大于50个字符' }]}
          >
            <Input placeholder="请输入" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <FormItem label="PFCs排放因子" name={['factorDetails', `${FactorPropertyEnum.PFCs}_${start + 3}`]}>
            <InputNumber placeholder="请输入数值" style={{ width: '100%' }} />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            label="单位"
            name={['factorDetails', `${FactorPropertyEnum.CommonUnit}_${start + 3}`]}
            rules={[{ max: 50, message: '长度不能大于50个字符' }]}
          >
            <Input placeholder="请输入" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <FormItem label="SF6排放因子" name={['factorDetails', `${FactorPropertyEnum.SF6}_${start + 4}`]}>
            <InputNumber placeholder="请输入数值" style={{ width: '100%' }} />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            label="单位"
            name={['factorDetails', `${FactorPropertyEnum.CommonUnit}_${start + 4}`]}
            rules={[{ max: 50, message: '长度不能大于50个字符' }]}
          >
            <Input placeholder="请输入" />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <FormItem label="NF3排放因子" name={['factorDetails', `${FactorPropertyEnum.NF3}_${start + 5}`]}>
            <InputNumber placeholder="请输入数值" style={{ width: '100%' }} />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            label="单位"
            name={['factorDetails', `${FactorPropertyEnum.CommonUnit}_${start + 5}`]}
            rules={[{ max: 50, message: '长度不能大于50个字符' }]}
          >
            <Input placeholder="请输入" />
          </FormItem>
        </Col>
      </Row>
    </>
  );
};

export default ExtraFactor;
