import { Tooltip, Modal } from 'antd';
import { PlusSquareOutlined, DeleteOutlined } from '@ant-design/icons';
import { OperationProp } from '../types';
import styles from '../index.module.scss';

type Prop = {
  operation: OperationProp;
  nodeData: any;
  disabled: boolean;
};

const TitleNode = ({ operation, nodeData, disabled }: Prop) => {
  const [modalApi, modalContextHolder] = Modal.useModal();
  const { id, title, level = 1 } = nodeData;

  return (
    <div className={styles.treeNode}>
      <span className={styles.treeLabel}>{title}</span>
      <div className={styles.ar}>
        {level < 3 && disabled && (
          <span
            className={styles.add}
            onClick={e => {
              e.stopPropagation();
              operation.onAdd(id, title);
            }}
          >
            <Tooltip placement="top" title="新建">
              <PlusSquareOutlined />
            </Tooltip>
          </span>
        )}
        {level !== 1 && disabled && (
          <span
            className={styles.remove}
            onClick={e => {
              e.stopPropagation();
              modalApi.confirm({
                title: '是否删除权限？',
                onOk() {
                  operation.onRemove(id);
                },
              });
            }}
          >
            <Tooltip placement="top" title="删除">
              <DeleteOutlined />
            </Tooltip>
          </span>
        )}
      </div>
      {modalContextHolder}
    </div>
  );
};

export default TitleNode;
