import React, { Key, useEffect, useState } from 'react';
import { RoleSteps } from '../../../components/CommonSteps';
import { Button, Checkbox, Col, Form, Input, Modal, Radio, Row } from 'antd';
import styles from './index.module.scss';
import TenantSelect from '../TenantSelect';
import { useUpdate } from 'ahooks';
import { Wrapper, useAsync } from '@maxtropy/components';
import { commonNameRegex } from '../../../utils/regex';
import {
  checkUserNameUniqueness,
  createRole,
  findRoleByCode,
  getIntegratedAppList,
  RoleInfo,
  updateRole,
} from '../../../api/cc-role';
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';
import { useNavigate, useParams } from 'react-router-dom';
import usePageStatus, { PageStatus, PageStatusDisplay } from './usePageStatus';
import { RoleStatus, RoleStatusDisplay, RoleType, RoleTypeDisplay } from '../../../api/cc-const';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailFormItemLayout = {
  wrapperCol: {
    span: 16,
    offset: 8,
  },
};

const Platform = 'platform';

interface EditRoleProps {}

const EditRole: React.FC<EditRoleProps> = props => {
  const [modalApi, modalContextHolder] = Modal.useModal();
  const [form] = Form.useForm();
  const { code } = useParams<{ code: string }>();
  const pageStatus = usePageStatus();
  const update = useUpdate();
  const [role, setRole] = useState<RoleInfo>();
  const navigate = useNavigate();
  const [submitLoading, setSubmitLoading] = useState(false);

  const integratedAppList = useAsync(getIntegratedAppList, []);

  useEffect(() => {
    if (!code) return;
    if (pageStatus !== PageStatus.CREATE) {
      findRoleByCode(code).then(data => {
        setRole(data);
        form.setFieldsValue({
          name: data.name,
          type: data.type,
          tenantReVoList:
            data.type === RoleType.SPECIALPURPOSE
              ? (data.tenantReVoList ?? []).map(item => ({ label: item.name, value: item.mcid }))
              : [],
          state: data.state,
          integratedAppIdList: ((data.roleIntegratedAppList ?? []).map(i => i.id) as Key[]).concat(Platform),
        });
        update();
      });
    }
  }, [form, code, pageStatus, update]);

  const routes = [
    { name: '租户管理' },
    { name: '租户组织权限和账户' },
    { name: '预置角色管理' },
    { name: `${PageStatusDisplay[pageStatus!]}预置角色` },
  ];

  const onFinish = (values: any) => {
    setSubmitLoading(true);
    let body = {
      ...values,
      integratedAppIdList: (values.integratedAppIdList ?? []).filter((i: Key) => i !== Platform),
      ...(values.type === RoleType.SPECIALPURPOSE && {
        tenantReVoList: values.tenantReVoList.map((item: any) => ({ mcid: item.value, name: item.label })),
      }),
    };
    if (pageStatus === PageStatus.CREATE) {
      createRole(body)
        .then(data => {
          navigate(`/tenant/customer/prefab-role/permissions/update/${data.code}`);
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    } else {
      body = { ...body, id: role?.id, code: role?.code };
      updateRole(body)
        .then(data => {
          navigate(`/tenant/customer/prefab-role/permissions/update/${role?.code}`);
        })
        .finally(() => {
          setSubmitLoading(false);
        });
    }
  };

  const onFinishFailed = (errorInfo: any) => {};

  const nameValidator = (_: RuleObject, name: StoreValue) => {
    if (!commonNameRegex({ start: 0, end: 12 }).test(name)) {
      return Promise.reject(new Error('角色名称仅支持汉字、数字、字母'));
    } else {
      return Promise.resolve();
    }
  };

  const nameUniquenessValidator = (_: RuleObject, name: StoreValue) => {
    return checkUserNameUniqueness(role?.id ? { name, id: String(role.id) } : { name })
      .onError(error => {
        throw Promise.reject(new Error(error.errorMessage));
      })
      .then(response => {
        if (!response) {
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('该名称已存在'));
        }
      });
  };

  const changeRoute = () => {
    navigate(`/tenant/customer/prefab-role/permissions/detail/${role?.code}`);
  };

  const disabled = pageStatus === PageStatus.DETAIL;

  const onClose = () => {
    if (!disabled) {
      modalApi.confirm({
        icon: null,
        content: '确认放弃未保存的修改返回列表吗?',
        onOk: () => {
          navigate(`/tenant/customer/prefab-role`);
        },
      });
    } else {
      navigate(`/tenant/customer/prefab-role`);
    }
  };

  return (
    <Wrapper routes={routes} className={styles.roleWrapper}>
      <RoleSteps current={0} />
      <Row className={styles.formWrapper}>
        <Col span={16} offset={4}>
          <Form
            // className={styles.form}
            {...layout}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            validateTrigger={['onBlur']}
            form={form}
          >
            {pageStatus !== PageStatus.CREATE && (
              <>
                <Form.Item label="角色模板ID">
                  <span>{role?.id}</span>
                </Form.Item>

                <Form.Item label="角色模板CODE">
                  <span>{role?.code}</span>
                </Form.Item>
              </>
            )}

            <Form.Item
              label="角色名称"
              name="name"
              validateFirst
              normalize={value => (value ?? '').replaceAll(' ', '')}
              rules={[
                { required: true, message: '请输入角色名称!' },
                { type: 'string', max: 12 },
                { validator: nameValidator },
                { validator: nameUniquenessValidator },
              ]}
            >
              <Input placeholder="请输入角色名称，最多12字" disabled={disabled} style={{ width: 300 }} />
            </Form.Item>

            <Form.Item label="是否专用" name="type" initialValue={RoleType.GENERAL}>
              <Radio.Group onChange={update} disabled={disabled}>
                <Radio value={RoleType.GENERAL}>{RoleTypeDisplay[RoleType.GENERAL]}</Radio>
                <Radio value={RoleType.SPECIALPURPOSE}>{RoleTypeDisplay[RoleType.SPECIALPURPOSE]}</Radio>
              </Radio.Group>
            </Form.Item>

            {form.getFieldValue('type') === RoleStatus.FREEZE && (
              <Form.Item
                label="设置指定使用租户"
                name="tenantReVoList"
                validateFirst
                rules={[{ required: true, message: '请输入至少1个租户' }]}
                initialValue={[]}
                validateTrigger={['onChange']}
              >
                <TenantSelect disabled={disabled} />
              </Form.Item>
            )}
            <Form.Item
              label="角色状态"
              name="state"
              validateFirst
              rules={[{ required: true, message: '请选择角色状态' }]}
            >
              <Radio.Group disabled={disabled}>
                <Radio value={RoleStatus.ENABLE}>{RoleStatusDisplay[RoleStatus.ENABLE]}</Radio>
                <Radio value={RoleStatus.FREEZE}>{RoleStatusDisplay[RoleStatus.FREEZE]}</Radio>
                {pageStatus !== PageStatus.CREATE && (
                  <Radio value={RoleStatus.INVALID}>{RoleStatusDisplay[RoleStatus.INVALID]}</Radio>
                )}
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="角色应用范围"
              name="integratedAppIdList"
              validateFirst
              initialValue={[Platform]}
              rules={[{ required: true, message: '请选择角色应用范围' }]}
            >
              <Checkbox.Group disabled={disabled} style={{ display: 'flex', width: '140%' }} onChange={() => {}}>
                <Row>
                  <Col sm={24} md={12} lg={8} xl={6}>
                    <Checkbox disabled value={Platform}>
                      平台
                    </Checkbox>
                  </Col>
                  {integratedAppList.map(i => (
                    <Col sm={24} md={12} lg={8} xl={6} key={i.name}>
                      <Checkbox key={i.id} value={i.id}>
                        {i.name}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button
                loading={submitLoading}
                type="primary"
                htmlType={disabled ? 'button' : 'submit'}
                {...(disabled ? { onClick: changeRoute } : {})}
              >
                下一步
              </Button>
              <Button style={{ marginLeft: 20 }} onClick={onClose}>
                关闭
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      {modalContextHolder}
    </Wrapper>
  );
};

export default EditRole;
