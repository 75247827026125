import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Popover, Row, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataValueContext } from '..';
import { TemplateStatus } from '../../../api/type';
import { ApiDataRulePostRequest } from '../../../ytt/types/api/dataRule';

const AccumulateFormItems = () => {
  const { prevStep, saveForm, data: details, saveLoading } = useContext(DataValueContext);
  const [form] = useForm<ApiDataRulePostRequest>();
  const navigate = useNavigate();
  const nextBtn = (save = true) => {
    form.validateFields().then(values => {
      saveForm?.({ ...values, save }).then(id => {
        if (id) {
          navigate(-1);
        }
      });
    });
  };
  useEffect(() => {
    form.setFieldsValue({
      magnificationCumulative: details?.magnificationCumulative,
      cumulativeStartName: details?.cumulativeStartName,
      cumulativeStartValue: details?.cumulativeStartValue ?? 0,
    });
  }, [details]);
  return (
    <>
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          cumulativeStartValue: 0,
        }}
      >
        <Row gutter={15}>
          <Col span={24}>
            <Form.Item
              label={
                <>
                  <span>瞬时量算累积量倍率 &nbsp;</span>
                  <Popover
                    content={
                      <div style={{ width: 400 }}>
                        <div>累积量计算公式：</div>
                        <div>如果导入的瞬时量值为【A1，A2，A3，A4，….，An】</div>
                        <div>累积量Cn=(A1+A2+…An)*瞬时量算累积量倍率</div>
                        <div>瞬时量算累积量倍率确定方式如下：</div>
                        <div>例1:如果瞬时量的单位是w，数据点间隔是1小时，累积量的单位是KWh，则倍率为0.001；</div>
                        <div>例2：如果瞬时量的单位是Kw，数据点间隔是15分钟，累积量的单位是KWh，则倍率为0.25。</div>
                      </div>
                    }
                    trigger="hover"
                  >
                    <ExclamationCircleOutlined style={{ color: '#FDD300' }} />
                  </Popover>
                </>
              }
              name="magnificationCumulative"
              rules={[{ required: true, message: '请输入' }]}
            >
              <InputNumber style={{ width: '100%' }}></InputNumber>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={15}>
          <Col span={12}>
            <Form.Item
              label="累积量起始值"
              name="cumulativeStartName"
              rules={[{ required: true }, { pattern: /^.{2,10}$/, message: '限制2-10个字之间' }]}
            >
              <Input></Input>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="默认值" name="cumulativeStartValue" rules={[{ required: true }]}>
              <InputNumber style={{ width: '100%' }}></InputNumber>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space>
            <Button type="primary" onClick={() => nextBtn(true)} loading={saveLoading}>
              发布
            </Button>
            <Button onClick={prevStep}>上一步</Button>
            {/* 发布状态下不显示保存草稿 */}
            {details?.status !== +TemplateStatus.PUBLISH && <Button onClick={() => nextBtn(false)}>保存草稿</Button>}
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};
export default AccumulateFormItems;
