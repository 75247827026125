export enum CardSize {
  // 1 =  小
  SMALL = 1,
  // 2 = 中
  MIDDLE = 2,
  // 3 = 大
  LARGE = 3,
}

export const CardSizeDisplay = {
  [CardSize.SMALL]: '小尺寸',
  [CardSize.MIDDLE]: '中尺寸',
  [CardSize.LARGE]: '大尺寸',
};

export interface List {
  isUse?: boolean;
  /**
   * 自增主键
   */
  id?: number;

  cardSize?: number;
  /**
   * 工具名称
   */
  name?: string;
  /**
   * 工具分类id
   */
  categoryId?: number;
  /**
   * 工具分类名称
   */
  categoryName?: string;
  /**
   * 工具路径(git仓库地址)
   */
  gitRepoUrl?: string;
  /**
   * 工具图标（fileCenter key）
   */
  icon?: string;
  /**
   * 工具路径
   */
  entryPath?: string;
  /**
   * 引用数量
   */
  refTime?: number;
  /**
   * 工具类型：1 自研、2 isv (自研不需要服务商id和集成应用id)
   */
  type?: number;
  /**
   * 服务商id
   */
  vendorId?: number;
  /**
   * 集成应用id
   */
  integratedAppId?: number;
  /**
   * 工具简介
   */
  description?: string;
  /**
   * 是否支持小卡片：0  不支持、1 支持
   */
  cardSupport?: number;
  /**
   * 创建时间
   */
  createTime?: {
    /**
     * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
     */
    seconds?: number;
    /**
     * The number of nanoseconds, later along the time-line, from the seconds field.
     * This is always positive, and never exceeds 999,999,999.
     */
    nanos?: number;
  };
  /**
   * 更新时间
   */
  updateTime?: {
    /**
     * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
     */
    seconds?: number;
    /**
     * The number of nanoseconds, later along the time-line, from the seconds field.
     * This is always positive, and never exceeds 999,999,999.
     */
    nanos?: number;
  };
}

export interface CardLayout {
  /**
   * A string corresponding to the component key.
   * Uses the index of components instead if not provided.
   */
  i: string;

  cardSize?: CardSize;
  className?: string;
  name?: string;
  /**
   * X position in grid units.
   */
  x: number;

  /**
   * Y position in grid units.
   */
  y: number;

  /**
   * Width in grid units.
   */
  w: number;

  /**
   * Height in grid units.
   */
  h: number;

  /**
   * Minimum width in grid units.
   */
  minW?: number | undefined;

  /**
   * Maximum width in grid units.
   */
  maxW?: number | undefined;

  /**
   * Minimum height in grid units.
   */
  minH?: number | undefined;

  /**
   * Maximum height in grid units.
   */
  maxH?: number | undefined;

  /**
   * set by DragEvents (onDragStart, onDrag, onDragStop) and ResizeEvents (onResizeStart, onResize, onResizeStop)
   */
  moved?: boolean | undefined;

  /**
   * If true, equal to `isDraggable: false` and `isResizable: false`.
   */
  static?: boolean | undefined;

  /**
   * If false, will not be draggable. Overrides `static`.
   */
  isDraggable?: boolean | undefined;

  /**
   * If false, will not be resizable. Overrides `static`.
   */
  isResizable?: boolean | undefined;

  /**
   * By default, a handle is only shown on the bottom-right (southeast) corner.
   * Note that resizing from the top or left is generally not intuitive.
   */

  /**
   * If true and draggable, item will be moved only within grid.
   */
  isBounded?: boolean | undefined;
  picKey?: string | undefined;
}
