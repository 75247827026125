import { Wrapper } from '@maxtropy/components';
import { Button, Col, Form, Row } from 'antd';
import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getApplicationDetail } from '../../../../api/openPlatform-application';
import ShowInput from '../../../../components/ShowInput';
import { ApplicationSteps } from '../../CommonSteps';
import { VisibleRangeType } from '../AppAuthStepTwo/components/InterfaceAuth';
import AppSecret from './AppSecret';

const routes = [
  { name: '开发者平台' },
  { name: '应用开发开放平台' },
  { name: '集成应用列表' },
  { name: '创建应用' },
  { name: '完成' },
];

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const FinishStepThree: FC = () => {
  let { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // 查询详情回想
  useEffect(() => {
    if (id) {
      getApplicationDetail(id).then(res => {
        form.setFieldsValue({
          independentSoftwareVendorName: res.independentSoftwareVendorName,
          appKey: res.appKey,
          name: res.name,
          appSecret: res.appSecret,
          interfaceNumber: res.apiAuthorityType === VisibleRangeType.ALL ? '全部' : res.apiAuthorities?.length ?? '--',
          tenantNumber:
            res.tenantAuthorityType === VisibleRangeType.ALL ? '全部' : res.tenantAuthorities?.length ?? '--',
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleReturnStepTwo = () => {
    if (id) {
      navigate(`/open-platform/open-platform/app/updateAuth/${id}`);
    }
  };

  const handleReturnToList = () => {
    navigate('/open-platform/open-platform/app');
  };

  return (
    <Wrapper routes={routes} className="open-platform-common-wrapper">
      <ApplicationSteps current={2} />
      <Form {...layout} form={form} name="app-form" layout="vertical">
        <Row style={{ marginLeft: '20%' }}>
          <Col span={8}>
            <Form.Item name="independentSoftwareVendorName" label="服务商">
              <ShowInput />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="appKey" label="应用ID">
              <ShowInput />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="name" label="应用名称">
              <ShowInput />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="appSecret" label="秘钥">
              <AppSecret />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="interfaceNumber" label="接口权限数">
              <ShowInput />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="tenantNumber" label="租户权限数">
              <ShowInput />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginLeft: '20%' }}>
          <Button type="primary" onClick={handleReturnToList}>
            返回列表
          </Button>
          <Button style={{ marginLeft: 20 }} onClick={handleReturnStepTwo}>
            返回上一步
          </Button>
        </Row>
      </Form>
    </Wrapper>
  );
};

export default FinishStepThree;
