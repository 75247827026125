import { createFromIconfontCN } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import styles from '../index.module.scss';

interface IconGroupProps {
  data: Record<string, React.ReactNode>;
  onChange?: (str: string) => void;
  value?: string;
}

const MyIcon = createFromIconfontCN({
  extraCommonProps: {
    size: 32,
  },
  scriptUrl: '//at.alicdn.com/t/c/font_4141648_fy5c9d5azul.js', // 在 iconfont.cn 上生成
});

export const miniAppIconMap: Record<string, React.ReactNode> = {
  alert: <MyIcon type="icon-alert" />,
  'file-text': <MyIcon type="icon-file-text" />,
  setting: <MyIcon type="icon-setting" />,
  edit: <MyIcon type="icon-edit" />,
  fund: <MyIcon type="icon-fund" />,
  'pie-chart': <MyIcon type="icon-pie-chart" />,
  signal: <MyIcon type="icon-signal" />,
  'file-unknown': <MyIcon type="icon-file-unknown" />,
  home: <MyIcon type="icon-home" />,
  'app-store': <MyIcon type="icon-app-store" />,
};

const IconGroup = ({ onChange, value, data }: IconGroupProps) => {
  const [currentValue, setCurrentValue] = useState<string | undefined>('icon-alert-fill');
  const onclick = (str: string) => {
    setCurrentValue(str);
    onChange?.(str);
  };
  useEffect(() => {
    setCurrentValue(value);
  }, [value]);
  return (
    <>
      <div className={styles.icon_box}>
        {Object.entries(data).map(([key, value]) => {
          return (
            <div
              className={`${styles.icon_box_item} ${currentValue === key && styles.active}`}
              key={key}
              onClick={() => onclick(key)}
            >
              {value}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default IconGroup;
