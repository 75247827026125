import { CheckOutlined, CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Cascader, Form, InputNumber, Modal, Select, Space, Switch } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useMemo, useState } from 'react';
import styles from '../index.module.scss';
import { apiDeviceGet, ApiDeviceGetResponse } from '../../../ytt/types/api/device';
import { DefaultOptionType } from 'antd/es/cascader';
import { RulesSetIdGetResponse } from '../../../ytt/types/rulesSet/id';
import {
  apiDataPropertyListByDeviceTypeRulesIdGet,
  DataPropertyListByDeviceTypeRulesIdGetResponse,
} from '../../../ytt/types/dataProperty/listByDeviceTypeRulesId';
import { apiRulesSetIdDevicesPost } from '../../../ytt/types/id/devices';
import { apiRulesSetDevicesIdPut } from '../../../ytt/types/devices/id';
import { PropertyType } from '../../../api/type';
import { useRequest } from 'ahooks';
import { useAsync } from '@maxtropy/components';
import { getDeviceTypeData } from '@/api/attribute';
import { getDeviceList } from '@/api/mockDevice';

interface Iprops {
  detail: RulesSetIdGetResponse;
  deviceId?: number;
  onCancel?: () => void;
  onConfirm?: () => void;
  opType: string;
}

interface DeviceTypeTree {
  aliasName: Array<string>;
  tree: Array<DeviceTypes>;
}

interface CascadingMultipleData {
  label: string;
  value: number;
  child: Array<CascadingMultipleData> | undefined;
}

interface DeviceTypes {
  id: number;
  name: string;
  allowAttribute?: boolean;
  children?: Array<DeviceTypes>;
  deviceTypes?: Array<DeviceTypes>;
  parentId?: number;
  parentName?: string;
  rootId?: number;
}

const BindDeviceModal = (props: Iprops) => {
  const { detail, deviceId, onCancel, onConfirm, opType } = props;
  const [modalApi, modalContextHolder] = Modal.useModal();
  const [form] = useForm();
  const [deviceList, setDeviceList] = useState<ApiDeviceGetResponse>([]);
  const [propertyList, setPropertyList] = useState<DataPropertyListByDeviceTypeRulesIdGetResponse>([]);
  const [deviceTypeId, setDeviceTypeId] = useState<string>();
  const deviceTypeData = useAsync(getDeviceTypeData);

  // 编辑回显
  useEffect(() => {
    if (deviceTypeData && deviceId) {
      const currentDevice = detail?.deviceList?.find(i => i.id === deviceId);
      setDeviceTypeId(String(currentDevice?.deviceTypeId));
      form.setFieldsValue({
        ...currentDevice,
        cumulativeStartValue: currentDevice?.cumulativeStartValue ?? detail.cumulativeStartValue,
        yMagnificationValue: currentDevice?.yMagnificationValue ?? detail.yMagnificationValue,
        deviceTypeId: currentDevice?.deviceTypeList,
        dataPropertyList: currentDevice?.dataPropertyList?.map(i => i.id),
        deviceList: currentDevice?.deviceList?.map(i => i.id),
      });
    }
  }, [deviceId, detail?.id, deviceTypeData]);

  useEffect(() => {
    if (!deviceTypeId) return;
    apiDataPropertyListByDeviceTypeRulesIdGet({ deviceTypeId, rulesId: String(detail.dataRuleId) }).then(res => {
      setPropertyList(res ?? []);
      if (opType === 'add') {
        form.setFieldsValue({
          dataPropertyList: res?.map(i => i.id),
        });
      }
    });
  }, [deviceTypeId]);

  const bindDevice = () => {
    form.validateFields().then(values => {
      if (deviceId) {
        apiRulesSetDevicesIdPut({ ...values, deviceTypeId, id: deviceId }).then(() => {
          onConfirm?.();
        });
      } else {
        apiRulesSetIdDevicesPost({ ...values, deviceTypeId, id: detail.id }).then(() => {
          onConfirm?.();
        });
      }
    });
  };

  const onDeviceTypeSelect = (val: Array<string | number>, selectedOptions: DefaultOptionType[]) => {
    form.setFieldsValue({ deviceList: [] });
    setSearchStr('');
    const currentTypeId = val[val.length - 1];
    setDeviceTypeId(String(currentTypeId));
  };

  const formatOptionData = (response: DeviceTypeTree | undefined): Array<CascadingMultipleData> | undefined => {
    const loop = (data: Array<DeviceTypes>): Array<CascadingMultipleData> => {
      return data.map(item => ({
        label: item.name,
        value: item.id,
        child: item.deviceTypes || item.children ? loop(item.deviceTypes || item.children!) : undefined,
      }));
    };
    if (!response) return undefined;
    return loop(response.tree);
  };

  const [searchStr, setSearchStr] = useState<string>();
  const handleSearch = (newValue: string) => {
    setSearchStr(newValue);
  };
  const { data: searchData, loading: searchLoading } = useRequest(
    () => {
      return getDeviceList({ deviceTypeId: deviceTypeId, tenantMcid: detail?.mcid!, name: searchStr }).then(res => {
        return (res ?? []).splice(0, 20);
      });
    },
    {
      debounceWait: 300,
      refreshDeps: [searchStr, deviceTypeId],
      ready: !!deviceTypeId && !!detail?.mcid,
    }
  );

  const tempDeviceList = useMemo(() => {
    // 编辑
    if (deviceTypeData && deviceId) {
      const currentDevice = detail?.deviceList?.find(i => i.id === deviceId);
      let currentList = currentDevice?.deviceList ?? [];
      let hasExist = currentList.map(item => item.id);
      if (searchData && searchData.length > 0) {
        let temp = searchData.filter(item => !hasExist.includes(item.id));
        currentList = [...currentList, ...temp] as any;
      } else {
        currentList = [];
      }
      return currentList;
    } else {
      return searchData ?? [];
    }
  }, [searchData, deviceTypeData, deviceId]);
  return (
    <>
      <Modal
        title="绑定设备"
        open
        width={600}
        closable={false}
        onCancel={() => {
          modalApi.confirm({
            title: '您是否确认放弃录入的信息？',
            onOk() {
              onCancel?.();
            },
            onCancel() {},
          });
        }}
        onOk={() => {
          bindDevice();
        }}
      >
        <div className={styles.modal_box}>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              unifiedCompute: true,
              yMagnificationValue: detail.yMagnificationValue,
              cumulativeStartValue: detail.cumulativeStartValue,
            }}
          >
            <Form.Item label="选择类目" name="deviceTypeId" rules={[{ required: true }]}>
              <Cascader
                options={formatOptionData(deviceTypeData as DeviceTypeTree)}
                allowClear={false}
                fieldNames={{ children: 'child' }}
                placeholder={'请选择所属类目'}
                onChange={onDeviceTypeSelect}
              />
            </Form.Item>
            <Form.Item label="选择设备" name="deviceList" rules={[{ required: true }]}>
              <Select
                mode="multiple"
                showSearch
                defaultActiveFirstOption={false}
                showArrow={false}
                placeholder="请输入关键字搜索"
                onSearch={handleSearch}
                filterOption={false}
                loading={searchLoading}
                // notFoundContent={searchLoading ? <Spin size="small" /> : <div>暂无数据</div>}
                options={tempDeviceList.map(i => ({ label: i.name, value: i.id }))}
              ></Select>
            </Form.Item>
            <Form.Item label="选择数据属性" name="dataPropertyList" rules={[{ required: true }]}>
              <Select mode="multiple" options={propertyList.map(i => ({ label: i.name, value: i.id }))}></Select>
            </Form.Item>
            {/* 仅累积量显示, 瞬时量不显示 */}
            {detail.propertyType === PropertyType.ACCUMULATE && (
              <Form.Item label={detail.cumulativeStartName} name="cumulativeStartValue" rules={[{ required: true }]}>
                <InputNumber></InputNumber>
              </Form.Item>
            )}

            <Form.Item label={detail.yMagnificationName} name="yMagnificationValue" rules={[{ required: true }]}>
              <InputNumber></InputNumber>
            </Form.Item>
            <Form.Item
              valuePropName="checked"
              label={
                <Space>
                  统一计算
                  <InfoCircleOutlined style={{ color: 'var(--primary-color)' }} />
                  <span>选中后，所有数据点将同时上传同样的数据</span>
                </Space>
              }
              name="unifiedCompute"
            >
              <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      {modalContextHolder}
    </>
  );
};

export default BindDeviceModal;
