import { Wrapper } from '@maxtropy/components';
import DeviceBatchEdge from '@/shared/components/DeviceBatchEdge';

const routes = [{ name: 'Iot配置' }, { name: '设备管理' }, { name: '设备管理' }, { name: '批量修改数采信息' }];

const BatchUpdateEdge: React.FC = () => {
  return (
    <Wrapper routes={routes}>
      <DeviceBatchEdge isMgmt={true} />
    </Wrapper>
  );
};

export default BatchUpdateEdge;
