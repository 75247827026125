import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, message, Row, Select, Space } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { getIntegratedAppList, IntegratedAppResponse } from '../../../api/cc-integrated-app';
import { QlDetailProps, TagItemProps } from '../../../api/cc-tenant-ql';

import ListTableForm, { RefListTableProps } from '../../../components/ListTableForm';
import AppPermissionSelect from './AppPermissionSelect';
import FourLevelPermissionSelect from './FourLevelPermissionSelect';
import QflowAppTagSelect from './QflowAppTagSelect';
import QflowAppPageSelect from './QflowAppPageSelect';
import { dependentRenderCols, renderCols } from '../common';
const { Option } = Select;

interface Iprops {
  isSet?: boolean;
  mcid?: string;
  hasSelectAppIds?: string[];
  initData?: QlDetailProps;
  tagList?: TagItemProps[];
  appIdOnChange?: (val?: string) => void;
}

const QlModuleItem: React.FC<Iprops> = ({ isSet, mcid, tagList, initData, hasSelectAppIds, appIdOnChange }) => {
  const [messageApi, messageContextHolder] = message.useMessage();
  const listTableRef = useRef<RefListTableProps>({});

  const form = Form.useFormInstance();
  const [integratedApps, setApps] = useState<IntegratedAppResponse[]>([]);
  // 已经选择的三级应用功能权限
  const [selectsAppPermission, setSelectsAppPermission] = useState<string[]>([]);
  useEffect(() => {
    if (isSet && initData) {
      let { integrateAppModules } = initData;
      let selects = selectsAppPermission;
      integrateAppModules?.forEach((item: any) => {
        let temp = item.permissionCode;
        if (temp && temp[2]) {
          selects.push(temp[2]);
        }
      });
      setSelectsAppPermission(selects);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSet, initData]);
  // 获取集成应用列表
  useEffect(() => {
    if (mcid) {
      getIntegratedAppList(mcid).then(res => {
        setApps(res);
      });
    }
  }, [mcid]);

  // 应用功能权限下拉改变, 重新读取表单值(onclear有局限)
  const appPermissionSelectChange = () => {
    let data = form.getFieldValue('moduleItems');
    let tempPermissions: string[] = [];
    data?.forEach((item: any) => {
      let temp = item.appPermssion;
      if (temp && temp[2]) {
        if (tempPermissions.includes(temp[2])) return;
        tempPermissions.push(temp[2]);
      }
    });
    setSelectsAppPermission(tempPermissions);
  };

  // 新增表单行
  const addRow = () => {
    listTableRef?.current.addRow?.();
  };
  // 删除表格行
  const removeRow = (index: number) => {
    listTableRef?.current.removeRow?.(index);
  };
  const copyPath = (dependent: any, index: number) => {
    if (!dependent) return;
    let pathStr = `${window.SELECTSTAFFORIGIN}/ql/integrated/${dependent}`;
    navigator.clipboard.writeText(pathStr).then(() => messageApi.success('复制成功'));
  };
  // 表格行
  const tableCols = [
    {
      title: '集成应用',
      dataIndex: 'integratedApps',
      name: 'integratedApps',
      render: (v: string, record: any) =>
        renderCols({
          record: record,
          name: 'integratedApps',
          required: true,
          options: (
            <>
              {integratedApps.map(item => (
                <Option value={item.id} key={item.appKey}>
                  {item.name}
                </Option>
              ))}
            </>
          ),
        }),
    },
    {
      title: '应用功能权限',
      dataIndex: 'appPermssion',
      name: 'appPermssion',
      render: (v: string, record: any) =>
        dependentRenderCols({
          record,
          name: 'appPermssion',
          dependentName: 'integratedApps',
          required: dependentData => !!dependentData,
          // prettier-ignore
          optionsRender: dependentData => {// NOSONAR
            return (
              <AppPermissionSelect
                appId={dependentData}
                hasSelects={selectsAppPermission}
                onChange={appPermissionSelectChange}
                disabled={!dependentData}
                isSet={isSet}
              />
            );
          },
        }),
    },
    {
      title: '四级权限',
      dataIndex: 'fourLevelPermssion',
      name: 'fourLevelPermssion',
      render: (v: string, record: any) =>
        dependentRenderCols({
          record,
          name: 'fourLevelPermssion',
          dependentName: 'appPermssion',
          // prettier-ignore
          optionsRender: (dependentData, rowData) => {// NOSONAR
            return (
              <FourLevelPermissionSelect threeLevelCode={dependentData} integrateAppId={rowData?.integratedApps} />
            );
          },
        }),
    },
    {
      title: '轻流应用包',
      dataIndex: 'qflowTag',
      name: 'qflowTag',
      render: (v: string, record: any) =>
        dependentRenderCols({
          record: record,
          name: 'qflowTag',
          dependentName: 'appPermssion',
          required: dependentData => !!dependentData,
          // prettier-ignore
          optionsRender: dependentData => {// NOSONAR
            return <QflowAppTagSelect appId={dependentData} isSet={isSet} options={tagList} />;
          },
        }),
    },
    {
      title: '对应轻流应用/门户',
      dataIndex: 'qflowPage',
      name: 'qflowPage',
      render: (v: string, record: any, index: number) =>
        dependentRenderCols({
          record,
          name: 'qflowPage',
          dependentName: 'qflowTag',
          required: dependentData => !!dependentData,
          // prettier-ignore
          optionsRender: dependentData => {// NOSONAR
            let data = tagList?.find(item => item.tagId === dependentData);
            let selects = data?.appList;
            return (
              <QflowAppPageSelect
                appId={dependentData}
                isSet={isSet}
                hasSelects={hasSelectAppIds}
                onChange={appIdOnChange}
                options={selects}
                disabled={!dependentData}
              />
            );
          },
        }),
    },
    {
      title: '操作',
      dataIndex: 'op',
      name: 'op',
      render: (v: string, record: any, index: number) =>
        dependentRenderCols({
          record,
          name: 'op',
          dependentName: 'id',
          // prettier-ignore
          optionsRender: dependentData => {// NOSONAR
            return (
              <Space>
                <Button type="link" disabled={!dependentData} onClick={() => copyPath(dependentData, index)}>
                  复制路径
                </Button>
                <Button type="link" onClick={() => removeRow(index)}>
                  删除
                </Button>
              </Space>
            );
          },
        }),
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <ListTableForm ref={listTableRef} cls={tableCols} scroll={{ y: 300 }} formName="moduleItems" />
        </Col>
      </Row>
      <div style={{ height: '18px' }}></div>
      <Row>
        <Col span={24}>
          <Button size="large" type="dashed" onClick={addRow} block icon={<PlusOutlined />}>
            添加定制轻流模块
          </Button>
        </Col>
      </Row>
      {messageContextHolder}
    </>
  );
};
export default QlModuleItem;
