import { fetch } from '@maxtropy/components';

export interface AppFunctionPermissionVo {
  id: number;
  name: string;
  code: string;
  parentId: number;
  type?: string;
  standard: boolean;
  description?: string;
  pic?: string;
  flag?: boolean;
  origin?: string;
  path?: string;
  disabled?: boolean;
  children: AppFunctionPermissionVo[];
}

export function getAppPermissionTree(): Promise<AppFunctionPermissionVo[]> {
  return fetch('/api/app/function/permission/tree');
}

export interface PermissionLabelResponse {
  name: string;
  codes: string[];
}

export function getAppPermissionLabel(): Promise<PermissionLabelResponse[]> {
  return fetch(`/api/app/function/permission/label`);
}
