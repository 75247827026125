import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { CSSProperties, FC, HTMLAttributes, useEffect, useRef, useState } from 'react';
import { Table, TableProps } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

interface RowProps extends HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 99 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, child => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined ref={setActivatorNodeRef} style={{ touchAction: 'none', cursor: 'move' }} {...listeners} />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

interface DragSortTableProps extends TableProps<any> {
  rowKey: string;
  dataSource: any[] | undefined;
  setDataSource: (dataSource: any) => any;
}

const DragSortTable: FC<DragSortTableProps> = props => {
  const { dataSource, setDataSource, columns, rowKey } = props;

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    })
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const newData = [...dataSource!];
      const activeIndex = newData.findIndex(i => i[rowKey] === active.id);
      const overIndex = newData.findIndex(i => i[rowKey] === over?.id);
      setDataSource(arrayMove(newData, activeIndex, overIndex));
    }
  };

  return (
    <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext items={dataSource ? dataSource.map(i => i[rowKey]) : []} strategy={verticalListSortingStrategy}>
        <Table
          {...props}
          components={{
            body: {
              row: Row,
            },
          }}
          rowKey={rowKey}
          columns={[
            {
              key: 'sort',
              width: 40,
            },
            ...(columns ?? []),
          ]}
          dataSource={dataSource}
        />
      </SortableContext>
    </DndContext>
  );
};

export default DragSortTable;
