import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Input, Form, Cascader, Select, Col } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';

import { Wrapper, Paging, Table, usePaging, EllipsisSpan, useAsync } from '@maxtropy/components';
import { useQuery } from '../../../utils/utils';
import Filter from '@/shared/components/Filter';
import { getDeviceTypeData } from '../../../api/attribute';
import {
  formatOptionData,
  getAllChild,
  getChildNodesByParentIds,
} from '@/shared/components/CascadingMultipleSelector/utils';
import { DefaultOptionType } from 'antd/es/cascader';
import { getManufacturerList, ManufacturerItem } from '../../../api/edgeTemplate';
import { getPhysicalModelPage, PhysicalModelItems, PhysicalModelRequest } from '../../../api/device';
import dayjs from 'dayjs';

export interface FilterParams {
  deviceTypeIds: Array<Array<number>> | undefined;
  manufacturerId?: number;
  physicalModelId?: string;
}

type SearchParams = Omit<PhysicalModelRequest, 'page' | 'size'>;

const routes = [{ name: 'Iot配置' }, { name: '设备管理' }, { name: '物模型型号管理' }];

const columns: ColumnsType<PhysicalModelItems> = [
  {
    title: '物模型模型ID',
    dataIndex: 'id',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '物模型型号',
    dataIndex: 'modelNo',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '厂商',
    dataIndex: 'manufacturerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '场景',
    dataIndex: 'scene',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '一级类目',
    dataIndex: 'firstLevelDeviceType',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '二级类目',
    dataIndex: 'secondLevelDeviceType',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },

  {
    title: '更新时间',
    dataIndex: 'updateTime',
    fixed: 'right',
    width: 160,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
];

const PhysicalModel: React.FC = () => {
  const [form] = Form.useForm();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const deviceTypeData = useAsync(getDeviceTypeData);

  const [searchParams, setSearchParams] = useState<SearchParams>();

  const { data, isLoading } = useQuery(
    useCallback(() => {
      return getPhysicalModelPage({
        ...searchParams,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        if (res) setTotalCount(res.total);
        return res.list;
      });
    }, [pageOffset, pageSize, searchParams, setTotalCount])
  );

  const onFinish = (val: FilterParams) => {
    const deviceTypeIds = getChildNodesByParentIds(val.deviceTypeIds, deviceTypeData?.tree);
    // 下面方法有bug
    // const deviceTypeIds = getAllChild(v.deviceTypeIds, formatOptionData(deviceTypeData));
    const params: SearchParams = {
      ...val,
      deviceTypeIds,
    };
    setSearchParams(params);
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    setPageOffset(1);
  };
  const [manufacturerList, setManufacturerList] = useState<ManufacturerItem[]>([]); // 厂商列表

  useEffect(() => {
    getManufacturerList().then(setManufacturerList);
  }, []);
  const manufacturerOptions = useMemo(() => {
    if (manufacturerList && manufacturerList.length > 0) {
      return manufacturerList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [manufacturerList]);

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  const filters = (
    <Filter<FilterParams> form={form} onFinish={val => onFinish(val as FilterParams)} onReset={onReset}>
      <Col span={6}>
        <Form.Item name="deviceTypeIds" label="所属类目">
          <Cascader
            options={formatOptionData(deviceTypeData)}
            allowClear={false}
            fieldNames={{ children: 'child' }}
            multiple
            maxTagCount="responsive"
            showSearch={{ filter }}
            placeholder={'请选择所属类目'}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name="manufacturerId" label="厂商">
          <Select optionFilterProp="label" placeholder="请选择" showSearch options={manufacturerOptions} />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name="modelNo" label="物模型型号">
          <Input placeholder={'请输入名称查询'} />
        </Form.Item>
      </Col>
    </Filter>
  );

  return (
    <Wrapper routes={routes} filters={filters}>
      <Table<PhysicalModelItems>
        sticky
        scroll={{ x: 1900 }}
        rowKey="id"
        dataSource={data ?? []}
        loading={isLoading}
        columns={columns}
      />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default PhysicalModel;
