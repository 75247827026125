import { useMemo, useState, FC } from 'react';
import { Button, Col, Empty, Form, Input, Row, Select, Space, Spin, Tabs, TabsProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import { usePaging, Wrapper, Paging, useUpdate } from '@maxtropy/components';
import { getRoot } from '../../api/mockCustomer';
import styles from './index.module.scss';

import ListCard from './components/ListCard';
import { rulesGroupStatusDisplay } from '../../api/type';
import { useRequest } from 'ahooks';
import { apiDataPropertyGroupTreeGet } from '../../ytt/types/dataPropertyGroup/tree';
import { apiRulesSetPageGet } from '../../ytt/types/rulesSet/page';
import { numberToString } from '../TemplateManage/utils';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);
const { useForm } = Form;

const routes = [{ name: '数据运营工具' }, { name: '数据模拟' }, { name: '规则集管理' }];

type FilterParams = {
  name?: string;
  mcid?: string;
  sceneId?: string;
};

const RulesManage: FC = () => {
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const navigate = useNavigate();
  // const [ruleSets, setRuleSets] = useState<RuleSet[]>([]);
  const [searchParams, setSearchParams] = useState<FilterParams>({});
  const [updateState, updateFn] = useUpdate();
  const [form] = useForm();
  const [currentTab, setCurrentTab] = useState('');

  // 格式化tabs
  const items: TabsProps['items'] = useMemo(
    () =>
      Object.entries(rulesGroupStatusDisplay)
        .sort((a, b) => +a[0] - +b[0])
        .map(([k, v]) => ({ key: k, label: v })),
    []
  );
  const { data: tenants } = useRequest(getRoot);
  const { data: scenes } = useRequest(apiDataPropertyGroupTreeGet);
  // 查询模板列表
  const { data: pageList, loading } = useRequest(
    () => {
      return apiRulesSetPageGet({
        ...searchParams,
        status: currentTab,
        page: numberToString(pageOffset),
        size: numberToString(pageSize),
        ...searchParams,
      }).then(res => {
        setTotalCount(res.total!);
        return res.list;
      });
    },
    {
      refreshDeps: [pageOffset, pageSize, searchParams, currentTab, updateState],
    }
  );
  const onReset = () => {
    form.resetFields();
    setSearchParams({});
    setPageOffset(1);
  };
  return (
    <Wrapper
      filtersStyle={{ minWidth: 'auto' }}
      routes={routes}
      className={styles.outer}
      filters={
        <div className={styles.filter}>
          <Form form={form} onFinish={setSearchParams}>
            <Row gutter={10}>
              <Col span={6}>
                <Form.Item name="mcid" label="所属租户">
                  <Select
                    showSearch
                    placeholder="请选择租户"
                    optionFilterProp="label"
                    options={(tenants ?? []).map(v => ({ label: v.name, value: v.mcid }))}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="名称" name="name">
                  <Input placeholder="请输入规则集名称"></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="场景" name="sceneId">
                  <Select
                    showSearch
                    placeholder="请选择场景"
                    optionFilterProp="label"
                    options={(scenes ?? []).map(v => ({ label: v.sceneName, value: v.sceneId }))}
                  />
                </Form.Item>
              </Col>
              <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Form.Item>
                  <Space size={12}>
                    <Button type="primary" htmlType="submit">
                      查询
                    </Button>
                    <Button onClick={onReset}>重置</Button>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      }
    >
      <div className={styles.page_box}>
        <div className={styles.tabs}>
          <Tabs
            defaultActiveKey=""
            tabBarExtraContent={{
              right: (
                <Space size={12}>
                  <Button type="primary" onClick={() => navigate('/data-tools/shaping/rulesmanage/rules')}>
                    创建规则集
                  </Button>
                </Space>
              ),
            }}
            items={items}
            onChange={setCurrentTab}
          />
        </div>
        <div className={cx({ list: !pageList || pageList.length === 0 })}>
          <Spin spinning={loading}>
            {pageList && pageList.length > 0 ? (
              <Row gutter={[15, 15]}>
                {(pageList ?? []).map(item => (
                  <Col xxl={6} xl={8} key={item.id}>
                    <ListCard data={item} delete={updateFn} stopAndOpen={updateFn} copy={updateFn} />
                  </Col>
                ))}
              </Row>
            ) : (
              <Empty />
            )}
          </Spin>
        </div>
        <Paging pagingInfo={pagingInfo} />
      </div>
    </Wrapper>
  );
};

export default RulesManage;
