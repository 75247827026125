import { fetch, Key } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import qs from 'qs';

export interface PageSearchParams extends PageRequest {
  tenantMcid?: string;
  typeId?: Key;
}

export interface ReportsUploadListRes {
  id: Key;
  name: string; // 报告名称
  typeId: Key; // 报告类型id
  typeName: string; // 报告类型名称
  tenantMcid: string; // 所属租户
  tenantName: string; // 所属租户名称
  resource: string; // 附件阿里云key
  reportTime: string; // 报告时间
  createTime: string; // 创建时间
  updateTime: string; // 修改时间
}

// 运管端报告分页
export function getAnalysisReportPage(query: PageSearchParams): Promise<PageResponse<ReportsUploadListRes>> {
  return fetch(`/api/analysis-report/page?${qs.stringify(query, { indices: false })}`);
}

export interface AddReportBody {
  name: string; // 报告名称
  typeId: Key; // 报告类型id
  tenantMcid: string; // 所属租户
  resource: string; // 附件阿里云key
  reportTime: string; // 报告时间
}

// 新增报告
export function addAnalysisReport(body: AddReportBody) {
  return fetch(`/api/analysis-report`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
}

// 删除报告
export function delAnalysisReport(analysisReportId: Key) {
  return fetch(`/api/analysis-report/${analysisReportId}`, {
    method: 'DELETE',
  });
}

export interface AddReportTypeBody {
  name: string; // 报告名称
  tenantMcid: string; // 所属租户
  coverPicResource: string; // 封面图阿里云key
}

// 新增报告类型
export function addAnalysisReportType(body: AddReportTypeBody) {
  return fetch(`/api/analysis-report/type`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export type editReportTypeBody = Omit<AddReportTypeBody, 'tenantMcid'>;

// 修改报告类型
export function editAnalysisReportType(analysisReportTypeId: Key, body: editReportTypeBody) {
  return fetch(`/api/analysis-report/type/${analysisReportTypeId}`, {
    method: 'PUT',
    body: JSON.stringify(body),
  });
}

export interface ReportTypeDetail {
  id: Key;
  name: string; // 报告类型名称
  tenantMcid: string; // 所属租户
  tenantName: string; // 所属租户名称
  coverPicResource: string; // 封面图阿里云key
  createTime: string; // 创建时间
  updateTime: string; // 修改时间
}

// 根据id获取报告类型
export function getAnalysisReportTypeById(analysisReportTypeId: Key) {
  return fetch<ReportTypeDetail>(`/api/analysis-report/type/${analysisReportTypeId}`, {
    method: 'GET',
  });
}

// 获取报告类型列表
export function getAnalysisReportTypeList(query: { tenantMcid?: string }) {
  return fetch<ReportTypeDetail[]>(`/api/analysis-report/type/list?${qs.stringify(query, { indices: false })}`, {
    method: 'GET',
  });
}
