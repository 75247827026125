import React, { CSSProperties } from 'react';
import { CustomAttribute } from './Editor';
import { Form } from 'antd';

export interface CustomAttributesDisplayProps {
  customAttributes: CustomAttribute[];
  style?: CSSProperties;
  className?: string;
}

const CustomAttributesDisplay: React.FC<CustomAttributesDisplayProps> = ({ customAttributes, style, className }) => {
  const renderTree = (items: CustomAttribute[]): React.ReactNode => {
    if (!items.length) return;
    return items.map(item => (
      <>
        <div style={{ paddingLeft: item.depth * 20 }}>
          {item.isGroup ? (
            <div
              style={{
                color: 'var(--heading-color)',
                fontWeight: 'bold',
                marginBottom: 24,
              }}
            >
              {item.label}
            </div>
          ) : (
            <Form.Item label={item.label}>{item.value || '-'}</Form.Item>
          )}
        </div>
        {renderTree(customAttributes.filter(child => child.parentKey === item.key))}
      </>
    ));
  };

  return (
    <div style={style} className={className}>
      {renderTree(customAttributes.filter(item => !item.parentKey))}
    </div>
  );
};

export default CustomAttributesDisplay;
