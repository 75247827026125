import $RefParser from '@apidevtools/json-schema-ref-parser';
import converter from 'swagger2openapi';
import { v4 as uuidv4 } from 'uuid';
import { OpenAPIV3 } from '../Entity';
import dereference from './dereference';

export interface OpenAPI extends OpenAPIV3.Document {
  swagger?: string;
}

export interface ParameterObject extends OpenAPIV3.ParameterObject {}

export interface OperationObject extends OpenAPIV3.OperationObject {
  server?: string; // 用在
  uuid: string;
  path: string;
  method: string;
}

export interface ProcessSpecResult extends Omit<OpenAPI, 'paths'> {
  paths: OperationObject[];
  securitySchemes?: {
    [key: string]: OpenAPIV3.SecuritySchemeObject;
  };
}

export const flatComponents = (origin: any, checkMap: any) => {
  Object.keys(checkMap).forEach(key1 => {
    let obj = checkMap[key1];
    if (obj.items && obj.items.$ref) {
      let str = obj.items.$ref;
      // 根据$ref:#/components/schemas/PvStationElectricityPriceVO找到对应的数据源
      if (!str) return;
      let arr = str.split('/');
      let str2 = arr[arr.length - 1];
      obj.items = origin[str2];
      return;
    }
    if (obj.$ref) {
      let str = obj.$ref;
      if (!str) return;
      let arr = str.split('/');
      let str2 = arr[arr.length - 1];
      checkMap[key1] = origin[str2];
      return;
    }
    if (obj.properties) {
      flatComponents(origin, obj.properties);
    }
  });
};
// openAPI 3.0.1将components中的数据添加到content/schema中;
const handleDataforOpenApi3 = (swagger: any) => {
  let { components, paths } = swagger;
  // 扁平化处理
  let originSchemas = components.schemas;
  flatComponents(originSchemas, originSchemas);
  console.log('originSchemas', originSchemas);
  let methods = ['get', 'put', 'post', 'delete', 'patch', 'options', 'head', 'patch', 'trace'];
  Object.keys(paths).forEach((path: string) => {
    let tempData = paths[path];
    let currentMethod = methods.find(method => tempData[method]);
    if (!currentMethod) return;
    let item = tempData[currentMethod];
    let temp = item.responses['200']['content']['*/*']['schema'];
    if (temp) {
      if (temp.$ref) {
        // map形式
        let tempSchemas = temp.$ref;
        let keys = tempSchemas.split('/');
        let key = keys[keys.length - 1];
        item.responses['200']['content']['*/*']['schema'] = components.schemas[key];
        return;
      }
      if (temp.items) {
        // map[]形式
        let tempSchemas = temp.items.$ref;
        if (!tempSchemas) return;
        let keys = tempSchemas.split('/');
        let key = keys[keys.length - 1];
        item.responses['200']['content']['*/*']['schema']['items'] = components.schemas[key];
        return;
      }
    }
  });
  return swagger;
};
// prettier-ignore
const ProcessSpec = (swagger: OpenAPI): Promise<ProcessSpecResult> => {// NOSONAR
  let options = { patch: true, warnOnly: true };
  const openapi = !!swagger.swagger
    ? converter.convertObj(swagger, options).then(async function (api3Spec: { openapi: object }) {
      // console.log("api3Spec", api3Spec.openapi)
      console.info('%c Convertion to OpenAPI 3.0 - Success !!! ', 'color:cornflowerblue');
      let parser = new $RefParser();
      await parser.dereference(api3Spec.openapi, { dereference: { circular: 'ignore' } });
      if (parser.$refs.circular) {
        dereference(parser, { dereference: { circular: 'ignore' } });
      }
      console.log('2.0版本转换后>>>>>>>>>>>>>>>>>>>>>>');
      console.log(parser.schema);
      return parser.schema;
    })
    : Promise.resolve(handleDataforOpenApi3(swagger));

  return openapi
    .then(function (deReffedSpec: OpenAPI) {
      // console.log("deReffedSpec", deReffedSpec)
      console.info('%c OpenAPI 3.0 Dereferencing - Success !!! ', 'color:cornflowerblue');
      let methods = ['get', 'put', 'post', 'delete', 'patch', 'options', 'head', 'patch', 'trace'];
      let _paths: OperationObject[] = [];
      // For each path find the tag and push it into the corrosponding tag
      const { paths } = deReffedSpec;
      for (const path in paths) {
        const { parameters = [], /*summary="", description="", */ servers = [] } = paths[path];
        let commonParams = parameters;
        let commonPathProp = { servers, parameters };
        Object.entries(deReffedSpec.paths[path])
          .filter(([key, value]) => methods.includes(key))
          .forEach(([methodName, value]) => {
            const fullPath = value as OpenAPIV3.OperationObject;
            if (fullPath) {
              // Merge Common Parameters with This methods parameters
              let finalParameters = [];
              if (Array.isArray(fullPath.parameters)) {
                finalParameters = commonParams
                  .filter(commonParam => {
                    return (
                      Array.isArray(fullPath.parameters) &&
                      !fullPath.parameters.some(param => commonParam.name === param.name && commonParam.in === param.in)
                    );
                  })
                  .concat(fullPath.parameters);
              } else {
                finalParameters = commonParams.slice(0);
              }

              _paths.push({
                ...fullPath,
                uuid: uuidv4(),
                path,
                method: methodName,
                parameters: finalParameters,
                servers: fullPath.servers ? commonPathProp.servers.concat(fullPath.servers) : commonPathProp.servers,
              });
            }
          });
      }

      const securitySchemes = deReffedSpec.components ? deReffedSpec.components.securitySchemes : undefined;
      const result: ProcessSpecResult = { ...deReffedSpec, paths: _paths, securitySchemes };
      return Promise.resolve(result);
    })
    .catch(function (err: Error) {
      console.error(err);
    });
};

export default ProcessSpec;
