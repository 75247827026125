//@ts-nocheck
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { apiV2MiniAppToolEditPost } from '@maxtropy/cc-mgmt-apis-v2';
import ChildItem from './ChildItem';
import ChildSortableItem from './ChildSortableItem';
import Grid from './Grid';

const ChildApp: FC = props => {
  const [sortItem, setSortItem] = useState<any[]>([]);

  const [activeId, setActiveId] = useState<string | null>(null);
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const sensors = useSensors(mouseSensor, useSensor(TouchSensor));

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id);
  }, []);
  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (active.id !== over?.id) {
        const oldIndex = sortItem.findIndex(item => item.id === active.id);
        const newIndex = sortItem.findIndex(item => item.id === over?.id);
        let temp = arrayMove(sortItem, oldIndex, newIndex);
        temp = temp.map((item, index) => {
          item.sorting = index;
          return item;
        });
        const tempData = temp.map(item => {
          return {
            id: item.id,
            name: item.name,
            sorting: item.sorting,
            categoryId: item.mirrorCategoryId,
          };
        });

        apiV2MiniAppToolEditPost({ menuId: props.currentCenterId, toolEditRequests: tempData }).then(_res => {
          setSortItem(temp);
        });
      }

      setActiveId(null);
    },
    [sortItem, props.currentCenterId]
  );

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  useEffect(() => {
    if (props.childrenData?.length) {
      setSortItem(props.childrenData);
    }
  }, [props.childrenData]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={sortItem} strategy={rectSortingStrategy}>
        <Grid columns={2}>
          {sortItem.map(item => (
            <ChildSortableItem
              key={item.id}
              id={item.id}
              name={item.name}
              currentCenterId={props.currentCenterId}
              update={props.update}
              icon={item.icon}
            />
          ))}
        </Grid>
      </SortableContext>
      <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
        {activeId ? <ChildItem id={activeId} isDragging /> : null}
      </DragOverlay>
    </DndContext>
  );
};

export default ChildApp;
