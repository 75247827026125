import { useCallback, useState, FC, useMemo } from 'react';

import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Card, Col, Input, Modal, Row, Space, Tag, Typography } from 'antd';

import { ClockCircleOutlined } from '@ant-design/icons';

import { Wrapper, useAsync } from '@maxtropy/components';

import { useRequest } from 'ahooks';
import { apiV2CronjobListPost } from '@maxtropy/tody-mgmt-apis-v2';
import TaskModal, { DataProps } from './TaskModal';

const { Title } = Typography;

const DataRun = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const [currentData, setCurrentData] = useState<DataProps>();

  const onCancel = () => {
    setVisible(false);
  };
  const onConfirm = () => {
    setVisible(false);
  };
  const { data: cardList } = useRequest(() => apiV2CronjobListPost().then(res => res.list), {
    refreshDeps: [],
  });

  const routes = useMemo(() => {
    return [{ name: '数据运营工具' }, { name: '业务数据模拟' }, { name: `定时任务数据重跑` }];
  }, []);

  return (
    <Wrapper routes={routes}>
      <Title level={2}>{routes[2].name}</Title>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Row gutter={10}></Row>
        <Row gutter={[16, 16]}>
          {cardList?.map(item => (
            <Col span={6} key={item.id}>
              <Card
                title={item.name}
                extra={
                  <>
                    <Button
                      type="link"
                      icon={<ClockCircleOutlined />}
                      size="small"
                      onClick={() => {
                        setVisible(true);
                        setCurrentData(item);
                      }}
                    />
                  </>
                }
              ></Card>
            </Col>
          ))}
        </Row>
      </Space>
      {visible && <TaskModal data={currentData} onCancel={onCancel} onConfirm={onConfirm} />}
    </Wrapper>
  );
};

export default DataRun;
