import React from 'react';
import { Steps, StepsProps } from 'antd';
import './index.scss';

const { Step } = Steps;

interface CommonStepsProps extends StepsProps {
  children?: React.ReactNode;
}

const CommonSteps: React.FC<CommonStepsProps> = props => {
  const prefixCls = 'mgmt-steps';
  return (
    <div className={`${prefixCls}-wrapper`}>
      <Steps {...props}>{props.children}</Steps>
    </div>
  );
};

export const ApplicationSteps: React.FC<CommonStepsProps> = props => {
  return (
    <CommonSteps {...props} style={{ width: 1000 }}>
      <Step title="基本信息" />
      <Step title="应用授权" />
      <Step title="完成" />
    </CommonSteps>
  );
};
