import qs from 'qs';
import { fetch } from '@maxtropy/components';

export enum OperationLogType {
  // 新增
  ADD = '1',
  // 查找
  FIND = '2',
  // 编辑
  UPDATE = '3',
  // 删除
  DELETE = '4',
}

export const OperationLogTypeDispaly = {
  [OperationLogType.ADD]: '新增',
  [OperationLogType.FIND]: '查找',
  [OperationLogType.UPDATE]: '编辑',
  [OperationLogType.DELETE]: '删除',
};

export interface getOperationLogListParams {
  navigationName?: string;
  operationType?: OperationLogType;
  navigationProgramId?: string;
  offset: number;
  count: number;
}

export interface GetOperationLogList {
  offset: number;
  count: number;
  total: number;
  contents: Content[];
}

export interface Content {
  id?: number;
  navigationProgramId?: number;
  /**
   * 操作类型
   */
  operationType?: 1 | 2 | 3 | 4;
  /**
   * 操作用户名称
   */
  operatingUsername?: string;
  /**
   * 操作时间
   */
  operatingTime?: string;
  /**
   * 原始数据
   */
  originalData?: {
    /**
     * 名称
     */
    name?: string;
    /**
     * 上级导航名称
     */
    parentName?: string;
    /**
     * 权重
     */
    weighting?: number;
    /**
     * 打开方式
     */
    openTypeDesc?: string;
    /**
     * 跳转方式
     */
    jumpTypeDesc?: string;
    /**
     * 链接地址
     */
    url?: string;
    /**
     * 权限类型
     */
    permissionDesc?: string;
    /**
     * 权限编码
     */
    permissionCode?: string;
    /**
     * 模块域名
     */
    moduleDesc?: string;
    /**
     * 相对路径
     */
    path?: string;
    /**
     * 第三方应用
     */
    integratedAppDesc?: string;
  };
  /**
   * 修改后的数据
   */
  currentData?: {
    /**
     * 名称
     */
    name?: string;
    /**
     * 上级导航名称
     */
    parentName?: string;
    /**
     * 权重
     */
    weighting?: number;
    /**
     * 打开方式
     */
    openTypeDesc?: string;
    /**
     * 跳转方式
     */
    jumpTypeDesc?: string;
    /**
     * 链接地址
     */
    url?: string;
    /**
     * 权限类型
     */
    permissionDesc?: string;
    /**
     * 权限编码
     */
    permissionCode?: string;
    /**
     * 模块域名
     */
    moduleDesc?: string;
    /**
     * 相对路径
     */
    path?: string;
    /**
     * 第三方应用
     */
    integratedAppDesc?: string;
  };
  /**
   * 创建时间
   */
  createTime?: {
    /**
     * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
     */
    seconds?: number;
    /**
     * The number of nanoseconds, later along the time-line, from the seconds field.
     * This is always positive, and never exceeds 999,999,999.
     */
    nanos?: number;
  };
  /**
   * 更新时间
   */
  updateTime?: {
    /**
     * The number of seconds from the epoch of 1970-01-01T00:00:00Z.
     */
    seconds?: number;
    /**
     * The number of nanoseconds, later along the time-line, from the seconds field.
     * This is always positive, and never exceeds 999,999,999.
     */
    nanos?: number;
  };
}

export interface CurrentDataClass {
  name: string;
  parentName: string;
  weighting: number;
  openTypeDesc: string;
  jumpTypeDesc: string;
  url: string;
  permissionDesc: string;
  permissionCode: string;
  moduleDesc: string;
  path: string;
  integratedAppDesc: string;
  [key: string]: any;
}

export function getOperationLogList(query: getOperationLogListParams): Promise<GetOperationLogList> {
  return fetch(`/api/navigation/log/page?${qs.stringify(query, { indices: false })}`);
}
