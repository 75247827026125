import React, { useEffect, useState } from 'react';
import { Modal, List, Row, Col, Typography } from 'antd';
import LogDetail from '../LogDetail';
import { FormContent, Paging, usePaging } from '@maxtropy/components';
import { LogType, OperateTargetType } from '../../api/openPlatform-const';
import { LogListQuery, LogProps, queryLogListPaged } from '../../api/openPlatform-log';
import dayjs from 'dayjs';

export interface MappingProps {
  label: string;
  dataIndex: string;
  type: 'string' | 'array' | 'enum';
  enumDisplay?: { [key: string]: string };
}

interface LogListProps {
  mapping: MappingProps[];
  id: string;
  type: OperateTargetType;
  updateState: number;
}

const LogList: React.FC<LogListProps> = props => {
  const { mapping, id, type, updateState } = props;
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [dataSource, setDataSource] = useState<LogProps[]>([]);

  const [isModalOpened, setIsModalOpened] = useState(false);
  const [lastRecord, setLastRecord] = useState({});
  const [currentRecord, setCurrentRecord] = useState({});

  const getData = (query?: Partial<LogListQuery>) => {
    queryLogListPaged({ targetId: id, targetType: type, count: pageSize, offset: pageOffset, ...query }).then(res => {
      setTotalCount(res.total);
      setPageOffset(res.current);
      setDataSource(res.records);
    });
  };

  useEffect(() => {
    if (!id || !type) return;
    getData({ offset: pageOffset });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, pageSize, id, type, updateState]);

  const showModal = (item: LogProps) => {
    setIsModalOpened(true);
    setLastRecord(JSON.parse(item.beforeInfo));
    setCurrentRecord(JSON.parse(item.afterInfo));
  };

  const handleOk = () => {
    setIsModalOpened(false);
  };

  const handleCancel = () => {
    setIsModalOpened(false);
  };

  return (
    <>
      <FormContent title="日志">
        <List
          style={{ marginLeft: 30 }}
          itemLayout="horizontal"
          dataSource={dataSource}
          renderItem={item => {
            const { operatorName, additionalInfo } = item;
            let content;
            switch (item.type) {
              case LogType.EDIT_FORM:
                content = (
                  <span>
                    {operatorName}
                    <span>编辑了{type === OperateTargetType.APP ? '应用信息' : '开发者信息'}</span>
                  </span>
                );
                break;
              case LogType.BIND_DEVELOPER:
                content = (
                  <span>
                    {operatorName}
                    <span style={{ color: 'red' }}>{`新增了${JSON.parse(additionalInfo).role}，用户名是${
                      JSON.parse(additionalInfo).name
                    }`}</span>
                  </span>
                );
                break;
              case LogType.UNBIND_DEVELOPER:
                content = (
                  <span>
                    {operatorName}
                    <span style={{ color: 'red' }}>{`删除了${JSON.parse(additionalInfo).role}，用户名是${
                      JSON.parse(additionalInfo).name
                    }`}</span>
                  </span>
                );
                break;
              case LogType.BIND_APP:
                content = (
                  <span>
                    {operatorName}
                    <span style={{ color: 'red' }}>{`添加了应用${JSON.parse(additionalInfo).name}，当前用户身份为${
                      JSON.parse(additionalInfo).role
                    }`}</span>
                  </span>
                );
                break;
              case LogType.UNBIND_APP:
                content = (
                  <span>
                    {operatorName}
                    <span style={{ color: 'red' }}>{`删除了应用${JSON.parse(additionalInfo).name}，当前用户此前身份为${
                      JSON.parse(additionalInfo).role
                    }`}</span>
                  </span>
                );
                break;
              case LogType.GEN_SECRET:
                content = (
                  <span>
                    {operatorName}
                    <span style={{ color: 'red' }}>重置了密钥</span>
                  </span>
                );
                break;
            }
            return (
              <List.Item>
                <Row style={{ width: '100%' }}>
                  <Col span={4}>{dayjs(item.timestamp).format('YYYY-MM-DD HH:mm:ss')}</Col>
                  <Col span={18}>{content}</Col>
                  <Col span={2}>
                    {LogType.EDIT_FORM === item.type && (
                      <Typography.Link key="list-view" onClick={() => showModal(item)}>
                        查看
                      </Typography.Link>
                    )}
                  </Col>
                </Row>
              </List.Item>
            );
          }}
        />
        <Paging pagingInfo={pagingInfo} />
      </FormContent>
      <Modal width={870} title="日志详情" open={isModalOpened} onOk={handleOk} onCancel={handleCancel}>
        <LogDetail mapping={mapping} lastRecord={lastRecord} currentRecord={currentRecord} />
      </Modal>
    </>
  );
};

export default LogList;
