import { fetch, Key } from '@maxtropy/components';
import qs from 'qs';
import { Paged, PageRequest } from './openPlatform-const';
import { ApplicationProps } from './openPlatform-application';
import { AccountItem } from './openPlatform-account';

export enum ServiceStatus {
  DISABLE = 0,
  ENABLE,
}

export interface ServiceListRequest extends PageRequest {
  name?: string;
  companyName?: string;
  status: ServiceStatus;
}

export interface ServiceListRes {
  id: Key;
  status: ServiceStatus; // 状态
  name: string; // 服务商名称
  companyName: string; // 公司名称
  introduction: string; // 介绍
  logoResource: string; // logo资源
  createTime: string; // 创建时间
  updateTime: string;
  applications: ApplicationProps[]; // 应用
  developerAccounts: AccountItem[]; // 账号
}

export function getServicePage(params: ServiceListRequest): Promise<Paged<ServiceListRes>> {
  return fetch(`/api/isv/page?${qs.stringify(params, { indices: false })}`);
}

export interface CreateServiceBody {
  name: string; //   服务商名称
  companyName: string; //   公司名称
  introduction?: string; //   介绍
  logoResource: string; //   logo资源
}

export function createService(body: CreateServiceBody) {
  return fetch(`/api/isv`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function editService(id: Key, body: CreateServiceBody) {
  return fetch(`/api/isv/${id}`, {
    method: 'PUT',
    body: JSON.stringify(body),
  });
}

export function getServiceDetail(id: Key): Promise<ServiceListRes> {
  return fetch(`/api/isv/${id}`, {
    method: 'GET',
  });
}

export function deleteService(id: Key) {
  return fetch(`/api/isv/${id}`, {
    method: 'DELETE',
  });
}

export function changeServiceStatus(id: Key, query: { status: number }) {
  return fetch(`/api/isv/status/${id}?${qs.stringify(query, { indices: false })}`, {
    method: 'GET',
  });
}
