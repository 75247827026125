import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { Table } from 'antd';
import { Button } from '@maxtropy/components';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { getColumns } from './columns';
import { ActionType, DataPointType, VirtualPoint } from '../../../../types';
import FormDialog from './FormDialog';
import { formatOnSave, parseOnEcho } from '../../../FormulaInput';
import { useQuery } from '../../../../utils/utils';
import { getDataProperties } from '../../../../api/options';
import { PointContext, PointContextProps } from '../../../EdgeDevicePointInfo/contextTypes';
import Header from '../../../EdgeDevicePointInfo/mockingbird/Header';
import AddIdentifier from '../AddIdentifier';
import { BatchEdgeContent } from '../../contentTypes';

export type EdgeDeviceTemplatePoint = VirtualPoint & { actionType?: ActionType };

interface VirtualPointProps {
  editColumns?: ColumnsType<EdgeDeviceTemplatePoint>;
  onUpdate?: (values: any) => any;
  onAddIdentifier?: (values: any) => void;
  alterMode?: boolean;
}

const indexColumn: ColumnType<EdgeDeviceTemplatePoint> = {
  title: '序号',
  width: 70,
  fixed: 'left',
  render: (_, record, index) => index + 1,
};

const Virtual: FC<VirtualPointProps> = props => {
  const { editColumns, onUpdate, onAddIdentifier, alterMode = false } = props;

  const { dataSource, row, setRow, info, promptSlot, editable } = useContext(
    PointContext
  ) as PointContextProps<EdgeDeviceTemplatePoint>;
  const { baseForm } = useContext(BatchEdgeContent);
  const { hasProperty } = baseForm?.getFieldsValue();

  const _row = useMemo(() => {
    if (row) {
      const { parameters, formula, ...rest } = row;
      return { ...rest, formula: parseOnEcho(formula), valueRange: [parameters?.valueFloor, parameters?.valueCeiling] };
    } else {
      return row;
    }
  }, [row]);

  const usedProperties = useMemo(
    () =>
      dataSource
        ?.filter(item => item.actionType !== ActionType.DELETE && item.dataPropertyId !== row?.dataPropertyId)
        .map(item => item.dataPropertyId) || [],
    [dataSource, row]
  );

  const usedIdentifier = useMemo(
    () =>
      (dataSource
        ?.filter(item => item.actionType !== ActionType.DELETE && item.identifier !== row?.identifier)
        .map(item => item.identifier)
        .filter(Boolean) as string[]) || [],
    [dataSource, row]
  );

  const { isLoading: requiredLoading, data: dataProperties = [] } = useQuery(
    useCallback(
      () =>
        info?.deviceTypeId
          ? getDataProperties(info.iotProtocol, info.deviceTypeId, info.physicalModelId)
          : Promise.resolve([]),
      [info?.iotProtocol, info?.deviceTypeId, info?.physicalModelId]
    )
  );

  const dataPropertiesMap = useMemo(
    () => new Map(dataProperties.map(item => [String(item.id), item.name])),
    [dataProperties]
  );

  const onOk = (values: any) => {
    const { valueRange, formula, ...rest } = values;
    const _values = {
      ...rest,
      pointType: DataPointType.VIRTUAL_POINT,
      formula: formatOnSave(formula),
      parameters: {
        driveType: info?.driveType,
        pointType: DataPointType.VIRTUAL_POINT,
        valueCeiling: valueRange[1],
        valueFloor: valueRange[0],
      },
    };
    onUpdate?.(_values);
  };

  return (
    <div>
      <Header slot={promptSlot}>
        {!alterMode && editable && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setRow?.({ pointType: DataPointType.VIRTUAL_POINT })}
          >
            添加虚拟点
          </Button>
        )}
        {alterMode && editable && hasProperty === false && (
          <AddIdentifier
            usedIdentifier={usedIdentifier}
            submit={identifier =>
              onAddIdentifier?.({ identifier, hasProperty: false, pointType: DataPointType.VIRTUAL_POINT })
            }
          />
        )}
      </Header>
      <Table
        sticky
        rowKey="id"
        scroll={{ x: 1300, y: 500 }}
        loading={requiredLoading}
        dataSource={dataSource?.filter(item => item.pointType === DataPointType.VIRTUAL_POINT)}
        columns={[indexColumn, ...getColumns(dataPropertiesMap, alterMode), ...(editColumns || [])]}
        pagination={false}
      />
      <FormDialog
        onCancel={() => setRow?.(undefined)}
        onOk={onOk}
        usedProperties={usedProperties}
        dataProperties={dataProperties}
        usedIdentifier={usedIdentifier}
        row={_row}
        alterMode={alterMode}
      />
    </div>
  );
};

export default Virtual;
