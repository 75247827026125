import { EllipsisSpan } from '@maxtropy/components';
import {
  EdgeDeviceTemplate,
  EdgeDeviceTemplateStatus,
  EdgeDeviceTemplateStatusDisplay,
  IotProtocolType,
  IotProtocolTypeDisplay,
} from '@/shared/types';
import React from 'react';
import { Badge } from 'antd';

export const templateColumns = [
  {
    title: '模板编号',
    dataIndex: 'serialNumber',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '模板名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最新版本号',
    dataIndex: 'latestVersion',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v.toString()} />,
  },
  {
    title: '所属类目',
    dataIndex: 'deviceTypes',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '厂商',
    dataIndex: 'manufacturerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '物模型型号',
    dataIndex: 'modelNo',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '操作',
    render: (record: EdgeDeviceTemplate) => (
      <a
        href={
          record.latestVersionId
            ? `/device/edge/template/${record.id}?versionId=${record.latestVersionId}`
            : `/device/edge/template/${record.id}`
        }
        target="_blank"
        rel="noreferrer"
      >
        查看
      </a>
    ),
  },
];

export const selectTemplateColumns = [
  {
    title: '模板编号',
    dataIndex: 'serialNumber',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '模板名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '物联层协议',
    dataIndex: 'iotProtocol',
    ellipsis: { showTitle: true },
    render: (v: IotProtocolType) => <EllipsisSpan value={IotProtocolTypeDisplay[v]} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (v: EdgeDeviceTemplateStatus) => (
      <Badge
        status={v === EdgeDeviceTemplateStatus.USED ? 'success' : 'error'}
        text={EdgeDeviceTemplateStatusDisplay[v]}
      />
    ),
  },
  {
    title: '最新版本号',
    dataIndex: 'latestVersion',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v.toString()} />,
  },
  {
    title: '所属类目',
    dataIndex: 'deviceTypes',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '厂商',
    dataIndex: 'manufacturerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '物模型',
    dataIndex: 'modelNo',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export const selectTenantColumns = [
  {
    title: '租户CODE',
    dataIndex: 'tenantCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '租户名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '统一社会信用代码',
    dataIndex: 'uscc',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];
