import { EllipsisSpan, Paging, usePaging, useUpdate } from '@maxtropy/components';
import { Button, Form, Input, Modal, Space, Table } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { copyNavigation, CusNavListResponse, getNavigationList, updateNavgationName } from '../../../api/cc-navigation';
import { PermissionsType } from '../../../common/permissionsConst';
import { useHasPermission } from '../../../utils/hooks';

const columns = [
  {
    title: '导航方案编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '导航方案名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const CusNavList: FC = () => {
  const hasNavAddAndEdit = useHasPermission(PermissionsType.B_NAVIGATIONPROGRAMUPDATECOPY); // 编辑复制

  const pagingInfo = usePaging(50);
  const [loading, setLoading] = useState<boolean>(false); // 加载状态
  const [form] = Form.useForm();
  const [opened, setOpened] = useState<boolean>(false);
  const [currentRecord, setCurrentRecord] = useState<Partial<CusNavListResponse>>();
  const [data, setData] = useState<CusNavListResponse[]>([]); // 后端返回数据
  const navigate = useNavigate();
  const [updateState, updateFn] = useUpdate(); // 更新

  useEffect(() => {
    setLoading(true);
    getNavigationList().then(res => {
      setData(res);
      setLoading(false);
    });
  }, [updateState]);

  useEffect(() => {
    if (currentRecord) {
      form.setFieldsValue({
        name: currentRecord.name,
      });
    }
  }, [currentRecord, form]);

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      // width: 183,
      // fixed: 'right' as 'right',
      render: (record: CusNavListResponse) => (
        <Space size={16}>
          {hasNavAddAndEdit && record.id !== 1 && (
            <Button
              style={{ paddingLeft: 0, paddingRight: 0 }}
              type="link"
              onClick={() => {
                setCurrentRecord(record);
              }}
            >
              编辑导航方案名称
            </Button>
          )}
          {hasNavAddAndEdit && record.id !== 1 && (
            <Button
              style={{ paddingLeft: 0, paddingRight: 0 }}
              type="link"
              onClick={() => {
                navigate(`/platform/navigationSettings/scheme/edit/${record.id}?name=${record.name}`);
              }}
            >
              编辑导航方案内容
            </Button>
          )}
          {record.id === 1 && (
            <Button
              style={{ paddingLeft: 0, paddingRight: 0 }}
              type="link"
              onClick={() => {
                navigate(`/platform/navigationSettings/scheme/edit/${record.id}`);
              }}
            >
              查看
            </Button>
          )}
          {hasNavAddAndEdit && (
            <Button
              style={{ paddingLeft: 0, paddingRight: 0 }}
              type="link"
              onClick={() => {
                copyNavigation(String(record.id)).then(() => {
                  updateFn();
                });
              }}
            >
              复制
            </Button>
          )}
          <Button
            style={{ paddingLeft: 0, paddingRight: 0 }}
            type="link"
            onClick={() => {
              window.open(`/platform/navigationSettings/scheme/detail/${record.id}`);
            }}
          >
            操作日志
          </Button>
        </Space>
      ),
    },
  ];

  const onClose = () => {
    setOpened(false);
    setCurrentRecord(undefined);
    form.resetFields();
  };

  const onOk = () => {
    form.validateFields().then((data: { name: string }) => {
      if (currentRecord) {
        updateNavgationName({
          id: currentRecord.id!,
          name: data.name,
        }).then(() => {
          onClose();
          updateFn();
        });
      }
    });
  };

  return (
    <>
      <div style={{ padding: '20px', margin: '0 10px' }}>
        <Table
          rowKey="id"
          sticky
          scroll={{ x: 1300 }}
          loading={loading}
          columns={buildColumns}
          dataSource={data}
          pagination={false}
        />
        <Paging pagingInfo={pagingInfo} />
      </div>
      <Modal
        title={`导航方案名称修改`}
        width={600}
        open={!!currentRecord || opened}
        onCancel={onClose}
        destroyOnClose
        onOk={onOk}
        bodyStyle={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Form style={{ width: 450 }} form={form} labelCol={{ flex: '110px' }} labelAlign="right">
          <Form.Item
            name="name"
            label="导航方案名称"
            rules={[
              { required: true, message: '请输入方案名称', whitespace: true, type: 'string' },
              { max: 20, message: '最多输入20个字' },
            ]}
          >
            <Input maxLength={20} placeholder="请输入" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CusNavList;
