import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Form, Input, Modal, Row, Select, SelectProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useState } from 'react';
import { apiDataPropertyGroupPost } from '../../../ytt/types/api/dataPropertyGroup';
import { apiDataPropertyListGet } from '../../../ytt/types/dataProperty/list';
import { apiDataPropertyGroupIdPut } from '../../../ytt/types/dataPropertyGroup/id';
import { ChildNodeData, ParentNodeData } from '../utils';
import { getAllDataPropertyList } from '@/api/TemplateManage';
interface Iprops {
  onCancel?: () => void;
  onConfirm?: () => void;
  parentNodeData?: ParentNodeData;
  childNodeData?: ChildNodeData;
  opType?: string;
}
const PropsGroupModal = ({ onCancel, onConfirm, parentNodeData, childNodeData, opType }: Iprops) => {
  const [modalApi, modalContextHolder] = Modal.useModal();
  const [form] = useForm();
  const [propertyList, setPropertyList] = useState<SelectProps['options']>([]);
  const [loading, setLoading] = useState(false);
  // 查询数据属性下拉列表
  useEffect(() => {
    getAllDataPropertyList().then(res => {
      let options = res.map(item => ({
        label: item.name,
        value: item.id,
      }));
      setPropertyList(options);
    });
  }, []);
  useEffect(() => {
    if (opType === 'edit') {
      // 数据回显
      form.setFieldsValue({
        name: childNodeData?.name,
        dataPropertyIds: childNodeData?.dataPropertyList?.map(item => item.id),
      });
    }
  }, [opType, childNodeData]);
  const onOk = () => {
    form.validateFields().then(res => {
      setLoading(true);
      const apis = opType === 'edit' ? apiDataPropertyGroupIdPut : apiDataPropertyGroupPost;
      apis({
        sceneId: parentNodeData?.sceneId!,
        id: childNodeData?.id,
        ...res,
      })
        .then(_ => {
          onConfirm?.();
        })
        .finally(() => setLoading(false));
    });
  };

  return (
    <>
      <Modal
        title="属性组"
        open
        // width={600}
        confirmLoading={loading}
        onCancel={() => {
          modalApi.confirm({
            title: '您是否确认放弃录入的信息?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
              onCancel?.();
            },
          });
        }}
        onOk={onOk}
      >
        <Form layout="vertical" form={form}>
          <div style={{ margin: '0 auto', width: '80%' }}>
            <Row>
              <Col span={24}>
                <Form.Item label="所属场景">{parentNodeData?.sceneName}</Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label="属性组名"
                  rules={[{ required: true }, { pattern: /^.{2,10}$/, message: '属性组名为2-10个字' }]}
                >
                  <Input maxLength={10} minLength={2}></Input>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="dataPropertyIds" label="可用数据属性" rules={[{ required: true }]}>
                  <Select mode="multiple" optionFilterProp="label" options={propertyList}></Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </Modal>
      {modalContextHolder}
    </>
  );
};
export default PropsGroupModal;
