import { PlusOutlined } from '@ant-design/icons';
import { Table } from '@maxtropy/components';
import { Button, Modal, Space } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { selectTenantColumns } from '../utils';
import TenantModal from './TenantModal';
import { TemplateListRes } from '../../../api/tenantAttribute';

interface SelectTemplateProps {
  value?: TemplateListRes[];
  onChange?: (value: TemplateListRes[]) => void;
}

const SelectTenant: React.FC<SelectTemplateProps> = ({ value, onChange }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<TemplateListRes[]>([]);
  const [modalApi, modalContextHolder] = Modal.useModal();

  const disabledIds = useMemo(() => {
    return (value ?? []).map(i => i.mcid);
  }, [value]);

  useEffect(() => {
    setDataSource(value ?? []);
  }, [value]);

  const unBind = (v: TemplateListRes) => {
    modalApi.confirm({
      title: '确定移出？',
      okText: '继续',
      onOk() {
        const newValue = (value ?? []).filter(i => i.mcid !== v.mcid);
        onChange?.(newValue);
      },
    });
  };

  const columns = [
    ...selectTenantColumns,
    {
      title: '操作',
      width: 250,
      fixed: 'right' as const,
      render: (v: TemplateListRes) => (
        <Button
          style={{ paddingLeft: 0, paddingRight: 0 }}
          type="link"
          onClick={() => {
            unBind(v);
          }}
        >
          移出
        </Button>
      ),
    },
  ];

  const onCancel = () => {
    setDataSource(value ?? []);
    setVisible(false);
  };

  const onOk = () => {
    onChange?.(dataSource);
    setVisible(false);
  };

  return (
    <>
      <Space direction="vertical">
        <Button type="dashed" onClick={() => setVisible(true)}>
          <PlusOutlined />
          添加
        </Button>
        <div />
      </Space>
      <Table sticky scroll={{ x: 1300, y: 400 }} columns={columns} dataSource={dataSource} />
      <Modal open={visible} title="租户选择" destroyOnClose onOk={onOk} onCancel={onCancel} width={900}>
        <TenantModal onChange={setDataSource} value={dataSource} disabledIds={disabledIds} />
      </Modal>
      {modalContextHolder}
    </>
  );
};

export default SelectTenant;
