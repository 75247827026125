import { useState } from 'react';
import {
  apiCarbonEmissionProcessDeletePost,
  apiCarbonEmissionProcessDisablePost,
  apiCarbonEmissionProcessEnablePost,
  apiCarbonEmissionProcessPagePost,
  apiCarbonIndustryAllPost,
  CarbonEmissionProcessPagePostResponse,
} from '@maxtropy/device-mgmt-apis';
import { List, Options, Query, Status } from './types';

export const useCarbonEmissions = (update: () => void) => {
  const [loading, setLoading] = useState(true);
  const [industryOptions, setIndustryOptions] = useState<Options[]>();
  const [tableData, setTableData] = useState<CarbonEmissionProcessPagePostResponse['list']>();

  const fetchIndustryOptions = async () => {
    const res = await apiCarbonIndustryAllPost();
    const tempOptions = res.map(item => ({
      label: item.name,
      value: item.id,
    }));
    setIndustryOptions(tempOptions);
  };

  const getTableData = async (
    searchParams: Query | undefined,
    pageOffset: number,
    pageSize: number,
    setTotalCount: (count: number) => void
  ) => {
    setLoading(true);
    try {
      const params = { ...searchParams, page: String(pageOffset), size: String(pageSize) };
      const res = await apiCarbonEmissionProcessPagePost(params);
      setTableData(res.list);
      setTotalCount(res.total as number);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeStatus = async (record: List) => {
    const { id, state } = record;
    const idString = String(id);
    if (state === Status.DRAFT || state === Status.DISABLE) {
      await apiCarbonEmissionProcessEnablePost({ id: idString });
    } else {
      await apiCarbonEmissionProcessDisablePost({ id: idString });
    }
    update();
  };

  const handleDelete = async (record: List) => {
    const { id } = record;
    const idString = String(id);

    try {
      await apiCarbonEmissionProcessDeletePost({ id: idString });
    } catch (err) {
      console.error('Error deleting record:', err);
    } finally {
      update();
    }
  };

  return {
    tableData,
    loading,
    industryOptions,
    getTableData,
    handleChangeStatus,
    handleDelete,
    fetchIndustryOptions,
  };
};
