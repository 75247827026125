import { FC, useCallback } from 'react';
import { Select, Spin } from 'antd';
import { SelectProps } from 'antd/es/select';
import styles from './index.module.scss';

import { debounce } from 'lodash-es';

const SearchLocation: FC<
  SelectProps<string> & {
    loading?: boolean;
  }
> = ({ loading = false, onSearch, ...props }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onLocalSearch = useCallback(
    debounce(v => {
      onSearch?.(v);
    }, 400),
    [onSearch]
  );

  return (
    <div className={styles.searchInput}>
      <Select
        showSearch
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        notFoundContent={null}
        onSearch={onLocalSearch}
        {...props}
      />
      <Spin spinning={loading} size="small" className={styles.spin} />
    </div>
  );
};

export default SearchLocation;
