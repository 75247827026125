import { fetch, Key } from '@maxtropy/components';
import qs from 'qs';
import { AlarmPushStatus, Operator, AlarmLevel, ChannelAppIDType } from '@/shared/types';
import { PageRequest, PageResponse } from './page';

export interface AlrmPushPage {
  id: number;
  serialNumber: string;
  name: string;
  status: AlarmPushStatus;
  rootMcid: string;
  tenantName: string;
  tenantCode: string;
  ouName: string;
  pushUserNum?: number;
  linkDeviceNum?: number;
  lastUpdateTime: string;
  lastUpdateUserName: string;
  lastUpdateSource: Operator;
}

export interface AlarmPushPageRequest extends PageRequest {
  name?: string;
  status?: AlarmPushStatus;
  rootMcid?: string;
}

export function getAlrmPushPage(query: AlarmPushPageRequest): Promise<PageResponse<AlrmPushPage>> {
  return fetch(`/api/device-alarm-push-strategy/page?${qs.stringify(query, { indices: false })}`);
}

export function changeAlrmStatusOrDelete(pushId: number, status: AlarmPushStatus): Promise<boolean> {
  return fetch(`/api/device-alarm-push-strategy/update-status/${pushId}?status=${status}`, {
    method: 'PUT',
  });
}

export interface PushCenterStaffLinkRequestList {
  staffId: number;
  thirdPartyIdList: ChannelAppIDType[];
}

export interface OutsiderRequest {
  contactPhone: string;
  contactName: string;
  thirdPartyIdList: ChannelAppIDType[];
}

export interface OutsiderResponse extends OutsiderRequest {
  id: Key;
}

export interface CreateAlarmPushRequest {
  name: string;
  rootMcid: string;
  ouId: number;
  levels: AlarmLevel[];
  pushCenterStaffLinkRequestList: PushCenterStaffLinkRequestList[];
  deviceIdList: number[];
  outsidersList: OutsiderRequest[];
}

export function createAlarmPush(params: CreateAlarmPushRequest): Promise<boolean> {
  return fetch(`/api/device-alarm-push-strategy/insert`, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'x-tenant-mcid': `${params.rootMcid}`,
    },
  });
}

export interface UpdateAlarmPushRequest {
  name: string;
  levels: AlarmLevel[];
  oldPushCenterStaffLinkRequestList: PushCenterStaffLinkRequestList[];
  newPushCenterStaffLinkRequestList: PushCenterStaffLinkRequestList[];
  addDeviceIdList: number[];
  deleteDeviceIdList: number[];
  oldOutsidersIds: Key[];
  newOutsiders: OutsiderRequest[];
}

export function updateAlarmPush(pushId: number, params: UpdateAlarmPushRequest): Promise<boolean> {
  return fetch(`/api/device-alarm-push-strategy/update/${pushId}`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });
}

export interface PushCenterStaffLinkVoList extends AlrmPushStaffPageResponse {
  thirdPartyAppId: ChannelAppIDType[];
}

export interface AlarmPush {
  id: number;
  name: string;
  tenantName: string;
  rootMcid: string;
  ouId: number;
  ouName: string;
  levels: AlarmLevel[];
  pushCenterStaffLinkVoList: PushCenterStaffLinkVoList[];
  deviceAlarmPushDeviceVoList: AlrmPushDevice[];
  outsiders: OutsiderResponse[];
}

export function getAlarmPush(id: string): Promise<AlarmPush> {
  return fetch(`/api/device-alarm-push-strategy/details?id=${id}`);
}

export interface AlarmPushDevicePageRequest extends PageRequest {
  ouId: string;
  rootMcid: string;
  name?: string;
  deviceTypes?: Array<number> | undefined;
}

export interface AlrmPushDevice {
  deviceId: number;
  code: string;
  name: string;
  deviceType: string;
  customerName: string;
}

export function getAlarmPushDevicePage(query: AlarmPushDevicePageRequest): Promise<PageResponse<AlrmPushDevice>> {
  return fetch(`/api/device-alarm-push-strategy/page-device?${qs.stringify(query, { indices: false })}`);
}

export interface AlarmPushStaffPageRequest extends PageRequest {
  ouId: string;
  name?: string;
  humanCode?: string;
  staffCode?: string;
  phone?: string;
}

export interface AlrmPushStaffPageResponse {
  staffId: number;
  humanCode: string;
  name: string;
  phone: string;
  staffCode: string;
  customerName: string;
}

export function getAlarmPushStaffPage(
  query: AlarmPushStaffPageRequest
): Promise<PageResponse<AlrmPushStaffPageResponse>> {
  return fetch(`/api/device-alarm-push-strategy/page-staff?${qs.stringify(query, { indices: false })}`);
}

// export function getThirdAppList(): Promise<ThirdApp[]> {
//   return fetch(`/api/device-alarm-push-strategy/list-third-app`)
// }
