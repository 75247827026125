import { CardSize } from './types';
import classnames from 'classnames/bind';
import styles from './index.module.scss';
import { Button } from 'antd';

const cx = classnames.bind(styles);

interface SizeTagProps {
  size: number;
  active: boolean;
  disabled?: boolean;
  onClick: () => void;
}

const SizeTag: React.FC<SizeTagProps> = ({ size, active, disabled, onClick }) => {
  const sizeText = size === CardSize.SMALL ? '小尺寸' : size === CardSize.MIDDLE ? '中尺寸' : '大尺寸';
  return (
    <Button className={cx('sizeTag', active && 'sizeTagHover')} disabled={disabled} onClick={onClick}>
      {sizeText}
    </Button>
  );
};

export default SizeTag;
