import React from 'react';
import { Button, List, ListProps, Popconfirm, Space } from 'antd';
import AppItem from './AppItem';
import { AppStatus } from '../../../api/openPlatform-const';
import { ApplicationProps, updateApplicationStatus } from '../../../api/openPlatform-application';
import { Link } from 'react-router-dom';
import { PermissionsType } from '@/common/permissionsConst';
import { useMgmtPermissions } from '@/components/Layout/hooks';

interface AppListProps extends ListProps<any> {
  dataSource: ApplicationProps[];
  update?: () => void;
}
const AppList: React.FC<AppListProps> = props => {
  const permissions = useMgmtPermissions();

  // 禁用与 启用
  const onDisabledOrEnableApp = (id: number, status: AppStatus) => {
    updateApplicationStatus(id, {
      status: status === AppStatus.ENABLE ? AppStatus.DISABLE : AppStatus.ENABLE,
    }).then(_ => {
      props.update?.();
    });
  };

  return (
    <div style={{ width: '100%' }}>
      <List
        itemLayout="horizontal"
        dataSource={props.dataSource}
        renderItem={item => (
          <List.Item>
            <AppItem
              data={item}
              operate={
                <Space>
                  {permissions?.includes(PermissionsType.B_UPDATEAPP) && (
                    <Link to={`/open-platform/open-platform/app/updateBasicInfo/${item.id}`}>编辑</Link>
                  )}
                  {item.status === AppStatus.ENABLE && (
                    <Link to={`/open-platform/open-platform/app/updateAuth/${item.id}`}>授权</Link>
                  )}

                  {item.status === AppStatus.ENABLE && (
                    <Popconfirm
                      title="你确定要禁用该应用吗？ 禁用后该应用将不可用！"
                      onConfirm={() => onDisabledOrEnableApp(item.id, item.status)}
                      okText="确定"
                      cancelText="取消"
                    >
                      <Button style={{ padding: 0 }} type="link">
                        禁用
                      </Button>
                    </Popconfirm>
                  )}
                  {item.status === AppStatus.DISABLE && (
                    <Popconfirm
                      title="确认启用该应用吗？启用后该应用将重新可用！"
                      onConfirm={() => onDisabledOrEnableApp(item.id, item.status)}
                      okText="确定"
                      cancelText="取消"
                    >
                      <Button style={{ padding: 0 }} type="link">
                        启用
                      </Button>
                    </Popconfirm>
                  )}
                  <Link to={`/open-platform/open-platform/app/detail/${item.id}`}>查看</Link>
                </Space>
              }
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default AppList;
