import React from 'react';
import { Divider, Space } from 'antd';
import { AttributeField, DeviceAttribute } from '../../types';
import FieldItem from '../FieldItem';

export interface AttributeItemProps {
  attribute: DeviceAttribute;
  disabled: boolean;
  isDetail?: boolean;
  style?: React.CSSProperties;
  className?: string;
  buttons?: React.ReactNode;
  hideTitle?: boolean;
  checkable?: boolean;
  onCheck?: (checked: boolean, field: AttributeField) => void;
}

const AttributeItem: React.FC<AttributeItemProps> = ({
  attribute,
  disabled,
  isDetail,
  style,
  className,
  buttons,
  hideTitle,
  checkable,
  onCheck,
}) => {
  return (
    <>
      <div
        className={className}
        style={{
          borderBottom: '1px dashed rgba(var(--base-text-color), .12)',
          paddingBottom: 10,
          paddingTop: 20,
          fontSize: 14,
          ...style,
        }}
      >
        {!hideTitle && (
          <Space size={10}>
            {<div style={{ fontWeight: 'bold', color: 'var(--heading-color)' }}>{attribute.name}：</div>}
            {buttons}
          </Space>
        )}
        <div style={{ paddingLeft: 28, paddingTop: 20 }}>
          {attribute.deviceFields.map(item => (
            <FieldItem
              key={item.id}
              disabled={disabled}
              isDetail={isDetail}
              field={item}
              checkable={checkable}
              onCheck={onCheck}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default AttributeItem;
