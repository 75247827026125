import React, { ReactNode } from 'react';
import { Col, Image, Row } from 'antd';
import styles from './index.module.scss';
import { ApplicationProps } from '../../../api/openPlatform-application';
import dayjs from 'dayjs';
import appDefault from '../../../assets/image/app-default.png';
import { getRealUrl } from '@maxtropy/components';
import { VisibleRangeType } from '../Create/AppAuthStepTwo/components/InterfaceAuth';

interface AppItemProps {
  operate: ReactNode;
  data: ApplicationProps;
}

const AppItem: React.FC<AppItemProps> = props => {
  const { data, operate } = props;
  return (
    <Row className={styles.itemWrapper}>
      <Image
        width={120}
        height={60}
        src={data.logoResource ? getRealUrl(data.logoResource) : appDefault}
        preview={false}
      />
      <div className={styles.itemContent}>
        <h3>{data.name}</h3>
        <Row className={styles.detail}>
          <Col span={7}>创建时间：{dayjs(data.createTime).format('YYYY-MM-DD HH:mm:ss')}</Col>
          <Col span={6}>服务商：{data.independentSoftwareVendorName}</Col>
          <Col span={6}>应用ID：{data.appKey}</Col>
          <Col span={5}>{operate}</Col>
        </Row>
        <Row className={styles.detail}>
          <Col span={7}>最后修改时间：{dayjs(data.updateTime).format('YYYY-MM-DD HH:mm:ss')}</Col>
          <Col span={6}>创建人：{data.createByUsername}</Col>
          <Col span={6}>
            接口数量：{data.apiAuthorityType === VisibleRangeType.ALL ? '全部' : (data.apiAuthorities ?? []).length}
          </Col>
        </Row>
      </div>
    </Row>
  );
};

export default AppItem;
