import React, { FC, useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Space,
  Button,
  Divider,
  Form,
  Input,
  Dropdown,
  Menu,
  Checkbox,
  Image,
  Select,
  Modal,
  Radio,
  Col,
  Cascader,
} from 'antd';
import { Table, Wrapper, EllipsisSpan, Paging, usePaging, getRealUrl, useUpdate, useAsync } from '@maxtropy/components';

import { DownOutlined, PlusOutlined } from '@ant-design/icons';

import CascadingMultipleSelector from '@/shared/components/CascadingMultipleSelector';
import Filter from '@/shared/components/Filter';

import dayjs from 'dayjs';

import styles from './index.module.scss';
import { getDeviceTypeData } from '../../../api/attribute';
import { getDeviceList, Device, DeviceListRequest, updateDeviceStatus, getRoot } from '../../../api/device';
import { DeviceStatus, DeviceStatusDisplay, OperatorDisplay } from '@/shared/types';
import {
  formatOptionData,
  getAllChild,
  getChildNodesByParentIds,
} from '@/shared/components/CascadingMultipleSelector/utils';
import { useHasPermission, useQuery } from '../../../utils/utils';
import { PermissionsType } from '../../../common/permissionsConst';
import qs from 'qs';

import SelectRuleGroupModal from '../components/SelectRuleGroupModal';
import { DefaultOptionType } from 'antd/es/cascader';

type SearchParams = Omit<DeviceListRequest, 'page' | 'size'>;

const routes = [{ name: 'Iot配置' }, { name: '设备管理' }, { name: '设备管理' }];

const columns = [
  {
    title: '设备编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备图片',
    dataIndex: 'pic',
    ellipsis: { showTitle: true },
    render: (v: string) =>
      v ? <Image width={50} wrapperClassName={styles.image} src={getRealUrl(v)} /> : <div style={{ height: 50 }} />,
  },
  {
    title: '所属类目',
    dataIndex: 'typeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属租户',
    dataIndex: 'tenantCode',
    ellipsis: { showTitle: true },
    render: (v: string, record: Device) => <EllipsisSpan value={`${v} ${record.tenantName}`} />,
  },
  {
    title: '所在组织简称',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '第三方对接',
    dataIndex: 'thirdParty',
    ellipsis: { showTitle: true },
    render: (v: boolean) => <EllipsisSpan value={v ? '是' : '否'} />,
  },
  {
    title: '所在组织全称',
    dataIndex: 'customerFullName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '标签',
    dataIndex: 'tags',
    ellipsis: { showTitle: true },
    render: (v?: string[]) => <EllipsisSpan value={v ? v.join(',') : '--'} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (value: DeviceStatus) => {
      return <EllipsisSpan value={DeviceStatusDisplay[value]} />;
    },
  },
  {
    title: '第三方对接',
    dataIndex: 'thirdParty',
    ellipsis: { showTitle: true },
    render: (v: boolean) => {
      return <EllipsisSpan value={v ? '是' : '否'} />;
    },
  },
  {
    title: '最后操作时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '最后操作人',
    dataIndex: 'updateByUsername',
    ellipsis: { showTitle: true },
    render: (value: string, record: Device) => {
      return <EllipsisSpan value={`${value}（${OperatorDisplay[record.updateSource]}）`} />;
    },
  },
];

interface FilterParams {
  codeOrName: string | undefined;
  typeId: Array<Array<number>> | undefined;
  status?: DeviceStatus;
  rootMcid?: string;
  invalid?: boolean;
  tag?: string;
  thirdParty?: boolean;
}

const DeviceList: FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [updateState, update] = useUpdate();
  const canBatchConfigAttribute = useHasPermission(PermissionsType.B_BATCHCONFIGATTRIBUTE);
  const canBatchConfigEdgeInfo = useHasPermission(PermissionsType.B_BATCHCONFIGEDGEINFO);

  const deviceTypeData = useAsync(getDeviceTypeData);

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const localParamsStr = sessionStorage.getItem(window.location.pathname);
  const localParamsObj = localParamsStr ? (JSON.parse(localParamsStr) as SearchParams) : {};
  const [physicalModelId, setPhysicalModelId] = useState<number>(); // 物模型型号ID

  const [searchParams, setSearchParams] = useState<SearchParams>(localParamsObj);

  useEffect(() => {
    if (localParamsStr) {
      form.setFieldsValue({
        ...localParamsObj,
        typeId: localParamsObj.originDeviceType,
      });
    }
    return () => {
      sessionStorage.removeItem(window.location.pathname);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [statusDevice, setStatusDevice] = useState<Device>();
  const [modalApi, modalContextHolder] = Modal.useModal();

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        getDeviceList({
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) setTotalCount(res.total);
          return res.list;
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [pageOffset, pageSize, searchParams, setTotalCount, updateState]
    )
  );

  const [selectRuleGroupDevice, setSelectRuleGroupDevice] = useState<Device>();

  const tenant = useAsync(getRoot, []);

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 250,
      fixed: 'right' as const,
      render: (value: undefined, record: Device) => {
        return (
          <Space size={16}>
            <Dropdown
              disabled={record.status === DeviceStatus.INVALID}
              overlay={
                <Menu
                  onClick={() => {}}
                  items={[
                    {
                      show: true,
                      key: '1',
                      label: <Link to={`/device/manage/device/${record.id}/edit`}>编辑资产信息</Link>,
                    },
                    {
                      show: true,
                      key: '2',
                      label: (
                        <Link to={`/device/manage/device/${record.id}/attribute/edit`}>{`${
                          record.existAttribute ? '编辑' : '添加'
                        }固定信息`}</Link>
                      ),
                    },
                    {
                      show: !record.thirdParty,
                      key: '3',
                      label: (
                        <Link
                          to={`/device/manage/device/${record.id}/dataMining/${
                            record.existEdgeTemplate ? 'edit' : 'new'
                          }`}
                        >{`${record.existEdgeTemplate ? '编辑' : '添加'}数采信息`}</Link>
                      ),
                    },
                    {
                      show: true,
                      key: '4',
                      label: (
                        <span
                          onClick={() => {
                            record.physicalModelId !== null && setPhysicalModelId(record.physicalModelId);
                            if (record.existEdgeTemplate) {
                              setSelectRuleGroupDevice(record);
                            } else {
                              modalApi.warning({
                                title: '请先添加设备的数采信息',
                              });
                            }
                          }}
                        >
                          {record.existRuleGroup ? '编辑' : '添加'}报警规则
                        </span>
                      ),
                    },
                  ].filter(item => item.show)}
                />
              }
            >
              <Button style={{ paddingLeft: 0, paddingRight: 0 }} type="link" onClick={e => e.preventDefault()}>
                编辑
                <DownOutlined />
              </Button>
            </Dropdown>
            <Button
              style={{ paddingLeft: 0, paddingRight: 0 }}
              disabled={record.status === DeviceStatus.INVALID}
              type="link"
              onClick={() => {
                setStatusDevice(record);
              }}
            >
              状态变更
            </Button>
            <Dropdown
              overlay={
                <Menu
                  onClick={() => {}}
                  items={[
                    {
                      key: '1',
                      label: (
                        <Link to={`/device/manage/device/${record.id}/detail?thirdParty=${!!record.thirdParty}`}>
                          查看设备信息
                        </Link>
                      ),
                    },
                    {
                      key: '2',
                      disabled: !record.existRuleGroup,
                      label: (
                        <Link target="_blank" to={`/device/rule/list/group/detail/${record.ruleGroupId}`}>
                          查看报警规则
                        </Link>
                      ),
                    },
                  ]}
                />
              }
            >
              <Button style={{ paddingLeft: 0, paddingRight: 0 }} type="link" onClick={e => e.preventDefault()}>
                查看
                <DownOutlined />
              </Button>
            </Dropdown>
          </Space>
        );
      },
    },
  ];

  const onFinish = (val: FilterParams) => {
    // const typeId = getAllChild(val.typeId, formatOptionData(deviceTypeData));
    const typeId = getChildNodesByParentIds(val.typeId, deviceTypeData?.tree);
    const params: SearchParams = {
      codeOrName: val.codeOrName,
      rootMcid: val.rootMcid,
      typeId: typeId,
      status: val.status,
      invalid: val.invalid ?? false,
      tag: val.tag,
      thirdParty: val.thirdParty,
    };
    sessionStorage.setItem(
      window.location.pathname,
      JSON.stringify({
        ...params,
        originDeviceType: val.typeId,
      })
    );
    setSearchParams(params);
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    setPageOffset(1);
    sessionStorage.removeItem(window.location.pathname);
  };

  const onOk = () => {
    if (statusDevice) {
      updateDeviceStatus({
        deviceId: statusDevice.id,
        status: statusDevice.status,
      }).then(res => {
        if (res) {
          setStatusDevice(undefined);
          update();
        }
      });
    }
  };
  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  const filters = (
    <Filter<FilterParams>
      form={form}
      onFinish={val => onFinish(val as FilterParams)}
      onReset={onReset}
      collapseItems={
        <>
          <Col span={4}>
            <Form.Item name="rootMcid" label="所属租户">
              <Select style={{ width: '100%' }} placeholder="请选择">
                {tenant.map(i => (
                  <Select.Option key={i.mcid} value={i.mcid}>
                    {i.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="invalid" valuePropName="checked">
              <Checkbox>显示已作废设备</Checkbox>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="tag" label="标签">
              <Input placeholder="请输入" />
            </Form.Item>
          </Col>
        </>
      }
    >
      <>
        <Col span={4}>
          <Form.Item name="codeOrName" label="编号/名称">
            <Input placeholder={'请输入编号或名称查询'} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="status" label="状态">
            <Select style={{ width: '100%' }} placeholder="请选择">
              <Select.Option value={DeviceStatus.USING}>{DeviceStatusDisplay[DeviceStatus.USING]}</Select.Option>
              <Select.Option value={DeviceStatus.REPAIRING}>
                {DeviceStatusDisplay[DeviceStatus.REPAIRING]}
              </Select.Option>
              <Select.Option value={DeviceStatus.DEACTIVATING}>
                {DeviceStatusDisplay[DeviceStatus.DEACTIVATING]}
              </Select.Option>
              <Select.Option value={DeviceStatus.CALLOUT}>{DeviceStatusDisplay[DeviceStatus.CALLOUT]}</Select.Option>
              <Select.Option value={DeviceStatus.SOLD}>{DeviceStatusDisplay[DeviceStatus.SOLD]}</Select.Option>
              <Select.Option value={DeviceStatus.SCRAPPED}>{DeviceStatusDisplay[DeviceStatus.SCRAPPED]}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="typeId" label="所属类目">
            <Cascader
              options={formatOptionData(deviceTypeData)}
              allowClear={false}
              fieldNames={{ children: 'child' }}
              multiple
              maxTagCount="responsive"
              showSearch={{ filter }}
              placeholder={'请选择所属类目'}
            />
          </Form.Item>
        </Col>
      </>
    </Filter>
  );

  return (
    <Wrapper routes={routes} filters={filters}>
      <div className={styles.operationArea}>
        <Space>
          <Button type="primary">
            <Link to="/device/manage/device/create">
              <PlusOutlined />
              新建设备
            </Link>
          </Button>
          <Button type="primary">
            <Link to="/device/manage/device/daq-deployment">批量配置数采信息</Link>
          </Button>
          <Button type="primary">
            <Link to="/device/manage/device/rule-group/bind">批量配置报警规则</Link>
          </Button>
          {canBatchConfigEdgeInfo && (
            <Button type="primary">
              <Link to="/device/manage/device/edge/update">批量修改数采信息</Link>
            </Button>
          )}
          {canBatchConfigAttribute && (
            <Button type="primary">
              <Link to="/device/manage/device/attribute/batch">批量配置固定属性</Link>
            </Button>
          )}
        </Space>
      </div>
      <Table
        rowKey={'id'}
        sticky={{
          getContainer: () => {
            return document.querySelector('.central-menu-content') as HTMLElement;
          },
        }}
        scroll={{ x: 1900 }}
        loading={isLoading}
        dataSource={data}
        columns={buildColumns}
      />
      <Paging pagingInfo={pagingInfo} />
      <Modal
        className={styles.modal}
        open={!!statusDevice}
        width={600}
        title="状态变更"
        onOk={onOk}
        onCancel={() => {
          setStatusDevice(undefined);
        }}
      >
        <Radio.Group
          value={statusDevice?.status}
          onChange={e => {
            setStatusDevice({
              ...statusDevice!,
              status: e.target.value,
            });
          }}
        >
          <Radio value={DeviceStatus.USING}>{DeviceStatusDisplay[DeviceStatus.USING]}</Radio>
          <Radio value={DeviceStatus.REPAIRING}>{DeviceStatusDisplay[DeviceStatus.REPAIRING]}</Radio>
          <Radio value={DeviceStatus.DEACTIVATING}>{DeviceStatusDisplay[DeviceStatus.DEACTIVATING]}</Radio>
          <Radio value={DeviceStatus.CALLOUT}>{DeviceStatusDisplay[DeviceStatus.CALLOUT]}</Radio>
          <Radio value={DeviceStatus.SOLD}>{DeviceStatusDisplay[DeviceStatus.SOLD]}</Radio>
          <Radio value={DeviceStatus.SCRAPPED}>{DeviceStatusDisplay[DeviceStatus.SCRAPPED]}</Radio>
          <Radio value={DeviceStatus.INVALID}>
            {DeviceStatusDisplay[DeviceStatus.INVALID]}（历史数据保留，一旦作废，不允许变更状态和编辑）
          </Radio>
        </Radio.Group>
      </Modal>
      <SelectRuleGroupModal
        visible={selectRuleGroupDevice !== undefined}
        device={selectRuleGroupDevice}
        physicalModelId={physicalModelId}
        updateFn={update}
        onClose={() => {
          setSelectRuleGroupDevice(undefined);
        }}
      />
      {modalContextHolder}
    </Wrapper>
  );
};

export default DeviceList;
