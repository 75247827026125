import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { Gender, LeftNavPreference, UserStatus } from './cc-const';

export interface Paged<T> {
  total: number;
  offset: number;
  count: number;
  contents: T[];
}

export interface PageRequest {
  offset?: number;
  count?: number;
}

export interface TenantUserListRequest extends PageRequest {
  humanCode?: string;
  username?: string;
  cellphone?: string;
  status?: number;
}

export interface TenantUserListResponse {
  muid: string;
  username: string;
  name: string;
  cellphone: string;
  expired: boolean;
  disabled: boolean;
  passwordUpdateTime: string;
  createTime: string;
  updateTime: string;
  status: number;
  gender: number;
  birthday: string;
  email: string;
  wechat: string;
  qq: string;
  headPic: string;
  humanCode: string;
  lockTime?: string;
  lockStatus: boolean;
}

export interface TenantUserDetailResponse {
  muid: string;
  username: string;
  name: string;
  cellphone: string;
  expired: boolean;
  disabled: boolean;
  passwordUpdateTime: string;
  createTime: string;
  updateTime: string;
  status: number;
  gender: number;
  birthday: string;
  email: string;
  wechat: string;
  qq: string;
  headPic: string;
  humanCode: string;
  navigationPreference: LeftNavPreference;
}

export function getTenantUserList(query: TenantUserListRequest): Promise<Paged<TenantUserListResponse>> {
  return fetch(`/api/customer/user/page?${qs.stringify(query, { indices: false })}`);
}

export function batchDisableTenantUserList(query: string[]): Promise<boolean> {
  return fetch(`/api/customer/user/disable/batch?${qs.stringify({ muids: query }, { arrayFormat: 'repeat' })}`);
}

export function getTenantUserDetail(muid: string | undefined): Promise<TenantUserDetailResponse> {
  return fetch(`/api/customer/user/get?muid=${muid}`);
}

interface OperateCustomerRequest {
  muid: string;
}

interface ResetCustomerPasswordRes {
  password?: string;
}

export function enableCustomer(body: OperateCustomerRequest): Promise<boolean> {
  return fetch(`/api/customer/user/enable?muid=${body.muid}`);
}

export function freezeCustomer(body: OperateCustomerRequest): Promise<boolean> {
  return fetch(`/api/customer/user/freeze?muid=${body.muid}`);
}

export function disableCustomer(body: OperateCustomerRequest): Promise<boolean> {
  return fetch(`/api/customer/user/disable?muid=${body.muid}`);
}

export function resetCustomerPassword(body: OperateCustomerRequest): Promise<ResetCustomerPasswordRes> {
  // return fetch(`/api/customer/user/reset-password?muid=${body.muid}`);
  return fetch(`/api/customer/user/reset-password-return?muid=${body.muid}`);
}

export function unlockCustomerUser(muid: string) {
  return fetch<boolean>(`/api/customer/user/unlock?muid=${muid}`);
}

export interface CustomerStaffResponse {
  id: number;
  mcid: string;
  muid: string;
  admin: boolean;
  status: number;
  username: string;
  staffCode: string;
  parentMcid: string;
  parentName: string;
  rootMcid: string;
  rootName: string;
  rootFullName: string;
}

export function getCustomerStaff(muid: string | undefined): Promise<CustomerStaffResponse[]> {
  return fetch(`/api/customer/user/getStaff?muid=${muid}`);
}

export function getByUsername(username: string): Promise<TenantUserDetailResponse> {
  return fetch(`/api/customer/user/getByUsername?username=${username}`);
}

export function getListCustomer(value: string): Promise<TenantUserDetailResponse[]> {
  return fetch(`/api/customer/user/list?value=${value}`);
}

export function existStaffUsername(username: string, mcid: string): Promise<boolean> {
  return fetch(`/api/customer/exist-staff-username?username=${username}&mcid=${mcid}`);
}

export function checkCellphone(cellphone: string): Promise<TenantUserDetailResponse> {
  return fetch(`/api/customer/user/search?cellphone=${cellphone}`);
}

export function checkUsername(username: string) {
  return fetch(`/api/customer/user/check?username= ${encodeURIComponent(username)}`);
}

export interface PostCustomerUserRequest {
  name: string;
  cellphone: string;
  username: string;
  status?: UserStatus;
  gender?: Gender;
  email: string;
  birthday?: string;
  qq?: string;
  wechat?: string;
  headPic?: string;
  muid?: string;
  navigationPreference?: LeftNavPreference;
}

export function createCustomerUser(body: PostCustomerUserRequest): Promise<PostCustomerUserRequest> {
  return fetch(`/api/customer/user/create`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
}

interface OrganizationDataProps {
  canAddSub?: boolean;
  code?: string;
  hasPermission?: boolean;
  levelName?: string;
  mcid: string;
  name?: string;
}

export interface OrganizationProps {
  data: OrganizationDataProps;
  parent?: number;
  children: OrganizationProps[];
}

export function getOrganization(mcid: string): Promise<OrganizationProps> {
  return fetch(`/api/customer/organization?mcid=${mcid}`);
}

export const getDefaultPassword = () => fetch<{ defaultPassword: string }>('/api/customer/user/defaultPassword');
