import { Table, Paging, usePaging, useUpdate, EllipsisSpan } from '@maxtropy/components';
import { Form, Col, Input } from 'antd';
import { FC, Key, useEffect, useState } from 'react';
import Filter from '@/shared/components/Filter';

import styles from './index.module.scss';
import { BatchRuleGroupDeviceRequest } from '../../../../api/ruleGroup';
import { IotProtocolType } from '@/shared/types';
import {
  V2RuleGroupDevicePageDeviceBindingPostResponse,
  apiV2RuleGroupDevicePageDeviceBindingPost,
} from '@maxtropy/device-mgmt-apis-v2';

export const columns = [
  {
    title: '设备编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    dataIndex: 'typeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '物模型型号',
    dataIndex: 'modelNo',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属组织',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export interface DevicesModalProps {
  value?: Key[];
  onChange?: (value: Key[]) => void;
  rootMcid?: string;
  type?: number[];
  iotProtocol?: IotProtocolType;
  deviceTypeId?: number;
  objectModalType?: number;
}

type SearchParams = Omit<BatchRuleGroupDeviceRequest, 'rootMcid' | 'iotProtocol' | 'size' | 'page' | 'deviceType'>;

const DevicesModal: FC<DevicesModalProps> = ({
  value,
  onChange,
  rootMcid,
  deviceTypeId,
  iotProtocol,
  objectModalType,
}) => {
  const [form] = Form.useForm();

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [devices, setDevices] = useState<V2RuleGroupDevicePageDeviceBindingPostResponse['list']>([]);
  const [updateState] = useUpdate();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (rootMcid && iotProtocol !== undefined) {
      setLoading(true);
      apiV2RuleGroupDevicePageDeviceBindingPost({
        ...searchParams,
        rootMcid,
        deviceTypeId,
        iotProtocol,
        physicalModelId: objectModalType,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        setLoading(false);
        if (res) {
          setTotalCount(res.total ?? 0);
          res.list && setDevices(res.list);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootMcid, iotProtocol, pageOffset, pageSize, searchParams, setTotalCount, updateState]);

  const rowSelection = {
    onChange: (selectedRowKeys: Key[]) => {
      console.log(selectedRowKeys);
      onChange?.(selectedRowKeys);
    },
    preserveSelectedRowKeys: true,
    selectedRowKeys: value ?? [],
  };

  const onFinish = (value: SearchParams) => {
    setSearchParams(value);
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    form.setFieldsValue({
      name: undefined,
    });
    setPageOffset(1);
  };

  const filters = (
    <Filter
      form={form}
      onFinish={val => {
        onFinish(val);
      }}
      onReset={onReset}
    >
      <>
        <Col span={12}>
          <Form.Item name="name" label="编号/名称">
            <Input placeholder="请输入编号或名称查询" />
          </Form.Item>
        </Col>
      </>
    </Filter>
  );

  return (
    <>
      {filters}
      <div className={styles.tips}>
        <div>
          已选择<span className={styles.red}>{(value ?? []).length}</span>项
        </div>
      </div>
      <Table
        loading={loading}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        sticky
        scroll={{ y: 300 }}
        rowKey="deviceId"
        columns={columns}
        dataSource={devices}
      />
      <Paging pagingInfo={pagingInfo} />
    </>
  );
};

export default DevicesModal;
