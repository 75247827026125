import qs from 'qs';
import { fetch } from '@maxtropy/components';
import { LogType, Paged, PageRequest } from './openPlatform-const';

export interface LogListQuery extends PageRequest {
  targetId: string;
  targetType: string;
}

export interface LogProps {
  id: number;
  operatorName: string;
  timestamp: string;
  type: LogType;
  operateSourceId: number;
  beforeInfo?: any;
  afterInfo?: any;
  operateTargetId: number;
  operateTargetType: string;
  operateSourceType: string;
  additionalInfo: string;
}

export function queryLogListPaged(query: LogListQuery): Promise<Paged<LogProps>> {
  return fetch(`/api/log/page?${qs.stringify(query, { indices: false })}`);
}
