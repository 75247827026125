import { PlusOutlined } from '@ant-design/icons';
import { Table } from '@maxtropy/components';
import { Button, Modal, Space } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import TemplateModal from './TemplateModal';
import { selectTemplateColumns } from '../utils';
import { EdgeDeviceTemplate } from '@/shared/types';
import { EdgeTemplatePageTemplateRes } from '../../../api/edgeTemplate';

interface SelectTemplateProps {
  value?: EdgeTemplatePageTemplateRes[];
  onChange?: (value: EdgeTemplatePageTemplateRes[]) => void;
}

const SelectTemplate: React.FC<SelectTemplateProps> = ({ value, onChange }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<EdgeTemplatePageTemplateRes[]>([]);
  const [modalApi, modalContextHolder] = Modal.useModal();

  const disabledIds = useMemo(() => {
    return (value ?? []).map(i => i.id);
  }, [value]);

  useEffect(() => {
    setDataSource(value ?? []);
  }, [value]);

  const unBind = (v: EdgeDeviceTemplate) => {
    modalApi.confirm({
      title: '确定移出？',
      okText: '继续',
      onOk() {
        const newValue = (value ?? []).filter(i => i.id !== v.id);
        onChange?.(newValue);
      },
    });
  };

  const columns = [
    ...selectTemplateColumns,
    {
      title: '操作',
      width: 100,
      fixed: 'right' as const,
      render: (v: EdgeDeviceTemplate) => (
        <Button
          style={{ paddingLeft: 0, paddingRight: 0 }}
          type="link"
          onClick={() => {
            unBind(v);
          }}
        >
          移出
        </Button>
      ),
    },
  ];

  const onCancel = () => {
    setDataSource(value ?? []);
    setVisible(false);
  };

  const onOk = () => {
    onChange?.(dataSource);
    setVisible(false);
  };

  return (
    <>
      <Space direction="vertical">
        <Button type="dashed" onClick={() => setVisible(true)}>
          <PlusOutlined />
          添加
        </Button>
        <div />
      </Space>
      <Table sticky scroll={{ x: 1300, y: 400 }} columns={columns} dataSource={dataSource} />
      <Modal open={visible} title="模板选择" destroyOnClose onOk={onOk} onCancel={onCancel} width={900}>
        <TemplateModal onChange={setDataSource} value={dataSource} disabledIds={disabledIds} />
      </Modal>
      {modalContextHolder}
    </>
  );
};

export default SelectTemplate;
