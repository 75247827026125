import {
  apiV2MiniAppToolPagePost,
  V2MiniAppToolGetListPostResponse,
  V2MiniAppToolPagePostRequest,
} from '@maxtropy/cc-mgmt-apis-v2';
import { apiMiniAppCategoryListPost } from '@maxtropy/cc-mgmt-apis';
import { EllipsisSpan, getRealUrl, Paging, usePaging } from '@maxtropy/components';
import { useRequest } from 'ahooks';
import { Button, Col, Form, Input, Row, Select, Space, Table } from 'antd';
import React, { useState } from 'react';
import { CardSize, List } from './types';
import SizeTag from './SizeTag';

enum CardSupportState {
  NOT_SUPPORT = 0,
  SUPPORT = 1,
}

const { Item } = Form;

const otherColumns = [
  {
    title: '工具',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (text: string, record: List) => (
      <div>
        <img src={getRealUrl(record.icon)} alt="" style={{ marginRight: '10px' }} width="44" />
        <span>{text}</span>
      </div>
    ),
  },
  {
    title: '类型',
    dataIndex: 'categoryName',
    ellipsis: { showTitle: true },
    render: (text: string) => <EllipsisSpan value={text} />,
  },
];

interface Props {
  list?: List[];
  listId?: number;
  currentHomePageInfo?: V2MiniAppToolGetListPostResponse['list'];
  setList: React.Dispatch<React.SetStateAction<List[] | undefined>>;
}
const AddCardModal: React.FC<Props> = ({ listId, currentHomePageInfo, list, setList }) => {
  const [form] = Form.useForm();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  // 类型列表
  const { data: categoryList } = useRequest(() => apiMiniAppCategoryListPost());
  // 查询参数
  const [searchParams, setSearchParams] = useState<V2MiniAppToolPagePostRequest>();

  // 选择卡片尺寸
  const handleSizeClick = (
    size: number,
    rowIndex: number,
    setList: React.Dispatch<React.SetStateAction<List[] | undefined>>
  ) => {
    return () => {
      setList(prev => {
        const newList = [...(prev as List[])];
        if (newList[rowIndex].cardSize === size) {
          newList[rowIndex].cardSize = undefined;
          return newList;
        }

        newList[rowIndex].cardSize = size;
        return newList;
      });
    };
  };

  const { loading } = useRequest(
    () => {
      return apiV2MiniAppToolPagePost({
        page: pageOffset,
        size: pageSize,
        cardSupport: CardSupportState.SUPPORT,
        miniAppId: listId,
        ...searchParams,
      });
    },
    {
      onSuccess: res => {
        setTotalCount(res.total ?? 0);
        // 为已经添加过的卡片添加cardSize属性
        const tempList = res?.list?.map(item => {
          const cardSize = currentHomePageInfo?.find(info => info.originalId === item.id)?.cardSize;
          return {
            ...item,
            cardSize,
          };
        });
        setList(tempList);
      },
      refreshDeps: [pageOffset, pageSize, searchParams, setPageOffset],
    }
  );

  const columns = [
    ...otherColumns,
    {
      title: '选择卡片尺寸',
      ellipsis: { showTitle: true },
      render: (_: List, record: List, rowIndex: number) => {
        return (
          <>
            <Space>
              <SizeTag
                size={CardSize.LARGE}
                active={record.cardSize === CardSize.LARGE}
                onClick={handleSizeClick(CardSize.LARGE, rowIndex, setList)}
                disabled={record.isUse}
              />
              <SizeTag
                size={CardSize.MIDDLE}
                active={record.cardSize === CardSize.MIDDLE}
                onClick={handleSizeClick(CardSize.MIDDLE, rowIndex, setList)}
                disabled={record.isUse}
              />
              <SizeTag
                size={CardSize.SMALL}
                active={record.cardSize === CardSize.SMALL}
                onClick={handleSizeClick(CardSize.SMALL, rowIndex, setList)}
                disabled={record.isUse}
              />
            </Space>
          </>
        );
      },
    },
  ];

  // const rowSelection = {
  //   selectedRowKeys: selectedRowKeys,
  //   onChange: (selectedRowKeys: React.Key[], selectedRows: List[]) => {
  //     setSelectedRowKeys(selectedRowKeys);
  //     setSelectedRows(selectedRows);
  //   },
  //   getCheckboxProps: (record: List) => ({
  //     disabled: currentHomePageInfo?.some(item => item.originalId === record.id),
  //   }),
  // };

  return (
    <>
      <Form
        form={form}
        onFinish={val => {
          setSearchParams(val);
          setPageOffset(1);
        }}
      >
        <Row gutter={[20, 10]}>
          <Col span={10}>
            <Item name="categoryId" label="类型">
              <Select
                placeholder="请选择"
                options={categoryList?.map(item => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </Item>
          </Col>

          <Col span={10}>
            <Item name="toolName" label="工具">
              <Input />
            </Item>
          </Col>

          <Col span={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="primary" htmlType="submit">
              查询
            </Button>
          </Col>
        </Row>
      </Form>

      <Table
        rowKey="id"
        loading={loading}
        columns={columns}
        dataSource={list}
        // rowSelection={rowSelection}
        pagination={false}
      />
      <Paging pagingInfo={pagingInfo} />
    </>
  );
};

export default AddCardModal;
