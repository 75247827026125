import { Wrapper } from '@maxtropy/components';
import { useRequest } from 'ahooks';
import { Spin, Steps } from 'antd';
import { ComponentType, createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiRulesSetPost, ApiRulesSetPostRequest } from '../../ytt/types/api/rulesSet';
import { apiRulesSetIdGet, RulesSetIdGetResponse } from '../../ytt/types/rulesSet/id';
import { numberToString } from '../TemplateManage/utils';
import ChangeParams from './components/ChangeParams';
import PublishFormItems from './components/PublishFormItems';
import SceneFormItems from './components/SceneFormItems';
import TemplateSelect from './components/TemplateSelect';
import styles from './index.module.scss';

const baseRoutes = [{ name: '数据运营工具' }, { name: '数据模拟' }, { name: '创建规则集' }];
const rulesStepList = ['确定场景', '选择模板', '调整参数', '发布'];
const formStep: ComponentType<any>[] = [SceneFormItems, TemplateSelect, ChangeParams, PublishFormItems];
export const RulesGroupContext = createContext<{
  data?: RulesSetIdGetResponse;
  nextStep?: () => void;
  prevStep?: () => void;
  saveLoading?: boolean;
  saveForm?: (values: ApiRulesSetPostRequest) => Promise<number>;
}>({});
const RulesGroup = (props: { isEdit?: boolean }) => {
  const routes = useMemo(() => {
    return [...baseRoutes, { name: `${props.isEdit ? '编辑' : '创建'}规则集` }];
  }, [props.isEdit]);
  const localRulesId = sessionStorage.getItem('rulesId');
  const stepTileList = useMemo(() => {
    return rulesStepList.map((item, index) => ({ key: index + 1, title: item }));
  }, []);
  const { id } = useParams<{ id?: string }>();
  // 当前步骤
  const [current, setCurrent] = useState(1);
  const hasStepRef = useRef<number>();
  // 当前步骤要显示的表单
  const CurrentFormItem = useMemo(() => {
    return formStep[current - 1];
  }, [current]);
  // 当前规则集id
  const [currentRulesId, setCurrentRulesId] = useState<number>();
  const [saveLoading, setSaveLoading] = useState(false);
  // 当前规则集详情
  const { data: detail, loading } = useRequest(() => apiRulesSetIdGet({ id: numberToString(currentRulesId)! }), {
    ready: !!currentRulesId,
    refreshDeps: [current, currentRulesId],
  });
  useEffect(() => {
    if (id) {
      setCurrentRulesId(+id);
    }
  }, [id]);
  useEffect(() => {
    if (!props.isEdit && localRulesId) {
      setCurrentRulesId(+localRulesId);
    }
  }, [props.isEdit]);
  useEffect(() => {
    if (detail?.step) {
      // 只赋值一次
      if (hasStepRef.current) return;
      setCurrent(detail.step);
      hasStepRef.current = detail.step;
    }
  }, [detail]);
  const nextBtn = useCallback(() => {
    setCurrent(current => current + 1);
  }, []);
  const prevBtn = useCallback(() => {
    setCurrent(current => current - 1);
  }, []);
  // 保存表单api
  const saveFormApi = useCallback(
    (values: ApiRulesSetPostRequest) => {
      setSaveLoading(true);
      return apiRulesSetPost({
        id: currentRulesId,
        step: current,
        ...values,
      })
        .then(id => {
          setCurrentRulesId(id);
          // 新建时本地保存id, 防止新建页面刷新时数据丢失
          if (!props.isEdit) {
            sessionStorage.setItem('rulesId', `${id}`);
          }
          return id;
        })
        .finally(() => {
          setSaveLoading(false);
          return false;
        });
    },
    [currentRulesId, current]
  );
  useEffect(() => {
    return () => {
      sessionStorage.removeItem('rulesId');
    };
  }, []);
  const contextValue = useMemo(() => {
    return {
      data: detail,
      nextStep: nextBtn,
      prevStep: prevBtn,
      saveLoading: saveLoading,
      saveForm: saveFormApi,
    };
  }, [detail, nextBtn, prevBtn, saveFormApi, saveLoading]);
  return (
    <>
      <Wrapper routes={routes} className={styles.outer}>
        <div className={styles.page_box}>
          <div className={styles.steps_box}>
            <Steps current={current - 1} items={stepTileList} />
          </div>
          <div className={styles.content_box}>
            <Spin spinning={loading}>
              <RulesGroupContext.Provider value={contextValue}>
                <CurrentFormItem />
              </RulesGroupContext.Provider>
            </Spin>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default RulesGroup;
