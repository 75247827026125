import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Wrapper, FormContent, useAsync } from '@maxtropy/components';
import { Button, Col, Form, Modal, Row, Select, Space, Spin } from 'antd';
import DataAcquisitionForm, { DataAcquisitionFormRef } from '../../../components/DataAcquisitionForm';
import { useDaqFormState } from '../../../components/DataAcquisitionForm/common/useDaqFormState';
import { ActionType, IotProtocolType } from '@/shared/types';
import styles from './index.module.scss';

import { getDeviceTypeData, getPointsByTemplateVersionId } from '../../../api/template';
import { getProtocol } from '../../../api/protocol';
import EdgeDevicePointInfo, { EdgeDeviceTemplatePoint } from '@/shared/components/EdgeDevicePointInfo';
import { convertDataToEntities, DataNode, formatToDataNode, getDeviceType } from '@/shared/utils/cascade-util';

import {
  getBatchDeviceTemplateProperty,
  BatchDeviceTemplatePropertyResponse,
  getBatchDeviceTemplateEdgeInfo,
  updateBatchDeviceTemplateEdge,
} from '../../../api/batch';

import BaseInfo from '../components/BaseInfo';
import { omit, pick } from 'lodash-es';
import { MockingbirdFormValues } from '@/shared/components/DataAcquisitionForm/types';
import { halfFormItemLayout } from '@/shared/components/DataAcquisitionForm/common';
import useIsGateway from '@/shared/hooks/useIsGateway';

interface DataMiningProps {
  isEdit?: boolean;
}

const routes = [{ name: 'Iot配置' }, { name: '设备管理' }, { name: '批量导入设备' }, { name: '配置数采信息' }];

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

const EdgeTemplateDataMining: React.FC<DataMiningProps> = ({ isEdit = false }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const state = useDaqFormState();
  const {
    setIotProtocolType,
    setDeviceType,
    setTemplateVersionId,
    templateVersionId,
    iotProtocolType,
    setRootMcid,
    driveType,
    setDriveType,
    setObjectModalType,
  } = state;
  const daqFormRef: MutableRefObject<DataAcquisitionFormRef | null> = useRef(null);
  const queryParams = useQueryParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const tabs = urlSearchParams.get('tabs') || '1';

  const [batchDeviceTemplateProperty, setBatchDeviceTemplateProperty] = useState<BatchDeviceTemplatePropertyResponse>();
  const { isGateway } = useIsGateway(batchDeviceTemplateProperty?.typeId);

  const deviceTypes = useAsync(getDeviceTypeData);
  const iotProtocolData = useAsync(getProtocol);

  useEffect(() => {
    if (id) {
      getBatchDeviceTemplateProperty(id).then(res => {
        if (res) {
          setBatchDeviceTemplateProperty(res);
          setRootMcid(res.rootMcid);
          if (res.physicalModelId) {
            setObjectModalType(res.physicalModelId as number);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (batchDeviceTemplateProperty && deviceTypes) {
      const tree: DataNode[] = formatToDataNode(deviceTypes.tree as any);
      const entities = convertDataToEntities(tree);
      const deviceType = getDeviceType(entities, batchDeviceTemplateProperty.typeId!);
      setDeviceType(deviceType);
    }
  }, [batchDeviceTemplateProperty, deviceTypes, setDeviceType]);

  useEffect(() => {
    if (id) {
      getBatchDeviceTemplateEdgeInfo(id).then(data => {
        if (data) {
          const { templateVersionId, iotProtocol, driveType, parameters } = data;
          if (templateVersionId) {
            setTemplateVersionId(templateVersionId);
          }
          if (iotProtocol) {
            setIotProtocolType(iotProtocol);
            form.setFieldsValue({ iotProtocol });
          }
          if (iotProtocol === IotProtocolType.MOCKINGBIRD) {
            setDriveType(driveType);
            daqFormRef.current?.form?.setFieldsValue({
              driveType,
              ...pick(parameters, [
                'baudRate',
                'checkType',
                'dataBit',
                'stopBit',
                'samplingTimeout',
                'crcOrder',
                'addrOffset',
                'groupWords',
                'stationNum',
                'groupLength',
                'leadByte',
                'serialPort',
                'ip',
                'port',
                'samplingInterval',
                'samplingRetry',
                'address',
              ]),
            });
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [pointsData, setPointData] = useState<EdgeDeviceTemplatePoint[]>();

  useEffect(() => {
    if (templateVersionId) {
      getPointsByTemplateVersionId(templateVersionId).then(points => {
        setPointData(points.map(item => ({ ...item, actionType: ActionType.NONE })));
      });
    }
  }, [templateVersionId]);

  const [submitting, setSubmitting] = useState(false);
  const [modalApi, modalContextHolder] = Modal.useModal();

  const submit = () => {
    Promise.all([form.validateFields(), daqFormRef.current?.form?.validateFields()]).then(() => {
      setSubmitting(true);
      let parameters;
      if (iotProtocolType === IotProtocolType.MOCKINGBIRD) {
        const values = daqFormRef.current?.form?.getFieldsValue() as MockingbirdFormValues;
        parameters = omit(values, [
          'name',
          'version',
          'versionRemark',
          'deviceTypeName',
          'deviceModelName',
          'iotProtocol',
          'remark',
        ]);
      }
      if (batchDeviceTemplateProperty) {
        updateBatchDeviceTemplateEdge(
          {
            batchTemplateId: Number(id),
            iotProtocol: iotProtocolType!,
            templateVersionId: templateVersionId!,
            driveType: driveType,
            parameters: {
              ...parameters,
              typeId: batchDeviceTemplateProperty?.typeId!,
            },
          },
          batchDeviceTemplateProperty.rootMcid
        )
          .then(() => {
            navigate(`/device/manage/batch?tabs=${tabs}`);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    });
  };

  const cancel = () => {
    modalApi.confirm({
      title: null,
      icon: null,
      content: <div>是否放弃所有未保存信息并返回列表？</div>,
      onOk: () => {
        navigate(`/device/manage/batch?tabs=${tabs}`);
      },
    });
  };

  const handleIotProtocolTypeChange = (value: number) => {
    setIotProtocolType(value);
    setTemplateVersionId(undefined);
    daqFormRef.current?.form?.resetFields();
  };

  return (
    <Wrapper className={styles.wrapper} routes={routes}>
      <Spin spinning={false}>
        <BaseInfo data={batchDeviceTemplateProperty} />

        <FormContent title="数采信息">
          <Form form={form} labelAlign="left">
            <Row>
              <Col span={12}>
                <Form.Item
                  {...halfFormItemLayout}
                  name="iotProtocol"
                  rules={[{ required: true, message: '请选择协议选择' }]}
                  label="协议选择"
                >
                  <Select<IotProtocolType> onChange={handleIotProtocolTypeChange} placeholder="请选择">
                    {iotProtocolData?.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <>
              <DataAcquisitionForm state={state} ref={daqFormRef} isBatch />
              {iotProtocolType === IotProtocolType.ESTUN && templateVersionId && (
                <div className={styles.table}>
                  <EdgeDevicePointInfo isBatch dataSource={pointsData} type={iotProtocolType!} />
                </div>
              )}
              {iotProtocolType === IotProtocolType.MOCKINGBIRD &&
                templateVersionId &&
                (driveType !== undefined || isGateway) && (
                  <div className={styles.table}>
                    <EdgeDevicePointInfo
                      dataSource={pointsData}
                      type={iotProtocolType}
                      isBatch
                      info={{
                        iotProtocol: iotProtocolType,
                        deviceTypeId: batchDeviceTemplateProperty?.typeId,
                        driveType: driveType,
                      }}
                    />
                  </div>
                )}
            </>
            <Space className="sticky-footer">
              {!isEdit && !!queryParams.get('hasPrevious') && (
                <Button
                  type="primary"
                  onClick={() => {
                    navigate(`/device/manage/batch/${id}/attribute/create?tabs=${tabs}`);
                  }}
                >
                  上一步
                </Button>
              )}
              <Button htmlType="submit" type="primary" loading={submitting} onClick={submit}>
                完成
              </Button>
              <Button onClick={cancel}>取消</Button>
            </Space>
          </Form>
        </FormContent>
      </Spin>
      {modalContextHolder}
    </Wrapper>
  );
};

export default EdgeTemplateDataMining;
