import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, Form, Select, Space, Switch } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RulesGroupContext } from '..';
import { ApiRulesSetPostRequest } from '../../../ytt/types/api/rulesSet';
import styles from '../index.module.scss';
import { listDataTimeRules, listUploadTimeRules } from '@/api/mockRule';

const PublishFormItems = () => {
  const { prevStep, data: details, saveForm, saveLoading } = useContext(RulesGroupContext);
  const [form] = useForm<ApiRulesSetPostRequest>();
  const navigate = useNavigate();

  const { data: gapList } = useRequest(listUploadTimeRules);
  const { data: delayList } = useRequest(listDataTimeRules);

  const publishBtn = (save = true) => {
    form.validateFields().then(values => {
      saveForm?.({ ...values, save }).then(id => {
        if (id) {
          // 点击保存草稿后跳转到列表页
          if (!save) {
            navigate(-1);
            return;
          }
          // 跳转到规则集详情页
          navigate(`/data-tools/shaping/rulesmanage/detail/${id}`, { replace: true });
        }
      });
    });
  };
  useEffect(() => {
    if (details) {
      form.setFieldsValue({
        ...details,
        unifiedCompute: details.unifiedCompute ?? true,
      });
    }
  }, [details]);
  return (
    <div className={styles.form_content}>
      <Form layout="vertical" form={form} initialValues={{ unifiedCompute: true }}>
        <Form.Item label="两次上传间隔" name="uploadTimeRuleId" rules={[{ required: true }]}>
          <Select
            showSearch
            placeholder="请选择"
            optionFilterProp="label"
            options={(gapList ?? []).map(v => ({ label: v.name, value: v.id }))}
          />
        </Form.Item>

        <Form.Item label="数据生成后延时上传" name="dataTimeRuleId" rules={[{ required: true }]}>
          <Select
            showSearch
            placeholder="请选择"
            optionFilterProp="label"
            options={(delayList ?? []).map(v => ({ label: v.name, value: v.id }))}
          />
        </Form.Item>

        <Form.Item label="数据点统一计算" name="unifiedCompute" valuePropName="checked">
          <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
        </Form.Item>
        <div style={{ paddingTop: 10 }}>
          <Form.Item>
            <Space>
              <Button onClick={() => publishBtn(false)} loading={saveLoading}>
                保存草稿
              </Button>
              <Button onClick={() => prevStep?.()}>上一步</Button>
              <Button type="primary" onClick={() => publishBtn(true)} loading={saveLoading}>
                发布
              </Button>
            </Space>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
export default PublishFormItems;
