import React, { useEffect, useState } from 'react';
import { EllipsisSpan, Table, getRealUrl } from '@maxtropy/components';
import { Modal } from 'antd';
import { MiniAppLogDetailPostResponse, apiMiniAppLogDetailPost } from '@maxtropy/cc-mgmt-apis';
import { currentVo, PreVo, toolItem } from './api';
import { flatten, isNil } from 'lodash-es';

interface Props {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  logDetailId: string;
}

const columns = [
  {
    title: '字段',
    dataIndex: 'field',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '操作前',
    dataIndex: 'preVo',
    render: (v: string) => {
      if (isNil(v)) return <EllipsisSpan value={v} />;
      if (typeof v === 'string' && v.includes('icon-true-')) {
        const icon = v.slice(10);
        return <img src={getRealUrl(icon)} alt="" width="50" height="50" />;
      }
      if (typeof v === 'string' && v.includes('bg-true-')) {
        const bg = v.slice(8);
        return <img src={getRealUrl(bg)} alt="" width="50" height="50" />;
      }
      return <EllipsisSpan value={v} />;
    },
  },
  {
    title: '操作后',
    dataIndex: 'currentVo',
    render: (v: string) => {
      if (isNil(v)) return <EllipsisSpan value={v} />;
      if (typeof v === 'string' && v.includes('icon-true-')) {
        const icon = v.slice(10);
        return <img src={getRealUrl(icon)} alt="" width="50" height="50" />;
      }
      if (typeof v === 'string' && v.includes('bg-true-')) {
        const bg = v.slice(8);
        return <img src={getRealUrl(bg)} alt="" width="50" height="50" />;
      }
      return <EllipsisSpan value={v} />;
    },
  },
];

const LogDetailModal: React.FC<Props> = ({ modalOpen, setModalOpen, logDetailId }) => {
  const [detail, setDetail] = useState<MiniAppLogDetailPostResponse>({});
  const [toolArr, setToolArr] = useState<toolItem[]>([]);

  // 处理行数据
  const getColumnData = (key: string, field: string) => ({
    key,
    field,
    preVo: (detail?.preVo as PreVo)?.[key]
      ? key === 'icon' || key === 'bg'
        ? `${key}-true-${(detail?.preVo as PreVo)?.[key]}`
        : (detail?.preVo as PreVo)?.[key]
      : undefined,
    currentVo: (detail?.currentVo as currentVo)?.[key]
      ? key === 'icon' || key === 'bg'
        ? `${key}-true-${(detail?.currentVo as currentVo)?.[key]}`
        : (detail?.currentVo as currentVo)?.[key]
      : undefined,
  });

  //获取详情数据
  useEffect(() => {
    if (isNil(logDetailId)) return;
    apiMiniAppLogDetailPost({ id: logDetailId }).then(setDetail);
  }, [logDetailId]);

  // 处理详情 动态行数据
  useEffect(() => {
    const preVoCategoryList = detail?.preVo?.categoryList ?? [];
    const allName_pre = preVoCategoryList.map((item: any) => item.name);
    const currentVoCategoryList = detail?.currentVo?.categoryList ?? [];
    const allName_cur = currentVoCategoryList.map((item: any) => item.name);
    const allName = [...new Set([...allName_pre, ...allName_cur])];
    const preAndCur: any = [];

    allName.forEach(i => {
      const pre = preVoCategoryList.find((item: any) => item.name === i);
      const cur = currentVoCategoryList.find((item: any) => item.name === i);
      preAndCur.push({
        name: i,
        pre,
        cur,
      });
    });

    const res = preAndCur.map((item: any, index: number) => {
      return [
        {
          key: `${index}-index`,
          field: item.name,
          preVo: item?.pre?.visibility,
          currentVo: item?.cur?.visibility,
        },
        {
          key: `${index}-index555`,
          field: item.name + '工具',
          preVo: item?.pre?.toolName,
          currentVo: item?.cur?.toolName,
        },
      ];
    });

    setToolArr(flatten(res));
  }, [detail]);

  const detailData = [
    getColumnData('name', '名称'),
    getColumnData('appId', '小程序Appid'),
    getColumnData('version', '小程序版本'),
    getColumnData('body', '小程序主体'),
    getColumnData('description', '说明'),
    getColumnData('icon', 'icon'),
    getColumnData('welcomeText', '欢迎词'),
    getColumnData('bg', '背景'),
    ...toolArr,
  ];

  return (
    <Modal title="日志详情" open={modalOpen} width={1024} onCancel={() => setModalOpen(false)} footer={null}>
      <Table rowKey="key" columns={columns} dataSource={detailData} />
    </Modal>
  );
};

export default LogDetailModal;
