import { Checkbox, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormItemProps } from 'antd/es/form/FormItem';

export interface CustomFormItemProps extends Partial<FormItemProps> {
  name: string;
  placeholder?: string;
  disabled: boolean;
  customProps?: { [key: string]: unknown };
  render: (props: any) => any;
  onCheck?: (checked: boolean, name: string) => void;
  clear?: boolean;
  setClear?: () => void;
}

const AntFormItem = Form.Item;

const CustomFormItem: React.FC<CustomFormItemProps> = props => {
  const {
    rules: propRules,
    placeholder: propPlaceholder,
    disabled: propDisabled,
    name,
    render,
    onCheck,
    clear,
    setClear,
    ...restProps
  } = props;

  const [disabled, setDisabled] = useState<boolean>(true);
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    if (clear) {
      setChecked(false);
      setDisabled(true);
    }
  }, [clear]);

  const renderProps = {
    allowClear: true,
    placeholder: checked ? propPlaceholder : '若需要修改请勾选',
    disabled: disabled || propDisabled,
    style: {
      width: '80%',
    },
  };

  return (
    <>
      <AntFormItem name={name} rules={checked ? propRules : []} {...restProps} noStyle>
        {render?.(renderProps)}
      </AntFormItem>
      <Checkbox
        style={{ marginLeft: 20 }}
        disabled={propDisabled}
        checked={checked}
        onChange={e => {
          const checked = e.target.checked;
          setChecked(checked);
          setDisabled(!checked);
          onCheck?.(checked, name);
          setClear?.();
        }}
      />
    </>
  );
};

export default CustomFormItem;
