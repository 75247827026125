import { Wrapper, useAsync } from '@maxtropy/components';
import { Button, Col, Form, Input, Row, Select, Space, TreeSelect } from 'antd';
import { StoreValue } from 'antd/es/form/interface';
import { RuleObject } from 'antd/es/form';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import MemberSelect from '@/shared/components/MemberSelect';
import { CommonUserInfo } from '@/shared/components/MemberSelect/interface';
import { OuStaffType } from '@/shared/const';
import { getOrganization, getRoot, OrganizationResponse } from '../../../api/customer';
import {
  addOu,
  getOuInfo,
  OuInfoProps,
  queryOuStaffList,
  queryOuStaffPage,
  queryOuTypeList,
  queryUserList,
  updateOu,
} from '../../../api/ou';
import TextOrSelect from '../components/TextOrSelect';
import usePageStatus, { PageStatus, PageStatusDisplay } from '../hooks/usePageStatus';
import styles from './index.module.scss';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const formatTreeData = (data: OrganizationResponse[]) => {
  return data.map(item => {
    const node: DefaultOptionType = {
      key: item.data?.mcid ?? '',
      value: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children || []),
    };
    return node;
  });
};

const membersValidator = (_: RuleObject, value: StoreValue) => {
  const hasAdmin = (value ?? []).some((item: any) => item.staffType === OuStaffType.ADMINISTRATOR);
  if (!hasAdmin) {
    return Promise.reject(new Error('最少选择一个管理员'));
  } else {
    return Promise.resolve();
  }
};

interface EditProductionUnitProps {}

const EditProductionUnit: React.FC<EditProductionUnitProps> = props => {
  const [form] = Form.useForm();
  const [organization, setOrganization] = useState<DefaultOptionType[]>([]);
  const { id } = useParams<{ id: string }>();
  const pageStatus = usePageStatus();
  const [tenantId, setTenantId] = useState<string>();
  const [mcid, setMcid] = useState<string>();
  const [detail, setDetail] = useState<OuInfoProps>();
  const tempMembersIds = useRef<number[]>([]);
  const navigate = useNavigate();

  const routes = [
    { name: 'Iot配置' },
    { name: '生产运营单元' },
    { name: '单元管理' },
    { name: PageStatusDisplay[pageStatus] },
  ];

  const tenants = useAsync(
    useCallback(() => getRoot({ check: true }), []),
    []
  );
  const types = useAsync(queryOuTypeList, []);

  const isEdit = pageStatus === PageStatus.UPDATE;
  const isDetail = pageStatus === PageStatus.DETAIL;

  useEffect(() => {
    if (!id) return;
    if (isEdit) {
      Promise.all([getOuInfo(id), queryOuStaffList(id)])
        .then(([info, members]) => {
          setDetail(info);
          form.setFieldsValue({
            name: info.name,
            ouTypeId: info.ouTypeName,
            mcid: info.customerName,
            tenantId: info.tenantName,
            directorName: info.directorName,
            directorPhone: info.directorPhone,
            remark: info.remark,
            members,
          });
          tempMembersIds.current = (members ?? []).map(item => item.id);
          setTenantId(info.tenantMcid);
          setMcid(info.mcid);
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      getOuInfo(id)
        .then(info => {
          setDetail(info);
          form.setFieldsValue({
            name: info.name,
            ouTypeId: info.ouTypeName,
            mcid: info.customerName,
            tenantId: info.tenantName,
            directorName: info.directorName,
            directorPhone: info.directorPhone,
            remark: info.remark,
          });
          setTenantId(info.tenantId);
          setMcid(info.mcid);
        })
        .catch(error => {
          console.error(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isEdit]);

  useEffect(() => {
    if (!tenantId) {
      setOrganization([]);
      return;
    }
    getOrganization(tenantId).then(organization => {
      if (organization) {
        setOrganization(formatTreeData([organization]));
      } else {
        setOrganization([]);
      }
    });
  }, [tenantId]);

  const userDataSource = useMemo(() => {
    if (!tenantId) {
      return () => Promise.resolve({ list: [], page: 1, size: 10, total: 0 });
    } else {
      return (params: any) => queryUserList({ tenantMcid: tenantId, ...params });
    }
  }, [tenantId]);

  const memberDataSource = useMemo(() => {
    if (!id) {
      return () => Promise.resolve({ list: [], page: 1, size: 10, total: 0 });
    } else {
      return (params: any) => queryOuStaffPage(id, { ...params });
    }
  }, [id]);

  const handleTenantChange = (value: string) => {
    setTenantId(value);
    setMcid(undefined);
    form.setFieldsValue({ mcid: undefined, members: [] });
  };

  const handleMcidChange = (value: string) => {
    setMcid(value);
  };

  const onFinish = (value: any) => {
    let { members = [], ...rest } = value;
    const add = members as CommonUserInfo[];
    // const ids = (members as CommonUserInfo[]).filter(item => item.id).map(item => item.id);
    const adminIds = add.filter(item => item.staffType === OuStaffType.ADMINISTRATOR).map(item => item.id);
    const memberIds = add.filter(item => item.staffType === OuStaffType.MEMBER).map(item => item.id);
    // const cancelIds = tempMembersIds.current.filter(id => !ids.includes(id));

    if (isEdit) {
      if (id && tenantId) {
        updateOu(
          id,
          {
            id: id,
            name: rest.name,
            directorName: rest.directorName,
            directorPhone: rest.directorPhone,
            remark: rest.remark,
            memberIds,
            adminIds,
          },
          tenantId
        )
          .then(() => {
            navigate(`/device/ou/production-unit`);
          })
          .catch(error => {
            console.error('addOu', error);
          });
      }
    } else {
      if (types.length === 1) {
        rest = { ...rest, ouTypeId: types[0].id };
      }
      addOu({ ...rest, adminIds, memberIds })
        .then(() => {
          navigate(`/device/ou/production-unit`);
        })
        .catch(error => {
          console.error('addOu', error);
        });
    }
  };

  return (
    <Wrapper routes={routes} className={styles.wrapper}>
      <Form form={form} layout="vertical" {...formLayout} onFinish={onFinish}>
        <Row style={{ marginLeft: 28 }}>
          <Col span={8} className={styles.col}>
            <Form.Item
              name="name"
              label="名称"
              rules={[
                { required: true, message: '请输入名称' },
                { max: 30, message: '名称不超过30个字符' },
              ]}
            >
              {isDetail ? <ShowFormItem /> : <Input placeholder="请输入" />}
            </Form.Item>
          </Col>
          {types.length !== 1 && (
            <Col span={8} className={styles.col}>
              <Form.Item name="ouTypeId" label="类型" rules={[{ required: true }]}>
                {isEdit || isDetail ? (
                  <ShowFormItem />
                ) : (
                  <TextOrSelect
                    placeholder="请输入"
                    showSearch
                    optionFilterProp="label"
                    options={types.map(item => ({ label: item.name, value: item.id }))}
                  />
                )}
              </Form.Item>
            </Col>
          )}
          <Col span={8} className={styles.col}>
            <Form.Item name="tenantId" label="所属租户" rules={[{ required: true, message: '请选择所属租户' }]}>
              {isEdit || isDetail ? (
                <ShowFormItem />
              ) : (
                <Select
                  placeholder="请选择"
                  onChange={handleTenantChange}
                  options={tenants.map(item => ({ label: item.name, value: item.mcid }))}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={8} className={styles.col}>
            <Form.Item name="mcid" label="所属组织" rules={[{ required: true, message: '请选择所属组织' }]}>
              {isEdit || isDetail ? (
                <ShowFormItem />
              ) : (
                <TreeSelect treeData={organization} placeholder="请选择" onChange={handleMcidChange} />
              )}
            </Form.Item>
          </Col>
          <Col span={8} className={styles.col}>
            <Form.Item name="directorName" label="负责人" rules={[{ max: 6, message: '负责人不超过6个字符' }]}>
              {isDetail ? <ShowFormItem /> : <Input placeholder="请输入" />}
            </Form.Item>
          </Col>
          <Col span={8} className={styles.col}>
            <Form.Item
              name="directorPhone"
              label="负责人联系方式"
              rules={[{ max: 15, message: '负责人联系方式不超过15个字符' }]}
            >
              {isDetail ? <ShowFormItem /> : <Input placeholder="请输入" />}
            </Form.Item>
          </Col>
          <Col span={8} className={styles.col}>
            <Form.Item name="remark" label="备注" rules={[{ max: 50, message: '备注不超过50个字符' }]}>
              {isDetail ? <ShowFormItem /> : <Input placeholder="请输入" />}
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ marginLeft: 28 }}>
          <Col span={24} className={styles.col}>
            <Form.Item
              validateFirst
              name="members"
              label="管理员/成员"
              rules={
                isEdit
                  ? []
                  : [{ required: true, type: 'array', message: '请选择管理员/成员' }, { validator: membersValidator }]
              }
              wrapperCol={{ span: 24 }}
            >
              <MemberSelect
                userDataSource={userDataSource}
                memberDataSource={memberDataSource}
                disabled={isDetail}
                tenantId={tenantId}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="sticky-footer">
          <Space>
            {pageStatus !== PageStatus.DETAIL && (
              <Button type="primary" htmlType="submit">
                保存
              </Button>
            )}
            <Button onClick={() => navigate(`/device/ou/production-unit`)}>{isDetail ? '返回' : '取消'}</Button>
          </Space>
        </Row>
      </Form>
    </Wrapper>
  );
};

const ShowFormItem: React.FC<{ value?: string }> = ({ value }) => <div>{value ? value : '--'}</div>;

export default EditProductionUnit;
