import { Key, fetch } from '@maxtropy/components';

export enum PermissionType {
  Platform = 1,
  ThirdParty = 2,
  None = 3,
}
export const PermissionTypeDisplay = {
  [PermissionType.Platform]: '系统内权限',
  [PermissionType.ThirdParty]: '第三方权限',
  [PermissionType.None]: '无权限',
};

export enum ApplicationServiceType {
  PC = 1,
  MiniProgram = 2,
}

export const ApplicationServiceTypeDisplay = {
  [ApplicationServiceType.PC]: 'PC端',
  [ApplicationServiceType.MiniProgram]: '小程序',
};

// 获取导航列表
export interface MaxAbilityListRes {
  key: Key; // 唯一值
  permissionId: Key; // 所属平台权限id
  functionMenuName: string; //  功能名称
  tenantNavigationName: string[]; // 租户导航名称
  preSalesBundleLabels: { id: Key; name: string }[];
  applicationServiceType: ApplicationServiceType; // 应用服务类型	枚举备注: 1 :PC 2 :小程序
  permissionType: PermissionType; //权限类型	枚举备注: 1 :平台权限 2 :第三方应用权限 3 :无权限
  integratedApplicationId: Key; // 集成应用id
  integratedApplicationName: string; // 集成应用名称
  routeLink: string[]; // URL
  keyFeatures: string; // 功能亮点
  featureOverview: string; // 功能简述
  permissionCode: string; // 权限code
}

export const getAbilityList = (preSalesBundleLabelIds: Key[]) =>
  fetch<MaxAbilityListRes[]>('/api/internal/tool/menu/list', {
    method: 'POST',
    body: JSON.stringify({ preSalesBundleLabelIds }),
  });

export enum ColorType {
  '#91CAFF',
  '#D3F261',
  '#EFDBFF',
  '#FFC53D',
  '#FFADD2',
  '#ADC6FF',
  '#B5F5EC',
  '#FFCCC7',
  '#95DE64',
  '#FFF566',
  '#FFBB96',
  '#BAE0FF',
  '#FFE58F',
  '#B7EB8F',
  '#FFC069',
  '#FF85C0',
  '#87E8DE',
  '#BAE637',
  '#69B1FF',
  '#FF7875',
}

export const ColorTypeDisplay = {
  [ColorType['#91CAFF']]: '#91CAFF',
  [ColorType['#D3F261']]: '#D3F261',
  [ColorType['#EFDBFF']]: '#EFDBFF',
  [ColorType['#FFC53D']]: '#FFC53D',
  [ColorType['#FFADD2']]: '#FFADD2',
  [ColorType['#ADC6FF']]: '#ADC6FF',
  [ColorType['#B5F5EC']]: '#B5F5EC',
  [ColorType['#FFCCC7']]: '#FFCCC7',
  [ColorType['#95DE64']]: '#95DE64',
  [ColorType['#FFF566']]: '#FFF566',
  [ColorType['#FFBB96']]: '#FFBB96',
  [ColorType['#BAE0FF']]: '#BAE0FF',
  [ColorType['#FFE58F']]: '#FFE58F',
  [ColorType['#B7EB8F']]: '#B7EB8F',
  [ColorType['#FFC069']]: '#FFC069',
  [ColorType['#FF85C0']]: '#FF85C0',
  [ColorType['#87E8DE']]: '#87E8DE',
  [ColorType['#BAE637']]: '#BAE637',
  [ColorType['#69B1FF']]: '#69B1FF',
  [ColorType['#FF7875']]: '#FF7875',
};

// 获取导航标签列表
export interface MenuLabelListRes {
  id: Key; // 标签id
  name: string; // 标签名称
  colorType: ColorType;
}

export const getMenuLabelList = () =>
  fetch<MenuLabelListRes[]>('/api/internal/tool/menu/label/list', {
    method: 'POST',
  });

// 更改导航标签
export interface UpdateMenuLabelReq {
  permissionType: PermissionType;
  permissionId: Key; //权限id
  preSaleBundleLabelIds: Key[]; //关联标签ids
}

export const updateMenuLabel = (data: UpdateMenuLabelReq) =>
  fetch('/api/internal/tool/menu/label/config', {
    method: 'POST',
    body: JSON.stringify(data),
  });

// 创建导航标签
export interface CreateMenuLabelReq {
  name: string; // 标签名称
  colorType: ColorType;
}

export const createMenuLabel = (data: CreateMenuLabelReq) =>
  fetch('/api/internal/tool/menu/label/create', {
    method: 'POST',
    body: JSON.stringify(data),
  });
