import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { EllipsisSpan, Paging, Table, usePaging, useUpdate, Wrapper } from '@maxtropy/components';
import { Button, Col, Divider, Form, Input, Modal, Row, Space, Tag } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import {
  apiMiniAppCopyPost,
  apiMiniAppPagePost,
  MiniAppPagePostRequest,
  MiniAppPagePostResponse,
} from '@maxtropy/cc-mgmt-apis';
import { ListDataType, StatusEnum, UpgradeStatusEnum } from './types';
import styles from './index.module.scss';

const routes = [{ name: '系统设置' }, { name: '小程序' }, { name: '小程序管理' }];

const TIME = 'YYYY-MM-DD HH:mm';

const otherColumns = [
  {
    title: 'ID',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '小程序',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string, record: ListDataType) => {
      return (
        <>
          <EllipsisSpan value={v} />
          {record.status === StatusEnum.DRAFT && <Tag style={{ marginLeft: '8px' }}>草稿</Tag>}
        </>
      );
    },
  },
  {
    title: '主体',
    dataIndex: 'body',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '版本',
    dataIndex: 'version',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '更新时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format(TIME)} />,
  },
];

interface Props {}
const MiniProgram: React.FC<Props> = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<MiniAppPagePostRequest>();
  const [updateState, update] = useUpdate();
  const [modal, contextHolder] = Modal.useModal();
  const [list, setList] = useState<MiniAppPagePostResponse['list']>();
  const [loading, setLoading] = useState<boolean>(false);
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  // 搜索
  const onFinish = () => {
    const params = form.getFieldsValue();
    setSearchParams({ ...params });
    setPageOffset(1);
  };

  const getList = () => {
    setLoading(true);
    apiMiniAppPagePost({
      page: String(pageOffset),
      size: String(pageSize),
      ...searchParams,
    })
      .then(res => {
        if (res) {
          setList(res.list);
          setTotalCount(res.total as number);
          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (updateState) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState, searchParams]);

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, pageSize, setTotalCount]);

  const columns = [
    ...otherColumns,
    {
      title: '操作',
      fixed: 'right' as const,
      width: 200,
      render: (record: ListDataType) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => {
                navigate(`/platform/miniProgram/manage/pack/update/${record.id}?status=${record.upgradeStatus}`);
              }}
            >
              {record.upgradeStatus === UpgradeStatusEnum.EDIT ? '编辑' : '升级'}
            </Button>
            <Button
              type="link"
              onClick={() => {
                modal.confirm({
                  title: '您是否复制此小程序',
                  content: '确定要复制吗？',
                  onOk() {
                    apiMiniAppCopyPost({ id: String(record.id) }).then(_res => {
                      update();
                    });
                  },
                });
              }}
            >
              复制
            </Button>
            <Button
              type="link"
              onClick={() => {
                navigate(`/platform/miniProgram/manage/LogPage/${record.id}`);
              }}
            >
              操作日志
            </Button>
          </Space>
        );
      },
    },
  ];

  const filters = (
    <Form form={form} className={styles.form}>
      <Row>
        <Col>
          <Form.Item name="name">
            <Input
              placeholder="请输入小程序名称搜索"
              className={styles.input}
              suffix={<SearchOutlined className={styles.plusIcon} onClick={onFinish} />}
              allowClear
            />
          </Form.Item>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={() => {
              navigate('/platform/miniProgram/manage/pack');
            }}
          >
            <PlusOutlined />
            新建小程序
          </Button>
        </Col>
      </Row>
    </Form>
  );
  return (
    <>
      <Wrapper routes={routes} filters={filters}>
        <Table columns={columns} rowKey="id" loading={loading} pagination={false} dataSource={list} />
        <Paging pagingInfo={pagingInfo} />
      </Wrapper>
      {contextHolder}
    </>
  );
};

export default MiniProgram;
