import { fetch } from '@maxtropy/components';
import { RuleType } from './constants';
import { PreviewRuleContent, PreviewRuleRequest, Rule, RuleContent } from './entities';

export function listRules(type: RuleType): Promise<Rule[]> {
  return fetch(`/api/rule/type/${type}`);
}

export const listUploadTimeRules = () => listRules(RuleType.UPLOAD_TIME);
export const listDataTimeRules = () => listRules(RuleType.DATA_TIME);
export const listDataValueRules = () => listRules(RuleType.DATA_VALUE);

export function getRule(type: RuleType, id: number): Promise<Rule> {
  return fetch(`/api/rule/type/${type}/${id}`);
}

export function createRule(type: RuleType, rule: RuleContent): Promise<Rule> {
  return fetch(`/api/rule/type/${type}`, {
    method: 'POST',
    body: JSON.stringify(rule),
  });
}

export function updateRule(type: RuleType, id: number, rule: RuleContent): Promise<Rule> {
  return fetch(`/api/rule/type/${type}/${id}`, {
    method: 'PUT',
    body: JSON.stringify(rule),
  });
}

export function deleteRule(type: RuleType, id: number): Promise<void> {
  return fetch(`/api/rule/type/${type}/${id}`, {
    method: 'DELETE',
  });
}

export function previewRule(request: RuleContent & PreviewRuleRequest) {
  return fetch<Array<PreviewRuleContent>>(`/api/rule/type/preview`, {
    method: 'POST',
    body: JSON.stringify(request),
  });
}
