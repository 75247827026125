import { ExclamationCircleOutlined } from '@ant-design/icons';
import { EllipsisSpan, Wrapper } from '@maxtropy/components';
import { Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Select, Space, Table } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getRoot } from '../../../api/mockCustomer';
import { Tenant } from '../../../api/entities';
import dayjs, { Dayjs } from 'dayjs';
import {
  createDataGenertor,
  DataGeneratorNode,
  FormListRes,
  getDataGenertorInfo,
  getFormList,
  getStaffListByMcid,
  updateDataGenertor,
} from '../../../api/dataGenerator';
import { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';

export interface IDataGeneratorCreate {
  isEdit?: boolean;
}

const baseRoutes = [{ name: '数据运营工具' }, { name: '业务数据模拟' }, { name: '业务数据生成器' }];

const { RangePicker } = DatePicker;

export enum WorkCalendarEnum {
  ONLY_DAY = 1,
  ALL_DAY = 2,
}

export const WorkCalendarEnumLabels = {
  [WorkCalendarEnum.ONLY_DAY]: '长白班',
  [WorkCalendarEnum.ALL_DAY]: '三班倒',
};

// const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const DataGeneratorCreate: FC<IDataGeneratorCreate> = props => {
  const { isEdit } = props;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [selectTenant, setSelectTenant] = useState<any>();
  const [staffList, setStaffList] = useState<any[]>();
  const [formList, setFormList] = useState<FormListRes[]>();
  const [modalApi, modalContextHolder] = Modal.useModal();

  const routes = useMemo(() => {
    return [...baseRoutes, { name: `${isEdit ? '编辑' : '新建'}数据生成器` }];
  }, [isEdit]);

  useEffect(() => {
    if (isEdit && id) {
      getDataGenertorInfo(id).then(res => {
        setSelectTenant(res.tenantMcid);
        form.setFieldsValue({
          name: res.name,
          tenantMcid: res.tenantMcid,
          userStaffId: res.staffId,
          workCalendar: res.workCalendar,
          rangeTime: [dayjs(res.beginTime), dayjs(res.endTime)],
          period: res.period,
          nodes: res.nodes.map((item, index) => {
            return {
              sequence: index + 1,
              id: item.id,
              name: item.name,
              intervalTime: item.intervalTime,
              intervalVolatility: item.intervalVolatility,
              formId: item.formId,
              formName: item.formName,
            };
          }),
        });
      });
    }
  }, [id]);

  // 根据租户信息获取staff列表
  useEffect(() => {
    if (selectTenant) {
      getStaffListByMcid({ mcid: selectTenant }).then(res => {
        setStaffList(res);
      });
    }
  }, [selectTenant]);

  useEffect(() => {
    // 获取租户信息
    getRoot({}).then(res => {
      setTenants(res);
    });
    // 获取表单list
    getFormList().then(res => {
      setFormList(res);
    });
  }, []);

  const columns = [
    {
      title: '序号',
      dataIndex: 'sequence',
      width: 100,
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '节点',
      dataIndex: 'name',
      width: 220,
      ellipsis: { showTitle: true },
      render: (text: any, record: any, index: number) => {
        return (
          <Form.Item
            style={{ margin: 0 }}
            name={['nodes', index, 'name']}
            rules={[
              { required: true, message: '请输入节点名称' },
              { min: 1, message: '最少输入1个字符' },
              { max: 10, message: '最多输入10个字符' },
            ]}
          >
            <Input placeholder="请输入节点名称" />
          </Form.Item>
        );
      },
    },
    {
      title: '表单',
      dataIndex: 'formId',
      width: 220,
      render: (text: any, record: any, index: number) => {
        return (
          <Form.Item
            style={{ margin: 0 }}
            name={['nodes', index, 'formId']}
            rules={[{ required: true, message: '请选择表单' }]}
          >
            <Select
              placeholder="请选择表单"
              options={formList?.map(item => ({
                label: item.name,
                value: item.id,
              }))}
            />
          </Form.Item>
        );
      },
    },
    {
      title: '间隔时间',
      dataIndex: 'intervalTime',
      width: 180,
      render: (text: any, record: any, index: number) => {
        return (
          <Form.Item
            style={{ margin: 0 }}
            initialValue={0}
            name={['nodes', index, 'intervalTime']}
            rules={[{ required: true, message: '请输入间隔时间' }]}
          >
            <InputNumber min={0} precision={0} placeholder="请输入间隔时间" addonAfter="秒" />
          </Form.Item>
        );
      },
    },
    {
      title: '间隔波动率',
      dataIndex: 'intervalVolatility',
      width: 180,
      render: (text: any, record: any, index: number) => {
        return (
          <Form.Item
            style={{ margin: 0 }}
            initialValue={0}
            name={['nodes', index, 'intervalVolatility']}
            rules={[{ required: true, message: '请输入间隔波动率' }]}
          >
            <InputNumber min={0} max={100} placeholder="请输入间隔波动率" addonAfter="%" />
          </Form.Item>
        );
      },
    },
    {
      title: '操作',
      fixed: 'right' as const,
      width: 140,
      render: (_: undefined, record: DataGeneratorNode) => {
        return (
          <Space size={16}>
            <Button style={{ padding: 0 }} type="link" onClick={() => deleteNode(record.sequence)}>
              删除
            </Button>
          </Space>
        );
      },
    },
  ];
  const deleteNode = (sequence?: number) => {
    modalApi.confirm({
      title: '您是否要删除此节点?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        if (sequence) {
          const newData = form.getFieldValue('nodes').filter((item: DataGeneratorNode) => item.sequence !== sequence);
          const reSortTable = newData.map((item: DataGeneratorNode, index: number) => {
            return {
              ...item,
              sequence: index + 1,
            };
          });
          form.setFieldValue('nodes', [...reSortTable]);
        }
      },
    });
  };

  const onSelectTenant = (val: string) => {
    setSelectTenant(val);
  };

  const handleAdd = () => {
    const nodesData = form.getFieldValue('nodes');
    const newData = {
      sequence: (nodesData ?? []).length + 1,
      name: `节点${(nodesData ?? []).length + 1}`,
    };
    form.setFieldValue('nodes', [...(nodesData ?? []), newData]);
  };

  const onSaveAndEnable = async (isEnabled: boolean) => {
    const values = await form.validateFields();
    let params = {
      ...values,
      saveRun: isEnabled,
      beginTime:
        Object.prototype.toString.call(values.rangeTime) === '[object Array]' ? values.rangeTime![0] : undefined,
      endTime: Object.prototype.toString.call(values.rangeTime) === '[object Array]' ? values.rangeTime![1] : undefined,
    };
    if (isEdit && id) {
      updateDataGenertor(id, params).then(_ => {
        navigate(`/data-tools/business/dataGeneratorManagement`);
      });
    } else {
      createDataGenertor(params).then(_ => {
        navigate(`/data-tools/business/dataGeneratorManagement`);
      });
    }
  };

  const onCancel = () => {
    modalApi.confirm({
      title: '您是否确定不保存数据并返回列表界面?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        navigate(`/data-tools/business/dataGeneratorManagement`);
      },
    });
  };

  return (
    <Wrapper routes={routes}>
      <Form form={form} layout="vertical">
        <Row gutter={10}>
          <Col span={6}>
            <Form.Item
              label="业务名称"
              name="name"
              rules={[
                { required: true, message: '请输入业务名称' },
                { min: 2, max: 50 },
              ]}
            >
              <Input placeholder="请输入业务名称" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={6}>
            <Form.Item name="tenantMcid" label="所属租户" rules={[{ required: true, message: '请选择所属租户' }]}>
              <Select
                showSearch
                placeholder="请选择租户"
                optionFilterProp="label"
                options={tenants.map(v => ({ label: v.name, value: v.mcid }))}
                onChange={val => onSelectTenant(val)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={6}>
            <Form.Item name="userStaffId" label="选择用户" rules={[{ required: true, message: '请选择用户' }]}>
              <Select
                showSearch
                placeholder="请选择用户"
                optionFilterProp="label"
                disabled={!selectTenant}
                options={staffList?.map(v => ({ label: v.staffName + '（' + v.staffCode + '）', value: v.id }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={6}>
            <Form.Item name="workCalendar" label="工作日历" rules={[{ required: true, message: '请选择工作日历' }]}>
              <Select placeholder="请选择工作日历">
                {Object.entries(WorkCalendarEnumLabels).map(([key, value]) => (
                  <Select.Option key={key} value={Number(key)}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={6}>
            <Form.Item
              name="rangeTime"
              label="时间范围"
              rules={[
                { required: true, message: '请选择时间范围' },
                {
                  validator: async (_, value: [DatePickerProps['value'], DatePickerProps['value']]) => {
                    if (value) {
                      if (value[0]?.format('YYYY-MM-DD HH:mm:ss') === value[1]?.format('YYYY-MM-DD HH:mm:ss'))
                        return Promise.reject('结束时间必须要迟于开始时间！');
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <RangePicker
                style={{ width: '100%' }}
                format="YYYY-MM-DD HH:mm:ss"
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('11:59:59', 'HH:mm:ss')],
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col span={6}>
            <Form.Item name="period" label="执行周期" rules={[{ required: true, message: '请输入执行周期' }]}>
              <InputNumber precision={0} placeholder="请输入执行周期" addonAfter="秒" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col span={16}>
            <Form.Item
              name="nodes"
              label="业务节点"
              valuePropName="dataSource"
              rules={[{ required: true, message: '' }]}
            >
              <Table rowKey="sequence" bordered columns={columns} pagination={false} />
            </Form.Item>
            <Button onClick={handleAdd} type="dashed" style={{ marginBottom: 16, width: '100%' }}>
              +新增节点
            </Button>
          </Col>
        </Row>

        <Form.Item>
          <Space>
            <Button onClick={() => onSaveAndEnable(true)} type="primary">
              保存并启用
            </Button>
            <Button onClick={() => onSaveAndEnable(false)} type="primary">
              保存
            </Button>
            <Button onClick={() => onCancel()}>取消</Button>
          </Space>
        </Form.Item>
      </Form>
      {modalContextHolder}
    </Wrapper>
  );
};

export default DataGeneratorCreate;
