import { Avatar, Tooltip } from 'antd';
import React, { ReactNode } from 'react';

export interface EllipsisSpanProps {
  value?: string | ReactNode;
  className?: string;
  avatar?: string;
  defaultValue?: string | ReactNode;
}

export const EllipsisSpan: React.FC<EllipsisSpanProps> = props => {
  const { value, className = '', avatar, defaultValue } = props;
  return (
    <>
      {avatar && <Avatar size={29} src={avatar} />}
      {!(value === undefined || value === null) ? (
        <Tooltip placement={'topLeft'} title={value}>
          <span className={className}>{value}</span>
        </Tooltip>
      ) : (
        <span className={className}>{defaultValue ?? '--'}</span>
      )}
    </>
  );
};
