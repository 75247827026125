import React, { useEffect, useState, useMemo, useRef } from 'react';
import { EllipsisSpan, Paging, Table, usePaging, Wrapper, Filter } from '@maxtropy/components';
import { Button, Col, Divider, Form, Input, Modal, Popconfirm, Space, Select, Steps, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { MiniAppToolPagePostRequest, MiniAppToolTagPagePostResponse } from '@maxtropy/cc-mgmt-apis';
import { ISvListResponse, getIsvList } from '../../api/cc-miniApp';
import ToolForm, { ToolFormRef } from './components/ToolForm';
import { ToolListType } from './types';
import styles from './index.module.scss';
import PermissionManage from './components/PermissionManage';
import {
  apiV2MiniAppToolToolModifyPost,
  apiV2MiniAppToolToolAddPost,
  apiV2MiniAppToolPagePost,
  apiV2MiniAppToolToolDeletePost,
} from '@maxtropy/cc-mgmt-apis-v2';

const routes = [{ name: '系统设置' }, { name: '小程序' }, { name: '工具管理' }];

const steps = [
  {
    title: '基本信息',
  },
  {
    title: '权限录入',
  },
];

enum operationType {
  'create' = '新建',
  'edit' = '编辑',
}

type SearchParams = Omit<MiniAppToolPagePostRequest, 'page' | 'size'>;

const ToolManage: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [list, setList] = useState<MiniAppToolTagPagePostResponse['list']>();
  const [isvList, setIsvList] = useState<ISvListResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [operate, setOperate] = useState<'create' | 'edit'>('create');
  const [current, setCurrent] = useState<number>(0);
  const [miniAppToolId, setMiniAppToolId] = useState<number>(0);
  const [toolName, setToolName] = useState<string>();

  const toolFormRef = useRef<ToolFormRef>({});

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  useEffect(() => {
    getIsvList().then(res => {
      setIsvList(res || []);
    });
  }, []);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, pageOffset, pageSize]);

  const loadData = () => {
    setLoading(true);

    apiV2MiniAppToolPagePost({
      page: pageOffset,
      size: pageSize,
      ...searchParams,
    })
      .then(res => {
        if (res.list) {
          setList(res.list || []);
          setTotalCount(res.total as number);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const openToolModal = (operate: 'create' | 'edit') => {
    setOperate(operate);
    setOpen(true);
  };

  // 搜索
  const onFinish = (query: MiniAppToolPagePostRequest) => {
    setPageOffset(1);
    setSearchParams(query);
  };

  const onReset = () => {
    setSearchParams({});
    setPageOffset(1);
  };

  const delTool = (id: number) => {
    setLoading(true);
    apiV2MiniAppToolToolDeletePost({ id: id }).then(res => {
      setLoading(false);

      loadData();
    });
  };

  const modalFunc = {
    onNext: () => {
      toolFormRef.current?.form.validateFields().then(async () => {
        setFormLoading(true);
        const values = toolFormRef.current.form.getFieldsValue(true);
        const api = operate === 'create' ? apiV2MiniAppToolToolAddPost : apiV2MiniAppToolToolModifyPost;

        api(values)
          .then(res => {
            setMiniAppToolId(res.value as number);
            setToolName(values.name);
            setCurrent(1);
            setOperate('edit');
          })
          .finally(() => {
            setFormLoading(false);
          });
      });
    },
    onPrev: () => {
      setCurrent(0);
    },
    onCancel: () => {
      setCurrent(0);
      setOpen(false);
      toolFormRef.current?.form.resetFields();
    },
    onOk: () => {
      modalFunc.onCancel();
      setPageOffset(1);
      loadData();
    },
  };

  const columns = useMemo(
    () => [
      {
        title: '工具名称',
        dataIndex: 'name',
        ellipsis: { showTitle: true },
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '工具简介',
        dataIndex: 'description',
        ellipsis: { showTitle: true },
        render: (v: string) => <EllipsisSpan value={v || undefined} />,
      },
      {
        title: '工具分类',
        dataIndex: 'categoryName',
        ellipsis: { showTitle: true },
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '开发商',
        dataIndex: 'vendorId',
        ellipsis: { showTitle: true },
        render: (v: number) => {
          return <EllipsisSpan value={isvList.find(item => item.id === v)?.name} />;
        },
      },
      {
        title: '被使用数',
        dataIndex: 'refTime',
        ellipsis: { showTitle: true },
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '操作',
        width: 200,
        render: (record: ToolListType) => {
          return (
            <Space size={16}>
              <Button
                type="link"
                onClick={() => {
                  setMiniAppToolId(record.id);
                  openToolModal('edit');
                }}
              >
                编辑
              </Button>
              {record.refTime > 0 ? (
                <Button type="link" disabled={true}>
                  删除
                </Button>
              ) : (
                <Popconfirm title="您是否删除此应用" onConfirm={() => delTool(record.id)}>
                  <Button type="link">删除</Button>
                </Popconfirm>
              )}
              <Button
                type="link"
                onClick={() => {
                  navigate(`/platform/miniProgram/toolManage/operationLog/${record.id}`);
                }}
              >
                日志
              </Button>
            </Space>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isvList]
  );

  const filters = (
    <Filter form={form} onFinish={onFinish} onReset={onReset}>
      <Col span={6}>
        <Form.Item name="vendorId" label="开发商">
          <Select placeholder="请选择开发商">
            {isvList.map((item: any) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name="toolName" label="工具名称">
          <Input placeholder="请输入工具名称搜索" />
        </Form.Item>
      </Col>
      <Col span={6}></Col>
    </Filter>
  );

  const Footer = () => (
    <Space size={8} className={styles.footer}>
      {current === 0 && (
        <>
          <Button onClick={modalFunc.onCancel}>取消</Button>
          <Button type="primary" onClick={modalFunc.onNext}>
            下一步
          </Button>
        </>
      )}
      {current === 1 && (
        <>
          <Button onClick={modalFunc.onPrev}>上一步</Button>
          <Button type="primary" onClick={modalFunc.onOk}>
            确定
          </Button>
        </>
      )}
    </Space>
  );

  return (
    <>
      <Wrapper routes={routes} filters={filters}>
        <div className={styles.operationArea}>
          <Button
            type="primary"
            onClick={() => {
              openToolModal('create');
              setMiniAppToolId(0);
            }}
          >
            <PlusOutlined />
            新建工具
          </Button>
        </div>
        <Table columns={columns} rowKey="id" loading={loading} pagination={false} dataSource={list} />
        <Paging pagingInfo={pagingInfo} />
      </Wrapper>
      <Modal
        title={`${operationType[operate]}工具`}
        width={800}
        className={styles.toolModal}
        open={open}
        onCancel={modalFunc.onCancel}
        destroyOnClose={true}
        footer={<Footer />}
      >
        <Steps current={current} items={steps} className={styles.steps} />
        {current === 0 && (
          <Spin spinning={formLoading}>
            <ToolForm miniAppToolId={miniAppToolId} isvList={isvList} ref={toolFormRef} />
          </Spin>
        )}
        {current === 1 && <PermissionManage toolName={toolName} miniAppToolId={miniAppToolId} />}
      </Modal>
    </>
  );
};

export default ToolManage;
