import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Badge, Button, Cascader, Col, Form, Input, Modal, Select, Space } from 'antd';
import { Link } from 'react-router-dom';
import { EllipsisSpan, Paging, Table, usePaging, Wrapper, useAsync } from '@maxtropy/components';
import {
  changeTemplateStatus,
  getTemplateList,
  Status,
  TemplateListProps,
  TemplateListRequest,
} from '../../api/template';
import { useHasPermission, useQuery } from '../../utils/utils';
import dayjs from 'dayjs';
import Filter from '@/shared/components/Filter';
import { FilterParams } from '../Attribute';

import { getDeviceTypeData } from '../../api/attribute';
import { formatOptionData, getChildNodesByParentIds } from '@/shared/components/CascadingMultipleSelector/utils';
import { PermissionsType } from '../../common/permissionsConst';
import { getManufacturerList } from '../../api/edgeTemplate';
import { getPhysicalModelList } from '../../api/device';
import { DefaultOptionType } from 'antd/es/cascader';

const routes = [{ name: 'Iot配置' }, { name: '设备信息模板库' }, { name: '固定属性模板' }];

const columns = [
  {
    title: '模板编号',
    dataIndex: 'serialNumber',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '模板名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    width: 300,
    dataIndex: 'deviceTypeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '厂商',
    dataIndex: 'manufacturerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '物模型型号',
    dataIndex: 'modelNo',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最新版本号',
    dataIndex: 'lastVersion',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (v: Status) => (
      <Badge status={v === Status.DISABLE ? 'error' : 'success'} text={v === Status.DISABLE ? '禁用' : '启用'} />
    ),
  },
  {
    title: '使用数量',
    dataIndex: 'useCount',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={String(v)} />,
  },
  {
    title: '最后操作时间',
    width: 200,
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '最后操作人',
    dataIndex: 'lastUsername',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

type SearchParams = Omit<TemplateListRequest, 'page' | 'size'>;

const TemplateList: React.FC = () => {
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const hasPermission = useHasPermission(PermissionsType.BATTRIBUTETEMPLATEENABLEDISABLE);

  const localParamsStr = sessionStorage.getItem(window.location.pathname);
  const localParamsObj = localParamsStr ? (JSON.parse(localParamsStr) as SearchParams) : {};
  const [searchParams, setSearchParams] = useState<SearchParams>(localParamsObj);
  const [modalApi, modalContextHolder] = Modal.useModal();

  const [form] = Form.useForm();

  const deviceTypeData = useAsync(getDeviceTypeData);
  useEffect(() => {
    if (localParamsStr) {
      form.setFieldsValue({
        ...localParamsObj,
        deviceType: localParamsObj.originDeviceType,
      });
    }
  }, []);

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        getTemplateList({
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) {
            setTotalCount(res.total);
          }
          return res.list;
        }),
      [pageOffset, pageSize, searchParams, setTotalCount]
    )
  );

  const render = (record: TemplateListProps) => (
    <Space size={16} className="action-buttons">
      <Button type="link">
        <Link to={`/device/info/template/${record.id}${window.location.search}`}>查看</Link>
      </Button>
      <Button type="link">
        <Link to={`/device/info/template/${record.id}/copy${window.location.search}`}>复制</Link>
      </Button>
      <Button type="link">
        <Link to={`/device/info/template/${record.id}/edit${window.location.search}`}>编辑</Link>
      </Button>
      {hasPermission && (
        <>
          {record.status === Status.ENABLE && (
            <>
              <Button
                type="link"
                onClick={() => {
                  modalApi.confirm({
                    title: '是否禁用？',
                    okText: '继续',
                    onOk: () => {
                      changeStatus(record.id, record.status);
                    },
                  });
                }}
              >
                禁用
              </Button>
            </>
          )}
          {record.status === Status.DISABLE && (
            <Button type="link" onClick={() => changeStatus(record.id, record.status)}>
              启用
            </Button>
          )}
        </>
      )}
    </Space>
  );

  const changeStatus = (id: number, status: Status) => {
    changeTemplateStatus({
      id,
      state: status === Status.DISABLE ? Status.ENABLE : Status.DISABLE,
    }).then(data => {
      setSearchParams({ ...searchParams });
    });
  };

  const onFinish = (val: FilterParams) => {
    const deviceType = getChildNodesByParentIds(val.deviceType, deviceTypeData?.tree);
    const params = {
      ...val,
      deviceType,
    };
    sessionStorage.setItem(
      window.location.pathname,
      JSON.stringify({
        ...params,
        originDeviceType: val.deviceType,
      })
    );
    setSearchParams(params);
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    setPageOffset(1);
    sessionStorage.removeItem(window.location.pathname);
  };
  const manufacturerId = Form.useWatch('manufacturerId', form);
  const deviceType = Form.useWatch('deviceType', form);
  const manufacturerList = useAsync(getManufacturerList, []);
  const physicalModelList = useAsync(
    useCallback(() => {
      const deviceTypeIds = getChildNodesByParentIds(deviceType, deviceTypeData?.tree);
      const params = {
        deviceTypeIds,
        manufacturerId,
      };
      return getPhysicalModelList(params);
    }, [deviceType, manufacturerId, deviceTypeData])
  );

  const manufacturerOptions = useMemo(() => {
    if (manufacturerList && manufacturerList.length > 0) {
      return manufacturerList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [manufacturerList]);
  const objectModalTypeOptions = useMemo(() => {
    if (physicalModelList && physicalModelList.length > 0) {
      return physicalModelList.map(i => ({ label: i.modelNo, value: i.id }));
    }
  }, [physicalModelList]);
  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const filters = (
    <Filter form={form} onFinish={val => onFinish(val as FilterParams)} onReset={onReset}>
      <Col span={6}>
        <Form.Item name="name" label="编号/名称">
          <Input placeholder={'请输入编号或名称查询'} />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name="deviceType" label="所属类目">
          <Cascader
            options={formatOptionData(deviceTypeData)}
            allowClear={false}
            fieldNames={{ children: 'child' }}
            multiple
            maxTagCount="responsive"
            showSearch={{ filter }}
            placeholder={'请选择所属类目'}
            onChange={() => {
              form.setFieldsValue({
                physicalModelId: undefined,
              });
            }}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name="manufacturerId" label="厂商">
          <Select
            optionFilterProp="label"
            placeholder="请选择"
            showSearch
            options={manufacturerOptions}
            onChange={() => {
              form.setFieldsValue({
                physicalModelId: undefined,
              });
            }}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name="physicalModelId" label="物模型型号">
          <Select optionFilterProp="label" placeholder="请选择" showSearch options={objectModalTypeOptions} />
        </Form.Item>
      </Col>
    </Filter>
  );

  return (
    <Wrapper routes={routes} filters={filters}>
      <Link to={`/device/info/template/new`}>
        <Button type="primary" style={{ marginBottom: 15 }}>
          +新建模板
        </Button>
      </Link>
      <Table
        sticky
        rowKey="id"
        dataSource={data}
        loading={isLoading}
        scroll={{ x: 1500 }}
        columns={[...columns, { title: '操作', width: 220, fixed: 'right', render }]}
      />
      <Paging pagingInfo={pagingInfo} />
      {modalContextHolder}
    </Wrapper>
  );
};

export default TemplateList;
