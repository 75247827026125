import React from 'react';
import { MenuType } from './types';
import { AppstoreOutlined, HomeOutlined, SmallDashOutlined } from '@ant-design/icons';
import { miniAppIconMap } from './ToolsAndMenusModal/IconGroup';

interface Props {
  type?: MenuType;
  icon?: string;
}
const MenuIconRenderer: React.FC<Props> = ({ type, icon }) => {
  return icon ? (
    <>{miniAppIconMap[icon]}</>
  ) : type === MenuType.CARD ? (
    <HomeOutlined />
  ) : type === MenuType.TOOL ? (
    <AppstoreOutlined />
  ) : (
    <SmallDashOutlined />
  );
};

export default MenuIconRenderer;
