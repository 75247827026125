import { RouteObject } from 'react-router-dom';
import { PermissionsType } from '../common/permissionsConst';
import AuthorizedPermission from '../components/AuthorizedPermission';
import CreatePlateformEmployee from '../pages/CreatePlatformEmployee';
import DataPermissionAssignment from '../pages/CreatePlatformEmployee/DataPermissionAssignment';
import EditPlatformEmployee from '../pages/CreatePlatformEmployee/EditPlatformEmployee';
import FunctionPermissionAssignment from '../pages/CreatePlatformEmployee/FunctionPermissionAssignment';
import StaffList from '../pages/PlatformManagement/OrganizationAuthority/StaffManagement';
import MaxtropyAbilityList from '../pages/MaxtropyAbilityList';
import MiniProgram from '../pages/MiniProgram/List';
import LogPage from '../pages/MiniProgram/AppletNavigationLog/OperationLog';
import MiniProgramPack from '../pages/MiniProgram/Pack/MiniProgramPack';
import ToolManage from '../pages/ToolManage';
import OperationLog from '../pages/ToolManage/OperationLog';
import CustomizeNavigation from '../pages/CustomizeNavigation';
import EditEachRecord from '../pages/CustomizeNavigation/EditEachRecord';
import ListDetail from '../pages/CustomizeNavigation/ListDetail';
import Oalite from '../pages/Oalite';
import CreateOaliteAccount from '../pages/Oalite/CreateOaliteAccount';
import ChooseWorkspace from '../pages/Oalite/Workspace/ChooseWorkspace';
import ManageWorkspace from '../pages/Oalite/Workspace/ManageWorkspace';
import { EditPermissions, EditRole } from '../pages/RolePermissions/Edit';
import TenantPrefabRole from '../pages/TenantManagement/SystemSetting/TenantPrefabRole';
import TenantEmployeeList from '../pages/TenantManagement/SystemSetting/TenantEmployeeManagement';
import TenantEmployeeCreate from '../pages/TenantManagement/SystemSetting/TenantEmployeeManagement/TenantEmployeeCreate';
import TenantEmployeeDetail from '../pages/TenantManagement/SystemSetting/TenantEmployeeManagement/TenantEmployeeDetail';
import TenantStaffCreate from '../pages/TenantManagement/SystemSetting/TenantUserManagement/CreateStaff';
import CustomerManage from '../pages/CustomerManage';
import CustomerForm from '../pages/CustomerManage/form';
import CustomerTab from '../pages/CustomerManage/CustomerTab';
import IntegrateAppPermissions from '../pages/CustomerManage/IntegrateAppPermissions';
import TenantUserList from '../pages/TenantManagement/SystemSetting/TenantUserManagement';
import TenantUserCreate from '../pages/TenantManagement/SystemSetting/TenantUserManagement/Create';
import TenantUserDetail from '../pages/TenantManagement/SystemSetting/TenantUserManagement/Detail';
import LoginPasswordForm from '../pages/PersonalCenter/PersonalSetting/LoginPassword';
import OperatePasswordForm from '../pages/PersonalCenter/PersonalSetting/OperatePassword';

const ccRoutes: RouteObject[] = [
  {
    path: 'platform/account/employee',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PCORPUSER}>
        <StaffList />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'platform/account/employee/create',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PCREATECORPUSER}>
        <CreatePlateformEmployee />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'platform/account/employee/edit/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PUPDATECORPUSER}>
        <EditPlatformEmployee />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'platform/account/employee/show/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PCORPUSERINFO}>
        <EditPlatformEmployee isEdit={false} />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'platform/account/employee/function/create/:corpUserId',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PCREATECORPUSER}>
        <FunctionPermissionAssignment isCreate />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'platform/account/employee/function/edit/:corpUserId',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PUPDATECORPUSER}>
        <FunctionPermissionAssignment />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'platform/account/employee/function/show/:corpUserId',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PCORPUSERINFO}>
        <FunctionPermissionAssignment isEdit={false} />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'platform/account/employee/data/create/:corpUserId',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PCREATECORPUSER}>
        <DataPermissionAssignment />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'platform/account/employee/data/edit/:corpUserId',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PUPDATECORPUSER}>
        <DataPermissionAssignment />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'platform/account/employee/data/show/:corpUserId',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PCORPUSERINFO}>
        <DataPermissionAssignment isEdit={false} />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'platform/maxtropyInfoPlatform/abilityList',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_MAXTROPY_CAPABILITY_LIST}>
        <MaxtropyAbilityList />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'platform/miniProgram/manage',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_NAVIGATIONPROGRAM}>
        <MiniProgram />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'platform/miniProgram/manage/pack',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_NAVIGATIONPROGRAM}>
        <MiniProgramPack />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'platform/miniProgram/manage/pack/update/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_NAVIGATIONPROGRAM}>
        <MiniProgramPack update />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'platform/miniProgram/manage/LogPage/:logId',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_NAVIGATIONPROGRAM}>
        <LogPage />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'platform/miniProgram/toolManage',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_MINIAPPTOOLMANAGE}>
        <ToolManage />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'platform/miniProgram/toolManage/operationLog/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_MINIAPPTOOLMANAGE}>
        <OperationLog />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'platform/navigationSettings/scheme',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_NAVIGATIONPROGRAM}>
        <CustomizeNavigation />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'platform/navigationSettings/scheme/edit/:editId',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_NAVIGATIONPROGRAM}>
        <EditEachRecord />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'platform/navigationSettings/scheme/detail/:navigationProgramId',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_NAVIGATIONPROGRAM}>
        <ListDetail />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tenant/customer/oalite',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_QING_FLOW_ACCOUNT_OPEN}>
        <Oalite />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tenant/customer/oalite/create/:mcid/open',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.B_QING_FLOW_ACCOUNT_OPEN}>
        <CreateOaliteAccount />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tenant/customer/oalite/create/:mcid/config',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.B_QING_FLOW_ACCOUNT_OPEN_PAGE}>
        <CreateOaliteAccount isSet />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tenant/customer/oalite/workspace/create/:mcid',
    element: <ChooseWorkspace />,
  },
  {
    path: 'tenant/customer/oalite/workspace/manage',
    element: <ManageWorkspace />,
  },
  {
    path: 'tenant/customer/prefab-role',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PROLEMANAGE}>
        <TenantPrefabRole />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tenant/customer/prefab-role/permissions/update/:code',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PUPDATEROLE}>
        <EditPermissions />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tenant/customer/prefab-role/permissions/detail/:code',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PROLEMANAGE}>
        <EditPermissions />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tenant/customer/prefab-role/role/create',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PCREATEROLE}>
        <EditRole />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tenant/customer/prefab-role/role/update/:code',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PUPDATEROLE}>
        <EditRole />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tenant/customer/prefab-role/role/detail/:code',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PROLEMANAGE}>
        <EditRole />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tenant/customer/employee',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PSTAFFMANAGE}>
        <TenantEmployeeList />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tenant/customer/employee/create',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PCREATESTAFF}>
        <TenantEmployeeCreate />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tenant/customer/employee/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PSTAFFMANAGE}>
        <TenantEmployeeDetail />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tenant/customer/employee/createStaff/:muid',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PCREATESTAFF}>
        <TenantStaffCreate />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tenant/customer/manage',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PTENANTMANAGE}>
        <CustomerManage />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tenant/customer/manage/create',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PCREATETENANTINFO}>
        <CustomerForm />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tenant/customer/manage/edit/:mcid',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PTENANTMANAGE}>
        <CustomerTab />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tenant/customer/manage/edit/:mcid/:key',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PTENANTMANAGE}>
        <CustomerTab />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tenant/customer/manage/detail/:mcid',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PTENANTMANAGE}>
        <CustomerTab disabled />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tenant/customer/manage/detail/:mcid/:key',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PTENANTMANAGE}>
        <CustomerTab disabled />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tenant/customer/manage/integrade/:mcid/:integratedAppId',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PTENANTMANAGE}>
        <IntegrateAppPermissions />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tenant/customer/user',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PCUSTOMERUSERMANAGE}>
        <TenantUserList />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tenant/customer/user/create',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PCREATECUSTOMERUSER}>
        <TenantUserCreate />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tenant/customer/user/create/:muid',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PCREATECUSTOMERUSER}>
        <TenantUserCreate />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tenant/customer/user/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PCUSTOMERUSERINFO}>
        <TenantUserDetail />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tenant/customer/user/createStaff/:muid',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PCUSTOMERUSERMANAGE}>
        <TenantStaffCreate />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'user/settings/loginPassword',
    element: <LoginPasswordForm />,
  },
  {
    path: 'user/settings/operatePassword',
    element: <OperatePasswordForm />,
  },
];

export default ccRoutes;
