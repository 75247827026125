import { fetch } from '@maxtropy/components';
import qs from 'qs';

export interface UniversalResourcesCount {
  deviceTemplateCount: number;
  gatewayCount: number;
  edgeDeviceCountTemplate: number;
}

export function getUniversalResourcesCount(mcid: string): Promise<UniversalResourcesCount> {
  return fetch(`/api/integrated-app/universal-resources-count?mcid=${mcid}`);
}

export interface IntegratedAppResponse {
  id: number;
  name: string;
  code: string;
  appKey: string;
  picUrl: string;
  subscribe?: boolean;
  createTime?: string;
  updateTime?: string;
}

export function getIntegratedAppList(mcid: string): Promise<IntegratedAppResponse[]> {
  return fetch(`/api/integrated-app/list?${qs.stringify({ mcid }, { indices: false })}`);
}

// 获取所有的集成应用信息
export function getAllIntegratedAppList(): Promise<IntegratedAppResponse[]> {
  return fetch(`/api/integrated-app/all`);
  // return fetch(`http://10.50.16.213:40001/mock/202/api/integrated-app/all`)
}

export function unsubscribeIntegratedApp(id: number, mcid: string): Promise<boolean> {
  return fetch(`/api/integrated-app/${id}/unsubscribe?mcid=${mcid}`, {
    method: 'PUT',
    headers: {
      'x-tenant-mcid': `${mcid}`,
    },
  });
}

export function subscribeIntegratedApp(id: number, mcid: string): Promise<boolean> {
  return fetch(`/api/integrated-app/${id}/subscribe?mcid=${mcid}`, {
    method: 'PUT',
    headers: {
      'x-tenant-mcid': `${mcid}`,
    },
  });
}
