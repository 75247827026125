import React, { CSSProperties } from 'react';

interface IndexProps {
  style?: CSSProperties;
  children?: React.ReactNode;
}

const Footer: React.FC<IndexProps> = props => {
  return (
    <div className="footer" style={props.style}>
      {props.children}
    </div>
  );
};

export default Footer;
