export enum statusEnum {
  启用,
  禁用,
  冻结,
}

export enum genderEnum {
  男,
  女,
}
