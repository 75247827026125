import { FC, useEffect, useMemo, useState } from 'react';
import { App, Button, Form, Input, Popconfirm, Select, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FormInstance } from 'antd/es';
import { useNavigate, useParams } from 'react-router-dom';
import { uniqBy } from 'lodash-es';
import { Wrapper } from '@maxtropy/components';
import {
  apiCarbonEmissionProcessDetailPost,
  apiCarbonEmissionProcessEditFormulaPost,
  CarbonEmissionProcessDetailPostResponse,
} from '@maxtropy/device-mgmt-apis';

import styles from '../index.module.scss';
import StepsView from '../components/StepsView';
import { Fields } from '../BasicInfo/BasicInfoTable';
import { useReadonly } from '../hooks';

const { Item } = Form;

enum CalculateTypeValue {
  IN = 1, // 输入
  OUT = 2, // 输出
}

enum State {
  DRAFT = 0, // 草稿
  ENABLE = 1, // 启用
  DISABLE = 2, // 禁用
}

interface CalcFormulaProps {}

const CarbonEmissionsEditCalcFormula: FC<CalcFormulaProps> = props => {
  const { id } = useParams<{ id: string }>();
  const { modal, message } = App.useApp();
  const navigate = useNavigate();
  const readonly = useReadonly();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    apiCarbonEmissionProcessDetailPost({ id: id })
      .then(res => {
        // 绑定字段有修改，对不上则置为空
        res.params?.forEach(paramItem => {
          let fieldItem = res.fields?.find(fieldItem => fieldItem.fieldName === paramItem.formFieldName);
          if (!fieldItem) {
            paramItem.formFieldName = '';
          }
        });

        form.setFieldsValue(res);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const routes = useMemo(() => {
    return [{ name: '企业碳排查' }, { name: '排放过程管理' }, { name: id ? '编辑排放过程' : '新建排放过程' }];
  }, [id]);

  const validate = async (): Promise<boolean> => {
    let flag = true;
    const params: ParamsTableRow[] = form.getFieldValue('params') || [];

    if (params.filter(item => item.calculateType === CalculateTypeValue.IN).length === 0) {
      message.error('请填写输入参数');
      flag = false;
    }
    if (params.filter(item => item.calculateType === CalculateTypeValue.OUT).length === 0) {
      message.error('请填写输出参数');
      flag = false;
    }
    try {
      await form.validateFields();
    } catch (e) {
      flag = false;
    }
    return flag;
  };

  const confirm = (state?: State) => {
    validate().then(res => {
      if (!res) return;

      setLoading(true);
      apiCarbonEmissionProcessEditFormulaPost({
        id: Number(id),
        state: state,
        params: form.getFieldValue('params'),
        calculateFormula: form.getFieldValue('calculateFormula'),
      })
        .then(res => {
          navigate('/dualCarbon/carbonEmissions/process');
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const onPre = () => {
    if (readonly) {
      navigate(`/dualCarbon/carbonEmissions/process/basicInfo/detail/${id}`);
      return;
    }
    modal.confirm({
      content: '确认放弃未保存的修改返回上一步吗?',
      onOk: () => {
        navigate(`/dualCarbon/carbonEmissions/process/basicInfo/edit/${id}`);
      },
    });
  };

  return (
    <Spin spinning={loading} style={{ maxHeight: '100%' }}>
      <Wrapper routes={routes} className={styles.wrapper}>
        <div className={styles.wrapperInner}>
          <StepsView currentStep={1}></StepsView>

          <Form layout="vertical" form={form} style={{ width: '100%' }} disabled={readonly}>
            <Item label="输入参数" required>
              <ParamsTable
                form={form}
                calculateType={CalculateTypeValue.IN}
                fields={form.getFieldValue('fields')}
              ></ParamsTable>
            </Item>
            <Item label="输出参数" required>
              <ParamsTable
                form={form}
                calculateType={CalculateTypeValue.OUT}
                fields={form.getFieldValue('fields')}
              ></ParamsTable>
            </Item>
            <Item label="公式" required>
              <Item name="calculateFormula" rules={[{ required: true, message: '请输入公式' }]}>
                <Input.TextArea autoSize={{ minRows: 5 }}></Input.TextArea>
              </Item>
              <div style={{ color: 'rgba(0,0,0,0.65)', marginTop: 8 }}>
                注意：公式仅支持+-*/（）=，等号；输入参数以大写C开头，如C1，C2，输出参数以大写的P开头，如P1，P2；多个公式用;间隔
              </div>
            </Item>
          </Form>

          <div className={styles.ctrlWrap}>
            <Button onClick={onPre}>上一步</Button>
            {readonly ? (
              <Button onClick={() => navigate('/dualCarbon/carbonEmissions/process')}>关闭</Button>
            ) : (
              <>
                <Button type="primary" onClick={() => confirm()}>
                  保存
                </Button>
                <Button type="primary" onClick={() => confirm(State.ENABLE)}>
                  保存并启用
                </Button>
              </>
            )}
          </div>
        </div>
      </Wrapper>
    </Spin>
  );
};

type ParamsTableRow = NonNullable<CarbonEmissionProcessDetailPostResponse['params']>[number];
interface ParamsTableRowWithIndex extends ParamsTableRow {
  totalIndex: number;
}

interface InParamsTableRowProps {
  calculateType: CalculateTypeValue;
  fields: Fields;
  form: FormInstance;
}

const ParamsTable: FC<InParamsTableRowProps> = props => {
  const readonly = useReadonly();
  const { form, calculateType, fields } = props;

  const params: ParamsTableRow[] = Form.useWatch('params', { form, preserve: true });
  const allData = useMemo(() => {
    return (
      params?.map((item, index) => {
        return {
          ...item,
          totalIndex: index,
        };
      }) ?? []
    );
  }, [params]);
  const data = useMemo(() => {
    return allData?.filter(item => item.calculateType === calculateType);
  }, [allData, calculateType]);

  const onAddRow = () => {
    const newData = params ? [...params] : [];
    newData.push({
      calculateType: calculateType,
      paramOrder: newData.length + 1,
      paramName: '',
      formFieldName: '',
    });
    form.setFieldValue('params', newData);
  };

  const otherColumns: ColumnsType<ParamsTableRowWithIndex> = [
    {
      title: '序号',
      dataIndex: 'paramOrder',
      width: 80,
    },
    {
      title: '参数',
      dataIndex: 'paramName',
      render: (text, record, index) => (
        <Item
          name={['params', record.totalIndex, 'paramName']}
          rules={[
            { required: true, message: '请输入参数' },
            {
              validator: (rule, value) => {
                if (!value) return Promise.resolve();
                let flag = allData?.some(item => {
                  return item.paramName === value && item.totalIndex !== record.totalIndex;
                });
                return flag ? Promise.reject('参数名不能重复') : Promise.resolve();
              },
            },
          ]}
          className={styles.mb0}
        >
          <Input style={{ width: 135 }} maxLength={5}></Input>
        </Item>
      ),
    },
    {
      title: '绑定字段',
      dataIndex: 'formFieldName',
      render: (text, record, index) => (
        <Item
          name={['params', record.totalIndex, 'formFieldName']}
          rules={[
            { required: true, message: '请选择绑定字段' },
            {
              validator: (rule, value) => {
                if (!value) return Promise.resolve();
                let flag = allData?.some(item => {
                  return item.formFieldName === value && item.totalIndex !== record.totalIndex;
                });
                return flag ? Promise.reject('绑定字段不能重复') : Promise.resolve();
              },
            },
          ]}
          className={styles.mb0}
        >
          <Select
            style={{ width: 200 }}
            options={fields?.map(item => ({ label: item.fieldName, value: item.fieldName }))}
          ></Select>
        </Item>
      ),
    },
  ];

  const actionColumns: ColumnsType<ParamsTableRowWithIndex> = [
    {
      title: '操作',
      width: 120,
      align: 'center',
      render: (text, record, index) => (
        <Popconfirm
          title="是否移除此参数？"
          onConfirm={() => {
            const newData = allData.filter(item => item.totalIndex !== record.totalIndex);
            form.setFieldValue('params', newData);
          }}
        >
          <Button type="link">移除</Button>
        </Popconfirm>
      ),
    },
  ];
  const columns = readonly ? otherColumns : otherColumns.concat(actionColumns);

  return (
    <>
      <Table pagination={false} columns={columns} dataSource={data} rowKey="paramOrder"></Table>
      {!readonly && (
        <Button type="dashed" style={{ width: '100%', marginTop: 10 }} onClick={onAddRow}>
          新增一行
        </Button>
      )}
    </>
  );
};

export default CarbonEmissionsEditCalcFormula;
