import { FormInstance } from 'antd';
import { ComponentType } from 'react';
import { EdgeDeviceTemplateDetail, IotProtocolType } from '../../types';
import EstunTemplateFormItems from './estun/TemplateFormItems';
import EstunTemplateFormItemsDisplay from './estun/TemplateFormItemsDisplay';
import MockingbirdTemplateFormItems from './mockingbird/TemplateFormItems';
import MockingbirdTemplateFormItemsDisplay from './mockingbird/TemplateFormItemsDisplay';

export interface TemplateFormItemsProps {
  row?: EdgeDeviceTemplateDetail;
  form: FormInstance;
}

export const templateFormItemsByType: Partial<Record<IotProtocolType, ComponentType<TemplateFormItemsProps>>> = {
  [IotProtocolType.ESTUN]: EstunTemplateFormItems,
  [IotProtocolType.MOCKINGBIRD]: MockingbirdTemplateFormItems,
};

export interface TemplateFormItemsDisplayProps {
  row?: EdgeDeviceTemplateDetail;
}

export const templateFormItemsDisplayByType: Partial<
  Record<IotProtocolType, ComponentType<TemplateFormItemsDisplayProps>>
> = {
  [IotProtocolType.ESTUN]: EstunTemplateFormItemsDisplay,
  [IotProtocolType.MOCKINGBIRD]: MockingbirdTemplateFormItemsDisplay,
};
