import { PageRequest, PageResponse } from './page';
import qs from 'qs';
import { fetch, Key } from '@maxtropy/components';
import { DriveType, IotProtocolType } from '../types';

export interface DevicePageRequest extends PageRequest {
  codeOrName?: string;
  tag?: string;
  iotProtocol?: IotProtocolType;
  driveType?: DriveType;
  tenantMcid?: string;
  deviceTypeId?: number | number[];
  physicalModelId?: Key;
}

export interface EdgeDevice {
  id: number;
  name: string;
  code: string;
  typeId: number;
  typeName: string;
  rootMcid: string;
  tenantCode: string;
  rootFullName: string;
  tags?: string[];
  customerFullName?: string;
}

export type DevicePageResponse = PageResponse<EdgeDevice>;

export function getDevicePage(params: DevicePageRequest): Promise<DevicePageResponse> {
  return fetch(`/api/batch/edgeDevice/device/page?${qs.stringify(params, { indices: false })}`);
}
