import { Form, Input, Modal } from '@maxtropy/components';
import { apiV2CronjobExecPost, V2CronjobListPostResponse } from '@maxtropy/tody-mgmt-apis-v2';
import { Col, Row, Space } from 'antd';

import { useEffect, useMemo, useState } from 'react';
export type DataProps = Exclude<V2CronjobListPostResponse['list'], undefined>[number];
interface Iprops {
  data?: DataProps;
  onCancel?: () => void;
  onConfirm?: () => void;
}
const TaskModal = ({ data, onCancel, onConfirm }: Iprops) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    form.setFieldsValue({
      list: data?.param ?? [],
    });
  }, [data]);

  const param = useMemo(() => data?.param ?? [], [data]);
  const onOk = () => {
    form.validateFields().then(values => {
      setLoading(true);
      apiV2CronjobExecPost({
        taskId: data?.taskId,
        param: values.list,
      })
        .then(res => {
          onConfirm?.();
          if (res.msg) {
            Modal.confirm({
              content: res.msg,
            });
          }
        })
        .finally(() => setLoading(false));
    });
  };
  return (
    <>
      <Modal
        bodyScroll
        maskClosable={false}
        confirmLoading={loading}
        open
        size="big"
        title={data?.name ?? '--'}
        onCancel={() => onCancel?.()}
        onOk={onOk}
      >
        <Form form={form}>
          <Form.List name="list">
            {fields => {
              return (
                <div>
                  {fields.map(field => {
                    let currentData = param?.[field.name];
                    return (
                      <div key={field.key}>
                        <Space size={10}>
                          <Form.Item
                            rules={[
                              {
                                required: currentData?.required === 'true',
                                message: `请输入${currentData?.describe ?? ''}`,
                              },
                            ]}
                            label={currentData?.field ?? ''}
                            name={[field.name, 'value']}
                          >
                            <Input style={{ width: 200 }} placeholder={currentData?.placeholder ?? ''} />
                          </Form.Item>

                          <div style={{ flex: 1, paddingBottom: 22 }}>{currentData?.describe ?? ''}</div>
                        </Space>
                      </div>
                    );
                  })}
                </div>
              );
            }}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};

export default TaskModal;
