import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, Link as ReactDomLink, useNavigate } from 'react-router-dom';
import { AuthorizedPage } from '@maxtropy/components';
import { menuData } from '../../common/menu';
import { useCurrent, useMgmtMenu, useMgmtMenuOption } from './hooks';
import { Layout, Menu } from 'antd';
import { MenuEntry, PlaceType } from '@/api/menu';
import UserInfo from './UserInfo';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import Logo from './Logo';
import { getFirstUrl } from '@/utils/utils';
import { Link } from 'react-router-dom';
import './index.scss';

const { Header, Sider } = Layout;

const buildSubMenu = (data: MenuEntry[]) => {
  const items: ItemType[] = data.map(i => {
    return {
      key: i.url,
      title: i.name,
      label: i.children && i.children.length ? i.name : <Link to={i.url}>{i.name}</Link>,
      children: i.children && i.children.length ? buildSubMenu(i.children) : undefined,
    };
  });
  return items;
};

const BasicLayout: FC<{ children?: ReactNode }> = props => {
  const current = useCurrent();
  const menuOption = useMgmtMenuOption();
  const navigate = useNavigate();
  const menu = useMgmtMenu(menuData);
  const { pathname } = useLocation();

  const [openkeys, setOpenKeys] = useState<string[]>([]);
  const [inlineCollapsed, setInlineCollapsed] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);
  const headerMenuPopupDivRef = useRef<HTMLDivElement>(null);

  const firstActiveKey = useMemo(() => {
    return pathname.split('/')[1];
  }, [pathname]);

  const thirdActiveKey = useMemo(() => {
    return pathname.split('/').slice(0, 4).join('/');
  }, [pathname]);

  const activeSubMenu = useMemo(() => {
    const subMenu = menu.find(i => (i.url ?? '').split('/')[1] === firstActiveKey);
    return subMenu?.children || [];
  }, [firstActiveKey, menu]);

  useEffect(() => {
    if (menuOption && menu) {
      const activeMenuOption = menuOption
        .filter(i => i.place === PlaceType.TOP)
        .find(i => i.path === pathname.replace(/\/+$/, ''));
      if (activeMenuOption) {
        const filterMenu = menu.find(i => i.key === activeMenuOption.code);
        if (filterMenu) {
          const url = getFirstUrl(filterMenu.children);
          navigate(url);
        }
      }
    }
  }, [pathname, menu]);

  useEffect(() => {
    const openKey = pathname.split('/').slice(0, 3).join('/');
    setOpenKeys([openKey]);
  }, [pathname]);

  const defaultUserInfoMenu = useMemo(() => {
    if (menuOption) {
      return menuOption
        .filter(i => i.place === PlaceType.NAVBAR)
        .map(i => {
          return {
            url: i.path,
            key: i.code,
            name: i.name,
          } as MenuEntry;
        });
    } else {
      return [];
    }
  }, [menuOption]);

  return (
    <Layout className={`central-menu-layout-wrap`}>
      <Header className={`central-menu-header`}>
        <ReactDomLink className={`central-menu-link`} to="/">
          <Logo title={window?.MGMTTITLE ?? '运营管理平台'} />
        </ReactDomLink>
        {!!activeSubMenu.length && (
          <div
            className={`central-menu-inline-collapsed`}
            onClick={() => {
              setInlineCollapsed(!inlineCollapsed);
            }}
          >
            {inlineCollapsed ? (
              <MenuUnfoldOutlined style={{ fontSize: 17, color: '#FFF' }} />
            ) : (
              <MenuFoldOutlined style={{ fontSize: 17, color: '#FFF' }} />
            )}
          </div>
        )}
        <div className={`central-menu-menu`}>
          <Menu
            theme="dark"
            mode="horizontal"
            getPopupContainer={() => headerMenuPopupDivRef.current!}
            selectedKeys={[firstActiveKey]}
            items={menu.map(i => ({
              key: (i.url ?? '').split('/')[1] ?? '',
              title: i.name,
              label: (
                <>
                  <div className="menuContent"></div>
                  <Link className="menuLink" to={i.url}>
                    {i.name}
                  </Link>
                  <div className="menuContent"></div>
                </>
              ),
            }))}
          />
        </div>
        <UserInfo menuItems={defaultUserInfoMenu} hasAvatar={false} name={current?.name} />
      </Header>
      <Layout>
        <Sider
          width={254}
          className={`central-menu-sider`}
          collapsed={!activeSubMenu.length || inlineCollapsed}
          collapsedWidth={0}
          zeroWidthTriggerStyle={{ display: 'none' }}
          trigger={null}
        >
          {!(!activeSubMenu.length || inlineCollapsed) && (
            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={inlineCollapsed ? [] : [thirdActiveKey]}
              openKeys={inlineCollapsed ? [] : openkeys}
              onOpenChange={openkeys => {
                setOpenKeys(openkeys as string[]);
              }}
              items={buildSubMenu(activeSubMenu)}
              inlineCollapsed={inlineCollapsed}
              getPopupContainer={() => ref.current as HTMLElement}
            />
          )}
        </Sider>
        <Layout className={`central-menu-content`}>
          <AuthorizedPage checkStatus={false}>{props.children}</AuthorizedPage>
        </Layout>
      </Layout>
      <div id={`central-menu-sider-wrapper`} ref={ref} />
      <div className={`central-menu-header-popup`} ref={headerMenuPopupDivRef}></div>
    </Layout>
  );
};

export default BasicLayout;
