import { fetch, Key } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import qs from 'qs';
import { IotProtocolType, PointType } from '@/shared/types';
import { EdgeDeviceTemplatePoint } from '@/shared/components/EdgeDevicePointInfo';
import { MockingbirdDeviceProps } from '@/shared/components/DataAcquisitionForm/types';

export interface EdgeDeviceInfo extends MockingbirdDeviceProps {
  id: number;
  edgeGatewayId?: number;
  deviceId: number;
  deviceTypeId: number;
  iotProtocol: number;
  deviceModelId: IotProtocolType;
  edgeDeviceTemplateId: number;
  edgeDeviceTemplateVersionId: number;
  edgeDeviceTemplateVersion: number;
  points: PointType[];
  edgeDeviceTemplateName?: string;
  edgeDeviceTemplateLatestVersion?: number;
  edgeDeviceTemplateVersionRemark?: string;
}

export const queryEdgeDeviceInfo = (id: string): Promise<EdgeDeviceInfo> =>
  fetch<EdgeDeviceInfo>(`/api/edgeDevice/v2/${id}`).then(data => ({
    ...data,
    points: (data?.points ?? []).map((item: any) => ({ ...item, id: item.edgeDevicePointId })),
  }));

export interface EdgeDeviceRealTimeDataProps {
  pointId: number;
  data: string;
  ts: any;
}

export const queryEdgeDeviceRealTimeData = (body: { pointIds: string[] }) =>
  fetch<EdgeDeviceRealTimeDataProps[]>(`/api/edgeDevice/v2/realTimeData`, {
    method: 'POST',
    body: JSON.stringify(body),
  });

export const queryEdgeDeviceRealTimeDataForMockingbird = (body: { pointIds: string[] }) =>
  fetch<EdgeDeviceRealTimeDataProps[]>(`/api/edgeDevice/v2/realTimeDataForMockingbird`, {
    method: 'POST',
    body: JSON.stringify(body),
  });

interface UpdateEstunDeviceBody {
  edgeDeviceTemplateVersionId: number;
  deviceIds: number[];
  iotProtocol: number;
  typeId: number;
  physicalModelId?: Key;
}

export const updateEstunDevice = (body: UpdateEstunDeviceBody, tenantMcid: string) => {
  return fetch<void>(`/api/edgeDevice/v2`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'x-tenant-mcid': tenantMcid,
    },
  });
};

export const emptyEdgeDevice = (id: string) => fetch<boolean>(`/api/edgeDevice/v2/clear/${id}`, { method: 'PUT' });

interface UpdateMockingbirdDeviceBody extends MockingbirdDeviceProps {
  edgeDeviceTemplateVersionId: number;
  deviceIds: number[];
  iotProtocol: number;
  typeId: number;
  points: EdgeDeviceTemplatePoint[];
  deletePointIds?: string[];
  physicalModelId?: Key;
}

export const updateMockingbirdDevice = (body: UpdateMockingbirdDeviceBody, tenantMcid: string) =>
  fetch<any>(`/api/edgeDevice/v2`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'x-tenant-mcid': tenantMcid,
    },
  });

export interface EdgeDeviceDetailSimple {
  id: number;
  deviceTypeId: number;
  iotProtocol: IotProtocolType;
}

export const getEdgeDeviceInfoSimple = (id: string) => fetch<EdgeDeviceDetailSimple>(`/api/edgeDevice/v2/${id}/simple`);

export interface DevicePageRequest extends PageRequest {
  name?: string;
  deviceType?: Array<number>;
  rootMcid?: string | undefined;
  physicalModelId?: Key;
}

interface EdgeDevice {
  id: number;
  name: string;
  code: string;
  typeId: number;
  typeName: string;
  rootMcid: string;
  tenantCode: string;
  rootFullName: string;
}

export type DevicePageResponse = PageResponse<EdgeDevice>;

export function getNotConfiguredEdgeDevicePage(params: DevicePageRequest): Promise<DevicePageResponse> {
  return fetch(`/api/edgeDevice/v2/device/list?${qs.stringify(params, { indices: false })}`);
}
