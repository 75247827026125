import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { Button, Col, Divider, Form, Input, Popconfirm, Select, message, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { EllipsisSpan, Paging, Table, usePaging, Wrapper } from '@maxtropy/components';
import {
  apiCarbonIndustryAllPost,
  apiCarbonEmissionLibraryDeletePost,
  apiCarbonEmissionLibraryStatusPost,
  apiCarbonEmissionLibraryPagePost,
  CarbonEmissionLibraryPagePostRequest,
  CarbonEmissionLibraryPagePostResponse,
  CarbonIndustryAllPostResponse,
} from '@maxtropy/device-mgmt-apis';
import Filter from '@/shared/components/Filter';
import { CarbonEmissionLibraryData, TypeEnum, statusValue, StatusEnum, typeValue } from './type';

const routes = [{ name: '双碳管理' }, { name: '基本信息' }, { name: '排放因子库' }];

const FormItem = Form.Item;

const basicColumns = [
  {
    title: '名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '类别',
    dataIndex: 'type',
    ellipsis: { showTitle: true },
    render: (v: TypeEnum) => <EllipsisSpan value={typeValue[v]} />,
  },
  {
    title: '状态',
    dataIndex: 'state',
    ellipsis: { showTitle: true },
    render: (v: StatusEnum) => <EllipsisSpan value={statusValue[v]} />,
  },
  {
    title: '操作人',
    dataIndex: 'updateUser',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '操作时间',
    width: 200,
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: any) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm')} />,
  },
];

export interface SearchParams extends Omit<CarbonEmissionLibraryPagePostRequest, 'page' | 'size'> {}

const EmissionFactor: React.FC = () => {
  const [industryData, setIndustryData] = useState<CarbonIndustryAllPostResponse>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<SearchParams>({ industryId: '0', type: '0' });
  const [list, setList] = useState<CarbonEmissionLibraryPagePostResponse['list']>();

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [form] = Form.useForm();

  const loadData = () => {
    if (!searchParams) {
      return;
    }
    setLoading(true);

    apiCarbonEmissionLibraryPagePost({
      page: String(pageOffset),
      size: String(pageSize),
      ...searchParams,
    })
      .then(res => {
        if (res.list) {
          setList(res.list || []);
          setTotalCount(res.total as number);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, pageOffset, pageSize]);

  useEffect(() => {
    apiCarbonIndustryAllPost().then(res => {
      if (res) {
        setIndustryData(res);
      }
    });
  }, []);

  const onFinish = (query: any) => {
    setPageOffset(1);
    setSearchParams(query);
  };

  const onReset = () => {
    const params = { industryId: '0', type: '0' };
    setSearchParams(params);
    setPageOffset(1);
  };

  const onDel = (id: number) => {
    apiCarbonEmissionLibraryDeletePost({ id: String(id) }).then(() => {
      // message.success('删除成功');
      loadData();
    });
  };

  const onChangeStatus = (record: CarbonEmissionLibraryData) => {
    const status = record.state === StatusEnum.Enable ? StatusEnum.Disable : StatusEnum.Enable;
    apiCarbonEmissionLibraryStatusPost({ id: String(record.id), status: String(status) }).then(res => {
      // message.success('修改成功');
      loadData();
    });
  };

  const columns = useMemo(
    () => [
      ...basicColumns,
      {
        title: '操作',
        width: 200,
        render: (record: CarbonEmissionLibraryData) => {
          return (
            <Space size={16}>
              <Button type="link" disabled={record.state === StatusEnum.Draft} onClick={() => onChangeStatus(record)}>
                {[StatusEnum.Draft, StatusEnum.Enable].includes(record.state)
                  ? statusValue[StatusEnum.Disable]
                  : statusValue[StatusEnum.Enable]}
              </Button>
              <Link to={`/dualCarbon/basicInfo/emissionFactor/edit/${record.id}`}>
                <Button type="link">编辑</Button>
              </Link>
              {record.state === StatusEnum.Enable ? (
                <Button type="link" disabled={true}>
                  删除
                </Button>
              ) : (
                <Popconfirm title="是否删除此因子?" onConfirm={() => onDel(record.id)}>
                  <Button type="link">删除</Button>
                </Popconfirm>
              )}
            </Space>
          );
        },
      },
    ],
    []
  );

  const filters = (
    <Filter form={form} onFinish={onFinish} onReset={onReset}>
      <Col span={6}>
        <FormItem label="类型" name="type" initialValue={0}>
          <Select>
            <Select.Option key={0} value={0}>
              全部类型
            </Select.Option>
            {Object.entries(typeValue).map(([key, label]) => (
              <Select.Option key={key} value={Number(key)}>
                {label}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
      </Col>
      <Col span={6}>
        <FormItem name="industryId" initialValue={0} label="行业">
          <Select optionFilterProp="label" placeholder="请选择" showSearch>
            {[{ id: 0, name: '全部行业' }, ...industryData]?.map(i => (
              <Select.Option key={i.id} value={i.id}>
                {i.name}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
      </Col>
      <Col span={6}>
        <FormItem name="name" label="名称">
          <Input placeholder={'名称搜索'} />
        </FormItem>
      </Col>
    </Filter>
  );

  return (
    <Wrapper routes={routes} filters={filters}>
      <Link to={`/dualCarbon/basicInfo/emissionFactor/create`}>
        <Button type="primary" style={{ marginBottom: 15 }}>
          <PlusOutlined />
          新建排放因子
        </Button>
      </Link>
      <Table rowKey="id" dataSource={list} loading={loading} pagination={false} columns={columns} />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default EmissionFactor;
