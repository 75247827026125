import React, { FC, useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Space, Button } from 'antd';
import { BaseInfoContent, Form, FormTitle, Key, MXTabsProps, SubContent, Tabs, Wrapper } from '@maxtropy/components';
import PropertyDetail from './PropertyDetail';
import AttributeDetail from './AttributeDetail';
import EdgeTemplateDetail from './EdgeTemplateDetail';

import styles from './index.module.scss';
import { getDeviceProperty } from '@/api/device';
import ShowInput from '@/shared/components/ShowInput';

const routes = [{ name: 'Iot配置' }, { name: '设备管理' }, { name: '设备管理' }, { name: '设备详情' }];

const DeviceDetail: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const thirdParty = urlSearchParams.get('thirdParty');

  const [activeKey, setActiveKey] = useState<string>('2');
  const [physicalModelId, setPhysicalModelId] = useState<Key>();
  const [existEdgeTemplate, setExistEdgeTemplate] = useState<boolean>(false);
  const [existAttribute, setExistAttribute] = useState<boolean>(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      getDeviceProperty(id).then(res => {
        setPhysicalModelId(res.physicalModelId);
        setExistEdgeTemplate(res.existEdgeTemplate ?? false);
        setExistAttribute(res.existAttribute ?? false);
        form.setFieldsValue({
          id: res.id,
          name: res.name,
          code: res.code,
        });
      });
    }
  }, [id]);

  const items: MXTabsProps['items'] = useMemo(() => {
    if (!id) return [];
    const list = [
      {
        key: '2',
        label: '基础信息',
        children: <PropertyDetail id={id} />,
      },
    ];
    if (existAttribute) {
      list.push({
        key: '3',
        label: '固定属性信息',
        children: <AttributeDetail id={id} />,
      });
    }
    if (!(thirdParty === 'true') && existEdgeTemplate) {
      list.unshift({
        key: '1',
        label: '数采信息',
        children: <EdgeTemplateDetail id={id} physicalModelId={physicalModelId} />,
      });
      setActiveKey('1');
    }
    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, thirdParty, existAttribute, existEdgeTemplate]);

  const onTabChange = (key: string) => {
    setActiveKey(key);
  };

  return (
    <Wrapper routes={routes} className={styles.wrapper}>
      <FormTitle title="设备详情" />
      <SubContent>
        <Form form={form} style={{ marginRight: 50, marginBottom: 24 }}>
          <BaseInfoContent layout="horizontal">
            <Form.Item name="name" label="设备名称">
              <ShowInput />
            </Form.Item>
            <Form.Item name="code" label="设备编号">
              <ShowInput />
            </Form.Item>
            <Form.Item name="id" label="设备id">
              <ShowInput />
            </Form.Item>
          </BaseInfoContent>
        </Form>

        <Tabs activeKey={activeKey} items={items} onChange={onTabChange} />
        <Space className="sticky-footer-left">
          <Button
            onClick={() => {
              navigate('/device/manage/device');
            }}
          >
            返回
          </Button>
        </Space>
      </SubContent>
    </Wrapper>
  );
};

export default DeviceDetail;
