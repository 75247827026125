import { DataNode } from 'antd/es/tree';
import { DataPropertyGroupTreeGetResponse } from '../../ytt/types/dataPropertyGroup/tree';
export type ParentNodeData = DataPropertyGroupTreeGetResponse[number];
export type ChildNodeData = Exclude<ParentNodeData['dataPropertyGroupList'], undefined>[number];

export const formateTreeNode = (
  tree?: DataPropertyGroupTreeGetResponse,
  parentTitleNode?: (data?: ParentNodeData) => React.ReactNode,
  childTitleNode?: (data?: ParentNodeData, groups?: ChildNodeData) => React.ReactNode
) => {
  const treeData = tree?.map(item => {
    let children = item.dataPropertyGroupList?.map(child => {
      return {
        key: child.id,
        title: childTitleNode ? childTitleNode(item, child) : child.name,
        dataPropertyList: child.dataPropertyList,
      };
    });
    return {
      key: item.sceneId,
      title: parentTitleNode ? parentTitleNode(item) : item.sceneName,
      children: children,
    };
  });
  return treeData as DataNode[];
};

export const numberToString = (num?: number) => {
  return typeof num === 'number' ? String(num) : undefined;
};
export const stringToNumber = (str?: string) => {
  return str ? Number(str) : undefined;
};
