export interface CommonsPaged<T> {
  total: number;
  offset: number;
  count: number;
  contents: T[];
}
export interface CommonsPageRequest {
  offset?: number;
  count?: number;
}

export interface Paged<T> extends CommonsPaged<T> {
  records: T[];
  current: number;
}

export interface PageRequest extends CommonsPageRequest {}

export enum AppStatus {
  DISABLE = 0,
  ENABLE = 1,
}

export const AppStatusDisplay = {
  [AppStatus.DISABLE]: '禁用',
  [AppStatus.ENABLE]: '启用',
};

export enum OperateTargetType {
  APP = 'APP',
  DEVELOPER = 'DEVELOPER',
}

export enum LogType {
  EDIT_FORM = 'EDIT_FORM',
  BIND_DEVELOPER = 'BIND_DEVELOPER',
  UNBIND_DEVELOPER = 'UNBIND_DEVELOPER',
  BIND_APP = 'BIND_APP',
  UNBIND_APP = 'UNBIND_APP',
  GEN_SECRET = 'GEN_SECRET',
}

export enum Role {
  ADMIN = 'SA',
  DEVELOPER = 'DEVELOPER',
}

export enum RoleType {
  ADMIN = 1,
  COMMON = 2,
}

export const RoleTypeDisplay = {
  [RoleType.ADMIN]: '管理员',
  [RoleType.COMMON]: '普通',
};

export enum StateType {
  DISABLE = 0,
  ENABLE = 1,
}

export const StateTypeDisplay = {
  [StateType.DISABLE]: '禁用',
  [StateType.ENABLE]: '启用',
};
