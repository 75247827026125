import { FC, useDeferredValue, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Row, Col, Button, Modal, Space, Cascader } from 'antd';
import { Wrapper, FormContent, AttachmentUpload } from '@maxtropy/components';
import { commonPhoneRegex } from '../../utils/regex';
import ModalSelect from './components/ModalSelect';

import { transformSpace } from './util';

import { useCountDownGotoModal } from '../../utils/help';

import { createTenantBaseInfo, CreateTenantBaseInforequest } from '../../api/cc-tenant';

import styles from './index.module.scss';
import { areaCodeToName, useAreaJson } from '../../utils/hooks';
import { fetchLocationTips, LocationTipsResponse } from '../../api/cc-location';
import { SearchLocation } from '../../components/FormComponent';

const routes = [{ name: '租户组织权限和账号' }, { name: '租户管理' }, { name: '新建租户' }];

const formLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 2, span: 16 },
};

const CreateTenant: FC = () => {
  const [form] = Form.useForm();

  const [locationSearchData, setLocationSearchData] = useState<LocationTipsResponse['tips']>([]);

  const adCode = Form.useWatch<Array<string> | undefined>('adCode', form);

  const [search, setSearch] = useState<string>('');
  const [searchLoading, setSearchLoading] = useState<boolean>(false);

  const deferredSearch = useDeferredValue(search);

  useEffect(() => {
    if (deferredSearch && adCode) {
      setSearchLoading(true);
      fetchLocationTips({
        keywords: deferredSearch,
        city: adCode[2],
      })
        .then(res => {
          setLocationSearchData(res.tips);
          setSearch('');
        })
        .finally(() => {
          setSearchLoading(false);
        });
    }
  }, [adCode, deferredSearch]);

  const [modalApi, modalContextHolder] = Modal.useModal();
  const navigate = useNavigate();
  const { setOption, contextHolder } = useCountDownGotoModal(10);

  const districtData = useAreaJson();

  const onFinish = (values: Omit<CreateTenantBaseInforequest, 'adCode'> & { adCode: string[] }) => {
    createTenantBaseInfo({
      ...values,
      adCode: values?.adCode?.[values.adCode.length - 1],
      provinceCityDistrict: values.adCode
        ? areaCodeToName(districtData ?? [], values.adCode[values.adCode.length - 1])
        : undefined,
      affiliatendMcids: (values?.enterprises ?? []).map(i => i.mcid),
      enterprises: undefined,
    }).then(res => {
      setOption({
        title: '操作成功',
        okText: '返回列表',
        content: `已经成功新建租户，租户名${res.name}`,
        keyboard: false,
        onOk() {
          navigate('/tenant/customer/manage');
        },
      });
    });
  };

  const onCacel = () => {
    modalApi.confirm({
      title: null,
      icon: null,
      content: <div>是否放弃所有未保存信息并返回列表？</div>,
      onOk: () => {
        navigate('/tenant/customer/manage');
      },
    });
  };

  return (
    <Wrapper routes={routes}>
      <div className={styles.container}>
        <Form form={form} layout="vertical" {...formLayout} onFinish={onFinish}>
          <FormContent title="租户基础信息">
            <Row>
              <Col span={8}>
                <Form.Item
                  name="name"
                  label="租户全称"
                  rules={[
                    { required: true, message: '请输入租户全称' },
                    {
                      pattern: /^[\u4e00-\u9fa5a-zA-Z0-9\s\-.,()\uFF08\uFF09]+$/g,
                      message: '租户全称不能包含特殊字符',
                    },
                    { max: 30, message: '租户全称最多30个字符' },
                  ]}
                  validateFirst={true}
                >
                  <Input placeholder="请输入租户全称" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="uscc"
                  label="统一社会信用代码"
                  rules={[
                    {
                      pattern: /(^\d{15,15}$)|(^[A-Z0-9]{18,18}$)/,
                      message: '请输入正确的统一社会信用代码',
                    },
                  ]}
                  validateFirst={true}
                >
                  <Input placeholder="请输入统一社会信用代码" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="所属省市" name="adCode">
                  <Cascader
                    options={districtData}
                    fieldNames={{ label: 'name', value: 'adcode', children: 'districts' }}
                    placeholder="请选择"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="详细地址" name="address">
                  <SearchLocation
                    onSearch={setSearch}
                    placeholder="请输入详细地址"
                    loading={searchLoading}
                    options={(locationSearchData ?? []).map(d => ({
                      value: d.name,
                      label: d.name,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="公司电话"
                  name="phone"
                  normalize={transformSpace}
                  rules={[
                    {
                      pattern: commonPhoneRegex,
                      message: '请输入正确的公司电话',
                    },
                  ]}
                >
                  <Input placeholder="请输入公司电话" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="营业执照" name="businessLicense">
                  <AttachmentUpload
                    showUploadList={{
                      showDownloadIcon: true,
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item label="添加关联企业" name="enterprises">
                  <ModalSelect />
                </Form.Item>
              </Col>
            </Row>
          </FormContent>
          <Row className={styles.buttonWrapper}>
            <Col span={7}>
              <Form.Item {...tailLayout}>
                <Space>
                  <Button htmlType="submit" type="primary">
                    创建租户
                  </Button>
                  <Button onClick={onCacel}>关闭</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      {contextHolder}
      {modalContextHolder}
    </Wrapper>
  );
};

export default CreateTenant;
