import { EllipsisSpan, Paging, Table, usePaging, useUpdate } from '@maxtropy/components';
import { Button, Modal, Space, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
  AlarmChannel,
  AlarmLevel,
  AlarmLevelColorDisplay,
  AlarmLevelDisplay,
  AlarmStatus,
  AlarmStatusDisplay,
  OperatorDisplay,
} from '@/shared/types';
import { Link } from 'react-router-dom';
import { deleteRule, disableRule, enableRule } from '../../../api/rule';
import { PermissionsType } from '../../../common/permissionsConst';
import { RulePageList } from '@/pages/Alarm/List/types';
import {
  V2RulePagePostRequest,
  apiV2RulePagePost,
  apiV2RuleGetChannelPost,
  V2RuleGetChannelPostResponse,
} from '@maxtropy/tody-mgmt-apis-v2';

interface RuleProps {
  searchParams?: V2RulePagePostRequest;
  permission?: string[];
  tabsKey: string;
}

const Rule: React.FC<RuleProps> = ({ searchParams, permission, tabsKey }) => {
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [updateState, update] = useUpdate();
  const [loading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<RulePageList[]>([]);
  const [alarmChannelList, setAlarmChannelList] = useState<V2RuleGetChannelPostResponse['list']>();

  const [modalApi, modalContextHolder] = Modal.useModal();

  const getList = () => {
    setLoading(true);
    apiV2RulePagePost({ ...searchParams, page: pageOffset, size: pageSize }).then(res => {
      if (res) {
        setTotalCount(res.total ?? 0);
        res.list && setList(res.list);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (updateState) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState]);

  // 获取渠道数据
  useEffect(() => {
    apiV2RuleGetChannelPost().then(res => {
      setAlarmChannelList(res.list ?? []);
    });
  }, []);

  useEffect(() => {
    setPageOffset(1);
    if (pageOffset === 1) {
      update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, setPageOffset]);

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, pageSize]);

  const columns = [
    {
      title: '规则编号',
      dataIndex: 'code',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '规则名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '报警信息',
      dataIndex: 'alarmName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '状态',
      dataIndex: 'status',
      ellipsis: { showTitle: true },
      render: (v: AlarmStatus) => <EllipsisSpan value={AlarmStatusDisplay[v]} />,
    },
    {
      title: '报警等级',
      dataIndex: 'alarmType',
      ellipsis: { showTitle: true },
      render: (v: AlarmLevel) => (
        <EllipsisSpan value={<Tag color={AlarmLevelColorDisplay[v]}>{AlarmLevelDisplay[v]}</Tag>} />
      ),
    },
    {
      title: '渠道',
      dataIndex: 'channel',
      ellipsis: { showTitle: true },
      render: (v: AlarmChannel) => <EllipsisSpan value={alarmChannelList?.find(i => i.code === v)?.desc} />,
    },
    {
      title: '已使用设备数量',
      dataIndex: 'useDeviceCount',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={v.toString()} />,
    },
    {
      title: '所属租户',
      dataIndex: 'tenantCode',
      ellipsis: { showTitle: true },
      render: (v: string, record: RulePageList) => <EllipsisSpan value={`${v} ${record.tenantName}`} />,
    },
    {
      title: '最后操作时间',
      dataIndex: 'updateTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
    },
    {
      title: '最后操作人',
      dataIndex: 'updateByUsername',
      ellipsis: { showTitle: true },
      render: (value: string, record: RulePageList) => {
        return <EllipsisSpan value={`${value}（${OperatorDisplay[record.updateSource!]}）`} />;
      },
    },
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 280,
      render: (record: RulePageList) => (
        <Space size={16}>
          {(permission ?? []).includes(PermissionsType.B_RULE_AND_GROUP_DELETE) && (
            <Button
              style={{ paddingLeft: 0, paddingRight: 0 }}
              type="link"
              onClick={() => {
                onDelete(record.id!);
              }}
            >
              删除
            </Button>
          )}
          {(permission ?? []).includes(PermissionsType.B_RULE_AND_GROUP_ENABLE_DISABLE) && (
            <Button
              style={{ paddingLeft: 0, paddingRight: 0 }}
              type="link"
              onClick={() => {
                onChangeStatus(record.id!, record.status!);
              }}
            >
              {record.status === AlarmStatus.ENABLE ? '禁用' : '启用'}
            </Button>
          )}
          <Button style={{ paddingLeft: 0, paddingRight: 0 }} type="link">
            <Link to={`/device/rule/list/create/${record.id}?tabs=${tabsKey}`}>编辑</Link>
          </Button>
          <Button style={{ paddingLeft: 0, paddingRight: 0 }} type="link">
            <Link to={`/device/rule/list/detail/${record.id}?tabs=${tabsKey}`}>查看</Link>
          </Button>
        </Space>
      ),
    },
  ];

  const onDelete = (id: number) => {
    modalApi.confirm({
      title: '确定删除？',
      content: '删除后不可恢复，你还要继续吗',
      okText: '继续',
      onOk: () => {
        deleteRule(id).then(res => {
          update();
        });
      },
    });
  };

  const onChangeStatus = (id: number, status: AlarmStatus) => {
    if (status === AlarmStatus.DISABLE) {
      enableRule(id).then(res => {
        update();
      });
    } else {
      modalApi.confirm({
        title: '确定禁用？',
        content: '禁用后该报警规则不再生效，你还要继续吗',
        okText: '继续',
        onOk: () => {
          disableRule(id).then(res => {
            update();
          });
        },
      });
    }
  };

  return (
    <>
      <Table rowKey="id" sticky scroll={{ x: 1900 }} loading={loading} columns={columns} dataSource={list} />
      <Paging pagingInfo={pagingInfo} />
      {modalContextHolder}
    </>
  );
};

export default Rule;
