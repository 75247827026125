import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { EllipsisSpan, useUpdate, Wrapper } from '@maxtropy/components';
import { Button, Col, Form, Row, Space, Switch, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { apiRulesSetDevicesIdDelete } from '../../ytt/types/devices/id';
import { apiRulesSetIdGet, RulesSetIdGetResponse } from '../../ytt/types/rulesSet/id';
import { numberToString } from '../TemplateManage/utils';
import BindDeviceModal from './components/BindDeviceModal';
import DataValuesTemplateDetail from './components/DataValuesTemplateDetail';
import styles from './index.module.scss';
const routes = [{ name: '数据运营工具' }, { name: '数据模拟' }, { name: '规则集详情' }];

const Detail = () => {
  const [isLoading] = useState(false);
  const columns = [
    {
      title: '所属类目',
      dataIndex: 'deviceTypeName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '设备',
      dataIndex: 'deviceList',
      ellipsis: { showTitle: true },
      render: (v: any) => <EllipsisSpan value={v?.map((i: { name: any }) => i.name).join()} />,
    },
    {
      title: '属性',
      dataIndex: 'dataPropertyList',
      ellipsis: { showTitle: true },
      render: (v: any) => <EllipsisSpan value={v?.map((i: { name: any }) => i.name).join()} />,
    },
    {
      title: '数据值模板参数',
      dataIndex: 'deviceTags',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '延时上传参数',
      dataIndex: 'customerName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '是否统一计算',
      dataIndex: 'unifiedCompute',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v ? '是' : '否'} />,
    },
    {
      title: '操作',
      fixed: 'right' as const,
      width: 80,
      render: (v: any) => {
        return (
          <Space size={16}>
            <Button
              style={{ padding: 0 }}
              type="link"
              onClick={() => {
                setDeviceId(v.id);
                setShowModal(true);
                setOpType('edit');
              }}
            >
              编辑
            </Button>
            <Button style={{ padding: 0 }} type="link" onClick={() => delDevice(v.id)}>
              删除
            </Button>
          </Space>
        );
      },
    },
  ];
  const [showModal, setShowModal] = useState(false);
  const [detail, setDetail] = useState<RulesSetIdGetResponse>({});
  const [deviceId, setDeviceId] = useState<number>();

  const { id } = useParams<{ id?: string }>();
  const [updateState, updateFn] = useUpdate();

  const delDevice = (id: number) => {
    if (!numberToString(id)) return;
    apiRulesSetDevicesIdDelete({ id: numberToString(id)! }).then(() => {
      updateFn();
    });
  };

  useEffect(() => {
    if (!id) return;
    apiRulesSetIdGet({ id }).then(res => setDetail(res));
  }, [id, updateState]);
  const [opType, setOpType] = useState<string>('add');
  return (
    <>
      <Wrapper routes={routes} className={styles.outer}>
        <div className={styles.form_area}>
          <Form layout="vertical">
            <Row gutter={10}>
              <Col span={8}>
                <Form.Item label="规则名称">
                  <span>{detail?.name ?? '-'}</span>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="所属租户">
                  <span>{detail?.tenantName ?? '-'}</span>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="场景">
                  <span>{detail?.sceneName ?? '-'}</span>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="属性组">
                  <span>{detail?.dataPropertyGroupName ?? '-'}</span>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="数据点统一计算">
                  <Switch
                    checked={detail?.unifiedCompute}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="数据值模板">
                  <DataValuesTemplateDetail data={detail} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="两次上传间隔">
                  <span>{detail?.uploadTimeRuleName ?? '-'}</span>
                </Form.Item>
              </Col>
              {/* <Col span={8}>
                <Form.Item label="上传时间间隔参数">
                  <span>{detail?.dataPointInterval ?? '-'}</span>
                </Form.Item>
              </Col> */}
              <Col span={8}>
                <Form.Item label="延时上传时间">
                  <span>{detail?.dataTimeRuleName ?? '-'}</span>
                </Form.Item>
              </Col>
              {/* <Col span={8}>
                <Form.Item label="延时上传参数">
                  <span>{detail?.dataPointPeriod ?? '-'}</span>
                </Form.Item>
              </Col> */}
            </Row>
          </Form>
        </div>
        <div className={styles.gap}></div>
        <div className={styles.table_area}>
          <div className={styles.buttons}>
            <Button
              type="primary"
              onClick={() => {
                setDeviceId(undefined);
                setShowModal(true);
                setOpType('add');
              }}
            >
              绑定设备
            </Button>
          </div>
          <Table
            loading={isLoading}
            sticky
            dataSource={detail.deviceList ?? []}
            rowKey="id"
            scroll={{ y: 300 }}
            columns={columns}
            pagination={false}
          />
        </div>
      </Wrapper>
      {showModal && (
        <BindDeviceModal
          detail={detail}
          opType={opType}
          deviceId={deviceId}
          onCancel={() => setShowModal(false)}
          onConfirm={() => {
            setShowModal(false);
            updateFn();
          }}
        />
      )}
    </>
  );
};

export default Detail;
