import { alarmRuleFormItemsByChannel } from '@/pages/Alarm/Create/components/AlarmChannelFormItem/factory';
import { AlarmChannel } from '@/shared/types';
import React from 'react';

interface Props {
  channel: AlarmChannel;
}
const AlarmRuleFormItems: React.FC<Props> = ({ channel }) => {
  const FormItem = alarmRuleFormItemsByChannel[channel];
  return <>{FormItem && <FormItem />}</>;
};

export default AlarmRuleFormItems;
