import React, { useState } from 'react';
import { Button, Modal, Table } from 'antd';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { EllipsisSpan } from '@maxtropy/components';
import ProcessModal from './ProcessModal';
import styles from './index.module.scss';
import { GuideClassificationProcessDto } from './create';
import { MenuOutlined } from '@ant-design/icons';

const otherColumns = [
  {
    key: 'sort',
  },
  {
    width: '30%',
    title: '序号',
    dataIndex: 'processOrder',
    render: (value: number) => <EllipsisSpan value={value} />,
  },
  {
    width: '50%',
    title: '排放过程',
    dataIndex: 'name',
    render: (value: string) => <EllipsisSpan value={value} />,
  },
];

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, isDragging } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    ...(isDragging ? { position: 'relative', zIndex: 996 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, child => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined ref={setActivatorNodeRef} style={{ touchAction: 'none', cursor: 'move' }} {...listeners} />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

interface SortableTableProps {
  value?: GuideClassificationProcessDto[];
  onChange?: (value: GuideClassificationProcessDto[]) => void;
  allProcessIds: (number | undefined)[];
  setAllProcessIds: React.Dispatch<React.SetStateAction<(number | undefined)[]>>;
}

const SortableTable: React.FC<SortableTableProps> = props => {
  const [processModalOpen, setProcessModalOpen] = useState<boolean>(false);
  const [value, setValue] = useState<GuideClassificationProcessDto[]>(props.value || []);
  const [selectRows, setSelectRows] = useState<GuideClassificationProcessDto[]>([]);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
      },
    })
  );

  const onSelectRowsChange = (rows: GuideClassificationProcessDto[]) => {
    const newRows = rows?.map((item, index) => {
      return {
        ...item,
        processOrder: index + 1,
      };
    });
    setSelectRows(newRows as GuideClassificationProcessDto[]);
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = value.findIndex(i => i.id === active.id);
      const overIndex = value.findIndex(i => i.id === over?.id);
      const newValue = arrayMove(value, activeIndex, overIndex).map((item, index) => ({
        ...item,
        processOrder: index + 1,
      }));
      props.onChange?.(newValue);
      setValue(newValue);
    }
  };

  const onModalOk = () => {
    setValue(selectRows);
    const selectProcessIds = selectRows.map(item => item.id);
    props.setAllProcessIds(prev => {
      return [...prev, ...selectProcessIds];
    });
    props.onChange?.(selectRows);
    setProcessModalOpen(false);
  };

  const handleDelete = (id: number) => {
    const newValue = value.filter(i => i.id !== id);
    props.setAllProcessIds(prev => {
      return prev.filter(i => i !== id);
    });
    setValue(newValue);
    props.onChange?.(newValue);
  };

  const columns = [
    ...otherColumns,
    {
      width: '20%',
      title: '操作',
      render: (record: GuideClassificationProcessDto) => (
        <Button type="link" onClick={() => handleDelete(record.id as number)}>
          移除
        </Button>
      ),
    },
  ];

  return (
    <>
      <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={(selectRows ?? [])
            .filter((i): i is { id: number } => i !== undefined && i.id !== undefined)
            .map(i => ({ id: i.id }))}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: Row,
              },
            }}
            rowKey="id"
            columns={columns}
            dataSource={value}
            pagination={false}
          />

          <Button
            type="dashed"
            className={styles.addRowBtn}
            onClick={() => {
              setProcessModalOpen(true);
            }}
          >
            新增排放过程
          </Button>
        </SortableContext>
      </DndContext>

      <Modal
        width={800}
        title="选择排放过程"
        open={processModalOpen}
        onCancel={() => {
          setProcessModalOpen(false);
          setSelectRows([]);
        }}
        onOk={onModalOk}
        destroyOnClose
      >
        <ProcessModal value={value} setCurrentSelectRows={onSelectRowsChange} allProcessIds={props.allProcessIds} />
      </Modal>
    </>
  );
};

export default SortableTable;
