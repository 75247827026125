import React, { useMemo, useState } from 'react';
import { AttributeInfoProps, EdgeDeviceTemplatePoint } from './index';
import { ActionType, ESTUNPoint, IotProtocolType } from '../../types';
import { PointContext } from './contextTypes';
import MockingbirdPointDetail, { SearchParams } from './mockingbird/Detail';
import EstunPointDetail from './estun/Detail';

export interface EdgeDevicePointDetailProps {
  type: IotProtocolType;
  dataSource?: EdgeDeviceTemplatePoint[];
  loading?: boolean;
  info?: AttributeInfoProps;
  setSearchParams?: (searchParams: SearchParams) => void;
  hasDataColumns?: boolean;
}

const EdgeDevicePointDetail: React.FC<EdgeDevicePointDetailProps> = props => {
  const { type, dataSource, loading, info, setSearchParams, hasDataColumns } = props;

  const data = useMemo(() => dataSource?.filter(item => item.actionType !== ActionType.DELETE), [dataSource]);

  const renderContent = () => {
    if (type === IotProtocolType.ESTUN) {
      return <EstunPointDetail loading={loading} dataSource={data} type={type} hasDataColumns={hasDataColumns} />;
    } else if (type === IotProtocolType.MOCKINGBIRD) {
      return (
        <MockingbirdPointDetail loading={loading} setSearchParams={setSearchParams} hasDataColumns={hasDataColumns} />
      );
    }
  };

  return (
    <PointContext.Provider value={{ dataSource: data, info, editable: false }}>{renderContent()}</PointContext.Provider>
  );
};

export default EdgeDevicePointDetail;
