import { FormContent, Wrapper } from '@maxtropy/components';
import { Button, Col, Form, Modal, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from '../index.module.scss';
import SelectTemplate from './SelectTemplate';
import { useLocation, useParams } from 'react-router-dom';
import {
  getTenantDistributionTemplate,
  tenantBindingTemplate,
  TenantDistributionTemplateRes,
} from '../../../api/edgeTemplate';
import ShowInput from '@/shared/components/ShowInput';
import { useNavigate } from 'react-router-dom';

const routes = [{ name: 'Iot配置' }, { name: '数采配置' }, { name: '模板分配' }, { name: '数采模板分配' }];

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const TemplateDistribution: React.FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [info, setInfo] = useState<TenantDistributionTemplateRes>();
  const [modalApi, modalContextHolder] = Modal.useModal();

  useEffect(() => {
    if (id) {
      getTenantDistributionTemplate(id).then(data => {
        form.setFieldsValue(data);
        setInfo(data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const goList = () => {
    navigate(`/device/edge/distribution${search}`);
  };

  const onCacel = () => {
    modalApi.confirm({
      title: null,
      icon: null,
      content: <div>是否放弃所有未保存信息并返回列表？</div>,
      onOk: goList,
    });
  };

  const onFinish = (value: TenantDistributionTemplateRes) => {
    if (id) {
      const currentIds = (value.templateList ?? []).map(i => i.id);
      const templatesId = (info?.templateList ?? []).map(i => i.id);
      let params = {};
      if (templatesId.length === 0) {
        params = {
          addTemplateIdList: currentIds,
        };
      } else {
        params = {
          deleteTemplateIdList: templatesId.filter(i => !currentIds.includes(i)),
          addTemplateIdList: currentIds.filter(i => !templatesId.includes(i)),
        };
      }
      tenantBindingTemplate({ tenantMcid: id, ...params }).then(res => {
        if (res) goList();
      });
    }
  };

  return (
    <Wrapper routes={routes} className={styles.wrapper}>
      <Form form={form} layout="vertical" onFinish={onFinish} {...formLayout}>
        <FormContent title="租户信息">
          <Row className={styles.row}>
            <Col span={12} className={styles.col}>
              <Form.Item label="租户code" name="code">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={12} className={styles.col}>
              <Form.Item label="租户名称" name="name">
                <ShowInput />
              </Form.Item>
            </Col>
          </Row>
        </FormContent>
        <FormContent title="数采模板">
          <Form.Item name="templateList" wrapperCol={{ span: 24 }}>
            <SelectTemplate />
          </Form.Item>
        </FormContent>
        <Space className="sticky-footer">
          <Button type="primary" htmlType="submit">
            保存
          </Button>
          <Button className={styles.button} onClick={onCacel}>
            取消
          </Button>
        </Space>
      </Form>
      {modalContextHolder}
    </Wrapper>
  );
};

export default TemplateDistribution;
