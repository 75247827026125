import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  EdgeDeviceInfo,
  EdgeDeviceRealTimeDataProps,
  queryEdgeDeviceInfo,
  queryEdgeDeviceRealTimeData,
  queryEdgeDeviceRealTimeDataForMockingbird,
} from '../../../../api/edgeDevice';
import { IotProtocolType } from '@/shared/types';
import { EdgeDeviceTemplatePoint } from '@/shared/components/EdgeDevicePointInfo';
import EdgeDetail from './EdgeDetail';
import { chunk, flatten } from 'lodash-es';
import { getGatewayBaseInfoById } from '../../../../api/gateway';
import { Key } from '@maxtropy/components';

interface EdgeTemplateDetailProps {
  id: string;
  physicalModelId?: Key;
}

const EdgeTemplateDetail: FC<EdgeTemplateDetailProps> = ({ id, physicalModelId }) => {
  const [data, setData] = useState<EdgeDeviceInfo>();
  const [edgeGatewaySerialNumber, setEdgeGatewaySerialNumber] = useState<string | undefined>(undefined);
  const [pointRealTimeMap, setPointRealTimeMap] = useState<Map<number, EdgeDeviceRealTimeDataProps>>();

  const dataSource = useMemo<EdgeDeviceTemplatePoint[]>(() => {
    let result: EdgeDeviceTemplatePoint[] = [];
    if (data?.points) {
      result = data?.points ?? [];
    }
    if (pointRealTimeMap?.size) {
      result = result.map(item => ({
        ...item,
        edgeTime: pointRealTimeMap?.get(item.id!)?.ts,
        edgeData: pointRealTimeMap.get(item.id!)?.data,
      }));
    }
    return result;
  }, [data?.points, pointRealTimeMap]);

  useEffect(() => {
    queryEdgeDeviceInfo(id).then(setData);
  }, [id]);

  useEffect(() => {
    // 拿到设备关联网关
    if (data?.edgeGatewayId) {
      getGatewayBaseInfoById(data?.edgeGatewayId).then(res => {
        setEdgeGatewaySerialNumber(res?.serialNumber);
      });
    }
  }, [data?.edgeGatewayId]);

  useEffect(() => {
    if (data && id) {
      const pointIds = (data.points ?? []).map(({ id }) => String(id));
      if (pointIds.length) {
        const query =
          data.iotProtocol === IotProtocolType.MOCKINGBIRD
            ? queryEdgeDeviceRealTimeDataForMockingbird
            : queryEdgeDeviceRealTimeData;
        const pointIdChunks = chunk(pointIds, 10);
        Promise.all(pointIdChunks.map(chunk => query({ pointIds: chunk })))
          .then(data => {
            setPointRealTimeMap(new Map((data ? flatten(data) : []).map(item => [item.pointId, item])));
          })
          .catch(error => {
            console.error(error);
          });
      }
    }
  }, [id, data]);

  return (
    <>
      {data && data.edgeDeviceTemplateName && (
        <>
          <EdgeDetail
            data={data}
            dataSource={dataSource}
            hasDataColumns
            edgeGatewaySerialNumber={edgeGatewaySerialNumber}
            physicalModelId={physicalModelId}
          />
        </>
      )}
    </>
  );
};

export default EdgeTemplateDetail;
