import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Button,
  Checkbox,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  Popconfirm,
  Space,
  Modal,
  Cascader,
  Tooltip,
} from 'antd';
import { EllipsisSpan, Paging, Table, usePaging, Wrapper, useAsync } from '@maxtropy/components';
import { useQuery } from '../../utils/utils';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import Filter from '@/shared/components/Filter';
import CascadingMultipleSelector from '@/shared/components/CascadingMultipleSelector';
import { useNavigate, Link } from 'react-router-dom';
import qs from 'qs';
import { FilterParams } from '../Attribute';

import { getDeviceTypeData } from '../../api/attribute';
import { DownOutlined } from '@ant-design/icons';
import { changeEdgeTemplateStatus, EdgeTemplatePageRequest, getEdgeTemplatePage } from '../../api/edgeTemplate';
import { EdgeDeviceTemplateStatus, EdgeDeviceTemplateStatusDisplay } from '@/shared/types';
import {
  formatOptionData,
  getAllChild,
  getChildNodesByParentIds,
} from '@/shared/components/CascadingMultipleSelector/utils';
import { getIotProtocols } from '@/shared/api/options';
import { DefaultOptionType } from 'antd/es/cascader';
import {
  apiV2EdgeTemplatePagePost,
  V2EdgeTemplatePagePostRequest,
  V2EdgeTemplatePagePostResponse,
} from '@maxtropy/tody-mgmt-apis-v2';
import { ColumnsType } from 'antd/es/table';
import DeviceModal from './DeviceModal';

const routes = [{ name: 'Iot配置' }, { name: '数采配置' }, { name: '数采模板' }];

type SearchParams = Omit<V2EdgeTemplatePagePostRequest, 'page' | 'size'>;

type EdgeDeviceTemplate = Exclude<V2EdgeTemplatePagePostResponse['list'], undefined>[number];

const EdgeDeviceTemplateList: React.FC = () => {
  const { data: iotProtocols } = useQuery(useCallback(getIotProtocols, []));

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const urlSearchParams = new URLSearchParams(window.location.search);
  const name = urlSearchParams.get('name') || undefined;
  const displayAbolished = urlSearchParams.get('displayAbolished') === 'true';
  const deviceType = urlSearchParams
    .get('deviceType')
    ?.split(',')
    .map(s => Number(s));
  const deviceTypeDisplay = urlSearchParams
    .get('deviceTypeDisplay')
    ?.split(',')
    .map(s => s.split('|').map(Number));
  const [searchParams, setSearchParams] = useState<SearchParams>({
    name,
    deviceType,
    displayAbolished,
  });
  const [open, setOpen] = useState<boolean>();
  const [selectedId, setSelectedId] = useState<number>();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const deviceTypeData = useAsync(getDeviceTypeData);
  const [modalApi, modalContextHolder] = Modal.useModal();

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        apiV2EdgeTemplatePagePost({
          ...searchParams,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          setTotalCount(res.total ?? 0);
          return res.list ?? [];
        }),
      [pageOffset, pageSize, searchParams, setTotalCount]
    )
  );

  const columns = useMemo(() => {
    return [
      {
        title: '模板编号',
        dataIndex: 'serialNumber',
        ellipsis: { showTitle: true },
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '模板名称',
        dataIndex: 'name',
        ellipsis: { showTitle: true },
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '所属类目',
        dataIndex: 'deviceTypes',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '物模型型号',
        dataIndex: 'modelNo',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={v} />,
      },
      {
        title: '状态',
        dataIndex: 'status',
        ellipsis: { showTitle: true },
        render: (v: EdgeDeviceTemplateStatus) => (
          <Badge
            status={v === EdgeDeviceTemplateStatus.USED ? 'success' : 'error'}
            text={EdgeDeviceTemplateStatusDisplay[v]}
          />
        ),
      },
      {
        title: '已使用设备数量',
        dataIndex: 'usedByDevice',
        ellipsis: { showTitle: true },
        render: (v: number, record) => {
          return v ? (
            <Tooltip placement={'topLeft'} title={v}>
              <Button
                type="link"
                onClick={() => {
                  setOpen(true);
                  setSelectedId(record.id!);
                }}
              >
                {v}
              </Button>
            </Tooltip>
          ) : (
            '--'
          );
        },
      },
      {
        title: '最新版本号',
        dataIndex: 'latestVersion',
        ellipsis: { showTitle: true },
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '物联层协议',
        dataIndex: 'iotProtocol',
        ellipsis: { showTitle: true },
        render: (v: number) => <EllipsisSpan value={iotProtocols?.find(item => item.id === v)?.name} />,
      },
      {
        title: '最后操作时间',
        width: 180,
        dataIndex: 'updateTime',
        ellipsis: { showTitle: true },
        render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
      },
      {
        title: '最后操作人',
        dataIndex: 'updateByUsername',
        ellipsis: { showTitle: true },
        render: (v: string) => <EllipsisSpan value={v} />,
      },
    ] as ColumnsType<EdgeDeviceTemplate>;
  }, [iotProtocols]);

  const render = (record: EdgeDeviceTemplate) => (
    <Space size={16} className="action-buttons">
      {record.status !== EdgeDeviceTemplateStatus.ABOLISHED && (
        <>
          {!record.usedByDevice ? (
            <Popconfirm
              okText="继续"
              placement="topRight"
              title={
                <>
                  <div>确定作废？</div>
                  <div style={{ color: '#f5222d' }}>
                    作废后不可恢复
                    <span style={{ color: 'rgba(0, 0, 0, 0.3)' }}>，你还要继续吗？</span>
                  </div>
                </>
              }
              onConfirm={() => changeStatus(record.id!, EdgeDeviceTemplateStatus.ABOLISHED)}
            >
              <Button type="link">作废</Button>
            </Popconfirm>
          ) : (
            <Button
              type="link"
              onClick={() =>
                modalApi.confirm({
                  title: '数采模版已被设备使用，不可以作废',
                  okText: '继续',
                  cancelText: '取消',
                })
              }
            >
              作废
            </Button>
          )}
          <Button
            type="link"
            onClick={() =>
              changeStatus(
                record.id!,
                record.status === EdgeDeviceTemplateStatus.USED
                  ? EdgeDeviceTemplateStatus.UNUSED
                  : EdgeDeviceTemplateStatus.USED
              )
            }
          >
            {record.status === EdgeDeviceTemplateStatus.USED ? '禁用' : '启用'}
          </Button>
          <Dropdown
            overlay={
              <Menu
                className={styles.dropdownMenu}
                items={[
                  {
                    key: '1',
                    label: (
                      <Link to={`/device/edge/template/${record.id}/edit${window.location.search}`}>编辑基础信息</Link>
                    ),
                  },
                  {
                    key: '2',
                    label: (
                      <Link
                        to={`/device/edge/template/${record.id}/point${window.location.search}${
                          window.location.search ? '&' : '?'
                        }from=edgeTemplate`}
                      >
                        {record.latestVersion === 0 ? '添加' : '编辑'}采集信息
                      </Link>
                    ),
                  },
                ]}
              />
            }
          >
            <Button type="link" onClick={e => e.preventDefault()}>
              编辑
              <DownOutlined />
            </Button>
          </Dropdown>
        </>
      )}
      <Dropdown
        overlay={
          <Menu
            className={styles.dropdownMenu}
            items={[
              {
                key: '1',
                label: (
                  <Link
                    to={
                      record.latestVersionId
                        ? `/device/edge/template/${record.id}?versionId=${record.latestVersionId}`
                        : `/device/edge/template/${record.id}`
                    }
                  >
                    查看模板
                  </Link>
                ),
              },
              {
                key: '2',
                label: (
                  <Link to={`/device/edge/template/${record.id}/version${window.location.search}?from=edgeTemplate`}>
                    查看历史版本
                  </Link>
                ),
              },
            ]}
          />
        }
      >
        <Button type="link" onClick={e => e.preventDefault()}>
          查看
          <DownOutlined />
        </Button>
      </Dropdown>
    </Space>
  );

  const changeStatus = (id: number, status: EdgeDeviceTemplateStatus) => {
    changeEdgeTemplateStatus(id, status).then(() => {
      setSearchParams({ ...searchParams });
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      name,
      deviceType: deviceTypeDisplay,
      displayAbolished,
    });
  }, [deviceTypeDisplay, form, name, displayAbolished]);

  const onFinish = (val: any) => {
    // const deviceType = getAllChild(val.deviceType, formatOptionData(deviceTypeData));
    const deviceType = getChildNodesByParentIds(val.deviceType, deviceTypeData?.tree);
    const params = {
      name: val.name,
      deviceCode: val.deviceCode,
      deviceType: deviceType,
      displayAbolished: val.displayAbolished || false,
    };
    setSearchParams(params);
    setPageOffset(1);
    const paramsObj = {
      name: val.name,
      displayAbolished: String(val.displayAbolished || false),
      deviceType: deviceType?.join(','),
      deviceTypeDisplay: val.deviceType?.map((item: any) => item.join('|')).join(','),
    };
    const paramsMap = new Map<string, string>();
    Object.entries(paramsObj).forEach(([key, value]) => {
      value && paramsMap.set(key, value);
    });
    navigate(`?${qs.stringify(Object.fromEntries(paramsMap))}`);
  };

  const onReset = () => {
    const params: SearchParams = {
      name: undefined,
      deviceType: undefined,
      displayAbolished: false,
    };
    setSearchParams(params);
    setPageOffset(1);
    navigate(`?`);
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const filters = (
    <Filter
      form={form}
      onFinish={val => onFinish(val as FilterParams)}
      onReset={onReset}
      collapseItems={
        <>
          <Col span={8}>
            <Form.Item name="deviceCode" label="已使用设备编号">
              <Input placeholder={'请输入'} />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="displayAbolished" valuePropName="checked">
              <Checkbox>显示已作废模板</Checkbox>
            </Form.Item>
          </Col>
        </>
      }
    >
      <Col span={8}>
        <Form.Item name="name" label="编号/名称">
          <Input placeholder={'请输入编号或名称查询'} />
        </Form.Item>
      </Col>
      <Col span={8}>
        <Form.Item name="deviceType" label="所属类目">
          <Cascader
            options={formatOptionData(deviceTypeData)}
            allowClear={false}
            fieldNames={{ children: 'child' }}
            multiple
            maxTagCount="responsive"
            showSearch={{ filter }}
            placeholder={'请选择所属类目'}
          />
        </Form.Item>
      </Col>
    </Filter>
  );

  return (
    <Wrapper routes={routes} filters={filters}>
      <Link to={`/device/edge/template/new`}>
        <Button type="primary" style={{ marginBottom: 15 }}>
          +新建数采模板
        </Button>
      </Link>
      <Table
        sticky
        rowKey="id"
        dataSource={data}
        loading={isLoading}
        scroll={{ x: 1500 }}
        columns={[...columns, { title: '操作', fixed: 'right', width: 240, render }]}
      />
      <Paging pagingInfo={pagingInfo} />
      {modalContextHolder}
      {open && <DeviceModal id={selectedId!} onCancel={() => setOpen(false)} />}
    </Wrapper>
  );
};

export default EdgeDeviceTemplateList;
