import React, { ReactNode } from 'react';
import { Col, Row } from 'antd';

interface HeaderProps {
  slot?: React.ReactElement;
  children?: ReactNode;
}

const Header: React.FC<HeaderProps> = props => {
  return (
    <Row style={{ marginBottom: props.children || props.slot ? 20 : 0 }}>
      {props.children}
      <Col flex={1} style={{ display: 'flex', alignItems: 'center' }}>
        {props.slot}
      </Col>
    </Row>
  );
};

export default Header;
