import { FC, useEffect } from 'react';
import { useRouteError } from 'react-router-dom';

const RootRouteBoundary: FC = () => {
  const error = useRouteError();

  useEffect(() => {
    console.error(error)
  }, [error])

  return (
    <></>
  )
}

export default RootRouteBoundary;
