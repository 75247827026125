import { CheckOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, InputNumber, Row, Select, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataValueContext } from '..';
import {
  DataPointInterval,
  dataPointIntervalDisplay,
  DataPointPeriod,
  dataPointPeriodDisplay,
  PropertyType,
} from '../../../api/type';
import { ApiDataRulePostRequest } from '../../../ytt/types/api/dataRule';
import UploadModal from './UploadModal';
export interface DataPoint {
  dataPointPeriod?: number;
  dataPointInterval?: number;
}
const InstanceFormItems = () => {
  const [uploadModalShow, setUploadModalShow] = useState(false);
  const navigate = useNavigate();
  const [fileKey, setFileKey] = useState<string>();
  const { nextStep, prevStep, saveLoading, data: details, saveForm } = useContext(DataValueContext);
  const [form] = useForm<ApiDataRulePostRequest>();
  // 存储数据点周期、数据点间隔
  const [currentData, setCurrentData] = useState<DataPoint>();
  const nextBtn = (save = true) => {
    form.validateFields().then(values => {
      saveForm?.({ ...values, save }).then(id => {
        if (id) {
          // 点击保存草稿后跳转到列表页
          if (!save) {
            navigate(-1);
            return;
          }
          if (currentPropertyType === PropertyType.INSTANCE) {
            navigate(-1);
            return;
          }
          nextStep?.();
        }
      });
    });
  };

  // 当前属性类型
  const currentPropertyType = useMemo(() => {
    return details?.propertyType;
  }, [details]);
  const uploadBtn = () => {
    // 校验数据点周期和数据点间隔
    form.validateFields(['dataPointPeriod', 'dataPointInterval']).then(valid => {
      setCurrentData(valid);
      setUploadModalShow(true);
    });
  };
  useEffect(() => {
    form.setFieldsValue({
      dataPointPeriod: details?.dataPointPeriod ?? DataPointPeriod.DAY,
      dataPointInterval: details?.dataPointInterval ?? DataPointInterval.MINUTE_15,
      excelKey: details?.uploadFileKey,
      yMagnificationName: details?.yMagnificationName,
      yMagnificationValue: details?.yMagnificationValue,
    });
    if (details?.uploadFileKey) {
      setFileKey(details?.uploadFileKey);
    }
  }, [details]);
  return (
    <>
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          dataPointPeriod: DataPointPeriod.DAY,
          dataPointInterval: DataPointInterval.MINUTE_15,
        }}
      >
        <Form.Item label="数据点周期" name="dataPointPeriod" rules={[{ required: true }]}>
          <Select
            optionFilterProp="label"
            options={Object.entries(dataPointPeriodDisplay).map(([k, v]) => ({ label: v, value: +k }))}
          />
        </Form.Item>
        <Form.Item noStyle dependencies={['dataPointPeriod']}>
          {({ getFieldValue }) => {
            let disabledItems: DataPointInterval[] = [];
            let temp = getFieldValue('dataPointPeriod');
            if (temp === DataPointPeriod.DAY) {
              disabledItems = [DataPointInterval.MINUTE_6H, DataPointInterval.MINUTE_12H, DataPointInterval.MINUTE_24H];
            }
            if (temp === DataPointPeriod.YEAR) {
              disabledItems = [DataPointInterval.MINUTE_1];
            }
            return (
              <>
                <Form.Item label="数据点间隔" name="dataPointInterval" rules={[{ required: true }]}>
                  <Select
                    optionFilterProp="label"
                    options={Object.entries(dataPointIntervalDisplay).map(([k, v]) => ({
                      label: v,
                      value: +k,
                      disabled: disabledItems.includes(+k),
                    }))}
                  />
                </Form.Item>
              </>
            );
          }}
        </Form.Item>
        <Form.Item label="数据点数值" name="excelKey" rules={[{ required: true }]}>
          <div>
            {fileKey && (
              <Space size={5}>
                <CheckOutlined style={{ color: 'var(--primary-color)' }} />
                数据点已上传&nbsp;&nbsp;
              </Space>
            )}
            <Button icon={<UploadOutlined />} onClick={uploadBtn}>
              {fileKey ? '重新上传' : '点击上传'}
            </Button>
          </div>
        </Form.Item>
        <Row gutter={15}>
          <Col span={12}>
            <Form.Item label="Y轴倍率" name="yMagnificationName" rules={[{ required: true }]}>
              <Input></Input>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="默认值" name="yMagnificationValue" rules={[{ required: true }]}>
              <InputNumber></InputNumber>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Space>
            {/* 选择了累积值 */}
            {currentPropertyType === PropertyType.ACCUMULATE && (
              <>
                <Button onClick={prevStep}>上一步</Button>
                <Button type="primary" onClick={() => nextBtn()} loading={saveLoading}>
                  下一步
                </Button>
              </>
            )}
            {/* 选择了瞬时值 */}
            {currentPropertyType === PropertyType.INSTANCE && (
              <>
                <Button type="primary" onClick={() => nextBtn()} loading={saveLoading}>
                  发布
                </Button>
                <Button onClick={prevStep}>上一步</Button>
                <Button onClick={() => nextBtn(false)} loading={saveLoading}>
                  保存草稿
                </Button>
              </>
            )}
          </Space>
        </Form.Item>
      </Form>
      {uploadModalShow && (
        <UploadModal
          data={currentData}
          id={details?.id}
          onCancel={() => setUploadModalShow(false)}
          onConfirm={(fileKey?: string) => {
            setFileKey(fileKey);
            form.setFieldsValue({
              excelKey: fileKey,
            });
            setUploadModalShow(false);
          }}
        />
      )}
    </>
  );
};
export default InstanceFormItems;
