import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Form, Row, Space } from 'antd';
import { Wrapper, FormContent, getRolePermissions, useAsync, Key } from '@maxtropy/components';

import { getPermissionTree, FunctionPermissionVo } from '../../../api/cc-function';
import {
  getCorpUserFunctionPermission,
  upsertCropUserFunctionPermissions,
  UpsertCropUserFunctionPermissionRequest,
  CropUserFunctionProps,
} from '../../../api/cc-corp';

import styles from '../index.module.scss';
import { useNavigate, useParams } from 'react-router-dom';

import { RoleTree, TreeDataNode as DataNode } from '@maxtropy/components';

const { useForm } = Form;

const tailLayout = {
  wrapperCol: { offset: 2, span: 16 },
};

export function dig(data: FunctionPermissionVo[], level = 0): DataNode[] {
  return data.map(item => {
    const hasChildren = !(Array.isArray(item.children) && item.children.length > 0);
    const isLeaf = level === 2 || level === 3 ? true : hasChildren;
    const node = {
      children: [] as DataNode[],
      isLeaf: isLeaf,
      key: item.code,
      title: item.name,
      customized: false,
    };
    if (!hasChildren) {
      node.children = dig(item.children!, level + 1);
    }
    return node;
  });
}

const routes = [{ name: '系统设置' }, { name: '平台组织权限和账户' }, { name: '员工管理' }, { name: '功能权限分配' }];

const FunctionPermissionAssignment: FC<{ isEdit?: boolean; isCreate?: boolean }> = ({
  isEdit = true,
  isCreate = false,
}) => {
  const [form] = useForm();
  const [autoSyncedKeys, setAutoSyncedKeys] = useState<Key[]>([]);
  const { corpUserId } = useParams<{ corpUserId: string }>();
  const navigate = useNavigate();
  const originalData = useRef<{ originalCheckedKeys: Key[]; originalAutoSyncedKeys: Key[] }>();

  const tree = useAsync(getPermissionTree, []);

  const treeData = useMemo(() => {
    return dig(tree);
  }, [tree]);

  const getCorpUserPermissionCallback = useCallback(() => {
    return getCorpUserFunctionPermission(Number(corpUserId));
  }, [corpUserId]);
  const functionPermissions = useAsync(getCorpUserPermissionCallback);

  useEffect(() => {
    const checkedKeys = functionPermissions?.permissions ?? [];
    const autoSyncedKeys = functionPermissions?.syncs ?? [];
    setAutoSyncedKeys(autoSyncedKeys);
    form.setFieldsValue({ requests: checkedKeys });
    originalData.current = { originalCheckedKeys: checkedKeys, originalAutoSyncedKeys: autoSyncedKeys };
  }, [functionPermissions, treeData, form]);

  const onFinish = (value: { requests: string[] }) => {
    const { permissions, syncs } = getRolePermissions({
      treeData,
      checkedKeys: value.requests,
      autoSyncedKeys,
      ...originalData.current!,
    });
    const requests: CropUserFunctionProps = {
      addPermissions: permissions[0],
      deletePermission: permissions[1],
      addSyncs: syncs[0],
      deleteSyncs: syncs[1],
    };
    const data: UpsertCropUserFunctionPermissionRequest = {
      requests,
      corpUserId: Number(corpUserId),
    };
    upsertCropUserFunctionPermissions(data).then(() => {
      navigate(`/platform/account/employee/data/${isCreate ? 'create' : 'edit'}/${corpUserId}`);
    });
  };

  return (
    <Wrapper routes={routes}>
      <div className={styles.container}>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <FormContent title="运管用户功能权限管理">
            <div className={styles.functionFormLabelContainer}>
              <Form.Item
                name="requests"
                label="功能权限管理"
                validateFirst={true}
                valuePropName="checkedKeys"
                trigger="onCheck"
              >
                {/* <FormFunctionPermission content={content} disabled={!isEdit}/> */}
                <RoleTree
                  treeData={treeData}
                  autoSyncedKeys={autoSyncedKeys}
                  onAutoSync={setAutoSyncedKeys}
                  autoSyncable
                  disabled={!isEdit}
                />
              </Form.Item>
            </div>
          </FormContent>
          <Row className={styles.buttonWrapper}>
            <Col span={7}>
              <Form.Item {...tailLayout}>
                <Space>
                  {isEdit ? (
                    <>
                      <Button
                        onClick={e => {
                          navigate(`/platform/account/employee/edit/${corpUserId}`);
                        }}
                      >
                        上一步
                      </Button>
                      <Button htmlType="submit" type="primary">
                        下一步
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => {
                          navigate(`/platform/account/employee/show/${corpUserId}`);
                        }}
                      >
                        上一步
                      </Button>
                      <Button
                        type="primary"
                        onClick={e => {
                          e.preventDefault();
                          navigate(`/platform/account/employee`);
                        }}
                      >
                        关闭
                      </Button>
                      <Button
                        onClick={e => {
                          e.preventDefault();
                          navigate(`/platform/account/employee/data/show/${corpUserId}`);
                        }}
                      >
                        下一步
                      </Button>
                    </>
                  )}
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Wrapper>
  );
};

export default FunctionPermissionAssignment;
