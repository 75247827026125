import React, { useEffect } from 'react';
import {
  MiniAppPartOneTwoDetailPostResponse,
  MiniAppPreviewPostResponse,
  apiMiniAppPreviewPost,
} from '@maxtropy/cc-mgmt-apis';
import { QRCode } from 'antd';
import { useRequest } from 'ahooks';
import { getRealUrl } from '@maxtropy/components';
import styles from './index.module.scss';

interface Props {
  listId?: number;
  partOneTwoData?: MiniAppPartOneTwoDetailPostResponse;
  isNext: boolean;
}
const PreRelease: React.FC<Props> = ({ listId, partOneTwoData, isNext }) => {
  const [previewRes, setPreviewRes] = React.useState<MiniAppPreviewPostResponse>();
  const { data: QRcode, cancel } = useRequest(
    () => {
      return apiMiniAppPreviewPost({ id: String(listId) });
    },
    {
      pollingInterval: 1000,
      pollingErrorRetryCount: 20,
      onSuccess: data => {
        if (data && data.key) {
          cancel();
        }
      },
    }
  );

  useEffect(() => {
    if (isNext) {
      apiMiniAppPreviewPost({ id: String(listId) }).then(res => {
        if (res && res.key) {
          setPreviewRes(res);
          cancel();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNext, listId]);

  return (
    <>
      <div className={styles.preRelease}>
        {(QRcode && QRcode.key) || (previewRes && previewRes.key) ? (
          <img className={styles.QrCode} alt="预览二维码" src={getRealUrl(QRcode?.key || previewRes?.key)} />
        ) : (
          <QRCode value={'-'} size={320} status="loading" style={{ marginTop: '128px' }} />
        )}
        <span>微信扫描二维码进行预览</span>
      </div>
    </>
  );
};

export default PreRelease;
