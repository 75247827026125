import { DataNode } from 'antd/es/tree';

type AttributeDataNode = DataNode & { parentKey?: string };

function listToTree(list: AttributeDataNode[]) {
  let map: Record<string, number> = {};
  let node: AttributeDataNode;
  let roots: AttributeDataNode[] = [];
  let i: number;

  for (i = 0; i < list.length; i += 1) {
    map[String(list[i].key)] = i;
    list[i].children = [];
  }
  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parentKey) {
      list[map[node.parentKey]!]!.children!.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}

export default listToTree;
