import { Button, useUpdate } from '@maxtropy/components';
import { Col, Input, List, Modal, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { ColorType, MenuLabelListRes, createMenuLabel, getMenuLabelList } from '../../../api/cc-MaxtropyAbilityList';
import { isNil } from 'lodash-es';

interface Props {
  open: boolean;
  setOpen: (v: boolean) => void;
  listUpdateFn: () => void;
}

const AddLabelModal: React.FC<Props> = ({ open, setOpen, listUpdateFn }) => {
  const [data, setData] = useState<MenuLabelListRes[]>([]);
  const [inputting, setInputting] = useState<boolean>(false);
  const [update, updateFn] = useUpdate();

  useEffect(() => {
    // 获取售前套餐标签
    getMenuLabelList().then(setData);
  }, [update]);

  const addLabel = (name: string) => {
    if (!isNil(name) && name !== '') {
      createMenuLabel({ name, colorType: data.length % 20 }).then(() => {
        updateFn();
        setInputting(false);
      });
    }
  };

  return (
    <Modal
      title="套餐标签管理"
      open={open}
      width={620}
      onCancel={() => {
        setOpen(false);
        listUpdateFn();
      }}
      footer={null}
      bodyStyle={{
        marginBottom: 50,
      }}
    >
      {data.length > 0 && (
        <List
          style={{
            paddingTop: 24,
          }}
          split={false}
          dataSource={data}
          grid={{ column: 2 }}
          renderItem={(item, index) => (
            <List.Item>
              {
                <Tag color={ColorType[item.colorType]}>
                  <span style={{ fontSize: 12, color: 'rgba(0,0,0,0.65)', fontWeight: 400 }}>{item.name}</span>
                </Tag>
              }
            </List.Item>
          )}
        />
      )}
      {inputting ? (
        <Input
          maxLength={20}
          onBlur={e => {
            addLabel(e.target.value);
          }}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              addLabel(e.target.value);
            }
          }}
          style={{
            marginTop: 14,
            width: '98%',
          }}
          placeholder="请输入"
        />
      ) : (
        <Col span={6}>
          <Button
            type="primary"
            onClick={() => {
              setInputting(true);
            }}
            block
          >
            新增套餐标签
          </Button>
        </Col>
      )}
    </Modal>
  );
};

export default AddLabelModal;
