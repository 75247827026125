import { Wrapper, useAsync } from '@maxtropy/components';
import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { Typography } from 'antd';

const getReadme: () => Promise<string> = () => window.fetch('/README.md').then(res => res.text());

const routes = [{ name: '数据运营工具' }, { name: '数据模拟' }, { name: '使用说明' }];

const Index: FC = () => {
  const ReadmeMd = useAsync(getReadme, '');

  return (
    <Wrapper routes={routes}>
      <ReactMarkdown
        children={ReadmeMd}
        // prettier-ignore
        components={{// NOSONAR
          img: ({ node, ...props }) => <img alt={''} style={{ width: '100%' }} {...props} />,
          h1: ({ node, ...props }) => <Typography.Title children={props.children} />,
        }}
      />
    </Wrapper>
  );
};

export default Index;
