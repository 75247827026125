import { Col, message, Progress, Row, Upload } from 'antd';
import { LockOutlined, PaperClipOutlined, UnlockOutlined, UploadOutlined } from '@ant-design/icons';
import React from 'react';
import { UploadFile } from 'antd/es/upload/interface';
import { Button } from '@maxtropy/components';

interface LockProps {
  locked: boolean;
  serial: string;
  downloading?: boolean;
  fileList: UploadFile[];
  setFileList: (fileList: UploadFile[]) => void;
  style?: React.CSSProperties;
  onLock?: () => void;
  onUnLock?: () => void;
  onDownload?: () => void;
  extra?: React.ReactNode;
}

const Lock: React.FC<LockProps> = props => {
  const { locked, serial = '', downloading, style, onLock, onUnLock, onDownload, fileList, setFileList, extra } = props;
  const [messageApi, messageContextHolder] = message.useMessage();

  const uploadProps = {
    accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    onRemove: (file: UploadFile) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file: UploadFile) => {
      const isLt50M = (file?.size ?? 0) / 1024 / 1024 < 2;
      if (!isLt50M) {
        messageApi.error('文件必须小于50MB!');
        return false;
      }
      setFileList([file]);
      return false;
    },
    fileList,
  };

  return (
    <div style={style}>
      <p style={{ color: '#F5222D', lineHeight: '20px' }}>
        信息完成后，点击锁定按钮，生成模版及模版编号，若想修改信息，需要解除锁定才能修改信息。再次锁定后，无论信息是否发生变化，都会生成一个新的模版及新的模版编号。需要重新下载，才可以上传。{' '}
      </p>
      {!locked ? (
        <Button type="primary" icon={<LockOutlined />} onClick={onLock}>
          锁定
        </Button>
      ) : (
        <>
          <Row>
            <Col style={{ width: 280, borderRight: '1px solid rgba(0, 0, 0, 0.06)' }}>
              <Button type="primary" icon={<UnlockOutlined />} onClick={onUnLock}>
                解除锁定
              </Button>
              {serial ? (
                <Row style={{ margin: '20px 0' }}>
                  <Col span={24}>
                    <PaperClipOutlined />
                    <Button loading={downloading} style={{ paddingLeft: 4 }} type="link" onClick={onDownload}>
                      点击下载模版
                    </Button>
                  </Col>
                  <Col span={24} style={{ padding: '8px 18px' }}>
                    模版编号：{serial}
                  </Col>
                </Row>
              ) : (
                <Row style={{ margin: '20px 0' }}>
                  <Col span={24}>
                    <PaperClipOutlined />
                    模版生成中，请稍后
                  </Col>
                  <Col span={24}>
                    <Progress
                      style={{ width: 230 }}
                      percent={30}
                      showInfo={false}
                      strokeColor="#1890FF"
                      trailColor="#D9D9D9"
                      strokeLinecap="square"
                    />
                  </Col>
                </Row>
              )}
            </Col>
            <Col style={{ marginLeft: 20 }}>
              <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />}>上传模版</Button>
              </Upload>
              {extra}
            </Col>
          </Row>
        </>
      )}
      {messageContextHolder}
    </div>
  );
};

export default Lock;
