import { Paging, Table, usePaging, useUpdate } from '@maxtropy/components';
import { Button, Divider, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { selectTemplateColumns } from './utils';
import { ParamsProps } from './index';
import { getTemplateList, Status, TemplateListProps } from '../../api/template';
import { PermissionsType } from '../../common/permissionsConst';

interface TemplateTableProps {
  searchParams?: ParamsProps;
  tabsKey?: string;
  permission?: string[];
}

const TemplateList: React.FC<TemplateTableProps> = ({ searchParams, tabsKey, permission }) => {
  const { search } = useLocation();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [updateState, update] = useUpdate();
  const [loading, setLoading] = useState<boolean>(false);
  const [list, setList] = useState<TemplateListProps[]>([]);

  const getList = () => {
    setLoading(true);
    getTemplateList({ ...searchParams, page: pageOffset, size: pageSize }).then(res => {
      if (res) setTotalCount(res.total);
      setList(res.list);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (updateState) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState]);

  useEffect(() => {
    setPageOffset(1);
    if (pageOffset === 1) {
      update();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, setPageOffset]);

  useEffect(() => {
    update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, pageSize]);

  const columns = [
    ...selectTemplateColumns,
    {
      title: '操作',
      fixed: 'right' as 'right',
      width: 200,
      render: (v: TemplateListProps) => (
        <Space size={16}>
          {(permission ?? []).includes(PermissionsType.P_TENANTASSIGNATRIBUTETEMPLATE) && (
            <Button style={{ paddingLeft: 0, paddingRight: 0 }} type="link" disabled={v.status !== Status.ENABLE}>
              <Link to={`/device/info/distribution/tenant/create/${v.id}${search}`}>租户分配</Link>
            </Button>
          )}
          <Button style={{ paddingLeft: 0, paddingRight: 0 }} type="link">
            <Link to={`/device/info/distribution/tenant/detail/${v.id}${search}`}>查看</Link>
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table sticky scroll={{ x: 1900 }} loading={loading} columns={columns} dataSource={list} />
      <Paging pagingInfo={pagingInfo} />
    </>
  );
};

export default TemplateList;
