import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { MiniAppMenuGetListGetResponse } from '@maxtropy/cc-mgmt-apis';
import { Button, Modal } from 'antd';
import React from 'react';

import styles from '../index.module.scss';
import {
  V2MiniAppToolGetListPostResponse,
  apiV2MiniAppToolEditPost,
  apiV2MiniAppToolGetListPost,
} from '@maxtropy/cc-mgmt-apis-v2';

interface Props {
  currentHomePageInfo: V2MiniAppToolGetListPostResponse['list'] | undefined;
  currentMenuId?: number;
  currentMenuIndex: number;
  customMenus: MiniAppMenuGetListGetResponse;
  setToolAndMenuCardOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentHomePageInfo: React.Dispatch<React.SetStateAction<V2MiniAppToolGetListPostResponse['list'] | undefined>>;
}
const CustomPage: React.FC<Props> = ({
  currentHomePageInfo,
  currentMenuId,
  currentMenuIndex,
  customMenus,
  setCurrentHomePageInfo,
  setToolAndMenuCardOpen,
}) => {
  const [modal, contextHolder] = Modal.useModal();
  return (
    <>
      <div className={styles.menuHomePage}>
        {currentHomePageInfo && currentHomePageInfo[0]?.name ? (
          <>
            <div className={styles.text}>{`${currentHomePageInfo[0]?.name}首页`}</div>
            <Button
              className={styles.removeTool}
              type="default"
              icon={<CloseOutlined />}
              onClick={() => {
                modal.confirm({
                  title: '确定移除此工具?',
                  onOk() {
                    if (!currentHomePageInfo[0]?.id) return;
                    apiV2MiniAppToolEditPost({
                      menuId: currentMenuId,
                      deleteToolIds: [currentHomePageInfo[0]?.id],
                    }).then(() => {
                      currentMenuId &&
                        apiV2MiniAppToolGetListPost({ id: +currentMenuId }).then(res => {
                          if (res) {
                            setCurrentHomePageInfo(res.list);
                          }
                        });
                    });
                  },
                });
              }}
            >
              移除此工具
            </Button>
          </>
        ) : (
          <>
            {currentHomePageInfo?.length === 0 && currentMenuIndex !== customMenus.length + 2 && (
              <Button
                className={styles.addTool}
                style={{ marginTop: '-170px' }}
                onClick={() => {
                  setToolAndMenuCardOpen(true);
                }}
                type="default"
                icon={<PlusOutlined className={styles.plusIcon} />}
              >
                <span>添加工具</span>
              </Button>
            )}
          </>
        )}
      </div>
      {contextHolder}
    </>
  );
};

export default CustomPage;
