import React from 'react';
import { Form, Button, Row, Col, Input, Modal, FormInstance } from 'antd';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { MinusCircleOutlined, PlusOutlined, MenuOutlined } from '@ant-design/icons';
import SortableTable from './SortableTable';
import styles from './index.module.scss';
import { CategoryData, GuideClassificationProcessDto } from './create';

interface ReusableFormComponentProps {
  form: FormInstance;
  formName: string;
  formLabel: string;
  allProcessIds: (number | undefined)[];
  setAllProcessIds: React.Dispatch<React.SetStateAction<(number | undefined)[]>>;
}

const ReusableFormComponent: React.FC<ReusableFormComponentProps> = ({
  form,
  formName,
  formLabel,
  allProcessIds,
  setAllProcessIds,
}) => {
  const [modalApi, modalContextHolder] = Modal.useModal();

  // 移除分类
  const handleRemoveCategory = (remove: (index: number | number[]) => void, name: number) => {
    const values = form.getFieldsValue();

    const categoryData: CategoryData = values[formName][name];

    const currentDeleteIds =
      categoryData && categoryData.guideClassificationProcessDtos
        ? categoryData.guideClassificationProcessDtos.map((i: GuideClassificationProcessDto) => i.id)
        : [];

    modalApi.confirm({
      title: '移除分类',
      content: '是否移除此分类？',
      onOk: () => {
        remove(name);
        setAllProcessIds(prev => {
          return prev.filter(id => !currentDeleteIds.includes(id));
        });
      },
    });
  };

  return (
    <>
      <Form.Item label={`${formLabel}排放`} wrapperCol={{ span: 24 }}>
        <Form.List name={formName}>
          {(fields, { add, remove, move }) => {
            const handleDragEnd = (result: DropResult) => {
              if (!result.destination) return;

              const sourceIndex = result.source.index;
              const destIndex = result.destination.index;
              move(sourceIndex, destIndex);
            };

            return (
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable">
                  {provided => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {fields.map(({ key, name, ...restField }, index) => {
                        return (
                          <Draggable key={key.toString()} draggableId={key.toString()} index={index}>
                            {provided => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                className={styles.subFormWrapper}
                              >
                                <div className={styles.subForm}>
                                  <Row justify="space-between">
                                    <Col span={8}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, 'classificationName']}
                                        rules={[
                                          { required: true, min: 2, max: 20, message: '分类名称须在2-20字符之间' },
                                        ]}
                                      >
                                        <Input placeholder="请输入分类名称" />
                                      </Form.Item>
                                    </Col>

                                    <Col>
                                      <div {...provided.dragHandleProps}>
                                        <MenuOutlined className={styles.menuIconDrag} />
                                      </div>
                                    </Col>

                                    <Col span={24}>
                                      <Form.Item noStyle {...restField} name={[name, 'guideClassificationProcessDtos']}>
                                        <SortableTable
                                          allProcessIds={allProcessIds}
                                          setAllProcessIds={setAllProcessIds}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </div>

                                <MinusCircleOutlined
                                  className={styles.removeBtn}
                                  onClick={() => handleRemoveCategory(remove, name)}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                      <Form.Item>
                        <Button
                          type="primary"
                          onClick={() => {
                            add();
                          }}
                          icon={<PlusOutlined />}
                          className={styles.addCategoryBtn}
                        >
                          新增{formLabel}分类
                        </Button>
                      </Form.Item>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            );
          }}
        </Form.List>
      </Form.Item>
      {modalContextHolder}
    </>
  );
};

export default ReusableFormComponent;
