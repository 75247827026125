import { PlusOutlined } from '@ant-design/icons';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { Button } from 'antd';
import React from 'react';
import Grid from './Grid';

import styles from '../index.module.scss';
import Item from './Item';
import SortableItem from './SortableItem';

interface Props {
  sortCate: any[];
  currentCenterId?: number;
  activeId: string | null;
  handleDragStart: (event: any) => void;
  handleDragEnd: (event: DragEndEvent) => void;
  handleDragCancel: () => void;
  setToolModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  update: () => void;
  setActiveId: React.Dispatch<React.SetStateAction<string | null>>;
}
const ToolCenter: React.FC<Props> = ({
  sortCate,
  currentCenterId,
  activeId,
  handleDragStart,
  handleDragEnd,
  handleDragCancel,
  setToolModalOpen,
  update,
  setActiveId,
}) => {
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  return (
    <>
      <div className={styles.dndArea}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
        >
          <SortableContext items={sortCate} strategy={rectSortingStrategy}>
            <Grid columns={1}>
              {sortCate.map((item: any) => (
                <SortableItem
                  childrenData={item.children}
                  key={item.id}
                  id={item.mirrorCategoryName}
                  currentCenterId={currentCenterId}
                  update={update}
                />
              ))}
            </Grid>
          </SortableContext>
          <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
            {activeId ? <Item id={activeId} isDragging /> : null}
          </DragOverlay>
        </DndContext>
      </div>
      {/* 图标拖拽区域 */}

      <Button
        className={styles.addTools}
        type="default"
        onClick={() => {
          setToolModalOpen(true);
        }}
        icon={<PlusOutlined className={styles.plusIcon} />}
      >
        <span className={styles.addToolsText}>添加工具</span>
      </Button>
    </>
  );
};

export default ToolCenter;
