import React from 'react';
import { Modal, Form, Input } from 'antd';
import { CreateWorkspaceParams, createWorkspace } from '../../../api/cc-tenant-ql';

type CreateWorkspaceModalProps = {
  open: boolean;
  closeModal: () => void;
  forceUpdate: () => void;
};

export default function CreateWorkspaceModal(props: CreateWorkspaceModalProps) {
  const [modalApi, modalContextHolder] = Modal.useModal();
  const { open, closeModal, forceUpdate } = props;
  const [form] = Form.useForm<CreateWorkspaceParams>();

  const onFinish = (values: CreateWorkspaceParams) => {
    createWorkspace(values)
      .then(res => {
        modalApi.success({
          content: '新建工作区成功',
          onOk: () => {
            // 强制渲染列表页面, 拉取新的工作区
            forceUpdate();
          },
        });
      })
      .catch(error => {
        modalApi.error({
          content: '新建工作区失败，请检查输入项是否正确',
        });
      })
      .finally(() => {
        closeModal();
      });
  };

  return (
    <Modal
      title="新建工作区"
      destroyOnClose
      open={open}
      onOk={() => {
        form.submit();
      }}
      onCancel={() => {
        closeModal();
      }}
    >
      <Form
        labelAlign="left"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        preserve={false}
        onFinish={onFinish}
      >
        <Form.Item rules={[{ required: true }]} name="spaceIdCode" label="工作区ID">
          <Input placeholder="请输入工作区ID" />
        </Form.Item>
        <Form.Item rules={[{ required: true }]} name="spaceName" label="工作区名称">
          <Input placeholder="请输入工作区名称" />
        </Form.Item>
        <Form.Item rules={[{ required: true }]} name="adminAccessToken" label="adminAccessToken">
          <Input.Password placeholder="请输入adminAccessToken" />
        </Form.Item>
        <Form.Item rules={[{ required: true }]} name="adminAccount" label="管理员账号">
          <Input placeholder="请输入管理员账号" />
        </Form.Item>
        <Form.Item rules={[{ required: true }]} name="adminPassword" label="管理员密码">
          <Input.Password placeholder="请输入管理员密码" />
        </Form.Item>
        <Form.Item rules={[{ required: true }]} name="userId" label="成员ID">
          <Input placeholder="请输入成员 ID" />
        </Form.Item>
      </Form>
      {modalContextHolder}
    </Modal>
  );
}
