import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Form, Input, Modal, Row, Select, Cascader, InputNumber } from 'antd';
import { isNil } from 'lodash-es';

import { FormContent, Wrapper, useAsync } from '@maxtropy/components';
import {
  AlarmLevel,
  AlarmLevelDisplay,
  AlarmType,
  IotProtocolType,
  IotProtocolTypeDisplay,
  AlarmChannel,
  GatewayAlarmType,
} from '@/shared/types';
import ShowInput from '@/shared/components/ShowInput';
import AlarmIotProtocolFormItem from '@/shared/components/AlarmIotProtocolFormItem';
import {
  formulaValidator,
  formatOnSave,
  parseOnEcho,
  getRelatedDataPropertiesFromFormula,
} from '@/shared/components/FormulaInput';
import FormulaInput from '@/shared/components/FormulaInput/FormulaInputOfAlarm';
import { formatDeviceTypeToDataNode, useQuery, sliceDeviceTypeName } from '@/shared/utils/utils';
import { getDataProperties, getPhysicalModelList } from '@/shared/api/options';
import { getProtocol } from '../../../api/protocol';
import { getRoot } from '../../../api/device';
import { getDeviceTypeTreeWithoutScene } from '../../../api/template';
import styles from '../List/index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  V2RuleInfoPostResponse,
  apiV2RuleAddPost,
  apiV2RuleInfoPost,
  apiV2RuleUpdatePost,
} from '@maxtropy/device-mgmt-apis-v2';
import { apiV2RuleGetChannelPost, V2RuleGetChannelPostResponse } from '@maxtropy/tody-mgmt-apis-v2';
import AlarmRuleFormItems from '@/pages/Alarm/Create/components/AlarmChannelFormItem';

interface AlarmCreateProps {
  isEdit?: boolean;
}

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

// 平台网关设备的 id
const platformGatewayId = 5003;

const AlarmCreate: React.FC<AlarmCreateProps> = ({ isEdit }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const tabs = urlSearchParams.get('tabs') || '1';
  const [iotProtocolType, setIotProtocolType] = useState<IotProtocolType>();
  const [info, setInfo] = useState<V2RuleInfoPostResponse>();
  const [deviceType, setDeviceType] = useState<number>();
  const [physicalModelId, setPhysicalModelId] = useState<number>();

  const tenant = useAsync(getRoot, []);
  const iotProtocolData = useAsync(getProtocol);

  const [modalApi, modalContextHolder] = Modal.useModal();
  const { data: deviceTypeTreeData } = useQuery(useCallback(() => getDeviceTypeTreeWithoutScene(), []));
  // 用户新选择的关联属性, 不包含公式里的
  const [newSelectedRelatedDataProperties, setNewSelectedRelatedDataProperties] = useState<number[]>([]);
  const [alarmChannelList, setAlarmChannelList] = useState<V2RuleGetChannelPostResponse['list']>();
  const initialRelatedDataPropertiesRef = useRef<number[]>([]);
  const [alarmChannel, setAlarmChannel] = useState<AlarmChannel>();

  // 获取平台网关设备类目 id
  // useEffect(() => {
  //   getPlatformGatewayId().then(res => {
  //     setPlatformGatewayId(res);
  //   });
  // }, []);

  const { data: dataProperties = [] } = useQuery(
    useCallback(
      () =>
        iotProtocolType && !isNil(deviceType)
          ? getDataProperties(iotProtocolType, deviceType, physicalModelId)
          : Promise.resolve([]),
      [iotProtocolType, deviceType, physicalModelId]
    )
  );
  const { data: physicalModelOptions = [] } = useQuery(
    useCallback(async () => {
      if (!isNil(deviceType) && !isEdit) {
        const response = await getPhysicalModelList({ deviceTypeIds: [deviceType] });
        return response;
      }
      return [];
    }, [deviceType, isEdit])
  );

  const onDeviceTypeIdChange = useCallback(
    (e: any) => {
      setDeviceType(e[1] as number | undefined);
      setPhysicalModelId(undefined);
      form.setFieldsValue({
        formula: undefined,
        physicalModelId: undefined,
        duration: 0,
        relatedDataProperties: undefined,
      });
    },
    [form]
  );

  // 获取渠道数据
  useEffect(() => {
    apiV2RuleGetChannelPost().then(res => {
      setAlarmChannelList(res.list ?? []);
    });
  }, []);

  useEffect(() => {
    if (id) {
      apiV2RuleInfoPost({ id: +id }).then(data => {
        setIotProtocolType(data.iotProtocol);
        setInfo(data);
        setDeviceType(data.deviceTypeId);
        setAlarmChannel(data.channel);
        setPhysicalModelId(data.physicalModelId);
        // 保存最初的关联属性, 用于增量提交时候的比较
        initialRelatedDataPropertiesRef.current = data.relatedPropertyIds ?? [];

        form.setFieldsValue({
          ...data,
          physicalModelId: data.modelNo,
          rootMcid: data.tenantName,
          iotProtocol: IotProtocolTypeDisplay[data.iotProtocol as IotProtocolType],
          channel: alarmChannelList?.find(i => i.code === data.channel)?.desc ?? '--',
          deviceTypeId: data.deviceTypeName ? sliceDeviceTypeName(data.deviceTypeName) : '--',
          formula: parseOnEcho(data.formula),
          relatedDataProperties: data.relatedPropertyIds ?? [],
        });
      });
    }
  }, [id, form, alarmChannelList]);

  const onFinish = async (values: any) => {
    const params = {
      ...values,
      physicalModelId: isEdit ? info?.physicalModelId : values.physicalModelId,
      rootMcid: isEdit ? info?.rootMcid : values.rootMcid,
      type: AlarmType.MARGIN,
      iotProtocol: isEdit ? info?.iotProtocol : values.iotProtocol,
      deviceTypeId: isEdit ? info?.deviceTypeId : deviceType,
    };

    if (values.formula) {
      params.formula = formatOnSave(values.formula);
    }
    const { relatedDataProperties, ...restParams } = params;

    // 渠道为三方和朗新时 iotProtocol 协议传 3
    const iotProtocol =
      alarmChannel === AlarmChannel.LONGSHINE || alarmChannel === AlarmChannel.THIRD
        ? IotProtocolType.LONGSHINE
        : iotProtocolType;
    // 增量提交, 编辑时传 addRelatedPropertyIds 和 deleteRelatedPropertyIds 字段
    // 添加规则时全是新增, 因此只需传 relatedPropertyIds
    // 需要判断设备类目是平台网关且是云端报警的时候不传 relatedDataProperties 相关逻辑
    const isCloudAlarm = params?.deviceTypeId === platformGatewayId && params?.alarmMode === GatewayAlarmType.CLOUD;

    const res = isEdit
      ? isCloudAlarm
        ? await apiV2RuleUpdatePost(
            {
              ...restParams,
              channel: alarmChannel,
              id: Number(id),
              iotProtocol,
            },
            {
              headers: {
                'x-tenant-mcid': params.rootMcid,
              },
            }
          )
        : await apiV2RuleUpdatePost(
            {
              ...restParams,
              channel: alarmChannel,
              id: Number(id),
              iotProtocol,
              addRelatedPropertyIds:
                initialRelatedDataPropertiesRef.current &&
                values?.relatedDataProperties?.filter(
                  (v: number) => !initialRelatedDataPropertiesRef.current?.includes(v)
                ),
              deleteRelatedPropertyIds:
                initialRelatedDataPropertiesRef.current &&
                initialRelatedDataPropertiesRef.current?.filter(v => !values.relatedDataProperties?.includes(v)),
            },
            {
              headers: {
                'x-tenant-mcid': params.rootMcid,
              },
            }
          )
      : isCloudAlarm
      ? await apiV2RuleAddPost(
          {
            ...restParams,
            iotProtocol,
          },
          {
            headers: {
              'x-tenant-mcid': params.rootMcid,
            },
          }
        )
      : await apiV2RuleAddPost(
          {
            ...restParams,
            relatedPropertyIds: values.relatedDataProperties,
            iotProtocol,
          },
          {
            headers: {
              'x-tenant-mcid': params.rootMcid,
            },
          }
        );
    if (res) {
      navigate(`/device/rule/list?tabs=${tabs}`);
    }
  };

  useEffect(() => {
    if (isEdit) return;
    form.setFieldsValue({
      channel: AlarmChannel.MAXTROPY,
    });
    setAlarmChannel(AlarmChannel.MAXTROPY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit]);

  const routes = useMemo(() => {
    return [
      { name: 'Iot配置' },
      { name: '报警规则' },
      { name: '设备报警规则' },
      { name: `${isEdit ? '编辑' : '新建'}设备报警规则` },
    ];
  }, [isEdit]);

  return (
    <Wrapper routes={routes} className={styles.wrapper}>
      <FormContent>
        <Form layout="vertical" form={form} {...formLayout} onFinish={onFinish}>
          <Row>
            <Col span={8} className={styles.col}>
              <Form.Item
                name="name"
                label="规则名称"
                rules={[
                  { required: true, message: '请输入规则名称' },
                  { max: 20, message: '最多输入二十个字' },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item name="rootMcid" label="所属租户" rules={[{ required: false, message: '请选择所属租户' }]}>
                {isEdit ? (
                  <ShowInput />
                ) : (
                  <Select placeholder="请选择">
                    {tenant.map(i => (
                      <Select.Option key={i.mcid} value={i.mcid}>
                        {i.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item name="alarmType" label="报警等级" rules={[{ required: true, message: '请选择报警等级' }]}>
                <Select placeholder="请选择">
                  <Select.Option value={AlarmLevel.HIGHEST}>{AlarmLevelDisplay[AlarmLevel.HIGHEST]}</Select.Option>
                  <Select.Option value={AlarmLevel.HIGH}>{AlarmLevelDisplay[AlarmLevel.HIGH]}</Select.Option>
                  <Select.Option value={AlarmLevel.MIDDLE}>{AlarmLevelDisplay[AlarmLevel.MIDDLE]}</Select.Option>
                  <Select.Option value={AlarmLevel.LOW}>{AlarmLevelDisplay[AlarmLevel.LOW]}</Select.Option>
                  <Select.Option value={AlarmLevel.LOWEST}>{AlarmLevelDisplay[AlarmLevel.LOWEST]}</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item
                name="alarmName"
                label="报警信息"
                rules={[
                  { required: true, message: '请输入报警信息' },
                  { max: 500, message: '最多输入500个字符' },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              {isEdit ? (
                <>
                  <Form.Item name="channel" label="渠道">
                    <ShowInput />
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item
                    name={'channel'}
                    initialValue={AlarmChannel.MAXTROPY}
                    label="渠道"
                    rules={[{ required: true, message: '请选择渠道' }]}
                  >
                    <Select
                      onChange={setAlarmChannel}
                      options={alarmChannelList?.map(
                        ({ code, desc }) =>
                          ({
                            label: desc,
                            value: code,
                          } ?? [])
                      )}
                    />
                  </Form.Item>
                </>
              )}
            </Col>
            {alarmChannel === AlarmChannel.MAXTROPY && (
              <>
                <Col span={8} className={styles.col}>
                  <Form.Item
                    name="iotProtocol"
                    rules={[{ required: true, message: '请选择协议选择' }]}
                    label="物联层协议"
                  >
                    {isEdit ? (
                      <ShowInput />
                    ) : (
                      <Select<IotProtocolType> onChange={setIotProtocolType} placeholder="请选择">
                        {iotProtocolData?.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                {iotProtocolType === IotProtocolType.MOCKINGBIRD && (
                  <>
                    <Col span={8}>
                      <Form.Item
                        name="deviceTypeId"
                        label="适用设备类目"
                        rules={[{ required: true, message: '请选择适用设备类目' }]}
                      >
                        {isEdit ? (
                          <ShowInput />
                        ) : (
                          <Cascader
                            allowClear={false}
                            style={{ width: '100%' }}
                            options={formatDeviceTypeToDataNode(deviceTypeTreeData?.deviceTypes ?? [])}
                            fieldNames={{ label: 'name', value: 'id' }}
                            onChange={onDeviceTypeIdChange}
                            placeholder="请选择"
                          />
                        )}
                      </Form.Item>
                    </Col>

                    <Col span={8} className={styles.col}>
                      <Form.Item name="physicalModelId" label="物模型型号">
                        {isEdit ? (
                          <ShowInput />
                        ) : (
                          <Select
                            placeholder="请选择"
                            onChange={v => {
                              setPhysicalModelId(v);
                              form.setFieldsValue({
                                formula: undefined,
                                duration: 0,
                                relatedDataProperties: undefined,
                              });
                            }}
                            options={physicalModelOptions.map(item => ({
                              label: item.modelNo,
                              value: item.id,
                            }))}
                            allowClear
                          />
                        )}
                      </Form.Item>
                    </Col>

                    <Col span={8} className={styles.col}>
                      <Form.Item
                        name="duration"
                        initialValue={0}
                        label="报警持续时间"
                        rules={[{ required: true, message: '请输入报警持续时间' }]}
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          min={0}
                          max={86400000}
                          precision={0}
                          placeholder="请输入0~86400000的数字"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8} className={styles.col}>
                      <Form.Item
                        name="relatedDataProperties"
                        label="关联属性"
                        tooltip={{
                          title: '报警详情中可显示相关设备属性数值，并提供录波数据参考',
                          icon: <InfoCircleOutlined />,
                        }}
                      >
                        <Select
                          optionFilterProp="label"
                          disabled={isNil(deviceType)}
                          mode="multiple"
                          allowClear
                          options={dataProperties.map(v => ({ label: v.name, value: v.id }))}
                          onChange={(changedValue: number[]) => {
                            // 监听关联属性多选框改动, 一旦改动设置用户新选择的属性
                            const formulaRelatedDataProperties = getRelatedDataPropertiesFromFormula(
                              formatOnSave(form.getFieldValue('formula')),
                              dataProperties
                            ).map(v => v.id);
                            setNewSelectedRelatedDataProperties(
                              changedValue.filter((v: number) => !formulaRelatedDataProperties.includes(v))
                            );
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8} className={styles.col}>
                      <Form.Item
                        name="formula"
                        label="报警公式"
                        validateFirst
                        rules={[{ required: true, message: '请填写公式' }, { validator: formulaValidator }]}
                      >
                        <FormulaInput
                          disabled={isNil(deviceType)}
                          onBlur={() => {
                            const newRelatedDataProperties = getRelatedDataPropertiesFromFormula(
                              formatOnSave(form.getFieldValue('formula')),
                              dataProperties
                            ).map(v => v.id);
                            form.setFieldValue('relatedDataProperties', [
                              ...new Set(newRelatedDataProperties.concat(newSelectedRelatedDataProperties)),
                            ]);
                          }}
                          dataProperties={dataProperties.map(item => ({
                            id: `x_${item.id}`,
                            display: item.name,
                            description: '建模',
                          }))}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
                {iotProtocolType && <AlarmIotProtocolFormItem type={iotProtocolType} />}
              </>
            )}

            {
              // 根据渠道显示不同的表单项
              alarmChannel && <AlarmRuleFormItems channel={alarmChannel} />
            }
          </Row>
          <Button type="primary" htmlType="submit">
            保存
          </Button>
          <Button
            style={{ marginLeft: 20 }}
            onClick={() => {
              modalApi.confirm({
                title: null,
                icon: null,
                content: <div>是否放弃所有未保存信息并返回列表？</div>,
                onOk: () => navigate(`/device/rule/list?tabs=${tabs}`),
              });
            }}
          >
            取消
          </Button>
        </Form>
      </FormContent>
      {modalContextHolder}
    </Wrapper>
  );
};

export default AlarmCreate;
