import { fetch } from '@maxtropy/components';

export interface App {
  id: number;
  name: string;
  devGroup?: any;
  imgUrl?: any;
  memo?: any;
  appKey: string;
  status?: any;
  creatorName?: any;
  countOfDeveloper?: any;
  createTime: string;
  updateTime: string;
}

export interface Developer {
  id: number;
  corpUserId: number;
  username: string;
  name: string;
  status?: any;
  cellphone: string;
  devGroup?: any;
  countOfApp?: any;
  createTime: string;
  updateTime: string;
}

export interface Role {
  id: number;
  name: string;
  memo: string;
  createTime: string;
  updateTime: string;
}

export interface RelationVo {
  id: number;
  app: App;
  developer: Developer;
  role: Role;
}

export interface CorpUser {
  id: number;
  relationVos: RelationVo[] | null;
  username: string;
  name: string;
  cellphone: string;
}

export async function getCurrentUser(): Promise<CorpUser> {
  return await fetch('/api/user/current');
}
