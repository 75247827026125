import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Wrapper } from '@maxtropy/components';
import { Form, Input, Button, Space, Select, DatePicker, message } from 'antd';

import { useRequest } from 'ahooks';
import {
  apiCarbonAccountingGuideDetailPost,
  CarbonAccountingGuideDetailPostResponse,
  CarbonAccountingGuidePostRequest,
} from '@maxtropy/device-mgmt-apis';
import dayjs from 'dayjs';
import ReusableFormComponent from './ReusableFormComponent';
import { useCarbonEmissions } from '../List/useCarbonEmissions';
import { FormValue, GuideClassificationTransformed, InitialValuesType, Range } from './create';
import styles from './index.module.scss';
import { apiV2CarbonAccountingGuideAddOrUpdatePost } from '@maxtropy/tody-mgmt-apis-v2';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 8 },
};

const FORMAT_TIME = 'YYYY-MM-DD';

let validationFailed = false;

interface Props {
  edit?: boolean;
}
const GuidelinesCreate: React.FC<Props> = ({ edit }) => {
  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [messageApi, messageContextHolder] = message.useMessage();

  const [allProcessIds, setAllProcessIds] = useState<(number | undefined)[]>([]);

  const { industryOptions, fetchIndustryOptions } = useCarbonEmissions();

  const transformRange = (range: Range[], rangeNumber: number) => {
    return range.map((item, index) => {
      if (!item.guideClassificationProcessDtos || item.guideClassificationProcessDtos.length === 0) {
        messageApi.info('一个分类内，最少要添加一个排放过程');
        validationFailed = true;
        return item;
      }

      return {
        ...item,
        range: rangeNumber,
        classificationOrder: index + 1,
        guideClassificationProcessDtos:
          item.guideClassificationProcessDtos &&
          item.guideClassificationProcessDtos.map(innerItem => ({
            processOrder: innerItem.processOrder,
            emissionProcessId: innerItem.id,
            id: innerItem.key,
          })),
      };
    });
  };

  const onFinish = async (values: FormValue) => {
    const { issuingDate, submitType, ...restValues } = values;

    const ranges = ['range1', 'range2', 'range3'].flatMap((rangeKey, index) => {
      const rangeData = restValues[rangeKey];
      delete restValues[rangeKey];

      return rangeData ? transformRange(rangeData, index + 1) : [];
    });

    if (ranges.length === 0) {
      messageApi.info('一个核算指南内，最少要添加一个排放过程');
      return;
    }

    // 检查验证失败标志
    if (validationFailed) {
      validationFailed = false;
      return;
    }

    const postData: CarbonAccountingGuidePostRequest = {
      ...restValues,
      issuingDate: dayjs(issuingDate).format(FORMAT_TIME),
      enable: submitType,
      guideClassificationList: ranges as CarbonAccountingGuidePostRequest['guideClassificationList'],
      ...(id ? { id: Number(id) } : {}),
    };

    await apiV2CarbonAccountingGuideAddOrUpdatePost(postData);
    navigate('/dualCarbon/carbonEmissions/accountingGuidelines');
  };

  // 用于过滤和排序 guideClassificationList 的函数
  const transformGuideClassificationList = (
    guideClassificationList: CarbonAccountingGuideDetailPostResponse['guideClassificationList'],
    range: number
  ): GuideClassificationTransformed[] => {
    return (guideClassificationList || [])
      .filter(item => item.range === range)
      .sort((a, b) => (a.classificationOrder || 0) - (b.classificationOrder || 0))
      .map(item => ({
        ...item,
        guideClassificationProcessDtos: (item.guideClassificationProcessDtos || [])
          .sort((a, b) => (a.processOrder || 0) - (b.processOrder || 0))
          .map(processDto => ({
            ...processDto,
            name: processDto.emissionProcessName || '',
            id: processDto.emissionProcessId || 0,
            key: processDto.id,
          })),
      }));
  };

  // 回显数据的函数
  const handleEchoData = (detailData: CarbonAccountingGuideDetailPostResponse) => {
    if (detailData) {
      const { guideClassificationList, ...otherFields } = detailData;

      // 初始化存储转换后数据的对象
      const initialValues: InitialValuesType = {
        range1: [],
        range2: [],
        range3: [],
      };

      initialValues.range1 = transformGuideClassificationList(guideClassificationList, 1);
      initialValues.range2 = transformGuideClassificationList(guideClassificationList, 2);
      initialValues.range3 = transformGuideClassificationList(guideClassificationList, 3);

      const allProcessIds = (['range1', 'range2', 'range3'] as (keyof InitialValuesType)[]).reduce((acc, rangeKey) => {
        const rangeData = initialValues[rangeKey];
        const ids = rangeData.flatMap(item => (item.guideClassificationProcessDtos || []).map(dto => dto.id));
        return acc.concat(ids);
      }, [] as number[]);
      setAllProcessIds(allProcessIds);

      form.setFieldsValue({
        ...otherFields,
        ...initialValues,
        issuingDate: dayjs(detailData.issuingDate),
      });
    }
  };

  useRequest(
    async () => {
      if (!id) throw new Error('ID 不存在');
      return apiCarbonAccountingGuideDetailPost({ id });
    },
    {
      refreshDeps: [id],
      onSuccess: (detailData: CarbonAccountingGuideDetailPostResponse) => handleEchoData(detailData),
    }
  );

  useEffect(() => {
    fetchIndustryOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routes = [{ name: '企业碳盘查' }, { name: '核算指南管理' }, { name: `${edit ? '编辑' : '新建'}核算指南` }];

  return (
    <>
      <Wrapper className={styles.wrapper} routes={routes}>
        <Form form={form} onFinish={onFinish} {...formLayout}>
          <Form.Item name="industryId" label="核算指南行业" rules={[{ required: true }]}>
            <Select placeholder="请选择" disabled={edit} options={industryOptions}></Select>
          </Form.Item>

          <Form.Item name="standard" label="核算指南标准" rules={[{ required: true, min: 2, max: 50 }]}>
            <Input placeholder="请输入" />
          </Form.Item>

          <Form.Item name="issuingInstitution" label="颁发机构" rules={[{ min: 2, max: 50 }]}>
            <Input placeholder="请输入" />
          </Form.Item>

          <Form.Item name="issuingDate" label="颁发时间">
            <DatePicker placeholder="请选择" className={styles.datePicker} />
          </Form.Item>

          <ReusableFormComponent
            form={form}
            allProcessIds={allProcessIds}
            setAllProcessIds={setAllProcessIds}
            formName="range1"
            formLabel="范围一"
          />
          <ReusableFormComponent
            form={form}
            allProcessIds={allProcessIds}
            setAllProcessIds={setAllProcessIds}
            formName="range2"
            formLabel="范围二"
          />
          <ReusableFormComponent
            form={form}
            allProcessIds={allProcessIds}
            setAllProcessIds={setAllProcessIds}
            formName="range3"
            formLabel="范围三"
          />

          <Form.Item>
            <Space size={8}>
              <Form.Item hidden name="submitType">
                <Input />
              </Form.Item>

              <Button type="primary" htmlType="submit" onClick={() => form.setFieldsValue({ submitType: 1 })}>
                保存并启用
              </Button>

              <Button type="default" htmlType="submit" onClick={() => form.setFieldsValue({ submitType: 2 })}>
                保存
              </Button>

              <Button type="default" onClick={() => navigate('/dualCarbon/carbonEmissions/accountingGuidelines')}>
                取消
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Wrapper>
      {messageContextHolder}
    </>
  );
};

export default GuidelinesCreate;
