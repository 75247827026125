import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, Form, Input, Modal, Radio, Row, Select, Space, Spin, TreeSelect } from 'antd';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import { isNil } from 'lodash-es';
import { useNavigate, useParams } from 'react-router-dom';
import { FormContent, Wrapper, useAsync } from '@maxtropy/components';

import { IotProtocolType } from '@/shared/types';
import ShowInput from '@/shared/components/ShowInput';
import {
  checkSerialNumber,
  createGateway,
  fetchGatewayById,
  GatewayDetail,
  getUploadFrequencyOpts,
  SYSTEM_TYPE_MAP,
  updateGateway,
} from '../../../api/gateway';
import { getOrganization, getRoot, OrganizationResponse } from '../../../api/device';
import { getProtocol } from '../../../api/protocol';
import DeviceModal, { DeviceModalRef } from './DeviceModal';
import styles from '../index.module.scss';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const { Option } = Select;

interface DeviceFormProps {
  value?: string;
  onSelect: () => void;
}

const DeviceFormText: React.FC<DeviceFormProps> = ({ value, onSelect }) => {
  return (
    <>
      {value && <span style={{ marginRight: 8 }}>{value}</span>}
      <Button type="link" onClick={onSelect}>
        {value ? '重新选择' : '请选择'}
      </Button>
    </>
  );
};

const formatTreeData = (data: OrganizationResponse[]) => {
  return data.map(item => {
    const res: DefaultOptionType = {
      key: item.data?.mcid ?? '',
      value: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children || []),
    };
    return res;
  });
};

const CreateGateway: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const query = Object.fromEntries(urlSearchParams.entries());

  const { id } = useParams<{ id: string }>();
  const isEdit = !isNil(id);

  const routes = useMemo(
    () => [{ name: 'Iot配置' }, { name: '物联配置' }, { name: '网关管理' }, { name: isEdit ? '编辑网关' : '新建网关' }],
    [isEdit]
  );

  const iotProtocolData = useAsync(getProtocol);
  const tenant = useAsync(getRoot, []);
  const frequencyOpts = useAsync(getUploadFrequencyOpts, []);
  const [tenantMcid, setTenantMcid] = useState<string>();
  const [organization, setOrganition] = useState<OrganizationResponse>();
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GatewayDetail>();

  const [iotProtocolType, setIotProtocolType] = useState<IotProtocolType>();
  const [visible, setVisible] = useState(false);
  const [deviceId, setDeviceId] = useState<number>();
  const deviceRef: MutableRefObject<DeviceModalRef | null> = useRef(null);
  const [modalApi, modalContextHolder] = Modal.useModal();

  useEffect(() => {
    (async () => {
      if (id) {
        setLoading(true);
        const res = await fetchGatewayById(id);
        const organizationRes = await getOrganization(res.createByTenantId);
        setData(res);
        setOrganition(organizationRes);
        setLoading(false);
      }
    })();
  }, [id]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        name: data.name,
        remark: data.remark,
        createByOrgId: data.createByOrgId,
        createByTenantId: data.tenantName,
        iotProtocol: data.iotProtocol,
        serialNumber: data.serialNumber,
        uploadFrequency: data.uploadFrequency ? Object.keys(data.uploadFrequency)[0] : null,
        metaData: !isNil(data.deviceId),
        deviceName: data.deviceName,
        systemType: data.systemType,
        transferProtocol: data.transferProtocol,
      });
      setIotProtocolType(data.iotProtocol);
      setDeviceId(data.deviceId);
    }
  }, [data, form]);

  useEffect(() => {
    if (tenantMcid) {
      form.setFieldsValue({
        createByOrgId: undefined,
      });
      getOrganization(tenantMcid).then(res => setOrganition(res));
    }
  }, [tenantMcid, form]);

  const treeData = useMemo(() => {
    if (organization) {
      return formatTreeData([organization]);
    } else {
      return undefined;
    }
  }, [organization]);

  const handleOK = async () => {
    const device = deviceRef?.current?.selectedRows;
    if (device) {
      setDeviceId(device.id);
      form.setFieldsValue({
        deviceName: device.name,
      });
    }
    setVisible(false);
  };

  const onSelectDevice = async () => {
    if (tenantMcid === undefined && data?.tenantCode === undefined) {
      modalApi.warning({
        title: '请先选择租户',
      });
      return;
    } else if (iotProtocolType === undefined) {
      modalApi.warning({
        title: '请先选择物联层协议',
      });
      return;
    } else {
      setVisible(true);
    }
  };

  const onSubmit = (bindDevice: boolean = false) => {
    form.validateFields().then(values => {
      const iotProtocol = isEdit ? data?.iotProtocol : values.iotProtocol;
      const params = {
        name: values.name,
        createByTenantId: isEdit ? data?.createByTenantId : values.createByTenantId,
        createByOrgId: values.createByOrgId,
        protocol: iotProtocol,
        iotProtocol,
        uploadFrequencyId: values.uploadFrequency,
        serialNumber: values.serialNumber,
        remark: values.remark,
        deviceId,
        systemType: values.systemType,
        transferProtocol: values.transferProtocol,
      };

      const request = isEdit ? () => updateGateway(id, params) : () => createGateway(params);

      setSubmitting(true);
      request()
        .then(gatewayId => {
          const targetId = id || gatewayId;
          navigate(
            bindDevice
              ? `/device/config/gateway/update/${targetId}/bind?previous=true`
              : `/device/config/gateway${window.location.search}`,
            {
              replace: true,
            }
          );
        })
        .finally(() => setSubmitting(false));
    });
  };

  return (
    <Wrapper routes={routes} className={styles.wrapper}>
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          {...formLayout}
          initialValues={{
            transferProtocol: 0,
          }}
        >
          <FormContent>
            <Row>
              <Col span={8} className={styles.col}>
                <Form.Item
                  label="网关名称"
                  name="name"
                  rules={[
                    { required: true, message: '请输入网关名称' },
                    { max: 20, message: '网关名称不超过20个字符' },
                  ]}
                >
                  <Input placeholder="请输入网关名称，不超过二十个字" />
                </Form.Item>
              </Col>
              <Col span={8} className={styles.col}>
                <Form.Item
                  name="createByTenantId"
                  label="所属租户"
                  rules={[{ required: true, message: '请选择所属租户' }]}
                >
                  {isEdit ? (
                    <ShowInput />
                  ) : (
                    <Select
                      style={{ width: '100%' }}
                      placeholder="请选择"
                      onChange={v => {
                        setTenantMcid(v as string);
                      }}
                    >
                      {tenant.map(i => (
                        <Option key={i.mcid} value={i.mcid}>
                          {i.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={8} className={styles.col}>
                <Form.Item
                  label="所属组织"
                  name="createByOrgId"
                  rules={[{ required: true, message: '请选择所属组织' }]}
                >
                  <TreeSelect treeData={treeData} placeholder="请选择" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col span={8} className={styles.col}>
                <Form.Item
                  label="物联层协议"
                  name="iotProtocol"
                  rules={[{ required: true, message: '请选择物联层协议' }]}
                >
                  {isEdit ? (
                    <div>{iotProtocolData?.find(item => item.id === data?.iotProtocol)?.name ?? '--'}</div>
                  ) : (
                    <Select<IotProtocolType> placeholder="请选择" onChange={setIotProtocolType}>
                      {iotProtocolData?.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>

              <Form.Item noStyle dependencies={['iotProtocol']}>
                {({ getFieldValue }) =>
                  getFieldValue('iotProtocol') === IotProtocolType.MOCKINGBIRD && (
                    <>
                      <Col span={8}>
                        <Form.Item
                          name="uploadFrequency"
                          label="上传频率"
                          rules={[{ required: true, message: '请选择上传频率' }]}
                        >
                          <Select
                            placeholder="请选择"
                            getPopupContainer={triggerNode => triggerNode.parentNode as HTMLElement}
                          >
                            {frequencyOpts?.map(item => {
                              const [key, value] = Object.entries(item)[0];
                              return <Select.Option value={key}>{value}</Select.Option>;
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          required
                          name="serialNumber"
                          label="网关编号"
                          rules={[
                            {
                              validator: async (rule, value) => {
                                if (!value) {
                                  return Promise.reject('请输入网关编号');
                                }
                                if (!/^[A-Za-z0-9]{16}$/.test(value)) {
                                  return Promise.reject(new Error('请输入正确的16位网关编号'));
                                }
                                if (data?.serialNumber !== value) {
                                  const response = await checkSerialNumber({
                                    serialNumber: value,
                                  });
                                  if (!response) {
                                    return Promise.reject('网关编号已存在');
                                  }
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input placeholder="请输入16位网关编号" />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name="systemType"
                          label="网关系统类型"
                          rules={[{ required: true, message: '请选择网关系统类型' }]}
                        >
                          <Select placeholder="请选择">
                            {Object.entries(SYSTEM_TYPE_MAP).map(([k, v]) => {
                              return (
                                <Select.Option key={+k} value={+k}>
                                  {v}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8} className={styles.col}>
                        <Form.Item
                          name="metaData"
                          label="网关元数据采集"
                          rules={[{ required: true, message: '请选择' }]}
                          initialValue={true}
                        >
                          <Radio.Group
                            onChange={() => {
                              setDeviceId(undefined);
                              form.setFieldsValue({
                                deviceName: undefined,
                              });
                            }}
                          >
                            <Radio value={true}>是</Radio>
                            <Radio value={false}>否</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Form.Item noStyle dependencies={['metaData']}>
                        {({ getFieldValue }) =>
                          getFieldValue('metaData') && (
                            <Col span={8} className={styles.col}>
                              <div>
                                <Form.Item
                                  label="元数据采集设备"
                                  name="deviceName"
                                  rules={[{ required: true, message: '请选择元数据采集设备' }]}
                                >
                                  <DeviceFormText onSelect={onSelectDevice} />
                                </Form.Item>
                              </div>
                            </Col>
                          )
                        }
                      </Form.Item>
                      <Col span={8}>
                        <Form.Item
                          name="transferProtocol"
                          label="下发类型"
                          extra="仅zapdos版本在4.0以上，才支持kryo，否则会下发失败。"
                          rules={[{ required: true, message: '请选择下发类型' }]}
                        >
                          <Radio.Group>
                            <Radio value={0}>json</Radio>
                            <Radio value={1}>kryo</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </>
                  )
                }
              </Form.Item>
              <Col span={8} className={styles.col}>
                <Form.Item label="备注" name="remark" rules={[{ max: 50, message: '长度不能超过50个字符' }]}>
                  <Input placeholder="请输入备注" />
                </Form.Item>
              </Col>
            </Row>
          </FormContent>
        </Form>
      </Spin>
      <Space className="sticky-footer" style={{ paddingLeft: '40px' }}>
        {(!isEdit || query.next) && (
          <Button type="primary" loading={submitting} disabled={submitting} onClick={() => onSubmit(true)}>
            保存并绑定设备
          </Button>
        )}
        <Button type="primary" loading={submitting} disabled={submitting} onClick={() => onSubmit()}>
          保存
        </Button>
        <Button
          onClick={() =>
            navigate(`/device/config/gateway${window.location.search}`, {
              replace: true,
            })
          }
        >
          取消
        </Button>
      </Space>
      <Modal
        destroyOnClose
        wrapClassName={styles.customModal}
        width={1024}
        title="设备选择"
        open={visible}
        onOk={handleOK}
        onCancel={() => setVisible(false)}
      >
        <DeviceModal
          ref={deviceRef}
          iotProtocol={iotProtocolType}
          rootMcid={tenantMcid ?? data?.createByTenantId}
          deviceId={deviceId}
          edgeGatewayId={data?.id}
        />
      </Modal>
      {modalContextHolder}
    </Wrapper>
  );
};

export default CreateGateway;
