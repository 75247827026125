import { FC, Key, useEffect, useMemo, useState } from 'react';
import { Button, Card, Row, Col, Tag, Space, Descriptions, Table, Form, Input, Select, Modal } from 'antd';
import dayjs from 'dayjs';
import { FilterFunc } from 'rc-select/lib/Select';
import classNames from 'classnames';

import { EllipsisSpan, FormContent, useUpdate } from '@maxtropy/components';

import { TabsBaseProps } from '../util';
import { commonPhoneRegex } from '../../../utils/regex';
import { useHasPermission } from '../../../utils/hooks';

import {
  getCustomerContact,
  TenantBaseInfoResponse,
  CustomerContactResponse,
  CreateCustomerContactRequest,
  createCustomerContact,
  updateCustomerContact,
  getUpdateContactLogs,
  UpdateContactLogResponse,
} from '../../../api/cc-tenant';

import styles from './index.module.scss';
import { PermissionsType } from '../../../common/permissionsConst';

const { useForm } = Form;
const dateFormat = 'YYYY-MM-DD HH:mm:ss';

interface EditTenantBaseInfoProps extends TabsBaseProps {
  tenant?: TenantBaseInfoResponse;
  disabled?: boolean;
}

const formLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 },
};

const columns = [
  {
    title: '时间',
    dataIndex: 'operatorTime',
    render: (value: string) => {
      return <EllipsisSpan value={dayjs(value).format(dateFormat)} />;
    },
  },
  {
    title: '操作人',
    dataIndex: 'operatorName',
    render: (value: string) => {
      return <EllipsisSpan value={value} />;
    },
  },
  {
    title: '具体操作',
    dataIndex: 'contactsCode',
    render: (value: string) => {
      return <EllipsisSpan value={`编辑了联系人${value}的数据`} />;
    },
  },
  {
    title: '登录IP',
    dataIndex: 'ip',
    render: (value: string) => {
      return <EllipsisSpan value={value} />;
    },
  },
];

const ContactPerson: FC<EditTenantBaseInfoProps> = props => {
  const { onBreacrumbChange, tenant, disabled = false } = props;
  const [contacts, setContacts] = useState<CustomerContactResponse[]>([]);
  const [updateState, update] = useUpdate();
  const hasEdit = useHasPermission(PermissionsType.BUPDATECUSTOMERCONTACTS);

  const [updateContact, setUpdateContact] = useState<CustomerContactResponse>();
  const [logs, setLogs] = useState<UpdateContactLogResponse[]>([]);

  const [modalOpened, setModalOpened] = useState<boolean>(false);

  const [currentLog, setCurrentLog] = useState<UpdateContactLogResponse>();

  const [form] = useForm();

  useEffect(() => {
    if (tenant) {
      getCustomerContact(tenant.mcid).then(res => {
        setContacts(res);
      });
      getUpdateContactLogs(tenant.mcid).then(res => {
        setLogs(res);
      });
    }
  }, [tenant, updateState]);

  const routes = useMemo(() => {
    return [
      { name: '租户管理' },
      { name: '租户组织权限和账户' },
      { name: `${disabled ? '查看' : '编辑'}租户` },
      { name: '客户联系人信息' },
    ];
  }, [disabled]);

  useEffect(() => {
    onBreacrumbChange && onBreacrumbChange(routes);
  }, [onBreacrumbChange, routes]);

  const onModalCancel = () => {
    setModalOpened(false);
    setUpdateContact(undefined);
    form.resetFields();
  };

  const onEdit = (data: CustomerContactResponse) => {
    setUpdateContact(data);
    form.setFieldsValue(data);
    setModalOpened(true);
  };

  const onOk = () => {
    form.validateFields().then((res: CreateCustomerContactRequest) => {
      if (updateContact) {
        updateCustomerContact({
          ...res,
          mcid: tenant?.mcid!,
          id: updateContact.id,
        })
          .then(onModalCancel)
          .then(() => {
            update();
          });
      } else {
        createCustomerContact({
          ...res,
          mcid: tenant?.mcid!,
        })
          .then(onModalCancel)
          .then(() => {
            update();
          });
      }
    });
  };

  const onShowLogModal = (data: UpdateContactLogResponse) => {
    setCurrentLog(data);
  };

  const afterInfo = useMemo<CustomerContactResponse | undefined>(() => {
    if (currentLog) {
      return JSON.parse(currentLog.afterInfo);
    }
  }, [currentLog]);

  const beforeInfo = useMemo<CustomerContactResponse | undefined>(() => {
    if (currentLog) {
      return JSON.parse(currentLog.beforeInfo);
    }
  }, [currentLog]);

  const mergeColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 350,
      render: (value: undefined, record: UpdateContactLogResponse) => {
        return (
          <Button
            type="link"
            onClick={() => {
              onShowLogModal(record);
            }}
          >
            查看
          </Button>
        );
      },
    },
  ];

  const filterOption: FilterFunc<any> = (inputValue, option) => {
    return (
      !!(option as { value: Key; children: string })?.children.includes(inputValue) ||
      !!(option as { value: Key; children: string })?.value.toString().includes(inputValue)
    );
  };

  return (
    <div className={styles.container}>
      {!disabled && hasEdit && (
        <Button
          type="primary"
          onClick={() => {
            setModalOpened(true);
          }}
        >
          添加联系人
        </Button>
      )}
      <Row className={styles.cardWrapper} gutter={50}>
        {contacts.map(contact => (
          <Col className={styles.col} span={12} key={contact.id}>
            <Card
              title={
                <Space>
                  {contact.name}
                  <Tag className={styles.tag}>{contact.code}</Tag>
                </Space>
              }
              extra={
                !disabled ? (
                  hasEdit ? (
                    <Button
                      type="link"
                      onClick={() => {
                        onEdit(contact);
                      }}
                    >
                      编辑
                    </Button>
                  ) : null
                ) : null
              }
              className={styles.card}
            >
              <Descriptions column={2}>
                <Descriptions.Item label="手机">{contact.cellphone ?? '--'}</Descriptions.Item>
                <Descriptions.Item label="电话">{contact.telephone ?? '--'}</Descriptions.Item>
                <Descriptions.Item label="QQ">{contact.qq ?? '--'}</Descriptions.Item>
                <Descriptions.Item label="邮箱">{contact.email ?? '--'}</Descriptions.Item>
                <Descriptions.Item label="所在部门">{contact.dept ?? '--'}</Descriptions.Item>
                <Descriptions.Item label="上级">{contact.parentName ?? '--'}</Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        ))}
      </Row>
      <FormContent style={{ padding: 0 }} title="日志">
        <Row>
          <Col span={24}>
            <Table rowKey="id" className={styles.table} dataSource={logs} columns={mergeColumns} pagination={false} />
          </Col>
        </Row>
      </FormContent>
      <Modal
        title={updateContact ? '编辑联系人' : '新建联系人'}
        open={modalOpened}
        onCancel={onModalCancel}
        onOk={onOk}
        okText="保存"
      >
        <Form {...formLayout} form={form}>
          <Form.Item
            name="name"
            label="客户联系人姓名"
            rules={[
              { required: true, message: '请输入客户联系人姓名' },
              { pattern: /^[\u4e00-\u9fa5]+$/g, message: '客户联系人姓名必须为汉字' },
              { max: 30, message: '客户联系人姓名最多30个汉字' },
            ]}
          >
            <Input placeholder="请输入最多30个汉字" />
          </Form.Item>
          <Form.Item
            name="cellphone"
            label="客户联系人手机号"
            rules={[
              {
                pattern: commonPhoneRegex,
                message: '请输入正确的手机号',
              },
            ]}
          >
            <Input placeholder="请输入11位手机号码" />
          </Form.Item>
          <Form.Item
            name="telephone"
            label="客户联系人电话"
            rules={[
              {
                pattern: commonPhoneRegex,
                message: '请输入正确的电话',
              },
            ]}
          >
            <Input placeholder="请输入客户联系人电话" />
          </Form.Item>
          <Form.Item name="email" label="客户联系人邮箱" rules={[{ type: 'email', message: '请输入正确的邮箱' }]}>
            <Input placeholder="请输入客户联系人邮箱" />
          </Form.Item>
          <Form.Item
            name="qq"
            label="客户联系人QQ"
            rules={[
              {
                pattern: /^\d{4,15}?$/,
                message: '请输入正确的QQ',
              },
            ]}
          >
            <Input placeholder="请输入客户联系人QQ" />
          </Form.Item>
          <Form.Item
            name="dept"
            label="客户联系人所在部门"
            rules={[
              { pattern: /^[\u4e00-\u9fa5]+$/g, message: '所在部门必须为汉字' },
              { max: 30, message: '所在部门最多30个汉字' },
            ]}
          >
            <Input placeholder="请输入客户联系人所在部门" />
          </Form.Item>
          <Form.Item name="parentId" label="客户联系人上级">
            <Select showSearch placeholder="请选择客户联系人上级" filterOption={filterOption}>
              {contacts
                .filter(i => i.id !== updateContact?.id)
                .map(i => (
                  <Select.Option key={i.id} value={i.id}>
                    {i.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        width={700}
        title="日志详情"
        open={!!currentLog}
        onCancel={() => {
          setCurrentLog(undefined);
        }}
        onOk={() => {
          setCurrentLog(undefined);
        }}
      >
        <Row className={styles.modalLog} gutter={24}>
          <Col span={16}>
            <Row style={{ width: '100%' }} className={classNames(styles.header, styles.old)}>
              <Col className={styles.title} span={12}>
                字段
              </Col>
              <Col className={styles.title} span={12}>
                原始数据
              </Col>
            </Row>
            <Row style={{ width: '100%' }} className={styles.header}>
              <Col span={12}>
                <Row>
                  <Col span={24} className={styles.title}>
                    联系人CODE
                  </Col>
                  <Col span={24} className={styles.title}>
                    客户联系人
                  </Col>
                  <Col span={24} className={styles.title}>
                    客户联系人手机
                  </Col>
                  <Col span={24} className={styles.title}>
                    客户联系人电话
                  </Col>
                  <Col span={24} className={styles.title}>
                    客户联系人邮箱
                  </Col>
                  <Col span={24} className={styles.title}>
                    客户联系人QQ
                  </Col>
                  <Col span={24} className={styles.title}>
                    客户联系人所在部门
                  </Col>
                  <Col span={24} className={styles.title}>
                    客户联系人上级
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Row>
                  <Col span={24} className={styles.title}>
                    {beforeInfo?.code}
                  </Col>
                  <Col span={24} className={styles.title}>
                    {beforeInfo?.name}
                  </Col>
                  <Col span={24} className={styles.title}>
                    {beforeInfo?.cellphone ?? '--'}
                  </Col>
                  <Col span={24} className={styles.title}>
                    {beforeInfo?.telephone ?? '--'}
                  </Col>
                  <Col span={24} className={styles.title}>
                    {beforeInfo?.email ?? '--'}
                  </Col>
                  <Col span={24} className={styles.title}>
                    {beforeInfo?.qq ?? '--'}
                  </Col>
                  <Col span={24} className={styles.title}>
                    {beforeInfo?.dept ?? '--'}
                  </Col>
                  <Col span={24} className={styles.title}>
                    {beforeInfo?.parentName ?? '--'}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <Row className={classNames(styles.header, styles.new)}>
              <Col span={24} className={styles.title}>
                编辑后数据
              </Col>
            </Row>
            <Row className={styles.header}>
              <Col span={24} className={styles.title}>
                {afterInfo?.code}
              </Col>
              <Col
                span={24}
                className={classNames(styles.title, { [styles.change]: afterInfo?.name !== beforeInfo?.name })}
              >
                {afterInfo?.name}
              </Col>
              <Col
                span={24}
                className={classNames(styles.title, {
                  [styles.change]: afterInfo?.cellphone !== beforeInfo?.cellphone,
                })}
              >
                {afterInfo?.cellphone ?? '--'}
              </Col>
              <Col
                span={24}
                className={classNames(styles.title, {
                  [styles.change]: afterInfo?.telephone !== beforeInfo?.telephone,
                })}
              >
                {afterInfo?.telephone ?? '--'}
              </Col>
              <Col
                span={24}
                className={classNames(styles.title, { [styles.change]: afterInfo?.email !== beforeInfo?.email })}
              >
                {afterInfo?.email ?? '--'}
              </Col>
              <Col
                span={24}
                className={classNames(styles.title, { [styles.change]: afterInfo?.qq !== beforeInfo?.qq })}
              >
                {afterInfo?.qq ?? '--'}
              </Col>
              <Col
                span={24}
                className={classNames(styles.title, { [styles.change]: afterInfo?.dept !== beforeInfo?.dept })}
              >
                {afterInfo?.dept ?? '--'}
              </Col>
              <Col
                span={24}
                className={classNames(styles.title, { [styles.change]: afterInfo?.parentId !== beforeInfo?.parentId })}
              >
                {afterInfo?.parentName ?? '--'}
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default ContactPerson;
