import { FC, useEffect, useState } from 'react';
import { Button, Form, Modal, Radio, Select, Input, App } from 'antd';
import { FormInstance } from 'antd/es/form/hooks/useForm';
import {
  apiCarbonEmissionPropertySelectableListPost,
  CarbonEmissionPropertySelectableListPostResponse,
} from '@maxtropy/device-mgmt-apis';
import { isNil } from 'lodash-es';
import { MinusCircleOutlined } from '@ant-design/icons';
import styles from '../index.module.scss';
import { Field, Fields } from './BasicInfoTable';
import { useReadonly } from '../hooks';
import { typeValue } from '@/pages/EmissionFactor/type';

type DataLinkSetData = {
  fieldSource: Field['fieldSource']; // 字段来源(1:排放因子)
  fieldPropertyType: Field['fieldPropertyType']; // 字段属性类型(1燃料 2碳酸盐...)
  fieldPropertyId: Field['fieldPropertyId']; // 字段属性id，即数据库表单字段
  fieldFilterLogic: Field['fieldFilterLogic']; // 筛选逻辑
  filterExpression: Field['filterExpression']; // 筛选表达式
};

interface DataLinkSetModalProps {
  open: boolean;
  basicInfoTableData: Fields | undefined;
  basicInfoTableIndex: number | null | undefined;
  setOpen: (open: boolean) => void;
  confirm: (data: DataLinkSetData) => void;
}

const { Item } = Form;
const { TextArea } = Input;

// 数据库表单名称列表
const fieldSourceList = [{ id: 1, name: '排放因子' }];

const DataLinkSetModal: FC<DataLinkSetModalProps> = props => {
  const { open, setOpen, basicInfoTableData, basicInfoTableIndex, confirm } = props;
  const { message } = App.useApp();
  const readonly = useReadonly();
  const [filterModel, setFilterModel] = useState<string>('simple');
  const [form] = Form.useForm<DataLinkSetData>();
  // 简易模式下的类型列表
  const [logicFieldPropertyList, setLogicFieldPropertyList] =
    useState<CarbonEmissionPropertySelectableListPostResponse>([]);

  const simpleModelTableData = Form.useWatch('fieldFilterLogic', form);
  const setSimpleModelTableData = (data: SimpleModelRow[]) => {
    form.setFieldValue('fieldFilterLogic', data);
  };

  // 弹窗打开时回显数据
  useEffect(() => {
    if (!open) return;
    if (!basicInfoTableData || isNil(basicInfoTableIndex)) return;
    const data = basicInfoTableData[basicInfoTableIndex];
    form.setFieldsValue({
      fieldSource: data.fieldSource,
      fieldPropertyType: data.fieldPropertyType,
      fieldPropertyId: data.fieldPropertyId,
      fieldFilterLogic: data.fieldFilterLogic,
      filterExpression: data.filterExpression,
    });
    if (data.fieldPropertyType) {
      apiCarbonEmissionPropertySelectableListPost({ type: String(data.fieldPropertyType) }).then(res => {
        setLogicFieldPropertyList(res);
      });
    }
  }, [open]);

  const onOk = () => {
    validate().then(flag => {
      if (!flag) return;
      confirm({
        ...form.getFieldsValue(),
        fieldFilterLogic: simpleModelTableData,
      });
      setOpen(false);
    });
  };

  const validate = async () => {
    let flag = true;
    try {
      await form.validateFields();
    } catch (e) {
      flag = false;
    }
    if (filterModel === 'simple') {
      simpleModelTableData?.some(item => {
        if (!item.fieldPropertyId || !item.operateSign || !item.matchType || !item.matchValue) {
          flag = false;
          message.error('请完善筛选逻辑');
          return true;
        }
      });
    } else {
      if (!form.getFieldValue('filterExpression')) {
        flag = false;
        message.error('请填写筛选逻辑');
      }
    }

    return flag;
  };

  return (
    <Modal
      title="关联设置"
      width="732px"
      footer={readonly ? false : undefined}
      open={open}
      onCancel={() => setOpen(false)}
      onOk={onOk}
    >
      <Form layout="vertical" form={form}>
        <Item label="字段名称">{basicInfoTableData?.[basicInfoTableIndex!]?.fieldName}</Item>
        <Item
          label="数据库表单名称"
          name="fieldSource"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select placeholder="请选择数据库表单名称">
            {fieldSourceList.map(item => (
              <Select.Option value={item.id} key={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Item>
        <Item label="碳排因子类型" name="fieldPropertyType" rules={[{ required: true }]}>
          <Select
            placeholder="请选择碳排因子类型"
            onChange={value => {
              apiCarbonEmissionPropertySelectableListPost({ type: value }).then(res => {
                setLogicFieldPropertyList(res);
              });
              const newData = simpleModelTableData ? [...simpleModelTableData] : [];
              newData.forEach(item => {
                item.fieldPropertyId = undefined;
              });
              setSimpleModelTableData(newData);
              form.setFieldValue('fieldPropertyId', undefined);
            }}
          >
            {Object.entries(typeValue).map(([id, name]) => (
              <Select.Option value={Number(id)} key={Number(id)}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Item>
        <Item label="数据库表单字段" name="fieldPropertyId" rules={[{ required: true }]}>
          <Select placeholder="请选择数据库表单字段">
            {logicFieldPropertyList?.map(item => (
              <Select.Option value={item.id} key={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Item>
        <Item label="筛选逻辑">
          {/*todo：高级模式先不做，隐藏*/}
          <Radio.Group value={filterModel} onChange={e => setFilterModel(e.target.value)} style={{ marginBottom: 10 }}>
            <Radio.Button value="simple">简易模式</Radio.Button>
            {/*<Radio.Button value="advanced">高级模式</Radio.Button>*/}
          </Radio.Group>
          {filterModel === 'simple' ? (
            <SimpleModelTable
              form={form}
              fieldPropertyList={logicFieldPropertyList}
              basicInfoTableData={basicInfoTableData}
            ></SimpleModelTable>
          ) : (
            <Form.Item name="filterExpression" rules={[{ required: true, message: '请输入筛选逻辑' }]}>
              <TextArea autoSize={{ minRows: 5 }}></TextArea>
            </Form.Item>
          )}
        </Item>
      </Form>
    </Modal>
  );
};

type SimpleModelRow = NonNullable<Field['fieldFilterLogic']>[number];

interface SimpleModelTableProps {
  basicInfoTableData: Fields | undefined;
  fieldPropertyList: CarbonEmissionPropertySelectableListPostResponse | undefined;
  form: FormInstance;
}

enum OperateSign {
  EQUAL = 1,
  NOT_EQUAL = 2,
}

enum MatchType {
  MATCH_FIELD = 1,
  CUSTOM = 2,
}

const operateSignList = [
  { value: OperateSign.EQUAL, label: '等于' },
  { value: OperateSign.NOT_EQUAL, label: '不等于' },
];

const matchTypeList = [
  { value: MatchType.MATCH_FIELD, label: '匹配字段' },
  { value: MatchType.CUSTOM, label: '自定义' },
];

const SimpleModelTable: FC<SimpleModelTableProps> = props => {
  const { form, fieldPropertyList, basicInfoTableData } = props;

  const fieldFilterLogicList = Form.useWatch('fieldFilterLogic', { form, preserve: true });

  return (
    <div className={styles.simpleModelTable}>
      {(fieldFilterLogicList ?? []).map((item: SimpleModelRow, index: number) => (
        <div className={styles.row} key={index}>
          <Form.Item
            name={['fieldFilterLogic', index, 'fieldPropertyId']}
            rules={[{ required: true, message: '请选择类型' }]}
            className={styles.mb0}
          >
            <Select className={styles.rowItem} placeholder="请选择类型">
              {fieldPropertyList?.map(item => (
                <Select.Option value={item.id} key={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={['fieldFilterLogic', index, 'operateSign']} className={styles.mb0}>
            <Select className={styles.rowItem} options={operateSignList}></Select>
          </Form.Item>
          <Form.Item name={['fieldFilterLogic', index, 'matchType']} className={styles.mb0}>
            <Select
              className={styles.rowItem}
              options={matchTypeList}
              onChange={value => {
                const fieldFilterLogic = form.getFieldValue('fieldFilterLogic');
                fieldFilterLogic[index].matchValue = '';
                form.setFieldValue('fieldFilterLogic', fieldFilterLogic);
              }}
            ></Select>
          </Form.Item>
          {item.matchType === MatchType.MATCH_FIELD ? (
            <Form.Item
              name={['fieldFilterLogic', index, 'matchValue']}
              rules={[{ required: true, message: '请选择' }]}
              className={styles.mb0}
            >
              <Select className={styles.rowItem}>
                {basicInfoTableData?.map(item => (
                  <Select.Option value={item.fieldName} key={item.fieldName}>
                    {item.fieldName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : (
            <Form.Item
              name={['fieldFilterLogic', index, 'matchValue']}
              rules={[{ required: true, message: '请输入' }]}
              className={styles.mb0}
            >
              <Input className={styles.rowItem}></Input>
            </Form.Item>
          )}

          <MinusCircleOutlined
            className={styles.minusIcon}
            onClick={() => {
              let newData = [...form.getFieldValue('fieldFilterLogic')];
              newData.splice(index, 1);
              form.setFieldValue('fieldFilterLogic', newData);
            }}
          />

          <span
            className={styles.andText}
            style={{
              visibility: index === 0 ? 'hidden' : 'visible',
            }}
          >
            且
          </span>
        </div>
      ))}
      <Button
        type="dashed"
        style={{ width: '100%' }}
        onClick={() => {
          form.setFieldValue('fieldFilterLogic', [
            ...(form.getFieldValue('fieldFilterLogic') ?? []),
            {
              fieldPropertyId: undefined,
              operateSign: operateSignList[0].value,
              matchType: matchTypeList[0].value,
              matchValue: undefined,
            },
          ]);
        }}
      >
        新增且条件
      </Button>
    </div>
  );
};

export default DataLinkSetModal;
