import { Key, useEffect, useState } from 'react';
import { Tree, Form, Input, Space, Button, Spin, message } from 'antd';
import { DataNode } from 'antd/es/tree';
import {
  getPermissionTree,
  MiniAppPermissionFunctionTreeItem,
  deletePermission,
  updatePermission,
  createPermission,
} from '../../../api/cc-miniApp';
import TitleNode from './TitleNode';
import { ParentInfoProp, OperationProp } from '../types';
import styles from '../index.module.scss';

interface NewDataNode extends DataNode {
  code?: string;
}

const FormItem = Form.Item;

// 原始树结构转为tree组件的树结构
const formatTreeNode = (
  treeNodes: MiniAppPermissionFunctionTreeItem[],
  parentInfo: ParentInfoProp,
  operation: OperationProp
) => {
  const nodes = treeNodes?.map(item => {
    const node = {
      children: [] as DataNode[],
      key: item.id as Key,
      level: item.level,
      title: item.name,
      id: item.id,
      code: item.code,
      parentName: parentInfo?.name,
      parentId: parentInfo?.id,
    };

    if (item.children?.length) {
      node.children = formatTreeNode(item.children, { name: item.name, id: item.id }, operation);
    }

    return node;
  });

  return nodes;
};

const PermissionManage = ({ toolName, miniAppToolId }: { toolName?: string; miniAppToolId?: number }) => {
  // 右侧表单是否可编辑
  const [isDisabled, setDisabled] = useState<boolean>(false);
  // 是否一级导航，用来判断是否要展示父权限
  const [isRoot, setRoot] = useState<boolean>(true);
  const [originalData, setOriginalData] = useState<Record<string, string>>();
  const [treeData, setTreeData] = useState<NewDataNode[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const getPermissionFunctionTreeData = (isInit?: boolean) => {
    setLoading(true);
    getPermissionTree(miniAppToolId!)
      .then(res => {
        let data: any = [];
        if (res?.length) {
          data = formatTreeNode(res, {}, { onAdd, onRemove });
        } else {
          // 如果首次进来没有数据，默认取工具名称作为根节点
          if (isInit) {
            data = [
              {
                children: [] as DataNode[],
                level: 1,
                title: toolName,
                key: '0-0',
              },
            ];
          }
        }

        setTreeData(data);

        if (isInit) {
          form.setFieldsValue({
            name: data[0].title,
            code: data[0].code,
            id: data[0].id,
            parentId: data[0].parentId,
          });
          setOriginalData(data[0]);
          setRoot(true);
        }

        // 如果进入权限列表，根权限没有配置code，则右侧的表单默认是编辑状态，如果有code，右侧表单默认是查看状态
        setDisabled(!!data[0].code);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getPermissionFunctionTreeData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAdd = (id?: number, name?: string) => {
    form.setFieldsValue({
      parentName: name,
      code: '',
      name: '',
      id: undefined,
      parentId: id,
    });
    setDisabled(false);
    setRoot(false);
  };

  const onRemove = (id?: number) => {
    deletePermission(id!).then(() => {
      getPermissionFunctionTreeData(true);
    });
  };

  const onSelect = (_: any, item: Record<string, any>) => {
    const {
      node: { title, code, parentName, level, id, parentId },
    } = item;

    const data = {
      name: title,
      code,
      parentName,
      id,
      parentId,
    };

    form.setFieldsValue(data);

    setRoot(level === 1);

    // 选中的时候需要把原始数据保存一份，在取消编辑的时候做还原
    setOriginalData(data);
    setDisabled(true);
  };

  const onCancel = () => {
    if (isRoot && !treeData[0].code) {
      message.warning('根权限code必填');
      form.validateFields();
      return;
    }

    form.setFieldsValue(originalData);
    setDisabled(true);
  };
  // TODO:新增保存继续点击编辑，会出来一条新数据

  const onFinish = () => {
    const { code, name, id, parentId } = form.getFieldsValue(true);
    const fetchApi = id ? updatePermission : createPermission;

    fetchApi({
      code,
      name,
      id,
      miniAppToolId,
      parentPermissionId: parentId,
    }).then(res => {
      setDisabled(true);
      getPermissionFunctionTreeData();

      // 如果是新增，保存成功后需要给表单id赋值
      if (!id && res) {
        form.setFieldValue('id', res);
      }
    });
  };

  return (
    <div className={styles.permissionManage}>
      <div className={styles.permissionList}>
        <p className={styles.permissionTitle}>权限列表</p>
        <Spin spinning={loading}>
          <Tree
            onSelect={onSelect}
            className={styles.permissionTree}
            treeData={treeData}
            blockNode={true}
            disabled={!isDisabled}
            titleRender={nodeData => (
              <TitleNode disabled={isDisabled} nodeData={nodeData} operation={{ onAdd, onRemove }} />
            )}
          />
        </Spin>
      </div>
      <Form form={form} layout="vertical" className={styles.rightConfig} onFinish={onFinish}>
        {!isRoot && (
          <FormItem label="父权限" name="parentName">
            <Input placeholder="请输入" disabled={true} />
          </FormItem>
        )}
        <FormItem label="权限名称" name="name" rules={[{ required: true }, { min: 2 }, { max: 10 }]}>
          <Input placeholder="请输入" disabled={isRoot || isDisabled} />
        </FormItem>
        <FormItem
          label="权限code"
          name="code"
          rules={[
            { required: true },
            { pattern: /^[A-Za-z-]+$/g, message: '只能输入英文和-' },
            { min: 5, max: 50, message: '只能输入5-50个字符' },
          ]}
        >
          <Input placeholder="请输入" disabled={isDisabled} />
        </FormItem>
        <Space size={8}>
          {isDisabled && (
            <Button type="primary" onClick={() => setDisabled(false)}>
              编辑
            </Button>
          )}
          {!isDisabled && (
            <>
              <Button type="primary" htmlType="submit">
                保存
              </Button>
              <Button onClick={onCancel}>取消</Button>
            </>
          )}
        </Space>
      </Form>
    </div>
  );
};

export default PermissionManage;
