import { FC, useEffect, useState } from 'react';
import { Form, Input, Row, Col, InputNumber } from 'antd';
import { FactorPropertyEnum } from '../emissionProperty';
import ExtraFactor from './ExtraFactor';
import { TypeEnum } from '@/pages/EmissionFactor/type';

const FormItem = Form.Item;

interface Props {
  type?: TypeEnum;
}
const Electric: FC<Props> = ({ type }) => {
  const [CO2Id, setCO2Id] = useState<number>(FactorPropertyEnum.ElectricCO2EmissionFactor);
  const [unitId, setUnitId] = useState<number>(FactorPropertyEnum.ElectricUnit);
  useEffect(() => {
    if (type) {
      switch (type) {
        case TypeEnum.TRANSPORTATION:
          setCO2Id(FactorPropertyEnum.TRANSPORTATION_CO2EmissionFactor);
          break;
        case TypeEnum.GAS_DISPERSION:
          setCO2Id(FactorPropertyEnum.GAS_DISPERSION_CO2EmissionFactor);
          break;
        case TypeEnum.WASTE_DISPOSAL:
          setCO2Id(FactorPropertyEnum.WASTE_DISPOSAL_CO2EmissionFactor);
          break;
        case TypeEnum.EMPLOYEE_COMMUTING:
          setCO2Id(FactorPropertyEnum.EMPLOYEE_COMMUTING_CO2EmissionFactor);
          break;
      }
      setUnitId(FactorPropertyEnum.CommonUnit);
    }
  }, [type]);

  return (
    <>
      <Row gutter={16}>
        <Col span={8}>
          <FormItem label="CO2排放因子" name={['factorDetails', `${CO2Id}_1`]} rules={[{ required: true }]}>
            <InputNumber placeholder="请输入数值" style={{ width: '100%' }} />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            label="单位"
            name={['factorDetails', `${unitId}_2`]}
            rules={[{ max: 50, message: '长度不能大于50个字符' }]}
          >
            <Input placeholder="请输入" />
          </FormItem>
        </Col>
      </Row>
      <ExtraFactor start={3} />
    </>
  );
};

export default Electric;
