import { EllipsisSpan } from '@maxtropy/components';
import { Button, Form, Input, Radio, Select, Space, Table } from 'antd';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { ApiAuthoritiesProps } from '../../../../../../api/openPlatform-application';
import { queryUpstreamServiceList, UpstreamServiceListItem } from '../../../../../../api/openPlatform-upstreamService';
import InterfaceModal, { InterfaceModalRef } from '../InterfaceModal';
import styles from './index.module.scss';

interface InterfaceAuthProps {
  setInterfaceSelectedValues: React.Dispatch<React.SetStateAction<ApiAuthoritiesProps[]>>;
  interfaceSelectedValues?: ApiAuthoritiesProps[];
  selectedVisibleRange?: (visibleRange: VisibleRangeType) => void;
  visibleRangeValue?: VisibleRangeType;
}

interface SerachParams {
  serviceName?: string;
  interfaceName?: string;
}

const selectedColumns = [
  {
    title: '服务',
    dataIndex: 'serviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '调用方式',
    dataIndex: 'requestMethod',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '接口',
    dataIndex: 'requestUrl',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export interface InterfaceTableProps {
  serviceName: string;
  requestUrl: string;
  requestMethod: string;
}

// 可见范围类型
export enum VisibleRangeType {
  ALL = 1, // 全部租户可见
  PART = 2, // 部分租户可见
}

// 触发间隔类型的值
export const VisibleRangeValue = {
  [VisibleRangeType.ALL]: '全部租户可见',
  [VisibleRangeType.PART]: '部分租户可见',
};
// 触发间隔类型的值
export const VisibleInterfaceRangeValue = {
  [VisibleRangeType.ALL]: '全部接口可见',
  [VisibleRangeType.PART]: '部分接口可见',
};

const InterfaceAuth: FC<InterfaceAuthProps> = props => {
  const { setInterfaceSelectedValues, interfaceSelectedValues, selectedVisibleRange, visibleRangeValue } = props;
  const [form] = Form.useForm();
  const [selectInterfaceVisible, setSelectInterfaceVisible] = useState<boolean>(false);
  const addInterfaceRef = useRef<InterfaceModalRef>(null);
  const [search, setSearch] = useState<SerachParams>();
  const [isLoading, setIsLoading] = useState<boolean>(false); // 表格的loading
  const [serverList, setServerList] = useState<UpstreamServiceListItem[]>([]); // 服务模糊查询
  const [interfaceSelectedValuesTemp, setInterfaceSelectedValuesTemp] = useState<ApiAuthoritiesProps[]>([]); // 接口查询备份

  // 查询服务list
  useEffect(() => {
    queryUpstreamServiceList().then(setServerList);
  }, []);

  // 渲染已经所选择的数据
  useEffect(() => {
    if (interfaceSelectedValues) {
      setInterfaceSelectedValuesTemp(interfaceSelectedValues);
    }
  }, [interfaceSelectedValues]);

  // 点击查询的时候监听searchValues
  // prettier-ignore
  useEffect(() => { // NOSONAR
    setIsLoading(true);
    if (interfaceSelectedValues && interfaceSelectedValues.length) {
      if (search) {
        let searchArr: ApiAuthoritiesProps[] = [];
        interfaceSelectedValues.forEach(i => {
          if (search.serviceName && search.interfaceName) {
            if (i.serviceName.includes(search.serviceName) && i.requestUrl.includes(search.interfaceName)) {
              searchArr.push(i);
            }
          } else if (search.serviceName) {
            if (i.serviceName.includes(search.serviceName)) {
              searchArr.push(i);
            }
          } else if (search.interfaceName) {
            if (i.requestUrl.includes(search.interfaceName)) {
              searchArr.push(i);
            }
          } else {
            searchArr.push(i);
          }
        });
        setInterfaceSelectedValuesTemp(searchArr);
      } else {
        setInterfaceSelectedValuesTemp(interfaceSelectedValues);
      }
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleRet = () => {
    form.resetFields();
    setSearch(undefined);
  };

  // 移除
  const onRemove = (rowKey: string) => {
    if (interfaceSelectedValues && interfaceSelectedValues.length) {
      const newList = [...interfaceSelectedValues];
      const index = newList.findIndex(i => i.requestUrl + i.requestMethod === rowKey);
      newList.splice(index, 1);
      setInterfaceSelectedValues(newList);
    }
  };

  // 弹窗选择完租户的确定
  const onOk = () => {
    if (addInterfaceRef.current?.selectedRows.length) {
      let arr = (interfaceSelectedValues ?? []).concat(
        addInterfaceRef.current?.selectedRows.map(i => {
          return {
            serviceName: i.server!,
            requestMethod: i.method,
            requestUrl: i.path,
          };
        })
      );
      setInterfaceSelectedValues(arr);
    }
    setSelectInterfaceVisible(false);
  };

  const columns = {
    title: '操作',
    width: 200,
    fixed: 'right' as const,
    render: (v: any, record: InterfaceTableProps) => (
      <>
        <Space size={16}>
          <Button type="link" onClick={() => onRemove(record.requestUrl + record.requestMethod)}>
            移除
          </Button>
        </Space>
      </>
    ),
  };

  const serverOptions = useMemo(() => {
    if (serverList && serverList.length !== 0) {
      return serverList.map(i => ({ label: i.upstreamServiceName, value: i.upstreamServiceIdentifier }));
    }
  }, [serverList]);

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <span>可见范围：</span>
        <Radio.Group
          onChange={e => {
            selectedVisibleRange?.(e.target.value);
          }}
          value={visibleRangeValue}
        >
          <Radio value={VisibleRangeType.PART}>{VisibleInterfaceRangeValue[VisibleRangeType.PART]}</Radio>
          <Radio value={VisibleRangeType.ALL}>{VisibleInterfaceRangeValue[VisibleRangeType.ALL]}</Radio>
        </Radio.Group>
      </div>
      {visibleRangeValue === VisibleRangeType.PART && (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 6 }}>
            <Button
              type="primary"
              onClick={() => {
                setSelectInterfaceVisible(true);
              }}
            >
              选择接口
            </Button>
            <div style={{ width: '70%' }}>
              <Form
                form={form}
                onFinish={(v: SerachParams) => {
                  setSearch(v);
                }}
              >
                <div className={styles.ColStyle}>
                  <Space>
                    <Form.Item name="serviceName" label="服务">
                      <Select
                        style={{ width: 165 }}
                        placeholder="请选择服务"
                        options={serverOptions}
                        showSearch
                        optionFilterProp="label"
                      />
                    </Form.Item>
                    <Form.Item name="interfaceName" label="接口">
                      <Input style={{ width: 165 }} placeholder="请输入接口搜索" />
                    </Form.Item>
                    <Form.Item>
                      <Space>
                        <Button type="primary" htmlType="submit">
                          查询
                        </Button>
                        <Button onClick={handleRet}>重置</Button>
                      </Space>
                    </Form.Item>
                  </Space>
                </div>
              </Form>
            </div>
          </div>
          <Table
            loading={isLoading}
            sticky
            dataSource={interfaceSelectedValuesTemp}
            rowKey={record => `${record.requestUrl}${record.requestMethod}`}
            scroll={{ y: 300 }}
            columns={[...selectedColumns, columns]}
            pagination={false}
          />
        </>
      )}

      <InterfaceModal
        ref={addInterfaceRef}
        visible={selectInterfaceVisible}
        onOk={onOk}
        selectedData={interfaceSelectedValues}
        onCancel={() => setSelectInterfaceVisible(false)}
      />
    </>
  );
};

export default InterfaceAuth;
