import React, { useContext, useMemo } from 'react';
import { Button } from '@maxtropy/components';
import { PlusOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import { ActionType, DLT645Point, DataPointType, DataProperty } from '../../../../../types';
import DLT645PointBase from './DLT645PointBase';
import CreateForm from './CreateForm';
import { PointContext, PointContextProps } from '../../../../EdgeDevicePointInfo/contextTypes';
import Header from '../../../../EdgeDevicePointInfo/mockingbird/Header';
import AddIdentifier from '../../AddIdentifier';
import { BatchEdgeContent } from '../../../contentTypes';

export type EdgeDeviceTemplatePoint = DLT645Point & { actionType?: ActionType };

interface DLT645PointProps {
  editColumns?: ColumnsType<EdgeDeviceTemplatePoint>;
  onUpdate?: (values: any) => any;
  onAddIdentifier?: (values: any) => void;
  alterMode?: boolean;
  dataPropertiesAll?: DataProperty[];
  usedProperties?: number[];
  usedIdentifier?: string[];
}

const DLT645PointCom: React.FC<DLT645PointProps> = props => {
  const { editColumns, onUpdate, onAddIdentifier, alterMode, dataPropertiesAll, usedProperties, usedIdentifier } =
    props;

  const { row, setRow, info, promptSlot, editable } = useContext(
    PointContext
  ) as PointContextProps<EdgeDeviceTemplatePoint>;
  const { baseForm } = useContext(BatchEdgeContent);
  const { hasProperty } = baseForm?.getFieldsValue();

  const _row = useMemo(() => {
    if (row) {
      const { parameters, ...rest } = row;
      return {
        ...rest,
        ...parameters,
      };
    } else {
      return row;
    }
  }, [row]);

  const onOk = (values: any) => {
    const {
      dataPropertyId,
      dataPropertyName,
      physicalUnitId,
      physicalUnitGeneralName,
      identifier,
      hasProperty,
      writable,
      remark,
      ...rest
    } = values;
    const submitValues = {
      pointType: DataPointType.BASE_POINT,
      dataPropertyId,
      dataPropertyName,
      physicalUnitId,
      physicalUnitGeneralName,
      identifier,
      hasProperty,
      writable,
      remark,
      parameters: {
        driveType: info?.driveType,
        pointType: DataPointType.BASE_POINT,
        ...rest,
      },
    };
    if (onUpdate) {
      onUpdate(submitValues);
    }
  };

  return (
    <>
      <Header slot={promptSlot}>
        {!alterMode && editable && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() =>
              setRow?.({
                pointType: DataPointType.BASE_POINT,
              })
            }
          >
            添加采集点
          </Button>
        )}
        {alterMode && editable && hasProperty === false && (
          <AddIdentifier
            usedIdentifier={usedIdentifier!}
            submit={identifier =>
              onAddIdentifier?.({ identifier, hasProperty: false, pointType: DataPointType.BASE_POINT })
            }
          />
        )}
      </Header>

      <DLT645PointBase editColumns={editColumns} alterMode={alterMode} />
      <CreateForm
        onCancel={() => setRow?.(undefined)}
        row={_row}
        dataPropertiesAll={dataPropertiesAll}
        usedProperties={usedProperties}
        usedIdentifier={usedIdentifier}
        onOk={onOk}
        alterMode={alterMode}
      />
    </>
  );
};

export default DLT645PointCom;
