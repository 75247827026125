import React, { FC, Key, useCallback, useEffect, useMemo, useState } from 'react';
import { Badge, Button, Col, ConfigProvider, Empty, Form, Input, message, Modal, Select, Space } from 'antd';
import {
  Wrapper,
  Filter,
  Table,
  Paging,
  usePaging,
  EllipsisSpan,
  SearchInput,
  SearchInputOptionProps,
} from '@maxtropy/components';
import { FixedType } from 'rc-table/es/interface';
import styles from './index.module.scss';

import {
  disableStaff,
  disableBatchStaff,
  enableStaff,
  freezeStaff,
  getStaffList,
  StaffBaseRequest,
  StaffResponse,
  batchReassignStaff,
} from '../../../../api/cc-staff';
import { UserStatus, UserStatusDisplay } from '../../../../api/cc-const';
import { Link } from 'react-router-dom';
import { getListCustomer } from '../../../../api/cc-customer';
import dayjs from 'dayjs';
import { useHasPermission } from '../../../../utils/hooks';
import { PermissionsType } from '../../../../common/permissionsConst';
import { PlusOutlined } from '@ant-design/icons';

export interface PageRequest {
  offset?: number;
  count?: number;
}

export const BadgeStatusDisplayColor = {
  [UserStatus.ENABLE]: '#52C41A',
  [UserStatus.DISABLE]: '#F5222D',
  [UserStatus.FREEZE]: '#F7B500',
};

const routes = [{ name: '租户管理' }, { name: '租户组织权限和账户' }, { name: '租户员工工号管理' }];

const columns = [
  {
    title: '用户ID',
    dataIndex: 'customerUserHumanCode',
    ellipsis: { showTitle: true },
    width: 120,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '用户登录账号',
    dataIndex: 'customerUserUsername',
    ellipsis: { showTitle: true },
    width: 150,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所在组织简称',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    width: 150,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所在组织全称',
    dataIndex: 'customerFullName',
    ellipsis: { showTitle: true },
    width: 150,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '用户姓名',
    dataIndex: 'customerUsername',
    ellipsis: { showTitle: true },
    width: 120,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '手机号',
    dataIndex: 'customerUserCellphone',
    ellipsis: { showTitle: true },
    width: 120,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '员工工号',
    dataIndex: 'staffCode',
    ellipsis: { showTitle: true },
    width: 120,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '角色',
    dataIndex: 'roleName',
    ellipsis: { showTitle: true },
    width: 120,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '员工工号状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    width: 120,
    render: (value: UserStatus, record: StaffResponse) => {
      return <EllipsisSpan value={<Badge color={BadgeStatusDisplayColor[value]} text={UserStatusDisplay[value]} />} />;
    },
  },
  {
    title: '所属企业',
    dataIndex: 'rootTenantName',
    ellipsis: { showTitle: true },
    width: 120,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '最后修改时间',
    dataIndex: 'updateTime',
    width: 180,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
];

const batchColumns = [
  {
    title: '原所属用户ID',
    dataIndex: 'customerUserHumanCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '原所属用户登录账号',
    dataIndex: 'customerUserUsername',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '原所属用户手机号',
    dataIndex: 'customerUserCellphone',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '员工账号ID',
    dataIndex: 'id',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所在简称组织',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所在简称全称',
    dataIndex: 'customerFullName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所在企业',
    dataIndex: 'rootTenantName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export type FilterParams = Omit<StaffBaseRequest, 'count' | 'offset'>;

const TenantEmployeeList: FC = () => {
  const [modalApi, modalContextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();
  const status = useHasPermission(PermissionsType.BSTAFFREASSIGN);
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
  const [search, setSearch] = useState<FilterParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const [opened, setOpened] = useState<boolean>(false);
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [tableSource, setTableSource] = useState<StaffResponse[]>([]);

  const [selectedRowRecords, setSelectedRowRecords] = useState<StaffResponse[]>([]);
  const [operationPassword, setOperationPassword] = useState<string>('');

  const [batchReassignSelectedRowRecords, setBatchReassignSelectedRowRecords] = useState<StaffResponse[]>([]);

  const [option, setOption] = useState<SearchInputOptionProps[]>([]);
  const [muid, setMuid] = useState<string>();
  const [passwordOpened, setPasswordOpened] = useState<boolean>(false);

  // const [resultModalChildren, setResultModalChildren] = useState<React.ReactNode>();

  const onSelectChange = (keys: Key[], slectRecords: StaffResponse[]) => {
    setSelectedRowKeys(keys as number[]);
  };

  const getSelectedRowRecords = useCallback(() => {
    if (!selectedRowKeys) return [];
    return tableSource.filter(i => selectedRowKeys.includes(i.id));
  }, [selectedRowKeys, tableSource]);

  const onBatchReassignSelectChange = (keys: Key[], slectRecords: StaffResponse[]) => {
    setBatchReassignSelectedRowRecords(slectRecords);
  };

  const batchReassignSelectedRowKeys = useMemo(() => {
    if (!batchReassignSelectedRowRecords) return [];
    return batchReassignSelectedRowRecords.map(i => i.id);
  }, [batchReassignSelectedRowRecords]);

  const batchButtonDisable = useMemo(() => {
    if (!selectedRowKeys || selectedRowKeys.length === 0) return true;
    return getSelectedRowRecords().some(i => i.status === UserStatus.DISABLE);
  }, [selectedRowKeys, getSelectedRowRecords]);

  const getStaff = (page: PageRequest) => {
    if (!search) return;
    setLoading(true);
    getStaffList({ ...search, count: pageSize, offset: pageOffset, ...page })
      .then(res => {
        setTotalCount(res.total);
        setPageOffset(res.offset);
        setSelectedRowRecords([]);
        setTableSource(res.contents);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (search) {
      getStaff({ offset: 1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    getStaff({ offset: pageOffset });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset]);

  useEffect(() => {
    getStaff({ offset: 1, count: pageSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize]);

  const onSuccess = useCallback((content: React.ReactNode) => {
    return new Promise(resolve => {
      modalApi.success({
        title: '操作成功',
        className: styles.modal,
        content,
        icon: null,
        okText: '关闭',
        onOk: () => {
          resolve(undefined);
        },
        onCancel: () => {
          resolve(undefined);
        },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDisable = (record: StaffResponse) => {
    modalApi.confirm({
      title: <p>禁用账号</p>,
      icon: null,
      className: styles.modal,
      content: <div>即将禁用员工账号{record.username}, 点击确认以操作该执行</div>,
      onOk: () => {
        return disableStaff(record.id)
          .then(res => {
            if (res) {
              return onSuccess(<div>已禁用账号{record.username}</div>);
            }
          })
          .then(() => {
            getStaff({});
            // return;
          });
      },
    });
  };

  const onDisableBatch = () => {
    modalApi.confirm({
      title: <p>禁用账号</p>,
      icon: null,
      className: styles.modal,
      content: (
        <div>即将禁用员工账号{(selectedRowRecords ?? []).map(i => i.username).join(',')}, 点击确认以操作该执行</div>
      ),
      onOk: () => {
        return disableBatchStaff(selectedRowKeys)
          .then(res => {
            if (res) {
              return onSuccess(<div>已禁用账号{(selectedRowRecords ?? []).map(i => i.username).join(',')}</div>);
            }
          })
          .then(() => {
            getStaff({});
            setSelectedRowKeys([]);
            // return;
          });
      },
    });
  };

  const onFreeze = (record: StaffResponse) => {
    modalApi.confirm({
      title: <p>冻结账号</p>,
      icon: null,
      className: styles.modal,
      content: <div>即将冻结员工账号{record.username}, 点击确认以操作该执行</div>,
      onOk: close => {
        return freezeStaff(record.id)
          .then(res => {
            if (res) {
              return onSuccess(<div>已冻结账号{record.username}</div>);
            }
          })
          .then(() => {
            getStaff({});
            // return;
          });
      },
    });
  };

  const onEnable = (record: StaffResponse) => {
    modalApi.confirm({
      title: <p>启用账号</p>,
      icon: null,
      className: styles.modal,
      content: <div>即将启用员工账号{record.username}, 点击确认以操作该执行</div>,
      onOk: close => {
        return enableStaff(record.id)
          .then(res => {
            if (res) {
              return onSuccess(<div>已启用账号{record.username}</div>);
            }
          })
          .then(() => {
            getStaff({});
            // return;
          });
      },
    });
  };

  const onBatchReassignStaff = (isMultiple: boolean = true) => {
    if (isMultiple) {
      setSelectedRowRecords(getSelectedRowRecords());
    }
    setOpened(true);
  };

  useEffect(() => {
    if (!opened) {
      setSelectedRowRecords([]);
    }
  }, [opened]);

  // useEffect(() => {
  //   if (operation) {
  //     switch (operation.operate) {
  //       case "disable":
  //         setResultModalChildren(<>即将启用用户{(operation.data as StaffResponse).username}(用户ID：{(operation.data as StaffResponse).username})，点击确定以执行该操作</>)
  //     }
  //   }
  // }, [operation])

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      fixed: 'right' as FixedType,
      width: 350,
      render: (value: undefined, record: StaffResponse) => {
        return (
          <Space size={16}>
            {record.status === UserStatus.ENABLE ? (
              <>
                <Button
                  type="link"
                  onClick={() => {
                    onDisable(record);
                  }}
                >
                  禁用
                </Button>
                <Button
                  type="link"
                  onClick={() => {
                    onFreeze(record);
                  }}
                >
                  冻结
                </Button>
              </>
            ) : (
              <Button
                type="link"
                onClick={() => {
                  onEnable(record);
                }}
              >
                启用
              </Button>
            )}
            {status && (
              <Button
                type="link"
                onClick={() => {
                  setSelectedRowRecords([record]);
                  onBatchReassignStaff(false);
                }}
              >
                绑定给他人
              </Button>
            )}
            <Link target="_blank" to={`/tenant/customer/employee/${record.id}`}>
              查看详情
            </Link>
          </Space>
        );
      },
    },
  ];

  const onCustomerSearch = (value: string) => {
    // console.log(value)
    if (value) {
      getListCustomer(value).then(res => {
        setOption(
          res.map(i => ({
            name: `${i.humanCode} ${i.name} ${i.cellphone}`,
            value: i.muid,
          }))
        );
      });
    }
  };

  const children = (
    <>
      <Form>
        <Form.Item>
          <SearchInput
            style={{ width: 500 }}
            timer={300}
            onSearch={onCustomerSearch}
            placeholder="输入希望绑定到的用户手机号/完整用户ID/完整用户姓名"
            option={option}
            onChange={(value: string) => {
              setMuid(value);
            }}
            // autoComplete="off"
          />
        </Form.Item>
      </Form>
      <Table
        rowKey="id"
        rowSelection={{ selectedRowKeys: batchReassignSelectedRowKeys, onChange: onBatchReassignSelectChange }}
        dataSource={selectedRowRecords}
        columns={batchColumns}
      />
    </>
  );

  const onBatchReassignStaffOk = () => {
    if (!muid) {
      messageApi.warning('请先选择用户!');
      return;
    }
    if (!batchReassignSelectedRowKeys.length) {
      messageApi.warning('请先勾选需要换绑的员工!');
      return;
    }
    setPasswordOpened(true);
  };

  useEffect(() => {
    if (!passwordOpened) {
      setOperationPassword('');
    }
  }, [passwordOpened]);

  useEffect(() => {
    if (!opened) {
      setBatchReassignSelectedRowRecords([]);
    }
  }, [opened]);

  const onPasswordOk = () => {
    if (operationPassword && batchReassignSelectedRowKeys.length && muid) {
      return batchReassignStaff({
        muid: muid,
        staffIds: batchReassignSelectedRowKeys,
        operationPassword: operationPassword,
      })
        .then(res => {
          if (res) {
            return onSuccess(<div>绑定成功</div>);
          }
        })
        .then(() => {
          setOperationPassword('');
          setSelectedRowKeys([]);
          setBatchReassignSelectedRowRecords([]);
          setPasswordOpened(false);
          setOpened(false);
          //  将选中的muid清空
          getStaff({});
          // return;
        });
    } else {
      return Promise.reject();
    }
  };

  const onFinish = (value: FilterParams) => {
    setSearch({ ...value });
  };

  const onReset = () => {
    setSearch({});
  };

  const [searchForm] = Form.useForm();

  const filters = (
    <Filter<FilterParams>
      form={searchForm}
      onFinish={onFinish}
      onReset={onReset}
      hasClear
      collapseItems={
        <>
          <Col span={9}>
            <Form.Item name="status" label="用户状态">
              <Select placeholder="请选择状态">
                <Select.Option value={UserStatus.ENABLE}>{UserStatusDisplay[UserStatus.ENABLE]}</Select.Option>
                <Select.Option value={UserStatus.DISABLE}>{UserStatusDisplay[UserStatus.DISABLE]}</Select.Option>
                <Select.Option value={UserStatus.FREEZE}>{UserStatusDisplay[UserStatus.FREEZE]}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="staffStatus" label="员工工号状态">
              <Select placeholder="请选择员工工号状态">
                <Select.Option value={UserStatus.ENABLE}>{UserStatusDisplay[UserStatus.ENABLE]}</Select.Option>
                <Select.Option value={UserStatus.DISABLE}>{UserStatusDisplay[UserStatus.DISABLE]}</Select.Option>
                <Select.Option value={UserStatus.FREEZE}>{UserStatusDisplay[UserStatus.FREEZE]}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="cellphone" label="手机号">
              <Input placeholder="请输入完整用户手机号" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="customer" label="所在组织">
              <Input placeholder="请输入所在组织" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="rootCustomer" label="组织所在企业">
              <Input placeholder="请输入组织所在企业" />
            </Form.Item>
          </Col>
        </>
      }
    >
      <>
        <Col span={5}>
          <Form.Item name="humanCode" label="用户ID">
            <Input placeholder="请输入用户ID" />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="username" label="用户登录账号">
            <Input placeholder="请输入用户登录账号" />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item name="staffUsername" label="员工工号">
            <Input placeholder="请输入员工工号" />
          </Form.Item>
        </Col>
      </>
    </Filter>
  );

  return (
    <Wrapper routes={routes} filters={filters}>
      <div className={styles.operationArea}>
        <Space>
          <Button disabled={batchButtonDisable} onClick={onDisableBatch}>
            批量禁用员工
          </Button>
          {status && (
            <Button
              disabled={!selectedRowKeys.length}
              onClick={() => {
                onBatchReassignStaff();
              }}
            >
              批量绑定到其他用户
            </Button>
          )}
          <Button type="primary">
            <Link to="/tenant/customer/employee/create">
              <PlusOutlined />
              新建员工
            </Link>
          </Button>
        </Space>
      </div>
      <ConfigProvider
        renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="点击【查询】按钮后展示数据" />}
      >
        <Table
          sticky
          rowKey="id"
          loading={loading}
          rowSelection={{ selectedRowKeys, onChange: onSelectChange, fixed: true }}
          dataSource={tableSource}
          columns={buildColumns}
          scroll={{ x: '100vw' }}
        />
      </ConfigProvider>
      <Paging pagingInfo={pagingInfo} />
      <Modal
        width={800}
        title={<span style={{ color: 'red' }}>变更员工账号所绑定的账户</span>}
        children={children}
        open={opened}
        onCancel={() => setOpened(false)}
        onOk={onBatchReassignStaffOk}
      />
      <Modal
        width={350}
        title="请输入关键操作密码"
        children={
          <Space direction="vertical" style={{ width: '100%', padding: '20px 0' }}>
            <Input.Password
              autoComplete="new-password"
              value={operationPassword}
              placeholder="请输入操作密码"
              onChange={e => {
                setOperationPassword(e.target.value);
              }}
            />
            <a target="_blank" href="/user/settings/operatePassword">
              忘记密码或是未设置？
            </a>
          </Space>
        }
        open={passwordOpened}
        onCancel={() => setPasswordOpened(false)}
        onOk={onPasswordOk}
      />
      {messageContextHolder}
      {modalContextHolder}
    </Wrapper>
  );
};

export default TenantEmployeeList;
