import React, { ReactNode } from 'react';
import { Steps, StepsProps } from 'antd';
import './index.scss';

const { Step } = Steps;

interface CommonStepsProps extends StepsProps {
  children?: ReactNode;
}

const CommonSteps: React.FC<CommonStepsProps> = props => {
  const prefixCls = 'mgmt-steps';
  return (
    <div className={`${prefixCls}-wrapper`}>
      <Steps {...props}>{props.children}</Steps>
    </div>
  );
};

export default CommonSteps;

export const RoleSteps: React.FC<CommonStepsProps> = props => {
  return (
    <CommonSteps {...props} style={{ width: 500 }}>
      <Step title="角色基本信息" />
      <Step title="角色权限" />
    </CommonSteps>
  );
};
