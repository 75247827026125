import { FC, useState, useEffect } from 'react';
import { Button, Badge, Space, Divider, Form, Col, Input, Select } from 'antd';
import { Wrapper, Filter, Table, Paging, usePaging, EllipsisSpan } from '@maxtropy/components';
import { TenantStatus, TenantStatusDisplay } from '../../api/cc-const';
import styles from './index.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

import { getTenantList, TenantPageResponse, TenantRequest } from '../../api/cc-tenant';
import { apiV2TenantCheckMaxNumPost } from '@maxtropy/cc-mgmt-apis-v2';

const routes = [{ name: '租户管理' }, { name: '租户组织权限和账户' }, { name: '租户管理' }];

export const BadgeTenantStatusDisplayColor = {
  [TenantStatus.OPENACCOUNT]: '#52C41A',
  [TenantStatus.NOACCOUNT]: '#F5222D',
};

export const getBadgeTenantStatusDisplayColor = (value: boolean) => {
  return value
    ? BadgeTenantStatusDisplayColor[TenantStatus.OPENACCOUNT]
    : BadgeTenantStatusDisplayColor[TenantStatus.NOACCOUNT];
};

export const getTenantStatusDisplay = (value: boolean) => {
  return value ? TenantStatusDisplay[TenantStatus.OPENACCOUNT] : TenantStatusDisplay[TenantStatus.NOACCOUNT];
};

const columns = [
  {
    title: '租户CODE',
    dataIndex: 'tenantCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '租户名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '统一社会信用代码',
    dataIndex: 'uscc',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '租户状态',
    dataIndex: 'accountOpenStatus',
    ellipsis: { showTitle: true },
    render: (value: boolean, record: any) => {
      return (
        <EllipsisSpan
          value={<Badge color={getBadgeTenantStatusDisplayColor(value)} text={getTenantStatusDisplay(value)} />}
        />
      );
    },
  },
];

export type FilterParams = Omit<TenantRequest, 'count' | 'offset'>;

const List: FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<TenantPageResponse[]>([]);
  const [search, setSearch] = useState<FilterParams>();

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const getData = (page?: TenantRequest) => {
    setLoading(true);
    getTenantList({ ...search, count: pageSize, offset: pageOffset, ...page })
      .then(res => {
        setTotalCount(res.total);
        setPageOffset(res.offset);
        setData(res.contents);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!search) return;
    getData({ offset: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    getData({ offset: pageOffset });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, pageSize]);

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as const,
      width: 240,
      dataIndex: 'operation',
      render: (value: undefined, record: any) => {
        return (
          <Space size={16}>
            <Button style={{ paddingLeft: 0, paddingRight: 0 }} type="link">
              <Link to={`/tenant/customer/manage/detail/${record.mcid}`}>查看租户信息</Link>
            </Button>
            <Button type="link" style={{ paddingLeft: 0, paddingRight: 0 }}>
              <Link to={`/tenant/customer/manage/edit/${record.mcid}`}>编辑租户信息</Link>
            </Button>
          </Space>
        );
      },
    },
  ];

  const onFinish = (value: FilterParams) => {
    setSearch(value);
  };

  const onReset = () => {
    setSearch({});
  };

  const filters = (
    <Filter<FilterParams>
      form={form}
      onFinish={onFinish}
      onReset={onReset}
      hasClear
      collapseItems={
        <>
          <Col span={6}>
            <Form.Item name="uscc" label="统一社会信用代码">
              <Input placeholder="输入完整的统一社会信用代码" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="accountOpenStatus" label="租户状态">
              <Select placeholder="请选择租户状态">
                <Select.Option value={undefined}>全部</Select.Option>
                <Select.Option value={TenantStatus.NOACCOUNT}>
                  {TenantStatusDisplay[TenantStatus.NOACCOUNT]}
                </Select.Option>
                <Select.Option value={TenantStatus.OPENACCOUNT}>
                  {TenantStatusDisplay[TenantStatus.OPENACCOUNT]}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </>
      }
    >
      <>
        <Col span={6}>
          <Form.Item name="tenantCode" label="租户CODE">
            <Input placeholder="输入完整的租户CODE" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="name" label="租户名称">
            <Input placeholder="请输入全部/部分租户名称" />
          </Form.Item>
        </Col>
      </>
    </Filter>
  );

  const onNavigateCreateTenant = () => {
    apiV2TenantCheckMaxNumPost().then(_ => {
      navigate('/tenant/customer/manage/create');
    });
  };

  return (
    <Wrapper routes={routes} filters={filters}>
      <div className={styles.operationArea}>
        <Space>
          <Button type="primary" onClick={onNavigateCreateTenant}>
            <PlusOutlined />
            新建租户
          </Button>
        </Space>
      </div>
      <Table rowKey="id" sticky scroll={{ x: 1200 }} loading={loading} dataSource={data} columns={buildColumns} />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default List;
