import React, { MutableRefObject, useEffect, useState } from 'react';
import { Button, Col, Form, message, Modal, Row, Select, Space } from 'antd';
import FormText from '../common/FormText';
import TemplateModal, { TemplateModalRef } from '../estun/TemplateModal';
import { DetailsProps } from '../factory';
import TemplateVersionModal, { TemplateVersionModalRef } from '../estun/TemplateVersionModal';
import styles from '../index.module.scss';
import { getEdgeTemplateDetail } from '../../../api/template';
import { DriveType, DriveTypeLabels } from '@/shared/types';
import { MockingbirdFormValues } from '@/shared/components/DataAcquisitionForm/types';
import { halfFormItemLayout } from '@/shared/components/DataAcquisitionForm/common';
import SharedMockingbirdForm from '@/shared/components/DataAcquisitionForm/mockingbird/Form';
import useIsGateway from '@/shared/hooks/useIsGateway';

const MockingbirdForm: React.FC<DetailsProps<MockingbirdFormValues>> = ({ form, state, isBatch }) => {
  const templateListModalRef: MutableRefObject<TemplateModalRef | null> = React.useRef(null);
  const [templateListVisible, setTemplateListVisible] = useState<boolean>(false);
  const { setTemplateVersionId, templateVersionId, driveType, setDriveType, deviceType } = state;
  const versionListModalRef: MutableRefObject<TemplateVersionModalRef | null> = React.useRef(null);
  const [versionListVisible, setVersionListVisible] = useState<boolean>(false);
  const { isGateway } = useIsGateway(deviceType);
  const [messageApi, messageContextHolder] = message.useMessage();

  useEffect(() => {
    if (templateVersionId) {
      getEdgeTemplateDetail(+templateVersionId).then(data => {
        form.setFieldsValue(data);
      });
    }
  }, [templateVersionId, form]);

  const hideTemplateListModal = () => setTemplateListVisible(false);

  const handleTemplateListModal = async () => {
    const templateVersionId = templateListModalRef?.current?.selectedRowKeys;
    if (templateVersionId) {
      setTemplateVersionId(templateVersionId);
    }
    setTemplateListVisible(false);
  };

  const hideVersionListModal = () => setVersionListVisible(false);

  const handleVersionListModal = () => {
    const templateVersionId = versionListModalRef?.current?.selectedRowKeys;
    if (templateVersionId) {
      setTemplateVersionId(templateVersionId);
    }
    setVersionListVisible(false);
  };

  const handleDriveTypeChange = (value: number) => {
    setDriveType(value);
    setTemplateVersionId(undefined);
    form?.resetFields();
    form?.setFieldsValue({ driveType: value });
  };

  return (
    <>
      <div className={styles.mockingbirdForm}>
        {!isGateway && (
          <Row>
            <Col span={12}>
              <Form.Item
                {...halfFormItemLayout}
                label="驱动类型"
                name="driveType"
                rules={[{ required: true, message: '请选择驱动类型' }]}
                initialValue={DriveType.MODBUS_RTU}
              >
                <Select<DriveType> placeholder="请选择" onChange={handleDriveTypeChange}>
                  {Object.entries(DriveTypeLabels).map(([key, value]) => (
                    <Select.Option key={key} value={Number(key)}>
                      {value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}

        {(driveType !== undefined || isGateway) && (
          <Row>
            <Col span={12}>
              <Form.Item {...halfFormItemLayout} required label={'数采模板'}>
                <Space>
                  <Form.Item name={'name'} noStyle rules={[{ required: true, message: '请选择数采模板' }]}>
                    {templateVersionId && <FormText />}
                  </Form.Item>
                  <Button
                    type="link"
                    onClick={() =>
                      state.deviceType ? setTemplateListVisible(true) : messageApi.info('请选择所属类目')
                    }
                  >
                    {templateVersionId ? '重新选择' : '请选择'}
                  </Button>
                </Space>
              </Form.Item>
            </Col>
            {templateVersionId && (
              <>
                <Col span={12}>
                  <Form.Item {...halfFormItemLayout} name={'remark'} label={'模板备注'}>
                    <FormText />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item {...halfFormItemLayout} required label={'模板版本号'}>
                    <Space>
                      <Form.Item name={'version'} noStyle rules={[{ required: true, message: '请选择模板版本号' }]}>
                        <FormText className={styles.version} />
                      </Form.Item>
                      <Button type="link" onClick={() => setVersionListVisible(true)}>
                        重新选择
                      </Button>
                    </Space>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item {...halfFormItemLayout} name={'versionRemark'} label={'版本备注'}>
                    <FormText />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        )}
        {driveType !== undefined && !isGateway && <SharedMockingbirdForm form={form} />}
      </div>
      <Modal
        wrapClassName={styles.customModal}
        width={1000}
        title="模板选择"
        open={templateListVisible}
        destroyOnClose
        onOk={handleTemplateListModal}
        onCancel={hideTemplateListModal}
      >
        <TemplateModal state={state} ref={templateListModalRef} />
      </Modal>
      <Modal
        wrapClassName={styles.customModal}
        width={1000}
        title="版本选择"
        open={versionListVisible}
        destroyOnClose
        onOk={handleVersionListModal}
        onCancel={hideVersionListModal}
      >
        <TemplateVersionModal state={state} ref={versionListModalRef} />
      </Modal>
      {messageContextHolder}
    </>
  );
};

export default MockingbirdForm;
