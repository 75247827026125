import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Form, Input } from 'antd';
import { Rule } from 'antd/lib/form';
import styles from './index.module.scss';
import { Table } from '@maxtropy/components';

interface Iporps {
  formName: string; // formItem的name
  cls: TableItem[]; //列属性设置
  scroll?: object;
}

// 列参数
interface TableItem {
  title: string;
  name: string; // 表单绑定值
  dataIndex: string; // 表格字段
  elementType?: string; // 渲染元素类型
  render?: RenderFunc; // 渲染函数
  rules?: Rule[]; // 表单校验规则
  onCell?: (record: any, index: any) => any;
}

interface RenderFunc {
  (text: string, record: any, index: number): JSX.Element;
}
interface RenderTypeFunc {
  (record: any, index: number, item: TableItem): JSX.Element;
}

type remove = (index: number | number[]) => void;
type add = (defaultValue?: any, insertIndex?: number | undefined) => void;

export interface RefListTableProps {
  addRow?: add;
  removeRow?: remove;
}
const ListTableForm = forwardRef<RefListTableProps, Iporps>((props, ref) => {
  const [cls, setCls] = useState<TableItem[]>([]);
  // 渲染元素类型
  const renderType: RenderTypeFunc = (record, index, item) => {
    const { elementType } = item;
    switch (elementType) {
      case 'input':
        return (
          <Form.Item rules={item.rules} name={[record.name, item.name]} fieldKey={[record.fieldKey, item.name]}>
            <Input placeholder="请输入" />
          </Form.Item>
        );
      case 'text':
      default:
        return (
          <Form.Item shouldUpdate={true}>
            {({ getFieldValue }) => {
              return (getFieldValue(props.formName) || [])?.[index]?.[item?.name];
            }}
          </Form.Item>
        );
    }
  };

  useEffect(() => {
    const _newProps = props.cls.map(item => {
      const { render, ...resetProps } = item;
      return {
        ...resetProps,
        render: (text: string, record: any, index: number) => {
          if (render) {
            return render(text, record, index);
          } else {
            return renderType(record, index, item);
          }
        },
      };
    });
    setCls(_newProps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cls]);
  const addFunc = useRef<add>();
  const removeFunc = useRef<remove>();
  useImperativeHandle(ref, () => ({
    addRow: addFunc.current,
    removeRow: removeFunc.current,
  }));
  return (
    <>
      <Form.List name={props.formName || 'tableForm'}>
        {(fields, { add, remove }) => {
          addFunc.current = add;
          removeFunc.current = remove;
          return (
            <>
              <div className={styles.tableForm}>
                <Table bordered rowKey="name" scroll={props.scroll} dataSource={fields} columns={cls} />
              </div>
            </>
          );
        }}
      </Form.List>
    </>
  );
});

export default ListTableForm;
