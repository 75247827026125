import { useLocation } from 'react-router-dom';
import { useState } from 'react';

const useReadonly = () => {
  const location = useLocation();
  const [readonly] = useState(location.pathname.includes('/detail/'));
  return readonly;
};

export { useReadonly };
