import { Table, Paging, usePaging } from '@maxtropy/components';
import { Form, Col, Input } from 'antd';
import { FC, Key, useEffect, useMemo, useState } from 'react';
import Filter from '@/shared/components/Filter';

import styles from './index.module.scss';
import {
  AlrmPushStaffPageResponse,
  AlarmPushStaffPageRequest,
  getAlarmPushStaffPage,
  PushCenterStaffLinkVoList,
} from '../../../../api/deviceAlarmPushStrategy';
import { columns } from './util';
import { PushThirdApp } from '@/shared/types';

export interface PushStaffModalProps {
  value?: PushCenterStaffLinkVoList[];
  onChange?: (value: PushCenterStaffLinkVoList[]) => void;
  ouId?: string;
  disabledIds?: Key[];
}

type SearchParams = Omit<AlarmPushStaffPageRequest, 'size' | 'page' | 'ouId'>;

const PushStaffModal: FC<PushStaffModalProps> = ({ value, onChange, ouId, disabledIds }) => {
  const [form] = Form.useForm();

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [alarmPushStaffs, setAlarmPushStaffs] = useState<AlrmPushStaffPageResponse[]>([]);

  const alarmPushStaffIds = useMemo(() => {
    return (value ?? []).map(i => i.staffId);
  }, [value]);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (ouId) {
      setLoading(true);
      getAlarmPushStaffPage({ ...searchParams, ouId, page: pageOffset, size: pageSize }).then(res => {
        setLoading(false);
        if (res) {
          setTotalCount(res.total);
          setAlarmPushStaffs(res.list);
        }
      });
    }
  }, [ouId, pageOffset, pageSize, searchParams, setTotalCount]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: AlrmPushStaffPageResponse[]) => {
      const rulesId = alarmPushStaffs.map(i => i.staffId);
      const buildDataMap = new Map<number, PushCenterStaffLinkVoList>();

      const valueIds = (value ?? []).map(i => i.staffId);
      const appendRecords = selectedRows.filter(i => !valueIds.includes(i.staffId));
      appendRecords.forEach(i => {
        if (!buildDataMap.has(i.staffId)) {
          buildDataMap.set(i.staffId, {
            ...i,
            thirdPartyAppId: [PushThirdApp.WEB_SOCKET],
          });
        }
      });
      const selectedRowsIds = selectedRows.map(i => i.staffId);

      const unCheckIds = rulesId.filter(i => !selectedRowsIds.includes(i));

      (value ?? [])
        .filter(i => !unCheckIds.includes(i.staffId))
        .forEach(i => {
          if (!buildDataMap.has(i.staffId)) {
            buildDataMap.set(i.staffId, i);
          }
        });
      onChange && onChange(Array.from(buildDataMap.values()));
    },
    getCheckboxProps: (record: AlrmPushStaffPageResponse) => ({
      disabled: (disabledIds ?? []).includes(record.staffId),
    }),
    selectedRowKeys: alarmPushStaffIds,
  };

  const onFinish = (search: SearchParams) => {
    setSearchParams(search);
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    form.setFieldsValue({
      name: undefined,
      humanCode: undefined,
      staffCode: undefined,
      phone: undefined,
    });
    setPageOffset(1);
  };

  const filters = (
    <Filter
      form={form}
      onFinish={val => {
        onFinish(val);
      }}
      onReset={onReset}
      collapseItems={
        <>
          <Col span={6}>
            <Form.Item name="phone" label="手机号">
              <Input placeholder="请输入" />
            </Form.Item>
          </Col>
        </>
      }
    >
      <>
        <Col span={6}>
          <Form.Item name="humanCode" label="编号">
            <Input placeholder="请输入编号查询" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="name" label="姓名">
            <Input placeholder="请输入名称查询" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="staffCode" label="员工工号">
            <Input placeholder="请输入" />
          </Form.Item>
        </Col>
      </>
    </Filter>
  );

  return (
    <>
      {filters}
      <div className={styles.tips}>
        <div>
          已选择<span className={styles.red}>{(value ?? []).length}</span>项
        </div>
      </div>
      <Table
        loading={loading}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        sticky
        scroll={{ y: 300 }}
        rowKey="staffId"
        columns={columns}
        dataSource={alarmPushStaffs}
      />
      <Paging pagingInfo={pagingInfo} />
    </>
  );
};

export default PushStaffModal;
