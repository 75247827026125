import { Col, Form, Row, Typography } from 'antd';
import { FormContent, Key, Paging, usePaging } from '@maxtropy/components';
import React, { useEffect, useMemo, useState } from 'react';
import { EdgeDeviceData, formItemsDisplayByType } from './factory';
import EdgeDevicePointDetail from '../EdgeDevicePointInfo/Detail';
import { EdgeDeviceTemplatePoint } from '../EdgeDevicePointInfo';
import { SearchParams } from '../EdgeDevicePointInfo/mockingbird/Detail';
import { IotProtocolType } from '../../types';
import useIsGateway from '../../hooks/useIsGateway';
import { useNavigate, Link } from 'react-router-dom';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

interface SharedEdgeTemplateDetailProps {
  data?: EdgeDeviceData;
  dataSource: EdgeDeviceTemplatePoint[];
  hasDataColumns?: boolean;
  edgeGatewaySerialNumber?: string;
  physicalModelId?: Key;
}

const SharedEdgeTemplateFormDetail: React.FC<SharedEdgeTemplateDetailProps> = ({
  data,
  dataSource,
  hasDataColumns,
  edgeGatewaySerialNumber,
  physicalModelId,
}) => {
  const pagingInfo = usePaging(10000);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const { isGateway } = useIsGateway(data?.deviceTypeId);
  const FormDisplay = data?.iotProtocol !== undefined ? formItemsDisplayByType[data?.iotProtocol] : undefined;
  const navigate = useNavigate();

  const _data = useMemo(() => {
    let _dataSource = dataSource;
    if (data?.iotProtocol === IotProtocolType.MOCKINGBIRD) {
      _dataSource = dataSource.filter(item => (item as any).pointType === searchParams?.pointType);
    }
    setTotalCount(_dataSource.length);
    return _dataSource
      .filter(item =>
        !searchParams?.name
          ? true
          : item.dataPropertyName?.includes(searchParams?.name) || item.identifier?.includes(searchParams?.name)
      )
      .slice(pageSize * (pageOffset - 1), pageOffset * pageSize);
  }, [dataSource, pageOffset, pageSize, searchParams, setTotalCount, data?.iotProtocol]);

  useEffect(() => {
    setPageOffset(1);
  }, [searchParams?.pointType]);

  return (
    <>
      <FormContent title="设备数采信息">
        <Form layout="vertical" {...formLayout}>
          <Row>
            <Col span={8} className="detail-col-gap">
              <Form.Item name="edgeGatewaySerialNumber" label="关联网关">
                {edgeGatewaySerialNumber ? (
                  <>
                    {edgeGatewaySerialNumber}&nbsp;
                    <Typography.Link>
                      <Link target="_blank" to={`/device/config/gateway?serialNumber=${edgeGatewaySerialNumber}`}>
                        查看网关
                      </Link>
                    </Typography.Link>
                  </>
                ) : (
                  '--'
                )}
              </Form.Item>
            </Col>
            <Col span={8} className="detail-col-gap">
              <Form.Item name="edgeDeviceTemplateName" label="数采模板">
                <div>{data?.edgeDeviceTemplateName ?? '--'}</div>
              </Form.Item>
            </Col>
            <Col span={8} className="detail-col-gap">
              <Form.Item name="edgeDeviceTemplateVersion" label="版本号">
                <div>{data?.edgeDeviceTemplateVersion ?? '--'}</div>
              </Form.Item>
            </Col>
            <Col span={8} className="detail-col-gap">
              <Form.Item name="edgeDeviceTemplateLatestVersion" label="最新版本号">
                <div>{data?.edgeDeviceTemplateLatestVersion ?? '--'}</div>
              </Form.Item>
            </Col>
            <Col span={8} className="detail-col-gap">
              <Form.Item name="edgeDeviceTemplateVersionRemark" label="版本备注">
                <div>{data?.edgeDeviceTemplateVersionRemark ? data.edgeDeviceTemplateVersionRemark : '--'}</div>
              </Form.Item>
            </Col>
            {data?.iotProtocol === IotProtocolType.MOCKINGBIRD && !isGateway && FormDisplay && (
              <FormDisplay data={data} />
            )}
          </Row>
        </Form>
      </FormContent>
      {data && (
        <>
          <EdgeDevicePointDetail
            type={data.iotProtocol}
            dataSource={_data}
            info={{
              iotProtocol: data.iotProtocol,
              driveType: data.driveType,
              deviceTypeId: data.deviceTypeId,
              physicalModelId,
            }}
            setSearchParams={setSearchParams}
            hasDataColumns={hasDataColumns}
          />
          {/* <Paging pagingInfo={pagingInfo}/> */}
        </>
      )}
    </>
  );
};

export default SharedEdgeTemplateFormDetail;
