import marked, { MarkedOptions, Tokens } from 'marked';
import { TreeNode } from '../components/interface';

const escape = document.createElement('textarea');

function escapeHTML(html: string) {
  escape.textContent = html;
  return escape.innerHTML;
}

let scopeSecondLimited = '0';
let scopeMinuteLimited = '0';

const renderer = {
  heading(text: string, level: number) {
    const escapedText = escapeHTML(text).toLowerCase().replace(/\s+/g, '');

    return `<h${level} id="${escapedText}">
              <a name="${escapedText}" class="anchor" href="#${escapedText}">
                <span class="header-link"/>
              </a>
              ${text}
            </h${level}>`;
  },
  paragraph(text: string) {
    if (text.includes('使用Chrome浏览器网址输入（）')) {
      const origin = escapeHTML(window.location.origin);
      text = text.replace('使用Chrome浏览器网址输入（）', `使用Chrome浏览器网址输入<a href="${origin}">${origin}</a>`);
    }
    if (text.includes('#{secondLimited}')) {
      text = text.replace('#{secondLimited}', escapeHTML(scopeSecondLimited));
    }
    if (text.includes('#{minuteLimited}')) {
      text = text.replace('#{minuteLimited}', escapeHTML(scopeMinuteLimited));
    }
    return text;
  },
};

marked.use({ renderer } as unknown as MarkedOptions);

export const strongMarked = (text: string, secondLimited: string, minuteLimited: string) => {
  scopeSecondLimited = secondLimited;
  scopeMinuteLimited = minuteLimited;
  return marked(text);
};

export const getTokens = (markdownString: string): TreeNode[] => {
  const tokens: TreeNode[] = marked
    .lexer(markdownString)
    .filter(token => (token as any).type === 'heading')
    .map(token => {
      const _token = token as Tokens.Heading;
      const { depth, text } = _token;
      return {
        href: '#' + escapeHTML(text).toLowerCase().replace(/\s+/g, ''),
        title: text,
        depth,
        children: [],
      };
    });
  return tokens;
};

export const generateTree = (treeNodes: TreeNode[]): TreeNode[] => {
  const tree: TreeNode[] = [];
  const depthList = treeNodes.map(({ depth }) => depth);
  const minDepth = Math.min(...depthList);
  let sliceIndexList = [];
  for (let i = 1; i < depthList.length; i++) {
    if (depthList[i] === minDepth) {
      sliceIndexList.push(i);
    }
  }
  sliceIndexList.push(depthList.length);
  let start = 0;
  while (sliceIndexList.length) {
    const sliceIndex = sliceIndexList.shift() as number;
    const slicedTreeNodes = treeNodes.slice(start, sliceIndex);
    if (slicedTreeNodes.length) {
      const [parent, ...children] = slicedTreeNodes;
      parent.children = Array.isArray(children) ? children : [];
      parent.children.length && (parent.children = generateTree(parent.children));
      tree.push(parent);
    }
    start = sliceIndex;
  }
  return tree;
};
