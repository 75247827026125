import { fetch, Key } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import qs from 'qs';
import {
  EdgeDeviceTemplate,
  EdgeDeviceTemplateDetail,
  EdgeDeviceTemplatePoint,
  EdgeDeviceTemplateStatus,
  EdgeDeviceTemplateVersion,
  IotProtocolType,
  DriveType,
} from '@/shared/types';

export interface EdgeTemplateBody {
  name: string;
  deviceTypeId: number;
  deviceModelId?: number;
  deviceModelName: string;
  remark: string;
  iotProtocol: IotProtocolType;
  driveType?: DriveType;
  physicalModelId?: Key;
}

export const addEdgeTemplate = (params: EdgeTemplateBody) =>
  fetch<{ id: number }>(`/api/edgeTemplate`, {
    method: 'POST',
    body: JSON.stringify(params),
  });

export const updateEdgeTemplate = (id: number, params: Partial<EdgeTemplateBody>) =>
  fetch<void>(`/api/edgeTemplate/${id}`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });

export interface EdgeTemplatePageRequest extends PageRequest {
  name?: string;
  deviceType?: Array<number> | undefined;
  displayAbolished?: boolean;
  status?: EdgeDeviceTemplateStatus;
  manufacturerId?: string;
  physicalModelId?: string;
}

export const getEdgeTemplatePage = (params: EdgeTemplatePageRequest) =>
  fetch<PageResponse<EdgeDeviceTemplate>>(`/api/edgeTemplate/page?${qs.stringify(params, { indices: false })}`);

export const getEdgeTemplate = (id: number) => fetch<EdgeDeviceTemplateDetail>(`/api/edgeTemplate/${id}`);

export const changeEdgeTemplateStatus = (id: number, status: EdgeDeviceTemplateStatus) =>
  fetch<void>(`/api/edgeTemplate/${id}/status`, {
    method: 'PUT',
    body: JSON.stringify({ status }),
  });

export interface EdgeTemplateVersionPageRequest extends PageRequest {
  displayAbolished?: boolean;
}

export const getEdgeTemplateVersionPage = (id: number, params: EdgeTemplateVersionPageRequest) =>
  fetch<PageResponse<EdgeDeviceTemplateVersion>>(
    `/api/edgeTemplate/${id}/version/page?${qs.stringify(params, {
      indices: false,
    })}`
  );

export const changeEdgeTemplateVersionStatus = (id: number, status: EdgeDeviceTemplateStatus) =>
  fetch<void>(`/api/edgeTemplate/version/${id}/status`, {
    method: 'PUT',
    body: JSON.stringify({ status }),
  });

export const getEdgeTemplateByVersion = (versionId: number) =>
  fetch<EdgeDeviceTemplateDetail>(`/api/edgeTemplate/version/${versionId}`);

export const getLatestVersionPointList = (id: number) =>
  fetch<EdgeDeviceTemplatePoint[]>(`/api/edgeTemplate/${id}/latestVersion/point/list`);

export interface EdgeTemplatePointsBody {
  remark: string;
  points: Partial<EdgeDeviceTemplatePoint>[];
}

export const updateEdgeTemplatePoints = (id: number, params: EdgeTemplatePointsBody) =>
  fetch<void>(`/api/edgeTemplate/${id}/points`, {
    method: 'POST',
    body: JSON.stringify(params),
  });

export interface EdgeTemplateTenantRequest extends PageRequest {
  tenantCode?: string;
  name?: string;
  uscc?: string;
}

export interface EdgeTemplateTenantRes {
  tenantCode: string;
  uscc: string;
  name: string;
  rootMcid: string;
}

export const getEdgeTemplateTenant = (query: EdgeTemplateTenantRequest) =>
  fetch<PageResponse<EdgeTemplateTenantRes>>(
    `/api/edgeTemplate/tenant/page?${qs.stringify(query, { indices: false })}`
  );

export interface EdgeTemplatePageTemplateRequest extends PageRequest {
  name?: string;
  deviceType?: Array<number> | undefined;
  brandDeviceTypes?: Array<number> | undefined;
  productDeviceTypes?: Array<number> | undefined;
  statuses?: EdgeDeviceTemplateStatus;
  manufacturerId?: string;
  physicalModelId?: string;
}

export interface EdgeTemplatePageTemplateRes {
  id: number;
  name: string;
  serialNumber: string;
  iotProtocol: IotProtocolType;
  status: EdgeDeviceTemplateStatus;
  latestVersion: number;
  latestVersionId: number;
  usedByDevice: number;
  updateTime: string;
  updateByUsername: string;
  remark: string;
  deviceTypes: Array<number> | null;
  manufacturerName: string;
  modelNo: string;
}

export const getEdgeTemplatePageTemplate = (query: EdgeTemplatePageTemplateRequest) =>
  fetch<PageResponse<EdgeTemplatePageTemplateRes>>(
    `/api/edgeTemplate/pageTemplate?${qs.stringify(query, { indices: false })}`
  );

export interface TenantDistributionTemplateRes {
  rootMcid: string;
  code: string;
  name: string;
  templateList: EdgeDeviceTemplate[];
}

export const getTenantDistributionTemplate = (rootMcid: string) =>
  fetch<TenantDistributionTemplateRes>(`/api/edgeTemplate/tenantDistributionTemplate?rootMcid=${rootMcid}`);

export interface TenantBindingTemplateReq {
  tenantMcid: string;
  addTemplateIdList?: number[];
  deleteTemplateIdList?: number[];
}

export const tenantBindingTemplate = (body: TenantBindingTemplateReq) =>
  fetch(`/api/edgeTemplate/tenantBindingTemplate`, {
    method: 'POST',
    body: JSON.stringify(body),
  });

export interface TemplateDistributionTenantRes {
  code: string;
  name: string;
  tenantList: EdgeTemplateTenantRes[];
}

export const getTemplateDistributionTenant = (templateId: string) =>
  fetch<TemplateDistributionTenantRes>(`/api/edgeTemplate/templateDistributionTenant?templateId=${templateId}`);

export interface TemplateBindingTenantReq {
  templateId: string;
  addTenantMcidList?: string[];
  deleteTenantMcidList?: string[];
}

export const templateBindingTenant = (body: TemplateBindingTenantReq) =>
  fetch(`/api/edgeTemplate/templateBindingTenant`, {
    method: 'POST',
    body: JSON.stringify(body),
  });

export const getTenantDistributionTemplateSearch = (mcid: string) =>
  fetch<TenantDistributionTemplateRes>(`/api/edgeTemplate/tenantDistributionTemplate/search?rootMcid=${mcid}`);

export const getTemplateDistributionTenantSearch = (id: string) =>
  fetch<TemplateDistributionTenantRes>(`/api/edgeTemplate/templateDistributionTenant/search?templateId=${id}`);

export interface ManufacturerItem {
  id: Key;
  name: string; // 名称
  updateTime: string;
  createTime: string;
}

export const getManufacturerList = () => fetch<ManufacturerItem[]>('/api/manufacturer/list');
