import { fetch } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import qs from 'qs';
import { AlarmLevel, AlarmStatus, AlarmType, IotProtocolType, Operator, AlarmChannel } from '@/shared/types';

export interface RulePageRequest extends PageRequest {
  nameOrCode?: string;
  status?: AlarmStatus;
  type?: AlarmType;
  alarmType?: AlarmLevel;
  rootMcid?: string;
  deviceTypeId?: number;
  iotProtocol?: IotProtocolType;
  channel?: AlarmChannel;
}

export interface RulePageProps {
  id: number;
  code: string;
  name: string;
  status: AlarmStatus;
  type: AlarmType;
  alarmName: string;
  alarmType: AlarmLevel;
  channel: AlarmChannel;
  rootMcid: string;
  rootName: string;
  rootFullName: string;
  tenantCode: string;
  useDeviceCount: number;
  iotProtocol: IotProtocolType;
  updateSource: Operator;
  updateByUserId: number;
  updateByUsername: string;
  updateTime: string;
  alarmCode?: string;
  faultCode?: string;
  tenantName?: string;
  deviceTypeName?: string;
  formula?: string;
  deviceTypeId?: number;
  duration: number;
  thirdAlarmCode?: string;
}

export function getRulePage(query: RulePageRequest): Promise<PageResponse<RulePageProps>> {
  return fetch(`/api/rule/page?${qs.stringify(query, { indices: false })}`);
}

export function deleteRule(id: number) {
  return fetch(`/api/rule/${id}`, {
    method: 'DELETE',
  });
}

export interface CreateRuleRequest {
  name: string;
  type: AlarmType;
  alarmName: string;
  alarmType: AlarmLevel;
  rootMcid: string;
  iotProtocol: IotProtocolType;
  alarmCode: string;
  faultCode: string;
  duration: number;
}

export function createRule(body: CreateRuleRequest) {
  return fetch(`/api/rule/add`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'x-tenant-mcid': `${body.rootMcid}`,
    },
  });
}

export interface UpdateRuleRequest {
  id: number;
  iotProtocol: IotProtocolType;
  name: string;
  alarmName: string;
  alarmType: AlarmLevel;
  duration: number;
}

export function updateRule(body: UpdateRuleRequest) {
  return fetch(`/api/rule/update`, {
    method: 'PUT',
    body: JSON.stringify(body),
  });
}

export function getRuleInfo(id: number): Promise<RulePageProps> {
  return fetch(`/api/rule/info?id=${id}`);
}

export function disableRule(id: number) {
  return fetch(`/api/rule/disable/${id}`, {
    method: 'PUT',
  });
}

export function enableRule(id: number) {
  return fetch(`/api/rule/enable/${id}`, {
    method: 'PUT',
  });
}
