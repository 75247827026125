export enum PermissionsType {
  // Iot配置
  PBASICSETTING = 'p-basicSetting',

  // 设备信息模板库
  PDEVICETEMPLATE = 'p-deviceTemplate',
  // 固定属性模版
  PATTRIBUTETEMPLATE = 'p-attributeTemplate',
  // 新建模板页面
  PCREATEATTRIBUTETEMPLATE = 'p-createAttributeTemplate',
  // 编辑模板页面
  PUPDATEATTRIBUTETEMPPLATE = 'p-updateAttributeTempplate',
  // 启用/停用按钮
  BATTRIBUTETEMPLATEENABLEDISABLE = 'b-attributeTemplateEnableDisable',

  // 属性管理
  PATTRIBUTEMANAGE = 'p-attributeManage',
  // 查看页面
  PATTRIBUTEINFO = 'p-attributeInfo',

  // 设备类型库
  PDEVICETYPE = 'p-deviceType',
  // 类目树
  PDEVICETYPETREE = 'p-deviceTypeTree',

  // 数采配置
  PEDGESETTING = 'p-edgeSetting',
  // 数采模版
  PEDGETEMPLATE = 'p-edgeTemplate',
  // 新建模板页面
  PCREATEEDGETEMPLATE = 'p-createEdgeTemplate',
  // 编辑基础信息页面
  PUPDATEEDGETEMPLATEBASICINFO = 'p-updateEdgeTemplateBasicInfo',
  // 编辑采集信息页面
  PUPDATEEDGETEMPLATEEDGEINFO = 'p-updateEdgeTemplateEdgeInfo',

  // 物联配置
  PIOTSETTING = 'p-iotSetting',
  // 网关管理
  PEDGEGATEWAYMANAGE = 'p-edgeGatewayManage',
  // 设备绑定网关
  PGATEWAYBINDDEVICE = 'p-gatewayBindDevice',
  // 新建网关页面
  PCREATEGATEWAY = 'p-createGateway',
  // 编辑网关页面
  PUPDATEGATEWAY = 'p-updateGateway',
  // 作废/恢复按钮
  BEDGEGATEWAYMANAGEDISABLE = 'b-edgeGatewayManageDisable',
  // 新建网关按钮
  B_CREATEGATEWAY = 'b-createGateway',
  // 配置下发按钮 下发记录按钮
  B_GATEWAYDELIVERYCONFIG = 'b-gatewayDeliveryConfig',
  //导出按钮
  B_EXPORTGATEWAYDEVICE = 'b-exportGatewayDevice',

  // 设备管理
  PDEVICEMANAGE = 'p-devicemanage',
  // 设备管理页面
  PDEVICEMANAGEMENT = 'p-deviceManagement',
  // 新建设备页面
  PCREATEDEVICE = 'p-createDevice',
  // 编辑资产信息页面
  PUPDATEDEVICEBASICINFO = 'p-updateDeviceBasicInfo',
  // 查看页面
  PDEVICEINFO = 'p-deviceInfo',
  // 添加/编辑固定信息
  PUPSERTDEVICEATTRIBUTEINFO = 'p-upsertDeviceAttributeInfo',
  // 添加/编辑数采信息页面
  PUPSERTDEVICEEDGEINFO = 'p-upsertDeviceEdgeInfo',
  // 批量配置固定属性按钮
  B_BATCHCONFIGATTRIBUTE = 'b-batchConfigAttribute',
  // 批量修改数采信息按钮
  B_BATCHCONFIGEDGEINFO = 'b-batchConfigEdgeInfo',

  // 批量新建任务
  PBATCHIMPORTDEVICE = 'p-batchImportDevice',
  // 新建导入模板页面
  PCREATEIMPORTTEMPLATE = 'p-createImportTemplate',
  // 编辑资产信息页面
  PUPDATEBATCHDEVICEBASICINFO = 'p-updateBatchDeviceBasicInfo',
  // 查看页面
  PBATCHDEVICEINFO = 'p-batchDeviceInfo',
  // 导入按钮
  BDEVICEIMPORTBUTTON = 'b-deviceImportButton',
  // 添加/编辑数采信息页面
  PUPSERTBATCHDEVICEEDGEINFO = 'p-upsertBatchDeviceEdgeInfo',
  // 添加/编辑固定信息
  PUPSERTBATCHDEVICEATTRIBUTEINFO = 'p-upsertBatchDeviceAttributeInfo',

  // 生产运营单元
  P_OU = 'p-ou',
  // 单元管理
  P_OU_MANAGE = 'p-ouManage',
  // 新建生产运营单元
  P_CREATE_OU = 'p-createOu',
  // 编辑生产运营单元
  P_UPDATE_OU = 'p-updateOu',
  // 单元设备管理
  P_OU_DEVICE_MANAGE = 'p-ouDeviceManage',
  // 新建生产运营单元按钮
  B_CREATE_OU = 'b-createOu',
  // 添加单位设备管理按钮
  B_CREATE_OU_DEVICE = 'b-createOuDevice',
  // 作废生产运营单元按钮
  B_ABOLISH_OU = 'b-abolishOu',

  // 数据中心
  P_DATACENTER = 'p-dataCenter',
  // 历史数据
  P_HISTROYDATA = 'p-histroyData',
  // 设备历史数据
  PDEVICEHISTORY = 'p-deviceHistory',

  // 报警规则
  P_ALARM_RULE = 'p-alarmRule',
  // 规则管理
  P_RULE_MANAGE = 'p-ruleManage',
  // 新建规则
  P_CREATE_RULE = 'p-createRule',
  // 新建规则组
  P_CREATE_RULE_GROUP = 'p-createRuleGroup',
  // 编辑规则
  P_UPDATE_RULE = 'p-updateRule',
  // 编辑规则组
  P_UPDATE_RULE_GROUP = 'p-updateRuleGroup',
  // 新建规则按钮
  B_CREATE_RULE = 'b-createRule',
  // 新建规则组按钮
  B_CREATE_RULE_GROUP = 'b-createRuleGroup',
  // 启用/禁用按钮
  B_RULE_AND_GROUP_ENABLE_DISABLE = 'b-ruleAndGroupEnableDisable',
  // 删除按钮
  B_RULE_AND_GROUP_DELETE = 'b-ruleAndGroupDelete',

  // 报警推送
  P_ALARM_PUSH = 'p-alarmPush',
  // 报警记录
  P_ALARM_LOG = 'p-alarmLog',

  // 推送管理
  P_ALARM_PUSH_STRATEGY_MANAGE = 'p-alarmPushStrategyManage',
  // 新建推送
  P_CREATE_ALARM_PUSH_STRATEGY = 'p-createAlarmPushStrategy',
  // 编辑推送
  P_UPDATE_ALARM_PUSH_STRATEGY = 'p-updateAlarmPushStrategy',

  //设备信息模板库-模板分配
  P_ATTRIBUTETEMPLATEASSIGN = 'p-attributeTemplateAssign',
  //设备信息模板库-模板属性分配页面
  P_ATTRIBUTETEMPLATEASSIGNTENANT = 'p-attributeTemplateAssignTenant',
  //设备信息模板库-租户分配属性模板
  P_TENANTASSIGNATRIBUTETEMPLATE = 'p-tenantAssignAtributeTemplate',

  //数采配置-模板分配
  P_EDGETEMPLATEASSIGN = 'p-edgeTemplateAssign',
  //数采配置-租户分配数采模板
  P_TENANTASSIGNEDGETEMPLATE = 'p-tenantAssignEdgeTemplate',
  //数采配置-数采模板分配页面
  P_EDGETEMPLATEASSIGNTENANT = 'p-edgeTemplateAssignTenant',

  //信息管理
  P_INFORMATIONMANAGE = 'p-informationManage',
  //报告管理
  P_ANALYSISREPORTMANAGE = 'p-analysisReportManage',
  //上传报告
  B_ANALYSISREPORTUPLOAD = 'b-analysisReportUpload',
  // 类型管理
  B_ANALYSISREPORTTYPEMANAGE = 'b-analysisReportTypeManage',
  // 删除报告
  B_ANALYSISREPORTDELETE = 'b-analysisReportDelete',
  // 报告类型管理
  P_ANALYSISREPORTTYPEMANAGE = 'p-analysisReportTypeManage',

  // 工具中心
  P_TOOLSCENTER = 'p-toolsCenter',
  // 光伏测算工具
  P_PVESTIMATETOOLS = 'p-pvEstimateTools',
  // 光伏收益估算
  P_PVINCOME = 'p-pvIncome',
  // 光伏发电量测算
  P_PVGENERATINGCAPACITY = 'p-pvGeneratingCapacity',

  // AI报告
  P_AIREPORT = 'p-aiReport',
  // AI报告管理
  P_AIREPORTMANAGE = 'p-aiReportManage',

  // 物模型型号管理
  P_PHYSICAL_MODEL_MANAGE = 'p-physicalModelManagement',

  // 双碳管理
  P_DUAL_CARBON_MANAGEMENT = 'p-dualCarbonManagement',
  // 基本信息
  P_BASIC_INFORMATION = 'p-basicInformation',
  // 行业管理
  P_TRADE_MANAGEMENT = 'p-tradeManagement',
  // 排放因子库
  P_EMISSION_FACTOR_POOL = 'p-emissionFactorPool',
  // 碳排查
  P_CARBON_INVENTORY = 'p-carbonInventory',
  // 核算指南管理
  P_ACCOUNTING_GUIDELINES_MANAGEMENT = 'p-accountingGuidelinesManagement',
  // 排放过程管理
  P_EMISSION_PROCESS_MANAGEMENT = 'p-emissionProcessManagement',
  // 数据运营工具
  P_DATA_OPERATION_TOOLS = 'p-dataOperationTools',

  // 数据模拟
  P_DATA_SIMULATION = 'p-dataSimulation',

  // 使用说明
  P_USER_MANUAL = 'p-userManual',

  // 规则集配置
  P_RULE_CONFIGURATION = 'p-ruleConfiguration',
  // 新建/复制规则集
  B_CREATE_RULE_CONFIGURATION = 'b-createRuleConfiguration',
  // 编辑规则集
  B_EDIT_RULE_CONFIGURATION = 'b-editRuleConfiguration',
  // 启用/停用按钮
  B_ENABLE_RULE_CONFIGURATION = 'b-enableRuleConfiguration',
  // 删除规则集
  B_DELETE_RULE_CONFIGURATION = 'b-deleteRuleConfiguration',
  // 编辑数据点
  B_EDIT_DTA_POINTS = 'b-editDtaPoints',

  // 上传时间规则配置
  P_UPLOAD_RULE_CONFIG = 'p-uploadRuleConfig',
  // 创建/复制上传时间规则
  B_CREATE_UPLOAD_RULE = 'b-createUploadRule',
  // 编辑上传时间规则
  B_EDIT_UPLOAD_RULE = 'b-editUploadRule',
  // 删除上传时间规则
  B_DELETE_UPLOAD_RULE = 'b-deleteUploadRule',

  // 数据时间规则配置
  P_DATA_TIME_RULE_CONFIG = 'p-dataTimeRuleConfig',
  // 创建/复制数据时间规则规则
  B_CREATE_DATA_TIME_RULE_CONFIG = 'b-createDataTimeRuleConfig',
  // 编辑数据时间规则规则
  B_EDIT_DATA_TIME_RULE_CONFIG = 'b-editDataTimeRuleConfig',
  // 删除数据时间规则规则
  B_DELETE_DATA_TIME_RULE_CONFIG = 'b-deleteDataTimeRuleConfig',

  // 数据值规则配置
  P_DATA_VALUE_RULE_CONFIG = 'p-dataValueRuleConfig',
  // 创建/复制数据值规则规则
  B_CREATE_DATA_VALUE_RULE_CONFIG = 'b-createDataValueRuleConfig',
  // 编辑数据值规则规则
  B_EDIT_DATA_VALUE_RULE_CONFIG = 'b-editDataValueRuleConfig',
  // 删除数据值规则规则
  B_DELETE_DATA_VALUE_RULE_CONFIG = 'b-deleteDataValueRuleConfig',

  // 规则集管理
  P_DATA_RULE_MANAGE = 'p-mockRulesSetManage',
  // 数据值模板管理
  P_DATA_TEMPLATE_MANAGE = 'p-mockDataRuleNumericalManage',

  // 业务数据模拟
  P_BUSINESSDATASIMULATION = 'p-businessDataSimulation',
  // 表单模板管理
  P_MOCKFORMTEMPLATEMANAGE = 'p-mockFormTemplateManage',
  // 表单管理
  P_MOCKFORMMANAGE = 'p-mockFormManage',
  // 数据生成器
  P_MOCKDATAGENERATORMANAGE = 'p-mockDataGeneratorManage',

  // 模拟数据删除
  P_MOCK_DATA_DELETION = 'p-mockDataDel',
  // 系统设置
  PSYSTEM = 'p-system',
  // 平台组织权限和账户
  PACCOUNT = 'p-account',
  // 平台系统管理合设置员工管理
  PCORPUSER = 'p-corpuser',
  // 新增运营人员页面
  PCREATECORPUSER = 'p-createCorpUser',
  // 编辑运营人员页面
  PUPDATECORPUSER = 'p-updateCorpUser',
  // 查看运营人员页面
  PCORPUSERINFO = 'p-corpUserInfo',
  //-- --//
  // 租户管理
  PTENANT = 'p-tenant',
  // 租户组织权限和账户
  PTENANTPERMISSION = 'p-tenantPermission',

  // 租户员工工号管理
  PSTAFFMANAGE = 'p-staffManage',
  // 同时创建用户和员工
  PCREATESTAFF = 'p-createStaff',
  // 员工账号移交按钮
  BSTAFFREASSIGN = 'b-staffReassign',

  // 租户用户管理
  PCUSTOMERUSERMANAGE = 'p-customerUserManage',
  // 新建用户页面
  PCREATECUSTOMERUSER = 'p-createCustomerUser',
  // 租户用户详情页面
  PCUSTOMERUSERINFO = 'p-customerUserInfo',

  // 预制角色管理
  PROLEMANAGE = 'p-roleManage',
  // 新建预置角色页面
  PCREATEROLE = 'p-createRole',
  // 编辑预置角色页面
  PUPDATEROLE = 'p-updateRole',

  // 租户管理
  PTENANTMANAGE = 'p-tenantManage',
  // 编辑租户基础信息页面
  PUPDATETENANTINFO = 'p-updateTenantInfo',
  // 租户开户设定页面
  PTENANTOPENACCOUNT = 'p-tenantOpenAccount',
  // 编辑客户联系人按钮
  BUPDATECUSTOMERCONTACTS = 'b-updateCustomerContacts',
  // 查看客户联系人页面
  PCUSTOMERCONTACTSINFO = 'p-customerContactsInfo',
  // 编辑租户功能权限页面
  PUPDATETENANTFUNCTIONPERMISSION = 'p-updateTenantFunctionPermission',
  // 查看租户功能权限页面
  PTENANTFUNCTIONPERMISSIONINFO = 'p-tenantFunctionPermissionInfo',
  // 新建租户页面
  PCREATETENANTINFO = 'p-createTenantInfo',
  // 渠道端设置
  PCHANNELINFO = 'p-channelInfo',

  // 编辑订阅控制
  P_UPDATE_SUBSCRIPTION = 'p-updateSubscription',
  // 集成应用订阅
  B_INTEGRATED_APPLICATION_SUBSCRIPTION = 'b-IntegratedApplicationSubscription',

  // 修改客户联系人
  // UPDATECUSTOMERCONTACTS = 'updateCustomerContacts',
  // 绑定给他人
  // STAFFREASSIGN = 'staffReassign'

  // 轻流开户
  P_QING_FLOW_ACCOUNT_OPEN = 'p-qingFlowAccountOpen',
  B_QING_FLOW_ROLE_LINK = 'b-qingFlowRoleLink',
  B_QING_FLOW_ACCOUNT_OPEN = 'b-qingFlowAccountOpen',
  B_QING_FLOW_ACCOUNT_OPEN_CONFIG = 'b-qingFlowAccountOpenConfig',
  B_QING_FLOW_ACCOUNT_OPEN_PAGE = 'p-qingFlowAccountOpenConfig',
  // 工单中心
  P_QL_WORK_CENTER = 'p-qlWorkOrderCenter',
  // 工单记录
  P_QL_WORK_RECORD = 'p-qlWorkOrderRecord',
  // 报警工单
  P_QL_WORK_ALARM = 'p-qlWorkOrderAlarm',
  // 工单联系单
  P_QL_WORK_LINK = 'p-qlWorkOrderLink',
  // 设备资产管理
  P_QL_DEVICE_MANAGE = 'p-qlDeviceAssetManage',
  // 资产管理
  P_QL_ASSET_MANAGE = 'p-qlAssetManage',
  // 资产台账
  P_QL_ASSET_BOOK = 'p-qlAssetStandingBook',
  // 资产类目
  P_QL_ASSET_TYPE = 'p-qlAssetDeviceType',
  // 导航设置
  P_NAVIGATIONSETTING = 'p-navigationSetting',
  // 导航方案
  P_NAVIGATIONPROGRAM = 'p-navigationProgram',
  // 编辑/复制
  B_NAVIGATIONPROGRAMUPDATECOPY = 'b-navigationProgramUpdateCopy',
  // 编辑/删除/新增导航
  B_NAVIGATIONPROGRAMDETAILUPSERT = 'b-navigationProgramDetailUpsert',
  // 小程序管理
  P_MINIAPPMANAGE = 'p-miniAppManage',
  // 小程序工具管理
  P_MINIAPPTOOLMANAGE = 'p-miniAppToolManage',
  // 小程序
  P_MINIAPP = 'p-miniApp',
  // 熵云平台资料
  P_MAXTROPY_MATERIAL = 'p-maxtropyMaterial',
  // 熵云能力清单
  P_MAXTROPY_CAPABILITY_LIST = 'p-maxtropyCapabilityList',

  B_CREATEAPP = 'b-createApp',
  B_UPDATEAPP = 'b-updateApp',
  B_RESETSECRETKEY = 'b-resetSecretKey',
  B_CREATEDEVELOPER = 'b-createDeveloper',

  P_OPENPLATFORM = 'p-openPlatform',
  P_APPOPENPLATFORM = 'p-appOpenPlatform',
  P_APPLIST = 'p-appList',
  P_DEVELOPERPERMISSIONMANAGE = 'p-developerPermissionManage',
  P_APIDOCUMENTATION = 'p-apiDocumentation',

  P_APPCREATE = 'p-appCreate',
  P_APPUPDATE = 'p-appUpdate',
  P_APPINFO = 'p-appInfo',
  P_CREATEDEVELOPER = 'p-createDeveloper',
  P_UPDATEDEVELOPERPERMISSION = 'p-updateDeveloperPermission',
  P_DEVELOPERPERMISSIONINFO = 'p-developerPermissionInfo',
  P_DATAPROPERTYMANAGE = 'p-dataPropertyManage',

  // 定时任务
  P_RUN_TASK = 'p-cronjobRerun',
  // 导入设备数采配置
  B_IMPORTDEVICECONFIG = 'b-importDeviceConfig',
  // 导出设备数采配置
  B_EXPORTDEVICECONFIG = 'b-exportDeviceConfig',
}
