import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Cascader, Form, message, Modal, Select, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { omit } from 'lodash-es';
import { Key, Wrapper, useAsync } from '@maxtropy/components';
import { ActionType, IotProtocolType } from '@/shared/types';
import { formatOptionData } from '@/shared/components/CascadingMultipleSelector/utils';
import EdgeDevicePointInfo from '@/shared/components/EdgeDevicePointInfo';
import { useQuery } from '../../utils/utils';
import DataAcquisitionForm, { DataAcquisitionFormRef } from '../../components/DataAcquisitionForm';
import { useDaqFormState } from '../../components/DataAcquisitionForm/common/useDaqFormState';
import { getDeviceTypeData, getPointsByTemplateVersionId } from '../../api/template';
import { getProtocol } from '../../api/protocol';
import { getPhysicalModelList, getRoot, PhysicalModelRes } from '../../api/device';
import { updateMockingbirdDevice, updateEstunDevice } from '../../api/edgeDevice';
import DeviceModal, { DeviceModalRef } from './DeviceModal';
import styles from './index.module.scss';
import { MockingbirdFormValues } from '@/shared/components/DataAcquisitionForm/types';
import useIsGateway from '@/shared/hooks/useIsGateway';

const { Option } = Select;

const routes = [{ name: 'Iot配置' }, { name: '设备管理' }, { name: '设备管理' }, { name: '批量配置数采信息' }];

const layout = {
  labelCol: { style: { width: 120, alignItems: 'center', display: 'flex', justifyContent: 'flex-end', height: 32 } },
  wrapperCol: { span: 9 },
};

interface FormValues {
  iotProtocolType: number;
  deviceType: Array<number>;
  deviceIds: Array<number>;
  rootMcid: string;
  objectModalType: Key;
}

interface DeviceFormProps {
  value?: string;
  onSelect: () => void;
}

const DeviceFormText: React.FC<DeviceFormProps> = ({ value, onSelect }) => (
  <Space>
    <div>
      <span>已选择</span>
      <span className={styles.count}>{value?.length || 0}</span>
      <span>个设备</span>
    </div>
    <Button type="link" onClick={onSelect}>
      继续选择
    </Button>
  </Space>
);

const DAQDeployment: React.FC = () => {
  const deviceTypeData = useAsync(getDeviceTypeData);
  const iotProtocolData = useAsync(getProtocol);

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const state = useDaqFormState();
  const {
    deviceType,
    setDeviceType,
    iotProtocolType,
    setIotProtocolType,
    templateVersionId,
    setTemplateVersionId,
    rootMcid,
    setRootMcid,
    driveType,
    setObjectModalType,
  } = state;
  const customerData = useAsync(getRoot);
  const { isGateway } = useIsGateway(deviceType);

  const [deviceVisible, setDeviceVisible] = useState<boolean>(false);

  const deviceRef: MutableRefObject<DeviceModalRef | null> = useRef(null);
  const daqFormRef: MutableRefObject<DataAcquisitionFormRef | null> = useRef(null);

  const [physicalModelList, setPhysicalModelList] = useState<PhysicalModelRes[]>([]); // all 物模型型号列表
  const [messageApi, messageContextHolder] = message.useMessage();

  useEffect(() => {
    // 物模型型号
    if (deviceType) {
      getPhysicalModelList({ deviceTypeIds: [deviceType[deviceType.length - 1]] }).then(setPhysicalModelList);
    }
  }, [deviceType]);

  const objectModalTypeOptions = useMemo(() => {
    if (physicalModelList && physicalModelList.length > 0) {
      return physicalModelList.map(i => ({ label: `${i.modelNo}-${i.manufacturerName}`, value: i.id }));
    }
  }, [physicalModelList]);

  const { data: points = [], isLoading: pointsLoading } = useQuery(
    useCallback(async () => {
      if (templateVersionId) {
        const points = await getPointsByTemplateVersionId(templateVersionId);
        return points.map(item => ({ ...item, actionType: ActionType.NONE }));
      }
      return [];
    }, [templateVersionId])
  );

  const onSelectDevice = async () => {
    if (deviceType && rootMcid) {
      showDeviceModal();
    } else {
      await messageApi.info('请选择所属类目和所属租户');
    }
  };

  const hideDeviceModal = () => setDeviceVisible(false);
  const showDeviceModal = () => setDeviceVisible(true);
  const handleDeviceModal = () => {
    const deviceIds = deviceRef?.current?.selectedRowKeys;
    deviceIds && form.setFieldsValue({ deviceIds: deviceIds });
    hideDeviceModal();
  };

  const onDeviceTypeSelect = (val: Array<string | number>) => {
    setDeviceType(val as number[]);
    setTemplateVersionId(undefined);

    form.setFieldsValue({ deviceIds: undefined, objectModalType: undefined });
    setObjectModalType(undefined);

    daqFormRef.current?.form?.resetFields();
  };

  const handleRootMcidChange = (val: string) => {
    setRootMcid(val);
    setTemplateVersionId(undefined);
    form.setFieldsValue({ deviceIds: undefined });
    daqFormRef.current?.form?.resetFields();
  };

  const handleIotProtocolTypeChange = (value: number) => {
    setIotProtocolType(value);
    setTemplateVersionId(undefined);
    daqFormRef.current?.form?.resetFields();
  };

  // 物模型型号选择
  const onObjectModalTypeChange = (v: number) => {
    setObjectModalType(v);
    setTemplateVersionId(undefined);
    form.setFieldsValue({ deviceIds: undefined });
    daqFormRef.current?.form?.resetFields();
  };

  const onSubmit = (val: FormValues) => {
    Promise.all([form.validateFields(), daqFormRef.current?.form?.validateFields()]).then(() => {
      const submitValues = {
        deviceIds: val.deviceIds,
        edgeDeviceTemplateVersionId: templateVersionId!,
        iotProtocol: val.iotProtocolType,
        typeId: val.deviceIds[val.deviceIds.length - 1],
        physicalModelId: val.objectModalType,
      };
      if (iotProtocolType === IotProtocolType.ESTUN) {
        updateEstunDevice(submitValues, val.rootMcid).then(() => navigate('/device/manage/device'));
      } else if (iotProtocolType === IotProtocolType.MOCKINGBIRD) {
        const values = daqFormRef.current?.form?.getFieldsValue() as MockingbirdFormValues;
        const params = omit(values, [
          'name',
          'version',
          'versionRemark',
          'deviceTypeName',
          'deviceModelName',
          'iotProtocol',
          'remark',
        ]);
        updateMockingbirdDevice(
          {
            ...submitValues,
            ...params,
            typeId: deviceType!.slice(-1)[0],
            points: points,
          },
          val.rootMcid
        ).then(() => navigate('/device/manage/device'));
      }
    });
  };

  return (
    <Wrapper routes={routes}>
      <div className={styles.details}>
        <Form<FormValues> {...layout} form={form} onFinish={onSubmit}>
          <Form.Item name="deviceType" rules={[{ required: true, message: '请选择所属类目' }]} label="所属类目">
            <Cascader
              options={formatOptionData(deviceTypeData)}
              allowClear={false}
              fieldNames={{ children: 'child' }}
              onChange={onDeviceTypeSelect}
              placeholder={'请选择所属类目'}
            />
          </Form.Item>
          <Form.Item name="objectModalType" label="物模型型号">
            <Select
              allowClear
              optionFilterProp="label"
              showSearch
              options={objectModalTypeOptions}
              onChange={onObjectModalTypeChange}
              disabled={!form.getFieldValue('deviceType')}
              placeholder={form.getFieldValue('deviceType') ? '请选择' : '请先选择所属类目'}
            />
          </Form.Item>
          <Form.Item name="rootMcid" rules={[{ required: true, message: '请选择所属租户' }]} label="所属租户">
            <Select style={{ width: 175 }} placeholder={'请选择租户'} onChange={handleRootMcidChange}>
              {customerData?.map(item => (
                <Option key={item.id} value={item.mcid}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="deviceIds" rules={[{ required: true, message: '请选择设备' }]} label="设备选择">
            <DeviceFormText onSelect={onSelectDevice} />
          </Form.Item>
          <Form.Item name="iotProtocolType" rules={[{ required: true, message: '请选择协议' }]} label="协议选择">
            <Select<IotProtocolType> onChange={handleIotProtocolTypeChange} placeholder={'请选择协议'}>
              {iotProtocolData?.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div className={styles.form}>
            <>
              <DataAcquisitionForm state={state} ref={daqFormRef} />
              {iotProtocolType === IotProtocolType.ESTUN && templateVersionId && (
                <div className={styles.table}>
                  <EdgeDevicePointInfo loading={pointsLoading} dataSource={points} type={iotProtocolType!} />
                </div>
              )}
              {iotProtocolType === IotProtocolType.MOCKINGBIRD &&
                templateVersionId &&
                (driveType !== undefined || isGateway) &&
                deviceType && (
                  <div className={styles.table}>
                    <EdgeDevicePointInfo
                      loading={pointsLoading}
                      dataSource={points}
                      type={iotProtocolType}
                      info={{
                        iotProtocol: iotProtocolType,
                        deviceTypeId: deviceType[deviceType.length - 1],
                        driveType: driveType,
                      }}
                    />
                  </div>
                )}
            </>
          </div>
          <Space className={styles.footer}>
            <Button htmlType="submit" type={'primary'}>
              完成
            </Button>
            <Button onClick={() => navigate(-1)}>取消</Button>
          </Space>
        </Form>
      </div>
      <Modal
        wrapClassName={styles.customModal}
        width={1000}
        title="设备选择"
        open={deviceVisible}
        destroyOnClose
        onOk={handleDeviceModal}
        onCancel={hideDeviceModal}
      >
        <DeviceModal objectModalTypeOptions={objectModalTypeOptions} deviceIds={form.getFieldValue('deviceIds')} ref={deviceRef} state={state} />
      </Modal>
      {messageContextHolder}
    </Wrapper>
  );
};

export default DAQDeployment;
