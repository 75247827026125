import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Modal } from 'antd';
import dayjs from 'dayjs';
import { Table, Paging, usePaging, EllipsisSpan, Wrapper } from '@maxtropy/components';
import { apiMiniAppToolOperationLogPagePost, MiniAppToolOperationLogPagePostResponse } from '@maxtropy/cc-mgmt-apis';
import LogDetail from './Detail';
import { ToolOperationLogData } from '../types';
import styles from '../index.module.scss';

const buildColumns = [
  {
    title: '操作时间',
    dataIndex: 'operationTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '操作人',
    dataIndex: 'createByUsername',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const routes = [{ name: '系统设置' }, { name: '小程序' }, { name: '工具管理' }, { name: '操作日志' }];

const OperationLog = () => {
  const pagingInfo = usePaging(50);

  const { id } = useParams<{ id: string }>();

  const { pageOffset, pageSize, setTotalCount } = pagingInfo;

  const [loading, setLoading] = useState<boolean>(false);

  const [open, setOpen] = useState<boolean>(false);
  const [logListData, setLogListData] = useState<MiniAppToolOperationLogPagePostResponse['list']>([]);

  const [logDetail, setLogDetail] = useState<ToolOperationLogData>();

  const getLogList = () => {
    setLoading(true);
    apiMiniAppToolOperationLogPagePost({
      miniAppToolId: String(id),
      page: String(pageOffset),
      size: String(pageSize),
    })
      .then(res => {
        setTotalCount(res.total as number);
        setLogListData([...(res.list ?? [])]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getLogList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pageOffset, pageSize]);

  const columns = [
    ...buildColumns,
    {
      title: '详情',
      fixed: 'right' as const,
      width: 100,
      render: (v: ToolOperationLogData) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                setOpen(true);
                setLogDetail(v);
              }}
            >
              详情
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <Wrapper routes={routes} className={styles.content}>
      <Table rowKey="id" sticky loading={loading} columns={columns} pagination={false} dataSource={logListData} />

      <Paging pagingInfo={pagingInfo} />

      <Modal width={800} title="日志详情" open={open} onCancel={() => setOpen(false)} footer={null}>
        <LogDetail data={logDetail} />
      </Modal>
    </Wrapper>
  );
};

export default OperationLog;
