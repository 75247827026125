import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Spin, Space, Modal, Popconfirm } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { Wrapper, FormContent } from '@maxtropy/components';
import { fetchGatewayById, updateGatewayDevice } from '../../../api/gateway';
import { Device } from '../../../api/device';
import { useQuery } from '../../../utils/utils';
import DeviceTable from '../components/DeviceTable';
import GatewayDisplay from '../components/GatewayDisplay';
import DeviceModal, { DeviceModalRef } from './DeviceModal';
import styles from '../index.module.scss';

const routes = [{ name: 'Iot配置' }, { name: '物联配置' }, { name: '网关管理' }, { name: '设备绑定' }];

const GatewayDetail: React.FC = () => {
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const query = Object.fromEntries(urlSearchParams.entries());
  const { id } = useParams<{ id: string }>();

  const [visible, setVisible] = useState(false);
  const [devices, setDevices] = useState<Device[]>([]);
  const deviceRef: MutableRefObject<DeviceModalRef | null> = useRef(null);

  const { data: info, isLoading: isInfoLoading } = useQuery(
    useCallback(() => {
      if (id) return fetchGatewayById(id);
      return Promise.resolve(undefined);
    }, [id])
  );

  useEffect(() => {
    if (info && info.deviceWithEdgeDeviceDtos) {
      const data = info.deviceWithEdgeDeviceDtos.map(item => ({
        ...item.device,
      }));
      setDevices(data);
    }
  }, [info]);

  const handleOK = async () => {
    const selectedRows = deviceRef?.current?.selectedRows;
    // if ((selectedRows || []).length + devices.length > 10) {
    //   message.warning("最多选择十个设备");
    //   return;
    // }
    if (selectedRows) {
      const data = devices.concat(selectedRows);
      setDevices(data);
    }
    setVisible(false);
  };

  const onSubmit = async () => {
    if (info) {
      const params = {
        deviceIds: devices.map(item => item.id),
        edgeGatewayId: info.id,
        protocol: info.iotProtocol,
      };
      await updateGatewayDevice(params);
      navigate('/device/config/gateway', {
        replace: true,
      });
    }
  };

  return (
    <Wrapper routes={routes} className={styles.wrapper}>
      <Spin spinning={isInfoLoading}>
        <div className={styles.bd}>
          <FormContent title="基础信息">
            <GatewayDisplay row={info} />
          </FormContent>
          <FormContent title="关联设备">
            <div style={{ marginBottom: 10 }}>
              <Button type="primary" icon={<PlusOutlined />} onClick={() => setVisible(true)}>
                添加设备
              </Button>
            </div>
            <DeviceTable devices={devices} setDevices={setDevices} iotProtocol={info?.iotProtocol} />
          </FormContent>
        </div>
      </Spin>
      <Space className="sticky-footer" style={{ paddingLeft: 40 }}>
        {query.previous === 'true' && (
          <Button
            type="primary"
            onClick={() =>
              navigate(`/device/config/gateway/update/${id}?next=true`, {
                replace: true,
              })
            }
          >
            上一步
          </Button>
        )}
        <Popconfirm
          okText="继续"
          placement="topRight"
          onConfirm={onSubmit}
          title={
            <>
              <div>确定保存？</div>
            </>
          }
        >
          <Button type="primary">保存</Button>
        </Popconfirm>

        <Button
          onClick={() =>
            navigate('/device/config/gateway', {
              replace: true,
            })
          }
        >
          取消
        </Button>
      </Space>
      <Modal
        destroyOnClose
        wrapClassName={styles.customModal}
        width={1024}
        title="设备选择"
        open={visible}
        onOk={handleOK}
        onCancel={() => setVisible(false)}
      >
        <DeviceModal ref={deviceRef} iotProtocol={info?.iotProtocol} selectedItems={devices} />
      </Modal>
    </Wrapper>
  );
};

export default GatewayDetail;
