import React from 'react';
import { alarmIotProtocolFormItemsByType } from './factory';
import { IotProtocolType } from '../../types';

interface AlarmIotProtocolFormItemProps {
  type: IotProtocolType;
}

const AlarmIotProtocolFormItem: React.FC<AlarmIotProtocolFormItemProps> = ({ type }) => {
  const FormItems = alarmIotProtocolFormItemsByType[type];

  return <>{FormItems && <FormItems />}</>;
};

export default AlarmIotProtocolFormItem;
