import { FC, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Form, Row, Col, Button, Modal, Space } from 'antd';
import { Wrapper } from '@maxtropy/components';

import styles from '../index.module.scss';
import { createQl, getQlDetail, QlDetailProps, updateQl } from '../../../api/cc-tenant-ql';
import { useHasPermission } from '../../../utils/hooks';
import { PermissionsType } from '../../../common/permissionsConst';
import { routes, tailLayout } from '../common';
import BaseInfo from './BaseInfo';
import QuotaManage, { QuotaRefProps } from './QuotaManage';
import AppConfig, { AppConfigRef } from './AppConfig';

interface Iprops {
  isSet?: boolean; // 区分开户和开户设置
}

const CreateOaliteAccount: FC<Iprops> = ({ isSet }) => {
  const [modalApi, modalContextHolder] = Modal.useModal();
  const navigate = useNavigate();
  const showQlAccountOpenBtn = useHasPermission(PermissionsType.B_QING_FLOW_ACCOUNT_OPEN);
  const showQlAccountOpenConfigBtn = useHasPermission(PermissionsType.B_QING_FLOW_ACCOUNT_OPEN_CONFIG);
  const { mcid } = useParams<{ mcid: string }>();
  const quotaRef = useRef<QuotaRefProps>({});
  const appConfigRef = useRef<AppConfigRef>({});

  const [initFormData, setInitFormData] = useState<QlDetailProps>();
  const [searchParams] = useSearchParams();
  const workspaceId = searchParams.get('workspaceId') ?? undefined;
  const workspaceIdCode = searchParams.get('workspaceIdCode') ?? undefined;

  // 获取开户详情
  useEffect(() => {
    if (mcid) {
      getQlDetail(mcid).then(res => {
        // 已开户
        if (isSet) {
          setInitFormData(res);
        } else {
          // 未开户
          setInitFormData({
            ...res,
            qingFlowWorkspaceIdCode: workspaceIdCode,
          });
        }
      });
    }
  }, [isSet, mcid, workspaceIdCode]);

  // 取消按钮
  const onCancel = () => {
    modalApi.confirm({
      content: <div>是否放弃所有未保存信息并返回列表？</div>,
      onOk: () => {
        navigate('/tenant/customer/oalite');
      },
    });
  };

  // 更改设置按钮
  const changeSettingBtn = async () => {
    // 表单校验
    let validQuota = await quotaRef.current.checkForm?.();
    if (!validQuota) return;
    let validConfig = await appConfigRef.current.checkForm?.();
    if (!validConfig) return;
    modalApi.confirm({
      content: (
        <>
          <div>
            【{initFormData?.tenantName}】已开户/应用配置已变更，是否前往三方平台配置应用包/应用/门户对角色的可见关系？
          </div>
        </>
      ),
      okText: '确认',
      onOk: opApiBtn,
    });
  };
  // 开户or更新api
  const opApiBtn = async () => {
    let userQuota = quotaRef.current.getValues!();
    let configData = appConfigRef.current.getValues!();
    let { workOrder, integrateAppModules } = configData;
    const { qingFlowWorkspaceIdCode, ...rest } = initFormData!;
    if (isSet) {
      updateQl({
        ...rest,
        userQuota,
        workOrder,
        integrateAppModules,
      }).then(_ => {
        navigate('/tenant/customer/oalite');
      });
    } else {
      createQl({
        ...rest,
        userQuota,
        workOrder,
        integrateAppModules,
        qingFlowWorkspaceId: workspaceId ?? '',
      }).then(_ => {
        navigate('/tenant/customer/oalite');
      });
    }
  };

  return (
    <Wrapper routes={routes}>
      <div className={styles.container}>
        {/* 租户基础信息 */}
        <BaseInfo isSet={isSet} initData={initFormData} />
        {/* 配额管理 */}
        <QuotaManage ref={quotaRef} isSet={isSet} initData={initFormData} mcid={mcid} />
        <div className={styles.mg_t}></div>
        {/* 应用配置 */}
        <AppConfig ref={appConfigRef} isSet={isSet} initData={initFormData} mcid={mcid} workspaceId={workspaceId} />
        <Row className={styles.buttonWrapper}>
          <Col span={7}>
            <Form.Item {...tailLayout}>
              <Space>
                {showQlAccountOpenConfigBtn && isSet && (
                  <Button type="primary" onClick={changeSettingBtn}>
                    更改设置
                  </Button>
                )}
                {showQlAccountOpenBtn && !isSet && (
                  <Button type="primary" onClick={changeSettingBtn}>
                    确认开户
                  </Button>
                )}
                <Button onClick={onCancel}>关闭</Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </div>
      {modalContextHolder}
    </Wrapper>
  );
};

export default CreateOaliteAccount;
