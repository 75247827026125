import { FormContent, Wrapper } from '@maxtropy/components';
import { Button, Col, Form, Modal, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from '../index.module.scss';
import SelectTenant from './SelectTenant';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import ShowInput from '@/shared/components/ShowInput';
import { getTemplate } from '../../../api/template';
import { getTemplateList, TemplateListRes, TenantAssign } from '../../../api/tenantAttribute';

const routes = [{ name: 'Iot配置' }, { name: '数采配置' }, { name: '模板分配' }, { name: '租户分配属性模板' }];

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

interface ValueProps {
  serialNumber: string;
  name: string;
  tenantList: TemplateListRes[];
}

const TenantDistribution: React.FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [info, setInfo] = useState<TemplateListRes[]>();
  const [modalApi, modalContextHolder] = Modal.useModal();

  useEffect(() => {
    if (id) {
      getTemplate(Number(id)).then(res => {
        form.setFieldsValue(res.template);
      });
      getTemplateList(id).then(res => {
        form.setFieldsValue({
          tenantList: res,
        });
        setInfo(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const goList = () => {
    navigate(`/device/info/distribution${search}`);
  };

  const onCacel = () => {
    modalApi.confirm({
      title: null,
      icon: null,
      content: <div>是否放弃所有未保存信息并返回列表？</div>,
      onOk: goList,
    });
  };

  const onFinish = (value: ValueProps) => {
    if (id) {
      const currentIds = (value.tenantList ?? []).map(i => i.mcid);
      const tenantsId = (info ?? []).map(i => i.mcid);
      let params = {};
      if (tenantsId.length === 0) {
        params = {
          addMcids: currentIds,
        };
      } else {
        params = {
          deleteMcids: tenantsId.filter(i => !currentIds.includes(i)),
          addMcids: currentIds.filter(i => !tenantsId.includes(i)),
        };
      }
      TenantAssign({ templateId: id, ...params }).then(res => {
        if (res) goList();
      });
    }
  };

  return (
    <Wrapper routes={routes} className={styles.wrapper}>
      <Form form={form} layout="vertical" onFinish={onFinish} {...formLayout}>
        <FormContent title="模板信息">
          <Row className={styles.row}>
            <Col span={12} className={styles.col}>
              <Form.Item label="模板code" name="serialNumber">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={12} className={styles.col}>
              <Form.Item label="模板名称" name="name">
                <ShowInput />
              </Form.Item>
            </Col>
          </Row>
        </FormContent>
        <FormContent title="分配租户">
          <Form.Item name="tenantList" wrapperCol={{ span: 24 }}>
            <SelectTenant />
          </Form.Item>
        </FormContent>
        <Space className="sticky-footer">
          <Button type="primary" htmlType="submit">
            保存
          </Button>
          <Button className={styles.button} onClick={onCacel}>
            取消
          </Button>
        </Space>
      </Form>
      {modalContextHolder}
    </Wrapper>
  );
};

export default TenantDistribution;
