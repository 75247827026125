import React from 'react';
import { Col, Form, Input } from 'antd';
import styles from '../../../List/index.module.scss';

interface Props {}
const LongShineFormItem: React.FC<Props> = () => {
  return (
    <>
      <Col span={8} className={styles.col}>
        <Form.Item
          label="三方报警码"
          name="thirdAlarmCode"
          rules={[
            { required: true, message: '请输入三方报警码' },
            { max: 20, message: '最多输入二十个字' },
          ]}
        >
          <Input placeholder="请输入" />
        </Form.Item>
      </Col>
    </>
  );
};

export default LongShineFormItem;
