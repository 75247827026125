import React, { useEffect, useState } from 'react';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import { useBoolean } from 'ahooks';
import { isEmpty, reject } from 'lodash-es';
import { CardLayout, List } from './types';
import { getRealUrl } from '@maxtropy/components';
import {
  apiV2MiniAppToolEditPost,
  apiV2MiniAppToolGetListPost,
  V2MiniAppToolGetListPostResponse,
} from '@maxtropy/cc-mgmt-apis-v2';
import AddCardModal from './AddCardModal';
import GridLayout from 'react-grid-layout';
import classnames from 'classnames/bind';
import styles from './index.module.scss';

type DataMap = Record<
  'small' | 'middle' | 'large',
  {
    width: number;
    height: number;
    className: string;
  }
>;

const dataMap: DataMap = {
  small: {
    width: 1,
    height: 1,
    className: 'small',
  },
  middle: {
    width: 1,
    height: 2,
    className: 'middle',
  },
  large: {
    width: 2,
    height: 2,
    className: 'large',
  },
};

const cx = classnames.bind(styles);

interface Props {
  listId?: number;
  currentMenuId?: number;
  currentHomePageInfo: V2MiniAppToolGetListPostResponse['list'];
  setCurrentHomePageInfo: (data: V2MiniAppToolGetListPostResponse['list']) => void;
  update: () => void;
}
const CardHomePage: React.FC<Props> = ({ listId, currentMenuId, currentHomePageInfo, setCurrentHomePageInfo }) => {
  const [modalVisible, { setTrue, setFalse }] = useBoolean(false);

  // 卡片分页
  const [list, setList] = useState<List[]>();

  const [layoutData, setLayoutData] = useState<CardLayout[]>([]);
  const [serverData, setServerData] = useState<V2MiniAppToolGetListPostResponse['list']>();
  const [currentLayoutData, setCurrentLayoutData] = useState<GridLayout.Layout[]>();

  // 获取菜单下的卡片列表
  const getCardList = () => {
    currentMenuId &&
      apiV2MiniAppToolGetListPost({
        id: currentMenuId,
      }).then(res => {
        const { list } = res;
        setCurrentHomePageInfo(list);
      });
  };

  // 添加卡片
  const handleAddCard = () => {
    const cardLayoutData = sessionStorage.getItem('cardLayoutData');
    const layout = cardLayoutData ? JSON.parse(cardLayoutData) : [];
    const maxY = layout.reduce((prev: number, curr: GridLayout.Layout) => (curr.y > prev ? curr.y : prev), 0);

    const originalIds = currentHomePageInfo?.map(item => item.originalId);
    const filterRows = list
      ?.filter(item => !originalIds?.includes(item.id) && item.cardSize)
      .map(item => ({
        id: item.id,
        name: item.name,
        categoryId: item.categoryId,
        cardSize: item.cardSize,
        rowNumber: maxY ? maxY + 1 : 0,
        columnNumber: 0,
      }));

    if (isEmpty(filterRows)) {
      message.warning('请选择卡片');
      return;
    }

    apiV2MiniAppToolEditPost({
      menuId: currentMenuId,
      toolAddRequests: filterRows,
    }).then(() => {
      getCardList();
      setFalse();
    });
  };

  // 删除卡片
  const handleRemoveCard = (id: string) => {
    setServerData(reject(serverData, item => item.id!.toString() === id));
    apiV2MiniAppToolEditPost({
      menuId: currentMenuId,
      deleteToolIds: [+id],
    }).then(() => {
      getCardList();
    });
  };

  // 获取布局数据
  const getLayoutData = (serverData: V2MiniAppToolGetListPostResponse['list'] = []): CardLayout[] => {
    return serverData
      .map(item => {
        const keys = ['small', 'middle', 'large'][item?.cardSize! - 1];
        const dataMapKeys = Object.keys(dataMap);
        if (dataMapKeys.includes(keys)) {
          const { width, height, className } = dataMap[keys as keyof typeof dataMap];
          return item.id
            ? {
                i: item.id.toString(),
                w: width,
                h: height,
                x: item.columnNumber,
                y: item.rowNumber,
                className,
                name: item.name,
                cardSize: item.cardSize,
                picKey: item.picKey,
              }
            : null;
        }
        return null;
      })
      .filter(Boolean) as CardLayout[];
  };

  // 布局改变后，保存布局数据
  const handleLayoutChange = (layout: GridLayout.Layout[]) => {
    setCurrentLayoutData(layout);
    sessionStorage.setItem('cardLayoutData', JSON.stringify(layout));
    sessionStorage.setItem('cardPageId', JSON.stringify(currentMenuId));
  };

  useEffect(() => {
    if (!currentLayoutData) return;
    const postLayoutData = currentLayoutData.map(item => {
      const { x, y, i } = item;
      return {
        id: +i,
        columnNumber: x,
        rowNumber: y,
      };
    });
    apiV2MiniAppToolEditPost({
      menuId: currentMenuId,
      toolEditRequests: postLayoutData,
    }).then(() => {
      getCardList();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLayoutData, currentMenuId]);

  useEffect(() => {
    if (serverData?.length) {
      const data = getLayoutData(serverData);
      setLayoutData(data);
    }
  }, [serverData]);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('cardLayoutData');
      sessionStorage.removeItem('cardPageId');
    };
  }, []);

  useEffect(() => {
    setServerData(currentHomePageInfo);
  }, [currentHomePageInfo]);

  return (
    <>
      <div className={styles.cardWrapper}>
        {serverData && serverData.length > 0 && (
          <>
            <div>
              <GridLayout
                className={styles.cardArea}
                layout={layoutData}
                cols={2}
                rowHeight={78}
                width={360}
                margin={[16, 16]}
                isResizable={false}
                useCSSTransforms={false}
                onLayoutChange={layout => {
                  handleLayoutChange(layout);
                }}
                // onDragStop={layout => {
                //   transferToServerData(layout);
                // }}
              >
                {layoutData.map(item => {
                  const picKey = getRealUrl(item.picKey);
                  const backgroundImage = `url('${picKey}')`;
                  return (
                    <div key={item.i} className={styles[item.className!]} style={{ backgroundImage: backgroundImage }}>
                      {/* <div className={styles.cardTitle}>{item.name}</div> */}
                      <div
                        className={styles.deleteBtn}
                        onClick={e => {
                          handleRemoveCard(item.i);
                          e.nativeEvent.stopImmediatePropagation();
                          e.stopPropagation();
                        }}
                      >
                        <CloseOutlined className={styles.closeIcon} />
                      </div>
                    </div>
                  );
                })}
              </GridLayout>
            </div>
          </>
        )}

        <Button
          icon={<PlusOutlined />}
          className={cx(currentHomePageInfo?.length ? 'addCardBtnWithData' : 'addCardBtn')}
          onClick={setTrue}
        >
          添加卡片
        </Button>

        <Modal
          title="添加卡片"
          width={800}
          open={modalVisible}
          onCancel={setFalse}
          footer={
            <>
              <Button
                onClick={() => {
                  setFalse();
                }}
              >
                取消
              </Button>
              <Button type="primary" onClick={handleAddCard}>
                确定添加
              </Button>
            </>
          }
          destroyOnClose
        >
          <AddCardModal listId={listId} currentHomePageInfo={currentHomePageInfo} list={list} setList={setList} />
        </Modal>
      </div>
    </>
  );
};

export default CardHomePage;
