export interface Options {
  label: string | undefined;
  value: number | undefined;
}

export interface Query {
  name?: string;
  industryId?: string;
  status?: Status;
}

export enum Status {
  DRAFT = 0,
  ENABLE = 1,
  DISABLE = 2,
}

export const StatusDisplay = {
  [Status.DRAFT]: '草稿',
  [Status.ENABLE]: '启用',
  [Status.DISABLE]: '禁用',
};

export const StatusBtnDisplayMap = {
  [Status.DRAFT]: '启用',
  [Status.ENABLE]: '禁用',
  [Status.DISABLE]: '启用',
};

export interface TableData {
  page: number;
  size: number;
  total: number;
  list: List[];
}

export interface List {
  id: number;
  name: string;
  industryId: number;
  createTime: number;
  updateTime: number;
  calculateFormula: string;
  state: number;
}
