import { useRequest } from 'ahooks';
import { Button, Form, Input, Select, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RulesGroupContext } from '..';
import { getRoot } from '../../../api/mockCustomer';
import { RulesGroupStatus } from '../../../api/type';
import { ApiRulesSetPostRequest } from '../../../ytt/types/api/rulesSet';
import { apiDataPropertyGroupTreeGet } from '../../../ytt/types/dataPropertyGroup/tree';
import styles from '../index.module.scss';

const SceneFormItems = () => {
  const [currentSceneId, setCurrentSceneId] = useState<number>();
  const { nextStep, saveForm, saveLoading, data: details } = useContext(RulesGroupContext);
  const { data: tenants } = useRequest(getRoot);
  const { data: scenes } = useRequest(apiDataPropertyGroupTreeGet);
  const [form] = useForm<ApiRulesSetPostRequest>();
  const navigate = useNavigate();
  const groups = useMemo(() => {
    if (scenes) {
      return scenes.find(item => item.sceneId === currentSceneId)?.dataPropertyGroupList;
    }
  }, [currentSceneId, scenes]);

  const nextBtn = (save = true) => {
    form.validateFields().then(values => {
      saveForm?.({ ...values, save }).then(id => {
        if (id) {
          // 点击保存草稿后跳转到列表页
          if (!save) {
            navigate(-1);
            return;
          }
          nextStep?.();
        }
      });
    });
  };
  useEffect(() => {
    if (details) {
      if (details.sceneId) {
        setCurrentSceneId(details.sceneId);
      }
      form.setFieldsValue({
        ...details,
      });
    }
  }, [details]);
  // 发布状态下, 所属租户/场景/属性组 不允许编辑
  const formTtemDisable = useMemo(() => {
    return typeof details?.status === 'number' && details?.status !== +RulesGroupStatus.DRAFT;
  }, [details]);
  return (
    <div className={styles.form_content}>
      <Form layout="vertical" form={form}>
        <Form.Item
          label="名称"
          name="name"
          rules={[{ required: true }, { pattern: /^.{2,50}$/, message: '限制2-50个字之间' }]}
        >
          <Input placeholder="请输入名称" maxLength={50}></Input>
        </Form.Item>
        <Form.Item label="所属租户" name="mcid" rules={[{ required: true }]}>
          <Select
            showSearch
            disabled={formTtemDisable}
            placeholder="请选择租户"
            optionFilterProp="label"
            options={(tenants ?? []).map(v => ({ label: v.name, value: v.mcid }))}
          />
        </Form.Item>
        <Form.Item label="场景" name="sceneId" rules={[{ required: true }]}>
          <Select
            showSearch
            disabled={formTtemDisable}
            placeholder="请选择场景"
            optionFilterProp="label"
            onChange={v => {
              setCurrentSceneId(v);
              form.setFieldsValue({
                dataPropertyGroupId: undefined,
              });
            }}
            options={(scenes ?? []).map(v => ({ label: v.sceneName, value: v.sceneId }))}
          />
        </Form.Item>
        <Form.Item label="属性组" name="dataPropertyGroupId" rules={[{ required: true }]}>
          <Select
            showSearch
            disabled={formTtemDisable}
            placeholder="请选择属性组"
            optionFilterProp="label"
            options={(groups ?? []).map(v => ({ label: v.name, value: v.id }))}
          />
        </Form.Item>
        <Form.Item label="说明" name="detail">
          <TextArea showCount maxLength={200} style={{ height: 120, resize: 'none' }} />
        </Form.Item>
        <div style={{ paddingTop: 10 }}>
          <Form.Item>
            <Space>
              <Button type="primary" onClick={() => nextBtn(false)} loading={saveLoading}>
                保存草稿
              </Button>
              <Button type="primary" onClick={() => nextBtn(true)} loading={saveLoading}>
                下一步
              </Button>
            </Space>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
export default SceneFormItems;
