import React from 'react';
import styles from './index.module.scss';

interface FormContainerProps {
  title: string;
  children: React.ReactNode;
}

const FormContainer: React.FC<FormContainerProps> = props => {
  const { title } = props;

  return (
    <div className={styles.personalFormContainer}>
      <p className={styles.formTitle}>
        <span className={styles.titleBorder} />
        {title}
      </p>
      {props.children}
    </div>
  );
};

export default FormContainer;
