import { FormContent, Paging, Table, usePaging, Wrapper } from '@maxtropy/components';
import { Button, Col, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { selectTemplateColumns } from '../utils';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import ShowInput from '@/shared/components/ShowInput';
import { getTenantInfo, getTenantPage, TenantListRes } from '../../../api/tenantAttribute';

const routes = [{ name: 'Iot配置' }, { name: '设备信息模板库' }, { name: '模板分配' }, { name: '属性模板分配详情' }];

const formLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 18 },
};

const TemplateDetail: React.FC = () => {
  const [form] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [template, setTemplate] = useState<TenantListRes[]>([]);
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;

  useEffect(() => {
    if (id) {
      getTenantInfo(id).then(data => {
        form.setFieldsValue(data);
      });
      setLoading(true);
      getTenantPage({ mcid: id, page: pageOffset, size: pageSize }).then(res => {
        if (res) {
          setTemplate(res.list);
          setTotalCount(res.total);
          setLoading(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, pageOffset, pageSize]);

  return (
    <Wrapper routes={routes} {...formLayout}>
      <FormContent title="租户信息">
        <Form layout="vertical" form={form}>
          <Row>
            <Col span={12}>
              <Form.Item label="租户code" name="tenantCode">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="租户名称" name="name">
                <ShowInput />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </FormContent>
      <FormContent title="属性信息模板">
        <Table columns={selectTemplateColumns} dataSource={template} loading={loading} />
        <Paging pagingInfo={pagingInfo} />
        <Button style={{ marginTop: 20 }} onClick={() => navigate(`/device/info/distribution${search}`)}>
          返回
        </Button>
      </FormContent>
    </Wrapper>
  );
};

export default TemplateDetail;
