import { fetch } from '@maxtropy/components';
import { RuleSet, RuleSetContent, RuleSetRequest, PageResponse } from './entities';
import qs from 'qs';

export function getRuleSets(params: RuleSetRequest): Promise<PageResponse<RuleSet>> {
  return fetch(`/api/rule-set/page?${qs.stringify(params, { indices: false })}`);
}

export function getRuleSet(id: number): Promise<RuleSet> {
  return fetch(`/api/rule-set/${id}`);
}

export function createRuleSet(ruleSet: RuleSetContent): Promise<RuleSet> {
  return fetch(`/api/rule-set`, {
    method: 'POST',
    body: JSON.stringify(ruleSet),
  });
}

export function updateRuleSet(id: number, ruleSet: RuleSetContent): Promise<RuleSet> {
  return fetch(`/api/rule-set/${id}`, {
    method: 'PUT',
    body: JSON.stringify(ruleSet),
  });
}

export function deleteRuleSet(id: number): Promise<void> {
  return fetch(`/api/rule-set/${id}`, { method: 'DELETE' });
}

export function enableRuleSet(id: number): Promise<void> {
  return fetch(`/api/rule-set/${id}/enable`, { method: 'POST' });
}

export function disableRuleSet(id: number): Promise<void> {
  return fetch(`/api/rule-set/${id}/disable`, { method: 'POST' });
}
