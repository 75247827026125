import { InputNumber, Space } from 'antd';
import React from 'react';
import { ControlProps } from '.';
import { MinMaxConstraintValue, RangeFieldValue } from '../../types';

const RangeInput: React.FC<ControlProps> = ({
  placeholder,
  disabled,
  isDetail,
  digitalAccuracy,
  constraintValue,
  value,
  onChange,
  unit,
}) => {
  const { min, max } = constraintValue
    ? (constraintValue as MinMaxConstraintValue)
    : { min: undefined, max: undefined };
  const v = value?.value as RangeFieldValue | undefined;

  if (isDetail) {
    return (
      <Space>
        <div>{v?.min}</div>
        <div>~</div>
        <div>{v?.max}</div>
      </Space>
    );
  }
  return (
    <Space size={20}>
      <InputNumber
        style={{ width: 160 }}
        placeholder={placeholder ?? '请输入'}
        min={min}
        max={max}
        precision={digitalAccuracy}
        disabled={disabled}
        value={v?.min}
        onChange={n => {
          const rangeValue: RangeFieldValue = (value?.value as RangeFieldValue) || {};
          onChange && onChange({ ...value, value: { ...rangeValue, min: n! } });
        }}
      />
      <div>~</div>
      <InputNumber
        style={{ width: 160 }}
        placeholder={placeholder ?? '请输入'}
        min={min}
        max={max}
        precision={digitalAccuracy}
        disabled={disabled}
        value={v?.max}
        onChange={n => {
          const rangeValue: RangeFieldValue = (value?.value as RangeFieldValue) || {};
          onChange && onChange({ ...value, value: { ...rangeValue, max: n! } });
        }}
      />
    </Space>
  );
};

export default RangeInput;
