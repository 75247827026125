import React from 'react';

export default function useControlledState<T, R = T>(
  defaultStateValue: T | (() => T),
  option?: {
    defaultValue?: T | (() => T);
    value?: T;
    onChange?: (value: T, prevValue?: T) => void;
  }
): [R, (value: T) => void] {
  const { defaultValue, value, onChange } = option || {};
  const [innerValue, setInnerValue] = React.useState<T | undefined>(() => {
    if (value !== undefined) {
      return value;
    }
    if (defaultValue !== undefined) {
      return typeof defaultValue === 'function' ? (defaultValue as any)() : defaultValue;
    }
    return typeof defaultStateValue === 'function' ? (defaultStateValue as any)() : defaultStateValue;
  });

  let mergedValue = value !== undefined ? value : innerValue;

  function triggerChange(newValue: T) {
    setInnerValue(newValue);
    if (mergedValue !== newValue && onChange) {
      onChange(newValue);
    }
  }

  // Effect of reset value to `undefined`
  const firstRenderRef = React.useRef(true);
  React.useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }

    if (value === undefined) {
      setInnerValue(value);
    }
  }, [value]);

  return [mergedValue as unknown as R, triggerChange];
}
