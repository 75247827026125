import React from 'react';
import { FormInstance } from 'antd';

export interface BathEdgeContentProps {
  baseForm?: FormInstance;
  isMgmt: boolean;
}

export const BatchEdgeContent = React.createContext<BathEdgeContentProps>({
  isMgmt: true,
});
