import React, { useMemo } from 'react';
import JsonTree, { JsonTreeProps } from './index';

export const JsonTreeContext = React.createContext({ showDescr: false, format: 'text' });
JsonTreeContext.displayName = 'JsonTreeContext';

interface JsonTreeContentProps extends JsonTreeProps {
  format: string;
  showDescr?: boolean;
}

const JsonTreeContent: React.FC<JsonTreeContentProps> = ({ showDescr = false, format = 'text', ...restProps }) => {
  const contextValue = useMemo(() => {
    return { showDescr, format };
  }, [showDescr, format]);
  return (
    <JsonTreeContext.Provider value={contextValue}>
      <JsonTree {...restProps}></JsonTree>
    </JsonTreeContext.Provider>
  );
};

export default JsonTreeContent;
