import React, { useCallback } from 'react';
import { Button, Spin } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { Wrapper, FormContent, Table, EllipsisSpan } from '@maxtropy/components';
import { IotProtocolType } from '@/shared/types';
import { fetchGatewayById } from '../../api/gateway';
import { Device } from '../../api/device';
import { useHasPermission, useQuery } from '../../utils/utils';
import GatewayDisplay from './components/GatewayDisplay';
import styles from './index.module.scss';
import qs from 'qs';
import { PermissionsType } from '../../common/permissionsConst';

const routes = [{ name: 'Iot配置' }, { name: '物联配置' }, { name: '网关管理' }, { name: '网关查看' }];

export const deviceColumns = (isDetail?: boolean) => {
  const edgeDeviceId = isDetail
    ? [
        {
          title: '物理设备ID',
          dataIndex: 'id',
          ellipsis: { showTitle: true },
          render: (v: string) => <EllipsisSpan value={v} />,
        },
      ]
    : [];
  return [
    {
      title: '设备编号',
      dataIndex: 'code',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '设备名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    ...edgeDeviceId,
    {
      title: '设备S/N码',
      dataIndex: 'sn',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '所属类目',
      dataIndex: 'typeName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '设备标签',
      dataIndex: 'tags',
      ellipsis: { showTitle: true },
      render: (v: string[]) => {
        const tags = v ? v.join(', ') : '';
        return <EllipsisSpan value={tags} />;
      },
    },
    {
      title: '所属组织',
      dataIndex: 'customerName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
  ];
};

export const columns = [
  {
    title: '网关中的设备序号',
    dataIndex: 'sequence',
    ellipsis: { showTitle: true },
    render: (_: undefined, record: Device, index: number) => index + 1,
  },
  ...deviceColumns(true),
];

const GatewayDetail: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { data: info, isLoading: isInfoLoading } = useQuery(
    useCallback(() => {
      if (id) return fetchGatewayById(id);
      return Promise.resolve(undefined);
    }, [id])
  );

  const devices = info?.deviceWithEdgeDeviceDtos?.map(item => ({
    ...item.device,
  }));

  const exportAction = () => {
    const params = {
      ids: id,
    };
    window.open(`/api/gateway/download?${qs.stringify(params, { indices: false })}`);
  };

  return (
    <Wrapper routes={routes} className={styles.wrapper}>
      <Spin spinning={isInfoLoading}>
        <div className={styles.bd}>
          <FormContent title="基础信息">
            <GatewayDisplay row={info} />
          </FormContent>
          <FormContent title="关联设备">
            {useHasPermission(PermissionsType.B_EXPORTGATEWAYDEVICE) && (
              <Button type="primary" onClick={exportAction} style={{ marginBottom: 20 }}>
                网关与设备关系导出
              </Button>
            )}
            <Table
              rowKey="id"
              dataSource={devices}
              columns={info?.iotProtocol === IotProtocolType.MOCKINGBIRD ? columns.slice(1, columns.length) : columns}
              pagination={false}
            />
          </FormContent>
        </div>
      </Spin>
      <div className={styles.ft} style={{ padding: '30px 40px' }}>
        <Button onClick={() => navigate(-1)}>返回</Button>
      </div>
    </Wrapper>
  );
};

export default GatewayDetail;
