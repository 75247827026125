import { fetch } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import qs from 'qs';
import { Status } from './template';

export interface TenantListRes {
  id: number;
  name: string;
  serialNumber: string;
  deviceTypeId: number;
  deviceTypeName: string;
  lastVersion: number;
  status: Status;
  tenantUseCount: number;
}

export const getTenantList = (mcid: string) => fetch<TenantListRes[]>(`/api/tenant-attribute/tenant/list?mcid=${mcid}`);

export interface TemplateAssignReq {
  mcid: string;
  addTemplateIds?: number[];
  deleteTemplateIds?: number[];
}

export const TemplateAssign = (body: TemplateAssignReq) =>
  fetch(`/api/tenant-attribute/template/assign`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'x-tenant-mcid': body.mcid,
    },
  });

export interface TenantPageReq extends PageRequest {
  mcid: string;
}

export const getTenantPage = (query: TenantPageReq) =>
  fetch<PageResponse<TenantListRes>>(`/api/tenant-attribute/tenant/page?${qs.stringify(query, { indices: false })}`);

export interface TemplateListRes {
  id?: number;
  mcid: string;
  name: string;
  tenantCode: string;
  uscc: string;
}

export const getTemplateList = (templateId: string) =>
  fetch<TemplateListRes[]>(`/api/tenant-attribute/template/list?templateId=${templateId}`);

export interface TemplatePageReq extends PageRequest {
  templateId: string;
}

export const getTemplatePage = (query: TemplatePageReq) =>
  fetch<PageResponse<TemplateListRes>>(
    `/api/tenant-attribute/template/page?${qs.stringify(query, { indices: false })}`
  );

export interface TenantAssignReq {
  templateId: string;
  addMcids?: string[];
  deleteMcids?: string[];
}

export const TenantAssign = (body: TenantAssignReq) =>
  fetch(`/api/tenant-attribute/tenant/assign`, {
    method: 'POST',
    body: JSON.stringify(body),
  });

export interface TenantInfoRes {
  mcid: string;
  name: string;
  tenantCode: string;
  accountOpenStatus: boolean;
  uscc: string;
  adCode: string;
  address: string;
  id: number;
}

export const getTenantInfo = (mcid: string) => fetch<TenantInfoRes>(`/api/tenant/info?mcid=${mcid}`);
