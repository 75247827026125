import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import DebounceSelect from './DebounceSelect';
import { uniqBy } from 'lodash-es';
import './index.scss';
import { useControllableValue } from 'ahooks';
import { getAllTenant } from '../../../api/cc-tenant';

export interface OptionsProps {
  label: string;
  value: string;
}

async function fetchList(name: string): Promise<OptionsProps[]> {
  return name
    ? getAllTenant({ value: name }).then(data => {
        return (data ?? []).map(({ mcid, name }) => ({ value: mcid, label: name })).slice(0, 10);
      })
    : [];
}

interface SelectSelectionListProps {
  value: OptionsProps[];
  onDelete: (value: OptionsProps) => void;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const SelectSelectionList: React.FC<SelectSelectionListProps> = props => {
  const { value, onDelete, style } = props;
  return (
    <div className="mgmt-select-selection" style={style}>
      {value.map(item => (
        <span className="mgmt-select-selection-item" key={item.value}>
          <span className="mgmt-select-selection-item-content">{item.label}</span>
          {!props.disabled && (
            <CloseOutlined className="mgmt-select-selection-item-remove" onClick={() => onDelete(item)} />
          )}
        </span>
      ))}
    </div>
  );
};

interface TenantSelectProps {
  value?: OptionsProps[];
  onChange?: (value: OptionsProps[]) => void;
  onDelete?: (value: OptionsProps) => void;
  disabled?: boolean;
}

const TenantSelect: React.FC<TenantSelectProps> = props => {
  const [value, setValue] = useControllableValue<OptionsProps[]>(props, { defaultValue: [] });
  const [tempValue, setTempValue] = React.useState<OptionsProps[]>([]);
  const [isModalOpened, setIsModalOpened] = useState(false);

  useEffect(() => {
    if (isModalOpened) {
      setTempValue(value);
    } else {
      setTempValue([]);
    }
  }, [isModalOpened, value]);

  const showModal = () => {
    setIsModalOpened(true);
  };

  const handleOk = () => {
    setIsModalOpened(false);
    setValue(tempValue);
  };

  const handleCancel = () => {
    setIsModalOpened(false);
  };

  const handleChange = (value: OptionsProps[]) => {
    setTempValue(uniqBy(tempValue.concat(value), 'value'));
  };

  const handleTempDelete = (value: OptionsProps) => {
    setTempValue(tempValue.filter(item => item.value !== value.value));
  };

  const handleDelete = (val: OptionsProps) => {
    if (props.onDelete) {
      props.onDelete(val);
    } else {
      setValue(value.filter(item => item.value !== val.value));
    }
  };

  return (
    <>
      <Button type="dashed" icon={<PlusOutlined />} onClick={showModal} disabled={!!props.disabled}>
        选择租户
      </Button>
      <SelectSelectionList value={value} onDelete={handleDelete} disabled={!!props.disabled} />

      <Modal title="选择租户" open={isModalOpened} onOk={handleOk} onCancel={handleCancel}>
        <DebounceSelect
          style={{ width: '100%' }}
          placeholder="请输入租户完整名称"
          value={[]}
          fetchOptions={fetchList}
          onChange={handleChange}
        />
        <SelectSelectionList value={tempValue} onDelete={handleTempDelete} style={{ minHeight: 140 }} />
      </Modal>
    </>
  );
};

export default TenantSelect;
