import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { FC } from 'react';
import ChildItem, { ItemProps } from './ChildItem';

const ChildSortableItem: FC<ItemProps> = props => {
  const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  return (
    <ChildItem ref={setNodeRef} style={style} withOpacity={isDragging} {...props} {...attributes} {...listeners} />
  );
};

export default ChildSortableItem;
