import { PlusOutlined } from '@ant-design/icons';
import { Table } from '@maxtropy/components';
import { Button, Space, Modal } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';

import styles from './index.module.scss';

import DeviceModal from './DeviceModal';
import { AlrmPushDevice } from '../../../../api/deviceAlarmPushStrategy';

import { columns } from './util';

export interface SelectDevicesProps {
  value?: AlrmPushDevice[];
  onChange?: (value: AlrmPushDevice[]) => void;
  ouId?: number;
  rootMcid?: string;
  disabled?: boolean;
}

const SelectDevices: FC<SelectDevicesProps> = ({ value, onChange, ouId, rootMcid, disabled = false }) => {
  const [visible, setVisible] = useState<boolean>(false);

  const [dataSource, setDataSource] = useState<AlrmPushDevice[]>([]);
  const [modalApi, modalContextHolder] = Modal.useModal();

  const disabledIds = useMemo(() => {
    return (value ?? []).map(i => i.deviceId);
  }, [value]);

  useEffect(() => {
    setDataSource(value ?? []);
  }, [value]);

  const onAdd = () => {
    if (rootMcid === undefined) {
      modalApi.warning({
        title: '请先选择租户',
      });
      return;
    }
    if (ouId === undefined) {
      modalApi.warning({
        title: '请先选择生产运营单元',
      });
      return;
    }
    setVisible(true);
  };

  const unBind = (record: AlrmPushDevice) => {
    modalApi.confirm({
      title: '取消绑定？',
      okText: '继续',
      onOk() {
        const newValue = (value ?? []).filter(i => i.deviceId !== record.deviceId);
        onChange?.(newValue);
      },
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 250,
      fixed: 'right' as const,
      render: (value: undefined, record: AlrmPushDevice) => {
        return (
          <Button
            style={{ paddingLeft: 0, paddingRight: 0 }}
            type="link"
            onClick={() => {
              unBind(record);
            }}
          >
            取消绑定
          </Button>
        );
      },
    },
  ];

  const onCancel = () => {
    setDataSource(value ?? []);
    setVisible(false);
  };

  const onOk = () => {
    onChange?.(dataSource);
    setVisible(false);
  };

  return (
    <>
      {!disabled && (
        <Space direction="vertical">
          <Button type="dashed" onClick={onAdd}>
            <PlusOutlined />
            添加
          </Button>
          <div />
        </Space>
      )}
      <Table
        sticky
        scroll={{ x: 1300, y: 400 }}
        columns={disabled ? columns : buildColumns}
        rowKey="deviceId"
        dataSource={value ?? []}
      />
      <Modal
        className={styles.modal}
        title="设备选择"
        destroyOnClose
        open={visible}
        onOk={onOk}
        onCancel={onCancel}
        width={1100}
      >
        <DeviceModal
          ouId={ouId?.toString()}
          rootMcid={rootMcid}
          onChange={setDataSource}
          value={dataSource}
          disabledIds={disabledIds}
        />
      </Modal>
      {modalContextHolder}
    </>
  );
};

export default SelectDevices;
