import { FC, useEffect, useMemo, useState } from 'react';
import { Wrapper, Table, Paging, usePaging, EllipsisSpan, useAsync } from '@maxtropy/components';
import { Form, Select, Input, Col, Tag, Divider, Space, Button, DatePicker } from 'antd';
import Filter from '@/shared/components/Filter';

import { getRoot } from '../../../api/device';
import { AlarmLevel, AlarmLevelColorDisplay, AlarmLevelDisplay, AlarmState, AlarmStateDisplay } from '@/shared/types';
import { Link } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import {
  AlarmLogRequest,
  AlarmLogResponse,
  getAlarmLogList,
  getOuListByRootMcid,
  OuResponse,
} from '../../../api/alarm';
import RecordPagination from '@/components/RecordPagination';

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY/MM/DD';
const displayDateFormat = 'YYYY/MM/DD HH:mm:ss';

const routes = [{ name: 'Iot配置' }, { name: '报警推送' }, { name: '设备报警记录' }];

interface FilterParams {
  tenantMcid?: string;
  ouId?: number;
  codeOrName?: string;
  state?: number;
  time?: [Dayjs, Dayjs];
}

type SearchParams = Omit<AlarmLogRequest, 'page' | 'size'>;

export const columns = [
  {
    title: '设备编号',
    dataIndex: 'deviceCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'deviceName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警等级',
    dataIndex: 'alarmLevel',
    ellipsis: { showTitle: true },
    render: (v: AlarmLevel) => (
      <EllipsisSpan value={<Tag color={AlarmLevelColorDisplay[v]}>{AlarmLevelDisplay[v]}</Tag>} />
    ),
  },
  {
    title: '报警信息',
    dataIndex: 'alarmName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '报警时间',
    dataIndex: 'alarmTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format(displayDateFormat) : undefined} />,
  },
  {
    title: '恢复时间',
    dataIndex: 'recoveryTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format(displayDateFormat) : '未恢复'} />,
  },
  {
    title: '所属租户',
    dataIndex: 'tenantCode',
    ellipsis: { showTitle: true },
    render: (v: string, record: any) => <EllipsisSpan value={`${v} ${record.tenantName}`} />,
  },
];

const AlarmRecordList: FC = () => {
  const [form] = Form.useForm();

  const [totalCount, setTotalCount] = useState<number>(); // 总页数
  const [isSortValue, setIsSortValue] = useState<boolean>(false); // 是否传sortvalue
  const [currentPage, setCurrentPage] = useState<number>(1); // 当前页数
  const [sortValues, setSortValues] = useState<any[]>();
  const [pageSize, setPageSize] = useState<number>(50); // 每页多少条

  const [searchParams, setSearchParams] = useState<SearchParams>({});

  const tenant = useAsync(getRoot, []);
  const [tenantMcid, setTenantMcid] = useState<string>();

  const firstMcid = useMemo(() => {
    if (Array.isArray(tenant) && tenant.length) {
      const first = tenant[0];
      return first.mcid;
    }
  }, [tenant]);

  useEffect(() => {
    if (firstMcid) {
      setTenantMcid(firstMcid);
      setSearchParams({ ...searchParams, tenantMcid: firstMcid });
      form.setFieldsValue({
        tenantMcid: firstMcid,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstMcid, form]);

  const [ou, setOu] = useState<OuResponse[]>([]);

  useEffect(() => {
    // 重新获取ou
    if (tenantMcid) {
      getOuListByRootMcid(tenantMcid).then(res => {
        setOu(res);
      });
    }
  }, [tenantMcid]);

  const firstOuId = useMemo(() => {
    if (Array.isArray(ou) && ou.length) {
      const first = ou[0];
      return first.id;
    } else {
      return undefined;
    }
  }, [ou]);

  useEffect(() => {
    if (firstOuId) {
      form.setFieldsValue({
        ouId: firstOuId,
      });
      setSearchParams({ ...searchParams, ouId: firstOuId });
    } else {
      form.setFieldsValue({
        ouId: undefined,
      });
      setSearchParams({ ...searchParams, ouId: undefined });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstOuId, form]);

  const [records, setRecords] = useState<AlarmLogResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (searchParams.tenantMcid && searchParams.ouId) {
      setLoading(true);
      getAlarmLogList({
        ...searchParams,
        sortValues: isSortValue ? sortValues : undefined,
        page: currentPage,
        size: pageSize,
      }).then(res => {
        setLoading(false);
        if (res) {
          if (res.useSearchAfter) {
            setSortValues(res.sortValues);
            setIsSortValue(true);
          } else {
            setSortValues(undefined);
            setIsSortValue(false);
          }
          setRecords(res.list);
          setTotalCount(res.total);
        }
      });
    }
  }, [currentPage, pageSize, searchParams, setTotalCount]);

  const onFinish = (value: FilterParams) => {
    setIsSortValue(false);
    const endtime = value.time ? dayjs(value.time[1]).endOf('day') : undefined;
    const currentTime = dayjs();
    const params: SearchParams = {
      tenantMcid: value.tenantMcid,
      ouId: value.ouId,
      codeOrName: value.codeOrName,
      state: value.state,
      alarmStartTime: value.time ? dayjs(value.time[0]).startOf('day').valueOf() : undefined,
      alarmEndTime: endtime ? (endtime.isAfter(currentTime) ? currentTime : endtime).valueOf() : undefined,
    };
    setSearchParams(params);
    setCurrentPage(1);

    // const paramsObj = {
    //   tenantMcid: value.tenantMcid,
    //   ouId: value.ouId,
    //   codeOrName: value.codeOrName,
    //   state: value.state?.toString(),
    //   alarmStartTime: value.time ? dayjs(value.time[0]).format(dateFormat) : undefined,
    //   alarmEndTime: value.time ? dayjs(value.time[1]).format(dateFormat) : undefined,
    // };
    // const paramsMap = new Map<string, string>();
    // Object.entries(paramsObj).forEach(([key, value]) => {
    //   value && paramsMap.set(key, value)
    // });
    // history.push(`?${qs.stringify(Object.fromEntries(paramsMap))}`)
  };

  const onReset = () => {
    const params: FilterParams = {
      tenantMcid: firstMcid,
      ouId: undefined,
      codeOrName: undefined,
      state: undefined,
      time: undefined,
    };
    if (tenantMcid === firstMcid) {
      params.ouId = firstOuId;
    }
    setSearchParams(params);
    setTenantMcid(firstMcid);
    setCurrentPage(1);
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 250,
      fixed: 'right' as const,
      render: (value: undefined, record: AlarmLogResponse) => {
        return (
          <Space size={16}>
            <Button style={{ paddingLeft: 0, paddingRight: 0 }} type="link">
              <Link to={`/device/alarm/record/detail/${record.id}`}>查看</Link>
            </Button>
          </Space>
        );
      },
    },
  ];

  const filters = (
    <Filter
      form={form}
      onFinish={val => {
        onFinish(val as FilterParams);
      }}
      onReset={onReset}
      collapseItems={
        <>
          <Col span={6}>
            <Form.Item name="state" label="记录状态">
              <Select style={{ width: '100%' }} placeholder="请选择">
                <Select.Option value={AlarmState.RECOVER}>{AlarmStateDisplay[AlarmState.RECOVER]}</Select.Option>
                <Select.Option value={AlarmState.UNRECOVERED}>
                  {AlarmStateDisplay[AlarmState.UNRECOVERED]}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="time" label="报警日期">
              <RangePicker
                style={{ width: '100%' }}
                disabledDate={date => {
                  return dayjs().isBefore(date);
                }}
                format={dateFormat}
              />
            </Form.Item>
          </Col>
        </>
      }
    >
      <>
        <Col span={6}>
          <Form.Item name="tenantMcid" label="所属租户" initialValue={firstMcid}>
            <Select
              style={{ width: '100%' }}
              placeholder="请选择"
              onChange={v => {
                setTenantMcid(v as string);
              }}
            >
              {tenant.map(i => (
                <Select.Option key={i.mcid} value={i.mcid}>
                  {i.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="ouId" label="生产运营单元" initialValue={firstOuId}>
            <Select style={{ width: '100%' }} placeholder="请选择">
              {ou.map(i => (
                <Select.Option key={i.id} value={i.id}>
                  {i.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="codeOrName" label="编号/名称">
            <Input placeholder="请输入编号查询" />
          </Form.Item>
        </Col>
      </>
    </Filter>
  );

  return (
    <Wrapper routes={routes} filters={filters}>
      <Table sticky loading={loading} scroll={{ x: 1900 }} columns={buildColumns} dataSource={records} />
      {totalCount ? (
        <RecordPagination
          totalCount={totalCount}
          setPageSize={setPageSize}
          pageSize={pageSize}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setIsSortValue={setIsSortValue}
        />
      ) : null}
    </Wrapper>
  );
};

export default AlarmRecordList;
