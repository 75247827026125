import { EllipsisSpan, Paging, Table, usePaging, getRealUrl } from '@maxtropy/components';
import { Button } from 'antd';
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useEffect, useReducer, useState } from 'react';
import { getPvEstimationResultList, Result } from '../../../../api/tools';

const columns: ColumnType<Result>[] = [
  {
    title: '时间',
    dataIndex: 'createTime',
    width: 200,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '备注',
    dataIndex: 'remark',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '操作',
    dataIndex: 'operation',
    width: 180,
    render: (value: undefined, record: Result) => {
      return (
        <Button
          style={{ paddingLeft: 0, paddingRight: 0 }}
          type="link"
          disabled={!record.fileKey || record.taskStatus === 4}
          onClick={() => window.open(getRealUrl(record.fileKey))}
        >
          下载
        </Button>
      );
    },
  },
];

interface ResultProps {
  type: string;
}

const ResultModal: React.FC<ResultProps> = ({ type }) => {
  const [resultList, setResultList] = useState<Result[]>([]);
  const pagingInfo = usePaging(50);
  const [xx, forceUpdate] = useReducer(x => x + 1, 0);
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const param = {
      page: pageOffset,
      size: pageSize,
      taskSource: type,
      remark: '',
    };
    setLoading(true);
    getPvEstimationResultList(param)
      .then(res => {
        setResultList(res?.records);
        setTotalCount(res.total);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xx, pageOffset, pageSize, setTotalCount]);

  return (
    <div>
      <div style={{ marginBottom: 24 }}>
        <div style={{ textAlign: 'left', width: 300, display: 'inline-block' }}>任务计算失败，下载置灰禁用</div>
        <Button style={{ float: 'right' }} type="primary" onClick={() => forceUpdate()}>
          刷新
        </Button>
      </div>
      <Table loading={loading} scroll={{ y: 300 }} rowKey="id" columns={columns} dataSource={resultList} />
      <Paging pagingInfo={pagingInfo} />
    </div>
  );
};

export default ResultModal;
