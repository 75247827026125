export enum StatusEnum {
  Draft = 0, // 草稿
  Enable = 1, // 启用
  Disable = 2, // 禁用
}

export const statusValue = {
  [StatusEnum.Draft]: '草稿',
  [StatusEnum.Enable]: '启用',
  [StatusEnum.Disable]: '禁用',
};

export enum TypeEnum {
  FUEL = 1,
  CARBONATE = 2,
  ELECTRIC = 3,
  HEATING = 4,
  STEAM = 5,
  MCF = 6,
  INDUSTRIAL_PROCESS = 7,
  GWP = 8,
  TRANSPORTATION = 9,
  GAS_DISPERSION = 10,
  WASTE_DISPOSAL = 11,
  EMPLOYEE_COMMUTING = 12,
}

export const typeValue = {
  [TypeEnum.FUEL]: '燃料',
  [TypeEnum.CARBONATE]: '碳酸盐',
  [TypeEnum.ELECTRIC]: '电力',
  [TypeEnum.HEATING]: '热力',
  [TypeEnum.STEAM]: '蒸汽',
  [TypeEnum.MCF]: 'MCF',
  [TypeEnum.INDUSTRIAL_PROCESS]: '工业生产过程',
  [TypeEnum.GWP]: 'GWP',
  [TypeEnum.TRANSPORTATION]: '交通运输',
  [TypeEnum.GAS_DISPERSION]: '气体逸散',
  [TypeEnum.WASTE_DISPOSAL]: '废弃物处理',
  [TypeEnum.EMPLOYEE_COMMUTING]: '员工通勤',
};

export interface CarbonEmissionLibraryData {
  id: number;
  /**
   * 参考文献
   */
  reference?: string;
  /**
   * 说明
   */
  description?: string;
  /**
   * 名称
   */
  name?: string;
  /**
   * 类型(1燃料 2碳酸盐 3电力...)
   */
  type: TypeEnum;
  /**
   * 状态(0:草稿 1:启用 2:禁用)
   */
  state: StatusEnum;
  /**
   * 操作人
   */
  updateUser?: string;
  /**
   * 适用行业
   */
  industryId?: number[];
}

export interface CarbonIndustryData {
  id: number;
  /**
   * 行业名称
   */
  name: string;
}
