import React, { useState } from 'react';
import styles from './index.module.scss';
import { Wrapper, FormContent, AttachmentUpload } from '@maxtropy/components';
import { Button, Col, DatePicker, Divider, Form, Input, Modal, Radio, Row, Select, Space } from 'antd';
import { commonMobilePhoneRegex, commonUsernameRegex } from '../../../../utils/regex';
import { Gender, LeftNavPreference, UserStatus } from '../../../../api/cc-const';
import dayjs, { Dayjs } from 'dayjs';
import {
  checkCellphone,
  checkUsername,
  createCustomerUser,
  PostCustomerUserRequest,
} from '../../../../api/cc-customer';
import { useNavigate } from 'react-router-dom';
import { countModal } from '../../../../utils/help';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { EmailFormItem } from '../../../../components/FormComponent';

const { Option } = Select;

const routes = [
  { name: '租户管理' },
  { name: '租户组织权限和账户' },
  { name: '租户用户管理', path: '/tenant/customer/user' },
  { name: '新建租户用户' },
];

const formLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

export const emailFormLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 24 },
};

const tailLayout = {
  wrapperCol: { offset: 2, span: 16 },
};

export const checkUserName = async (rule: any, value: string) => {
  if (!value || !value.trim()) {
    return Promise.reject('请输入登录账号');
  } else if (!commonUsernameRegex.test(value)) {
    return Promise.reject('请输入长度5~20个字符，英文及数字并且不能为纯数字的组合');
  } else {
    const res = await checkUsername(value);
    if (res) {
      return Promise.reject('登录账号已被使用！');
    }
    return Promise.resolve();
  }
};

const TenantUserCreate: React.FC = () => {
  const [modalApi, modalContextHolder] = Modal.useModal();
  const [form] = Form.useForm();
  const [isCreate, setIsCreate] = useState<boolean>(true);
  const navigate = useNavigate();

  const checkCellPhone = async (rule: any, value: string) => {
    value = value ? value.replace(/(\s*$)/g, '') : '';
    if (value.substr(0, 1) !== '1') {
      return Promise.reject('请输入正确的手机号码');
    } else if (value.length !== 11) {
      return Promise.reject('请输入11位手机号码');
    } else if (value.indexOf(' ') !== -1) {
      return Promise.reject('手机号不能含有空格哦');
    } else if (!value || !commonMobilePhoneRegex.test(value)) {
      return Promise.reject('请输入正确的手机号码');
    } else {
      const res = await checkCellphone(value);
      if (res) {
        return Promise.reject('手机号码已被使用！');
      }
      return Promise.resolve();
    }
  };

  const onFinish = (values: PostCustomerUserRequest) => {
    values.birthday = values.birthday ? dayjs(values.birthday).format('YYYY-MM-DD') : '';
    values.cellphone = values.cellphone.replace(/(\s*$)/g, '');
    createCustomerUser(values).then(data => {
      if (data) {
        if (isCreate) {
          countModal(modalApi, 10, navigate, '/tenant/customer/user', `已经成功新建用户，用户登录账号${data.name}`);
        } else {
          navigate(`/tenant/customer/user/createStaff/${data.muid}`);
        }
      }
    });
  };

  return (
    <Wrapper routes={routes}>
      <div className={styles.container}>
        <Form
          layout="vertical"
          {...formLayout}
          form={form}
          initialValues={{ status: UserStatus.ENABLE }}
          onFinish={onFinish}
        >
          <FormContent title="基础信息">
            <Row>
              <Col span={8}>
                <Form.Item
                  label="手机号码"
                  required
                  name="cellphone"
                  validateTrigger={['onBlur']}
                  extra="登录密码将通过手机号发送，请确保手机号可用"
                  rules={[{ validator: checkCellPhone }]}
                >
                  <Input placeholder="请输入手机号码" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="姓名"
                  name="name"
                  // prettier-ignore
                  rules={[// NOSONAR
                    { required: true, message: '请输入姓名' },
                    {
                      pattern: /^[•\u4e00-\u9fa5A-Za-z\u00C0-\u00FF\u00D1\u00F1\u0400-\u052F\u0370-\u03FF]+$/g,
                      message: '姓名须为汉字、•、英文字母、法语字母、西班牙语字母、俄语字母、希腊语字符',
                    },
                    { max: 20, message: '姓名最多20个字符' },
                  ]}
                >
                  <Input placeholder="请输入姓名" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="用户状态" name="status">
                  <Select>
                    <Option value={UserStatus.ENABLE}>启用</Option>
                    <Option value={UserStatus.DISABLE}>禁用</Option>
                    <Option value={UserStatus.FREEZE}>冻结</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="登录账号"
                  required
                  name="username"
                  rules={[{ validator: checkUserName }]}
                  validateTrigger={['onBlur']}
                >
                  <Input placeholder="请输入登录账号" />
                </Form.Item>
              </Col>
            </Row>
          </FormContent>
          <Divider />
          <FormContent title="扩展信息">
            <Row>
              <Col span={8}>
                <Form.Item name="gender" label="性别">
                  <Radio.Group>
                    <Radio value={Gender.MALE}>男</Radio>
                    <Radio value={Gender.FEMALE}>女</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="birthday" label="生日">
                  <DatePicker
                    style={{ width: '100%' }}
                    disabledDate={(currentDate: Dayjs) => {
                      return currentDate.isAfter(dayjs(), 'date');
                    }}
                    placeholder="请输入生日"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="email"
                  label="邮箱"
                  {...emailFormLayout}
                  rules={[{ type: 'email', message: '请输入正确的邮箱' }]}
                >
                  <EmailFormItem />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="wechat"
                  label="微信"
                  rules={[{ pattern: /^[A-Za-z0-9_-]{6,20}$/, message: '请输入6-20位字母、数字、下划线或减号的组合' }]}
                >
                  <Input placeholder="请输入微信" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="qq" label="QQ" rules={[{ pattern: /^[0-9]{4,15}$/, message: '请输入4-15位数字' }]}>
                  <Input placeholder="请输入QQ" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="headPic" label="头像">
                  <AttachmentUpload />
                </Form.Item>
              </Col>
            </Row>
          </FormContent>
          <Divider />
          <FormContent title="个人偏好">
            <Row>
              <Col span={24}>
                <Form.Item
                  name="navigationPreference"
                  label="左侧导航栏"
                  rules={[{ required: true, message: '请选择' }]}
                >
                  <Radio.Group>
                    <Radio value={LeftNavPreference.OPEN_CLOSE}>收起/展开</Radio>
                    <Radio value={LeftNavPreference.ALL_OPEN}>一直展开</Radio>
                    <Radio value={LeftNavPreference.ALL_CLOSE}>直接隐藏</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </FormContent>
          <Divider />

          <Row className={styles.buttonWrapper}>
            <Col span={7}>
              <Form.Item {...tailLayout}>
                <Space>
                  <Button onClick={() => setIsCreate(false)} htmlType="submit" type="primary">
                    保存并前往创建员工工号
                  </Button>
                  <Button htmlType="submit" type="primary">
                    保存
                  </Button>
                  <Button
                    onClick={() => {
                      modalApi.confirm({
                        title: '操作确认',
                        icon: <ExclamationCircleOutlined />,
                        content: '是否放弃所有未保存信息并返回列表？',
                        onOk: () => navigate('/tenant/customer/user'),
                      });
                    }}
                  >
                    关闭
                  </Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      {modalContextHolder}
    </Wrapper>
  );
};

export default TenantUserCreate;
