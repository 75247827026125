import { Breadcrumb } from '@maxtropy/components';
import { Form, Space, Button, Spin, Divider } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AttributeItem from '@/shared/components/AttributeItem';
import CustomAttributesDisplay from '@/shared/components/CustomAttributes';
import { getTemplate } from '../../api/template';
import { useQuery } from '../../utils/utils';
import styles from './index.module.scss';

export interface TemplateDetailProps {}

const routes = [{ name: 'Iot配置' }, { name: '设备信息模板库' }, { name: '固定属性模板' }, { name: '模版详情' }];

const TemplateDetail: React.FC<TemplateDetailProps> = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useQuery(useCallback(() => getTemplate(+id!), [id]));

  const customAttributes = useMemo(
    () => (data?.template.description ? JSON.parse(data.template.description) : []),
    [data]
  );

  return (
    <Spin spinning={isLoading}>
      <div className={styles.container}>
        <div className={styles.breadcrumb}>
          <Breadcrumb routes={routes} />
        </div>

        <div className={styles.form}>
          <Form>
            <Space size={30}>
              <Form.Item label="模板名称">{data?.template.name}</Form.Item>
              <Form.Item label="所属类目">{data?.template.deviceTypeName}</Form.Item>
              <Form.Item label="模板型号">{`${data?.template.modelNo ?? ''}-${
                data?.template.manufacturerName ?? '-'
              }`}</Form.Item>
              <Form.Item label="版本号">
                <div className={styles.version}>{data?.template.lastVersion}</div>
              </Form.Item>
            </Space>
            <Form.Item label="模板备注">
              <div
                style={{
                  width: 487,
                  wordBreak: 'break-all',
                }}
              >
                {data?.template.remark || '-'}
              </div>
            </Form.Item>
          </Form>
        </div>

        <div className={styles.body}>
          <div className={styles.list}>
            {data?.template.deviceAttributes.map((item, index) => (
              <AttributeItem disabled attribute={item} />
            ))}
          </div>

          {!!data?.template.deviceAttributes.length && <Divider />}

          <CustomAttributesDisplay style={{ padding: '20px' }} customAttributes={customAttributes} />

          <Space className={styles.footer}>
            <Button
              onClick={() =>
                navigate(`/device/info/template${window.location.search}`, {
                  replace: true,
                })
              }
            >
              返回
            </Button>
          </Space>
        </div>
      </div>
    </Spin>
  );
};

export default TemplateDetail;
