import { FC } from 'react';
import { Form, Radio, Row, Col, InputNumber } from 'antd';
import { FactorPropertyEnum, steamTypeOptions } from '../emissionProperty';

const FormItem = Form.Item;

interface Props {}
const Steam: FC<Props> = () => {
  return (
    <>
      <Row gutter={16}>
        <Col span={8}>
          <FormItem
            label="蒸汽类型"
            name={['factorDetails', `${FactorPropertyEnum.SteamType}_1`]}
            rules={[{ required: true }]}
          >
            <Radio.Group options={steamTypeOptions} />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <FormItem
            label="压力/MPa"
            name={['factorDetails', `${FactorPropertyEnum.SteamPressure}_2`]}
            rules={[{ required: true }]}
          >
            <InputNumber placeholder="请输入数值" style={{ width: '100%' }} min={0} />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            label="温度/℃"
            name={['factorDetails', `${FactorPropertyEnum.SteamTemp}_3`]}
            rules={[{ required: true }]}
          >
            <InputNumber placeholder="请输入数值" style={{ width: '100%' }} min={0} />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            label="热焓值/kJ/kg"
            name={['factorDetails', `${FactorPropertyEnum.SteamEnthalpy}_4`]}
            rules={[{ required: true }]}
          >
            <InputNumber placeholder="请输入数值" style={{ width: '100%' }} min={0} />
          </FormItem>
        </Col>
      </Row>
    </>
  );
};

export default Steam;
