import { fetch } from '@maxtropy/components';
import qs from 'qs';

import { RoleStatus, RoleType } from './cc-const';
import { Key } from '@maxtropy/components';
import { AppFunctionPermissionVo } from './cc-app';

export interface Paged<T> {
  total: number;
  offset: number;
  count: number;
  contents: T[];
}

export interface PageRequest {
  offset?: number;
  count?: number;
}

export interface PrefabRoleListRequest extends PageRequest {
  state?: RoleStatus.ENABLE | RoleStatus.FREEZE;
  toVoid?: boolean;
}

export interface PrefabRoleListResponse {
  id: number;
  name: string;
  state: RoleStatus;
  createTime: string;
  updateTime: string;
  code: string;
}

export function getPreRoleList(query: PrefabRoleListRequest): Promise<Paged<PrefabRoleListResponse>> {
  return fetch(`/api/role/page?${qs.stringify(query, { indices: false })}`);
}

export function updatePreRoleState(id: number, query: { state: RoleStatus }): Promise<boolean> {
  return fetch(`/api/role/updState/${id}?${qs.stringify(query, { indices: false })}`, {
    method: 'PUT',
  });
}

interface TenantReVoProps {
  mcid: string;
  name: string;
}

export interface AllRoleProps {
  id: number;
  name: string;
  state: RoleStatus;
  type: RoleType;
  createTime: string;
  updateTime: string;
}

export function getAllRole(mcid: string): Promise<AllRoleProps[]> {
  return fetch(`/api/role/list/${mcid}`);
}

interface RequestRoleBody {
  name: string;
  state: string;
  tenantReVoList?: TenantReVoProps[];
  type: number;
  integratedAppIdList: number[];
}

export interface RoleInfo {
  id: number;
  code: string;
  name: string;
  state: RoleStatus;
  level: number;
  type: RoleType;
  tenantReVoList: TenantReVoProps[];
  roleIntegratedAppList: IntegratedApp[];
}

export function createRole(body: RequestRoleBody): Promise<RoleInfo> {
  return fetch(`/api/role/save`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function findRoleById(id: string): Promise<RoleInfo> {
  return fetch(`/api/role/details/${id}`);
}

export function findRoleByCode(code: string): Promise<RoleInfo> {
  return fetch(`/api/role/detailsByCode/${code}`);
}

export function updateRole(body: RequestRoleBody): Promise<boolean> {
  return fetch(`/api/role/update`, {
    method: 'PUT',
    body: JSON.stringify(body),
  });
}

interface PermissionProps {
  permissions: Key[];
  syncs: Key[];
}

export function queryPermissionList(roleCode: string): Promise<PermissionProps> {
  return fetch(`/api/role/function/permission/${roleCode}/permissionList`);
}

export interface UpdatePermission {
  roleCode: string;
  addPermissions: Key[];
  deletePermission: Key[];
  addSyncs: Key[];
  deleteSyncs: Key[];
}

export interface IntegratedAppPermission extends UpdatePermission {
  integratedAppId: number;
}
interface UpdatePermissionBody extends UpdatePermission {
  integratedAppPermission?: { [key: number]: IntegratedAppPermission };
}

export function updatePermission(body: UpdatePermissionBody): Promise<never> {
  return fetch(`/api/role/function/permission/upsert`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
}

interface CheckUserNameUniquenessQuery {
  name: string;
  id?: string;
}

export function checkUserNameUniqueness(query: CheckUserNameUniquenessQuery) {
  return fetch<boolean>(`/api/role/checkUserName?${qs.stringify(query, { indices: false })}`);
}

export interface IntegratedApp {
  id: number;
  name: string;
}

export function getIntegratedAppList(): Promise<IntegratedApp[]> {
  return fetch(`/api/role/permission/integrated/integratedAppList`);
}

export function getRoleIntegratedAppList(roleCode: string): Promise<IntegratedApp[]> {
  return fetch(`/api/role/integratedAppList?roleCode=${roleCode}`);
}

export function getIntegratedAppPermissionTree(integratedAppId: number): Promise<AppFunctionPermissionVo[]> {
  return fetch(`/api/role/permission/integrated/integratedAppTree?integratedAppId=${integratedAppId}`);
}

export function getIntegratedPermissionList(roleCode: string, integratedAppId: number): Promise<PermissionProps> {
  return fetch(`/api/role/permission/integrated/${roleCode}/permissionList?integratedAppId=${integratedAppId}`);
}

export interface MiniAppPermissionItem {
  id?: number;
  roleCode?: string;
  permissionCode?: string;
  miniAppId?: number;
  createTime?: string;
  updateTime?: string;
}

export function getRoleMiniAppPermissions(roleCode: string): Promise<MiniAppPermissionItem[]> {
  return fetch(`/api/role/mini/app/permissionList?roleCode=${roleCode}`);
}

export interface UpdateAppPermissionBodyItem {
  roleCode?: string;
  miniAppId?: number;
  permissions?: string[];
  syncPermissions?: string[];
}

export function updateMiniAppPermission(body: UpdateAppPermissionBodyItem[]): Promise<never> {
  return fetch(`/api/role/mini/app/permissions/upsert`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
}
