import { EllipsisSpan, Paging, useSearchParams, useUpdate, Wrapper } from '@maxtropy/components';
import { Button, Col, Form, Input, message, Popconfirm, Row, Space, Table } from 'antd';
import React, { Key, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  deleteFormTemplate,
  FormTemplateListRes,
  FormTemplateStatus,
  formTemplateStatusFormat,
  getFormTemplatePage,
  updateFormTemplateStatus,
} from '../../api/mockFormTemplate';
import styles from './index.module.scss';
import dayjs from 'dayjs';
import { set } from 'lodash-es';

const routes = [{ name: '数据运营工具' }, { name: '业务数据模拟' }, { name: '表单模板管理' }];

const columns = [
  {
    title: '模板名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '模板说明',
    dataIndex: 'description',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '字段数',
    dataIndex: 'paramCount',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (v: FormTemplateStatus) => <EllipsisSpan value={formTemplateStatusFormat[v]} />,
  },
  {
    title: '更新人',
    dataIndex: 'updateCropUserName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '更新时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'} />,
  },
];

type SearchParams = {
  name?: string;
};

const FormTemplateManagement = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const pagingInfo = useSearchParams<SearchParams>(20);
  // 解构出searchParams以及分页相关的属性或方法
  const { searchParams, finish, reset, setTotalCount } = pagingInfo;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<FormTemplateListRes[]>([]);
  const [updateState, updateFn] = useUpdate();

  useEffect(() => {
    setLoading(true);
    getFormTemplatePage({
      ...searchParams,
      page: searchParams.pageOffset,
      size: searchParams.pageSize,
    })
      .then(res => {
        setDataSource(res.list ?? []);
        setTotalCount(res.total ?? 0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, updateState]);

  const deleteForm = (id: Key) => {
    deleteFormTemplate(id).then(_ => {
      updateFn();
    });
  };

  const modifyStatus = (status: number, id: number) => {
    const statusParams = status === FormTemplateStatus.DISABLE ? FormTemplateStatus.ENABLE : FormTemplateStatus.DISABLE;
    updateFormTemplateStatus(id, statusParams).then(_ => {
      message.success(statusParams === FormTemplateStatus.ENABLE ? '启用成功！' : '禁用成功');
      updateFn();
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      fixed: 'right' as const,
      width: 200,
      render: (text: any, record: any) => {
        return (
          <Space size={16}>
            <Button type="link" style={{ padding: 0 }} onClick={() => modifyStatus(record.status, record.id)}>
              <span style={{ color: record.status === FormTemplateStatus.DISABLE ? 'unset' : 'red' }}>
                {record.status === FormTemplateStatus.DISABLE
                  ? formTemplateStatusFormat[FormTemplateStatus.ENABLE]
                  : formTemplateStatusFormat[FormTemplateStatus.DISABLE]}
              </span>
            </Button>

            <Button
              style={{ padding: 0 }}
              type="link"
              onClick={() => navigate(`/data-tools/business/formCreateModal/${record.id}`)}
            >
              编辑
            </Button>
            <Popconfirm
              title={'您是否要删除此表单模板？'}
              onConfirm={() => deleteForm(record.id)}
              okText="确定"
              cancelText="取消"
            >
              <Button style={{ padding: 0 }} type="link">
                删除
              </Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  return (
    <Wrapper
      routes={routes}
      className={styles.outer}
      filters={
        <div className={styles.filter}>
          <Form form={form} onFinish={val => finish({ ...val })} onReset={() => reset()}>
            <Row gutter={10}>
              <Col span={6}>
                <Form.Item label="名称" name="name">
                  <Input placeholder="请输入模板名称搜索"></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item>
                  <Space size={12}>
                    <Button type="primary" htmlType="submit">
                      搜索
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
              <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type="primary" onClick={() => navigate(`/data-tools/business/formCreateModal`)}>
                  新建表单模板
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      }
    >
      <Table
        loading={isLoading}
        dataSource={dataSource}
        sticky
        rowKey="id"
        scroll={{ y: 600 }}
        columns={buildColumns}
        pagination={false}
      />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default FormTemplateManagement;
