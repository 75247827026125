import React from 'react';
import pic from './home_new.png';
import styles from './index.module.scss';

const Home: React.FC = props => {
  return (
    <div className={styles.img}>
      {/* <p style={{
        position: 'absolute',
        margin: 20,
        color: '#383838',
        fontWeight: 600
      }}>欢迎登录本系统</p> */}
      <img className={styles.img} src={pic} alt="" />
    </div>
  );
};

export default Home;
