export interface IEnum {
  enum: string;
  name: string;
  value?: string;
}

// map转数组
export const map2Ary = function (map: { [x: string]: any }) {
  var list: { enum: string; name: string; value?: string }[] = [];
  for (var key in map) {
    list.push({
      enum: key,
      name: map[key],
    });
  }
  return list;
};

// map转数组2
export const map2Ary2 = function (map: { [x: string]: any }) {
  var list = [];
  for (var key in map) {
    list.push({
      value: key,
      enum: map[key],
    });
  }
  return list;
};

// 必填校验
export const valiDataMust = function (list: any[]) {
  let num: number = 0;
  list.forEach(item => {
    if (!item.value || item.value === '') {
      num++;
    }
  });
  if (num === list.length) {
    return false;
  }
  return true;
};

// 校验名称相同
export function valiDataSameValue(arr: any[], val: string | number) {
  let array: any[] = [];
  let arrCopy = JSON.parse(JSON.stringify(arr));
  arrCopy.forEach((item: { [x: string]: any }) => {
    if (item) {
      if (item[val] === undefined || '') item[val] = String(item[val]) + Math.random();
      array.push(item[val]);
    }
  });
  // console.log('array', array);
  if (new Set(array).size !== arr.length) {
    return false;
  }
  return true;
}

// 十六进制转十进制
export function hex2int(hex: string) {
  var len = hex.length,
    a = new Array(len),
    code;
  for (var i = 0; i < len; i++) {
    code = hex.charCodeAt(i);
    if (48 <= code && code < 58) {
      code -= 48;
    } else {
      code = (code & 0xdf) - 65 + 10;
    }
    a[i] = code;
  }
  return a.reduce(function (acc, c) {
    acc = 16 * acc + c;
    return acc;
  }, 0);
}
