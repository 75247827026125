import { Fragment, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './App';
import 'dayjs/locale/zh-cn';
import { ThemeProvider, Themes, isDev } from '@maxtropy/components';

const root = createRoot(document.getElementById('root') as Element);
const StrictModeComponent = isDev() ? Fragment : StrictMode;

root.render(
  <StrictModeComponent>
    <ThemeProvider theme={Themes.YELLOWLIGHT}>
      <App />
    </ThemeProvider>
  </StrictModeComponent>
);
