import { BehaviorSubject, Subscription } from 'rxjs';
import { queryGatewayChildren } from '../api/options';
import { debounce } from 'lodash-es';

const isFn = (val: any) => typeof val === 'function';

export class SubjectHolderMap {
  private subjectHolders: { [key: string]: SubjectHolder<any> } = {};
  private static instance: SubjectHolderMap;

  private constructor() {
    this.register('queryGatewayChildren', new SubjectHolder(queryGatewayChildren));
  }

  static getInstance() {
    if (!SubjectHolderMap.instance) {
      SubjectHolderMap.instance = new SubjectHolderMap();
    }
    return SubjectHolderMap.instance;
  }

  register<T>(name: string, subscribe: SubjectHolder<T>) {
    if (!this.subjectHolders[name]) {
      this.subjectHolders[name] = subscribe;
      return this.subjectHolders[name];
    }
  }

  getSubjectHolder = (name: string) => {
    if (!this.subjectHolders[name]) {
      throw new Error('name is not register');
    } else {
      return this.subjectHolders[name];
    }
  };
}

export class SubjectHolder<T> {
  private readonly subject: BehaviorSubject<T | undefined>;
  private readonly asyncAction: () => Promise<T>;
  private data: T | undefined;

  constructor(asyncAction: () => Promise<T>) {
    if (!isFn(asyncAction)) {
      throw new Error('asyncAction must be a method');
    }
    this.asyncAction = asyncAction;
    this.subject = new BehaviorSubject<T | undefined>(undefined);
    this.subscribe(data => {
      this.data = data;
    });
    this.action().catch(console.error);
  }

  private async action() {
    try {
      const data = await this.asyncAction();
      this.subject.next(data);
      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  subscribe(callback: (data?: T) => void): Subscription {
    return this.subject.subscribe(callback);
  }

  current(): T | undefined {
    return this.data;
  }

  refresh() {
    return this.action();
  }

  debounceRefresh = debounce(() => this.action(), 500);

  reset() {
    this.subject.next(undefined);
  }
}
