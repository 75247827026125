import { fetch } from '@maxtropy/components';
import { DeviceListRequest, Device, DataProperty, DataPropertyListRequest } from './entities';
import qs from 'qs';

// 规则集所属租户下的设备, 默认只拿 20 条
export function getDeviceList(params: DeviceListRequest): Promise<Device[]> {
  return fetch(`/api/device/list?${qs.stringify(params, { indices: false })}`, {
    headers: {
      'x-tenant-mcid': params.tenantMcid,
    },
  });
}

// 根据设备获取数据属性
export function getDataPropertyList(params: DataPropertyListRequest, tenantMcid: string): Promise<DataProperty[]> {
  return fetch(`/api/dataProperty/list-by-device-id?${qs.stringify(params, { indices: false })}`, {
    headers: {
      'x-tenant-mcid': tenantMcid,
    },
  });
}
