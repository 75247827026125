import React, { useMemo } from 'react';
import { AttributeInfoProps, EdgeDeviceTemplatePoint } from '@/shared/components/EdgeDevicePointInfo/index';
import { ActionType, IotProtocolType } from '@/shared/types';
import { PointContext } from '@/shared/components/EdgeDevicePointInfo/contextTypes';
import EstunPointDetail from '@/shared/components/EdgeDevicePointInfo/estun/Detail';
import EdgePointDetail, { SearchParams } from './EdgePointDetail';

export interface DevicePointDetailProps {
  type: IotProtocolType;
  dataSource?: EdgeDeviceTemplatePoint[];
  loading?: boolean;
  info?: AttributeInfoProps;
  setSearchParams?: (searchParams: SearchParams) => void;
  hasDataColumns?: boolean;
}

const DevicePointDetail: React.FC<DevicePointDetailProps> = props => {
  const { type, dataSource, loading, info, setSearchParams, hasDataColumns } = props;

  const data = useMemo(() => dataSource?.filter(item => item.actionType !== ActionType.DELETE), [dataSource]);

  const renderContent = () => {
    if (type === IotProtocolType.ESTUN) {
      return <EstunPointDetail loading={loading} dataSource={data} type={type} hasDataColumns={hasDataColumns} />;
    } else if (type === IotProtocolType.MOCKINGBIRD) {
      return <EdgePointDetail loading={loading} setSearchParams={setSearchParams} hasDataColumns={hasDataColumns} />;
    }
  };

  return (
    <PointContext.Provider value={{ dataSource: data, info, editable: false, canAdjustField: true }}>
      {renderContent()}
    </PointContext.Provider>
  );
};

export default DevicePointDetail;
