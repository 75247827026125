import qs from 'qs';
import { fetch } from '@maxtropy/components';
import { Paged } from './openPlatform-const';
import { Key } from '@maxtropy/components';

export interface PageRequest {
  offset?: number;
  count?: number;
}

export interface DeviceType {
  id: number;
  key: string;
  name: string;
  children?: DeviceType[];
  deviceTypes?: DeviceType[];
  degree?: number;
}

export const getDeviceTypeTreeWithoutScene = (params?: { onlyGateway?: boolean }) => {
  return fetch<{ deviceTypes: DeviceType[] }>(
    `/api/deviceType/treeWithoutScene?${qs.stringify(params, { indices: false })}`
  );
};

export interface FilterParams extends PageRequest {
  dataPropertyName?: string; // 名称
  deviceTypeIds?: number[]; // 设备类目
  manufacturerId?: Key; // 厂商id
  physicalModelId?: Key; // 物模型id
}

export enum DataPropertyType {
  YC = 1,
  ENUM,
  STRING,
  YK,
  YT,
}

export const DataPropertyTypeDisplay = {
  [DataPropertyType.YC]: '遥测',
  [DataPropertyType.ENUM]: '枚举',
  [DataPropertyType.STRING]: '字符串',
  [DataPropertyType.YK]: '遥控',
  [DataPropertyType.YT]: '遥调',
};

export enum MeasurementType {
  ACCUMULATIVE_TOTAL = 1, //累计量
  INSTANTANEOUS_FLOW = 2, // 瞬时量
}

export const MeasurementTypeDisplay = {
  [MeasurementType.ACCUMULATIVE_TOTAL]: '累计量',
  [MeasurementType.INSTANTANEOUS_FLOW]: '瞬时量',
};

export interface DataPropertyPageRes {
  id: number; // 数据属性ID
  name: string; // 数据属性名称
  type: DataPropertyType; // 数据属性类型
  measurementType: MeasurementType; // 瞬时量/累计量
  basicPhysicalQuantityId: number; // 基本物理单位id
  deviceTypeId: number; // 设备类目id
  generalName: string; // 单位名称
  global: boolean; // 是否是全局
  enumValue: string; // type为枚举时，值对应的名称
  unitGeneralName: string; // 单位名称
  unitChineseName: string; // 单位中文名称
  deviceTypes: DeviceType[]; // 类目
  createTime: string;
  updateTime: string;
  physicalModelNo: string;
  manufacturerName: string;
}

export function getDataPropertyPage(params: FilterParams): Promise<Paged<DataPropertyPageRes>> {
  return fetch(`/api/dataProperty/page?${qs.stringify(params, { indices: false })}`);
}

export interface PhysicalModelRes {
  id: Key;
  modelNo: string; // 型号
  manufacturerId: Key; // 厂商id
  deviceTypeId: Key; // 设备类目id
  createTime: string;
  updateTime: string;
  manufacturerName: string; // 厂商名称
}

export interface PhysicalParams {
  deviceTypeIds?: Key[]; // 设备类目id
  manufacturerId?: Key; // 厂商id
}

// 根据设备类目和厂商查询物模型列表

export function getPhysicalModelList(params: PhysicalParams) {
  return fetch<PhysicalModelRes[]>(`/api/physical-model/list?${qs.stringify(params, { indices: false })}`);
}

export interface ManufacturerItem {
  id: Key;
  name: string; // 名称
  updateTime: string;
  createTime: string;
}

export const getManufacturerList = () => fetch<ManufacturerItem[]>('/api/manufacturer/list');
