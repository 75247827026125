import { Key, fetch } from '@maxtropy/components';
import { CorpUserFunctionPermissionReponse } from './cc-entity';

interface CorpUserDataPermissionReponse {
  mcid: string;
  corpUserId: number;
}

export function getCorpUserDataPermission(corpUserId: number): Promise<CorpUserDataPermissionReponse[]> {
  return fetch(`/api/corp/permission/customer/${corpUserId}`);
}

export interface UpsertCropUserDataPermissionRequest {
  corpUserId: number;
  muids: string[];
}

export function upsertCropUserDataPermissions(body: UpsertCropUserDataPermissionRequest) {
  return fetch(`/api/corp/permission/customer/upsert`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function getCorpUserFunctionPermission(corpUserId: number): Promise<CorpUserFunctionPermissionReponse> {
  return fetch(`/api/corp/function/permission/${corpUserId}`);
}

export interface CropUserFunctionProps {
  addPermissions: Key[];
  deletePermission: Key[];
  addSyncs: Key[];
  deleteSyncs: Key[];
}

export interface UpsertCropUserFunctionPermissionRequest {
  corpUserId: number;
  requests: CropUserFunctionProps;
}

export function upsertCropUserFunctionPermissions(body: UpsertCropUserFunctionPermissionRequest) {
  return fetch(`/api/corp/function/permission/upsert`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
}
