//@ts-nocheck
import React, { forwardRef, HTMLAttributes } from 'react';
import ChildApp from './ChildApp';

import styles from '../index.module.scss';

export type ItemProps = HTMLAttributes<HTMLDivElement> & {
  id: string;
  withOpacity?: boolean;
  isDragging?: boolean;
  childrenData?: any[];
  currentCenterId?: number;
  update?: () => void;
};

const Item = forwardRef<HTMLDivElement, ItemProps>(
  ({ id, childrenData, update, currentCenterId, withOpacity, isDragging, style, ...props }, ref) => {
    return (
      <div ref={ref} {...props} className={styles.group}>
        <div className={styles.groupItemTitle}>{id}</div>

        <ChildApp parentId={id} childrenData={childrenData} currentCenterId={currentCenterId} update={update} />
      </div>
    );
  }
);

export default Item;
