import qs from 'qs';
import { VisibleRangeType } from '../pages/App/Create/AppAuthStepTwo/components/CustomerAuth';
import { Key, fetch, PromiseWithErrorHandler } from '@maxtropy/components';
import { AppStatus, Paged, PageRequest } from './openPlatform-const';

export interface DevGroup {
  id: number;
  name: string;
  createTime: string;
  updateTime: string;
}

export interface TenantAuthoritiesProps {
  id: number;
  tenantName: string;
  tenantMcid: string;
}

export interface ApiAuthoritiesProps {
  serviceName: string;
  requestUrl: string;
  requestMethod: string;
}

export interface ApplicationProps {
  appSecret: string; // 应用秘钥
  logoResource: string;
  createByUsername: string;
  id: number;
  name: string;
  devGroup: DevGroup;
  imgUrl?: any;
  memo?: any;
  appKey: string;
  status: AppStatus;
  creatorName?: string;
  countOfDeveloper: number;
  createTime: string;
  updateTime: string;
  independentSoftwareVendorId?: number; // 服务商id
  independentSoftwareVendorName?: string; // 服务商名称
  apiAuthorities?: ApiAuthoritiesProps[]; // 接口权限
  tenantAuthorities?: TenantAuthoritiesProps[]; // 租户权限
  tenantAuthorityType: VisibleRangeType; // 部分可见还是全部可见
  apiAuthorityType: VisibleRangeType; // 部分可见还是全部可见
}

export interface ApplicationListQuery extends PageRequest {
  status?: AppStatus;
  name?: string; // 应用名称
  independentSoftwareVendorId?: Key; // 服务商ID
}
export function queryApplicationListPaged(query: ApplicationListQuery): Promise<Paged<ApplicationProps>> {
  return fetch(`/api/application/page?${qs.stringify(query, { indices: false })}`);
}

export interface QueryIsvListProps {
  id: number; // id
  status?: number; // 状态	枚举: 0,1 枚举备注: 0 :禁用 1 :启用
  name: string; // 服务商名称
  companyName?: string; //公司名称
  introduction?: string; //介绍
  logoResource?: string; //logo资源
  createTime?: string; //创建时间
  updateTime?: string; //更新时间
}

// 查询服务商列表
export function queryIsvList(): Promise<QueryIsvListProps[]> {
  return fetch(`/api/isv/list`);
}

// 修改app状态
export function updateApplicationStatus(id: number, query: { status: AppStatus }): Promise<any> {
  return fetch(`/api/application/status/${id}?${qs.stringify(query, { indices: false })}`, {
    method: 'PUT',
  });
}

export interface ApplicationBody {
  name: string;
  independentSoftwareVendorId: number;
  imgUrl: string;
  memo: string;
}

export function addApplication(body: ApplicationBody): Promise<any> {
  return fetch(`/api/application`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function updateApplication(id: string, query: { status: string }, body: ApplicationBody): Promise<any> {
  return fetch(`/api/application/${id}?${qs.stringify(query, { indices: false })}`, {
    method: 'PUT',
    body: JSON.stringify(body),
  });
}

// 编辑app,不包含应用授权 S59
export function updateApplicationBasicInfo(id: string, body: ApplicationBody): Promise<any> {
  return fetch(`/api/application/${id}`, {
    method: 'PUT',
    body: JSON.stringify(body),
  });
}

export function getApplicationDetail(id: string): Promise<ApplicationProps> {
  return fetch(`/api/application/${id}`);
}
export interface TenantAuthority {
  tenantAuthorityType?: number;
  tenantMcids?: string[];
}

export interface ApiAuthority {
  apiAuthorityType?: number;
  requestInfos?: RequestInfos[];
}

export interface RequestInfos {
  serviceName?: string;
  requestMethod?: string;
  requestUrl?: string;
}
export interface ApplicationAuthorityBody {
  tenantAuthority?: TenantAuthority;
  apiAuthority?: ApiAuthority;
}

// 应用授权
export function updateApplicationAuthority(id: string, body: ApplicationAuthorityBody): Promise<any> {
  return fetch(`/api/application/authority/${id}`, {
    method: 'PUT',
    body: JSON.stringify(body),
  });
}

export interface TenantListProps {
  id: number;
  mcid: string;
  name: string; //名称
  tenantCode: string; //租户code
  accountOpenStatus?: boolean; //开户标记
  uscc?: string; //统一社会信用代码
  adCode?: string; //地区code
  address?: string; //地址
  provinceCityDistrict?: string; //所属省市
  phone?: string; //公司电话
  businessLicense?: string; //营业执照
  createTime?: string; //创建时间
  updateTime?: string; //修改时间;
}

// 选择租户（租户列表）
export function queryTenantList(): Promise<TenantListProps[]> {
  return fetch(`/api/tenant/list`);
}

export function queryApplicationList(query: { developerId: string }): Promise<ApplicationProps[]> {
  return fetch(`/api/application/list?${qs.stringify(query, { indices: false })}`);
}

export function queryUpstreamService(): Promise<any> {
  return fetch(`/api/upstreamService/openApi`);
}

export interface SecretProps {
  appKey: string;
  appSecret: string;
}

export function generateSecret(id: string): Promise<SecretProps> {
  return fetch(`/api/application/${id}/generateSecret`, {
    method: 'POST',
  });
}

interface AddDeveloperBody {
  developerId: number;
  roleId: number;
}

export function addDeveloper(id: string, body: AddDeveloperBody): Promise<any> {
  return fetch(`/api/application/${id}`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function deleteDeveloper(id: string, developerId: string): Promise<any> {
  return fetch(`/api/application/${id}/${developerId}`, {
    method: 'DELETE',
  });
}

export function checkUnique(name: string): PromiseWithErrorHandler<boolean> {
  return fetch(`/api/application/check-unique?${qs.stringify({ name }, { indices: false })}`);
}

export interface DeveloperProps {
  id: number;
  corpUserId: number;
  username: string;
  name: string;
  status?: any;
  cellphone: string;
  countOfApp?: any;
  createTime: string;
  updateTime: string;
  roleName: string;
  devGroup: DevGroup;
}

export function queryDeveloperList(id: string): Promise<DeveloperProps[]> {
  return fetch(`/api/application/${id}/developers`);
}

export function queryAllApplicationList(): Promise<ApplicationProps[]> {
  return fetch(`/api/application/all`);
}
