import { FieldValue, FieldType, AttributeField, InputType } from '../../types';

export function formatValueFn(value: FieldValue['value'], attribute?: AttributeField): string | undefined {
  switch (attribute?.type) {
    case FieldType.ENUM:
      return value !== null || value !== undefined ? value?.toString() : undefined;
    case FieldType.MULTIPLE:
      return value !== null || value !== undefined ? JSON.stringify(value) : undefined;
    case FieldType.RANGE:
      return value !== null || value !== undefined ? JSON.stringify(value) : undefined;
    case FieldType.FIXED:
      return value !== null || value !== undefined ? value?.toString() : undefined;
    default:
      return value?.toString();
  }
}

export const buildValueFn = (value: string, attribute?: AttributeField) => {
  switch (attribute?.type) {
    case FieldType.ENUM:
      return value
        ? ((attribute.inputType === InputType.NUMBER ? Number(value) : String(value)) as FieldValue['value'])
        : undefined;
    case FieldType.MULTIPLE:
      return value ? (JSON.parse(value) as FieldValue['value']) : undefined;
    case FieldType.RANGE:
      return value ? (JSON.parse(value) as FieldValue['value']) : undefined;
    case FieldType.FIXED:
      return value
        ? ((attribute.inputType === InputType.NUMBER ? Number(value) : String(value)) as FieldValue['value'])
        : undefined;
    default:
      return value;
  }
};
