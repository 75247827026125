import { CSSProperties, FC, ReactNode } from 'react';

const codeStyle: CSSProperties = {
  fontSize: 12,
  padding: '5px 10px',
  fontFamily: 'Consolas, Menlo, monospace',
  background: 'hsla(0,0%,58.8%,.1)',
  border: '1px solid hsla(0,0%,39.2%,.2)',
  borderRadius: 3,
  whiteSpace: 'pre-wrap',
  overflowWrap: 'anywhere',
};

interface CodeProps {
  style?: CSSProperties;
  children?: ReactNode;
}

const Code: FC<CodeProps> = props => {
  return <div style={{ ...codeStyle, ...props.style }}>{props.children}</div>;
};

export default Code;
