import { FormContent, Paging, usePaging, Wrapper, useAsync } from '@maxtropy/components';
import { Button, Form, Input, Space, Spin } from 'antd';
import React, { useCallback, useReducer, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import EdgeDeviceTemplateDisplay from '@/shared/components/EdgeDeviceTemplateForm/EdgeDeviceTemplateDisplay';
import EdgeDevicePointInfo from '@/shared/components/EdgeDevicePointInfo/Detail';
import { DataPointType, EdgeDeviceTemplatePoint, IotProtocolType } from '@/shared/types';
import { getEdgeTemplate, getEdgeTemplateByVersion } from '../../api/edgeTemplate';
import { useQuery } from '../../utils/utils';
import { getPointsPageByTemplateId, getPointsPageByVersionId, modifyVersion, Points } from '../../api/template';

import classNames from 'classnames';
import styles from './index.module.scss';

const routes = [{ name: 'Iot配置' }, { name: '数采配置' }, { name: '数采模板' }, { name: '模板详情' }];

function formatPoints(res: Points[]) {
  return res as EdgeDeviceTemplatePoint[];
}

interface SearchParams {
  name?: string;
  pointType?: DataPointType;
}

const Detail: React.FC = () => {
  const { templateId } = useParams<{ templateId: string }>();
  const pagingInfo = usePaging(50);
  const navigate = useNavigate();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [editable, setEditable] = useState<boolean>(false);
  const [versionRemark, setVersionRemark] = useState<string>();
  const [searchParams, setSearchParams] = useState<SearchParams>({ pointType: DataPointType.BASE_POINT });
  const [x, forceUpdate] = useReducer(x => x + 1, 1);

  const params = new URLSearchParams(window.location.search);
  const versionId = params.get('versionId');

  const { data: info, isLoading: isInfoLoading } = useQuery(
    useCallback(async () => {
      const templateDetail = await getEdgeTemplate(Number(templateId));
      let templateVersionDetail = {};
      if (versionId) {
        templateVersionDetail = await getEdgeTemplateByVersion(+versionId);
      }
      return { ...templateDetail, ...templateVersionDetail };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [templateId, versionId, x])
  );

  const fetchPointsRequest = useCallback(async () => {
    if (templateId && info) {
      let params = {
        page: pageOffset,
        size: pageSize,
      };
      if (info.iotProtocol === IotProtocolType.MOCKINGBIRD) {
        params = {
          page: pageOffset,
          size: pageSize,
          ...searchParams,
        };
      }
      if (versionId) {
        const points = await getPointsPageByVersionId({
          templateVersionId: +versionId,
          ...params,
        });
        setTotalCount(points.total);
        return formatPoints(points.list);
      } else {
        const points = await getPointsPageByTemplateId({
          templateId: +templateId,
          ...params,
        });
        setTotalCount(points.total);
        return formatPoints(points.list);
      }
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info, templateId, pageSize, pageOffset, searchParams]);

  const pointsData = useAsync(fetchPointsRequest);

  const onSave = async () => {
    await modifyVersion({ versionId: +versionId!, remark: versionRemark });
    setEditable(false);
    forceUpdate();
  };

  const onChangeText = (e: { target: { value: string } }) => {
    setVersionRemark(e.target.value);
  };

  return (
    <Wrapper className={styles.wrapper} routes={routes}>
      <FormContent title="基础信息">
        <Spin spinning={isInfoLoading}>
          <div className={styles.info}>
            {info?.iotProtocol && <EdgeDeviceTemplateDisplay type={info?.iotProtocol} row={info} />}
            <Form.Item label="版本号">
              <div className={styles.inline}>
                {info?.version ? <div className={styles.version}>{info.version}</div> : '-'}
                <span className={styles.tip}>（当前数采模板最新版本号：{info?.latestVersion || '-'}）</span>
                {info?.latestVersion && info?.version !== info?.latestVersion ? (
                  <Link to={`/device/edge/template/${templateId}?versionId=${info?.latestVersionId}`}>
                    查看最新版本
                  </Link>
                ) : (
                  <div />
                )}
              </div>
            </Form.Item>
          </div>
        </Spin>
      </FormContent>
      <FormContent title="采集信息">
        <Form.Item label="版本备注">
          <div className={styles.remark}>
            {editable ? (
              <Input
                bordered={editable}
                style={{ width: 180 }}
                placeholder={'请输入版本备注'}
                value={versionRemark}
                onChange={onChangeText}
                className={styles.input}
                disabled={!editable}
              />
            ) : (
              <span className={styles.input}>{info?.versionRemark || '-'}</span>
            )}
            {versionId && (
              <span style={{ marginLeft: 10 }}>
                {!editable ? (
                  <Space className={classNames(styles.action, styles.baseline)}>
                    <Button
                      type="link"
                      onClick={() => {
                        setVersionRemark(info?.versionRemark);
                        setEditable(true);
                      }}
                    >
                      编辑
                    </Button>
                  </Space>
                ) : (
                  <Space className={styles.action}>
                    <Button type="link" onClick={onSave}>
                      保存
                    </Button>
                    <Button type="link" onClick={() => setEditable(false)}>
                      取消
                    </Button>
                  </Space>
                )}
              </span>
            )}
          </div>
        </Form.Item>
        <div style={{ paddingBottom: 20 }}>
          {info && (
            <EdgeDevicePointInfo
              info={info}
              dataSource={pointsData}
              type={info.iotProtocol}
              setSearchParams={values => {
                setPageOffset(1);
                setSearchParams({
                  ...searchParams,
                  ...values,
                });
              }}
            />
          )}
          <Paging pagingInfo={pagingInfo} />
        </div>
      </FormContent>
      <Space size={10} className={styles.footer} style={{ zIndex: 1000 }}>
        <Button onClick={() => navigate('/device/edge/template')}>返回</Button>
      </Space>
    </Wrapper>
  );
};

export default Detail;
