import { FormContent, Wrapper } from '@maxtropy/components';
import { Button, Descriptions, Tag } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { isEmpty, isNil } from 'lodash-es';
import {
  AlarmLevelColorDisplay,
  AlarmLevelDisplay,
  IotProtocolType,
  IotProtocolTypeDisplay,
  AlarmChannel,
} from '@/shared/types';
import { parseOnDisplay } from '@/shared/components/FormulaInput';
import { useQuery, sliceDeviceTypeName } from '@/shared/utils/utils';
import { getDataProperties } from '@/shared/api/options';
import styles from '../List/index.module.scss';
import { V2RuleInfoPostResponse, apiV2RuleInfoPost } from '@maxtropy/device-mgmt-apis-v2';
import { apiV2RuleGetChannelPost, V2RuleGetChannelPostResponse } from '@maxtropy/tody-mgmt-apis-v2';

import { LongShineDetailItem } from '@/pages/Alarm/Detail/components/AlarmChannelDetailItem/LongShineDetailItem';
import { TripartiteServer } from '@/pages/Alarm/Detail/components/AlarmChannelDetailItem/TripartiteServer';

const routes = [{ name: 'Iot配置' }, { name: '报警规则' }, { name: '设备报警规则' }, { name: '设备报警规则详情' }];

const AlarmDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [info, setInfo] = useState<V2RuleInfoPostResponse>();
  const [alarmChannelList, setAlarmChannelList] = useState<V2RuleGetChannelPostResponse['list']>();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const tabs = urlSearchParams.get('tabs') || '1';

  const DetailItemMapByChannel = {
    [AlarmChannel.LONGSHINE as AlarmChannel]: LongShineDetailItem(info),
    [AlarmChannel.THIRD as AlarmChannel]: TripartiteServer(info),
  };

  // 获取渠道数据
  useEffect(() => {
    apiV2RuleGetChannelPost().then(res => {
      setAlarmChannelList(res.list ?? []);
    });
  }, []);

  useEffect(() => {
    if (id) {
      apiV2RuleInfoPost({ id: +id }).then(res => {
        setInfo(res);
      });
    }
  }, [id]);

  const { data: dataProperties = [] } = useQuery(
    useCallback(
      () =>
        info?.iotProtocol && !isNil(info?.deviceTypeId)
          ? getDataProperties(info.iotProtocol, info.deviceTypeId, info.physicalModelId)
          : Promise.resolve([]),
      [info?.iotProtocol, info?.deviceTypeId, info?.physicalModelId]
    )
  );

  const dataPropertiesMap = useMemo(
    () => new Map(dataProperties.map(item => [String(item.id), item.name])),
    [dataProperties]
  );

  return (
    <Wrapper routes={routes} className={styles.detail}>
      <FormContent>
        <Descriptions layout="vertical">
          <Descriptions.Item label="报警规则名称">{info?.name ?? '--'}</Descriptions.Item>
          <Descriptions.Item label="所属租户">{info?.rootFullName ?? '--'}</Descriptions.Item>
          <Descriptions.Item label="报警等级">
            {info ? (
              <Tag color={AlarmLevelColorDisplay[info.alarmType!]}>{AlarmLevelDisplay[info.alarmType!]}</Tag>
            ) : (
              '--'
            )}
          </Descriptions.Item>
          <Descriptions.Item label="报警信息">{info?.alarmName ?? '--'}</Descriptions.Item>
          {info && (
            <Descriptions.Item label="渠道">
              {alarmChannelList?.find(i => i.code === info.channel)?.desc ?? '--'}
            </Descriptions.Item>
          )}

          {info?.channel === AlarmChannel.MAXTROPY && (
            <>
              <Descriptions.Item label="物联层协议">
                {info?.iotProtocol ? IotProtocolTypeDisplay[info.iotProtocol] : '--'}
              </Descriptions.Item>
              {info?.iotProtocol === IotProtocolType.MOCKINGBIRD && (
                <>
                  <Descriptions.Item label="适用设备类目">
                    {info?.deviceTypeName ? sliceDeviceTypeName(info?.deviceTypeName) : '--'}
                  </Descriptions.Item>

                  <Descriptions.Item label="物模型型号">{info?.modelNo ?? '--'}</Descriptions.Item>

                  <Descriptions.Item label="报警持续时间">{info?.duration ?? '--'}</Descriptions.Item>
                  <Descriptions.Item label="关联属性">
                    {isEmpty(info?.relatedPropertyIds)
                      ? '--'
                      : dataProperties
                          .filter(v => info?.relatedPropertyIds?.includes(v.id))
                          .map(v => v.name)
                          .join(', ')}
                  </Descriptions.Item>
                  <Descriptions.Item label="报警公式">
                    {parseOnDisplay(info?.formula, dataPropertiesMap) ?? '--'}
                  </Descriptions.Item>
                </>
              )}
              {info?.iotProtocol === IotProtocolType.ESTUN && (
                <>
                  <Descriptions.Item label="边缘上报的报警编号">{info?.alarmCode ?? '--'}</Descriptions.Item>
                  <Descriptions.Item label="故障代码">{info?.faultCode ?? '--'}</Descriptions.Item>
                </>
              )}
            </>
          )}

          {DetailItemMapByChannel[info?.channel as AlarmChannel]}
        </Descriptions>
        <Button>
          <Link to={`/device/rule/list?tabs=${tabs}`}>返回</Link>
        </Button>
      </FormContent>
    </Wrapper>
  );
};

export default AlarmDetail;
