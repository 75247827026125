import { ExclamationCircleFilled } from '@ant-design/icons';
import { AttachmentUpload, Button, FormContent, Wrapper } from '@maxtropy/components';
import { Form, Modal, Space, Spin } from 'antd';
import { isNil } from 'lodash-es';
import { useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  changeServiceStatus,
  deleteService,
  getServiceDetail,
  ServiceListRes,
  ServiceStatus,
} from '../../../api/openPlatform-serviceManage';
import Footer from '../../../components/Footer';
import ShowInput from '../../../components/ShowInput';
const routes = [{ name: '开发者平台' }, { name: '应用开发开放平台' }, { name: '服务商管理' }, { name: '服务商详情' }];

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 9 },
};

const ServiceDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [enableLoading, setEnableLoading] = useState<boolean>(false);
  const [xx, forceUpdate] = useReducer(x => x + 1, 0);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [serviceInfo, setServiceInfo] = useState<ServiceListRes>();
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    if (id) {
      getServiceDetail(id).then(res => {
        form.setFieldsValue({
          name: res.name,
          companyName: res.companyName,
          introduction: res.introduction,
          logoResource: res.logoResource,
        });
        setServiceInfo(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, xx]);

  const onDelete = () => {
    if (serviceInfo?.developerAccounts?.length) {
      modal.warning({
        title: '该服务商有账号存在不能删除！',
        icon: <ExclamationCircleFilled />,
        maskClosable: true,
        okText: '查看',
        onOk: () => {
          navigate(`/open-platform/open-platform/developer-account?serviceId=${id}`);
        },
      });
      return;
    }
    if (serviceInfo?.applications?.length) {
      modal.warning({
        title: '该服务商有应用存在不能删除！',
        icon: <ExclamationCircleFilled />,
        maskClosable: true,
        okText: '查看',
        onOk: () => {
          navigate(`/open-platform/open-platform/app?serviceId=${id}`);
        },
      });
      return;
    }

    modal.confirm({
      title: '确认要删除该服务商吗？',
      icon: <ExclamationCircleFilled />,
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        if (isNil(id)) return;
        setLoading(true);
        deleteService(id).then(() => {
          setLoading(false);
          navigate('/open-platform/open-platform/service-management');
        });
      },
    });
  };

  const onChangeStatus = () => {
    const tipText =
      serviceInfo?.status === ServiceStatus.ENABLE
        ? '您确认要禁用此服务商吗？禁用后服务商下的账号都不能登录。'
        : '您确认要启用此服务商吗？';
    modal.confirm({
      title: tipText,
      icon: <ExclamationCircleFilled />,
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        if (isNil(id)) return;
        setEnableLoading(true);
        const status = serviceInfo?.status === ServiceStatus.ENABLE ? ServiceStatus.DISABLE : ServiceStatus.ENABLE;
        changeServiceStatus(id, { status }).then(res => {
          forceUpdate();
          setEnableLoading(false);
        });
      },
    });
  };

  return (
    <Wrapper routes={routes} className="open-platform-common-wrapper">
      <FormContent title={'服务商详情'}>
        <Form style={{ padding: '40px 74px' }} form={form} layout="vertical" {...formItemLayout}>
          <Form.Item name="name" label="服务商名称">
            <ShowInput />
          </Form.Item>
          <Form.Item name="companyName" label="公司名称">
            <ShowInput />
          </Form.Item>
          <Form.Item name="introduction" label="简介">
            <ShowInput />
          </Form.Item>

          <Form.Item name="logoResource" label="logo">
            <AttachmentUpload fileSize={1} disabled tip={false} />
          </Form.Item>

          <Footer style={{ padding: 0 }}>
            <Space>
              <Button type="primary" onClick={onDelete}>
                <Spin spinning={loading} /> 删除
              </Button>
              <Button type="primary" onClick={onChangeStatus} danger={serviceInfo?.status === ServiceStatus.ENABLE}>
                <Spin spinning={enableLoading} /> {serviceInfo?.status === ServiceStatus.ENABLE ? '禁用' : '启用'}
              </Button>
              <Button onClick={() => navigate('/open-platform/open-platform/service-management')}>返回</Button>
            </Space>
          </Footer>
        </Form>
      </FormContent>
      {contextHolder}
    </Wrapper>
  );
};

export default ServiceDetail;
