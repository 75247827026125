import { fetch } from '@maxtropy/components';
import qs from 'qs';

export interface AreaItem {
  adcode: string;
  name: string;
  center: string;
  level: string;
  citycode: string;
  polyline: string | null;
  districts?: AreaItem[];
}

export function fetchAreaJson() {
  return fetch<AreaItem>(`/api/location/district?subdistrict=3`);
}

export interface LocationTipsRequest {
  keywords?: string;
  city?: string;
  // cityLimit?: string;
}

export interface LocationTipsResponse {
  tips: Array<{
    id: string;
    name: string;
  }>;
  [key: string]: any;
}

export function fetchLocationTips(request: LocationTipsRequest) {
  return fetch<LocationTipsResponse>(
    `/api/location/inputTips?${qs.stringify(
      {
        ...request,
        cityLimit: false,
      },
      { indices: false }
    )}`
  );
}
