import { DataPointType } from '../../types';

export enum OperateType {
  DATA_POINT,
  DRIVE_TYPE_PARAMETERS,
}

export const OperateTypeDisplay = {
  [OperateType.DATA_POINT]: '数据点',
  [OperateType.DRIVE_TYPE_PARAMETERS]: '驱动类型参数',
};

export enum PointOperateType {
  UPDATE,
  ADD,
  DELETE,
}

export const PointOperateTypeDisplay = {
  [PointOperateType.UPDATE]: '修改数据点',
  [PointOperateType.ADD]: '新增数据点',
  [PointOperateType.DELETE]: '删除数据点',
};

export const DataPointTypeDisplay = {
  [DataPointType.BASE_POINT]: '采集点',
  [DataPointType.VIRTUAL_POINT]: '虚拟点',
};
