import { useEffect, useRef, useState } from 'react';
import { TreeNode } from '../components/interface';
import { generateTree, getTokens, strongMarked } from '../utils/markdown-utils';
import { flowRight, isEqual } from 'lodash-es';
import { queryRequestLimit } from '../../../api/openPlatform-limit';

const fetchMdRequest = (md: string): Promise<[string, TreeNode[]]> =>
  window
    .fetch(md)
    .then(response => response.blob())
    .then(data => {
      return new Promise(async (resolve, reject) => {
        const { second, minute } = await queryRequestLimit();
        const fileReader = new FileReader();
        fileReader.onload = event => {
          const markdownString = event.target!.result as string;
          const tree = flowRight([generateTree, getTokens])(markdownString);
          return resolve([strongMarked(markdownString, second ?? '0', minute ?? '0'), tree]);
        };
        fileReader.onerror = reject;
        fileReader.readAsText(data);
      });
    });

const useMarkdown = (mdList: string[]) => {
  const [markdownHtml, setMarkdownHtml] = useState<string>('');
  const [documentTree, setDocumentTree] = useState<TreeNode[]>([]);
  const lastMdList = useRef<string[]>();

  useEffect(() => {
    if (isEqual(lastMdList.current, mdList)) return;
    lastMdList.current = mdList;
    Promise.all(mdList.map(md => fetchMdRequest(md))).then(results => {
      setMarkdownHtml(results.reduce((pre, cur) => pre + cur[0], ''));
      setDocumentTree(results.reduce((pre, cur) => [...pre, ...cur[1]], [] as TreeNode[]));
    });
  }, [mdList]);

  return [markdownHtml, documentTree] as const;
};

export default useMarkdown;
