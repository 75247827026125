import React, { CSSProperties } from 'react';
import { Col, Form, Row } from 'antd';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useAsync } from '@maxtropy/components';
import { IotProtocolType } from '@/shared/types';
import { getProtocol } from '../../../api/protocol';
import { GatewayDetail, SYSTEM_TYPE_MAP, TransferProtocolTypeDisplay } from '../../../api/gateway';
import styles from './index.module.scss';

export interface GatewayDisplayProps {
  row?: GatewayDetail;
  className?: string;
  style?: CSSProperties;
}

const GatewayDisplay: React.FC<GatewayDisplayProps> = ({ row, className, style }) => {
  const iotProtocolData = useAsync(getProtocol);

  return (
    <Form className={classNames(className, styles.desc)} style={style}>
      <Row>
        <Col span={12}>
          <Form.Item label="网关编号">{row?.serialNumber}</Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="网关名称">{row?.name}</Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="所属租户">{row?.tenantName}</Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="所属组织">{row?.customerFullName}</Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="物联层协议">{iotProtocolData?.find(item => item.id === row?.iotProtocol)?.name}</Form.Item>
        </Col>
        {row?.iotProtocol === IotProtocolType.MOCKINGBIRD && (
          <>
            <Col span={12}>
              <Form.Item label="上传频率">
                {row?.uploadFrequency ? Object.values(row?.uploadFrequency)[0] : '-'}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="网关系统类型">
                {row?.systemType !== undefined ? SYSTEM_TYPE_MAP[row.systemType] : '-'}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="网关元数据采集设备">
                {row?.deviceName || '-'}
                {row?.deviceId && (
                  <span style={{ marginLeft: 10 }}>
                    <Link to={`/device/manage/device/${row.deviceId}/detail`}>查看</Link>
                  </span>
                )}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="下发类型">
                {row?.transferProtocol !== undefined ? TransferProtocolTypeDisplay[row.transferProtocol] : '-'}
              </Form.Item>
            </Col>
          </>
        )}
        <Col span={12}>
          <Form.Item label="备注">{row?.remark || '-'}</Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default GatewayDisplay;
