import { Typography } from 'antd';
import { BaseInfoContent, Button, Form, Key, usePaging } from '@maxtropy/components';
import React, { useEffect, useMemo, useState } from 'react';
import { EdgeDeviceData, formItemsDisplayByType } from '@/shared/components/DataAcquisitionForm/factory';
import { EdgeDeviceTemplatePoint } from '@/shared/components/EdgeDevicePointInfo';
import DevicePointDetail from './DevicePointDetail';
import { SearchParams } from '@/shared/components/EdgeDevicePointInfo/mockingbird/Detail';
import { IotProtocolType } from '@/shared/types';
import { useNavigate, Link } from 'react-router-dom';
import useIsGateway from '@/shared/hooks/useIsGateway';
import styles from '../index.module.scss';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

interface EdgeDetailProps {
  data?: EdgeDeviceData;
  dataSource: EdgeDeviceTemplatePoint[];
  hasDataColumns?: boolean;
  edgeGatewaySerialNumber?: string;
  physicalModelId?: Key;
}

const EdgeDetail: React.FC<EdgeDetailProps> = ({
  data,
  dataSource,
  hasDataColumns,
  edgeGatewaySerialNumber,
  physicalModelId,
}) => {
  const pagingInfo = usePaging(10000);
  const [expand, setExpand] = useState<boolean>(true);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const { isGateway } = useIsGateway(data?.deviceTypeId);
  const FormDisplay = data?.iotProtocol !== undefined ? formItemsDisplayByType[data?.iotProtocol] : undefined;
  const navigate = useNavigate();

  const _data = useMemo(() => {
    let _dataSource = dataSource;
    if (data?.iotProtocol === IotProtocolType.MOCKINGBIRD) {
      _dataSource = dataSource.filter(item => (item as any).pointType === searchParams?.pointType);
    }
    setTotalCount(_dataSource.length);
    return _dataSource
      .filter(item =>
        !searchParams?.name
          ? true
          : item.dataPropertyName?.includes(searchParams?.name) || item.identifier?.includes(searchParams?.name)
      )
      .slice(pageSize * (pageOffset - 1), pageOffset * pageSize);
  }, [dataSource, pageOffset, pageSize, searchParams, setTotalCount, data?.iotProtocol]);

  useEffect(() => {
    setPageOffset(1);
  }, [searchParams?.pointType]);

  return (
    <>
      <Form layout="vertical" {...formLayout}>
        <div className={styles.itemLabel}>
          <BaseInfoContent layout="horizontal" className={styles.leftBase}>
            <Form.Item name="edgeGatewaySerialNumber" label="关联网关">
              {edgeGatewaySerialNumber ? (
                <>
                  {edgeGatewaySerialNumber}&nbsp;
                  <Typography.Link>
                    <Link target="_blank" to={`/device/config/gateway?serialNumber=${edgeGatewaySerialNumber}`}>
                      查看网关
                    </Link>
                  </Typography.Link>
                </>
              ) : (
                '--'
              )}
            </Form.Item>
            <Form.Item name="edgeDeviceTemplateName" label="数采模板">
              <div>{data?.edgeDeviceTemplateName ?? '--'}</div>
            </Form.Item>
            <Form.Item name="edgeDeviceTemplateVersion" label="版本号">
              <div>{data?.edgeDeviceTemplateVersion ?? '--'}</div>
            </Form.Item>
            {expand && (
              <>
                <Form.Item name="edgeDeviceTemplateLatestVersion" label="最新版本号">
                  <div>{data?.edgeDeviceTemplateLatestVersion ?? '--'}</div>
                </Form.Item>
                <Form.Item name="edgeDeviceTemplateVersionRemark" label="版本备注">
                  <div>{data?.edgeDeviceTemplateVersionRemark ? data.edgeDeviceTemplateVersionRemark : '--'}</div>
                </Form.Item>
              </>
            )}
            {expand && data?.iotProtocol === IotProtocolType.MOCKINGBIRD && !isGateway && FormDisplay && (
              <FormDisplay data={data} />
            )}
          </BaseInfoContent>
          <Button className={styles.expandBtn} type="link" onClick={() => setExpand(!expand)}>
            {expand ? '收起' : '展开'}
            {expand ? <UpOutlined /> : <DownOutlined />}
          </Button>
        </div>
      </Form>

      {data && (
        <>
          <DevicePointDetail
            type={data.iotProtocol}
            dataSource={_data}
            info={{
              iotProtocol: data.iotProtocol,
              driveType: data.driveType,
              deviceTypeId: data.deviceTypeId,
              physicalModelId,
            }}
            setSearchParams={setSearchParams}
            hasDataColumns={hasDataColumns}
          />
          {/* <Paging pagingInfo={pagingInfo}/> */}
        </>
      )}
    </>
  );
};

export default EdgeDetail;
