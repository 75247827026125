import React, { useContext, useEffect, useState } from 'react';
import { Input, RadioChangeEvent } from 'antd';
import { DataPointType } from '@/shared/types';
import Point from '@/shared/components/EdgeDevicePointInfo/mockingbird/Point';
import Virtual from '@/shared/components/EdgeDevicePointInfo/mockingbird/Virtual';
import styles from '../index.module.scss';
import StaticSpot from '@/shared/components/EdgeDevicePointInfo/mockingbird/Static';
import { PointContext } from '@/shared/components/EdgeDevicePointInfo/contextTypes';
import useIsGateway from '@/shared/hooks/useIsGateway';
import { EllipsisSpan, MxColumnsType, Radio } from '@maxtropy/components';
import dayjs from 'dayjs';

export interface SearchParams {
  name?: string;
  pointType?: DataPointType;
}

interface MockingbirdPointDetailProps {
  loading?: boolean;
  setSearchParams?: (searchParams: SearchParams) => void;
  hasDataColumns?: boolean;
}

export const dataColumns: MxColumnsType<any> = [
  {
    title: '实时数据',
    dataIndex: 'edgeData',
    ellipsis: { showTitle: true },
    width: 120,
    render: (v: string) => <EllipsisSpan value={v ? (isNaN(Number(v)) ? v : (+v).toFixed(4)) : v} />,
  },
  {
    title: '采集时间',
    dataIndex: 'edgeTime',
    ellipsis: { showTitle: true },
    width: 180,
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : undefined} />,
  },
];

const MockingbirdPointDetail: React.FC<MockingbirdPointDetailProps> = props => {
  const { loading, setSearchParams, hasDataColumns } = props;

  const [activeKey, setActiveKey] = useState<DataPointType>();
  const [searchValue, setSearchValue] = useState('');
  const { info } = useContext(PointContext);
  const { loading: isGatewayLoading, isGateway } = useIsGateway(info?.deviceTypeId);

  useEffect(() => {
    if (isGatewayLoading) return;
    if (isGateway) {
      setActiveKey(DataPointType.STATIC_POINT);
    } else {
      setActiveKey(DataPointType.BASE_POINT);
    }
  }, [isGatewayLoading, isGateway]);

  useEffect(() => {
    if (activeKey) {
      setSearchParams?.({ pointType: +activeKey, name: searchValue });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey, searchValue]);

  if (isGatewayLoading) return null;

  return (
    <div className={styles.container}>
      <div className={styles.optionRow}>
        <Radio.Group
          buttonStyle="solid"
          onChange={(e: RadioChangeEvent) => {
            setActiveKey(e.target.value);
          }}
          value={activeKey}
        >
          <Radio.Button value={1}>采集点</Radio.Button>
          <Radio.Button value={2}>虚拟点</Radio.Button>
          <Radio.Button value={3}>静态点</Radio.Button>
        </Radio.Group>
        <div>
          <Input.Search
            style={{ width: 260 }}
            placeholder="请输入数据属性名称/标识符"
            allowClear
            onSearch={setSearchValue}
          />
        </div>
      </div>

      {!isGateway && activeKey === DataPointType.BASE_POINT && (
        <Point loading={loading} editColumns={hasDataColumns ? dataColumns : []} fixed={false} />
      )}
      {!isGateway && activeKey === DataPointType.VIRTUAL_POINT && (
        <Virtual loading={loading} editColumns={hasDataColumns ? dataColumns : []} />
      )}
      {activeKey === DataPointType.STATIC_POINT && (
        <StaticSpot loading={loading} editColumns={hasDataColumns ? dataColumns : []} />
      )}
    </div>
  );
};

export default MockingbirdPointDetail;
