import { UploadOutlined } from '@ant-design/icons';
import { getRealUrl } from '@maxtropy/components';
import { Button, message, Modal, Space, Spin, Upload } from 'antd';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/es/upload/interface';
import { useRef, useState } from 'react';
import {
  DataPointInterval,
  dataPointIntervalDisplay,
  DataPointPeriod,
  dataPointPeriodDisplay,
} from '../../../api/type';
import { apiDataRuleIdImportPost } from '../../../ytt/types/id/import';
import { numberToString } from '../../TemplateManage/utils';
import styles from '../index.module.scss';
import { DataPoint } from './InstanceFormItems';
import { downloadFile } from '@/utils/utils';
const fileSize = 50 * 1024 * 1024;
interface Iprops {
  onConfirm?: (fileKey?: string) => void;
  onCancel?: () => void;
  id?: number;
  data?: DataPoint;
}
const UploadModal = ({ data, id, onConfirm, onCancel }: Iprops) => {
  const [loading, setLoading] = useState(false);
  const [modalApi, modalContextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();
  const keyRef = useRef<string>('');
  const [errorKey, setErrorKey] = useState<any>('');
  const onChange = (uploadFile: UploadChangeParam<UploadFile>) => {
    const { file } = uploadFile;
    if (file.status === 'uploading') {
      setLoading(true);
    }
    if (file.status === 'error') {
      // 上传失败
      setLoading(false);
      keyRef.current = '';
    }
    if (file.status === 'done') {
      // 上传完成
      let key = file.response.key;
      keyRef.current = key;
      // 将fileKey拿去校验
      apiDataRuleIdImportPost({
        id: numberToString(id)!,
        fileKey: key,
        period: data?.dataPointPeriod!,
        interval: data?.dataPointInterval!,
      })
        .then((error: any) => {
          if (error?.failKey) {
            setErrorKey(error.failKey);
            return;
          }
          setErrorKey('');
        })
        .finally(() => setLoading(false));
    }
  };
  const beforeUpload = (file: RcFile) => {
    const { size, name } = file;
    const limitFileSize = size <= fileSize;
    const limitFileType = name.includes('.xlsx') || name.includes('.xls');
    if (!limitFileSize) {
      modalApi.warning({
        content: `上传文件的大小不得超过50M`,
      });
      return false;
    }
    if (!limitFileType) {
      modalApi.warning({
        content: `文件格式错误！仅支持.xls、.xlsx`,
      });
      return false;
    }
    return true;
  };
  const downloadTemplate = () => {
    downloadFile(`/api/data-rule/${id}/download?period=${data?.dataPointPeriod}&interval=${data?.dataPointInterval}`);
  };
  const downLoadError = () => {
    window.open(getRealUrl(errorKey), '_blank');
  };
  return (
    <>
      <Modal
        title="上传数据"
        width={600}
        open
        onOk={() => {
          if (!keyRef.current) {
            messageApi.warning('请上传模板');
            return;
          }
          if (errorKey) {
            messageApi.error('模板文件校验错误, 请重新上传');
            return;
          }
          onConfirm?.(keyRef.current);
        }}
        onCancel={() => onCancel?.()}
      >
        <Spin spinning={loading}>
          <p>
            1、当前上传数据周期为：{dataPointPeriodDisplay[data?.dataPointPeriod as DataPointPeriod]}，数据间隔为：
            {dataPointIntervalDisplay[data?.dataPointInterval as DataPointInterval]}，请{' '}
            <span onClick={downloadTemplate} className={styles.main_color}>
              下载模板
            </span>
          </p>
          <p>2、请不要删除模板中的内容，仔细阅读模板说明，并按要求填写数据，填写完成后上传</p>
          <Upload
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            action={`/api/file-center/upload`}
            onChange={onChange}
            beforeUpload={beforeUpload}
            showUploadList={true}
            maxCount={1}
          >
            <>
              <Space>
                <Button loading={loading} icon={<UploadOutlined />}>
                  点击上传
                </Button>
                {!!errorKey && (
                  <span onClick={downLoadError} className={styles.error}>
                    下载错误文档
                  </span>
                )}
              </Space>
            </>
          </Upload>
        </Spin>
      </Modal>
      {modalContextHolder}
      {messageContextHolder}
    </>
  );
};

export default UploadModal;
