import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { UserStatus } from './cc-const';

export interface Paged<T> {
  total: number;
  offset: number;
  count: number;
  contents: T[];
}

export interface PageRequest {
  offset?: number;
  count?: number;
}

export interface StaffResponse {
  id: number;
  status: UserStatus;
  username: string;
  staffCode: string;
  muid: string;
  mcid: string;
  customerUserName: string;
  customerUserUsername: string;
  customerUserHumanCode: string;
  customerUserCellphone: string;
  customerUserStatus: UserStatus;
  currentCustomerName: string;
  currentCustomerFullName: string;
  currentCustomerMcid: string;
  currentCustomerCode: string;
  rootCustomerName: string;
  rootCustomerMcid: string;
  rootTenantCode: string;
  rootTenantName: string;
  parentCustomerName: string;
  parentCustomerFullName: string;
  parentCustomerMcid: string;
  parentCustomerCode: string;
  mgmtRoleName: string;
  mgmtRoleCode: string;
}

export interface StaffBaseRequest {
  humanCode?: string;
  username?: string;
  cellphone?: string;
  status?: UserStatus;
  staffUsername?: string;
  staffStatus?: string;
  customer?: string;
  rootCustomer?: string;
}

export interface StaffRequest extends PageRequest, StaffBaseRequest {}

export function getStaffList(query: StaffRequest = {}): Promise<Paged<StaffResponse>> {
  return fetch(`/api/staff/page?${qs.stringify(query, { indices: false })}`);
}

export function getStaffInfo(staffId: number): Promise<StaffResponse> {
  return fetch(`/api/staff/info?staffId=${staffId}`);
}

export function disableStaff(staffId: number): Promise<boolean> {
  return fetch(`/api/staff/disable?staffId=${staffId}`);
}

export function enableStaff(staffId: number): Promise<boolean> {
  return fetch(`/api/staff/enable?staffId=${staffId}`);
}

export function freezeStaff(staffId: number): Promise<boolean> {
  return fetch(`/api/staff/freeze?staffId=${staffId}`);
}

export function disableBatchStaff(staffIds: number[]): Promise<boolean> {
  return fetch(`/api/staff/disable/batch?${qs.stringify({ staffIds }, { arrayFormat: 'repeat' })}`);
}

export interface BatchReassignStaffparams {
  staffIds: number[];
  muid: string;
  operationPassword: string;
}

export function batchReassignStaff(params: BatchReassignStaffparams): Promise<boolean> {
  return fetch(`/api/staff/batchReassign`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export function getStaffCode(mcid: string): Promise<{ staffCode: string }> {
  return fetch(`/api/staff/staff-code?mcid=${mcid}`);
}

export function checkUnique(staffCode: string, mcid: string) {
  return fetch(`/api/staff/checkUnique?staffCode=${staffCode}&mcid=${mcid}`);
}

export interface CreateStaffProps {
  mcid: string;
  muid: string;
  status: UserStatus;
  staffCode: string;
  workEmail?: string;
  workWechat?: string;
  workQq?: string;
  roleId?: number;
  tenant: string;
}

export function createStaff(body: CreateStaffProps, tenantMcid: string): Promise<CreateStaffProps> {
  return fetch(`/api/staff/create`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'x-tenant-mcid': tenantMcid,
    },
  });
}
