import React, { useContext, useMemo, useState } from 'react';
import { Button, Modal, MxColumnsType } from '@maxtropy/components';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ActionType, ModbusPoint, DataPointType, DataProperty } from '../../../../../types';
import { PointContext, PointContextProps } from '../../../contextTypes';
import ModbusPointBase from './modbusPointBase';
import CreateForm from './CreateForm';
import Header from '../../Header';
import { message, Space, Upload } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import { isEmpty } from 'lodash-es';
import { useHasPermission } from '@/utils/utils';
import { PermissionsType } from '@/common/permissionsConst';
import { useLocation } from 'react-router-dom';

export type EdgeDeviceTemplatePoint = ModbusPoint & { actionType?: ActionType };

interface ModbusPointProps {
  fixed?: boolean;
  loading?: boolean;
  editColumns?: MxColumnsType<EdgeDeviceTemplatePoint>;
  onUpdate?: (values: any) => any;
  dataPropertiesAll?: DataProperty[];
  usedProperties?: number[];
  usedIdentifier?: string[];
}

const ModbusPointCom: React.FC<ModbusPointProps> = props => {
  const { fixed, loading, editColumns, onUpdate, dataPropertiesAll, usedProperties, usedIdentifier } = props;
  const [importLoading, setImportLoading] = useState(false);
  const { row, setRow, info, dataSource, setDataSource, promptSlot, editable } = useContext(
    PointContext
  ) as PointContextProps<EdgeDeviceTemplatePoint>;
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  let from = urlSearchParams.get('from') || undefined;

  const hasImport = useHasPermission(PermissionsType.B_IMPORTDEVICECONFIG);
  const hasExport = useHasPermission(PermissionsType.B_EXPORTDEVICECONFIG);

  const _row = useMemo(() => {
    if (row) {
      const { parameters, ...rest } = row;
      return {
        ...rest,
        ...parameters,
      };
    } else {
      return row;
    }
  }, [row]);

  const onOk = async (values: any) => {
    const {
      dataPropertyId,
      dataPropertyName,
      physicalUnitId,
      physicalUnitGeneralName,
      identifier,
      hasProperty,
      writable,
      remark,
      starting,
      ...rest
    } = values;

    const submitValues = {
      pointType: DataPointType.BASE_POINT,
      dataPropertyId,
      dataPropertyName,
      physicalUnitId,
      physicalUnitGeneralName,
      identifier,
      hasProperty,
      writable,
      remark,
      parameters: {
        ...rest,
        driveType: info?.driveType,
        pointType: DataPointType.BASE_POINT,
        starting: parseInt(starting, 16),
      },
    };
    if (onUpdate) {
      await onUpdate(submitValues);
    }
  };

  const beforeUpload = async (info: File) => {
    const limitFileSize = info.size / 1024 / 1024 <= 1;

    if (!limitFileSize) {
      message.warning(`上传文件的大小不得超过1M`);
      return false;
    }

    return true;
  };

  const onUploadChange = (uploadFile: UploadChangeParam<UploadFile>) => {
    const { file } = uploadFile;
    if (file.status === 'uploading') {
      setImportLoading(true);
    }

    if (file.status === 'done') {
      setImportLoading(false);
      if (file.response?.code === 50000) {
        Modal.error({
          title: file.response?.msg,
          okText: '确定',
        });
        return;
      }

      Modal.success({
        title: '导入成功！若有枚举值，请配置映射关系。',
        okText: '确定',
      });

      const fileData = !isEmpty(file.response?.data?.list)
        ? file.response.data.list.map((i: EdgeDeviceTemplatePoint) => ({ ...i, actionType: ActionType.ADD }))
        : [];

      const data = [
        ...fileData,
        ...(dataSource ? dataSource.filter(i => i.pointType !== DataPointType.BASE_POINT) : []),
      ];

      setDataSource?.(data);
    }
  };

  return (
    <>
      <Header slot={promptSlot}>
        <Space size={10}>
          {editable && (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setRow?.({
                  pointType: DataPointType.BASE_POINT,
                });
              }}
            >
              添加采集点
            </Button>
          )}
          {from === 'edgeTemplate' && hasExport && (
            <Button
              type="primary"
              onClick={() => {
                window.open(
                  `/api/v2/edgeTemplate/download/point?templateId=${info?.id}&templateVersionId=${info?.latestVersionId}&pointType=1`
                );
              }}
            >
              导出配置
            </Button>
          )}
          {from === 'edgeTemplate' && editable && hasImport && (
            <>
              <Upload
                action="/api/v2/edgeTemplate/point/readExcel"
                accept=".xlsx"
                showUploadList={false}
                onChange={onUploadChange}
                beforeUpload={beforeUpload}
                data={{
                  templateId: info?.id,
                  pointType: 1,
                }}
              >
                <Button type="primary" loading={importLoading}>
                  导入配置
                </Button>
              </Upload>
              <InfoCircleOutlined style={{ color: 'var(--mx-warning-color)' }} />
              <span style={{ color: 'rgba(0,0,0,0.35)', fontSize: 12 }}>导入配置会覆盖已有配置</span>
            </>
          )}
        </Space>
      </Header>
      <ModbusPointBase
        editColumns={editColumns}
        loading={loading}
        fixed={fixed}
        dataPropertiesAll={dataPropertiesAll}
      />
      <CreateForm
        onCancel={() => setRow?.(undefined)}
        row={_row}
        dataPropertiesAll={dataPropertiesAll}
        usedProperties={usedProperties}
        usedIdentifier={usedIdentifier}
        onOk={onOk}
      />
    </>
  );
};

export default ModbusPointCom;
