import React, { useEffect, useMemo, useState } from 'react';
import {
  Wrapper,
  Table,
  EllipsisSpan,
  useUpdate,
  FormContent,
  AttachmentUpload,
  Key,
  getRealUrl,
} from '@maxtropy/components';
import { Form, Select, Col, Space, Button, Modal, Row, Input } from 'antd';
import Filter from '@/shared/components/Filter';
import { Link } from 'react-router-dom';
import { getRoot, Tenant } from '@/shared/api/options';
import {
  addAnalysisReportType,
  editAnalysisReportType,
  getAnalysisReportTypeById,
  getAnalysisReportTypeList,
  PageSearchParams,
  ReportTypeDetail,
} from '../../../api/reportsUpload';
import styles from './index.module.scss';
import defaultImg from './default.png';

// 查询参数类型
type SearchParams = Omit<PageSearchParams, 'page' | 'size' | 'typeId'>;

const routes = [{ name: '数据中心' }, { name: '信息管理' }, { name: '报告管理' }, { name: '报告类型管理' }];

const ReportTypeList: React.FC = () => {
  const [form] = Form.useForm();
  const [updateState, updateFn] = useUpdate();
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const [tenant, setTenant] = useState<Tenant[]>([]);
  const [reportTypeList, setReportTypeList] = useState<ReportTypeDetail[]>([]);
  const [addTypeOpen, setAddTypeOpen] = useState<boolean>(false);
  const [addTypeForm] = Form.useForm();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [analysisReportTypeId, setAnalysisReportTypeId] = useState<Key>();
  const [coverPicResource, setCoverPicResource] = useState<string>();
  const [coverPicModalOpen, setCoverPicModalOpen] = useState<boolean>(false);

  useEffect(() => {
    getRoot().then(setTenant);
  }, []);

  const tenantOptions = useMemo(() => {
    return tenant.map(i => ({ label: `【${i.tenantCode}】${i.name}`, value: i.mcid }));
  }, [tenant]);

  // 列表查询
  useEffect(() => {
    setLoading(true);
    getAnalysisReportTypeList({
      ...searchParams,
    })
      .then(setReportTypeList)
      .finally(() => setLoading(false));
  }, [searchParams, updateState]);

  useEffect(() => {
    if (isEdit && analysisReportTypeId) {
      getAnalysisReportTypeById(analysisReportTypeId).then(res => {
        addTypeForm.setFieldsValue({
          tenantMcid: res.tenantMcid,
          name: res.name,
          coverPicResource: res.coverPicResource,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, analysisReportTypeId]);

  const onFinish = (value: SearchParams) => {
    setSearchParams(value);
  };

  const onReset = () => {
    setSearchParams(undefined);
  };

  const buildColumns = [
    {
      title: '类型名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '封面图',
      dataIndex: 'coverPicResource',
      ellipsis: { showTitle: true },
      render: (v: string) => {
        return (
          <div className={styles.image}>
            {v ? <img alt="封面图" src={getRealUrl(v)} /> : <img alt="封面图" src={defaultImg} />}
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => {
                setCoverPicResource(v);
                setCoverPicModalOpen(true);
              }}
            >
              点击查看
            </Button>
          </div>
        );
      },
    },
    {
      title: '所属租户',
      dataIndex: 'tenantName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      width: 200,
      // fixed: "right" as const,
      render: (value: unknown, record: ReportTypeDetail) => {
        return (
          <Space size={16}>
            <Button
              type="link"
              onClick={() => {
                setIsEdit(true);
                setAddTypeOpen(true);
                setAnalysisReportTypeId(record.id);
              }}
            >
              编辑
            </Button>
          </Space>
        );
      },
    },
  ];

  const filters = (
    <Filter
      form={form}
      onFinish={val => {
        onFinish(val as SearchParams);
      }}
      onReset={onReset}
    >
      <>
        <Col span={8}>
          <Form.Item name="tenantMcid" label="所属租户">
            <Select
              style={{ width: '100%' }}
              placeholder="请选择"
              options={tenantOptions}
              optionFilterProp="label"
              showSearch
            />
          </Form.Item>
        </Col>
      </>
    </Filter>
  );

  const onModalOk = async () => {
    const values = await addTypeForm.validateFields();
    if (isEdit && analysisReportTypeId) {
      const params = {
        name: values.name,
        tenantMcid: values.tenantMcid,
        coverPicResource: values.coverPicResource ?? '',
      };
      editAnalysisReportType(analysisReportTypeId, params).then(() => {
        setAddTypeOpen(false);
        setIsEdit(false);
        addTypeForm.resetFields();
        updateFn();
      });
    } else {
      addAnalysisReportType(values).then(() => {
        setAddTypeOpen(false);
        addTypeForm.resetFields();
        updateFn();
      });
    }
  };

  return (
    <>
      <Wrapper routes={routes} filters={filters}>
        <Space size={12}>
          <Button type="primary">
            <Link to={'/data/info/report'}>返回报告管理</Link>
          </Button>
          <Button type="primary" onClick={() => setAddTypeOpen(true)}>
            新增类型
          </Button>
        </Space>
        <FormContent style={{ padding: 0 }}>
          <Table rowKey="id" loading={loading} columns={buildColumns} dataSource={reportTypeList} />
        </FormContent>
      </Wrapper>
      <Modal
        width={620}
        destroyOnClose
        open={addTypeOpen}
        title={isEdit ? '编辑报告类型' : '新增报告类型'}
        onCancel={() => {
          setAddTypeOpen(false);
          setIsEdit(false);
          addTypeForm.resetFields();
        }}
        onOk={onModalOk}
      >
        <Form style={{ width: 450 }} labelAlign="right" labelCol={{ flex: '80px' }} form={addTypeForm}>
          <Row>
            <Col span={22}>
              <Form.Item name="tenantMcid" label="所属租户" rules={[{ required: true, message: '请选择所属租户' }]}>
                <Select
                  disabled={isEdit}
                  style={{ width: '100%' }}
                  placeholder="请选择"
                  options={tenantOptions}
                  optionFilterProp="label"
                  showSearch
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={22}>
              <Form.Item
                name="name"
                label="类型名称"
                rules={[
                  { required: true, message: '请填写类型名称' },
                  { max: 15, message: '最多输入15个字符' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name="coverPicResource" label="封面图">
                <AttachmentUpload fileSize={2} accept=".jpg, .jpeg, .png" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="预览"
        width={620}
        okText="确定"
        open={coverPicModalOpen}
        onCancel={() => setCoverPicModalOpen(false)}
        onOk={() => setCoverPicModalOpen(false)}
      >
        {
          <>
            {coverPicResource ? (
              <img alt="封面图" style={{ width: '520px', objectFit: 'contain' }} src={getRealUrl(coverPicResource)} />
            ) : (
              <img alt="封面图" src={defaultImg} />
            )}
          </>
        }
      </Modal>
    </>
  );
};

export default ReportTypeList;
