import { Empty, Input, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';
import { DeviceAttribute } from '@/shared/types';

export interface AttributeListProps {
  usedAttributeIds: number[];
  deviceType?: number;
  isLoading: boolean;
  data?: { attributes: DeviceAttribute[] };
}

const AttributeList: React.FC<AttributeListProps> = ({ usedAttributeIds, deviceType, isLoading, data }) => {
  const [input, setInput] = useState('');
  const [keyword, setKeyword] = useState('');
  useEffect(() => {
    setKeyword('');
  }, [deviceType]);

  return (
    <div className={styles.attributeList}>
      <Input.Search
        style={{ padding: 10 }}
        placeholder="请输入搜索的属性"
        allowClear
        onChange={e => setInput(e.target.value)}
        value={input}
        onSearch={setKeyword}
      />
      <div className={styles.dashed}></div>
      <div className={styles.title}>
        属性列表
        <span className={styles.info}>
          <InfoCircleOutlined style={{ color: '#FFD100', marginLeft: 3, marginRight: 1 }} />
          拖拽属性到右侧画布
        </span>
      </div>
      <Spin spinning={isLoading}>
        {data?.attributes.length ? (
          data?.attributes
            .filter(item => item.name.toLowerCase().includes(keyword.toLowerCase()))
            .map(item => (
              <div
                className={classNames(styles.item, usedAttributeIds.includes(item.id) && styles.itemDisabled)}
                key={item.id}
                draggable={!usedAttributeIds.includes(item.id)}
                onDragStart={e => e.dataTransfer.setData('item', JSON.stringify(item))}
              >
                {item.name}
              </div>
            ))
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Spin>
    </div>
  );
};

export default AttributeList;
