import { Wrapper } from '@maxtropy/components';
import { useRequest } from 'ahooks';
import { Input, Spin, Steps } from 'antd';
import { ComponentType, createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PropertyType } from '../../api/type';
import { apiDataRulePost, ApiDataRulePostRequest } from '../../ytt/types/api/dataRule';
import { apiDataRuleIdGet, DataRuleIdGetResponse } from '../../ytt/types/dataRule/id';
import { numberToString } from '../TemplateManage/utils';
import AccumulateFormItems from './components/AccumulateFormItems';
import InstanceFormItems from './components/InstanceFormItems';
import SceneFormItems from './components/SceneFormItems';
import styles from './index.module.scss';
const { TextArea } = Input;

const baseRoutes = [{ name: '数据运营工具' }, { name: '数据模拟' }];

const getStepListByPropsType: Record<string, { title: string; key: number }[]> = {
  instance: [
    { title: '确定场景', key: 1 },
    { title: '设置瞬时值', key: 2 },
  ],
  accumulate: [
    { title: '确定场景', key: 1 },
    { title: '设置瞬时值', key: 2 },
    { title: '计算累积量', key: 3 },
  ],
};

const formStep: ComponentType<any>[] = [SceneFormItems, InstanceFormItems, AccumulateFormItems];
export const DataValueContext = createContext<{
  data?: DataRuleIdGetResponse;
  isEdit?: boolean;
  nextStep?: () => void;
  prevStep?: () => void;
  saveLoading?: boolean;
  saveForm?: (values: ApiDataRulePostRequest) => Promise<number>;
}>({});
const DataValueRules = (props: { isEdit?: boolean }) => {
  // 当前属性类型
  const [currentPropsType, setCurrentPropsType] = useState('instance');
  const routes = useMemo(() => {
    return [...baseRoutes, { name: `${props.isEdit ? '编辑' : '创建'}数值型模板` }];
  }, [props.isEdit]);
  const { id } = useParams<{ id?: string }>();
  const { type } = useParams<{ type?: string }>();
  const localValueId = sessionStorage.getItem('valueId');
  const [saveLoading, setSaveLoading] = useState(false);
  const stepTileList = useMemo(() => {
    return getStepListByPropsType[currentPropsType].map(({ title, key }) => ({ key, title }));
  }, [currentPropsType]);
  // 当前步骤
  const [current, setCurrent] = useState(1);
  const hasStepRef = useRef<number>();
  // 当前步骤要显示的表单
  const CurrentFormItem = useMemo(() => {
    return formStep[current - 1];
  }, [current]);
  // 当前数据值id
  const [currentValueId, setCurrentValueId] = useState<number>();
  // 数据值详情
  const { data: detail, loading } = useRequest(() => apiDataRuleIdGet({ id: numberToString(currentValueId)! }), {
    ready: !!currentValueId,
    refreshDeps: [current],
  });
  useEffect(() => {
    if (detail?.propertyType === PropertyType.ACCUMULATE) {
      setCurrentPropsType('accumulate');
      return;
    }
    setCurrentPropsType('instance');
  }, [detail]);
  useEffect(() => {
    if (id) {
      setCurrentValueId(+id);
    }
  }, [id]);
  useEffect(() => {
    if (!props.isEdit && localValueId) {
      setCurrentValueId(+localValueId);
    }
  }, [props.isEdit]);
  useEffect(() => {
    if (detail?.step) {
      // 只赋值一次
      if (hasStepRef.current) return;
      setCurrent(detail.step);
      hasStepRef.current = detail.step;
    }
  }, [detail]);
  const nextBtn = useCallback(() => {
    setCurrent(current => current + 1);
  }, []);
  const prevBtn = useCallback(() => {
    setCurrent(current => current - 1);
  }, []);

  // 保存表单api
  const saveFormApi = useCallback(
    (values: ApiDataRulePostRequest) => {
      setSaveLoading(true);
      return apiDataRulePost({
        id: currentValueId,
        ...values,
        step: current,
        type: type as ApiDataRulePostRequest['type'],
      })
        .then(res => {
          setCurrentValueId(res);
          // 新建时本地保存id, 防止新建页面刷新时数据丢失
          if (!props.isEdit) {
            sessionStorage.setItem('valueId', `${res}`);
          }
          return res;
        })
        .finally(() => {
          setSaveLoading(false);
          return false;
        });
    },
    [currentValueId, current]
  );
  useEffect(() => {
    return () => {
      sessionStorage.removeItem('valueId');
    };
  }, []);
  // contextValue 注入的值
  const contextValue = useMemo(() => {
    return {
      data: detail,
      isEdit: props.isEdit,
      nextStep: nextBtn,
      prevStep: prevBtn,
      saveForm: saveFormApi,
      saveLoading,
    };
  }, [detail, props.isEdit, nextBtn, prevBtn, saveFormApi, saveLoading]);
  return (
    <>
      <Wrapper routes={routes} className={styles.outer}>
        <div className={styles.page_box}>
          <div className={styles.steps_box}>
            <Steps current={current - 1} items={stepTileList} onChange={v => setCurrent(v)} />
          </div>
          <div className={styles.form_content}>
            <Spin spinning={loading}>
              <DataValueContext.Provider value={contextValue}>
                <CurrentFormItem />
              </DataValueContext.Provider>
            </Spin>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default DataValueRules;
