import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { OuStaffType, OuStatus } from '@/shared/const';
import { Operator } from '@/shared/types';
import { PageRequest, PageResponse } from './page';

export interface QueryProductionUnitListRequest extends PageRequest {
  nameOrSerialNum?: string;
  tenantMcid?: string;
  ifCancel?: boolean;
}

export interface ProductionUnitListProps {
  homePage?: string;
  id: number;
  name: string;
  ouTypeId: string;
  mcid: string;
  tenantId: string;
  tenantName: string;
  customerName: string;
  directorName: string;
  directorPhone: string;
  remark: string;
  updateTime: string;
  updateByUsername: string;
  updateByUserId: string;
  updateSource: Operator;
  serialNumber: string;
  ouTypeName: string;
  deviceNum: string;
  status: OuStatus;
}

export const queryProductionUnitList = (params: QueryProductionUnitListRequest) =>
  fetch<PageResponse<ProductionUnitListProps>>(`/api/ou/page?${qs.stringify(params, { indices: false })}`);

interface QueryUserListRequest extends PageRequest {
  name?: string;
  staffCode?: string;
  phone?: string;
  username?: string;
  humanCode?: string;
  tenantMcid: string;
}

export interface UserListProps {
  staffId: number;
  id: number;
  humanCode: string;
  staffType: any;
  mcid: string;
  muid: string;
  status: number;
  staffCode: string;
  username: string;
  name: string;
  phone: string;
  orgName: string;
  orgFullName: string;
}

export const queryUserList = (params: QueryUserListRequest) =>
  fetch<PageResponse<UserListProps>>(`/api/ou/user/page?${qs.stringify(params, { indices: false })}`);

export interface OuStaffListProps {
  staffId: number;
  id: number;
  staffType: OuStaffType;
  muid: string;
  username: string;
  staffCode: string;
  name: string;
  phone: string;
  orgName: string;
  humanCode: string;
}

export const queryOuStaffList = (id: string) => fetch<OuStaffListProps[]>(`/api/ou/loadStaffInfoList/${id}`);

export const queryOuStaffPage = (id: string, params: PageRequest) =>
  fetch<PageResponse<OuStaffListProps>>(
    `/api/ou/loadStaffInfoPage/${id}?${qs.stringify(params, {
      indices: false,
    })}`
  );

export interface OuTypeListProps {
  id: number;
  name: string;
}

export const queryOuTypeList = () => fetch<OuTypeListProps[]>(`/api/ou/ouType/list`);

interface AddOuBody {
  name: string;
  ouTypeId: string;
  tenantId: string;
  mcid: string;
  directorName: string;
  directorPhone: string;
  remark: string;
  memberIds: number[];
  adminIds: number[];
}

export const addOu = (body: AddOuBody) =>
  fetch<never>(`/api/ou`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'x-tenant-mcid': `${body.tenantId}`,
    },
  });

export interface OuInfoProps {
  tenantMcid: string;
  id: number;
  name: string;
  ouTypeId: number;
  ouTypeName: string;
  status: OuStatus;
  tenantId: string;
  tenantName: string;
  tenantCode?: any;
  mcid: string;
  deviceNum?: any;
  customerName: string;
  directorName: string;
  directorPhone: string;
  remark: string;
  createTime: string;
  updateTime: string;
  updateByUsername: string;
  updateByUserId: string;
  updateSource: number;
  serialNumber: string;
  userType?: any;
}

export const getOuInfo = (id: string) => fetch<OuInfoProps>(`/api/ou/${id}`);

interface UpdateOuBody {
  id: string;
  name: string;
  directorName: string;
  directorPhone: string;
  remark: string;
  memberIds: number[];
  adminIds: number[];
}

export const updateOu = (id: string, body: UpdateOuBody, tenantMcid: string) =>
  fetch<never>(`/api/ou/${id}`, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      'x-tenant-mcid': tenantMcid,
    },
  });

interface UpdateOuState {
  state: OuStatus;
  password: string;
}

export const updateOuState = (id: number, body: UpdateOuState) =>
  fetch<never>(`/api/ou/${id}/state`, {
    method: 'PUT',
    body: JSON.stringify(body),
  });
