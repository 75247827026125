import { FC } from 'react';
import { Form, Input, Row, Col, InputNumber } from 'antd';
import { FactorPropertyEnum } from '../emissionProperty';
import ExtraFactor from './ExtraFactor';

const FormItem = Form.Item;

interface Props {}
const Carbonate: FC<Props> = () => {
  return (
    <>
      <Row gutter={16}>
        <Col span={8}>
          <FormItem
            label="CO2排放因子"
            name={['factorDetails', `${FactorPropertyEnum.CarbonateCO2EmissionFactor}_1`]}
            rules={[{ required: true }]}
          >
            <InputNumber placeholder="请输入数值" style={{ width: '100%' }} />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            label="单位"
            name={['factorDetails', `${FactorPropertyEnum.CarbonateUnit}_2`]}
            rules={[{ max: 50, message: '长度不能大于50个字符' }]}
          >
            <Input placeholder="请输入" />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem
            label="分子式"
            name={['factorDetails', `${FactorPropertyEnum.CarbonateMolecularFormula}_3`]}
            rules={[{ max: 50, message: '长度不能大于50个字符' }]}
          >
            <Input placeholder="请输入" />
          </FormItem>
        </Col>
      </Row>
      <ExtraFactor start={4} />
    </>
  );
};

export default Carbonate;
