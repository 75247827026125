import React, { useEffect, useState } from 'react';
import { Wrapper } from '@maxtropy/components';
import { Tree } from 'antd';
import { getTypeTree } from '../../api/type';
import { DataNode } from 'antd/es/tree';

const routes = [{ name: 'Iot配置' }, { name: '设备类型库' }, { name: '类目树' }];

function formatTreeData(data: any[]) {
  return data?.map(item => {
    const res: DataNode = {
      ...item,
      key: item.key,
      title: item.name,
      children: formatTreeData(item.deviceTypes ?? item.children),
    };
    return res;
  });
}

const CategoryTree: React.FC = () => {
  const [treeData, setTreeData] = useState<any[]>([]);

  useEffect(() => {
    getTypeTree().then(data => {
      setTreeData(formatTreeData(data.tree));
    });
  }, []);

  return (
    <Wrapper routes={routes}>
      <Tree style={{ margin: '50px' }} showLine={{ showLeafIcon: true }} treeData={treeData} />
    </Wrapper>
  );
};

export default CategoryTree;
