import { fetch } from '@maxtropy/components';
import { PointSet, PointSetContent, PointSetV2, PointSetContentV2 } from './entities';

export function getPointSetByRuleSetId(id: number): Promise<PointSet[]> {
  return fetch(`/api/point-set/by-rule-set/${id}`);
}

export function getPointSetByRuleSetIdV2(id: number, tenantMcid: string): Promise<PointSetV2[]> {
  return fetch(`/api/v2/point-set/by-rule-set/${id}`, {
    headers: {
      'x-tenant-mcid': tenantMcid,
    },
  });
}

export function deletePointSet(id: number): Promise<void> {
  return fetch(`/api/point-set/${id}`, { method: 'DELETE' });
}

export function deletePointSetV2(id: number): Promise<void> {
  return fetch(`/api/v2/point-set/${id}`, { method: 'DELETE' });
}

export function createPointSet(data: PointSetContent): Promise<PointSet> {
  return fetch(`/api/point-set`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function createPointSetV2(data: Omit<PointSetContentV2, 'devices' | 'dataPropertys'>): Promise<PointSetV2> {
  return fetch(`/api/v2/point-set`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function updatePointSet(id: number, data: PointSetContent): Promise<PointSet> {
  return fetch(`/api/point-set/${id}`, {
    method: 'PUT',
    body: JSON.stringify(data),
  });
}

export function updatePointSetV2(
  id: number,
  data: Omit<PointSetContentV2, 'devices' | 'dataPropertys'>
): Promise<PointSetV2> {
  return fetch(`/api/v2/point-set/${id}`, {
    method: 'PUT',
    body: JSON.stringify(data),
  });
}
