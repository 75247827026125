import React, { ReactNode, useEffect, useState } from 'react';
import { Wrapper, FormContent, AttachmentUpload } from '@maxtropy/components';
import styles from './index.module.scss';
import { Button, Col, Descriptions, Divider, Form, Input, Modal, Row, Select, Space } from 'antd';
import ChooseOrganizationFormItem from './components/ChooseOrganizationFormItem';
import DescriptionsItem from 'antd/es/descriptions/Item';
import { getOrganization, getTenantUserDetail, OrganizationProps } from '../../../../api/cc-customer';
import { genderEnum, statusEnum } from '../../../PlatformManagement/OrganizationAuthority/StaffManagement/util';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { LeftNavPreferenceDisplay, RoleStatus, UserStatus } from '../../../../api/cc-const';
import { getAllTenant, TenantAllRootReponse } from '../../../../api/cc-tenant';
import { DataNode } from 'antd/es/tree';
import { checkUnique, createStaff, CreateStaffProps, getStaffCode } from '../../../../api/cc-staff';
import { commonCodeRegex } from '../../../../utils/regex';
import { AllRoleProps, getAllRole } from '../../../../api/cc-role';
import { countModal } from '../../../../utils/help';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

const formLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

function formatTreeData(data: OrganizationProps[]) {
  return data.map(item => {
    const res: DataNode = {
      ...item,
      key: item.data.mcid,
      title: item.data.name,
      children: formatTreeData(item.children),
    };
    return res;
  });
}

const TenantStaffCreate: React.FC = () => {
  const [modalApi, modalContextHolder] = Modal.useModal();
  const routeParams: { muid?: string } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [tenantUserDescriptions, setTenantDescriptions] = useState<{ label: string; value: string | ReactNode }[]>();
  const [allTenant, setAllTenant] = useState<TenantAllRootReponse[]>([]);
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [allRole, setAllRole] = useState<AllRoleProps[]>([]);
  const [organizationDisabled, setOrganizationDisabled] = useState<boolean>(true);
  const { pathname } = useLocation();

  const isUser = pathname.includes('user');

  useEffect(() => {
    getTenantUserDetail(routeParams.muid).then(res => {
      form.setFieldsValue({
        workEmail: res.email ?? '',
        workWechat: res.wechat ?? '',
        workQq: res.qq ?? '',
      });
      setTenantDescriptions([
        {
          label: '用户ID',
          value: res.humanCode,
        },
        {
          label: '手机号码',
          value: res.cellphone,
        },
        {
          label: '姓名',
          value: res.name,
        },
        {
          label: '用户状态',
          value: statusEnum[res.status],
        },
        {
          label: '登录账号',
          value: res.username,
        },
        {
          label: '性别',
          value: genderEnum[res.gender],
        },
        {
          label: '生日',
          value: res.birthday,
        },
        {
          label: '邮箱',
          value: res.email,
        },
        {
          label: '微信',
          value: res.wechat,
        },
        {
          label: 'QQ',
          value: res.qq,
        },
        {
          label: '头像',
          value: <AttachmentUpload disabled value={res.headPic} />,
        },
        {
          label: '个人偏好',
          value: LeftNavPreferenceDisplay[res.navigationPreference],
        },
      ]);
    });
    getAllTenant({ status: true }).then(data => {
      setAllTenant(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeParams]);

  const onChangeOrganization = () => {
    const mcid = form.getFieldValue('tenant');
    getStaffCode(mcid).then(data => {
      form.setFieldsValue({
        staffCode: data.staffCode,
      });
    });
    getAllRole(mcid).then(data => {
      setAllRole(data.filter(i => i.state === RoleStatus.ENABLE));
    });
    setOrganizationDisabled(false);
  };

  const checkStaffCode = async (rule: any, value: string) => {
    if (!value || !value.trim()) {
      return Promise.reject('请输入正确的企业员工工号');
    } else if (!commonCodeRegex.test(value)) {
      return Promise.reject('企业员工工号为字母加数字，最多12位');
    } else {
      const mcid = form.getFieldValue('mcid');
      const res = await checkUnique(value, mcid);
      if (res) {
        return Promise.reject('企业员工工号已被使用！');
      }
      return Promise.resolve();
    }
  };

  const onFinish = (values: CreateStaffProps) => {
    if (routeParams.muid) {
      values.muid = routeParams.muid;
    }
    createStaff(values, values.tenant).then(data => {
      if (data) {
        countModal(
          modalApi,
          10,
          navigate,
          isUser ? '/tenant/customer/user' : '/tenant/customer/employee',
          `已经成功新建员工，员工工号${data.staffCode}`
        );
      }
    });
  };

  const routes = [
    { name: '租户管理' },
    { name: '租户组织权限和账户' },
    isUser
      ? { name: '租户用户管理', path: '/tenant/customer/user' }
      : { name: '租户员工工号管理', path: '/tenant/customer/employee' },
    { name: '新建企业员工工号' },
  ];

  return (
    <Wrapper routes={routes}>
      <div className={styles.container}>
        <Form
          layout="vertical"
          {...formLayout}
          form={form}
          onFinish={onFinish}
          initialValues={{ status: UserStatus.ENABLE }}
        >
          <FormContent title="租户管理员设定">
            <Descriptions className={styles.description} style={{ padding: 0 }}>
              {tenantUserDescriptions &&
                tenantUserDescriptions.map(item => (
                  <DescriptionsItem key={item.label} label={item.label}>
                    {item.value}
                  </DescriptionsItem>
                ))}
            </Descriptions>
          </FormContent>
          <Divider />
          <FormContent title="企业员工工号设定">
            <Row>
              <Col span={8}>
                <Form.Item
                  name="tenant"
                  label="选择租户"
                  validateFirst={true}
                  rules={[{ required: true, message: '请选择租户' }]}
                >
                  <Select
                    placeholder="输入企业名称的全部/一部分"
                    showSearch
                    onSelect={(v: string) => {
                      getOrganization(v).then(data => {
                        if (data) setTreeData(formatTreeData([data]));
                        setDisabled(false);
                      });
                    }}
                    filterOption={(input, option: any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {allTenant.map(item => (
                      <Option key={item.mcid} value={item.mcid}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="mcid" label="选择组织" rules={[{ required: true, message: '请选择组织' }]}>
                  <ChooseOrganizationFormItem treeData={treeData} disabled={disabled} onChange={onChangeOrganization} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="staffCode"
                  label="企业员工工号"
                  required
                  rules={[{ validator: checkStaffCode }]}
                  validateTrigger={['onBlur']}
                >
                  <Input
                    disabled={organizationDisabled}
                    placeholder="字母+数字，最多12位，可不填，不填则按系统默认规则生成"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="roleId" label="角色">
                  <Select placeholder="请选择角色" disabled={disabled}>
                    {allRole.map(item => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="workEmail" label="工作邮箱" rules={[{ type: 'email', message: '请输入正确的邮箱' }]}>
                  <Input placeholder="请输入工作邮箱" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="workWechat"
                  label="工作微信"
                  rules={[{ pattern: /^[A-Za-z0-9_-]{6,20}$/, message: '请输入6-20位字母、数字、下划线或减号的组合' }]}
                >
                  <Input placeholder="请输入工作微信" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="workQq"
                  label="工作QQ"
                  rules={[{ pattern: /^[0-9]{4,15}$/, message: '请输入4-15位数字' }]}
                >
                  <Input placeholder="请输入工作QQ" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="status"
                  label="员工工号状态"
                  rules={[{ required: true, message: '请选择员工工号状态' }]}
                >
                  <Select>
                    <Option value={UserStatus.ENABLE}>启用</Option>
                    <Option value={UserStatus.DISABLE}>禁用</Option>
                    <Option value={UserStatus.FREEZE}>冻结</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Space>
                <Button htmlType="submit" type="primary">
                  保存
                </Button>
                <Button
                  onClick={() => {
                    modalApi.confirm({
                      title: '操作确认',
                      icon: <ExclamationCircleOutlined />,
                      content: '是否放弃所有未保存信息并返回列表？',
                      onOk: () => navigate(isUser ? '/tenant/customer/user' : '/tenant/customer/employee'),
                    });
                  }}
                >
                  关闭
                </Button>
              </Space>
            </Form.Item>
          </FormContent>
        </Form>
      </div>
      {modalContextHolder}
    </Wrapper>
  );
};

export default TenantStaffCreate;
