import React, { FC, useEffect } from 'react';
import { Form, Row, Col } from 'antd';
import { AttachmentUpload, FormContent } from '@maxtropy/components';
import ShowInput from '@/shared/components/ShowInput';
import { getBatchDeviceTemplateProperty } from '../../../api/batch';
import TagDisplayForm from '@/shared/components/MultiModalSelect/TagDisplayForm';
import styles from './index.module.scss';
import DeviceTagsDisplayForm from '@/shared/components/DeviceTags/TagsDisplayForm';

interface PropertyDetailProps {
  id: string;
}

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const PropertyDetail: FC<PropertyDetailProps> = ({ id }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (id) {
      getBatchDeviceTemplateProperty(id).then(res => {
        form.setFieldsValue({
          typeId: res.typeName,
          modelId: res.modelName,
          pic: res.pic,
          rootMcid: res.rootFullName,
          customerMcid: res.customerName,
          assetCode: res.assetCode,
          ouIds: res.ouIds,
          tags: res.tags ? res.tags.split(',') : [],
          modelNo: res.modelNo,
        });
      });
    }
  }, [id, form]);

  return (
    <Form form={form} layout="vertical" {...formLayout}>
      <FormContent title="设备信息">
        <Row>
          <Col span={8} className={styles.col}>
            <Form.Item name="typeId" label="所属类目">
              <ShowInput />
            </Form.Item>
          </Col>
          <Col span={8} className={styles.col}>
            <Form.Item name="modelId" label="所属型号">
              <ShowInput />
            </Form.Item>
          </Col>
          <Col span={8} className={styles.col}>
            <Form.Item name="rootMcid" label="所属租户">
              <ShowInput />
            </Form.Item>
          </Col>
          <Col span={8} className={styles.col}>
            <Form.Item name="customerMcid" label="所属组织">
              <ShowInput />
            </Form.Item>
          </Col>
          <Col span={8} className={styles.col}>
            <Form.Item name="assetCode" label="资产编码">
              <ShowInput />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="pic" label="设备图片">
              <AttachmentUpload disabled fileSize={5} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="modelNo" label="物模型型号">
              <ShowInput />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="ouIds" label="生产运营单元">
              <TagDisplayForm />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="tags" label="设备标签">
              <DeviceTagsDisplayForm />
            </Form.Item>
          </Col>
        </Row>
      </FormContent>
    </Form>
  );
};

export default PropertyDetail;
