import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Col, DatePicker, Form, InputNumber, Row, Space } from 'antd';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  DataZoomComponent,
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import styles from '../index.module.scss';
import dayjs from 'dayjs';
import { RulesGroupContext } from '..';
import { useRequest } from 'ahooks';
import { apiDataRuleIdGet } from '../../../ytt/types/dataRule/id';
import { numberToString } from '../../TemplateManage/utils';
import { apiDataRuleIdViewGet, IdViewGetRequest, IdViewGetResponse } from '../../../ytt/types/id/view';
import { PropertyType } from '../../../api/type';
import { useNavigate } from 'react-router-dom';

const { useForm, useWatch } = Form;

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  DataZoomComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
]);

function getOption(data: IdViewGetResponse) {
  return {
    tooltip: {
      trigger: 'axis',
      // position: function (pt: Array<any>) {
      //   return [pt[0], '10%'];
      // },
    },
    title: {
      left: 'center',
      text: '预览数据',
    },
    toolbox: {
      feature: {
        dataZoom: {
          yAxisIndex: 'none',
        },
        restore: {},
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'time',
      boundaryGap: false,
    },
    yAxis: {
      type: 'value',
      scale: true,
      boundaryGap: [0, '100%'],
    },
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100,
      },
      {
        start: 0,
        end: 100,
      },
    ],
    series: [
      {
        name: '数据',
        type: 'line',
        // smooth: true,
        symbol: data.length < 288 ? 'emptyCircle' : 'none',
        areaStyle: {},
        data: data.map(i => [i.time, i.value]),
      },
    ],
  };
}

const ChangeParams: FC = () => {
  const [form] = useForm();
  const { nextStep, prevStep, saveForm, saveLoading, data: details } = useContext(RulesGroupContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState<IdViewGetRequest>();
  // 查询label字段
  const { data: labelsDetail } = useRequest(() => apiDataRuleIdGet({ id: numberToString(details?.dataRuleId)! }), {
    ready: !!details?.dataRuleId,
    refreshDeps: [details],
  });

  const { data, loading } = useRequest(
    () => {
      return apiDataRuleIdViewGet({
        id: String(details?.dataRuleId),
        from: formData?.from ? String(dayjs(formData?.from).valueOf()) : String(dayjs().startOf('date').valueOf()),
        duration: formData?.duration ?? '24',
        yMagnificationValue: formData?.yMagnificationValue ?? numberToString(details?.yMagnificationValue) ?? '',
        cumulativeStartValue: formData?.cumulativeStartValue ?? numberToString(details?.cumulativeStartValue) ?? '',
        zoneOffset: numberToString(new Date().getTimezoneOffset() / 60),
      });
    },
    {
      ready: !!details?.dataRuleId,
      debounceWait: 300,
      refreshDeps: [details, formData],
    }
  );
  const onBlur = () => {
    form.validateFields().then(v => {
      setFormData(v);
    });
  };
  const nextBtn = (save = true) => {
    form.validateFields().then(values => {
      saveForm?.({ ...values, save }).then(id => {
        if (id) {
          // 点击保存草稿后跳转到列表页
          if (!save) {
            navigate(-1);
            return;
          }
          nextStep?.();
        }
      });
    });
  };
  useEffect(() => {
    if (details) {
      form.setFieldsValue({
        ...details,
        // 没有值得话从模板中取
        cumulativeStartValue: details?.cumulativeStartValue || labelsDetail?.cumulativeStartValue,
        yMagnificationValue: details?.yMagnificationValue || labelsDetail?.yMagnificationValue,
      });
    }
  }, [details, labelsDetail]);
  const currentPropertyType = useMemo(() => {
    return labelsDetail?.propertyType;
  }, [labelsDetail]);
  return (
    <Form
      form={form}
      onValuesChange={() => {}}
      initialValues={{
        duration: 24,
        from: dayjs().startOf('date'),
      }}
    >
      <Row>
        <Col span={24}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Space style={{ width: '100%' }}>
              <Form.Item name="from" label="起始时间" rules={[{ required: true }]}>
                <DatePicker onBlur={onBlur} showTime />
              </Form.Item>
              <Form.Item name="duration" label="总时长">
                <InputNumber onBlur={onBlur} addonAfter="小时" />
              </Form.Item>
            </Space>
            <div className={styles.chart_box}>
              {(data ?? []).length > 0 && (
                <ReactEChartsCore
                  echarts={echarts}
                  showLoading={loading}
                  notMerge
                  lazyUpdate={false}
                  style={{ height: 400, width: '100%' }}
                  option={getOption(data ?? [])}
                />
              )}
            </div>

            <Space style={{ width: '100%' }}>
              <Form.Item
                name="yMagnificationValue"
                rules={[{ required: true }]}
                label={labelsDetail?.yMagnificationName ?? '-'}
              >
                <InputNumber onBlur={onBlur} />
              </Form.Item>
              {currentPropertyType === PropertyType.ACCUMULATE && (
                <Form.Item
                  name="cumulativeStartValue"
                  rules={[{ required: true }]}
                  label={labelsDetail?.cumulativeStartName ?? '-'}
                >
                  <InputNumber onBlur={onBlur} />
                </Form.Item>
              )}
            </Space>
          </Space>
        </Col>
      </Row>
      <div style={{ paddingTop: 10 }}>
        <Form.Item>
          <Space>
            <Button onClick={() => nextBtn(false)} loading={saveLoading}>
              保存草稿
            </Button>
            <Button onClick={() => prevStep?.()}>上一步</Button>
            <Button type="primary" onClick={() => nextBtn(true)} loading={saveLoading}>
              下一步
            </Button>
          </Space>
        </Form.Item>
      </div>
    </Form>
  );
};

export default ChangeParams;
