import { fetch } from '@maxtropy/components';
import { Key } from 'react';

export enum PlaceType {
  TOP = 'top',
  NAVBAR = 'navbar',
}

export interface IMenu {
  id: number;
  name: string;
  code: string;
  type: string;
  place: PlaceType;
  path: string;
}

export interface MenuEntry {
  name: string;
  url: string;
  key: Key;
  children?: MenuEntry[];
  always?: boolean;
}

export function getMenu(): Promise<IMenu[]> {
  return fetch(`/api/menu`);
}

export function getFunctionPermission(): Promise<string[]> {
  return fetch(`/api/user/function-permission`);
}
