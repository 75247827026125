import { RouteObject } from 'react-router-dom';
import { PermissionsType } from '../common/permissionsConst';
import AuthorizedPermission from '../components/AuthorizedPermission';
import AccountingGuidelines from '../pages/AccountingGuidelines/List';
import GuidelinesCreate from '../pages/AccountingGuidelines/Create';
import AlarmPushDetail from '../pages/AlarmPush/Create/Detail';
import AlarmPushList from '../pages/AlarmPush/List';
import AlarmRecordDetail from '../pages/AlarmRecord/Detail';
import AlarmRecordList from '../pages/AlarmRecord/List';
import AlarmPushCreate from '../pages/AlarmPush/Create';
import AttributeList from '../pages/Attribute';
import AttributeDetail from '../pages/Attribute/Detail';
import BatchDeviceList from '../pages/BatchDevice/List';
import CreateBatchDeviceProperty from '../pages/BatchDevice/CreatePropertyInfo';
import CreateBatchDeviceAttribute from '../pages/BatchDevice/CreateAttributeInfo';
import CreateBatchDeviceEdge from '../pages/BatchDevice/CreateEdgeInfo';
import BatchDeviceDetail from '../pages/BatchDevice/Detail';
import CarbonEmissions from '../pages/CarbonEmissions/List';
import CarbonEmissionsEditBasicInfo from '../pages/CarbonEmissions/Edit/BasicInfo';
import CarbonEmissionsEditCalcFormula from '../pages/CarbonEmissions/Edit/CalcFormula';
import CategoryTree from '../pages/CategoryTree';
import DAQDeployment from '../pages/DaqDeployment';
import BatchBindRuleGroup from '../pages/Device/BatchBindRuleGroup';
import BatchUpdateEdge from '../pages/Device/BatchUpdateEdge';
import CreatePropertyInfo from '../pages/Device/CreatePropertyInfo';
import DeviceList from '../pages/Device/List';
import BatchDeviceAttribute from '../pages/Device/BatchAttribute';
import CreateAttributeInfo from '../pages/Device/CreateAttributeInfo';
import DeviceDetail from '../pages/Device/Detail';
import EdgeTemplateDataCollection from '../pages/EdgeDeviceTemplate/DataCollection';
import PhysicalModel from '../pages/Device/PhysicalModel';
import EdgeTemplateDetail from '../pages/EdgeDistribution/TemplateDetail';
import EdgeDistribution from '../pages/EdgeDistribution';
import EdgeTemplateDistribution from '../pages/EdgeDistribution/TemplateDistribution';
import EdgeTenantDistribution from '../pages/EdgeDistribution/TenantDistribution';
import EdgeTenantDetail from '../pages/EdgeDistribution/TenantDetail';
import DeviceDistribution from '../pages/DeviceDistribution';
import DeviceDistributionTemplateDistribution from '../pages/DeviceDistribution/TemplateDistribution';
import DeviceDistributionTemplateDetail from '../pages/DeviceDistribution/TemplateDetail';
import DeviceDistributionTenantDetail from '../pages/DeviceDistribution/TenantDetail';
import DeviceDistributionTenantDistribution from '../pages/DeviceDistribution/TenantDistribution';
import TradeManagement from '../pages/TradeManagement';
import EmissionFactor from '../pages/EmissionFactor';
import EmissionForm from '../pages/EmissionForm';
import EdgeDeviceTemplateList from '../pages/EdgeDeviceTemplate';
import EdgeDeviceTemplateFormPage from '../pages/EdgeDeviceTemplate/Form';
import EdgeTemplatePoint from '../pages/EdgeDeviceTemplate/TemplatePoint';
import EdgeDeviceVersionList from '../pages/EdgeDeviceTemplate/VersionList';
import EdgeDeviceDetail from '../pages/EdgeDeviceTemplate/Detail';
import GatewayList from '../pages/Gateway';
import CreateGateway from '../pages/Gateway/Create';
import GatewayDetail from '../pages/Gateway/Detail';
import GatewayBind from '../pages/Gateway/Bind';
import HistoryData from '../pages/History';
import DeviceManage from '../pages/DeviceManage';
import EditProductionUnit from '../pages/ProductionUnit/Edit';
import ProductionUitList from '../pages/ProductionUnit/List';
import ReportsUpload from '../pages/ReportsUpload';
import ReportTypeList from '../pages/ReportsUpload/ReportTypeList';
import AlarmList from '../pages/Alarm/List';
import AlarmCreate from '../pages/Alarm/Create/AlarmCreate';
import AlarmDetail from '../pages/Alarm/Detail/AlarmDetail';
import CreateAlarmRulesGroup from '../pages/AlarmRulesGroup/CreateAlarmRulesGroup';
import AlarmRulesGroupDetail from '../pages/AlarmRulesGroup/Detail';
import TemplateDetail from '../pages/TemplateDetail';
import TemplateForm from '../pages/TemplateForm';
import TemplateList from '../pages/TemplateList';
import AIreport from '../pages/AIReport';
import Power from '../pages/PhotovoltaicMeasure/Power';
import Profit from '../pages/PhotovoltaicMeasure/Profit';

const mainRoutes: RouteObject[] = [
  {
    path: 'dualCarbon/carbonEmissions/accountingGuidelines',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_ACCOUNTING_GUIDELINES_MANAGEMENT}>
        <AccountingGuidelines />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'dualCarbon/carbonEmissions/accountingGuidelines/create',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_ACCOUNTING_GUIDELINES_MANAGEMENT}>
        <GuidelinesCreate />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'dualCarbon/carbonEmissions/accountingGuidelines/edit/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_ACCOUNTING_GUIDELINES_MANAGEMENT}>
        <GuidelinesCreate edit />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/alarm/record',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_ALARM_LOG}>
        <AlarmRecordList />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/alarm/record/detail/:id',
    element: <AlarmRecordDetail />,
  },
  {
    path: 'device/alarm/push',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_ALARM_PUSH_STRATEGY_MANAGE}>
        <AlarmPushList />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/alarm/push/create',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_CREATE_ALARM_PUSH_STRATEGY}>
        <AlarmPushCreate />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/alarm/push/edit/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_UPDATE_ALARM_PUSH_STRATEGY}>
        <AlarmPushCreate isEdit />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/alarm/push/detail/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_ALARM_PUSH_STRATEGY_MANAGE}>
        <AlarmPushDetail />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/info/attribute',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PATTRIBUTEMANAGE}>
        <AttributeList />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/info/attribute/detail/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PATTRIBUTEINFO}>
        <AttributeDetail />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/manage/batch',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PBATCHIMPORTDEVICE}>
        <BatchDeviceList />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/manage/batch/create',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PCREATEIMPORTTEMPLATE}>
        <CreateBatchDeviceProperty isNext />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/manage/batch/create/:id',
    element: <CreateBatchDeviceProperty isNext isEdit />,
  },
  {
    path: 'device/manage/batch/:id/detail',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PBATCHDEVICEINFO}>
        <BatchDeviceDetail />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/manage/batch/:id/edit',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PUPDATEBATCHDEVICEBASICINFO}>
        <CreateBatchDeviceProperty isEdit />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/manage/batch/:id/attribute/create',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PUPSERTBATCHDEVICEATTRIBUTEINFO}>
        <CreateBatchDeviceAttribute />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/manage/batch/:id/attribute/edit',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PUPSERTBATCHDEVICEATTRIBUTEINFO}>
        <CreateBatchDeviceAttribute isEdit />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/manage/batch/:id/dataMining/new',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PUPSERTBATCHDEVICEEDGEINFO}>
        <CreateBatchDeviceEdge />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/manage/batch/:id/dataMining/edit',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PUPSERTBATCHDEVICEEDGEINFO}>
        <CreateBatchDeviceEdge />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'dualCarbon/carbonEmissions/process',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_EMISSION_PROCESS_MANAGEMENT}>
        <CarbonEmissions />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'dualCarbon/carbonEmissions/process/basicInfo/create',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_EMISSION_PROCESS_MANAGEMENT}>
        <CarbonEmissionsEditBasicInfo isEdit={false} />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'dualCarbon/carbonEmissions/process/basicInfo/edit/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_EMISSION_PROCESS_MANAGEMENT}>
        <CarbonEmissionsEditBasicInfo isEdit={true} />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'dualCarbon/carbonEmissions/process/basicInfo/detail/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_EMISSION_PROCESS_MANAGEMENT}>
        <CarbonEmissionsEditBasicInfo isEdit={false} />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'dualCarbon/carbonEmissions/process/calcFormula/edit/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_EMISSION_PROCESS_MANAGEMENT}>
        <CarbonEmissionsEditCalcFormula />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'dualCarbon/carbonEmissions/process/calcFormula/detail/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_EMISSION_PROCESS_MANAGEMENT}>
        <CarbonEmissionsEditCalcFormula />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/type/category',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PDEVICETYPETREE}>
        <CategoryTree />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/manage/device',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PDEVICEMANAGEMENT}>
        <DeviceList />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/manage/device/daq-deployment',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PDEVICEMANAGEMENT}>
        <DAQDeployment />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/manage/device/edge/update',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PDEVICEMANAGEMENT}>
        <BatchUpdateEdge />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/manage/device/rule-group/bind',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PDEVICEMANAGEMENT}>
        <BatchBindRuleGroup />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/manage/device/attribute/batch',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PDEVICEMANAGEMENT}>
        <BatchDeviceAttribute />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/manage/device/create',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PCREATEDEVICE}>
        <CreatePropertyInfo isNext />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/manage/device/create/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PCREATEDEVICE}>
        <CreatePropertyInfo isEdit isNext />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/manage/device/:id/edit',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PUPDATEDEVICEBASICINFO}>
        <CreatePropertyInfo isEdit />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/manage/device/:id/attribute/create',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PUPSERTDEVICEATTRIBUTEINFO}>
        <CreateAttributeInfo />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/manage/device/:id/attribute/edit',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PUPSERTDEVICEATTRIBUTEINFO}>
        <CreateAttributeInfo isEdit />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/manage/device/:id/detail',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PDEVICEINFO}>
        <DeviceDetail />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/manage/device/:id/dataMining/new',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PUPSERTDEVICEEDGEINFO}>
        <EdgeTemplateDataCollection />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/manage/device/:id/dataMining/edit',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PUPSERTDEVICEEDGEINFO}>
        <EdgeTemplateDataCollection isEdit />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/manage/physicalmodel',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_PHYSICAL_MODEL_MANAGE}>
        <PhysicalModel />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/edge/distribution',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_EDGETEMPLATEASSIGN}>
        <EdgeDistribution />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/edge/distribution/template/create/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_EDGETEMPLATEASSIGNTENANT}>
        <EdgeTemplateDistribution />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/edge/distribution/template/detail/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_EDGETEMPLATEASSIGN}>
        <EdgeTemplateDetail />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/edge/distribution/tenant/create/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_TENANTASSIGNEDGETEMPLATE}>
        <EdgeTenantDistribution />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/edge/distribution/tenant/detail/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_EDGETEMPLATEASSIGN}>
        <EdgeTenantDetail />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/info/distribution',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_ATTRIBUTETEMPLATEASSIGN}>
        <DeviceDistribution />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/info/distribution/template/create/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_ATTRIBUTETEMPLATEASSIGNTENANT}>
        <DeviceDistributionTemplateDistribution />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/info/distribution/template/detail/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_ATTRIBUTETEMPLATEASSIGN}>
        <DeviceDistributionTemplateDetail />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/info/distribution/tenant/create/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_TENANTASSIGNATRIBUTETEMPLATE}>
        <DeviceDistributionTenantDistribution />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/info/distribution/tenant/detail/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_ATTRIBUTETEMPLATEASSIGN}>
        <DeviceDistributionTenantDetail />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'dualCarbon/basicInfo/emissionFactor',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_EMISSION_FACTOR_POOL}>
        <EmissionFactor />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'dualCarbon/basicInfo/emissionFactor/create',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_EMISSION_FACTOR_POOL}>
        <EmissionForm />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'dualCarbon/basicInfo/emissionFactor/edit/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_EMISSION_FACTOR_POOL}>
        <EmissionForm />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'dualCarbon/basicInfo/tradeManagement',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_TRADE_MANAGEMENT}>
        <TradeManagement />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/edge/template',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PEDGETEMPLATE}>
        <EdgeDeviceTemplateList />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/edge/template/:id/version',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PEDGETEMPLATE}>
        <EdgeDeviceVersionList />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/edge/template/new',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PCREATEEDGETEMPLATE}>
        <EdgeDeviceTemplateFormPage />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/edge/template/:id/edit',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PUPDATEEDGETEMPLATEBASICINFO}>
        <EdgeDeviceTemplateFormPage isEdit />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/edge/template/:templateId',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PEDGETEMPLATE}>
        <EdgeDeviceDetail />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/edge/template/:id/point',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PUPDATEEDGETEMPLATEEDGEINFO}>
        <EdgeTemplatePoint />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/config/gateway',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PEDGEGATEWAYMANAGE}>
        <GatewayList />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/config/gateway/create',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PCREATEGATEWAY}>
        <CreateGateway />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/config/gateway/update/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PUPDATEGATEWAY}>
        <CreateGateway />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/config/gateway/detail/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PEDGEGATEWAYMANAGE}>
        <GatewayDetail />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/config/gateway/update/:id/bind',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PGATEWAYBINDDEVICE}>
        <GatewayBind />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data/history/device',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PDEVICEHISTORY}>
        <HistoryData />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/ou/production-unit',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_OU_MANAGE}>
        <ProductionUitList />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/ou/production-unit/:id/device',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_OU_DEVICE_MANAGE}>
        <DeviceManage />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/ou/production-unit/new',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_CREATE_OU}>
        <EditProductionUnit />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/ou/production-unit/edit/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_UPDATE_OU}>
        <EditProductionUnit />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/ou/production-unit/detail/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_OU_MANAGE}>
        <EditProductionUnit />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data/info/report',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_ANALYSISREPORTMANAGE}>
        <ReportsUpload />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data/info/report/reportTypeList',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_ANALYSISREPORTTYPEMANAGE}>
        <ReportTypeList />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/rule/list',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_RULE_MANAGE}>
        <AlarmList />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/rule/list/create',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_CREATE_RULE}>
        <AlarmCreate />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/rule/list/create/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_UPDATE_RULE}>
        <AlarmCreate isEdit />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/rule/list/detail/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_RULE_MANAGE}>
        <AlarmDetail />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/rule/list/group/create',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_CREATE_RULE_GROUP}>
        <CreateAlarmRulesGroup />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/rule/list/group/edit/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_UPDATE_RULE_GROUP}>
        <CreateAlarmRulesGroup isEdit />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/rule/list/group/detail/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_RULE_MANAGE}>
        <AlarmRulesGroupDetail />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/info/template',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PATTRIBUTETEMPLATE}>
        <TemplateList />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/info/template/new',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PCREATEATTRIBUTETEMPLATE}>
        <TemplateForm />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/info/template/:id/edit',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PCREATEATTRIBUTETEMPLATE}>
        <TemplateForm isEdit />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/info/template/:id/copy',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PCREATEATTRIBUTETEMPLATE}>
        <TemplateForm isCopy />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'device/info/template/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.PATTRIBUTETEMPLATE}>
        <TemplateDetail />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tools/AIreport/manage',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_AIREPORTMANAGE}>
        <AIreport />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tools/photovoltaic/power',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_PVGENERATINGCAPACITY}>
        <Power />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'tools/photovoltaic/profit',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_PVINCOME}>
        <Profit />
      </AuthorizedPermission>
    ),
  },
];

export default mainRoutes;
