import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { Table, Paging, usePaging, EllipsisSpan, Wrapper } from '@maxtropy/components';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import LogDetailModal from './Detail';
import { apiMiniAppLogPagePost } from '@maxtropy/cc-mgmt-apis';
import { WeChatAppLogPagePostResponseItem } from './api';
import styles from './index.module.scss';

const routes = [{ name: '系统设置' }, { name: '小程序' }, { name: '小程序管理' }, { name: '操作日志' }];

const buildColumns = [
  {
    title: '版本号',
    dataIndex: 'version',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '操作人',
    dataIndex: 'opCoprUserName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '操作时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
];

const LogPage: React.FC = () => {
  const pagingInfo = usePaging(50);
  const { logId } = useParams<{ logId: string }>();
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [logListData, setLogListData] = useState<WeChatAppLogPagePostResponseItem[]>([]);
  const [logDetailId, setLogDetailId] = useState<string>();

  useEffect(() => {
    setLoading(true);
    apiMiniAppLogPagePost({ id: logId!, page: String(pageOffset), size: String(pageSize) })
      .then(res => {
        setTotalCount(res.total as number);
        setLogListData(res.list ?? []);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [logId, pageOffset, pageSize, setTotalCount]);

  const columns = [
    ...buildColumns,
    {
      title: '详情',
      fixed: 'right' as const,
      width: 100,
      render: (v: WeChatAppLogPagePostResponseItem) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                setModalOpen(true);
                setLogDetailId(String(v.id));
              }}
            >
              详情
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <Wrapper routes={routes} className={styles.wrapperStyle}>
      <Table rowKey="id" sticky loading={loading} columns={columns} dataSource={logListData} />
      <Paging pagingInfo={pagingInfo} />
      <LogDetailModal modalOpen={modalOpen} setModalOpen={setModalOpen} logDetailId={logDetailId as string} />
    </Wrapper>
  );
};

export default LogPage;
