import { PlusOutlined } from '@ant-design/icons';
import { Button } from '@maxtropy/components';
import { Form, Input, Modal, Space } from 'antd';
import React, { useState } from 'react';
import { identifierValidatorBuilder } from '../../EdgeDevicePointInfo/mockingbird/Point/entity';

const formLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 13 },
};

interface AddIdentifierProps {
  usedIdentifier: string[];
  submit?: (identifier: string) => void;
}

const AddIdentifier: React.FC<AddIdentifierProps> = props => {
  const { usedIdentifier, submit } = props;
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();

  const onCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const onOk = (shouldContinue?: boolean) => {
    form
      .validateFields()
      .then(values => {
        const { identifier } = values;
        submit?.(identifier);
        form.resetFields();
        if (!shouldContinue) {
          setVisible(false);
        }
      })
      .catch(console.error);
  };

  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} onClick={() => setVisible(true)}>
        添加要修改的非建模点标识符
      </Button>
      <Modal
        width={600}
        open={visible}
        title="添加"
        centered={true}
        maskClosable={false}
        onCancel={onCancel}
        footer={
          <Space>
            <>
              <Button type="primary" onClick={() => onOk(true)}>
                确定并继续添加
              </Button>
              <Button type="primary" onClick={() => onOk()}>
                确定并完成
              </Button>
            </>
            <Button type="default" onClick={onCancel}>
              取消
            </Button>
          </Space>
        }
      >
        <Form form={form} {...formLayout}>
          <Form.Item
            name="identifier"
            label="非建模虚拟点标识符"
            validateFirst
            required
            rules={[
              {
                validator: identifierValidatorBuilder(usedIdentifier),
              },
            ]}
          >
            <Input placeholder="请输入" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddIdentifier;
