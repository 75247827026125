import { useEffect, useState } from 'react';
import TextOrSelect from '../../components/TextOrSelect';
import { SelectProps } from 'antd';
import { queryIsvList } from '../../api/openPlatform-application';
import { DefaultOptionType } from 'antd/es/select';

interface DevGroupSelectProps<T extends any> extends SelectProps<T> {
  width?: string;
}

const DevGroupSelect = <T extends any>(props: DevGroupSelectProps<T>) => {
  const [options, setOptions] = useState<DefaultOptionType[]>([]);

  useEffect(() => {
    queryIsvList().then(data => {
      const _data = (data ?? [])
        .filter(i => i.status === 1)
        .map(item => ({
          label: item.name,
          value: item.id,
        }));
      setOptions(_data);
    });
  }, []);

  return <TextOrSelect options={options} style={{ width: props.width ?? 300 }} {...props} />;
};

export default DevGroupSelect;
