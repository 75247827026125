import React, { FC, useEffect, useState } from 'react';
import { Button, Space, Divider, Modal, Checkbox, Form, Col, Select } from 'antd';
import { Wrapper, Filter, Table, Paging, usePaging, EllipsisSpan, useUpdate } from '@maxtropy/components';
import {
  getPreRoleList,
  PrefabRoleListResponse,
  PrefabRoleListRequest,
  updatePreRoleState,
} from '../../../../api/cc-role';
import { RoleStatus, RoleStatusDisplay } from '../../../../api/cc-const';
import styles from './index.module.scss';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const routes = [{ name: '租户管理' }, { name: '租户组织权限和账户' }, { name: '预置角色管理' }];

const columns = [
  {
    title: '角色ID',
    dataIndex: 'id',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '角色名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'state',
    ellipsis: { showTitle: true },
    render: (value: RoleStatus, record: any) => {
      return <EllipsisSpan value={RoleStatusDisplay[value]} />;
    },
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '最后修改时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
];

export type FilterParams = Omit<PrefabRoleListRequest, 'count' | 'offset'>;

const PrefabRoleList: FC = () => {
  const [modalApi, modalContextHolder] = Modal.useModal();
  const [loading, setLoading] = useState<boolean>(false);
  const [updateState, update] = useUpdate();
  const [tableSource, setTableSource] = useState<PrefabRoleListResponse[]>([]);

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [search, setSearch] = useState<FilterParams>();

  const getData = (page?: PrefabRoleListRequest) => {
    setLoading(true);
    getPreRoleList({ ...search, count: pageSize, offset: pageOffset, ...page })
      .then(res => {
        setTotalCount(res.total);
        setPageOffset(res.offset);
        setTableSource(res.contents);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!search) return;
    getData({ offset: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    getData({ offset: pageOffset });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, pageSize, updateState]);

  const updateRoleState = (title: string, id: number, state: RoleStatus) => {
    modalApi.confirm({
      icon: null,
      title: `${title}角色`,
      content: `即将${title}该角色，点击确认以操作该执行`,
      onOk() {
        updatePreRoleState(id, { state }).then(res => {
          if (res) {
            modalApi.success({
              content: `操作成功`,
              onOk() {
                update();
              },
            });
          }
        });
      },
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      fixed: 'right' as const,
      width: 300,
      render: (value: undefined, record: PrefabRoleListResponse) => {
        return (
          <Space size={16}>
            <Button style={{ paddingLeft: 0, paddingRight: 0 }} type="link">
              <Link to={`/tenant/customer/prefab-role/role/detail/${record.code}`} target="_blank">
                查看详情
              </Link>
            </Button>
            <Button style={{ paddingLeft: 0, paddingRight: 0 }} type="link">
              <Link to={`/tenant/customer/prefab-role/role/update/${record.code}`} target="_blank">
                编辑
              </Link>
            </Button>
            {(record.state === RoleStatus.FREEZE || record.state === RoleStatus.INVALID) && (
              <Button
                style={{ paddingLeft: 0, paddingRight: 0 }}
                onClick={() => {
                  updateRoleState('启用', record.id, RoleStatus.ENABLE);
                }}
                type="link"
              >
                启用
              </Button>
            )}
            {(record.state === RoleStatus.FREEZE || record.state === RoleStatus.ENABLE) && (
              <Button
                style={{ paddingLeft: 0, paddingRight: 0 }}
                onClick={() => {
                  updateRoleState('作废', record.id, RoleStatus.INVALID);
                }}
                type="link"
              >
                作废
              </Button>
            )}
            {(record.state === RoleStatus.ENABLE || record.state === RoleStatus.INVALID) && (
              <Button
                style={{ paddingLeft: 0, paddingRight: 0 }}
                onClick={() => {
                  updateRoleState('冻结', record.id, RoleStatus.FREEZE);
                }}
                type="link"
              >
                冻结
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  const onFinish = (value: FilterParams) => {
    value['state'] = value['state'] ?? undefined;
    value['toVoid'] = value['toVoid'] ?? false;
    setSearch({ ...value });
  };

  const onReset = () => {
    setSearch({});
  };

  const [form] = Form.useForm();

  const filters = (
    <Filter<FilterParams> form={form} onFinish={onFinish} onReset={onReset} hasClear>
      <>
        <Col span={6}>
          <Form.Item name="state" label="角色状态">
            <Select placeholder="请选择角色状态">
              <Select.Option value={undefined}>全部</Select.Option>
              <Select.Option value={RoleStatus.ENABLE}>{RoleStatusDisplay[RoleStatus.ENABLE]}</Select.Option>
              <Select.Option value={RoleStatus.FREEZE}>{RoleStatusDisplay[RoleStatus.FREEZE]}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item valuePropName="checked" name="toVoid">
            <Checkbox>显示已作废预置角色</Checkbox>
          </Form.Item>
        </Col>
      </>
    </Filter>
  );

  return (
    <Wrapper routes={routes} filters={filters}>
      <div className={styles.operationArea}>
        <Space>
          <Button type="primary">
            <Link to="/tenant/customer/prefab-role/role/create" target="_blank">
              <PlusOutlined />
              新建预置角色
            </Link>
          </Button>
        </Space>
      </div>
      <Table
        rowKey="id"
        scroll={{ x: 1200 }}
        sticky
        loading={loading}
        dataSource={tableSource}
        columns={buildColumns}
      />
      <Paging pagingInfo={pagingInfo} />
      {modalContextHolder}
    </Wrapper>
  );
};

export default PrefabRoleList;
