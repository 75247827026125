import { PlusOutlined } from '@ant-design/icons';
import { Button, Filter, Paging, useSearchParams, Wrapper } from '@maxtropy/components';
import { Col, Empty, Form, Input, Row, Spin, Tabs, TabsProps } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getServicePage, ServiceListRequest, ServiceListRes } from '../../api/openPlatform-serviceManage';
import ServiceCard from './ServiceCard';

const routes = [{ name: '开发者平台' }, { name: '应用开发开放平台' }, { name: '服务商管理' }];

interface SearchParams extends Omit<ServiceListRequest, ' offset' | 'count' | 'status'> {}

const ServiceManagement: React.FC = () => {
  const [form] = Form.useForm();
  const pagingInfo = useSearchParams<SearchParams>(50);
  const { searchParams, setTotalCount, finish, reset, setPageOffset } = pagingInfo;
  const [loading, setLoading] = useState<boolean>(false);
  const [tabsKey, setTabsKey] = useState<string>('1');
  const [cardList, setCardList] = useState<ServiceListRes[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getServicePage({
      name: searchParams.name,
      companyName: searchParams.companyName,
      count: searchParams.pageSize,
      offset: searchParams.pageOffset,
      status: Number(tabsKey),
    })
      .then(res => {
        setTotalCount(res.total);
        setCardList(res.records);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [tabsKey, searchParams, setTotalCount]);

  const onFinish = (v: SearchParams) => {
    finish({ ...v });
  };
  const onReset = () => {
    reset();
  };

  const filters = (
    <Filter
      form={form}
      onFinish={(v: SearchParams) => {
        onFinish(v);
      }}
      onReset={onReset}
    >
      <Col span={6}>
        <Form.Item name="name" label="服务商名称">
          <Input placeholder="请输入服务商名称" />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name="companyName" label="公司名称">
          <Input placeholder="请输入公司名称" />
        </Form.Item>
      </Col>
    </Filter>
  );

  const Cards = (
    <>
      {cardList && cardList.length > 0 ? (
        <Spin spinning={loading}>
          <Row gutter={23}>
            {cardList.map(item => {
              return (
                <Col key={item.id} sm={12} lg={8} md={12}>
                  <ServiceCard data={item} />
                </Col>
              );
            })}
          </Row>
          <Paging pagingInfo={pagingInfo} />
        </Spin>
      ) : (
        <div>
          <Empty />
        </div>
      )}
    </>
  );

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `启用`,
      children: Cards,
    },
    {
      key: '0',
      label: `禁用`,
      children: Cards,
    },
  ];

  return (
    <Wrapper routes={routes} filters={filters} className="open-platform-common-wrapper">
      <Button type="primary" onClick={() => navigate('/open-platform/open-platform/service-management/create')}>
        <PlusOutlined /> 创建服务商
      </Button>
      <Tabs
        defaultActiveKey="1"
        items={items}
        onChange={v => {
          setTabsKey(v);
          setPageOffset(1);
        }}
        style={{
          paddingTop: 24,
        }}
      />
    </Wrapper>
  );
};

export default ServiceManagement;
