import { FC, useEffect, useState } from 'react';
import { Wrapper, FormContent } from '@maxtropy/components';
import styles from './index.module.scss';
import { Form, Row, Col, Space, Button, Tag } from 'antd';
import ShowInput from '@/shared/components/ShowInput';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { AlarmLogDetailResponse, getAlarmLogDetail } from '../../../api/alarm';
import { AlarmLevelColorDisplay, AlarmLevelDisplay } from '@/shared/types';
import dayjs from 'dayjs';

const dateFormat = 'YYYY/MM/DD HH:mm:ss';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const routes = [{ name: 'Iot配置' }, { name: '报警推送' }, { name: '设备报警记录' }, { name: '记录详情' }];

const AlarmRecordDetail: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [data, setData] = useState<AlarmLogDetailResponse>();

  useEffect(() => {
    if (id) {
      getAlarmLogDetail(id).then(res => {
        setData(res);
      });
    }
  }, [id]);

  return (
    <Wrapper routes={routes} className={styles.wrapper}>
      <Form layout="vertical" {...formLayout}>
        <FormContent>
          <Row>
            <Col span={8} className={styles.col}>
              <Form.Item label="所属租户">
                <ShowInput value={data?.tenantName} />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item label="设备编号">
                <Space>
                  <ShowInput value={data?.deviceCode} />
                  <Button type="link">
                    <Link to={`/device/manage/device/${data?.deviceId}/detail`}>查看</Link>
                  </Button>
                </Space>
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item label="设备名称">
                <ShowInput value={data?.deviceName} />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item label="报警等级">
                <ShowInput
                  value={
                    <Tag color={AlarmLevelColorDisplay[data?.alarmLevel!]}>{AlarmLevelDisplay[data?.alarmLevel!]}</Tag>
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item label="报警信息">
                <ShowInput value={data?.alarmName} />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item label="报警规则">
                <Space>
                  <ShowInput value={data?.ruleName} />
                  <Button type="link">
                    <Link to={`/device/rule/list/detail/${data?.ruleId}`}>查看</Link>
                  </Button>
                </Space>
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item label="规则编号">
                <ShowInput value={data?.ruleCode} />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item label="报警时间">
                <ShowInput value={dayjs(data?.alarmTime).format(dateFormat)} />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item label="恢复时间">
                <ShowInput value={data?.recoveryTime ? dayjs(data?.recoveryTime).format(dateFormat) : '未恢复'} />
              </Form.Item>
            </Col>
            {/* <Col span={8} className={styles.col}>
              <Form.Item
                name="readTime"
                label="已读时间"
              >
                <ShowInput />
              </Form.Item>
            </Col> */}
          </Row>
        </FormContent>
        <Space className="sticky-footer">
          <Button
            className={styles.button}
            onClick={() => {
              navigate(`/device/alarm/record`);
            }}
          >
            返回
          </Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default AlarmRecordDetail;
