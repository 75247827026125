import React, { useState } from 'react';
import { MiniAppAddOrUpdatePartTwoPostRequest, MiniAppPartOneTwoDetailPostResponse } from '@maxtropy/cc-mgmt-apis';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Input, message, Upload } from 'antd';
import type { UploadChangeParam } from 'antd/es/upload';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { getRealUrl } from '@maxtropy/components';
import { useDebounceFn } from 'ahooks';

import styles from './index.module.scss';
import defaultBG from '../../Images/cover@2x.png';
import defaultLogo from '../../Images/JSlogo@2x.png';

const LOGO: ImageName = 'logo';
const BG: ImageName = 'bg';
type ImageName = 'logo' | 'bg';

export const DEFAULT_TEXT = '欢迎进入\r\n熵博士AI能源管理助手';

const { TextArea } = Input;

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const imageConstraints = {
  logo: { formats: ['image/jpeg', 'image/png'], maxSize: 500 * 1024, dimensions: [120, 120] },
  bg: { formats: ['image/jpeg', 'image/png'], maxSize: 500 * 1024, dimensions: [750, 1624] },
};

interface Props {
  partOneTwoData?: MiniAppPartOneTwoDetailPostResponse;
  appId?: number;
  partTwoParams: MiniAppAddOrUpdatePartTwoPostRequest | undefined;
  setPartTwoParams: React.Dispatch<React.SetStateAction<MiniAppAddOrUpdatePartTwoPostRequest | undefined>>;
}
const Entrance: React.FC<Props> = ({ setPartTwoParams, partTwoParams }) => {
  const [logoLoading, setLogoLoading] = useState(false);
  const [bgLoading, setBgLoading] = useState(false);

  const { run: warnUser } = useDebounceFn(
    customMessage => {
      message.warning(customMessage);
    },
    {
      wait: 300,
    }
  );

  const checkImageDimensions = (file: File, maxWidth: number, maxHeight: number): Promise<boolean> => {
    return new Promise(resolve => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        URL.revokeObjectURL(img.src);
        if (width <= maxWidth && height <= maxHeight) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
    });
  };

  const beforeUpload = async (file: File, name: string) => {
    const constraints = imageConstraints[name as ImageName];

    if (!constraints) {
      // 未知名称，无法验证
      return true;
    }

    const { formats, maxSize, dimensions } = constraints;

    if (!formats.includes(file.type)) {
      warnUser('图片格式不正确!');
      return false;
    }

    if (file.size > maxSize) {
      warnUser(`图片大小超过${maxSize / 1024}KB限制!`);
      return false;
    }

    if (name === LOGO || name === BG) {
      const isCorrectSize = await checkImageDimensions(file, dimensions[0], dimensions[1]);

      if (!isCorrectSize) {
        warnUser(`图片尺寸不符合要求，建议尺寸为${dimensions[0]}*${dimensions[1]}!`);
        return false;
      }
    }

    return true;
  };

  const handleUploadChange = (
    file: UploadFile,
    setLoading: (loading: boolean) => void,
    setParamsKey: (key: ImageName) => void
  ) => {
    if (file.status === 'uploading') {
      setLoading(true);
      // return;
    }
    if (file.status === 'done') {
      getBase64(file.originFileObj as RcFile, _url => {
        setLoading(false);
      });
      const response = file.response;
      if (response && response.key) {
        setParamsKey(response.key);
      }
    }
  };

  const handleLogoChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    handleUploadChange(info.file, setLogoLoading, key => setPartTwoParams(prev => ({ ...prev, icon: key })));
  };

  const handleBgChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    handleUploadChange(info.file, setBgLoading, key => setPartTwoParams(prev => ({ ...prev, bg: key })));
  };

  const onTextAreaChange = (val: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const welcomeText = val.currentTarget.value;

    if (welcomeText.length >= 30) {
      warnUser('欢迎词须在30个字符之内');
    }

    setPartTwoParams(prev => ({
      ...prev,
      welcomeText,
    }));
  };

  const logoUploadButton = (
    <div>
      <div>
        {logoLoading ? (
          <span className={styles.textStyle}>上传中</span>
        ) : (
          <span className={styles.textStyle}>更换</span>
        )}
      </div>
    </div>
  );

  const bgUploadButton = (
    <div>
      <div>{bgLoading ? <LoadingOutlined /> : <PlusOutlined />}</div>
    </div>
  );

  return (
    <>
      <div
        className={styles.entrance}
        style={{ backgroundImage: `url(${getRealUrl(partTwoParams?.bg) ?? defaultBG})` }}
      >
        <div
          className={styles.logo}
          style={{ backgroundImage: `url(${getRealUrl(partTwoParams?.icon) ?? defaultLogo})` }}
        >
          <div className={styles.cover}>
            <div className={styles.dashed}></div>
            <Upload
              method="POST"
              accept=".jpg,.png"
              listType="picture-card"
              showUploadList={false}
              action="/api/file-center/upload"
              beforeUpload={file => beforeUpload(file, LOGO)}
              onChange={handleLogoChange}
            >
              {logoUploadButton}
            </Upload>
          </div>
        </div>

        <div className={styles.textArea}>
          <TextArea
            defaultValue={partTwoParams?.welcomeText ?? DEFAULT_TEXT}
            className={styles.text}
            onChange={onTextAreaChange}
            maxLength={30}
          />
        </div>

        <div className={styles.bg}>
          <div className={styles.bgUpload}>
            <Upload
              method="POST"
              accept=".jpg,.png"
              listType="picture-card"
              showUploadList={false}
              action="/api/file-center/upload"
              beforeUpload={file => beforeUpload(file, BG)}
              onChange={handleBgChange}
            >
              {bgUploadButton}
            </Upload>
          </div>
        </div>
        <div className={styles.bgChange}>更换背景图</div>
      </div>
    </>
  );
};

export default Entrance;
