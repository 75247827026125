import { FC, useState, useMemo, useEffect, useRef } from 'react';
import { Button, Form, Row, Col, Divider, Space, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { TabsBaseProps } from '../util';
import {
  TenantBaseInfoResponse,
  getTenantFuctionPermission,
  updateTenantFuctionPermission,
} from '../../../api/cc-tenant';
import {
  getAppPermissionTree,
  AppFunctionPermissionVo,
  PermissionLabelResponse,
  getAppPermissionLabel,
} from '../../../api/cc-app';
import { RoleTree } from '@maxtropy/components';
import { dig } from '../../RolePermissions/Edit/EditPermissions';

import styles from './index.module.scss';
import { getRolePermissions, Key } from '@maxtropy/components';

interface TenantFuncPermissionsProps extends TabsBaseProps {
  tenant?: TenantBaseInfoResponse;
  disabled?: boolean;
}

const formLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 24 },
};

const { useForm } = Form;

const TenantFuncPermissions: FC<TenantFuncPermissionsProps> = props => {
  const [modalApi, modalContextHolder] = Modal.useModal();
  const { disabled, onBreacrumbChange, tenant } = props;
  const [appFunctionPermissions, setAppFunctionPermissions] = useState<AppFunctionPermissionVo[]>([]);
  const [tenantFuctionPermissions, setTenantFuctionPermissions] = useState<Key[]>([]);
  const [account, setAccount] = useState<boolean>(false);

  const originalTenantFuctionPermissions = useRef<Key[]>([]);

  const [permissionLabels, setPermissionLabels] = useState<PermissionLabelResponse[]>([]);

  useEffect(() => {
    if (tenant) {
      if (tenant.accountOpenStatus) {
        setAccount(true);
      }
    }
  }, [tenant]);

  const navigate = useNavigate();
  const [form] = useForm();

  const routes = useMemo(() => {
    return [
      { name: '租户管理' },
      { name: '租户组织权限和账户' },
      { name: `${disabled ? '查看' : '编辑'}租户` },
      { name: '租户功能权限' },
    ];
  }, [disabled]);

  useEffect(() => {
    onBreacrumbChange && onBreacrumbChange(routes);
  }, [onBreacrumbChange, routes]);

  useEffect(() => {
    getAppPermissionTree().then(setAppFunctionPermissions);
  }, []);

  useEffect(() => {
    getAppPermissionLabel().then(setPermissionLabels);
  }, []);

  useEffect(() => {
    if (tenant) {
      getTenantFuctionPermission(tenant?.mcid).then(res => {
        setTenantFuctionPermissions(res);
        originalTenantFuctionPermissions.current = res;
      });
    }
  }, [tenant]);

  const treeData = useMemo(() => {
    return dig(appFunctionPermissions ?? []);
  }, [appFunctionPermissions]);

  useEffect(() => {
    form.setFieldsValue({
      codes: tenantFuctionPermissions,
    });
  }, [tenantFuctionPermissions, form]);

  const onFinish = (value: any) => {
    const { permissions } = getRolePermissions({
      treeData,
      checkedKeys: value.codes ?? [],
      originalCheckedKeys: originalTenantFuctionPermissions.current,
    });
    updateTenantFuctionPermission({
      mcid: tenant?.mcid!,
      addCodes: permissions[0],
      deleteCodes: permissions[1],
    }).then(() => {
      navigate('/tenant/customer/manage');
    });
  };

  const onCacel = () => {
    modalApi.confirm({
      title: null,
      icon: null,
      content: <div>是否放弃所有未保存信息并返回列表？</div>,
      onOk: () => {
        navigate('/tenant/customer/manage');
      },
    });
  };

  const addPermissionsLabel = (codes: string[]) => {
    const checkKeys: string[] = form.getFieldValue('codes');
    form.setFieldsValue({
      codes: Array.from(new Set([...checkKeys, ...codes])),
    });
  };

  return (
    <div className={styles.container}>
      {permissionLabels.length ? (
        <Row>
          <Col className={styles.label} span={24}>
            <span>快捷开户标签：</span>
            {permissionLabels.map((i, index) => (
              <Button
                key={index}
                onClick={() => {
                  addPermissionsLabel(i.codes);
                }}
                disabled={!account || disabled}
              >
                {i.name}
              </Button>
            ))}
          </Col>
        </Row>
      ) : null}
      <Form form={form} layout="vertical" {...formLayout} onFinish={onFinish}>
        <Row>
          <Col span={20} className={styles.functionFormLabelContainer}>
            <Form.Item
              name="codes"
              label="功能权限管理"
              validateFirst={true}
              valuePropName="checkedKeys"
              trigger="onCheck"
            >
              <RoleTree treeData={treeData} disabled={!account || disabled} className={styles.tree} showDetail />
            </Form.Item>
          </Col>
        </Row>
        {!(!account || disabled) && (
          <Row className={styles.buttonWrapper}>
            <Divider />
            <Col span={7}>
              <Form.Item>
                <Space>
                  <Button htmlType="submit" type="primary">
                    保存
                  </Button>
                  <Button onClick={onCacel}>关闭</Button>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
      {modalContextHolder}
    </div>
  );
};

export default TenantFuncPermissions;
