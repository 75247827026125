import { FC, useEffect, useState } from 'react';
import { Button, Tabs, Form, Col, Select, Input, Space, Upload, Modal, Spin, Cascader } from 'antd';
import { useUpdate, Wrapper, useAsync } from '@maxtropy/components';
import styles from './index.module.scss';

import Filter from '@/shared/components/Filter';
import {
  formatOptionData,
  getAllChild,
  getChildNodesByParentIds,
} from '@/shared/components/CascadingMultipleSelector/utils';

import { getDeviceTypeData } from '../../../api/attribute';
import { getRoot } from '../../../api/device';
import { Link, useNavigate } from 'react-router-dom';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import DeviceRecord from './Record';
import DeviceTemplate from './Template';
import qs from 'qs';
import { SearchParams } from './util';
import { useHasPermission } from '../../../utils/utils';
import { PermissionsType } from '../../../common/permissionsConst';
import { UploadChangeParam, UploadFile } from 'antd/es/upload/interface';
import { extname } from '@/shared/utils/utils';
import { DefaultOptionType } from 'antd/es/cascader';

const routes = [{ name: 'Iot配置' }, { name: '设备管理' }, { name: '批量导入设备' }];

interface FilterParams {
  code: string | undefined;
  typeId: Array<Array<number>> | undefined;
  rootMcid?: string;
}

const fileSize = 10 * 1024 * 1024;

const List: FC = () => {
  const [form] = Form.useForm();
  const hasImportBtn = useHasPermission(PermissionsType.BDEVICEIMPORTBUTTON);
  const [updateState, update] = useUpdate();
  const [uploadState, setUploadState] = useState<boolean>(false);

  const deviceTypeData = useAsync(getDeviceTypeData);
  const tenant = useAsync(getRoot, []);
  const navigate = useNavigate();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const code = urlSearchParams.get('code') || undefined;
  const typeId = urlSearchParams
    .get('typeId')
    ?.split(',')
    .map(s => Number(s));
  const typeIdDisplay = urlSearchParams
    .get('typeIdDisplay')
    ?.split(',')
    .map(s => s.split('|').map(Number));
  const rootMcid = urlSearchParams.get('rootMcid') || undefined;
  const tabs = urlSearchParams.get('tabs') || '1';

  const [searchParams, setSearchParams] = useState<SearchParams>({ code, typeId, rootMcid });
  const [tabsKey, setTabsKey] = useState<string>(tabs);
  const [modalApi, modalContextHolder] = Modal.useModal();

  useEffect(() => {
    form.setFieldsValue({ code, rootMcid, typeId: typeIdDisplay });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (val: FilterParams) => {
    // const typeId = getAllChild(val.typeId, formatOptionData(deviceTypeData));
    const typeId = getChildNodesByParentIds(val.typeId, deviceTypeData?.tree);
    const params: SearchParams = {
      code: val.code,
      rootMcid: val.rootMcid,
      typeId: typeId,
    };
    setSearchParams(params);
    const paramsObj = {
      name: val.code,
      rootMcid: val.rootMcid,
      typeId: typeId?.join(','),
      typeIdDisplay: val.typeId?.map(item => item.join('|')).join(','),
      tabs: tabs,
    };
    const paramsMap = new Map<string, string>();
    Object.entries(paramsObj).forEach(([key, value]) => {
      value && paramsMap.set(key, value);
    });
    navigate(`?${qs.stringify(Object.fromEntries(paramsMap))}`);
  };

  const onReset = () => {
    const params: SearchParams = {
      code: undefined,
      typeId: undefined,
      rootMcid: undefined,
    };
    setSearchParams(params);
    navigate(`?`);
  };

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const filters = (
    <Filter<FilterParams> form={form} onFinish={val => onFinish(val as FilterParams)} onReset={onReset}>
      <>
        <Col span={4}>
          <Form.Item name="code" label="流水号/编号">
            <Input placeholder="请输入编号查询" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="typeId" label="所属类目">
            <Cascader
              options={formatOptionData(deviceTypeData)}
              allowClear={false}
              fieldNames={{ children: 'child' }}
              multiple
              maxTagCount="responsive"
              showSearch={{ filter }}
              placeholder={'请选择所属类目'}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="rootMcid" label="所属租户">
            <Select style={{ width: '100%' }} placeholder="请选择">
              {tenant.map(i => (
                <Select.Option key={i.mcid} value={i.mcid}>
                  {i.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </>
    </Filter>
  );

  const onChangeTabs = (v: string) => {
    setTabsKey(v);
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.delete('tabs');
    urlSearchParams.append('tabs', v);
    navigate(`?${urlSearchParams.toString()}`);
  };

  return (
    <Spin spinning={uploadState}>
      <Wrapper routes={routes} filters={filters}>
        <div className={styles.operationArea}>
          <Space>
            {hasImportBtn && (
              <Upload
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                action="/api/batch/import/import"
                onChange={(uploadFile: UploadChangeParam<UploadFile>) => {
                  const { file } = uploadFile;
                  if (file.status === 'uploading') {
                    setUploadState(true);
                  }
                  if (file.status === 'error') {
                    setUploadState(false);
                    modalApi.warning({
                      content: file.response.errorMessage || '未知错误！ 请联系管理员。',
                    });
                  }
                  if (file.status === 'done') {
                    setUploadState(false);
                    update();
                  }
                }}
                beforeUpload={file => {
                  const { name } = file;
                  const extName = extname(name);
                  const limitFileSize = file.size <= fileSize;
                  const limitFileType = extName === '.xlsx';
                  if (!limitFileSize) {
                    modalApi.warning({
                      content: `上传文件的大小不得超过10M`,
                    });
                  }
                  if (!limitFileType) {
                    modalApi.warning({
                      content: `仅支持上传excel文件`,
                    });
                  }
                  return limitFileType && limitFileSize;
                }}
                showUploadList={false}
              >
                <Button type="primary">
                  <UploadOutlined />
                  导入
                </Button>
              </Upload>
            )}
            <Button type="primary">
              <Link to={`/device/manage/batch/create?tabs=${tabsKey}`}>
                <PlusOutlined />
                新建导入模板
              </Link>
            </Button>
          </Space>
        </div>
        <Tabs
          defaultActiveKey={tabs}
          tabBarStyle={{
            marginBottom: 3,
          }}
          tabBarGutter={43}
          className={styles.tabs}
          onChange={v => onChangeTabs(v)}
        >
          <Tabs.TabPane tab="导入流水" key="1">
            <DeviceRecord key={updateState} queryData={searchParams} tabsKey={tabsKey} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="导入模板" key="2">
            <DeviceTemplate key={updateState} queryData={searchParams} tabsKey={tabsKey} />
          </Tabs.TabPane>
        </Tabs>
      </Wrapper>
      {modalContextHolder}
    </Spin>
  );
};

export default List;
