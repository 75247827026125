import { useEffect, useMemo, useState } from 'react';
import { SubjectHolderMap } from './subjectHolder';

const useIsGateway = (deviceTypeId: number | number[] | undefined) => {
  const instance = SubjectHolderMap.getInstance();
  const queryGatewayChildren = instance.getSubjectHolder('queryGatewayChildren');

  const [loading, setLoading] = useState(true);
  const [gatewayChildren, setGatewayChildren] = useState<number[] | undefined>();
  const [isGateway, setIsGateway] = useState(false);

  useEffect(() => {
    const subscription = queryGatewayChildren.subscribe(data => {
      setGatewayChildren(data);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (deviceTypeId) {
      queryGatewayChildren.debounceRefresh();
    }
  }, [deviceTypeId]);

  useEffect(() => {
    if (!deviceTypeId || !gatewayChildren) {
      setLoading(true);
      return;
    }
    if (Array.isArray(deviceTypeId)) {
      setIsGateway(gatewayChildren.includes(deviceTypeId.slice(-1)[0]));
    } else {
      setIsGateway(gatewayChildren.includes(deviceTypeId));
    }
    setLoading(false);
  }, [deviceTypeId, gatewayChildren]);

  return {
    loading,
    isGateway,
    refresh: queryGatewayChildren.refresh,
  } as const;
};

export default useIsGateway;
