import { PlusOutlined } from '@ant-design/icons';
// import { Table } from '@maxtropy/components';
import { Button, Space, Modal, Table } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { AlarmType, IotProtocolType, AlarmChannel } from '@/shared/types';
import { columns } from '../util';
import styles from './index.module.scss';
import AlarmRulesModal from './AlarmRulesModal';
import { RulePageList } from '@/pages/Alarm/List/types';

export interface SelectAlarmRulesProps {
  value?: RulePageList[];
  onChange?: (value: RulePageList[]) => void;
  iotProtocolType?: IotProtocolType;
  mcid?: string;
  deviceTypeId?: number;
  type: AlarmType;
  loading?: boolean;
  alarmChannel: AlarmChannel;
  physicalModelId?: number; // 物理模型id
}

const SelectAlarmRules: FC<SelectAlarmRulesProps> = ({
  value,
  onChange,
  iotProtocolType,
  mcid,
  type,
  loading = false,
  deviceTypeId,
  alarmChannel,
  physicalModelId,
}) => {
  const [currentPages, setCurrentPages] = useState<number>(1);
  const [pageSize, setPagesize] = useState<number>(20);

  const [visible, setVisible] = useState<boolean>(false);

  const [dataSource, setDataSource] = useState<RulePageList[]>([]);
  const [modalApi, modalContextHolder] = Modal.useModal();

  const disabledIds = useMemo<number[]>(() => {
    return (value ?? []).filter(i => i !== undefined).map(i => i.id as number);
  }, [value]);

  useEffect(() => {
    setDataSource(value ?? []);
  }, [value]);

  const onAdd = () => {
    if (loading) {
      return;
    }
    if (mcid === undefined) {
      modalApi.warning({
        title: '请先选择所属租户',
      });
      return;
    }
    if (alarmChannel === AlarmChannel.MAXTROPY) {
      if (iotProtocolType === undefined) {
        modalApi.warning({
          title: '请先选择物联层协议',
        });
        return;
      }
      if (iotProtocolType === IotProtocolType.MOCKINGBIRD && deviceTypeId === undefined) {
        modalApi.warning({
          title: '请先选择适用设备类目',
        });
        return;
      }
    } else if (alarmChannel === AlarmChannel.LONGSHINE && deviceTypeId === undefined) {
      modalApi.warning({
        title: '请先选择适用设备类目',
      });
      return;
    }
    setVisible(true);
  };

  const unBind = (record: RulePageList) => {
    modalApi.confirm({
      title: '取消绑定？',
      okText: '继续',
      onOk() {
        const newValue = (value ?? []).filter(i => i.id !== record.id);
        onChange?.(newValue);
      },
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 250,
      fixed: 'right' as const,
      render: (value: undefined, record: RulePageList) => {
        return (
          <Button
            style={{ paddingLeft: 0, paddingRight: 0 }}
            type="link"
            onClick={() => {
              unBind(record);
            }}
          >
            取消绑定
          </Button>
        );
      },
    },
  ];

  const onCancel = () => {
    setDataSource(value ?? []);
    setVisible(false);
  };

  const onOk = () => {
    onChange?.(dataSource);
    const page = Math.ceil((dataSource ?? []).length / pageSize);
    setCurrentPages(page);
    setVisible(false);
  };

  const pagination = useMemo(() => {
    return {
      pageSize: pageSize,
      hideOnSinglePage: true,
      current: currentPages,
      onChange(page: number, size: number) {
        if (size === pageSize) {
          setCurrentPages(page);
        }
      },
      onShowSizeChange(current: number, size: number) {
        setPagesize(size);
        setCurrentPages(1);
      },
    };
  }, [currentPages, pageSize]);

  return (
    <>
      <Space direction="vertical">
        <Button type="dashed" onClick={onAdd}>
          <PlusOutlined />
          添加
        </Button>
        <div />
      </Space>
      <Table
        loading={loading}
        tableLayout={'fixed'}
        scroll={{ x: 1300, y: 400 }}
        pagination={pagination}
        columns={buildColumns}
        rowKey="id"
        dataSource={value ?? []}
      />
      <Modal
        className={styles.modal}
        title="报警规则选择"
        destroyOnClose
        open={visible}
        onOk={onOk}
        onCancel={onCancel}
        width={900}
      >
        <AlarmRulesModal
          iotProtocolType={iotProtocolType}
          mcid={mcid}
          deviceTypeId={deviceTypeId}
          type={type}
          onChange={setDataSource}
          value={dataSource}
          disabledIds={disabledIds}
          alarmChannel={alarmChannel}
          physicalModelId={physicalModelId}
        />
      </Modal>
      {modalContextHolder}
    </>
  );
};

export default SelectAlarmRules;
