import React, { Key, useCallback, useImperativeHandle, useState } from 'react';
import { EllipsisSpan, Paging, Table, usePaging } from '@maxtropy/components';
import Filter from '../../Filter';
import { Space, Form, Input, Row } from 'antd';
import { useQuery } from '../../../utils/utils';
import { Link } from 'react-router-dom';
import { DevicePageRequest, DevicePageResponse, getDevicePage } from '../../../api/pages';
import { DeviceSelectOtions } from './index';

type SearchParams = Omit<DevicePageRequest, 'page' | 'size'>;

type FilterParams = Omit<SearchParams, 'deviceType'>;

export interface DeviceModalRef {
  selectedRowKeys: Array<Key> | undefined;
}

interface DeviceModalProps {
  deviceIds: Array<number> | undefined;
  options?: DeviceSelectOtions;
}

export const columns = [
  {
    title: '设备编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备标签',
    dataIndex: 'tags',
    ellipsis: { showTitle: true },
    render: (v: string[]) => {
      const tags = v ? v.join(', ') : '--';
      return <EllipsisSpan value={tags} />;
    },
  },
  {
    title: '所属类目',
    dataIndex: 'typeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属组织',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const DeviceModal: React.ForwardRefRenderFunction<DeviceModalRef, DeviceModalProps> = ({ deviceIds, options }, ref) => {
  const pagingInfo = usePaging(50);
  const [form] = Form.useForm();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [selectedRowKeys, setSelectedRowKeys] = useState<Array<Key> | undefined>(deviceIds);
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const { isMgmt, deviceType, rootMcid, iotProtocol, driveType, objectModalType } = options ?? {};

  const onReset = () => {
    form.resetFields();
    form.submit();
    setPageOffset(1);
  };

  const onFinish = (val: FilterParams) => {
    setSearchParams({ ...val, deviceTypeId: deviceType?.slice(-1) });
    setPageOffset(1);
  };

  const onSelectChange = (rowKeys: Key[]) => {
    setSelectedRowKeys(rowKeys);
  };

  useImperativeHandle(ref, () => ({
    selectedRowKeys,
  }));

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        getDevicePage({
          ...searchParams,
          deviceTypeId: deviceType?.slice(-1),
          tenantMcid: isMgmt ? rootMcid : undefined,
          iotProtocol,
          driveType,
          physicalModelId: objectModalType,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) {
            setTotalCount(res.total);
            return res.list;
          }
        }),
      [pageOffset, pageSize, searchParams, setTotalCount]
    )
  );

  return (
    <div style={{ minHeight: 585 }}>
      <Filter<FilterParams> form={form} onFinish={val => onFinish(val as FilterParams)} onReset={onReset}>
        <Space>
          <Form.Item name="codeOrName" label="编号/名称">
            <Input placeholder={'请输入编号或名称查询'} />
          </Form.Item>
          <Form.Item name="tag" label="标签">
            <Input placeholder="请输入" />
          </Form.Item>
        </Space>
      </Filter>
      <Row justify="space-between" style={{ marginBottom: 5 }}>
        <div>
          <span>已选择</span>
          <span style={{ color: '#d62500', padding: '0 5px', fontWeight: 500 }}>{selectedRowKeys?.length || 0}</span>
          <span>项</span>
        </div>
        <div>
          <span style={{ color: '#333' }}>点击前往</span>
          <Link to={'/device/manage/device/create'} style={{ padding: '0 5px', cursor: 'pointer' }}>
            添加设备
          </Link>
        </div>
      </Row>
      <Table
        scroll={{ y: 300 }}
        rowKey="id"
        loading={isLoading}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
      />
      <Paging pagingInfo={pagingInfo} />
    </div>
  );
};

export default React.forwardRef(DeviceModal);
