import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { Tree, Input, Tooltip, Empty, Spin } from 'antd';
import { DataNode } from 'antd/es/tree';
import classNames from 'classnames';
import { useQuery } from '../../../../utils/utils';
import { DeviceSceneTreeProps, DeviceTypes, getDeviceSceneTree } from '../../../../api/history';
import styles from './index.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';

const { Search } = Input;

export interface DeviceTreeProps {
  className?: string;
  tenantMcid?: string;
  companyId?: number;
  selectDevice: (selectedKeys: string) => void;
}

const formatTreeData = (data: DeviceSceneTreeProps[]) => {
  const format = (key: string, treeData: DeviceTypes[]) => {
    return treeData.map(item => {
      if (item.devices && item.devices.length) {
        return {
          key: `${key}-product-${item.id}`,
          title: item.name,
          selectable: false,
          children: item.devices.map(item => ({
            key: `${key}-device-${item.id}`,
            title: item.name,
          })),
        };
      } else {
        return {
          key: `${key}-product-${item.id}`,
          title: item.name,
          selectable: false,
        };
      }
    });
  };
  return data.map(item => ({
    key: `industry-${item.id}`,
    title: item.name,
    selectable: false,
    children: item.deviceTypeList.map(i => ({
      key: `industry-${item.id}-brand-${i.id}`,
      title: i.name,
      selectable: false,
      children: format(`industry-${item.id}-brand-${i.id}`, i.children),
    })),
  }));
};

const getParentIdsByNodeId = (data: DataNode[], nodeId: string, path: string[] = []): string[] => {
  for (const node of data) {
    const id = node.key.toString().split('-').at(-1);
    path.push(node.key.toString());
    if (id === nodeId) return path;
    if (node.children) {
      const findChildren = getParentIdsByNodeId(node.children, nodeId, path);
      if (findChildren?.length) return findChildren;
    }
    path.pop();
  }
  return [];
};

const DeviceTree: React.FC<DeviceTreeProps> = props => {
  const { tenantMcid, companyId, selectDevice, className } = props;
  const { search } = useLocation();
  const navigate = useNavigate();
  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);

  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [keyValue, setKeyValue] = useState<string | undefined>();
  const initialSetExapndKeysFromUrl = useRef(false);

  // treeData
  const { data, isLoading } = useQuery(
    useCallback(async () => {
      if (tenantMcid && companyId) {
        const response = await getDeviceSceneTree({
          tenantMcid,
          deviceName: keyValue,
        });
        const data = formatTreeData(response);
        // setExpandedKeys([]);
        // 搜索时展开树
        if (keyValue) {
          const ids: string[] = [];
          const loop = (data: DataNode[]) => {
            data.forEach(item => {
              ids.push(item.key.toString());
              if (item.children && item.children.length) {
                loop(item.children);
              }
            });
          };
          loop(data);
          setExpandedKeys(ids);
        }
        return data;
      }
    }, [tenantMcid, companyId, keyValue])
  );

  const parentIdsFromUrl = useMemo(() => {
    if (Array.isArray(data) && data.length) {
      if (urlSearchParams.has('deviceId')) {
        return getParentIdsByNodeId(data, urlSearchParams.get('deviceId') as string);
      }
    }
  }, [data, urlSearchParams]);

  const titleRender = (node: DataNode) => {
    return (node.title as string).length < 6 ? (
      (node.title as string)
    ) : (
      <Tooltip placement="right" title={node.title as string}>
        <div className={styles.txt}>{node.title as string}</div>
      </Tooltip>
    );
  };

  const onSelect = (selectedKeys: React.Key[]) => {
    const key = String(selectedKeys[0]).split('-').pop();
    if (key) {
      selectDevice(key);
    }
  };

  useEffect(() => {
    if (Array.isArray(parentIdsFromUrl) && parentIdsFromUrl.length) {
      if (initialSetExapndKeysFromUrl.current === false) {
        setExpandedKeys(parentIdsFromUrl);
        initialSetExapndKeysFromUrl.current = true;
      }
    }
  }, [parentIdsFromUrl]);

  return (
    <div className={classNames(className, styles.treeArea)}>
      <div className={styles.searchArea}>
        <Search
          placeholder="请输入设备名称"
          allowClear
          onSearch={value => {
            urlSearchParams.delete('deviceId');
            setKeyValue(value);
            navigate(`?${urlSearchParams.toString()}`);
          }}
        />
      </div>
      <Spin spinning={isLoading}>
        <div className={styles.treeBox}>
          {data && data.length > 0 ? (
            <Tree
              autoExpandParent={autoExpandParent}
              titleRender={titleRender}
              treeData={data}
              blockNode
              expandedKeys={expandedKeys}
              defaultSelectedKeys={parentIdsFromUrl?.length ? [parentIdsFromUrl.at(-1) as string] : undefined}
              onExpand={keys => {
                setExpandedKeys(keys as string[]);
                setAutoExpandParent(false);
              }}
              onSelect={onSelect}
              // height={800}
              style={{ height: 'calc(100vh - 120px)' }}
            />
          ) : (
            <Empty style={{ marginTop: 30 }} />
          )}
        </div>
      </Spin>
    </div>
  );
};

export default DeviceTree;
