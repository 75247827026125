import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Input, Space, Form, Cascader, Select, Col } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import { Link } from 'react-router-dom';

import { Wrapper, Paging, Table, usePaging, EllipsisSpan, useAsync } from '@maxtropy/components';
import { useQuery } from '../../utils/utils';
import Filter from '@/shared/components/Filter';
import { useNavigate } from 'react-router-dom';
import qs from 'qs';
import {
  Attribute,
  AttributeRequest,
  AttributeResponse,
  getAttributeList,
  getDeviceTypeData,
} from '../../api/attribute';
import { formatOptionData, getChildNodesByParentIds } from '@/shared/components/CascadingMultipleSelector/utils';
import { DefaultOptionType } from 'antd/es/cascader';
import { getManufacturerList, ManufacturerItem } from '../../api/edgeTemplate';
import { getPhysicalModelList, PhysicalModelRes } from '../../api/device';

export interface FilterParams {
  name: string | undefined;
  deviceType: Array<Array<number>> | undefined;
  manufacturerId?: number;
  physicalModelId?: number;
}

type SearchParams = Omit<AttributeRequest, 'page' | 'size'>;

interface AttributeTableData extends Omit<Attribute, 'deviceTypeList'> {
  devices: string;
}

function formatListData(dataSource: AttributeResponse): Array<AttributeTableData> {
  return dataSource?.list.map(item => ({
    ...item,
    devices: item.deviceTypeList,
  }));
}

const routes = [{ name: 'Iot配置' }, { name: '设备信息模板库' }, { name: '属性管理' }];

const columns: ColumnsType<AttributeTableData> = [
  {
    title: '属性编号',
    dataIndex: 'serialNumber',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '属性名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    dataIndex: 'devices',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '厂商',
    dataIndex: 'manufacturerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '物模型型号',
    dataIndex: 'modelNo',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '使用模板数',
    dataIndex: 'templateCount',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v.toString()} />,
  },
];

const render = (record: AttributeTableData) => <Link to={`/device/info/attribute/detail/${record.id}`}>查看详情</Link>;

const AttributeList: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const deviceTypeData = useAsync(getDeviceTypeData);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const name = urlSearchParams.get('name') || undefined;
  const deviceTypeFromUrl = urlSearchParams
    .get('deviceType')
    ?.split(',')
    .map(s => Number(s));
  const deviceTypeDisplay = urlSearchParams
    .get('deviceTypeDisplay')
    ?.split(',')
    .map(s => s.split('|').map(Number));
  const manufacturerIdFromUrl = urlSearchParams.get('manufacturerId')
    ? +urlSearchParams.get('manufacturerId')!
    : undefined;
  const physicalModelIdFromUrl = urlSearchParams.get('physicalModelId')
    ? +urlSearchParams.get('physicalModelId')!
    : undefined;

  const [searchParams, setSearchParams] = useState<SearchParams>({
    name,
    deviceType: deviceTypeFromUrl,
    manufacturerId: manufacturerIdFromUrl,
    physicalModelId: physicalModelIdFromUrl,
  });

  const { data, isLoading } = useQuery(
    useCallback(() => {
      return getAttributeList({
        ...searchParams,
        page: pageOffset,
        size: pageSize,
      }).then(res => {
        if (res) setTotalCount(res.total);
        return formatListData(res);
      });
    }, [pageOffset, pageSize, searchParams, setTotalCount])
  );

  useEffect(() => {
    form.setFieldsValue({
      name,
      deviceType: deviceTypeDisplay,
      manufacturerId: manufacturerIdFromUrl,
      physicalModelId: physicalModelIdFromUrl,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (val: FilterParams) => {
    const deviceType = getChildNodesByParentIds(val.deviceType, deviceTypeData?.tree);
    const params: SearchParams = {
      ...val,
      deviceType: deviceType,
    };
    setSearchParams(params);
    setPageOffset(1);
    const paramsObj = {
      ...val,
      deviceType: deviceType?.join(','),
      deviceTypeDisplay: val.deviceType?.map(item => item.join('|')).join(','),
    };
    const paramsMap = new Map<string, string | number>();
    Object.entries(paramsObj).forEach(([key, value]) => {
      value && paramsMap.set(key, value);
    });
    navigate(`?${qs.stringify(Object.fromEntries(paramsMap))}`);
  };

  const onReset = () => {
    setSearchParams({});
    setPageOffset(1);
    navigate(`?`);
  };
  const [manufacturerList, setManufacturerList] = useState<ManufacturerItem[]>([]); // 厂商列表

  useEffect(() => {
    getManufacturerList().then(setManufacturerList);
  }, []);
  const manufacturerOptions = useMemo(() => {
    if (manufacturerList && manufacturerList.length > 0) {
      return manufacturerList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [manufacturerList]);

  const [physicalModelList, setPhysicalModelList] = useState<PhysicalModelRes[]>([]); // all 物模型型号列表
  const manufacturerId = Form.useWatch('manufacturerId', form);
  const deviceType = Form.useWatch('deviceType', form);
  useEffect(() => {
    const deviceTypeIds = getChildNodesByParentIds(deviceType, deviceTypeData?.tree);
    const params = {
      deviceTypeIds,
      manufacturerId,
    };
    getPhysicalModelList(params).then(setPhysicalModelList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceType, manufacturerId]);
  const objectModalTypeOptions = useMemo(() => {
    if (physicalModelList && physicalModelList.length > 0) {
      return physicalModelList.map(i => ({ label: i.modelNo, value: i.id }));
    }
  }, [physicalModelList]);
  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
  const filters = (
    <Filter<FilterParams> form={form} onFinish={val => onFinish(val as FilterParams)} onReset={onReset}>
      <Col span={6}>
        <Form.Item name="name" label="编号/名称">
          <Input placeholder={'请输入编号或名称查询'} />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name="deviceType" label="所属类目">
          <Cascader
            options={formatOptionData(deviceTypeData)}
            allowClear={false}
            fieldNames={{ children: 'child' }}
            multiple
            maxTagCount="responsive"
            showSearch={{ filter }}
            placeholder={'请选择所属类目'}
            onChange={() => {
              form.setFieldsValue({
                physicalModelId: undefined,
              });
            }}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name="manufacturerId" label="厂商">
          <Select
            optionFilterProp="label"
            placeholder="请选择"
            showSearch
            options={manufacturerOptions}
            onChange={() => {
              form.setFieldsValue({
                physicalModelId: undefined,
              });
            }}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name="physicalModelId" label="物模型型号">
          <Select optionFilterProp="label" placeholder="请选择" showSearch options={objectModalTypeOptions} />
        </Form.Item>
      </Col>
    </Filter>
  );

  return (
    <Wrapper routes={routes} filters={filters}>
      <Table<AttributeTableData>
        sticky
        scroll={{ x: 1900 }}
        rowKey="id"
        dataSource={data}
        loading={isLoading}
        columns={[...columns, { title: '操作', fixed: 'right' as const, render }]}
      />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default AttributeList;
