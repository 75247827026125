import qs from 'qs';
import { fetch, Key } from '@maxtropy/components';

export interface AccountRequest {
  independentSoftwareVendorId?: Key;
  cellphone?: string;
  username?: string;
  status?: number;
  offset: number;
  count: number;
}
export interface AccountItem {
  id: number;
  independentSoftwareVendorName: string;
  independentSoftwareVendorStatus: number;
  roleType: number;
  username: string;
  password: string;
  status: number;
  cellphone: string;
  description: string;
  createTime: string;
  updateTime: string;
}
interface AccountResponse {
  total: number;
  current: number;
  size: number;
  records: AccountItem[];
}

export function queryDeveloperAccountList(params: AccountRequest): Promise<AccountResponse> {
  return fetch(`/api/developer-account/page?${qs.stringify(params, { indices: false })}`);
}

export interface CreateAccountProps {
  independentSoftwareVendorId: number;
  username: string;
  roleType: number;
  cellphone: string;
  description: string;
}

export function createDevelopAccount(params: CreateAccountProps) {
  return fetch(`/api/developer-account`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export function queryDeveloperAccount(developerAccountId: Key): Promise<AccountItem> {
  return fetch(`/api/developer-account/${developerAccountId}`);
}

export interface UpdateAccountProps {
  roleType: number;
  cellphone: string;
  description: string;
}

export function updateDeveloperAccount(developerAccountId: Key, body: UpdateAccountProps) {
  return fetch(`/api/developer-account/${developerAccountId}`, {
    method: 'PUT',
    body: JSON.stringify(body),
  });
}

export function deleteDeveloperAccount(developerAccountId: Key) {
  return fetch(`/api/developer-account/${developerAccountId}`, {
    method: 'DELETE',
  });
}

export function changeDeveloperStatus(developerAccountId: Key, query: { status: number }) {
  return fetch(`/api/developer-account/status/${developerAccountId}?${qs.stringify(query, { indices: false })}`, {
    method: 'PUT',
  });
}

export function resetAccountPwd(developerAccountId: Key) {
  return fetch(`/api/developer-account/reset-pwd/${developerAccountId}`, {
    method: 'PUT',
  });
}
