import { useState, useMemo } from 'react';
import {
  Modal,
  Row,
  Col,
  Form,
  Select,
  Radio,
  DatePicker,
  Input,
  Space,
  InputNumber,
  Button,
  Tooltip,
  Typography,
} from 'antd';
import { InfoCircleOutlined, CopyOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import { CopyToClipboard } from 'react-copy-to-clipboard';

type InterpolationModalProps = {
  open: boolean;
  onCancel: () => void;
};

type FormValues = {
  interpolationFn: string;
  // 单位为秒
  cycle: number | 'custom';
  customCycle: number;
  cycleStart: Dayjs;
  cumulantStart: number;
  cumulantRate: number;
};

type Knot = {
  dx: number;
  y: number;
};

// prettier-ignore
function getDxFromX(x: number, xMin: number, xMax: number) {// NOSONAR
  return (x - xMin) / (xMax - xMin);
}

// prettier-ignore
function getXFromDx(dx: number, xMin: number, xMax: number) {// NOSONAR
  return dx * (xMax - xMin) + xMin;
}

export default function InterpolationModal({ open, onCancel }: InterpolationModalProps) {
  const [modalApi, modalContextHolder] = Modal.useModal();
  const [form] = Form.useForm<FormValues>();
  const interpolationFunction = Form.useWatch('interpolationFn', form);
  const cycle = Form.useWatch('cycle', form);
  const customCycle = Form.useWatch('customCycle', form);
  const cycleStart = Form.useWatch('cycleStart', form);
  const cumulantStart = Form.useWatch('cumulantStart', form);
  const cumulantRate = Form.useWatch('cumulantRate', form);
  // 控制点
  const [knots] = useState<Knot[]>([
    {
      dx: 0.2,
      y: 0,
    },
    {
      dx: 0.5,
      y: 100,
    },
    {
      dx: 0.8,
      y: 20,
    },
  ]);
  const knotCoords = useMemo(
    // x0,y0,x1,y1...其中每个 x 为控制点的 dx 值
    () =>
      knots
        .map(knot => [knot.dx, knot.y])
        .flat()
        .join(','),
    [knots]
  );
  // 循环周期自定义秒数还是 1 天或者 7 天, 单位为秒
  const selectedCycle = useMemo(() => {
    return cycle === 'custom' ? customCycle : cycle;
  }, [customCycle, cycle]);
  const xMin = useMemo(() => {
    const difference = dayjs().endOf('day').valueOf() - cycleStart?.valueOf();
    const n = Math.ceil(difference / (selectedCycle * 1000));
    return cycleStart?.valueOf() + n * selectedCycle * 1000;
  }, [cycleStart, selectedCycle]);
  // prettier-ignore
  const xMax = useMemo(() => {// NOSONAR
    return xMin + selectedCycle * 1000;
  }, [selectedCycle, xMin]);

  // 起点 = 循环起点对应的 unix时间戳(毫秒), 以科学计数法表示
  const startPoint = useMemo(() => cycleStart?.valueOf()?.toExponential(), [cycleStart]);
  // 周期 = 循环周期对应的毫秒数, 以科学计数法表示
  const period = useMemo(() => (selectedCycle * 1000)?.toExponential(), [selectedCycle]);
  // 倍率 = 循环周期(秒) / 累积量倍率
  const rate = useMemo(() => (selectedCycle / cumulantRate)?.toExponential(), [cumulantRate, selectedCycle]);
  // 起始值 = 用户输入的起始值, 以科学计数法表示
  const startValue = cumulantStart?.toExponential();
  // 瞬时量公式
  const instantaneousQuantityFormula = useMemo(
    () => `${interpolationFunction}${knots.length}(${knotCoords});i((t-${startPoint})/${period})`,
    [interpolationFunction, knotCoords, knots.length, period, startPoint]
  );
  // 累积量公式
  const cumulativeQuantityFormula = useMemo(() => {
    return `${interpolationFunction}${knots.length}(${knotCoords});i((t-${startPoint})/${period})*${rate}+${startValue}`;
  }, [interpolationFunction, knotCoords, knots.length, period, rate, startPoint, startValue]);

  return (
    <>
      <Modal open={open} footer={null} onCancel={onCancel} title="生成插值函数" width={1200}>
        <Row gutter={16}>
          <Col span={6}>
            <Form
              initialValues={{
                interpolationFn: 'linear',
                cycle: 1 * 24 * 60 * 60,
                customCycle: 3600,
                cycleStart: dayjs('2015-08-20 00:00:00'),
                cumulantStart: 0,
                cumulantRate: 3600,
              }}
              onValuesChange={(changedValues, allValues) => {}}
              form={form}
              onFinish={values => {}}
              layout="horizontal"
            >
              <Form.Item
                label="插值函数"
                name="interpolationFn"
                extra={interpolationFunction ? `至少需要${interpolationFunction === 'akima' ? '3' : '2'}个控制点` : ''}
              >
                <Select
                  options={[
                    {
                      value: 'constseg',
                      label: '方波',
                    },
                    {
                      value: 'linear',
                      label: '线性',
                    },
                    {
                      value: 'akima',
                      label: 'Akima',
                    },
                    {
                      value: 'cubic',
                      label: '三次样条',
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item label="循环周期">
                <Space direction="vertical">
                  <Form.Item noStyle name="cycle">
                    <Radio.Group>
                      <Space direction="vertical">
                        <Radio value={1 * 24 * 60 * 60}>1天</Radio>
                        <Radio value={7 * 24 * 60 * 60}>7天</Radio>
                        <Radio value="custom">自定义秒数</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item noStyle name="customCycle">
                    <InputNumber min={0} step={100} placeholder="e.g. 3600" style={{ width: '100%' }} />
                  </Form.Item>
                </Space>
              </Form.Item>
              <Form.Item label="循环起点" name="cycleStart">
                <DatePicker showTime />
              </Form.Item>
              <Form.Item name="cumulantStart" label="累积量起始值" style={{ marginBottom: '12px' }}>
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item
                name="cumulantRate"
                label="累积量倍率"
                tooltip={{
                  title: (
                    <>
                      <div>瞬时量与累积量换算倍率, 单位为s</div>
                      <div>例如: </div>
                      <div>瞬时量为kW, 累积量为kWh, 则倍率为3600</div>
                      <div>瞬时量为m/s, 累积量为m, 则倍率为1</div>
                      <div>瞬时量为m/s, 累积量为km, 则倍率为0.001</div>
                    </>
                  ),
                  icon: <InfoCircleOutlined />,
                }}
              >
                <InputNumber min={0} style={{ width: '100%' }} />
              </Form.Item>
            </Form>
          </Col>
          <Col span={18}>图表</Col>
        </Row>
        <Row gutter={16} align="middle" style={{ marginBottom: '16px' }}>
          <Col span={2}>
            <Typography.Text strong>瞬时量公式:</Typography.Text>
          </Col>
          <Col span={19}>
            <Input
              style={{ color: '#00000040', backgroundColor: '#f5f5f5', borderColor: '#d9d9d9' }}
              value={instantaneousQuantityFormula}
              disabled
            />
          </Col>
          <Col span={1}>
            <Tooltip title="复制到剪贴板">
              <CopyToClipboard
                text={form.getFieldValue('interpolationFn')}
                onCopy={() => {
                  modalApi.success({
                    content: '瞬时量公式已复制到剪贴板',
                  });
                }}
              >
                <Button icon={<CopyOutlined />} />
              </CopyToClipboard>
            </Tooltip>
          </Col>
          <Col>
            <Button>填入</Button>
          </Col>
        </Row>
        <Row gutter={16} align="middle">
          <Col span={2}>
            <Typography.Text strong>累积量公式:</Typography.Text>
          </Col>
          <Col span={19}>
            <Input
              style={{ color: '#00000040', backgroundColor: '#f5f5f5', borderColor: '#d9d9d9' }}
              value={cumulativeQuantityFormula}
              disabled
            />
          </Col>
          <Col span={1}>
            <Tooltip title="复制到剪贴板">
              <CopyToClipboard
                text={form.getFieldValue('interpolationFn')}
                onCopy={() => {
                  modalApi.success({
                    content: '累积量公式已复制到剪贴板',
                  });
                }}
              >
                <Button icon={<CopyOutlined />} />
              </CopyToClipboard>
            </Tooltip>
          </Col>
          <Col>
            <Button>填入</Button>
          </Col>
        </Row>
      </Modal>
      {modalContextHolder}
    </>
  );
}
