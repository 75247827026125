import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Descriptions, Form, Input, message, Modal, Space } from 'antd';
import DescriptionsItem from 'antd/es/descriptions/Item';
import {
  Wrapper,
  Table,
  Paging,
  usePaging,
  EllipsisSpan,
  AttachmentUpload,
  SearchInput,
  SearchInputOptionProps,
} from '@maxtropy/components';
import styles from './index.module.scss';
import {
  CustomerStaffResponse,
  getCustomerStaff,
  getListCustomer,
  getTenantUserDetail,
} from '../../../../api/cc-customer';
import { genderEnum, statusEnum } from '../../../PlatformManagement/OrganizationAuthority/StaffManagement/util';
import { batchReassignStaff, disableStaff, enableStaff, freezeStaff } from '../../../../api/cc-staff';
import { LeftNavPreferenceDisplay } from '../../../../api/cc-const';

const routes = [
  { name: '租户管理' },
  { name: '租户组织权限和账户' },
  { name: '租户用户管理', path: '/tenant/customer/user' },
  { name: '用户详情' },
];

const titleArray = ['启用', '禁用', '冻结'];

export const tenantUserDetailColumns = [
  {
    title: '员工账号',
    dataIndex: 'staffCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '员工账号状态',
    dataIndex: 'statusAsString',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属组织',
    dataIndex: 'currentFullName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '组织ID',
    dataIndex: 'currentCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '上级组织',
    dataIndex: 'parentFullName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '上级组织ID',
    dataIndex: 'parentCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属企业',
    dataIndex: 'rootTenantName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '企业ID',
    dataIndex: 'rootCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const TenantUserDetail: React.FC = () => {
  const [modalApi, modalContextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();
  const routeParams: { id?: string } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<CustomerStaffResponse[]>([]);
  const [tenantUserDescriptions, setTenantDescriptions] = useState<{ label: string; value: string | ReactNode }[]>();
  // const pagingInfo = usePaging(5);
  const pagingInfo = usePaging(50);
  const [opened, setOpened] = useState<boolean>(false);
  const [operate, setOperate] = useState<number>();
  const [operateStaff, setOperateStaff] = useState<CustomerStaffResponse>();
  const [title, setTitle] = useState<string>();
  const [children, setChildren] = useState<React.ReactNode>();
  const [resultModalChildren, setResultModalChildren] = useState<React.ReactNode>();
  const [resultModalOpened, setResultModalOpened] = useState<boolean>(false);
  const [changeOpened, setChangeOpened] = useState<boolean>(false);
  const [option, setOption] = useState<SearchInputOptionProps[]>([]);
  const [muid, setMuid] = useState<string>();
  const [passwordOpened, setPasswordOpened] = useState<boolean>(false);
  const [operationPassword, setOperationPassword] = useState<string>('');
  const [currentStaffId, setCurrentStaffId] = useState<number>();

  useEffect(() => {
    getTenantUserDetail(routeParams.id).then(res => {
      setTenantDescriptions([
        {
          label: '用户ID',
          value: res.humanCode,
        },
        {
          label: '用户姓名',
          value: res.name,
        },
        {
          label: '用户登录账号',
          value: res.username,
        },
        {
          label: '手机号码',
          value: res.cellphone,
        },
        {
          label: '用户状态',
          value: statusEnum[res.status],
        },
        {
          label: '性别',
          value: genderEnum[res.gender],
        },
        {
          label: '生日',
          value: res.birthday,
        },
        {
          label: '邮箱',
          value: res.email,
        },
        {
          label: '微信',
          value: res.wechat,
        },
        {
          label: 'QQ',
          value: res.qq,
        },
        {
          label: '用户头像',
          value: <AttachmentUpload disabled value={res.headPic} />,
        },
        {
          label: '个人偏好',
          value: LeftNavPreferenceDisplay[res.navigationPreference],
        },
      ]);
    });
  }, [routeParams]);

  useEffect(() => {
    if (!resultModalOpened && !passwordOpened) {
      setLoading(true);
      getCustomerStaff(routeParams.id).then(res => {
        if (res) {
          setDataSource(
            res.map(item => {
              return {
                ...item,
                statusAsString: statusEnum[item.status],
              };
            })
          );
        }
      });
      setLoading(false);
    }
  }, [routeParams, resultModalOpened, passwordOpened]);

  const showModal = (s: number, v: CustomerStaffResponse) => {
    setOpened(true);
    setOperate(s);
    setOperateStaff(v);
    setTitle(titleArray[s]);
    if (s === 0) {
      setChildren(<p>即将启用员工工号{v.staffCode}，点击确定以执行该操作</p>);
    } else if (s === 1) {
      setChildren(<p>即将禁用员工工号{v.staffCode}，点击确定以执行该操作</p>);
    } else if (s === 2) {
      setChildren(<p>即将冻结员工工号{v.staffCode}，点击确定以执行该操作</p>);
    }
  };

  const statusButtonArray = (v: CustomerStaffResponse) => [
    <>
      <Button type="link" onClick={() => showModal(1, v)}>
        禁用
      </Button>
      <Button type="link" onClick={() => showModal(2, v)}>
        冻结
      </Button>
    </>,
    <>
      <Button type="link" onClick={() => showModal(0, v)}>
        启用
      </Button>
      <Button type="link" onClick={() => showModal(2, v)}>
        冻结
      </Button>
    </>,
    <>
      <Button type="link" onClick={() => showModal(0, v)}>
        启用
      </Button>
      <Button type="link" onClick={() => showModal(1, v)}>
        禁用
      </Button>
    </>,
  ];

  const renderAction = (v: CustomerStaffResponse) => {
    return (
      <Space size={16}>
        {statusButtonArray(v)[v.status]}
        <Button type="link">
          <Link to={`/tenant/customer/employee/${v.id}`}>查看详情</Link>
        </Button>
        <Button
          type="link"
          onClick={() => {
            setCurrentStaffId(v.id);
            setChangeOpened(true);
          }}
        >
          绑定给他人
        </Button>
      </Space>
    );
  };

  const onOk = async () => {
    if (operateStaff) {
      if (operate === 0) {
        const res = await enableStaff(operateStaff.id);
        if (res) {
          setOpened(false);
          setResultModalOpened(true);
          setResultModalChildren(<p>已启用账号{operateStaff?.username}</p>);
        }
      } else if (operate === 1) {
        const res = await disableStaff(operateStaff.id);
        if (res) {
          setOpened(false);
          setResultModalOpened(true);
          setResultModalChildren(<p>已禁用账号{operateStaff?.username}</p>);
        }
      } else if (operate === 2) {
        const res = await freezeStaff(operateStaff.id);
        if (res) {
          setOpened(false);
          setResultModalOpened(true);
          setResultModalChildren(<p>已冻结账号{operateStaff?.username}</p>);
        }
      }
    }
  };

  const onCustomerSearch = (value: string) => {
    if (value) {
      getListCustomer(value).then(res => {
        setOption(
          res.map(i => ({
            name: `${i.humanCode} ${i.name} ${i.cellphone}`,
            value: i.muid,
          }))
        );
      });
    }
  };

  const changeChildren = (
    <>
      <Form>
        <Form.Item>
          <SearchInput
            style={{ width: 500 }}
            timer={300}
            onSearch={onCustomerSearch}
            placeholder="输入希望绑定到的用户手机号/完整用户ID/完整用户姓名"
            option={option}
            onChange={(value: string) => {
              setMuid(value);
            }}
          />
        </Form.Item>
      </Form>
    </>
  );

  const onBatchReassignStaffOk = () => {
    if (!muid) {
      messageApi.warning('请先选择用户!');
      return;
    }
    setPasswordOpened(true);
  };

  useEffect(() => {
    if (!passwordOpened) {
      setOperationPassword('');
    }
  }, [passwordOpened]);

  const onSuccess = useCallback((content: React.ReactNode) => {
    return new Promise(resolve => {
      modalApi.success({
        title: '操作成功',
        className: styles.modal,
        content,
        icon: null,
        okText: '关闭',
        onOk: () => {
          resolve(undefined);
        },
        onCancel: () => {
          resolve(undefined);
        },
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPasswordOk = () => {
    if (operationPassword && currentStaffId && muid) {
      return batchReassignStaff({
        muid: muid,
        staffIds: [currentStaffId],
        operationPassword: operationPassword,
      })
        .then(res => {
          if (res) {
            return onSuccess(<div>绑定成功</div>);
          }
        })
        .then(() => {
          setOperationPassword('');
          setPasswordOpened(false);
          setChangeOpened(false);
          // return;
        });
    } else {
      return Promise.reject();
    }
  };

  return (
    <Wrapper className={styles.container} routes={routes}>
      <div className={styles.description}>
        <Descriptions>
          {tenantUserDescriptions &&
            tenantUserDescriptions.map(item => (
              <DescriptionsItem key={item.label} label={item.label}>
                {item.value}
              </DescriptionsItem>
            ))}
        </Descriptions>
        <Button type="primary">
          <Link to="/tenant/customer/user">返回</Link>
        </Button>
      </div>
      <div className={styles.table}>
        <Table
          rowKey="id"
          sticky
          loading={loading}
          dataSource={dataSource}
          columns={[...tenantUserDetailColumns, { title: '操作', width: 280, render: renderAction }]}
        />
        <Paging pagingInfo={pagingInfo} />
      </div>
      <Modal onOk={onOk} title={title} children={children} open={opened} onCancel={() => setOpened(false)} />
      <Modal
        title="操作成功"
        children={resultModalChildren}
        open={resultModalOpened}
        onOk={() => setResultModalOpened(false)}
        onCancel={() => setResultModalOpened(false)}
      />
      <Modal
        width={800}
        title={<span style={{ color: 'red' }}>变更员工账号所绑定的账户</span>}
        children={changeChildren}
        open={changeOpened}
        onCancel={() => setChangeOpened(false)}
        onOk={onBatchReassignStaffOk}
      />
      <Modal
        width={350}
        title="请输入关键操作密码"
        children={
          <Space direction="vertical" style={{ width: '100%', padding: '20px 0' }}>
            <Input.Password
              autoComplete="new-password"
              value={operationPassword}
              placeholder="请输入操作密码"
              onChange={e => {
                setOperationPassword(e.target.value);
              }}
            />
            <a target="_blank" href="/user/settings/operatePassword">
              忘记密码或是未设置？
            </a>
          </Space>
        }
        open={passwordOpened}
        onCancel={() => setPasswordOpened(false)}
        onOk={onPasswordOk}
      />
      {messageContextHolder}
      {modalContextHolder}
    </Wrapper>
  );
};

export default TenantUserDetail;
