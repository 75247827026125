import React, { useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './index.module.scss';
import { isObject } from 'lodash-es';
import BracketLeft from './BracketLeft';
import BracketRight from './BracketRight';
import ContentBlock from './ContentBlock';
import { getDataType, SchemaModel } from '../../utils/schema-utils';
import { JsonTreeContext } from './JsonTreeContent';

export interface JsonTreeProps {
  data: SchemaModel;
  parentData?: object;
  deep?: number;
  currentDeep?: number;
  currentKey?: string;
}

const cx = classNames.bind(styles);

const JsonTree: React.FC<JsonTreeProps> = ({
  data,
  parentData = null,
  deep = Infinity,
  currentDeep = 1,
  currentKey,
}) => {
  const treeContentBackground = 'transparent';
  const [visible, setVisible] = useState(true);
  const { showDescr, format } = useContext(JsonTreeContext);

  const lastKey = useMemo(() => {
    if (Array.isArray(parentData)) {
      return parentData.length - 1;
    } else if (isObject(parentData)) {
      let arr = Object.keys(parentData);
      return arr[arr.length - 1];
    }
  }, [parentData]);

  const isLastKey = useMemo(() => {
    return currentKey !== lastKey;
  }, [currentKey, lastKey]);

  useEffect(() => {
    setVisible(currentDeep <= deep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deep]);

  return (
    <div
      className={cx('sw-tree', `${showDescr ? 'sw-show-descr' : 'sw-hide-descr'}`, `sw-format-${format}`)}
      style={{
        backgroundColor: treeContentBackground,
        position: currentDeep > 1 ? 'unset' : 'relative',
      }}
    >
      {Array.isArray(data) || isObject(data) ? (
        <>
          <BracketLeft visible={visible} data={data} isLastKey={isLastKey} setVisible={setVisible}>
            {currentDeep > 1 && !Array.isArray(parentData) ? (
              currentKey?.endsWith('*') ? (
                <>
                  <span className={cx('item-required')}>{currentKey.substring(0, currentKey.length - 1)}</span>:
                </>
              ) : (
                `${currentKey}:`
              )
            ) : undefined}
          </BracketLeft>
          {Object.keys(data['values']).map(key => {
            return (
              <div className={cx('sw-tree-content')} style={{ display: visible ? '' : 'none' }} key={key}>
                <JsonTree
                  parentData={data['values'] as object}
                  data={(data['values'] as any)[key]}
                  deep={deep}
                  currentKey={key.toString()}
                  currentDeep={currentDeep + 1}
                ></JsonTree>
              </div>
            );
          })}
          <BracketRight visible={visible} data={data} isLastKey={isLastKey} setVisible={setVisible}></BracketRight>
        </>
      ) : (
        <ContentBlock
          parentDataType={getDataType(parentData)}
          dataType={getDataType(data)}
          text={data + ''}
          isLastKey={isLastKey}
          currentKey={currentKey}
        ></ContentBlock>
      )}
    </div>
  );
};

export default JsonTree;
