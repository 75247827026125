import { Select } from 'antd';
import { useEffect, useState } from 'react';
import { TagItemProps } from '../../../api/cc-tenant-ql';
interface Iprops {
  options?: TagItemProps[];
  value?: number;
  appId: string;
  isSet?: boolean;
  disabled?: boolean;
  onChange?: (value?: number) => void;
}
const QflowAppTagSelect: React.FC<Iprops> = ({ options, value, onChange, disabled, appId, isSet }) => {
  const [data, setData] = useState<TagItemProps[]>();
  const [currentValue, setCurrentValue] = useState<number>();

  useEffect(() => {
    if (options) {
      setData(options);
    }
  }, [options]);

  useEffect(() => {
    if (value && isSet) {
      setCurrentValue(value);
      onChange?.(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, isSet]);

  const selectChange = (val: number) => {
    setCurrentValue(val);
    onChange?.(val);
  };
  return (
    <>
      <Select
        placeholder="请选择"
        style={{ width: '100%' }}
        allowClear
        disabled={disabled}
        value={currentValue}
        onChange={selectChange}
      >
        {data?.map(item => (
          <Select.Option value={item.tagId} key={item.tagId}>
            {item.tagId + '-' + item.tagName}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default QflowAppTagSelect;
