import { Paging, Table, usePaging } from '@maxtropy/components';
import { Col, Form, Input, Modal, Select } from 'antd';
import React, { useCallback, useState } from 'react';
import Filter from '@/shared/components/Filter';
import { GatewayNetStatus, GatewayNetStatusDisplay } from '@/shared/types';
import { Tenant } from '../../../api/device';
import { IotProtocol } from '../../../api/protocol';
import { useQuery } from '../../../utils/utils';
import { Gateway, getGatewayList } from '../../../api/gateway';
import { SearchParams } from '../index';
import { EllipsisSpan } from '@/shared/components/DeviceBatchEdge/Mockingbird/EllipsisSpan';
import qs from 'qs';

const { Option } = Select;

interface ExportInfoModalProps {
  visible: boolean;
  onCancel: () => void;
  tenant: Tenant[];
  iotProtocolData?: Array<IotProtocol>;
}

const ExportInfoModal: React.FC<ExportInfoModalProps> = ({ visible, onCancel, tenant, iotProtocolData }) => {
  const [form] = Form.useForm();
  // const pagingInfo = usePaging(10);
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        visible
          ? getGatewayList({
              ...searchParams,
              page: pageOffset,
              size: pageSize,
            }).then(res => {
              if (res) setTotalCount(res.total);
              return res.list;
            })
          : Promise.resolve([]),
      [pageOffset, pageSize, searchParams, setTotalCount, visible]
    )
  );

  const rowSelection = {
    selectedRowKeys,
    hideSelectAll: true,
    onSelect: (record: any, selected: any) => {
      if (selected) {
        setSelectedRowKeys([...selectedRowKeys, record.id]);
      } else {
        setSelectedRowKeys(selectedRowKeys.filter(i => i !== record.id));
      }
    },
  };

  const onFinish = (v: SearchParams) => {
    setSearchParams(v);
    setPageOffset(1);
    setSelectedRowKeys([]);
  };

  const onReset = () => {
    setSearchParams(undefined);
    setPageOffset(1);
    setSelectedRowKeys([]);
  };

  const onOk = () => {
    const params = {
      ids: selectedRowKeys,
    };
    window.open(`/api/gateway/download?${qs.stringify(params, { indices: false })}`);
    onCancelAction();
  };

  const onCancelAction = () => {
    onCancel();
    setSelectedRowKeys([]);
    form.resetFields();
    setSearchParams(undefined);
    setPageOffset(1);
  };

  return (
    <Modal
      open={visible}
      width={1000}
      destroyOnClose
      title="网关选择"
      okText="导出选中的网关"
      onOk={onOk}
      onCancel={onCancelAction}
    >
      <Filter form={form} onFinish={onFinish} onReset={onReset}>
        <Col span={8}>
          <Form.Item name="name" label="编号/名称">
            <Input placeholder={'请输入编号或名称查询'} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="iotProtocol" label="物联层协议">
            <Select placeholder="请选择">
              {iotProtocolData?.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="netState" label="状态">
            <Select placeholder="请选择">
              <Option value={GatewayNetStatus.ONLINE}>{GatewayNetStatusDisplay[GatewayNetStatus.ONLINE]}</Option>
              <Option value={GatewayNetStatus.OFFLINE}>{GatewayNetStatusDisplay[GatewayNetStatus.OFFLINE]}</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="rootMcid" label="所属租户">
            <Select placeholder="请选择">
              {tenant.map(i => (
                <Option key={i.mcid} value={i.mcid}>
                  {i.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Filter>
      <p>
        已选择<span style={{ color: '#d62500' }}>{selectedRowKeys.length}</span>项
      </p>
      <Table
        rowKey="id"
        loading={isLoading}
        columns={columns}
        dataSource={data}
        scroll={{ y: 300 }}
        rowSelection={rowSelection}
      />
      <Paging pagingInfo={pagingInfo} />
    </Modal>
  );
};

export default ExportInfoModal;

const columns = [
  {
    title: '网关编号',
    dataIndex: 'serialNumber',
    ellipsis: { showTitle: true },
    width: 150,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '网关名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    width: 120,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属租户',
    dataIndex: 'createByTenantName',
    ellipsis: { showTitle: true },
    render: (v: string, record: Gateway) => <EllipsisSpan value={`${record.tenantCode} ${v}`} />,
  },
  {
    title: '所在组织简称',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    width: 150,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所在组织全称',
    dataIndex: 'customerFullName',
    ellipsis: { showTitle: true },
    width: 150,
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];
