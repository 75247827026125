import { Col, Row, Space } from 'antd';
import styles from '../index.module.scss';
import imgs from '../../../assets/image/no-cover.png';
interface Iprops {
  data?: any;
}
const DataValuesTemplateDetail = ({ data }: Iprops) => {
  return (
    <>
      <div className={styles.tempate_box}>
        <div className={styles.left}>
          {/* 本次迭代先给默认值, 后续取data.imageUri */}
          <img src={imgs} alt="no" />
        </div>
        <div className={styles.right}>
          <Row>
            <Col span={12}>
              <Space>
                <div className={styles.label}>{data?.yMagnificationName ?? '-'}</div>
                <div className={styles.value}>{data?.yMagnificationValue}</div>
              </Space>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default DataValuesTemplateDetail;
