import React, { useContext, useEffect, useMemo, useState } from 'react';
import { EdgeDeviceTemplatePoint } from '../../EdgeDevicePointInfo';
import { ActionType } from '../../../types';
import { ColumnsType } from 'antd/es/table';
import { Divider, Popconfirm, Space } from 'antd';
import { PointContext } from '../../EdgeDevicePointInfo/contextTypes';
import { BatchEdgeContent } from '../contentTypes';
import { PointOperateType } from '../interface';
import UpdateEdgePoint from './Update';
import AddEdgePoint from './Add';
import DeleteEdgePoint from './Delete';
import { Button } from '@maxtropy/components';

interface BatchEdgePointProps {
  editable?: boolean;
  dataSource?: EdgeDeviceTemplatePoint[];
  setDataSource?: (dataSource: EdgeDeviceTemplatePoint[]) => void;
  promptSlot?: React.ReactElement;
}

const BatchEdgePoint: React.FC<BatchEdgePointProps> = props => {
  const { editable = true, dataSource, setDataSource, promptSlot } = props;
  const [row, setRow] = useState<Partial<EdgeDeviceTemplatePoint> | undefined>();
  const [searchValue, setSearchValue] = useState<string>();
  const { baseForm } = useContext(BatchEdgeContent);
  const { pointOperateType, iotProtocol, deviceType, driveType, objectModalType } = baseForm?.getFieldsValue();

  const info = useMemo(
    () => ({
      iotProtocol,
      deviceTypeId: deviceType?.slice(-1),
      driveType,
      physicalModelId: objectModalType,
    }),
    [iotProtocol, deviceType, driveType]
  );

  const data = useMemo(
    () =>
      dataSource
        ?.filter(item => item.actionType !== ActionType.DELETE)
        .filter(item => {
          if (searchValue) {
            return (item.dataPropertyName || item.identifier)!.indexOf(searchValue) > -1;
          } else {
            return true;
          }
        }),
    [dataSource, searchValue]
  );

  useEffect(() => {
    setSearchValue(undefined);
  }, []);

  const onDelete = async (record: any) => {
    const copy = dataSource!.slice();
    copy.splice(dataSource!.indexOf(record), 1);
    setDataSource!(copy);
  };

  const onUpdate = async (values: any) => {
    const copy = dataSource?.slice() || [];
    if (row!.id) {
      const index = copy.findIndex(item => item.id === row!.id);
      copy[index] = {
        id: row!.id,
        ...values,
        actionType: ActionType.EDIT,
      };
      setDataSource!(copy);
    } else if (row!.actionType === ActionType.ADD || pointOperateType === PointOperateType.UPDATE) {
      const index = dataSource!.indexOf(row as EdgeDeviceTemplatePoint);
      copy[index] = {
        ...values,
        actionType: ActionType.ADD,
      };
      setDataSource!(copy);
    } else {
      copy.push({ ...values, actionType: ActionType.ADD });
      setDataSource!(copy);
    }
  };

  const onAddIdentifier = (values: any) => {
    const copy = dataSource?.slice() || [];
    copy.push({ ...values, parameters: {}, actionType: ActionType.NONE });
    setDataSource!(copy);
  };

  const opColumns: ColumnsType<EdgeDeviceTemplatePoint> = [
    {
      title: '操作',
      width: 150,
      fixed: 'right',
      render: (_, record, index) => (
        <Space size={16} className="action-buttons">
          <Button
            type="link"
            onClick={() => {
              setRow(record);
            }}
          >
            编辑
          </Button>
          <Popconfirm
            okText="继续"
            placement="topRight"
            title={
              <>
                <div>确定删除？</div>
                <div style={{ color: '#f5222d' }}>
                  删除后不可恢复<span style={{ color: 'var(--disabled-color)' }}>，你还要继续吗？</span>
                </div>
              </>
            }
            onConfirm={() => onDelete(record)}
          >
            <Button type="link">删除</Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const editColumns = [...(editable ? opColumns : [])];

  const renderContent = () => {
    if (pointOperateType === PointOperateType.UPDATE) {
      return <UpdateEdgePoint onUpdate={onUpdate} onAddIdentifier={onAddIdentifier} onDelete={onDelete} />;
    } else if (pointOperateType === PointOperateType.ADD) {
      return <AddEdgePoint onUpdate={onUpdate} editColumns={editColumns} onSearch={setSearchValue} />;
    } else if (pointOperateType === PointOperateType.DELETE) {
      return <DeleteEdgePoint onUpdate={onUpdate} onDelete={onDelete} />;
    } else {
      return undefined;
    }
  };

  return (
    <PointContext.Provider value={{ dataSource: data, row, setRow, info, promptSlot, editable }}>
      {renderContent()}
    </PointContext.Provider>
  );
};

export default BatchEdgePoint;
