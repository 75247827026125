import React, { ReactNode } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import './style/index.scss';
import classnames from 'classnames';

interface PromptProps {
  title: ReactNode;
  style?: React.CSSProperties;
  className?: string;
  spanClassName?: string;
}

const Prompt: React.FC<PromptProps> = props => {
  const prefixCls = 'open-platform-prompt';
  const { style, className, spanClassName, title } = props;
  return (
    <span className={classnames(`${prefixCls}-wrapper`, className)} style={style}>
      <span className={classnames(`${prefixCls}-title`, spanClassName)}>
        <InfoCircleOutlined className={`${prefixCls}-icon`} />
        {title}
      </span>
    </span>
  );
};

export default Prompt;
