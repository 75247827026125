import React from 'react';
import { Button, Divider, Popconfirm, Space } from 'antd';
import { Table } from '@maxtropy/components';
import { IotProtocolType } from '@/shared/types';
import { Device } from '../../../api/device';
import { deviceColumns } from '../Detail';

interface TableDataType extends Omit<Device, 'attributeList'> {}

interface DeviceTableProps {
  devices: Device[];
  setDevices: (devices: Device[]) => void;
  iotProtocol?: IotProtocolType;
}

const DeviceTable: React.FC<DeviceTableProps> = ({ devices, setDevices, iotProtocol }) => {
  const reorderItems = (from: number, to: number) => {
    const items = devices.slice();
    items.splice(to, 0, items.splice(from, 1)[0]);
    setDevices(items);
  };

  const mergedColumns = [
    {
      title: '网关中的设备序号',
      dataIndex: 'sequence',
      ellipsis: { showTitle: true },
      render: (_: undefined, record: Device, index: number) => index + 1,
    },
    ...deviceColumns(true),
    {
      title: '操作',
      dataIndex: 'operation',
      width: 300,
      render: (value: undefined, record: Device) => {
        const index = devices.findIndex(item => item.id === record.id);
        return (
          <Space size={16}>
            {iotProtocol === IotProtocolType.ESTUN && (
              <>
                <Button type="link" disabled={index === 0} onClick={() => reorderItems(index, index - 1)}>
                  上移
                </Button>
                <Button
                  type="link"
                  disabled={index === devices.length - 1}
                  onClick={() => reorderItems(index, index + 1)}
                >
                  下移
                </Button>
              </>
            )}
            <Popconfirm
              placement="topRight"
              title="确定删除?"
              onConfirm={() => {
                setDevices(devices.filter(o => o.id !== record.id));
              }}
            >
              <Button type="link">取消绑定</Button>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <Table<TableDataType>
        rowKey="id"
        dataSource={devices}
        columns={
          iotProtocol === IotProtocolType.MOCKINGBIRD ? mergedColumns.slice(1, mergedColumns.length) : mergedColumns
        }
        pagination={false}
      />
    </>
  );
};

export default DeviceTable;
