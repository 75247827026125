/* eslint-disable react-hooks/exhaustive-deps */
import { Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { AppItemProps } from '../../../api/cc-tenant-ql';
interface Iprops {
  options?: AppItemProps[];
  value?: string;
  appId: string;
  isSet?: boolean;
  hasSelects?: string[];
  disabled?: boolean;
  onChange?: (value?: string) => void;
}
const QflowAppPageSelect: React.FC<Iprops> = ({ options, value, onChange, disabled, appId, isSet, hasSelects }) => {
  const [data, setData] = useState<AppItemProps[]>();
  const [currentValue, setCurrentValue] = useState<string>();
  const onceTime = useRef<boolean>(false);

  // 依赖项变化, 表单置空
  useEffect(() => {
    setCurrentValue(undefined);
    onChange?.();
  }, [appId]);

  useEffect(() => {
    if (options) {
      setData(options);
    }
  }, [options]);

  // 表单赋值, 防止因appid变化表单置空
  useEffect(() => {
    if (onceTime.current) return;
    if (value && appId) {
      setCurrentValue(value);
      onChange?.(value);
      onceTime.current = true;
    }
  }, [value, appId]);

  const selectChange = (val?: string) => {
    setCurrentValue(val);
    onChange?.(val);
  };
  return (
    <>
      <Select
        placeholder="请选择"
        style={{ width: '100%' }}
        allowClear
        disabled={disabled}
        value={currentValue}
        onChange={selectChange}
      >
        {data?.map(item => (
          <Select.Option disabled={hasSelects?.includes(item.appKey!)} value={item.appKey} key={item.appKey}>
            {item.appKey + '-' + item.appName}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default QflowAppPageSelect;
