import React, { FC, Key, useCallback, useState } from 'react';
import { Col, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import { isNil } from 'lodash-es';
import { EllipsisSpan, Paging, Table, usePaging } from '@maxtropy/components';
import Filter from '@/shared/components/Filter';
import { useQuery } from '@/shared/utils/utils';
import { BatchDevicePageRequest, getBatchDevicePage } from '../../../../api/batch';
import { ActionType } from '../index';
import styles from './index.module.scss';

export const columns = [
  {
    title: '设备编号',
    dataIndex: 'code',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备标签',
    dataIndex: 'tags',
    ellipsis: { showTitle: true },
    render: (v: string[]) => {
      const tags = v ? v.join(', ') : '--';
      return <EllipsisSpan value={tags} />;
    },
  },
  {
    title: '所属类目',
    dataIndex: 'typeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属组织',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export interface DevicesModalProps {
  value?: Key[];
  onChange?: (value: Key[]) => void;
  rootMcid?: string;
  deviceType?: number[];
  templateId?: number;
  templateVersion?: number;
  actionType?: ActionType;
  deviceTypeName?: string;
  physicalModelId?: number;
}

type SearchParams = Omit<
  BatchDevicePageRequest,
  'deviceTypeId' | 'tenantMcid' | 'operationType' | 'templateId' | 'templateVersion' | 'page' | 'size'
>;

const DevicesModal: FC<DevicesModalProps> = ({
  value,
  onChange,
  rootMcid,
  deviceType,
  templateId,
  templateVersion,
  actionType,
  deviceTypeName,
  physicalModelId,
}) => {
  const [form] = Form.useForm();

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [searchParams, setSearchParams] = useState<SearchParams>();

  const { data, isLoading } = useQuery(
    useCallback(async () => {
      if (!isNil(rootMcid) && !isNil(deviceType) && !isNil(actionType) && !isNil(templateId)) {
        const page = await getBatchDevicePage({
          tag: searchParams?.tag,
          codeOrName: searchParams?.codeOrName,
          deviceTypeId: deviceType[deviceType.length - 1],
          tenantMcid: rootMcid,
          operationType: actionType,
          templateId,
          physicalModelId,
          templateVersion,
          page: pageOffset,
          size: pageSize,
        });
        if (page) {
          setTotalCount(page.total);
          return page.list;
        }
      }
    }, [
      pageOffset,
      pageSize,
      searchParams,
      setTotalCount,
      deviceType,
      rootMcid,
      actionType,
      templateId,
      templateVersion,
      physicalModelId,
    ])
  );

  const rowSelection = {
    onChange: (selectedRowKeys: Key[]) => {
      onChange?.(selectedRowKeys);
    },
    preserveSelectedRowKeys: true,
    selectedRowKeys: value ?? [],
  };

  const onFinish = (value: SearchParams) => {
    setSearchParams(value);
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    form.setFieldsValue({
      name: undefined,
    });
    setPageOffset(1);
  };

  const filters = (
    <Filter
      form={form}
      onFinish={val => {
        onFinish(val);
      }}
      onReset={onReset}
    >
      <>
        <Col span={12}>
          <Form.Item name="codeOrName" label="编号/名称">
            <Input placeholder="请输入编号或名称查询" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="所属类目">{deviceTypeName}</Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="tag" label="标签">
            <Input placeholder="请输入" />
          </Form.Item>
        </Col>
      </>
    </Filter>
  );

  return (
    <>
      {filters}
      <div className={styles.table_text}>
        <div className={styles.tips}>
          <div>
            已选择<span className={styles.red}>{(value ?? []).length}</span>项
          </div>
        </div>
        <div className={styles.action}>
          <span className={styles.text}>点击前往</span>
          <Link to={'/device/manage/device/create'} className={styles.href}>
            添加设备
          </Link>
        </div>
      </div>
      <Table
        loading={isLoading}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        sticky
        scroll={{ y: 300 }}
        rowKey="id"
        columns={columns}
        dataSource={data}
      />
      <Paging pagingInfo={pagingInfo} />
    </>
  );
};

export default DevicesModal;
