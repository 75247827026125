import React, { CSSProperties } from 'react';
import { Button, Popconfirm, Space } from 'antd';
import AttributeItem from '@/shared/components/AttributeItem';
import { DeviceAttribute } from '@/shared/types';
import styles from './index.module.scss';
import { Flipper, Flipped } from 'react-flip-toolkit';

export interface AttributeCanvasProps {
  attributes: DeviceAttribute[];
  setAttributes: (attributes: DeviceAttribute[]) => void;
  style?: CSSProperties;
}

const AttributeCanvas: React.FC<AttributeCanvasProps> = ({ attributes, setAttributes, style }) => {
  const reorderItems = (from: number, to: number) => {
    const items = attributes.slice();
    items.splice(to, 0, items.splice(from, 1)[0]);
    setAttributes(items);
  };

  return (
    <div
      style={style}
      onDrop={e => {
        e.preventDefault();
        const item = JSON.parse(e.dataTransfer.getData('item')) as DeviceAttribute;
        setAttributes([...attributes, item]);
      }}
      onDragOver={e => e.preventDefault()}
    >
      {style?.minHeight && !attributes.length && <div className={styles.dragArea}>拖拽区域</div>}
      <Flipper flipKey={attributes.map(item => item.id).join('')}>
        {attributes.map((item, index) => (
          <Flipped key={item.id} flipId={item.id}>
            <div>
              <AttributeItem
                disabled
                style={index === attributes.length - 1 ? { borderBottom: 'none' } : undefined}
                buttons={
                  <Space className={styles.buttons}>
                    <Button type="link" disabled={index === 0} onClick={() => reorderItems(index, 0)}>
                      置顶
                    </Button>
                    <Button type="link" disabled={index === 0} onClick={() => reorderItems(index, index - 1)}>
                      上移
                    </Button>
                    <Button
                      type="link"
                      disabled={index === attributes.length - 1}
                      onClick={() => reorderItems(index, index + 1)}
                    >
                      下移
                    </Button>
                    <Button
                      type="link"
                      disabled={index === attributes.length - 1}
                      onClick={() => reorderItems(index, attributes.length - 1)}
                    >
                      置底
                    </Button>
                    <Popconfirm
                      placement="topRight"
                      title="确定删除?"
                      onConfirm={() => {
                        setAttributes(attributes.filter(o => o.id !== item.id));
                      }}
                    >
                      <Button danger type="link">
                        删除
                      </Button>
                    </Popconfirm>
                  </Space>
                }
                attribute={item}
              />
            </div>
          </Flipped>
        ))}
      </Flipper>
    </div>
  );
};

export default AttributeCanvas;
