import {
  AddrOffsetOptions,
  BuadRateOptions,
  DataBitOptions,
  DriveType,
  SerialPort,
  SerialPortLabels,
  StopBitOptions,
} from '../../../types';
import { Form } from '@maxtropy/components';
import React from 'react';
import { CheckTypeOptions, CrcOrderOptions } from '../common';

const MockingbirdFormDisplay: React.FC<any> = ({ data }) => {
  return (
    <>
      {data.driveType === DriveType.MODBUS_RTU && (
        <>
          <SerialPortField value={data.serialPort} />

          <StationNumField value={data.stationNum} />

          <BaudrateField value={data.baudRate} />

          <CheckTypeField value={data.checkType} />

          <DataBitField value={data.dataBit} />

          <StopBitField value={data.stopBit} />

          <CrcOrderField value={data.crcOrder} />

          <GroupWordsField value={data.groupWords} />

          <AddrOffsetField value={data.addrOffset} />
        </>
      )}
      {data.driveType === DriveType.MODBUS_TCP && (
        <>
          <Form.Item label="IP地址" name="ip">
            <div>{data.ip ? data.ip : '--'}</div>
          </Form.Item>
          <Form.Item label="TCP端口" name="port">
            <div>{data.port ? data.port : '--'}</div>
          </Form.Item>
          <StationNumField value={data.stationNum} />
          <CrcOrderField value={data.crcOrder} />
          <GroupWordsField value={data.groupWords} />
          <AddrOffsetField value={data.addrOffset} />
        </>
      )}
      {(data.driveType === DriveType.DLT645_1997 || data.driveType === DriveType.DLT645_2007) && (
        <>
          <SerialPortField value={data.serialPort} />

          <Form.Item label="表计地址域" name="address">
            <div>{data.address ? data.address : '--'}</div>
          </Form.Item>

          <Form.Item label="前导地址" name="leadByte">
            <div>{data.leadByte !== undefined ? data.leadByte : '--'}</div>
          </Form.Item>

          <BaudrateField value={data.baudRate} />

          <CheckTypeField value={data.checkType} />

          <DataBitField value={data.dataBit} />

          <StopBitField value={data.stopBit} />
        </>
      )}
      {data.driveType === DriveType.IEC104 && (
        <>
          <Form.Item label="IP地址" name="ip">
            <div>{data.ip ? data.ip : '--'}</div>
          </Form.Item>
          <Form.Item label="端口号" name="port">
            <div>{data.port ? data.port : '--'}</div>
          </Form.Item>
        </>
      )}

      <SamplingIntervalField value={data.samplingInterval} />
      <SamplingTimeoutField value={data.samplingTimeout} />
      <SamplingRetryField value={data.samplingRetry} />
    </>
  );
};

const SerialPortField: React.FC<{ value?: number }> = ({ value }) => (
  <Form.Item label="串口地址" name="serialPort">
    <div>{value !== undefined ? SerialPortLabels[value as SerialPort] : '--'}</div>
  </Form.Item>
);

const StationNumField: React.FC<{ value?: number }> = ({ value }) => (
  <Form.Item label="Modbus站号" name="stationNum">
    <div>{value !== undefined ? value : '--'}</div>
  </Form.Item>
);

const BaudrateField: React.FC<{ value?: number }> = ({ value }) => (
  <Form.Item label="波特率" name="baudRate">
    <div>{value !== undefined ? BuadRateOptions.find(item => item.value === value)?.label : '--'}</div>
  </Form.Item>
);

const CheckTypeField: React.FC<{ value?: number }> = ({ value }) => (
  <Form.Item label="校验" name="checkType">
    <div>{value !== undefined ? CheckTypeOptions.find(item => item.value === value)?.label : '--'}</div>
  </Form.Item>
);

const DataBitField: React.FC<{ value?: number }> = ({ value }) => (
  <Form.Item label="数据位" name="dataBit">
    <div>{value !== undefined ? DataBitOptions.find(item => item.value === value)?.label : '--'}</div>
  </Form.Item>
);

const StopBitField: React.FC<{ value?: number }> = ({ value }) => (
  <Form.Item label="停止位" name="stopBit">
    <div>{value !== undefined ? StopBitOptions.find(item => item.value === value)?.label : '--'}</div>
  </Form.Item>
);

const CrcOrderField: React.FC<{ value?: number }> = ({ value }) => (
  <Form.Item label="CRC字节序" name="crcOrder">
    <div>{value !== undefined ? CrcOrderOptions.find(item => item.value === value)?.label : '--'}</div>
  </Form.Item>
);

const GroupWordsField: React.FC<{ value?: number }> = ({ value }) => (
  <Form.Item label="最大单次请求长度" name="groupWords">
    <div>{value !== undefined ? `${value}字(word)` : '--'}</div>
  </Form.Item>
);

const SamplingIntervalField: React.FC<{ value?: number }> = ({ value }) => (
  <Form.Item label="采集间隔" name="samplingInterval">
    <div>{value !== undefined ? `${value}ms` : '--'}</div>
  </Form.Item>
);

const SamplingTimeoutField: React.FC<{ value?: number }> = ({ value }) => (
  <Form.Item label="采集超时" name="samplingTimeout">
    <div>{value !== undefined ? `${value}ms` : '--'}</div>
  </Form.Item>
);

const SamplingRetryField: React.FC<{ value?: number }> = ({ value }) => (
  <Form.Item label="重试次数" name="samplingRetry">
    <div>{value !== undefined ? value : '--'}</div>
  </Form.Item>
);

const AddrOffsetField: React.FC<{ value?: number }> = ({ value }) => (
  <Form.Item label="首地址偏移" name="addrOffset">
    <div>{value !== undefined ? AddrOffsetOptions.find(item => item.value === value)?.label : '--'}</div>
  </Form.Item>
);

export default MockingbirdFormDisplay;
