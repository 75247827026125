import { Space } from 'antd';
import { FC, ReactNode, CSSProperties } from 'react';

export interface ShowInputProps {
  value?: ReactNode;
  style?: CSSProperties;
  addonAfter?: ReactNode;
}

const ShowInput: FC<ShowInputProps> = ({ value, style, addonAfter }) => {
  return (
    <div
      style={{
        ...style,
        overflowWrap: 'break-word',
      }}
    >
      {value ?? '--'}
      {addonAfter ? <span>&nbsp;{addonAfter}</span> : ''}
    </div>
  );
};

export default ShowInput;
