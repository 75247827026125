import { EllipsisSpan } from '@maxtropy/components';
import { ColumnType } from 'antd/es/table';
import { OutsiderResponse } from '../../../../api/deviceAlarmPushStrategy';

export const columns: ColumnType<OutsiderResponse>[] = [
  {
    title: '联系人',
    dataIndex: 'contactName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '手机号',
    dataIndex: 'contactPhone',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];
