import { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { Form, Select, Input, Radio, Col, Row, Modal, Upload, UploadFile } from 'antd';
import { AttachmentUpload, Button } from '@maxtropy/components';
import {
  apiIntegratedAppAllGet,
  apiMiniAppCategoryListPost,
  MiniAppCategoryListPostResponse,
} from '@maxtropy/cc-mgmt-apis';
import { apiV2MiniAppToolToolDetailPost } from '@maxtropy/cc-mgmt-apis-v2';
import { ISvListResponse } from '../../../api/cc-miniApp';
import ToolCategoryModal from './ToolCategoryModal';
import styles from '../index.module.scss';

type ToolProps = {
  isvList: ISvListResponse[];
  miniAppToolId?: number;
};

type integratedAppListType = {
  label?: string;
  value?: number;
};
const FormItem = Form.Item;

const cardOptions = [
  { label: '支持', value: 1 },
  { label: '不支持', value: 0 },
];

const toolTypeOptions = [
  { label: '自研', value: 1 },
  { label: 'ISV研发', value: 2 },
];

export type ToolFormRef = {
  form?: any;
};

const ToolForm = forwardRef<ToolFormRef, ToolProps>(({ isvList, miniAppToolId }, ref) => {
  const [form] = Form.useForm();
  const [modalApi, modalContextHolder] = Modal.useModal();
  const toolType = Form.useWatch('type', form);
  const [openCategory, setOpenCategory] = useState<boolean>(false);
  const [refreshCategory, setRefreshCategory] = useState<boolean>(false);
  const [toolCategoryList, setToolCategoryList] = useState<MiniAppCategoryListPostResponse>([]);
  const [integratedAppList, setIntegratedAppList] = useState<integratedAppListType[]>([]);
  const [cardFlag, setCardFlag] = useState<boolean>(false);

  useEffect(() => {
    apiMiniAppCategoryListPost().then(res => {
      setToolCategoryList(res || []);
    });
  }, [refreshCategory]);

  useEffect(() => {
    apiIntegratedAppAllGet().then(res => {
      let list: integratedAppListType[] = [];
      if (res.length) {
        list = res.map(item => ({
          label: item.name,
          value: item.id,
        }));
      }
      setIntegratedAppList(list);
    });
  }, []);

  useEffect(() => {
    if (miniAppToolId) {
      apiV2MiniAppToolToolDetailPost({ id: miniAppToolId }).then(res => {
        form.setFieldsValue(res);
        setCardFlag(res.cardSupport ? true : false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    form: form,
  }));

  const commonCheck = (file: UploadFile) => {
    const { size, type } = file;
    if ((size as number) > 1048576) {
      modalApi.error({ content: `上传图片超过1MB` });
      return Upload.LIST_IGNORE;
    }
    if (type !== 'image/png' && type !== 'image/jpg') {
      modalApi.error({ content: `仅支持.jpg, .png格式` });
      return Upload.LIST_IGNORE;
    }
  };

  const beforeUploadSmallImg = async (file: UploadFile) => {
    commonCheck(file);
    let isSuitable = await checkSmallImgSize(file);
    if (!isSuitable) {
      modalApi.error({ content: `图片尺寸需要328*148px` });
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const checkSmallImgSize = (file: UploadFile) => {
    const width = 328;
    const height = 148;
    return new Promise((resolve, reject) => {
      let _URL = window.URL || window.webkitURL;
      let img = new Image();
      img.onload = function () {
        let valid = img.width === width && img.height === height;
        valid ? resolve(true) : resolve(false);
      };
      img.src = _URL.createObjectURL(file as unknown as Blob);
    });
  };

  const beforeUploadMiddleImg = async (file: UploadFile) => {
    commonCheck(file);
    let isSuitable = await checkMiddleImgSize(file);
    if (!isSuitable) {
      modalApi.error({ content: `图片尺寸需要328*328px` });
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const checkMiddleImgSize = (file: UploadFile) => {
    const width = 328;
    const height = 328;
    return new Promise((resolve, reject) => {
      let _URL = window.URL || window.webkitURL;
      let img = new Image();
      img.onload = function () {
        let valid = img.width === width && img.height === height;
        valid ? resolve(true) : resolve(false);
      };
      img.src = _URL.createObjectURL(file as unknown as Blob);
    });
  };

  const beforeUploadBigImg = async (file: UploadFile) => {
    commonCheck(file);
    let isSuitable = await checkBigImgSize(file);
    if (!isSuitable) {
      modalApi.error({ content: `图片尺寸需要686*328px` });
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const checkBigImgSize = (file: UploadFile) => {
    const width = 686;
    const height = 328;
    return new Promise((resolve, reject) => {
      let _URL = window.URL || window.webkitURL;
      let img = new Image();
      img.onload = function () {
        let valid = img.width === width && img.height === height;
        valid ? resolve(true) : resolve(false);
      };
      img.src = _URL.createObjectURL(file as unknown as Blob);
    });
  };

  return (
    <>
      <Form form={form} labelAlign="left" className={styles.toolForm}>
        <Row>
          <Col span={20}>
            <FormItem label="工具分类" name="categoryId" rules={[{ required: true, message: '请选择工具分类' }]}>
              <Select placeholder="请选择">
                {toolCategoryList.map(item => {
                  return (
                    <Select.Option key={item.id} value={item.id} label={item.name}>
                      {item.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </FormItem>
          </Col>
          <Col span={4} style={{ textAlign: 'right' }}>
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() => {
                setOpenCategory(true);
                setRefreshCategory(false);
              }}
            >
              分类管理
            </Button>
          </Col>
          <Col span={24}>
            <FormItem
              label="工具名称"
              name="name"
              rules={[
                { required: true, message: '请输入工具名称' },
                { max: 4, message: '最多输入4个字符' },
              ]}
            >
              <Input placeholder="请输入" />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem label="仓库地址" name="gitRepoUrl" rules={[{ required: true, message: '请输入仓库地址' }]}>
              <Input placeholder="请输入" />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem label="入口路径" name="entryPath" rules={[{ required: true, message: '请输入入口路径' }]}>
              <Input placeholder="请输入" />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              label="工具图标"
              name="icon"
              rules={[{ required: true }]}
              help="建议尺寸176*176，格式是.gif、.png，大小小于1M"
            >
              <AttachmentUpload accept=".gif,.png" tip={false} />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem
              label="工具类型"
              name="type"
              initialValue={1}
              rules={[{ required: true, message: '请选择工具类型' }]}
            >
              <Radio.Group options={toolTypeOptions} />
            </FormItem>
          </Col>
          {toolType === 2 && (
            <>
              <Col span={24}>
                <FormItem label="服务商" name="vendorId" rules={[{ required: true, message: '请选择服务商' }]}>
                  <Select placeholder="请选择">
                    {isvList.map((item: any) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  label="集成应用"
                  name="integratedAppId"
                  rules={[{ required: true, message: '请选择集成应用' }]}
                >
                  <Select
                    placeholder="请选择"
                    style={{ width: '100%' }}
                    options={integratedAppList}
                    filterOption={(inputValue, option) => {
                      return (option?.label ?? '').includes(inputValue);
                    }}
                  />
                </FormItem>
              </Col>
            </>
          )}
          <Col span={24}>
            <FormItem label="工具简介" name="description" rules={[{ max: 200, message: '最多输入200个字符' }]}>
              <Input.TextArea placeholder="请输入" />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem label="小卡片" name="cardSupport" initialValue={0} rules={[{ required: true }]}>
              <Radio.Group
                options={cardOptions}
                onChange={e => {
                  setCardFlag(e.target.value ? true : false);
                }}
              />
            </FormItem>
          </Col>
          {cardFlag && (
            <>
              <Col span={24}>
                <FormItem
                  label="小尺寸"
                  name="smallCardPic"
                  rules={[{ required: true }]}
                  help="支持扩展名:  .png   .jpg,  尺寸328*148px ，小于1M"
                >
                  <AttachmentUpload accept=".jpg,.png" tip={false} maxCount={1} beforeUpload={beforeUploadSmallImg} />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  label="中尺寸"
                  name="middleCardPic"
                  rules={[{ required: true }]}
                  help="支持扩展名:  .png   .jpg,  尺寸328*328px ，小于1M"
                >
                  <AttachmentUpload accept=".jpg,.png" tip={false} maxCount={1} beforeUpload={beforeUploadMiddleImg} />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  label="大尺寸"
                  name="largeCardPic"
                  rules={[{ required: true }]}
                  help="支持扩展名:  .png   .jpg,  尺寸686*328px ，小于1M"
                >
                  <AttachmentUpload accept=".jpg,.png" tip={false} maxCount={1} beforeUpload={beforeUploadBigImg} />
                </FormItem>
              </Col>
            </>
          )}
        </Row>
      </Form>
      {openCategory && <ToolCategoryModal setOpenCategory={setOpenCategory} setRefreshCategory={setRefreshCategory} />}
      {modalContextHolder}
    </>
  );
});

export default ToolForm;
