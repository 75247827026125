import { PageRequest, PageResponse } from './page';
import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { Key } from 'react';

// 数据生成器
export interface DataGeneratorPageRequest extends PageRequest {
  name?: string;
  status?: string;
}

export interface DataGeneratorPageRes {
  id: number;
  name: string;
  status: number;
  beginTime: string;
  endTime: string;
  period: number;
  nodeCount: number;
  tenantMcid: string;
  tenantName: string;
  updateCropUserId: number;
  updateCropUserName: string;
  updateTime: string;
}
// 数据生成器分页
export function getDataGeneratorPage(query: DataGeneratorPageRequest): Promise<PageResponse<DataGeneratorPageRes>> {
  return fetch(`/api/dataGenerator/page?${qs.stringify(query, { indices: false })}`);
}

// 删除数据生成器
export function deleteDataGenerator(id: Key) {
  return fetch(`/api/dataGenerator/${id}`, {
    method: 'DELETE',
  });
}

export enum DataGeneratorStatus {
  ENABLE = 1,
  DISABLE = 2,
}
export const dataGeneratorStatusFormat = {
  [DataGeneratorStatus.ENABLE]: '启用',
  [DataGeneratorStatus.DISABLE]: '禁用',
};

// 修改模板状态
export function updateDataGeneratorStatus(id: number, status: DataGeneratorStatus) {
  return fetch(`/api/dataGenerator/${id}/status`, {
    method: 'PUT',
    body: JSON.stringify({ status }),
  });
}

// 复制
export function copyDataGenertorForm(id: Key) {
  return fetch(`/api/dataGenerator/${id}/copy`, {
    method: 'POST',
  });
}

export interface StaffListByMcidReq {
  mcid: string;
}

export interface StaffListByMcidRes {
  id: number;
  mcid: string;
  muid: string;
  status: number;
  staffCode: string;
  workEmail: string;
  workWechat: string;
  workQq: string;
  createTime: string;
  updateTime: string;
  roleName: string;
}

// 获取根据mcid获取staff列表
export function getStaffListByMcid(query: StaffListByMcidReq): Promise<StaffListByMcidRes[]> {
  return fetch(`/api/customer/staffs?${qs.stringify(query, { indices: false })}`);
}

export interface FormListRes {
  id: number;
  name: string;
  description: string;
  formTemplateId: number;
  formTemplateName: string;
  updateCropUserId: number;
  updateCropUserName: string;
  updateTime: string;
}

// 获取表单列表
export function getFormList(): Promise<FormListRes[]> {
  return fetch(`/api/form/list`);
}

export interface DataGeneratorNode {
  id?: number;
  name?: string;
  intervalTime?: number;
  intervalVolatility?: number;
  sequence?: number;
  formId?: number;
  formName?: string;
}

export interface DataGeneratorRes {
  staffId: number;
  userStaffId: number;
  id: number;
  name: string;
  status: number;
  workCalendar: number;
  beginTime: string;
  endTime: string;
  period: number;
  tenantMcid: string;
  tenantName: string;
  createCropUserId: number;
  updateCropUserId: number;
  createCropUserName: string;
  updateCropUserName: string;
  createTime: string;
  updateTime: string;
  nodes: DataGeneratorNode[];
}

// 获取生成器详情
export function getDataGenertorInfo(id: Key): Promise<DataGeneratorRes> {
  return fetch(`/api/dataGenerator/${id}`);
}

export interface AddDataGenertorReq {
  saveRun: boolean;
  name: string;
  workCalendar?: number;
  beginTime: string;
  endTime: string;
  period: number;
  tenantMcid: string;
  userStaffId: number;
  nodes: AddDataGeneratorNode[];
}

export interface AddDataGeneratorNode {
  name?: string;
  intervalTime?: number;
  intervalVolatility?: number;
  sequence?: number;
  formId?: number;
}

// 新增数据生成器
export function createDataGenertor(data: AddDataGenertorReq) {
  return fetch(`/api/dataGenerator`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

// 修改数据生成器
export function updateDataGenertor(id: Key, data: AddDataGenertorReq) {
  return fetch(`/api/dataGenerator/${id}`, {
    method: 'PUT',
    body: JSON.stringify(data),
  });
}
