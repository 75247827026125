import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Spin, Tabs } from 'antd';
import Prompt from '../../../components/Prompt';
import { Filter, Paging, useSearchParams, Wrapper } from '@maxtropy/components';
import styles from './index.module.scss';
import { AppStatus, AppStatusDisplay } from '../../../api/openPlatform-const';
import AppList from './AppList';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import {
  ApplicationProps,
  queryApplicationListPaged,
  queryIsvList,
  QueryIsvListProps,
} from '../../../api/openPlatform-application';
import { isNil } from 'lodash-es';
import { PlusOutlined } from '@ant-design/icons';
import { PermissionsType } from '@/common/permissionsConst';
import { useMgmtPermissions } from '@/components/Layout/hooks';

interface AppListProps {}

interface SearchParams {
  name?: string;
  independentSoftwareVendorId?: number;
}

const routes = [{ name: '开发者平台' }, { name: '应用开发开放平台' }, { name: '集成应用列表' }];

const AppListPage: React.FC<AppListProps> = props => {
  const [form] = Form.useForm();
  const [serverProviderList, setServerProviderList] = useState<QueryIsvListProps[]>([]);
  const pagingInfo = useSearchParams<SearchParams>(50);
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;
  const [activeKey, setActiveKey] = useState(AppStatus.ENABLE);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const serviceId = urlSearchParams.get('serviceId') ?? undefined;
  const [dataSource, setDataSource] = useState<ApplicationProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [xx, forceUpdate] = useReducer(x => x + 1, 0);

  const permissions = useMgmtPermissions();
  const navigate = useNavigate();

  useEffect(() => {
    const initServiceId = isNil(serviceId) ? undefined : Number(serviceId);
    form.setFieldsValue({ independentSoftwareVendorId: initServiceId });
    searchParams.independentSoftwareVendorId = initServiceId;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items = [
    {
      label: AppStatusDisplay[AppStatus.ENABLE],
      key: AppStatus.ENABLE as unknown as string,
      children: (
        <>
          <Spin spinning={loading}>
            <AppList dataSource={dataSource} update={forceUpdate} />
          </Spin>
        </>
      ),
    },
    {
      label: AppStatusDisplay[AppStatus.DISABLE],
      key: AppStatus.DISABLE as unknown as string,
      children: (
        <>
          <Row>
            <Prompt title="已禁用的APP，其token失效，且无法再调用接口，即使重新启用，也需要生成新的token才能使用" />
          </Row>
          <Spin spinning={loading}>
            <AppList dataSource={dataSource} update={forceUpdate} />
          </Spin>
        </>
      ),
    },
  ];
  // 查询服务商list
  useEffect(() => {
    queryIsvList().then(setServerProviderList);
  }, []);

  // 分页查询应用列表
  useEffect(() => {
    setLoading(true);
    queryApplicationListPaged({
      name: searchParams.name,
      independentSoftwareVendorId: searchParams.independentSoftwareVendorId,
      count: searchParams.pageSize,
      offset: searchParams.pageOffset,
      status: activeKey,
    })
      .then(res => {
        setTotalCount(res.total);
        setDataSource(res.records);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, activeKey, xx]);

  const serverProviderOptions = useMemo(() => {
    if (serverProviderList && serverProviderList.length !== 0) {
      return serverProviderList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [serverProviderList]);

  const onReset = () => {
    form.setFieldsValue({
      name: undefined,
      independentSoftwareVendorId: undefined,
    });
    reset();
  };

  const filters = (
    <Filter
      form={form}
      onFinish={(val: SearchParams) => {
        finish({ ...val });
      }}
      onReset={onReset}
    >
      <>
        <Col span={6}>
          <Form.Item name="independentSoftwareVendorId" label="服务商">
            <Select placeholder="请选择服务商" options={serverProviderOptions} showSearch optionFilterProp="label" />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="name" label="应用名称">
            <Input placeholder="请输入应用名称查询" />
          </Form.Item>
        </Col>
      </>
    </Filter>
  );

  return (
    <Wrapper routes={routes} className={classnames('open-platform-common-wrapper', styles.wrapper)} filters={filters}>
      {permissions?.includes(PermissionsType.B_CREATEAPP) && (
        <>
          <Button type="primary" onClick={() => navigate('/open-platform/open-platform/app/create')}>
            <PlusOutlined /> 创建应用
          </Button>
          <Prompt title="创建应用以获得应用密钥等" style={{ marginLeft: 20, display: 'inline-block' }} />
        </>
      )}
      <Tabs
        defaultActiveKey={AppStatus.ENABLE.toString()}
        onChange={activeKey => setActiveKey(activeKey as unknown as AppStatus)}
        items={items}
        style={{ paddingTop: 24 }}
      />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default AppListPage;
