import { Wrapper, useAsync } from '@maxtropy/components';
import { Col, Form, Input, Tabs } from 'antd';
import React, { useState } from 'react';
import Filter from '@/shared/components/Filter';
import styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import TenantList from './TenantList';
import TemplateList from './TemplateList';
import CascadingMultipleSelector from '@/shared/components/CascadingMultipleSelector';
import { formatOptionData, getAllChild } from '@/shared/components/CascadingMultipleSelector/utils';
import { getDeviceTypeData } from '../../api/attribute';

import qs from 'qs';
import { useMgmtPermissions } from '@/components/Layout/hooks';

const routes = [{ name: 'Iot配置' }, { name: '设备信息模板库' }, { name: '模板分配' }];

export interface ParamsProps {
  tenantCode?: string;
  name?: string;
  uscc?: string;
  deviceType?: Array<number> | undefined;
}

interface FilterParamsProps {
  deviceType: Array<Array<number>> | undefined;
  tenantCode?: string;
  name?: string;
  uscc?: string;
}

const DeviceDistribution: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const tabs = urlSearchParams.get('tabs') || '1';
  const [tabsKey, setTabsKey] = useState<string>(tabs);
  const [searchParams, setSearchParams] = useState<ParamsProps>();

  const permission = useMgmtPermissions();

  const deviceTypeData = useAsync(getDeviceTypeData);

  const onFinish = (val: FilterParamsProps) => {
    const deviceType = getAllChild(val.deviceType, formatOptionData(deviceTypeData));
    setSearchParams({
      ...val,
      deviceType,
    });
    const paramsMap = new Map<string, string>();
    Object.entries(val).forEach(([key, value]) => {
      value && paramsMap.set(key, value);
    });
    navigate(`?${qs.stringify(Object.fromEntries(paramsMap))}`);
  };

  const onReset = () => {
    const params = {
      tenantCode: undefined,
      name: undefined,
      uscc: undefined,
      deviceType: undefined,
    };
    setSearchParams(params);
    navigate(`?`);
  };

  const filters = (
    <Filter form={form} onFinish={val => onFinish(val as FilterParamsProps)} onReset={onReset}>
      {tabsKey === '1' ? (
        <>
          <Col span={5}>
            <Form.Item label="租户CODE" name="tenantCode">
              <Input placeholder="输入完整租户code" />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="租户名称" name="name">
              <Input placeholder="输入全部/部分租户名称进行搜索" />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="统一社会信用代码" name="uscc">
              <Input placeholder="输入完整代码" />
            </Form.Item>
          </Col>
        </>
      ) : (
        <>
          <Col span={5}>
            <Form.Item name="name" label="编号/名称">
              <Input placeholder={'请输入编号或名称查询'} />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item name="deviceType">
              <CascadingMultipleSelector
                labels={deviceTypeData?.aliasName}
                data={formatOptionData(deviceTypeData)}
                mode={['single']}
              />
            </Form.Item>
          </Col>
        </>
      )}
    </Filter>
  );

  const onChangeTabs = (v: string) => {
    form.resetFields();
    onReset();
    setTabsKey(v);
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.delete('tabs');
    urlSearchParams.append('tabs', v);
    navigate(`?${urlSearchParams.toString()}`);
  };

  return (
    <Wrapper routes={routes} filters={filters}>
      <Tabs
        defaultActiveKey={tabs}
        tabBarStyle={{
          marginBottom: 3,
        }}
        tabBarGutter={43}
        className={styles.tabs}
        onChange={v => onChangeTabs(v)}
        destroyInactiveTabPane
      >
        <Tabs.TabPane tab="租户列表" key="1">
          <TenantList searchParams={searchParams} tabsKey={tabsKey} permission={permission} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="模板列表" key="2">
          <TemplateList searchParams={searchParams} tabsKey={tabsKey} permission={permission} />
        </Tabs.TabPane>
      </Tabs>
    </Wrapper>
  );
};

export default DeviceDistribution;
