import {
  CheckCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FileOutlined,
  ScheduleOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { Card, Modal, Tag } from 'antd';
import styles from '../index.module.scss';
import imageInfo from '../../../assets/image/no-cover.png';
import icon from '../icons/appstore-filled.png';
import { RulesSetPageGetResponse } from '../../../ytt/types/rulesSet/page';
import { RulesGroupStatus, rulesGroupStatusDisplay, RulesStatus } from '../../../api/type';
import { useNavigate } from 'react-router-dom';
import { apiRulesSetIdDelete } from '../../../ytt/types/rulesSet/id';
import { numberToString } from '../../TemplateManage/utils';
import { apiRulesSetIdStatusPut } from '../../../ytt/types/id/status';
import { apiRulesSetIdCopysPost } from '../../../ytt/types/id/copys';
type Data = Exclude<RulesSetPageGetResponse['list'], undefined>[number];
interface Iprops {
  data?: Data;
  copy?: () => void;
  edit?: () => void;
  delete?: () => void;
  export?: () => void;
  stopAndOpen?: () => void;
}
const ListCard = ({ data, ...rest }: Iprops) => {
  const [modalApi, modalContextHolder] = Modal.useModal();
  // 删除
  const delItem = () => {
    if (!numberToString(data?.id)) return;
    apiRulesSetIdDelete({ id: numberToString(data?.id)! }).then(() => {
      rest.delete?.();
    });
  };

  // 停用
  const stopItem = (status?: number) => {
    if (!numberToString(data?.id)) return;
    apiRulesSetIdStatusPut({
      id: numberToString(data?.id)!,
      status: status === RulesStatus.STOP ? RulesStatus.ENABLE : RulesStatus.STOP,
    }).then(() => {
      rest.stopAndOpen?.();
    });
  };

  const navigate = useNavigate();
  return (
    <>
      <div className={styles.mask_outer}>
        <div className={styles.mask}>
          <div className={styles.icons}>
            <div
              className={styles.icon_block}
              onClick={() => navigate(`/data-tools/shaping/rulesmanage/detail/${data?.id}`)}
            >
              <FileOutlined />
              数据模拟
            </div>
            <div
              className={styles.icon_block}
              onClick={() => navigate(`/data-tools/shaping/rulesmanage/supplement/${data?.id}`)}
            >
              <ScheduleOutlined />
              数据补录
            </div>
            {data?.status === RulesStatus.DRAFT ? null : (
              <div
                className={styles.icon_block}
                onClick={() => {
                  modalApi.confirm({
                    title: `您是否${data?.status === RulesStatus.STOP ? '启用' : '停用'}此规则集?`,
                    icon: data?.status === RulesStatus.STOP ? <CheckCircleOutlined /> : <ExclamationCircleOutlined />,
                    onOk() {
                      stopItem(data?.status);
                    },
                  });
                }}
              >
                {data?.status === RulesStatus.STOP ? <CheckCircleOutlined /> : <StopOutlined />}
                {data?.status === RulesStatus.STOP ? '启用' : '停用'}
              </div>
            )}

            <div
              className={styles.icon_block}
              onClick={() => {
                modalApi.confirm({
                  title: '您是否确认复制此模板?',
                  icon: <ExclamationCircleOutlined />,
                  onOk() {
                    apiRulesSetIdCopysPost({
                      id: numberToString(data?.id)!,
                    }).then(_ => {
                      rest.copy?.();
                    });
                  },
                });
              }}
            >
              <CopyOutlined />
              复制
            </div>
            <div
              className={styles.icon_block}
              onClick={() => {
                navigate(`/data-tools/shaping/rulesmanage/rules/${data?.id}`);
              }}
            >
              <EditOutlined />
              编辑
            </div>
            {/* <div
              className={styles.icon_block}
              onClick={() => {
                // props.export?.();
                modalApi.info({ icon: null, content: <DataExport ruleSetId={data?.id!} /> });
              }}
            >
              <EyeOutlined />
              预览
            </div> */}
            {/* <div
              className={styles.icon_block}
              onClick={() => {
                // 绑定设备提示: 本规则集已绑定7个设备，是否确认删除
                // 未绑定设备: 您是否确认删除本规则集
                modalApi.confirm({
                  title: `本规则集已绑定${data?.deviceCount}个设备，是否确认删除?`,
                  icon: <ExclamationCircleOutlined />,
                  onOk() {
                    delItem();
                  },
                });
              }}
            >
              <DeleteOutlined />
              删除
            </div> */}
          </div>
        </div>
        <Card
          title={
            <>
              <div className={styles.head_title}>
                <div className={styles.head_title_left}>{data?.name ?? '--'}</div>
                {data?.status !== +RulesGroupStatus.ENABLE && (
                  <Tag style={{ marginLeft: 10 }}>
                    {data?.status ? rulesGroupStatusDisplay[data.status.toString() as RulesGroupStatus] : '-'}
                  </Tag>
                )}
              </div>
              <div className={styles.sub_title}>{data?.tenantName}</div>
            </>
          }
          extra={
            <>
              <div className={styles.head_suffix}>
                已绑定<span>{data?.deviceCount}</span>个设备
              </div>
              <div className={styles.sub_suffix}>
                <div className={styles.icon}>
                  <img src={icon} alt="no" />
                </div>
                <div>{data?.sceneName + '-' + data?.dataPropertyGroupName}</div>
              </div>
            </>
          }
        >
          <div className={styles.inner_content}>
            <div className={styles.no_cover}>
              <img src={imageInfo} alt="nodata" />
              <p>暂未生成封面</p>
            </div>
          </div>
        </Card>
      </div>
      {modalContextHolder}
    </>
  );
};

export default ListCard;
