import { Checkbox, Col, Form, Input, Radio } from 'antd';
import React from 'react';
import { AlarmDataStatus, AlarmDataStatusDisplay, AlarmDataTypeDisplay } from '@/shared/types';

import styles from '../../../List/index.module.scss';

interface Props {}
const TripartiteServerAlarmChannelForm: React.FC<Props> = () => {
  return (
    <>
      <Col span={8} className={styles.col}>
        <Form.Item
          label="三方报警码"
          name="thirdAlarmCode"
          rules={[
            { required: true, message: '请输入三方报警码' },
            { max: 20, message: '最多输入二十个字' },
          ]}
        >
          <Input placeholder="请输入" />
        </Form.Item>
      </Col>

      <Col span={8} className={styles.col}>
        <Form.Item
          label="是否具备报警数据"
          name="hasAlamData"
          rules={[
            {
              required: true,
              message: '请选择是否具备报警数据',
            },
          ]}
        >
          <Radio.Group
            options={Object.entries(AlarmDataStatusDisplay).map(([k, v]) => ({
              label: v,
              value: +k,
            }))}
          />
        </Form.Item>
      </Col>

      <Col span={8} className={styles.col}>
        <Form.Item noStyle dependencies={['hasAlamData']}>
          {({ getFieldValue }) => {
            const alarmDataStatus = getFieldValue('hasAlamData');
            if (alarmDataStatus === AlarmDataStatus.YES) {
              return (
                <Form.Item label="报警数据" name="alamData" rules={[{ required: true, message: '请选择报警数据' }]}>
                  <Checkbox.Group
                    options={Object.entries(AlarmDataTypeDisplay).map(([k, v]) => ({
                      label: v,
                      value: +k,
                    }))}
                  />
                </Form.Item>
              );
            }
            return null;
          }}
        </Form.Item>
      </Col>
    </>
  );
};

export default TripartiteServerAlarmChannelForm;
