import React, { useEffect, useState } from 'react';
import { Wrapper, Table, EllipsisSpan, usePaging, Paging, useUpdate } from '@maxtropy/components';
import { Form, Input, Select, Col, Button, Space, Divider, Popconfirm } from 'antd';
import Filter from '@/shared/components/Filter';
import { List, Status, StatusDisplay, Query, StatusBtnDisplayMap } from './types';
import { useCarbonEmissions } from './useCarbonEmissions';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

const otherColumns = [
  {
    title: '排放过程名称',
    dataIndex: 'name',
    render: (value: string) => <EllipsisSpan value={value} />,
  },
  {
    title: '所属行业',
    dataIndex: 'industryName',
    render: (value: string) => <EllipsisSpan value={value} />,
  },
  {
    title: '状态',
    dataIndex: 'state',
    render: (value: Status) => <EllipsisSpan value={StatusDisplay[value]} />,
  },
];

const routes = [{ name: '企业碳盘查' }, { name: '排放过程管理' }];

interface Props {}
const CarbonEmissions: React.FC<Props> = () => {
  const [form] = Form.useForm();
  const [state, update] = useUpdate();

  const navigate = useNavigate();

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [searchParams, setSearchParams] = useState<Query>();

  const { tableData, loading, industryOptions, getTableData, handleChangeStatus, handleDelete, fetchIndustryOptions } =
    useCarbonEmissions(update);

  const onFinish = (values: Query) => {
    setSearchParams(values);
    setPageOffset(1);
  };

  const onReset = () => {
    form.resetFields();
    setSearchParams(undefined);
    setPageOffset(1);
  };

  useEffect(() => {
    fetchIndustryOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTableData(searchParams, pageOffset, pageSize, setTotalCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, state, pageOffset, pageSize, setTotalCount]);

  const columns = [
    ...otherColumns,
    {
      title: '操作',
      width: 200,
      render: (record: List) => {
        const { state } = record;
        const btnDisabled = state === Status.ENABLE;
        return (
          <>
            <Space size={16}>
              <Button
                type="link"
                onClick={() => navigate(`/dualCarbon/carbonEmissions/process/basicInfo/detail/${record.id}`)}
              >
                详情
              </Button>
              <Button type="link" onClick={() => handleChangeStatus(record)}>
                {StatusBtnDisplayMap[state as Status]}
              </Button>
              <Button
                type="link"
                onClick={() => navigate(`/dualCarbon/carbonEmissions/process/basicInfo/edit/${record.id}`)}
              >
                编辑
              </Button>
              <Popconfirm
                title="您是否删除此排放过程?"
                okText="是"
                cancelText="否"
                onConfirm={() => handleDelete(record)}
                disabled={btnDisabled}
              >
                <Button type="link" disabled={btnDisabled}>
                  删除
                </Button>
              </Popconfirm>
            </Space>
          </>
        );
      },
    },
  ];

  const filters = (
    <Filter form={form} onFinish={onFinish} onReset={onReset}>
      <Col span={6}>
        <Form.Item label="所属行业" name="industryId">
          <Select placeholder="请选择所属行业" options={industryOptions} allowClear />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="排放过程名称" name="name">
          <Input placeholder="请输入排放过程名称" allowClear />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item label="状态" name="state">
          <Select
            placeholder="全部"
            options={Object.entries(StatusDisplay).map(([k, v]) => ({ label: v, value: Number(k) }))}
          ></Select>
        </Form.Item>
      </Col>
    </Filter>
  );

  return (
    <>
      <Wrapper routes={routes} filters={filters}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          style={{ marginBottom: '15px' }}
          onClick={() => navigate('/dualCarbon/carbonEmissions/process/basicInfo/create')}
        >
          新建排放过程
        </Button>
        <Table columns={columns} rowKey="id" loading={loading} pagination={false} dataSource={tableData} />
        <Paging pagingInfo={pagingInfo} />
      </Wrapper>
    </>
  );
};

export default CarbonEmissions;
