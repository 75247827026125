import { BreadcrumbItemProps } from '@maxtropy/components';

export interface TabsBaseProps {
  onBreacrumbChange?: (routes: BreadcrumbItemProps[]) => void;
  updateFn?: () => void;
}

export function transformSpace(value: string) {
  return value && value.replaceAll(' ', '');
}
