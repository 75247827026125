import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Select } from 'antd';
import { EllipsisSpan, Paging, Table, usePaging } from '@maxtropy/components';
import { useCarbonEmissions } from './../List/useCarbonEmissions';
import { Query } from '../List/types';
import { GuideClassificationProcessDto, ProcessModalResType } from './create';

const columns = [
  {
    title: '排放过程名称',
    dataIndex: 'name',
    render: (value: string) => <EllipsisSpan value={value} />,
  },
  {
    title: '所属行业',
    dataIndex: 'industryName',
    render: (value: string) => <EllipsisSpan value={value} />,
  },
];

interface Props {
  value?: GuideClassificationProcessDto[];
  setCurrentSelectRows: (rows: GuideClassificationProcessDto[]) => void;
  allProcessIds: (number | undefined)[];
}
const ProcessModal: React.FC<Props> = ({ value, allProcessIds, setCurrentSelectRows }) => {
  const [form] = Form.useForm();

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [searchParams, setSearchParams] = useState<Query>();
  const [selectedRows, setSelectedRows] = useState<GuideClassificationProcessDto[]>(value || []);

  const { loading, industryOptions, processTableData, fetchIndustryOptions, getProcessTableData } =
    useCarbonEmissions();

  const onFinish = (values: Query) => {
    setSearchParams(values);
    setPageOffset(1);
  };

  const rowSelection = {
    onChange: (_selectedRowKeys: React.Key[], rows: ProcessModalResType[]) => {
      setSelectedRows(rows);
      setCurrentSelectRows(rows);
    },
    getCheckboxProps: (record: ProcessModalResType) => ({
      disabled: allProcessIds.includes(record.id),
    }),
    selectedRowKeys: selectedRows?.map(i => i.id).filter((id): id is number => id !== undefined),
  };

  useEffect(() => {
    fetchIndustryOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getProcessTableData(searchParams, pageOffset, pageSize, setTotalCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, pageOffset, pageSize, setTotalCount]);

  return (
    <>
      <Form form={form} onFinish={onFinish} style={{ marginTop: '10px' }}>
        <Row justify="space-between">
          <Col span={10}>
            <Form.Item name="industryId" label="所属行业">
              <Select placeholder="请选择" options={industryOptions}></Select>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="name" label="排放过程名称">
              <Input placeholder="请输入排放过程名称" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Table
        rowKey="id"
        columns={columns}
        loading={loading}
        dataSource={processTableData}
        rowSelection={rowSelection}
        pagination={false}
      />
      <Paging pagingInfo={pagingInfo} />
    </>
  );
};

export default ProcessModal;
