import React, { FC, useEffect, useState } from 'react';
import { BatchDeviceTemplateEdgeInfo, getBatchDeviceTemplateEdgeInfo } from '../../../api/batch';
import { getEdgeTemplateDetail, getPointsByTemplateVersionId } from '../../../api/template';
import { EdgeDeviceTemplatePoint } from '@/shared/components/EdgeDevicePointInfo';
import { MockingbirdDeviceProps } from '@/shared/components/DataAcquisitionForm/types';
import SharedEdgeTemplateFormDetail from '@/shared/components/DataAcquisitionForm/Detail';

interface EdgeTemplateDetailProps {
  id: string;
}

type DataType = BatchDeviceTemplateEdgeInfo &
  Omit<MockingbirdDeviceProps, 'driveType'> & {
    edgeDeviceTemplateName?: string;
    edgeDeviceTemplateVersion?: number;
    edgeDeviceTemplateLatestVersion?: number;
    edgeDeviceTemplateVersionRemark?: string;
  };

const EdgeTemplateDetail: FC<EdgeTemplateDetailProps> = ({ id }) => {
  const [data, setData] = useState<DataType>();
  const [templateVersionId, setTemplateVersionId] = useState<number>();
  const [pointsData, setPointData] = useState<EdgeDeviceTemplatePoint[]>([]);

  useEffect(() => {
    if (id) {
      getBatchDeviceTemplateEdgeInfo(id).then(res => {
        if (res) {
          setData({ ...res, ...res.parameters });
          if (res.templateVersionId) {
            setTemplateVersionId(res.templateVersionId);
          }
        }
      });
    }
  }, [id, setTemplateVersionId]);

  useEffect(() => {
    if (templateVersionId) {
      getPointsByTemplateVersionId(templateVersionId).then(res => {
        setPointData((res ?? []) as EdgeDeviceTemplatePoint[]);
      });
    }
  }, [templateVersionId]);

  useEffect(() => {
    if (templateVersionId) {
      getEdgeTemplateDetail(templateVersionId).then(res => {
        if (res) {
          setData({
            ...data,
            edgeDeviceTemplateName: res.name,
            edgeDeviceTemplateVersion: res.version,
            edgeDeviceTemplateVersionRemark: res.versionRemark,
            edgeDeviceTemplateLatestVersion: res.latestVersion,
            deviceTypeId: res.deviceTypeId,
          } as DataType);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateVersionId, setData]);

  return (
    <>
      {data && (
        <>
          <SharedEdgeTemplateFormDetail data={data} dataSource={pointsData} />
        </>
      )}
    </>
  );
};

export default EdgeTemplateDetail;
