import { ComponentType } from 'react';
import { IotProtocolType } from '../../types';
import MockingbirdFormDisplay from './mockingbird/FormDisplay';
import { MockingbirdDeviceProps } from './types';

export interface EdgeDeviceData extends MockingbirdDeviceProps {
  edgeGatewayId?: number;
  deviceId?: number;
  deviceTypeId?: number;
  iotProtocol: IotProtocolType;
  edgeDeviceTemplateVersion?: number;
  edgeDeviceTemplateName?: string;
  edgeDeviceTemplateLatestVersion?: number;
  edgeDeviceTemplateVersionRemark?: string;
}

export interface FormItemsDisplayProps {
  data?: EdgeDeviceData;
}

export const formItemsDisplayByType: Partial<
  Record<IotProtocolType, ComponentType<FormItemsDisplayProps> | undefined>
> = {
  [IotProtocolType.ESTUN]: undefined,
  [IotProtocolType.MOCKINGBIRD]: MockingbirdFormDisplay,
};
