import { fetch } from '@maxtropy/components';
import qs from 'qs';

export interface Tenant {
  mcid: string;
  name: string;
}

interface GetRootRequest {
  value?: string;
  check?: boolean;
}
export const getRoot = (params: GetRootRequest) =>
  fetch<Tenant[]>(`/api/customer/getRoot?${qs.stringify(params, { indices: false })}`);

export interface Organization {
  mcid: string;
  name: string;
  hasPermission: boolean;
}

export interface OrganizationResponse {
  data: Organization;
  children: OrganizationResponse[];
}

export const getOrganization = (mcid: string) => fetch<OrganizationResponse>(`/api/customer/organization?mcid=${mcid}`);
