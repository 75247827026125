import React, { useEffect, useState } from 'react'; //useMemo
import { Link } from 'react-router-dom';
import { Badge, Col, ConfigProvider, Empty, Form, Input, message, Select, Space, Modal, Button, Tooltip } from 'antd';
import { Wrapper, Filter, Table, Paging, usePaging, EllipsisSpan, useUpdate } from '@maxtropy/components';
import styles from './index.module.scss';
import {
  batchDisableTenantUserList,
  disableCustomer,
  enableCustomer,
  freezeCustomer,
  getTenantUserList,
  resetCustomerPassword,
  TenantUserListRequest,
  TenantUserListResponse,
  unlockCustomerUser,
} from '../../../../api/cc-customer';
import dayjs from 'dayjs';
import { UserStatus, UserStatusDisplay } from '../../../../api/cc-const';
import { BadgeStatusDisplayColor } from '../TenantEmployeeManagement';
import { CopyOutlined, PlusOutlined } from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';

const routes = [{ name: '租户管理' }, { name: '租户组织权限和账户' }, { name: '租户用户管理' }];

const tenantUserColumns = [
  {
    title: '用户ID',
    dataIndex: 'humanCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '用户登录账号',
    dataIndex: 'username',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '用户姓名',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '员工手机',
    dataIndex: 'cellphone',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (value: UserStatus, record: TenantUserListResponse) => {
      return <Badge color={BadgeStatusDisplayColor[value]} text={UserStatusDisplay[value]} />;
    },
  },
  {
    title: '是否锁定',
    dataIndex: 'lockStatus',
    ellipsis: { showTitle: true },
    render: (value: boolean) => `${value ? '已' : '未'}锁定`,
  },
  {
    title: '锁定时间',
    dataIndex: 'lockTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : v} />,
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
  {
    title: '最后修改时间',
    dataIndex: 'updateTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
  },
];

export type FilterParams = Omit<TenantUserListRequest, 'count' | 'offset'>;

const TenantUserList: React.FC = () => {
  const [messageApi, messageContextHolder] = message.useMessage();
  const [modalApi, modalContextHolder] = Modal.useModal();
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  // const [selectedRowRecords, setSelectedRowRecords] = useState<TenantUserListResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [opened, setOpened] = useState<boolean>(false);
  const [resultModalOpened, setResultModalOpened] = useState<boolean>(false);
  const [resultModalChildren, setResultModalChildren] = useState<React.ReactNode>();
  const [title, setTitle] = useState<string>();
  const [children, setChildren] = useState<React.ReactNode>();
  const [dataSource, setDataSource] = useState<TenantUserListResponse[]>([]);
  const [operateCustomer, setOperateCustomer] = useState<TenantUserListResponse>();
  const [operate, setOperate] = useState<number>();
  const [searchParams, setSearchParams] = useState<FilterParams>();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  const [updateState, update] = useUpdate();

  const loadData = (count: number, offset: number) => {
    if (!searchParams) {
      return;
    }
    setLoading(true);
    getTenantUserList({ ...searchParams, count, offset }).then(res => {
      if (res) {
        setTotalCount(res.total);
        setDataSource(
          res.contents.map(item => {
            return {
              ...item,
            };
          })
        );
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData(pageSize, pageOffset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, pageOffset, searchParams, updateState]);

  const onSelectChange = (keys: any[]) => {
    setSelectedRowKeys(keys);
  };

  const onFilter = (query: FilterParams) => {
    setPageOffset(1);
    setSearchParams(query);
  };

  const onReset = () => {
    setPageOffset(1);
    setSearchParams({});
  };

  const [searchForm] = Form.useForm();

  const filters = (
    <Filter<FilterParams>
      form={searchForm}
      onFinish={onFilter}
      onReset={onReset}
      hasClear
      collapseItems={
        <>
          <Col span={4}>
            <Form.Item name="status" label="状态">
              <Select placeholder="请选择状态">
                <Select.Option value={UserStatus.ENABLE}>{UserStatusDisplay[UserStatus.ENABLE]}</Select.Option>
                <Select.Option value={UserStatus.DISABLE}>{UserStatusDisplay[UserStatus.DISABLE]}</Select.Option>
                <Select.Option value={UserStatus.FREEZE}>{UserStatusDisplay[UserStatus.FREEZE]}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="name" label="用户姓名">
              <Input placeholder="请输入用户姓名的全部/部分" />
            </Form.Item>
          </Col>
        </>
      }
    >
      <>
        <Col span={4}>
          <Form.Item name="humanCode" label="用户ID">
            <Input placeholder="请输入用户ID" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="username" label="用户登录账号">
            <Input placeholder="请输入用户登录账号" />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name="cellphone" label="手机号">
            <Input placeholder="请输入完整用户手机号" />
          </Form.Item>
        </Col>
      </>
    </Filter>
  );

  const titleArray = ['启用用户', '禁用用户及其所拥有的账号', '冻结用户及其所拥有的账号', '重置密码'];

  const showModal = (s: number, v: TenantUserListResponse) => {
    setOpened(true);
    setOperate(s);
    setOperateCustomer(v);
    setTitle(titleArray[s]);
    if (s === 0) {
      setChildren(
        <p>
          即将启用用户{v.name}(用户ID：{v.humanCode})，点击确定以执行该操作
        </p>
      );
    } else if (s === 1) {
      setChildren(
        <p>
          即将禁用用户{v.name}(用户ID：{v.humanCode})，点击确定以执行该操作
        </p>
      );
    } else if (s === 2) {
      setChildren(
        <p>
          即将冻结用户{v.name}(用户ID：{v.humanCode})，点击确定以执行该操作
        </p>
      );
    } else if (s === 3) {
      setChildren(
        <p>
          即将重置用户{v.name}(用户ID：{v.humanCode})密码，点击确定以执行该操作
        </p>
      );
    }
  };

  const statusButtonArray = (v: TenantUserListResponse) => [
    <>
      <Button style={{ padding: 0 }} type="link" onClick={() => showModal(1, v)}>
        禁用
      </Button>
      <Button style={{ padding: 0 }} type="link" onClick={() => showModal(2, v)}>
        冻结
      </Button>
    </>,
    <>
      <Button style={{ padding: 0 }} type="link" onClick={() => showModal(0, v)}>
        启用
      </Button>
      <Button style={{ padding: 0 }} type="link" onClick={() => showModal(2, v)}>
        冻结
      </Button>
    </>,
    <>
      <Button style={{ padding: 0 }} type="link" onClick={() => showModal(0, v)}>
        启用
      </Button>
      <Button style={{ padding: 0 }} type="link" onClick={() => showModal(1, v)}>
        禁用
      </Button>
    </>,
  ];

  const unlockFn = (record: TenantUserListResponse) => {
    unlockCustomerUser(record.muid).then(res => {
      if (res) {
        modalApi.success({
          content: `用户${record.username}账号已解锁。`,
          onOk: () => {
            update();
          },
          onCancel: () => {
            update();
          },
        });
      }
    });
  };

  const renderAction = (v: TenantUserListResponse) => {
    return (
      <Space size={16} align="center">
        {statusButtonArray(v)[v.status]}
        <Button
          style={{ padding: 0 }}
          type="link"
          disabled={!v.lockStatus}
          onClick={() => {
            unlockFn(v);
          }}
        >
          解锁
        </Button>
        <Button style={{ padding: 0 }} type="link" onClick={() => showModal(3, v)}>
          重置密码
        </Button>
        <Button style={{ padding: 0 }} type="link">
          <Link to={`/tenant/customer/user/${v.muid}`}>查看详情</Link>
        </Button>
        {v.status === 0 && (
          <Button style={{ padding: 0 }} type="link">
            <Link to={`/tenant/customer/user/createStaff/${v.muid}`}>新建员工</Link>
          </Button>
        )}
      </Space>
    );
  };

  // prettier-ignore
  const onOk = async () => {// NOSONAR

    if (operateCustomer) {
      if (operate === 0) {
        const res = await enableCustomer({ muid: operateCustomer.muid });
        if (res) {
          setOpened(false);
          setResultModalOpened(true);
          setResultModalChildren(<p>已启用用户{operateCustomer?.name}</p>);
          setPageOffset(1);
          update();
        }
      } else if (operate === 1) {
        const res = await disableCustomer({ muid: operateCustomer.muid });
        if (res) {
          setOpened(false);
          setResultModalOpened(true);
          setResultModalChildren(<p>已禁用用户{operateCustomer?.name}</p>);
          setPageOffset(1);
          update();
        }
      } else if (operate === 2) {
        const res = await freezeCustomer({ muid: operateCustomer.muid });
        if (res) {
          setOpened(false);
          setResultModalOpened(true);
          setResultModalChildren(<p>已冻结用户{operateCustomer?.name}</p>);
          setPageOffset(1);
          update();
        }
      } else if (operate === 3) {
        const res = await resetCustomerPassword({ muid: operateCustomer.muid });
        if (res.password) {
          setOpened(false);
          setResultModalOpened(true);
          setResultModalChildren(<p>用户{operateCustomer?.name}的密码已经重置为：{res.password}
          <Tooltip title="复制密码到剪贴板">
            <CopyToClipboard
            text={res.password}
            onCopy={() => {
              messageApi.success('复制密码成功');
            }}
          >
              <Button
                size="small"
                type='link'
                icon={<CopyOutlined />}
              />
            </CopyToClipboard>
          </Tooltip>
          </p>);
          setPageOffset(1);
          update();
        }else{
          setOpened(false);
          setResultModalOpened(true);
          setResultModalChildren(<p>已重置用户{operateCustomer?.name}的密码并发送到用户手机</p>);
          setPageOffset(1);
          update();
        }
      }
    }
    if (operate === 4) {
      const res = await batchDisableTenantUserList(selectedRowKeys);
      if (res) {
        setOpened(false);
        messageApi.success('操作成功！');
        setSelectedRowKeys([]);
        setPageOffset(1);
        update();
      }
    }
  };

  return (
    <Wrapper routes={routes} filters={filters}>
      <div className={styles.operationArea}>
        {/* <Button onClick={handleBatchDisable} disabled={batchButtonDisable}>批量禁用用户</Button> */}
        <Button className={styles.button} type="primary" target="_blank" href={'/tenant/customer/user/create'}>
          <PlusOutlined />
          新建用户
        </Button>
      </div>
      <ConfigProvider
        renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="点击【查询】按钮后展示数据" />}
      >
        <Table
          sticky
          scroll={{ x: 1500 }}
          rowKey="muid"
          loading={loading}
          rowSelection={{ selectedRowKeys, onChange: onSelectChange }}
          dataSource={dataSource}
          columns={[...tenantUserColumns, { title: '操作', fixed: 'right', width: 300, render: renderAction }]}
        />
      </ConfigProvider>
      <Paging pagingInfo={pagingInfo} />
      <Modal onOk={onOk} title={title} children={children} open={opened} onCancel={() => setOpened(false)} />
      <Modal
        title="操作成功"
        children={resultModalChildren}
        open={resultModalOpened}
        onOk={() => setResultModalOpened(false)}
        onCancel={() => setResultModalOpened(false)}
      />
      {messageContextHolder}
      {modalContextHolder}
    </Wrapper>
  );
};

export default TenantUserList;
