import React from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space, Row, Col, InputNumber } from 'antd';
import { FactorPropertyEnum } from '../emissionProperty';
import styles from '../index.module.scss';

const FormItem = Form.Item;

const IndustrialProcess: React.FC = () => (
  <div className={styles.dynamicForm}>
    <Form.List name={['factorDetails']}>
      {(fields, { add, remove }) => (
        <Space direction="vertical" style={{ width: '100%' }}>
          {fields.map(({ name, ...restField }) => {
            return (
              <Row gutter={16} key={name}>
                <Col span={8}>
                  <FormItem
                    name={[name, `${FactorPropertyEnum.ProcessMethod}`]}
                    label={name === 0 ? '工艺方法' : ''}
                    rules={[{ required: true }, { max: 50, message: '长度不能大于50个字符' }]}
                  >
                    <Input placeholder="请输入" />
                  </FormItem>
                </Col>
                <Col span={4}>
                  <FormItem
                    name={[name, `${FactorPropertyEnum.ProcessEmissionFactor}`]}
                    label={name === 0 ? '排放因子' : ''}
                    rules={[{ required: true }]}
                  >
                    <InputNumber placeholder="请输入数值" style={{ width: '100%' }} min={0} />
                  </FormItem>
                </Col>
                <Col span={1}>
                  <FormItem {...restField} label={name === 0 ? ' ' : ''}>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </FormItem>
                </Col>
              </Row>
            );
          })}
          <Row gutter={16}>
            <Col span={12}>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                新增一行
              </Button>
            </Col>
          </Row>
        </Space>
      )}
    </Form.List>
  </div>
);

export default IndustrialProcess;
