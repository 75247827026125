import { Tabs } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './index.module.scss';
import ProcessSpec, { ProcessSpecResult } from './utils/swagger-parse';
import classNames from 'classnames/bind';
import DocumentAnchor from './components/anchor';
import {
  queryUpstreamServiceInfo,
  queryUpstreamServiceList,
  UpstreamServiceListItem,
} from '../../api/openPlatform-upstreamService';
import useMarkdown from './hooks/useMarkdown';
import InterfaceTable from './InterfaceTable';

const cx = classNames.bind(styles);

const { TabPane } = Tabs;

const InterfaceDoc: React.FC = () => {
  const [markdownHtml, documentTree] = useMarkdown(['/md/content.md']);
  const [upstreamServiceList, setUpstreamServiceList] = useState<UpstreamServiceListItem[]>([]);
  const [interfaceBasePath, setInterfaceBasePath] = useState('');
  const [activeIdentifier, setActiveIdentifier] = useState<string>();
  const [activeKey, setActiveKey] = useState<string>('1');
  const [interfaceData, setInterfaceData] = useState<ProcessSpecResult>();

  useEffect(() => {
    queryUpstreamServiceList().then(data => {
      if (Array.isArray(data) && data.length > 0) {
        setUpstreamServiceList(data);
        handleUpstreamServiceClick(data[0].upstreamServiceIdentifier);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpstreamServiceClick = useCallback((upstreamServiceIdentifier: string) => {
    setActiveIdentifier(upstreamServiceIdentifier);
    queryUpstreamServiceInfo({ upstreamServiceIdentifier }).then(data => {
      const { host = '', basePath = '', servers } = data as any;
      let tempBasePath = host + basePath;
      if (servers && servers[0]) {
        tempBasePath = servers[0]['url'];
      }
      setInterfaceBasePath(tempBasePath);
      ProcessSpec(data)
        .then((spec: ProcessSpecResult) => {
          setInterfaceData(spec);
        })
        .catch((err: Error) => {
          console.error(err);
        });
    });
  }, []);

  function createMarkup() {
    return { __html: markdownHtml };
  }

  return (
    <>
      <div className={styles.container}>
        <aside className={styles.aside}>
          <Tabs activeKey={activeKey} onChange={activeKey => setActiveKey(activeKey)}>
            <TabPane tab="开发指南" key="1">
              <DocumentAnchor documentTree={documentTree} />
            </TabPane>
            <TabPane tab="接口列表" key="2">
              <ul className={styles.list}>
                {upstreamServiceList.map(item => {
                  return (
                    <li
                      key={item.upstreamServiceIdentifier}
                      className={cx(activeIdentifier === item.upstreamServiceIdentifier ? 'isActive' : '')}
                      onClick={() => handleUpstreamServiceClick(item.upstreamServiceIdentifier)}
                    >
                      <span>{item.upstreamServiceName}</span>
                    </li>
                  );
                })}
              </ul>
            </TabPane>
          </Tabs>
        </aside>

        <main className={cx('main', Number(activeKey) > 1 ? '' : 'markdown-body')} id="main-scroll-wrapper">
          {Number(activeKey) > 1 ? (
            <>
              <div className={cx('base-path-wrapper')} style={{ display: !!interfaceBasePath ? '' : 'none' }}>
                api根路径：{interfaceBasePath}
              </div>
              <InterfaceTable {...interfaceData!} />
            </>
          ) : (
            <div dangerouslySetInnerHTML={createMarkup()} />
          )}
        </main>
      </div>
    </>
  );
};

export default InterfaceDoc;
