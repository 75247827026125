import React, { useMemo, useContext } from 'react';
import { EllipsisSpan, Button, FieldTable, MxColumnType, MxColumnsType } from '@maxtropy/components';
import { ActionType, ESTUNPoint, UploadTypeEnum, UploadTypeEnumDisplay } from '../../../types';
import PointFormDialog from './PointFormDialog';
import { PointContext, PointContextProps } from '../contextTypes';

type EdgeDeviceTemplatePoint = ESTUNPoint & { actionType?: ActionType };

export interface EdgeDevicePointInfoProps {
  loading?: boolean;
  editColumns: MxColumnsType<EdgeDeviceTemplatePoint>;
  onUpdate: (values: any) => any;
}

const indexColumn: MxColumnType<EdgeDeviceTemplatePoint> = {
  title: '序号',
  width: 100,
  render: (_, record, index) => index + 1,
};

const columns: MxColumnsType<EdgeDeviceTemplatePoint> = [
  {
    title: '数据属性',
    dataIndex: 'dataPropertyName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '物联层数据点标识',
    dataIndex: 'symbol',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '单位',
    dataIndex: 'physicalUnitGeneralName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '上传方式',
    dataIndex: 'uploadType',
    ellipsis: { showTitle: true },
    render: (v: UploadTypeEnum) => <EllipsisSpan value={UploadTypeEnumDisplay[v]} />,
  },
];

const EdgeDevicePointInfo: React.FC<EdgeDevicePointInfoProps> = ({ loading, editColumns, onUpdate }) => {
  const { dataSource, row, setRow, info, editable, canAdjustField } = useContext(
    PointContext
  ) as PointContextProps<EdgeDeviceTemplatePoint>;
  const usedProperties = useMemo(
    () =>
      dataSource
        ?.filter(item => item.actionType !== ActionType.DELETE && item.dataPropertyId !== row?.dataPropertyId)
        .map(item => item.dataPropertyId) || [],
    [dataSource, row]
  );

  return (
    <>
      {editable && (
        <div style={{ marginBottom: 10 }}>
          <Button type="primary" onClick={() => setRow?.({})}>
            +添加数据点
          </Button>
        </div>
      )}
      <FieldTable
        sticky
        rowKey="id"
        adjustField={canAdjustField}
        adjustWidth={canAdjustField}
        cacheKey="estunDetailPoint"
        loading={loading}
        dataSource={dataSource}
        columns={[indexColumn, ...columns, ...editColumns]}
      />
      <PointFormDialog
        usedProperties={usedProperties}
        info={info}
        onCancel={() => setRow?.(undefined)}
        onOk={values => onUpdate(values)}
        row={row}
      />
    </>
  );
};

export default EdgeDevicePointInfo;
