import { Wrapper } from '@maxtropy/components';
import { FC } from 'react';
import CusNavList from './CusNavList';
import styles from './index.module.scss';

const CustomizeNavigation: FC = () => {
  const routes = [{ name: '系统设置' }, { name: '导航设置' }, { name: '导航方案' }];

  return (
    <Wrapper routes={routes} className={styles.content}>
      <CusNavList />
    </Wrapper>
  );
};

export default CustomizeNavigation;
