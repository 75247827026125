import { InfoCircleOutlined } from '@ant-design/icons';
import { EllipsisSpan, Filter } from '@maxtropy/components';
import { Form, Input, Modal, PaginationProps, Select, Table } from 'antd';
import React, { Key, Ref, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { ApiAuthoritiesProps } from '../../../../../../api/openPlatform-application';
import {
  queryUpstreamServiceInfo,
  queryUpstreamServiceList,
  UpstreamServiceListItem,
} from '../../../../../../api/openPlatform-upstreamService';
import ProcessSpec, { OperationObject } from '../../../../../InterfaceDoc/utils/swagger-parse';
import styles from './index.module.scss';

export interface InterfaceModalRef {
  // selectedRowKeys: Array<number> | undefined;
  selectedRows: Array<OperationObject>;
}

interface InterfaceModalProps {
  visible?: boolean;
  onOk?: () => void;
  onCancel?: () => void;
  selectedData?: ApiAuthoritiesProps[];
}

interface FilterProps {
  serviceName?: string;
  requestUrl?: string;
}

const columns = [
  {
    title: '服务',
    dataIndex: 'server',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '调用方式',
    dataIndex: 'method',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '接口',
    dataIndex: 'path',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const InterfaceModal = React.forwardRef((props: InterfaceModalProps, ref: Ref<InterfaceModalRef>) => {
  const { visible, onOk, onCancel, selectedData } = props;
  const [selectedRows, setSelectedRows] = useState<OperationObject[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const [searchParams, setSearchParams] = useState<FilterProps>();
  const [allData, setAllData] = useState<OperationObject[]>([]);
  const [allDataTemp, setAllDataTemp] = useState<OperationObject[]>([]); // 备份全部数据

  const [serverList, setServerList] = useState<UpstreamServiceListItem[]>([]); // 服务模糊查询

  // 查询服务商list
  useEffect(() => {
    queryUpstreamServiceList().then(setServerList);
  }, []);

  // 请求所有接口sweagger转换
  useEffect(() => {
    (async () => {
      let data = await queryUpstreamServiceList();
      if (Array.isArray(data) && data.length > 0) {
        let allres = await Promise.all(
          data.map(item => queryUpstreamServiceInfo({ upstreamServiceIdentifier: item.upstreamServiceIdentifier }))
        );
        let finishRes = await Promise.all(allres.map(item => ProcessSpec(item)));
        finishRes.forEach(i => {
          i.paths.forEach(
            item => (item.server = i.servers![0].url.split('/')[i.servers![0].url.split('/').length - 1])
          );
        });
        let allInterfaceRes: OperationObject[][] = finishRes.map(item => item.paths);
        setAllData(allInterfaceRes.flat());
        setAllDataTemp(allInterfaceRes.flat());
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //prettier-ignore
  useEffect(() => {// NOSONAR
    setIsLoading(true);
    if (allData) {
      if (searchParams) {
        setAllData(_ => {
          let searchArr: OperationObject[] = [];
          allDataTemp.forEach(i => {
            if (searchParams.serviceName && searchParams.requestUrl) {
              if ((i.server ?? '').includes(searchParams.serviceName) && i.path.includes(searchParams.requestUrl)) {
                searchArr.push(i);
              }
            } else if (searchParams.serviceName) {
              if ((i.server ?? '').includes(searchParams.serviceName)) {
                searchArr.push(i);
              }
            } else if (searchParams.requestUrl) {
              if (i.path.includes(searchParams.requestUrl)) {
                searchArr.push(i);
              }
            } else {
              searchArr.push(i);
            }
          });
          return searchArr;
        });
        setIsLoading(false);
      } else {
        setAllData(allDataTemp);
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useImperativeHandle(ref, () => ({ selectedRows }));

  const onReset = () => {
    const params = {
      serviceName: undefined,
      requestUrl: undefined,
    };
    setSearchParams(params);
  };

  const onFinish = (v: FilterProps) => {
    setSearchParams({ ...v });
  };

  const onSelectChange = (rowKeys: Key[], rows: OperationObject[]) => {
    const data = rows
      .filter(item => !!item)
      .filter(i => !selectedData?.map(s => s.requestUrl + s.requestMethod).includes(i.path + i.method));
    setSelectedRows(data);
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    onChange: onSelectChange,
    getCheckboxProps: (record: any) => ({
      disabled: (selectedData ?? []).map(i => i.requestUrl + i.requestMethod).includes(record.path + record.method),
    }),
    selectedRowKeys: (selectedRows ?? [])
      .map(i => i.path + i.method)
      .concat((selectedData ?? []).map(item => item.requestUrl + item.requestMethod)),
  };

  const length = useMemo(() => {
    return [
      ...new Set(
        (selectedData ?? [])
          .map(i => i.requestUrl + i.requestMethod)
          .concat((selectedRows ?? []).map(i => i.path + i.method))
      ),
    ].length;
  }, [selectedData, selectedRows]);

  const serverOptions = useMemo(() => {
    if (serverList && serverList.length !== 0) {
      return serverList.map(i => ({ label: i.upstreamServiceName, value: i.upstreamServiceIdentifier }));
    }
  }, [serverList]);

  const filters = (
    <Filter onFinish={v => onFinish(v as FilterProps)} form={form} onReset={onReset}>
      <Form.Item name="serviceName" label="服务" style={{ width: '30%', marginLeft: 10, marginRight: 16 }}>
        <Select
          style={{ width: '100%' }}
          placeholder="请选择服务"
          options={serverOptions}
          showSearch
          optionFilterProp="label"
        />
      </Form.Item>

      <Form.Item name="requestUrl" label="接口" style={{ width: '40%' }}>
        <Input placeholder="请输入接口搜索" />
      </Form.Item>
    </Filter>
  );

  const showTotal: PaginationProps['showTotal'] = (total: number) => `共 ${total} 条`;

  return (
    <>
      <Modal
        className={styles.ModalStyle}
        open={visible}
        title="选择接口"
        width={900}
        destroyOnClose
        onOk={() => {
          onOk?.();
        }}
        onCancel={() => {
          onCancel?.();
          setSelectedRows([]);
        }}
      >
        {filters}
        <span>
          已选择<span style={{ color: '#d62500', padding: '0 5px', fontWeight: 500 }}>{length}</span>项
          <InfoCircleOutlined style={{ margin: '0 4px 0 20px' }} />
          <span>如需更改请移除后重新选择</span>
        </span>
        <Table
          loading={isLoading}
          sticky
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          dataSource={allData}
          rowKey={record => `${record.path}${record.method}`}
          scroll={{ y: 300 }}
          columns={columns}
          pagination={{
            defaultPageSize: 20,
            showTotal,
            showSizeChanger: true,
          }}
        />
      </Modal>
    </>
  );
});

export default InterfaceModal;
