import AuthorizedPermission from '@/components/AuthorizedPermission';
import { RouteObject } from 'react-router-dom';
import EditApp from '../pages/App/Edit';
import InterfaceDoc from '../pages/InterfaceDoc';
import AppListPage from '../pages/App/List';
import DevelopmentTeamList from '../pages/DevelopmentTeam/List';
import CreateDeveloper from '../pages/DevelopmentTeam/Create';
import EditDeveloper from '../pages/DevelopmentTeam/Edit';
import DataPropertyManagement from '../pages/DataPropertyManageMent';
import CreateAppStepOne from '../pages/App/Create/BasicInfoStepOne';
import AppAuthStepTwo from '../pages/App/Create/AppAuthStepTwo';
import FinishStepThree from '../pages/App/Create/FinishStepThree';
import ServiceManagement from '../pages/ServiceManagement';
import ServiceCreate from '../pages/ServiceManagement/ServiceCreate';
import ServiceDetail from '../pages/ServiceManagement/ServiceDetail';
import DeveloperAccountList from '../pages/DeveloperAccount/List';
import CreateDeveloperAccount from '../pages/DeveloperAccount/Create';
import EditDeveloperAccount from '../pages/DeveloperAccount/Edit';
import { PermissionsType } from '@/common/permissionsConst';

const openPlatformRoutes: RouteObject[] = [
  {
    path: 'open-platform/open-platform/app',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_APPCREATE}>
        <AppListPage />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'open-platform/open-platform/app/create',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_APPCREATE}>
        <CreateAppStepOne />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'open-platform/open-platform/app/updateBasicInfo/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_APPCREATE}>
        <CreateAppStepOne />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'open-platform/open-platform/app/updateAuth/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_APPCREATE}>
        <AppAuthStepTwo />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'open-platform/open-platform/app/updateFinish/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_APPCREATE}>
        <FinishStepThree />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'open-platform/open-platform/app/update/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_APPUPDATE}>
        <EditApp />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'open-platform/open-platform/app/detail/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_APPINFO}>
        <EditApp />
      </AuthorizedPermission>
    ),
  },
  // {
  //   path: 'open-platform/open-platform/development-team',
  //   element: (
  //     <AuthorizedPermission permissionKey={PermissionsType.P_DEVELOPERPERMISSIONMANAGE}>
  //       <DevelopmentTeamList />
  //     </AuthorizedPermission>
  //   ),
  // },
  // {
  //   path: 'open-platform/open-platform/development-team/create',
  //   element: (
  //     <AuthorizedPermission permissionKey={PermissionsType.P_CREATEDEVELOPER}>
  //       <CreateDeveloper />
  //     </AuthorizedPermission>
  //   ),
  // },
  // {
  //   path: 'open-platform/open-platform/development-team/update/:id',
  //   element: (
  //     <AuthorizedPermission permissionKey={PermissionsType.P_UPDATEDEVELOPERPERMISSION}>
  //       <EditDeveloper />
  //     </AuthorizedPermission>
  //   ),
  // },
  // {
  //   path: 'open-platform/open-platform/development-team/detail/:id',
  //   element: (
  //     <AuthorizedPermission permissionKey={PermissionsType.P_DEVELOPERPERMISSIONINFO}>
  //       <EditDeveloper />
  //     </AuthorizedPermission>
  //   ),
  // },
  {
    path: 'open-platform/open-platform/developer-account',
    element: <DeveloperAccountList />,
  },
  {
    path: 'open-platform/open-platform/developer-account/create',
    element: <CreateDeveloperAccount />,
  },
  {
    path: 'open-platform/open-platform/developer-account/edit/:id',
    element: <EditDeveloperAccount />,
  },
  {
    path: 'open-platform/open-platform/developer-account/detail/:id',
    element: <EditDeveloperAccount isDetail />,
  },
  {
    path: 'open-platform/open-platform/interface-document',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_APIDOCUMENTATION}>
        <InterfaceDoc />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'open-platform/open-platform/dataProperty-management',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_DATAPROPERTYMANAGE}>
        <DataPropertyManagement />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'open-platform/open-platform/service-management',
    element: <ServiceManagement />,
  },
  {
    path: 'open-platform/open-platform/service-management/create',
    element: <ServiceCreate />,
  },
  {
    path: 'open-platform/open-platform/service-management/edit/:id',
    element: <ServiceCreate isEdit />,
  },
  {
    path: 'open-platform/open-platform/service-management/detail/:id',
    element: <ServiceDetail />,
  },
];

export default openPlatformRoutes;
