import { useEffect, useState, useMemo } from 'react';
import { Wrapper, Table, EllipsisSpan, usePaging, Paging } from '@maxtropy/components';
import { Form, Input, Col, Button, Space, Divider, Popconfirm, message, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import {
  apiCarbonIndustryPagePost,
  apiCarbonIndustryDeletePost,
  apiCarbonIndustryPost,
  CarbonIndustryPagePostRequest,
  CarbonIndustryPagePostResponse,
} from '@maxtropy/device-mgmt-apis';
import Filter from '@/shared/components/Filter';
import styles from './index.module.scss';

const routes = [{ name: '双碳管理' }, { name: '基本信息' }, { name: '行业管理' }];

interface industryType {
  id: number;
  name: string;
  isByUsing?: boolean;
}

type SearchParams = Omit<CarbonIndustryPagePostRequest, 'page' | 'size'>;

const TradeManagement = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [list, setList] = useState<CarbonIndustryPagePostResponse['list']>();

  const [form] = Form.useForm();
  const [modalForm] = Form.useForm();

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, pageOffset, pageSize]);

  const loadData = () => {
    setLoading(true);
    apiCarbonIndustryPagePost({
      page: String(pageOffset),
      size: String(pageSize),
      ...searchParams,
    })
      .then(res => {
        if (res.list) {
          setList(res.list || []);
          setTotalCount(res.total as number);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinish = (query: any) => {
    setPageOffset(1);
    setSearchParams(query);
  };

  const onReset = () => {
    const params: SearchParams = { name: undefined };
    setSearchParams(params);
    setPageOffset(1);
  };

  const delIndustry = (id: number) => {
    setLoading(true);
    apiCarbonIndustryDeletePost({ id: String(id) })
      .then(res => {
        loadData();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filters = (
    <Filter form={form} onFinish={onFinish} onReset={onReset}>
      <Col span={6}>
        <Form.Item label="行业名称" name="name">
          <Input placeholder="请输入行业名称搜索" />
        </Form.Item>
      </Col>
    </Filter>
  );

  const columns = useMemo(
    () => [
      {
        title: '行业名称',
        dataIndex: 'name',
        ellipsis: { showTitle: true },
        render: (v: string) => <EllipsisSpan value={v} />,
      },
      {
        title: '操作',
        width: 200,
        render: (record: industryType) => {
          return (
            <Space size={16}>
              <Button
                type="link"
                onClick={() => {
                  setOpen(true);
                  modalForm.setFields([
                    { name: 'name', value: record.name },
                    { name: 'id', value: record.id },
                  ]);
                }}
              >
                编辑
              </Button>
              {record.isByUsing ? (
                <Button
                  type="link"
                  onClick={() => {
                    message.warning('本行业已被引用，不支持删除。');
                  }}
                >
                  删除
                </Button>
              ) : (
                <Popconfirm title="您是否删除此行业？" onConfirm={() => delIndustry(record.id)}>
                  <Button type="link">删除</Button>
                </Popconfirm>
              )}
            </Space>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const modalFunc = {
    onOk: () => {
      modalForm.validateFields().then(() => {
        const values = modalForm.getFieldsValue(true);

        apiCarbonIndustryPost(values).then(() => {
          modalFunc.onCancel();

          setPageOffset(1);
          loadData();
        });
      });
    },
    onCancel: () => {
      setOpen(false);
      modalForm.resetFields();
    },
  };

  return (
    <>
      <Wrapper routes={routes} filters={filters}>
        <div className={styles.operationArea}>
          <Button
            type="primary"
            onClick={() => {
              setOpen(true);
            }}
          >
            <PlusOutlined />
            新建行业
          </Button>
        </div>
        <Table columns={columns} rowKey="id" pagination={false} loading={loading} dataSource={list} />
        <Paging pagingInfo={pagingInfo} />
      </Wrapper>
      <Modal
        title="新建行业"
        open={open}
        onOk={modalFunc.onOk}
        onCancel={modalFunc.onCancel}
        destroyOnClose={true}
        width={500}
      >
        <Form form={modalForm}>
          <Form.Item
            label="行业名称"
            name="name"
            rules={[{ required: true }, { max: 50, message: '最多能输入50个字符' }]}
          >
            <Input placeholder="请输入行业名称" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default TradeManagement;
