import { FC, useEffect, useState } from 'react';
import { Button, Col, Form, Row, Space } from 'antd';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { FormContent, Paging, Table, usePaging, Wrapper } from '@maxtropy/components';
import ShowInput from '@/shared/components/ShowInput';
import { IotProtocolType, IotProtocolTypeDisplay, AlarmChannel } from '@/shared/types';
import { sliceDeviceTypeName } from '@/shared/utils/utils';
import { RulePageProps } from '../../../api/rule';
import { getRulesPageById } from '../../../api/ruleGroup';
import { columns } from '../util';
import styles from './index.module.scss';
import { V2RuleGroupInfoPostResponse, apiV2RuleGroupInfoPost } from '@maxtropy/device-mgmt-apis-v2';
import { apiV2RuleGetChannelPost, V2RuleGetChannelPostResponse } from '@maxtropy/tody-mgmt-apis-v2';

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

const routes = [{ name: 'Iot配置' }, { name: '报警规则' }, { name: '设备报警规则' }, { name: '规则组详情' }];

const AlarmRulesGroupDetail: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);

  const [form] = Form.useForm();

  const { search } = useLocation();

  const [ruleGroup, setRuleGroup] = useState<V2RuleGroupInfoPostResponse>();
  const [rules, setRules] = useState<RulePageProps[]>([]);
  const [iotProtocolType, setIotProtocolType] = useState<IotProtocolType>();
  const [alarmChannelList, setAlarmChannelList] = useState<V2RuleGetChannelPostResponse['list']>();
  const [alarmChannel, setAlarmChannel] = useState<AlarmChannel>();

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;

  // 获取渠道数据
  useEffect(() => {
    apiV2RuleGetChannelPost().then(res => {
      setAlarmChannelList(res.list ?? []);
    });
  }, []);

  useEffect(() => {
    if (ruleGroup) {
      setIotProtocolType(ruleGroup.iotProtocol);
      setAlarmChannel(ruleGroup.channel);
      form.setFieldsValue({
        name: ruleGroup.name,
        channel: alarmChannelList?.find(i => i.code === ruleGroup.channel)?.desc,
        rootMcid: ruleGroup.tenantName,
        iotProtocol: IotProtocolTypeDisplay[ruleGroup.iotProtocol!],
        deviceTypeId: ruleGroup.deviceTypeName ? sliceDeviceTypeName(ruleGroup.deviceTypeName) : '--',
        physicalModelId: ruleGroup.modelNo ?? '--',
      });
    }
  }, [ruleGroup, form, alarmChannelList]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getRulesPageById({ id: Number(id), page: pageOffset, size: pageSize }).then(res => {
        setLoading(false);
        if (res) {
          setTotalCount(res.total);
          setRules(res.list);
        }
      });
    }
  }, [id, pageOffset, pageSize, setTotalCount]);

  useEffect(() => {
    if (id) {
      apiV2RuleGroupInfoPost({ id: +id }).then(res => {
        setRuleGroup(res);
      });
    }
  }, [id]);

  return (
    <Wrapper routes={routes} className={styles.wrapper}>
      <Form form={form} layout="vertical" {...formLayout}>
        <FormContent>
          <Row>
            <Col span={8} className={styles.col}>
              <Form.Item name="name" label="规则组名称">
                <ShowInput />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item name="rootMcid" label="所属租户">
                <ShowInput />
              </Form.Item>
            </Col>
          </Row>
        </FormContent>
        <FormContent title="报警规则">
          <Row className={styles.info}>
            <Col span={8}>
              <Form.Item label="渠道" name="channel">
                <ShowInput />
              </Form.Item>
            </Col>
            {alarmChannel === AlarmChannel.LONGSHINE ? (
              <>
                <Col span={8}>
                  <Form.Item name="deviceTypeId" label="适用设备类目">
                    <ShowInput />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <>
                <Col span={8}>
                  <Form.Item name="iotProtocol" label="物联层协议">
                    <ShowInput />
                  </Form.Item>
                </Col>
                {iotProtocolType === IotProtocolType.MOCKINGBIRD && (
                  <>
                    <Col span={8}>
                      <Form.Item name="deviceTypeId" label="适用设备类目">
                        <ShowInput />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item name="physicalModelId" label="物模型型号">
                        <ShowInput />
                      </Form.Item>
                    </Col>
                  </>
                )}
              </>
            )}

            <Col span={24}>
              <Form.Item
                // name="rules"
                wrapperCol={{ span: 24 }}
              >
                {/* 分页列表 */}
                <Table
                  loading={loading}
                  sticky
                  scroll={{ x: 1300, y: 400 }}
                  rowKey="id"
                  columns={columns}
                  dataSource={rules}
                />
                <Paging pagingInfo={pagingInfo} />
              </Form.Item>
            </Col>
          </Row>
        </FormContent>
        <Space className="sticky-footer">
          <Button
            className={styles.button}
            onClick={() => {
              navigate(`/device/rule/list${search}`);
            }}
          >
            返回
          </Button>
        </Space>
      </Form>
    </Wrapper>
  );
};

export default AlarmRulesGroupDetail;
