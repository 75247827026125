import { useCurrent } from '../../components/Layout/hooks';
import { useMemo } from 'react';
import { Role } from '../../api/openPlatform-const';
import { CorpUser } from '../../api/openPlatform-user';

const usePlatformRole = (appId: string): { isAdmin: boolean; isDeveloper: boolean; current?: CorpUser } => {
  const current = useCurrent();

  const [isAdmin, isDeveloper] = useMemo(() => {
    const relationVos = current?.relationVos ?? [];
    const appIds = relationVos.map(({ app }) => app.id);
    if (appIds.includes(Number(appId))) {
      const role = relationVos.find(item => item.app.id === Number(appId))?.role.memo;
      return [role === Role.ADMIN, role === Role.DEVELOPER];
    }
    return [false, false];
  }, [current, appId]);

  return { isAdmin, isDeveloper, current };
};

export default usePlatformRole;
