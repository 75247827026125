import React from 'react';
import { InputNumber, Space } from 'antd';
import useMergedState from '../../../../hooks/useMergedState';

interface NumberRangeProps {
  value?: number[];
  onChange?: (value: number[]) => void;
}

const NumberRange: React.FC<NumberRangeProps> = props => {
  const [mergedValue, setMergedValue] = useMergedState([], { value: props.value, onChange: props.onChange });

  return (
    <Space size={10}>
      <InputNumber
        placeholder="请输入"
        style={{ width: 160 }}
        value={mergedValue[0]}
        onChange={value => setMergedValue([value!, mergedValue[1]])}
      />
      <span>-</span>
      <InputNumber
        placeholder="请输入"
        style={{ width: 160 }}
        value={mergedValue[1]}
        onChange={value => setMergedValue([mergedValue[0], value!])}
      />
    </Space>
  );
};

export default NumberRange;
