import React from 'react';
import { EllipsisSpan, Table, getRealUrl } from '@maxtropy/components';
import { Detail, ToolOperationLogData } from '../types';

interface Props {
  data?: ToolOperationLogData;
}

const LogDetail: React.FC<Props> = ({ data }) => {
  const renderDetailField = (key: keyof Detail, data?: Detail) => {
    const value = data?.[key];

    switch (key) {
      case 'icon':
        // 工具图标
        return <img width={50} src={getRealUrl(value as string)} alt="" />;
      case 'type':
        // 工具类型
        return ((value as number) === 1 && '自研') || (value === 2 && 'ISV研发') || value;
      case 'cardSupport':
        // 是否支持小卡片
        return ((value as number) === 1 && '支持') || (value === 0 && '不支持') || value;
      default:
        return value;
    }
  };

  const columns = [
    {
      title: '字段',
      dataIndex: 'field',
      width: 100,
    },
    {
      title: '操作前',
      dataIndex: 'preVo',
      render: (text: string | JSX.Element) => {
        if (typeof text === 'string') {
          return <EllipsisSpan value={text || undefined} />;
        }
        return text || '--';
      },
    },
    {
      title: '操作后',
      dataIndex: 'currentVo',
      render: (text: string | JSX.Element) => {
        if (typeof text === 'string') {
          return <EllipsisSpan value={text || undefined} />;
        }
        return text || '--';
      },
    },
  ];

  // 处理行数据
  const getColumnData = (key: keyof Detail, field: string) => ({
    key,
    field,
    preVo: renderDetailField(key, data?.previousData),
    currentVo: renderDetailField(key, data?.currentData),
  });

  const detailData = [
    getColumnData('categoryName', '工具分类'),
    getColumnData('name', '工具名称'),
    getColumnData('entryPath', '工具路径'),
    getColumnData('icon', '工具图标'),
    getColumnData('type', '工具类型'),
    getColumnData('vendorName', '服务商'),
    getColumnData('integratedAppName', '集成应用'),
    getColumnData('description', '工具简介'),
    getColumnData('cardSupport', '小卡片'),
    getColumnData('permissionCode', '权限'),
  ];

  return <Table rowKey="id" columns={columns} dataSource={detailData} />;
};

export default LogDetail;
