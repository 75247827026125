import React from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import './App.scss';
import Home from './pages/Home';
import { CustomApp, useThemeContext } from '@maxtropy/components';

import BasicLayout from './components/Layout';

import routes from './routes';
import RootRouteBoundary from '@/shared/components/RootRouteBoundary';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import Login from './pages/Login';

const router = createBrowserRouter([
  {
    path: '/*',
    errorElement: <RootRouteBoundary />,
    element: (
      <BasicLayout>
        <Outlet />
      </BasicLayout>
    ),
    children: [
      {
        index: true,
        element: <Home />,
      },
      ...routes,
    ],
  },
  {
    path: 'login',
    element: <Login />,
  },
]);

const App: React.FC = () => {
  const theme = useThemeContext();

  return (
    <div className="App">
      <ConfigProvider locale={zhCN} theme={theme} space={{ size: 3 }}>
        <CustomApp style={{ height: '100%' }}>
          <RouterProvider router={router} />
        </CustomApp>
      </ConfigProvider>
    </div>
  );
};

export default App;
