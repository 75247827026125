import { EllipsisSpan, Paging, usePaging, useUpdate, Wrapper } from '@maxtropy/components';
import { Button, Col, Form, Row, Space, Switch, Table } from 'antd';
import styles from './index.module.scss';
import React, { useEffect, useState } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import DataValuesTemplateDetail from '../../RulesGroup/components/DataValuesTemplateDetail';
import { apiRulesSetIdGet, RulesSetIdGetResponse } from '../../../ytt/types/rulesSet/id';
import { useParams } from 'react-router-dom';
import AddDataSupplementModal from './AddDataSupplementModal';
import dayjs from 'dayjs';
import ViewDataSupplementModal from './ViewDataSupplementModal';
import { apiRulesSetPageAppendDataPost, RulesSetPageAppendDataPostResponse } from '@maxtropy/device-mock-apis';
import { isNil } from 'lodash-es';
import { DataSupplementStatusType, DataSupplementStatusTypeDisplay } from '../../../api/type';

const routes = [{ name: '数据运营工具' }, { name: '数据模拟' }, { name: '数据补录详情' }];

export type TableRow = Exclude<RulesSetPageAppendDataPostResponse['list'], undefined>[number];

const DataSupplement = () => {
  const [isLoading] = useState<boolean>(false);
  const { id } = useParams<{ id?: string }>();
  const [updateState, updateFn] = useUpdate();
  const [detail, setDetail] = useState<RulesSetIdGetResponse>({});
  const [opType, setOpType] = useState<string>('add');
  const [showModal, setShowModal] = useState(false);

  const [deviceId, setDeviceId] = useState<number>();

  const columns = [
    {
      title: '所属类目',
      dataIndex: 'deviceTypeName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '设备',
      dataIndex: 'deviceNames',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '属性',
      dataIndex: 'dataPropertyNames',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '数据值模板参数',
      dataIndex: 'deviceTags',
      ellipsis: { showTitle: true },
      render: (v: string, record: TableRow) => (
        <EllipsisSpan
          value={`${record.yMagnificationName ?? '--'}=${record.yMagnificationValue ?? '--'};${
            record.cumulativeStartName ?? '--'
          }=${record.cumulativeStartValue ?? '--'}`}
        />
      ),
    },
    {
      title: '补录数据开始时间',
      dataIndex: 'beginTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'} />,
    },
    {
      title: '补录数据结束时间',
      dataIndex: 'endTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'} />,
    },
    {
      title: '进度',
      dataIndex: 'progressRate',
      ellipsis: { showTitle: true },
      render: (v: number) => <EllipsisSpan value={!isNil(v) ? (v * 100).toFixed(2) + '%' : '--'} />,
    },
    {
      title: '状态',
      dataIndex: 'status',
      ellipsis: { showTitle: true },
      render: (v: DataSupplementStatusType) => <EllipsisSpan value={v ? DataSupplementStatusTypeDisplay[v] : '--'} />,
    },
    {
      title: '操作',
      fixed: 'right' as const,
      width: 80,
      render: (v: any) => {
        return (
          <Space size={16}>
            <Button
              style={{ padding: 0 }}
              type="link"
              onClick={() => {
                setDeviceId(v.id);
                setShowModal(true);
                setOpType('view');
              }}
            >
              详情
            </Button>
          </Space>
        );
      },
    },
  ];

  useEffect(() => {
    if (!id) return;
    apiRulesSetIdGet({ id }).then(res => setDetail(res));
  }, [id, updateState]);

  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [pageAppendData, setPageAppendData] = useState<TableRow[]>();

  useEffect(() => {
    if (!id) return;
    apiRulesSetPageAppendDataPost({
      rulesSetId: Number(id),
      page: pageOffset,
      size: pageSize,
    }).then(res => {
      if (res) {
        setTotalCount(res.total!);
        setPageAppendData(res.list);
      }
    });
  }, [id, pageOffset, pageSize, updateState]);

  return (
    <>
      <Wrapper routes={routes} className={styles.outer}>
        <div className={styles.form_area}>
          <Form layout="vertical">
            <Row gutter={10}>
              <Col span={8}>
                <Form.Item label="规则名称">
                  <span>{detail?.name ?? '-'}</span>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="所属租户">
                  <span>{detail?.tenantName ?? '-'}</span>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="场景">
                  <span>{detail?.sceneName ?? '-'}</span>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="属性组">
                  <span>{detail?.dataPropertyGroupName ?? '-'}</span>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="数据点统一计算">
                  <Switch
                    checked={detail?.unifiedCompute}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="数据值模板">
                  <DataValuesTemplateDetail data={detail} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="两次上传间隔">
                  <span>{detail?.uploadTimeRuleName ?? '-'}</span>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="延时上传时间">
                  <span>{detail?.dataTimeRuleName ?? '-'}</span>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <div className={styles.gap}></div>
        <div className={styles.table_area}>
          <div className={styles.buttons}>
            <Button
              type="primary"
              onClick={() => {
                setDeviceId(undefined);
                setShowModal(true);
                setOpType('add');
              }}
            >
              补录数据
            </Button>
          </div>
          <Table
            loading={isLoading}
            sticky
            dataSource={pageAppendData ?? []}
            rowKey="id"
            scroll={{ y: 300 }}
            columns={columns}
            pagination={false}
          />
          <Paging pagingInfo={pagingInfo} />
        </div>
      </Wrapper>
      {showModal && opType === 'add' && (
        <AddDataSupplementModal
          detail={detail}
          deviceId={deviceId}
          onCancel={() => setShowModal(false)}
          onConfirm={() => {
            setShowModal(false);
            updateFn();
          }}
        />
      )}
      {showModal && opType === 'view' && (
        <ViewDataSupplementModal detail={detail} deviceId={deviceId} onCancel={() => setShowModal(false)} />
      )}
    </>
  );
};

export default DataSupplement;
