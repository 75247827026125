import React, { useCallback } from 'react';
import { Button, Space } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { Wrapper, FormContent } from '@maxtropy/components';
import { useQuery } from '../../utils/utils';
import { DeviceField, getAttributeDetail } from '../../api/attribute';
import { AttributeField } from '@/shared/types';
import AttributeItem from '@/shared/components/AttributeItem';
import styles from './index.module.scss';

const routes = [{ name: 'Iot配置' }, { name: '设备信息模板库' }, { name: '属性管理' }, { name: '属性详情' }];

function formatField(fields: Array<DeviceField>): Array<AttributeField> {
  return fields.map(field => ({
    id: field.id,
    name: field.name,
    type: field.type,
    inputType: field.inputType,
    constraintValue: field.constraintValue,
    physicalUnits: field.physicalUnits?.map(unit => ({
      id: unit.id,
      generalName: unit.generalName,
      chineseName: unit.chineseName,
      k: unit.k,
      b: unit.b,
      isBasic: unit.isBasic,
    })),
  }));
}

const AttributeDetail: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();

  const { data } = useQuery(
    useCallback(() => {
      if (id) return getAttributeDetail(id);
      return Promise.resolve(undefined);
    }, [id])
  );

  return (
    <Wrapper routes={routes}>
      <div className={styles.wrapper}>
        <FormContent title={'属性详情'}>
          <div className={styles.content}>
            <div className={styles.detail}>
              {data ? (
                [data.attribute].map(item => (
                  <>
                    <div className={styles.display}>
                      <div className={styles.field}>
                        <div className={styles.label}>属性名称:</div>
                        <div className={styles.value}>{item.name}</div>
                      </div>
                      <div className={styles.field}>
                        <div className={styles.label}>所属类目:</div>
                        <div className={styles.value}>{item.deviceTypeList}</div>
                      </div>
                    </div>
                    <AttributeItem
                      style={{ border: 'none' }}
                      disabled
                      hideTitle
                      attribute={{
                        id: item.id,
                        name: item.name,
                        serialNumber: item.serialNumber,
                        deviceFields: formatField(item.deviceFields),
                      }}
                    />
                  </>
                ))
              ) : (
                <div />
              )}
            </div>
          </div>
        </FormContent>
        <Space className={styles.footer}>
          <Button className={styles.button} onClick={() => navigate(-1)}>
            返回
          </Button>
        </Space>
      </div>
    </Wrapper>
  );
};

export default AttributeDetail;
