import { EllipsisSpan } from '@maxtropy/components';
import { Button, Input, Radio, Space, Table } from 'antd';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { TenantAuthoritiesProps } from '../../../../../../api/openPlatform-application';
import CustomerModal, { CustomerModalRef } from '../CustomerModal';

// 可见范围类型
export enum VisibleRangeType {
  ALL = 1, // 全部租户可见
  PART = 2, // 部分租户可见
}

// 触发间隔类型的值
export const VisibleRangeValue = {
  [VisibleRangeType.ALL]: '全部租户可见',
  [VisibleRangeType.PART]: '部分租户可见',
};

export interface CustomerTableProps {
  id: number;
  tenantName: string;
  tenantMcid: string;
}

const selectedColumns = [
  {
    title: '租户',
    dataIndex: 'tenantName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: 'mcid',
    dataIndex: 'tenantMcid',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface CustomerAuthProps {
  setTenantSelectedValues: React.Dispatch<React.SetStateAction<TenantAuthoritiesProps[]>>;
  tenantSelectedValues?: TenantAuthoritiesProps[];
  selectedVisibleRange?: (visibleRange: VisibleRangeType) => void;
  visibleRangeValue?: VisibleRangeType;
}

const CustomerAuth: FC<CustomerAuthProps> = props => {
  const { setTenantSelectedValues, tenantSelectedValues, selectedVisibleRange, visibleRangeValue } = props;

  const [selectCusVisible, setSelectCusVisible] = useState<boolean>(false);
  const addCustomerRef = useRef<CustomerModalRef>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false); // 表格的loading
  const [searchValue, setSearchValue] = useState<string>(); // 租户名称搜索
  const [tenantSelectedValuesTemp, setTenantSelectedValuesTemp] = useState<TenantAuthoritiesProps[]>([]); // 租户查询备份

  useEffect(() => {
    if (tenantSelectedValues) {
      setTenantSelectedValuesTemp(tenantSelectedValues);
    }
  }, [tenantSelectedValues]);

  // 前端模糊搜索
  const getData = (searchValue?: string) => {
    setIsLoading(true);
    if (searchValue) {
      let searchArr: TenantAuthoritiesProps[] = [];
      (tenantSelectedValues ?? []).forEach(i => {
        if (i.tenantName?.includes(searchValue)) {
          searchArr.push(i);
        }
      });
      setTenantSelectedValuesTemp(searchArr);
    } else {
      setTenantSelectedValuesTemp(tenantSelectedValues ?? []);
    }
    setIsLoading(false);
  };

  // 弹窗选择完租户的确定
  const onOk = () => {
    if (addCustomerRef.current?.selectedRows.length) {
      let arr = (tenantSelectedValues ?? []).concat(
        addCustomerRef.current?.selectedRows.map(i => {
          return {
            id: i.id,
            tenantName: i.name,
            tenantMcid: i.mcid,
          };
        })
      );
      // 去重mcid
      const map = new Map();
      const newArr = arr.filter(v => !map.has(v.tenantMcid + v.tenantName) && map.set(v.tenantMcid + v.tenantName, v));
      setTenantSelectedValues(newArr);
    }
    setSelectCusVisible(false);
  };

  const getCustomerDatasource = useCallback(() => {
    if (tenantSelectedValues && tenantSelectedValues.length) {
      getData(searchValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, tenantSelectedValues]);

  const onRemove = (id: number) => {
    const newList = [...(tenantSelectedValues ?? [])];
    const index = newList.findIndex(i => i.id === id);
    newList.splice(index, 1);
    setTenantSelectedValues(newList);
  };

  const columns = {
    title: '操作',
    width: 200,
    fixed: 'right' as const,
    render: (v: any, record: CustomerTableProps) => (
      <>
        <Space size={16}>
          <Button type="link" onClick={() => onRemove(record.id)}>
            移除
          </Button>
        </Space>
      </>
    ),
  };

  return (
    <>
      <div style={{ marginBottom: 20 }}>
        <span>可见范围：</span>
        <Radio.Group
          onChange={e => {
            selectedVisibleRange?.(e.target.value);
          }}
          value={visibleRangeValue}
        >
          <Radio value={VisibleRangeType.PART}>{VisibleRangeValue[VisibleRangeType.PART]}</Radio>
          <Radio value={VisibleRangeType.ALL}>{VisibleRangeValue[VisibleRangeType.ALL]}</Radio>
        </Radio.Group>
      </div>
      {visibleRangeValue === VisibleRangeType.PART && (
        <>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
            <Button
              type="primary"
              onClick={() => {
                setSelectCusVisible(true);
              }}
            >
              选择租户
            </Button>

            <div style={{ display: 'flex' }}>
              <Space>
                <span>租户：</span>
                <Input
                  onChange={e => setSearchValue(e.target.value)}
                  value={searchValue}
                  placeholder="请输入租户名称搜索"
                />
                <Button type="primary" onClick={getCustomerDatasource}>
                  查询
                </Button>
                <Button
                  onClick={() => {
                    setSearchValue(undefined);
                    getData();
                  }}
                >
                  重置
                </Button>
              </Space>
            </div>
          </div>

          <Table
            style={{ marginTop: 30 }}
            loading={isLoading}
            sticky
            dataSource={tenantSelectedValuesTemp}
            rowKey="id"
            scroll={{ y: 300 }}
            columns={[...selectedColumns, columns]}
          />
        </>
      )}

      <CustomerModal
        ref={addCustomerRef}
        visible={selectCusVisible}
        onOk={onOk}
        selectedData={tenantSelectedValues}
        onCancel={() => setSelectCusVisible(false)}
      />
    </>
  );
};

export default CustomerAuth;
