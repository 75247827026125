import { AttachmentUpload, Button, FormContent, Wrapper } from '@maxtropy/components';
import { Form, Input, Space, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createService,
  CreateServiceBody,
  editService,
  getServiceDetail,
} from '../../../api/openPlatform-serviceManage';
import Footer from '../../../components/Footer';
import { LoadingOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 9 },
};

interface Props {
  isEdit?: boolean;
}

const ServiceCreate: React.FC<Props> = ({ isEdit = false }) => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const routes = [
    { name: '开发者平台' },
    { name: '应用开发开放平台' },
    { name: '服务商管理' },
    isEdit ? { name: '编辑服务商' } : { name: '创建服务商' },
  ];

  useEffect(() => {
    if (id) {
      getServiceDetail(id).then(res => {
        form.setFieldsValue({
          name: res.name,
          companyName: res.companyName,
          introduction: res.introduction,
          logoResource: res.logoResource,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onFinish = (v: CreateServiceBody) => {
    setLoading(true);

    if (isEdit && id) {
      editService(id, v).then(() => {
        setLoading(false);
        navigate('/open-platform/open-platform/service-management');
      });
      return;
    }

    createService(v).then(() => {
      setLoading(false);
      navigate('/open-platform/open-platform/service-management');
    });
  };

  return (
    <Wrapper routes={routes} className="open-platform-common-wrapper">
      <FormContent title={isEdit ? '编辑服务商' : '创建服务商'}>
        <Form
          style={{
            padding: '40px 74px',
          }}
          form={form}
          layout="vertical"
          {...formItemLayout}
          onFinish={onFinish}
        >
          <Form.Item
            name="name"
            label="服务商名称"
            rules={[
              { required: true, message: '请输入' },
              { max: 50, message: '服务商名称最多50字' },
            ]}
          >
            <Input placeholder="请输入服务商名称" />
          </Form.Item>

          <Form.Item
            name="companyName"
            label="公司名称"
            rules={[
              { required: true, message: '请输入' },
              { max: 200, message: '公司名称最多200字' },
            ]}
          >
            <Input placeholder="请输入公司名称" />
          </Form.Item>

          <Form.Item name="introduction" label="简介" rules={[{ max: 500, message: '简介最多500字' }]}>
            <TextArea rows={4} placeholder="请输入服务商简介，最多500字" />
          </Form.Item>

          <Form.Item name="logoResource" label="上传Logo" rules={[{ required: true, message: '请上传' }]}>
            <AttachmentUpload fileSize={1} accept=".jpg, .jpeg, .png" />
          </Form.Item>

          <Footer style={{ padding: 0 }}>
            <Space>
              <Button type="primary" htmlType="submit">
                <Spin
                  style={{ marginRight: 2 }}
                  indicator={<LoadingOutlined style={{ fontSize: 16, color: 'white' }} spin />}
                  spinning={loading}
                />
                保存
              </Button>
              <Button onClick={() => navigate('/open-platform/open-platform/service-management')}>取消</Button>
            </Space>
          </Footer>
        </Form>
      </FormContent>
    </Wrapper>
  );
};

export default ServiceCreate;
