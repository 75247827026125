import React from 'react';
import classNames from 'classnames/bind';
import styles from './index.module.scss';
import JsonTreeContent from '../json-tree/JsonTreeContent';
import { Tabs } from 'antd';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/prism/json';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { RequestParam } from '../interface';

SyntaxHighlighter.registerLanguage('json', json);
interface RequestTableProps {
  data: RequestParam[];
}

const { TabPane } = Tabs;

const cx = classNames.bind(styles);

const RequestTable: React.FC<RequestTableProps> = ({ data }) => {
  return (
    <div className={cx('common-table-wrapper')}>
      <table>
        <thead>
          <tr>
            <th className={cx('col-header', 'col-name')}>Name</th>
            <th className={cx('col-header', 'col-description')}>Description</th>
          </tr>
        </thead>
        <tbody>
          {data.map(param => {
            const { name, required, in: _in, type, format, enum: _enum, description, schemaTree, example } = param;
            return (
              <tr key={param.name}>
                <td className={cx('col-name')}>
                  <div className={cx('name', `${required ? 'required' : ''}`)}>
                    {name}
                    {required ? <span>&nbsp;*</span> : null}
                  </div>
                  <div className={cx('type')}>
                    {type}
                    <span className={cx('prop-format')}>{format ? `(${format})` : undefined}</span>
                  </div>
                  <div className={cx('deprecated')}></div>
                  <div className={cx('parameter-in')}>{`(${_in})`}</div>
                </td>
                <td className={cx('col-description')}>
                  <div className={cx('markdown')}>
                    <p>{description}</p>
                  </div>
                  {Array.isArray(_enum) && _enum.length > 0 ? (
                    <div className={cx('enum', 'markdown')}>
                      <p>
                        <i>Available values</i> : _enum.join(',')
                      </p>
                    </div>
                  ) : undefined}
                  {schemaTree ? (
                    <Tabs defaultActiveKey="1">
                      <TabPane tab="Example Value" key="1">
                        {example ? (
                          <SyntaxHighlighter language="json" style={atomDark}>
                            {example}
                          </SyntaxHighlighter>
                        ) : undefined}
                      </TabPane>
                      <TabPane tab="Model" key="2">
                        <div className={cx('json-tree-wrapper')}>
                          <JsonTreeContent data={schemaTree} format="text" />
                        </div>
                      </TabPane>
                    </Tabs>
                  ) : undefined}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default RequestTable;
