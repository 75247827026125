import React, { useEffect, useMemo, useState } from 'react';
import LogList, { MappingProps } from '../../../components/LogList';
import { Button, Col, Form, message, Modal, Row, Space } from 'antd';
import styles from './index.module.scss';
import { AttachmentUpload, FormContent, Wrapper } from '@maxtropy/components';
import usePageStatus, { PageStatus, PageStatusDisplay } from '../../../hooks/usePageStatus';
import { AppStatusDisplay, OperateTargetType } from '../../../api/openPlatform-const';
import useCountDownGotoModal from '../../../hooks/useCountDownGotoModal';
import { useNavigate, useParams } from 'react-router-dom';
import { ApplicationProps, getApplicationDetail, updateApplication } from '../../../api/openPlatform-application';
import classnames from 'classnames';
import useUpdate from '../../../hooks/useUpdate';
import Footer from '../../../components/Footer';
import usePlatformRole from '../../hooks/usePlatformRole';
import useIsGodRole from '../../hooks/useIsGodRole';
import ShowInput from '../../../components/ShowInput';
import { CopyOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { isNil } from 'lodash-es';

const mapping: MappingProps[] = [
  { label: '应用名称', dataIndex: 'name', type: 'string' },
  { label: '图片', dataIndex: 'logoResource', type: 'string' },
  { label: '备注', dataIndex: 'memo', type: 'string' },
  { label: '状态', dataIndex: 'status', type: 'enum', enumDisplay: AppStatusDisplay },
];

interface EditAppProps {}

const EditApp: React.FC<EditAppProps> = props => {
  const [form] = Form.useForm();
  const pageStatus = usePageStatus();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [detail, setDetail] = useState<ApplicationProps>();
  const [updateState] = useUpdate();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [modal, modalContextHolder] = Modal.useModal();

  const { isAdmin } = usePlatformRole(id!);
  const isGod = useIsGodRole();

  const [passwordVisible, setPasswordVisible] = useState(false);

  const { setOption, contextHolder } = useCountDownGotoModal(30);

  const routes = [
    { name: '开发者平台' },
    { name: '应用开发开放平台' },
    { name: '集成应用列表' },
    { name: `${PageStatusDisplay[pageStatus]}应用` },
  ];

  useEffect(() => {
    if (!id) return;
    getApplicationDetail(id).then(data => {
      setDetail(data);
      form.setFieldsValue({
        status: data.status,
        name: data.name,
        independentSoftwareVendorName: data.independentSoftwareVendorName,
        logoResource: data.logoResource,
        memo: data.memo,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSave = () => {
    if (id) {
      form.validateFields().then(() => {
        const values = form.getFieldsValue();
        let { status, ...restValues } = values;
        restValues = { ...restValues, logoResource: restValues.logoResource ?? null, memo: restValues.memo ?? null };
        updateApplication(id, { status }, restValues).then(() => {
          setOption({
            title: '编辑成功',
            okText: '返回列表',
            content: `编辑应用成功，即将返回列表`,
            keyboard: false,
            onOk() {
              navigate('/open-platform/open-platform/app');
            },
          });
        });
      });
    }
  };

  const onCancel = () => {
    modal.confirm({
      icon: null,
      content: '是否确定放弃，确定放弃将丢弃未保存信息并返回列表?',
      onOk: () => {
        navigate('/open-platform/open-platform/app');
      },
    });
  };

  const disabled = pageStatus === PageStatus.DETAIL;

  const appSecretNumber = useMemo(() => {
    let temp: string[] = [];
    [...new Array(detail?.appSecret?.length)].forEach(_ => {
      temp.push('*');
    });
    return temp;
  }, [detail?.appSecret]);

  return (
    <Wrapper routes={routes} className={classnames('open-platform-common-wrapper', styles.wrapper)}>
      {messageContextHolder}

      <Form name="app-form" form={form} layout="vertical">
        <FormContent title={'应用信息维护'}>
          <div style={{ marginLeft: 30 }}>
            <Row style={{ marginTop: 40 }}>
              <Col span={8}>
                <Form.Item label="应用ID" name="appKey">
                  <span>{detail?.appKey}</span>
                  <br />
                  <span>秘钥：</span>
                  {detail?.appSecret ? (
                    <>
                      <span>{passwordVisible ? detail?.appSecret : appSecretNumber}</span>
                      <Button
                        type="link"
                        icon={passwordVisible ? <EyeInvisibleOutlined /> : <EyeTwoTone />}
                        style={{ padding: 0, paddingLeft: 6 }}
                        onClick={() => {
                          setPasswordVisible(prevState => !prevState);
                        }}
                      />
                      <Button
                        type="link"
                        icon={<CopyOutlined />}
                        onClick={() => {
                          window.navigator.clipboard.writeText(detail?.appSecret ?? '');
                          messageApi.success('复制成功');
                        }}
                      />
                    </>
                  ) : (
                    '--'
                  )}

                  {/* {permissions?.includes(ButtonPermission.RESETSECRETKEY) && (isGod || isAdmin) && (
                    <>
                      <Secret id={id!} afterGenerate={update} />
                      <Prompt spanClassName={styles.prompt} title="出于安全考虑，密钥不会保存，请谨慎操作" />
                    </>
                  )} */}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item name="status" label="状态">
                  {/* <Radio.Group defaultValue={AppStatus.ENABLE} disabled={disabled}>
                    <Radio.Button value={AppStatus.ENABLE}>{AppStatusDisplay[AppStatus.ENABLE]}</Radio.Button>
                    <Radio.Button value={AppStatus.DISABLE}>{AppStatusDisplay[AppStatus.DISABLE]}</Radio.Button>
                  </Radio.Group> */}
                  {AppStatusDisplay[detail?.status!]}
                </Form.Item>
                {/* <Prompt spanClassName={styles.prompt} title="禁用APP时，已生成的密钥将失效，应用也不能再调用任何平台" /> */}
              </Col>
              <Col span={8}>
                <Form.Item label="创建人">{detail?.createByUsername}</Form.Item>
              </Col>
            </Row>
            <Row style={{ marginTop: 40 }}>
              <Col span={8}>
                <Form.Item label="应用名称" name="name">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="independentSoftwareVendorName" label="服务商">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="logoResource" label="图片上传" rules={[]}>
                  <AttachmentUpload disabled={disabled} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Form.Item name="memo" label="备注" rules={[{ type: 'string', max: 100, message: '不能超过100个字符' }]}>
                <ShowInput />
              </Form.Item>
            </Row>
          </div>
        </FormContent>
      </Form>
      <LogList mapping={mapping} id={id!} type={OperateTargetType.APP} updateState={updateState} />

      <Footer>
        <Space>
          {pageStatus === PageStatus.UPDATE ? (
            <>
              <Button type="primary" disabled={isGod ? false : !isAdmin} onClick={onSave}>
                保存
              </Button>
              <Button onClick={onCancel}>取消</Button>
            </>
          ) : (
            <Button onClick={() => navigate('/open-platform/open-platform/app')}>关闭</Button>
          )}
        </Space>
      </Footer>
      {contextHolder}
      {modalContextHolder}
    </Wrapper>
  );
};

export default EditApp;
