import React, { Key, Ref, useCallback, useImperativeHandle, useState } from 'react';
import { Form, Input, Col, Cascader } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';

import { Paging, Table, usePaging, useAsync } from '@maxtropy/components';
import {
  formatOptionData,
  getAllChild,
  getChildNodesByParentIds,
} from '@/shared/components/CascadingMultipleSelector/utils';
import { IotProtocolType } from '@/shared/types';
import Filter from '@/shared/components/Filter';
import { useQuery } from '../../../utils/utils';
import { getDevicePage, DevicePageRequest } from '../../../api/gateway';
import { Device } from '../../../api/device';
import { getDeviceTypeData } from '../../../api/attribute';
import { deviceColumns as columns } from '../Detail';
import styles from '../index.module.scss';
import { DefaultOptionType } from 'antd/es/cascader';

interface FilterParams {
  name?: string | undefined;
  tag?: string;
  typeId: Array<Array<number>> | undefined;
}

export interface DeviceModalRef {
  selectedRows?: Device[];
}

type SearchParams = Omit<DevicePageRequest, 'page' | 'size' | 'gatewayId' | 'iotProtocol'>;

interface DeviceModalProps {
  selectedItems: Device[];
  iotProtocol?: IotProtocolType;
}

const DeviceModal = React.forwardRef(({ selectedItems, iotProtocol }: DeviceModalProps, ref: Ref<DeviceModalRef>) => {
  const pagingInfo = usePaging(50);
  const [form] = Form.useForm();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [selectedRows, setSelectedRows] = useState<Device[]>();
  const { id } = useParams<{ id: string }>();

  const deviceTypeData = useAsync(getDeviceTypeData);

  const { data, isLoading } = useQuery(
    useCallback(async () => {
      if (iotProtocol && id) {
        const res = await getDevicePage({
          ...searchParams,
          page: pageOffset,
          size: pageSize,
          iotProtocol,
          gatewayId: id,
        });
        if (res) setTotalCount(res.total);
        return res.list;
      }
    }, [id, iotProtocol, pageOffset, pageSize, searchParams, setTotalCount])
  );

  const onReset = () => {
    form.resetFields();
    form.submit();
  };

  const onFinish = (val: FilterParams) => {
    // const deviceTypes = getAllChild(val.typeId, formatOptionData(deviceTypeData));
    const deviceTypes = getChildNodesByParentIds(val.typeId, deviceTypeData?.tree);
    const params: SearchParams = {
      name: val.name,
      tag: val.tag,
      deviceTypes,
    };
    setSearchParams(params);
    setPageOffset(1);
  };

  const onSelectChange = (rowKeys: Key[], rows: Device[]) => {
    const data = rows.filter(item => !!item).filter(item => !selectedItems?.map(s => s.id).includes(item.id));
    setSelectedRows(data);
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: (selectedRows || []).concat(selectedItems || []).map(item => item.id),
    getCheckboxProps: (record: Device) => ({ disabled: selectedItems?.map(s => s.id).includes(record.id) }),
    onChange: onSelectChange,
  };

  useImperativeHandle(ref, () => ({
    selectedRows,
  }));

  const length = (data || [])
    .filter(item => selectedItems?.map(s => s.id).includes(item.id))
    .concat(selectedRows || []).length;

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  return (
    <div className={styles.deviceModal}>
      <Filter<FilterParams> form={form} onFinish={val => onFinish(val as FilterParams)} onReset={onReset}>
        <>
          <Col span={6}>
            <Form.Item name="name" label="编号/名称">
              <Input placeholder={'请输入编号或名称查询'} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="typeId" label="所属类目">
              <Cascader
                options={formatOptionData(deviceTypeData)}
                allowClear={false}
                fieldNames={{ children: 'child' }}
                multiple
                maxTagCount="responsive"
                showSearch={{ filter }}
                placeholder={'请选择所属类目'}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="tag" label="标签">
              <Input placeholder="请输入" />
            </Form.Item>
          </Col>
        </>
      </Filter>
      <div className={styles.table_text}>
        <div className={styles.tips}>
          <span>已选择</span>
          <span className={styles.red}>{length}</span>
          <span>项</span>
          <InfoCircleOutlined style={{ margin: '0 4px 0 20px' }} />
          <span className={styles.grey}>只展示已经配置数采，且未绑定到其他网关的在用设备。</span>
        </div>
        <div className={styles.action}>
          <span className={styles.text} style={{ marginRight: 5 }}>
            点击前往
          </span>
          <Link to="/device/manage/device/create" target="_blank">
            添加设备
          </Link>
        </div>
      </div>
      <Table
        scroll={{ y: 300 }}
        rowKey="id"
        loading={isLoading}
        rowSelection={rowSelection}
        columns={columns()}
        dataSource={data}
      />
      <Paging pagingInfo={pagingInfo} />
    </div>
  );
});

export default DeviceModal;
