import { Tag } from 'antd';
import { useEffect, useState } from 'react';
import { getFourPermissionTree, PemissionTreeProps } from '../../../api/cc-tenant-ql';
interface Iprops {
  threeLevelCode: string[];
  integrateAppId: string;
}
const FourLevelPermissionSelect: React.FC<Iprops> = ({ threeLevelCode, integrateAppId }) => {
  const [data, setData] = useState<PemissionTreeProps[]>();

  // 根三级权限查询四级权限
  useEffect(() => {
    let code = threeLevelCode && threeLevelCode[2];
    if (code && integrateAppId) {
      getFourPermissionTree(integrateAppId, code).then(res => {
        if (res && res.length > 0) {
          setData(res);
        }
      });
    } else {
      setData([]);
    }
  }, [threeLevelCode, integrateAppId]);

  return <>{data?.map(item => <Tag key={item.code}>{item.name}</Tag>) ?? '--'}</>;
};

export default FourLevelPermissionSelect;
