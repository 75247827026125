import { useCallback, useState, FC, useMemo } from 'react';
import { RuleNames, RuleType } from '../../api/constants';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Card, Col, Input, Modal, Row, Space, Tag, Typography } from 'antd';
import { deleteRule, listRules } from '../../api/mockRule';
import { AreaChartOutlined, CopyOutlined, DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons';
import Code from '../../components/Code';
import PreviewRuleModal from './PreviewRuleModal';
import { Rule } from '../../api/entities';
import { PermissionsType } from '../../common/permissionsConst';
import { useHasPermission } from '../../utils/utils';
import { Wrapper, useAsync } from '@maxtropy/components';

const { Title } = Typography;

type OprateType = 'create' | 'update' | 'delete';

const permissionMap: Record<RuleType, Record<OprateType, PermissionsType>> = {
  [RuleType.UPLOAD_TIME]: {
    create: PermissionsType.B_CREATE_UPLOAD_RULE,
    update: PermissionsType.B_EDIT_UPLOAD_RULE,
    delete: PermissionsType.B_DELETE_UPLOAD_RULE,
  },
  [RuleType.DATA_TIME]: {
    create: PermissionsType.B_CREATE_DATA_TIME_RULE_CONFIG,
    update: PermissionsType.B_EDIT_DATA_TIME_RULE_CONFIG,
    delete: PermissionsType.B_DELETE_DATA_TIME_RULE_CONFIG,
  },
  [RuleType.DATA_VALUE]: {
    create: PermissionsType.B_CREATE_DATA_VALUE_RULE_CONFIG,
    update: PermissionsType.B_EDIT_DATA_VALUE_RULE_CONFIG,
    delete: PermissionsType.B_DELETE_DATA_VALUE_RULE_CONFIG,
  },
};

export interface RulesProps {
  type: RuleType;
}

const Rules: FC<RulesProps> = ({ type }) => {
  const [modalApi, modalContextHolder] = Modal.useModal();
  const hasCreate = useHasPermission(permissionMap[type].create);
  const hasUpdate = useHasPermission(permissionMap[type].update);
  const hasDelete = useHasPermission(permissionMap[type].delete);

  const location = useLocation();
  const navigate = useNavigate();
  const [counter, setCounter] = useState(0);
  const [keyword, setKeyword] = useState('');
  const focusParam = new URLSearchParams(location.search).get('focus');
  const listRulesWithType = useCallback(() => listRules(type), [type, counter]);
  const rules = useAsync(listRulesWithType, []).filter(r =>
    keyword.length ? r.name.includes(keyword) || r.description?.includes(keyword) : true
  );

  const [previewValue, setPreviewValue] = useState<Rule>();

  const onCancel = () => {
    setPreviewValue(undefined);
  };

  const doDelete = useCallback(
    (id: number, name: string) => {
      modalApi.confirm({
        content: `确定删除${RuleNames[type]}：${name} 吗？`,
        onOk: () => deleteRule(type, id).then(() => setCounter(c => c + 1)),
      });
    },
    [type]
  );

  const routes = useMemo(() => {
    return [{ name: '数据运营工具' }, { name: '数据模拟' }, { name: `${RuleNames[type]}配置` }];
  }, [type]);

  return (
    <Wrapper routes={routes}>
      <Title level={2}>{RuleNames[type]}列表</Title>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Row gutter={10}>
          <Col span={6}>
            <Input prefix={<SearchOutlined />} value={keyword} onChange={e => setKeyword(e.target.value.trim())} />
          </Col>
          {hasCreate && (
            <Col span={3}>
              <Button type="primary" onClick={() => navigate(`/data-tools/shaping/${type}/create`)}>
                创建规则
              </Button>
            </Col>
          )}
        </Row>
        <Row gutter={[16, 16]}>
          {rules.map(rule => (
            <Col span={6} key={rule.id}>
              <Card
                headStyle={focusParam && rule.id === +focusParam ? { backgroundColor: '#fffce0' } : {}}
                title={rule.name}
                extra={
                  <>
                    <Tag color="purple">{rule.constants} 参数</Tag>
                    {type === RuleType.DATA_VALUE && (
                      <>
                        &ensp;
                        <Button
                          type="link"
                          icon={<AreaChartOutlined />}
                          size="small"
                          onClick={() => {
                            setPreviewValue(rule);
                          }}
                        />
                      </>
                    )}
                    {hasCreate && (
                      <>
                        &ensp;
                        <Button
                          type="link"
                          icon={<CopyOutlined />}
                          size="small"
                          onClick={() => navigate(`/data-tools/shaping/${type}/create?copyFrom=${rule.id}`)}
                        />
                      </>
                    )}
                    {hasUpdate && (
                      <>
                        &ensp;
                        <Button
                          type="link"
                          icon={<EditOutlined />}
                          size="small"
                          onClick={() => navigate(`/data-tools/shaping/${type}/${rule.id}/update`)}
                        />
                      </>
                    )}
                    {hasDelete && (
                      <>
                        &ensp;
                        <Button
                          type="link"
                          icon={<DeleteOutlined />}
                          size="small"
                          onClick={() => doDelete(rule.id, rule.name)}
                        />
                      </>
                    )}
                  </>
                }
              >
                <p>{rule.description}</p>
                <Code>{rule.expression}</Code>
              </Card>
            </Col>
          ))}
        </Row>
      </Space>
      {type === RuleType.DATA_VALUE && <PreviewRuleModal onCancel={onCancel} previewValue={previewValue} />}
      {modalContextHolder}
    </Wrapper>
  );
};

export default Rules;
