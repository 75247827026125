import { fetch } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import qs from 'qs';
import { DataPropertyType } from '@/shared/types';
import dayjs from 'dayjs';

export enum MeasurementType {
  ACCUM = 1,
  INSTANT = 2,
}

export interface Company {
  id: number;
  name: string;
}

export function getDeviceCompany(): Promise<Company[]> {
  return fetch(`/api/device/scene/list`);
}

interface Device {
  id: number;
  name: string;
}

export interface DeviceTypes {
  id: number;
  name: string;
  allowAttribute?: boolean;
  parentId?: number;
  parentKey?: string;
  devices: Device[];
}

export interface DeviceTreeResponse {
  id: number;
  name: string;
  children: DeviceTypes[];
}

export type DataTypeDisplayName = Record<number, string>;

export interface DevicePoint {
  id: number;
  name: string;
  measurementType: MeasurementType;
  generalName?: string;
  type?: DataPropertyType;
  enumValue?: DataTypeDisplayName;
}

export function getDevicePoints(id: number): Promise<DevicePoint[]> {
  return fetch(`/api/dataProperty/third-party-list-by-device-id?deviceId=${id}`);
}

export enum FrequencyType {
  DAY = 'D',
  MONTH = 'M',
  YEAR = 'Y',
  ORIGINAL = 'O',
}

export interface HistoryDataRequest {
  deviceId: number;
  propertyId: number;
  frequency: FrequencyType;
  measurementType: MeasurementType;
  startTime?: number;
  endTime?: number;
}

export interface RawData {
  time: number | null;
  value: number | string | null;
  updateTime: string | null;
}

export function getHistoryData(params: HistoryDataRequest): Promise<RawData[]> {
  return fetch(`/api/history/data/resampled?${qs.stringify(params, { indices: false })}`);
}

export interface RawPageDataRequest extends PageRequest {
  deviceId: number;
  propertyId: number;
  startTime?: number;
  endTime?: number;
  type: DataPropertyType;
}

export type RawPageDataResponse = PageResponse<RawData>;

export function getRawPageData(params: RawPageDataRequest): Promise<RawPageDataResponse> {
  return fetch(`/api/history/data/raw/page?${qs.stringify(params, { indices: false })}`);
}

export function getRawStrPageData(params: RawPageDataRequest): Promise<RawPageDataResponse> {
  return fetch(`/api/history/data/raw/str/page?${qs.stringify(params, { indices: false })}`);
}

export interface RawChartDataRequest {
  deviceId: number;
  propertyId: number;
  startTime?: number;
  endTime?: number;
}

export function getRawChartData(params: RawChartDataRequest): Promise<RawData[]> {
  return fetch(`/api/history/data/raw/list?${qs.stringify(params, { indices: false })}`);
}

export interface DeviceSceneTreeRequest {
  tenantMcid: string;
  deviceName?: string;
}

export interface DeviceSceneTreeProps {
  id: number;
  name: string;
  deviceTypeList: DeviceTreeResponse[];
}

export function getDeviceSceneTree(params: DeviceSceneTreeRequest): Promise<DeviceSceneTreeProps[]> {
  return fetch(`/api/device/sceneTree?${qs.stringify(params, { indices: false })}`);
}

export interface HistoryDataExportRequest {
  deviceId: number;
  propertyId: number[];
  timeSolution: FrequencyType;
  startTime?: number;
  endTime?: number;
  type: string;
}
export function exportHistoryData(params: HistoryDataExportRequest) {
  return window.open(
    `/api/v2/history/data/exportExcel?${qs.stringify({ timestamp: dayjs().valueOf(), ...params }, { indices: false })}`
  );
}
