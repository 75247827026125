import React, { useState, useEffect } from 'react';
import { Form, Input, Row, Col, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Modal, EllipsisSpan, Table, Paging, usePaging } from '@maxtropy/components';
import {
  apiV2TenantPagePost,
  V2TenantPagePostResponse,
  apiV2TenantAddChannelMcidPost,
} from '@maxtropy/tody-mgmt-apis-v2';
import { TenantBaseInfoResponse } from '../../../api/cc-tenant';
import { BindTenantListProps } from '.';

interface AddTenantProps {
  tenant?: TenantBaseInfoResponse;
  bindTenantList: BindTenantListProps;
  onCancel: () => void;
  onOk: () => void;
}

type DataSourceProps = V2TenantPagePostResponse['list'];

type RowData = Exclude<V2TenantPagePostResponse['list'], undefined>[number];

const AddTenant = ({ tenant, onCancel, onOk, bindTenantList }: AddTenantProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [dataSource, setDataSource] = useState<DataSourceProps>([]);
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount } = pagingInfo;

  const [form] = Form.useForm();
  const codeOrName = Form.useWatch('codeOrName', form);

  const getTenantPage = () => {
    setLoading(true);
    apiV2TenantPagePost({
      codeOrName,
      channelMcid: tenant?.mcid,
      accountOpenStatus: true,
      page: pageOffset,
      size: pageSize,
    })
      .then(res => {
        setDataSource(res.list ?? []);
        setTotalCount(res.total ?? 0);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (bindTenantList?.length) {
      setSelectedRowKeys(bindTenantList.map(item => item.mcid as React.Key));
    }
  }, [bindTenantList]);

  useEffect(() => {
    getTenantPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageOffset, pageSize]);

  const columns = [
    {
      title: '租户Code',
      dataIndex: 'tenantCode',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '租户名称',
      dataIndex: 'name',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => setSelectedRowKeys(newSelectedRowKeys),
    getCheckboxProps: (record: RowData) => ({
      disabled: bindTenantList?.map(t => t.mcid).includes(record.mcid),
    }),
    preserveSelectedRowKeys: true,
  };

  const onBlur = () => getTenantPage();

  const handleOk = () => {
    const newTenantMcIds = selectedRowKeys.filter(i => !bindTenantList?.find(t => t.mcid === i));
    if (newTenantMcIds.length > 0) {
      apiV2TenantAddChannelMcidPost({
        tenantMcid: tenant?.mcid,
        channelLinkTenantMcid: newTenantMcIds as string[],
      }).then(() => {
        message.success('添加成功！');
        onOk();
      });
    } else {
      onOk();
    }
  };

  return (
    <Modal title="添加租户" size="large" open={true} bodyScroll onCancel={onCancel} onOk={handleOk}>
      <Form form={form}>
        <Row>
          <Col span={12}>
            <Form.Item name="codeOrName">
              <Input placeholder="请输入租户名称/Code搜索" suffix={<SearchOutlined />} onBlur={onBlur} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table<RowData>
        rowKey="mcid"
        loading={loading}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataSource}
      />
      <Paging pagingInfo={pagingInfo} />
    </Modal>
  );
};

export default AddTenant;
