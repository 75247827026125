import { Key, fetch } from '@maxtropy/components';

export interface CarbonEmissionProcessFieldFilterLogic {
  fieldPropertyId?: Key; // 字段属性id
  operateSign?: number; // 运算符号(1:等于 2:不等于)
  matchType?: number; // 匹配类型(1:匹配字段 2:自定义)
  matchValue?: string; // 匹配值(字段名称或自定义输入值)
}

export interface CarbonEmissionProcessEditFormField {
  id?: Key; // 字段id
  fieldName?: string; // 字段名称
  fieldType?: number; // 字段类型(1:数据关联 2:数值 3:输出)
  fieldPropertyId?: Key; // 字段属性id
  fieldPropertyType?: number; // 字段属性类型(1燃料 2碳酸盐...)
  fieldOrder?: number; // 字段序号
  fieldSource?: number; // 字段来源(1:排放因子)
  decimalDigit?: number; // 小数位数
  numericalExpression?: string; // 数值表达式(例:(0,100])
  filterExpression?: string; // 筛选表达式
  fieldFilterLogic?: CarbonEmissionProcessFieldFilterLogic[]; // 筛选逻辑
  businessType?: number; // 业务类型（1碳排放 2甲烷）
}

// 编辑碳排放过程表单字段
export const editCarbonEmissionProcessFormField = (body: CarbonEmissionProcessEditFormField) => {
  return fetch(
    `/api/v2/carbon/emission/process/editFormField`,
    {
      method: 'POST',
      body: JSON.stringify(body),
    },
    true
  );
};
