import { FC, useEffect, useMemo, useState } from 'react';
import { useParams, useNavigate, useLocation, Link } from 'react-router-dom';
import { Button, Tabs, Tag, Space } from 'antd';
import { Wrapper, useUpdate, BreadcrumbItemProps } from '@maxtropy/components';
import BaseInfo from '../BaseInfo';
import ContactPerson from '../ContactPerson';
import Accounts from '../Accounts';
import TenantFuncPermissions from '../TenantFuncPermissions';
import IntegratedApp from '../IntegratedApp';
import ChannelInfo from '../ChannelInfo';

import styles from './index.module.scss';
import { useHasPermission } from '../../../utils/hooks';
import { PermissionsType } from '../../../common/permissionsConst';
import { getTenatInfo, TenantBaseInfoResponse } from '../../../api/cc-tenant';

const { TabPane } = Tabs;

enum TabKeys {
  BASEINFO = 'baseinfo',
  ACCOUNTS = 'accound',
  CHANNEL = 'channel',
  CONTACTPERSON = 'contact-person',
  FUNCPERMISSIONS = 'function-permissions',
  INTEGRATEDAPP = 'integrated-app',
}

// prettier-ignore
const useGetFirstTabKey = (disabled: boolean) => {// NOSONAR
  const hasBaseInfo = useHasPermission(PermissionsType.PUPDATETENANTINFO);
  const hasChannelInfo = useHasPermission(PermissionsType.PCHANNELINFO);
  const hasAccounts = useHasPermission(PermissionsType.PTENANTOPENACCOUNT);
  const hasFuncPermissions = useHasPermission(
    disabled ? PermissionsType.PTENANTFUNCTIONPERMISSIONINFO : PermissionsType.PUPDATETENANTFUNCTIONPERMISSION
  );
  const hasContactPerson = useHasPermission(PermissionsType.PCUSTOMERCONTACTSINFO);
  const hasIntegratedApp = useHasPermission(PermissionsType.P_UPDATE_SUBSCRIPTION);
  return hasBaseInfo
    ? TabKeys.BASEINFO
    : hasAccounts
      ? TabKeys.ACCOUNTS
      : hasFuncPermissions
        ? TabKeys.CHANNEL
        : hasChannelInfo       
          ? TabKeys.FUNCPERMISSIONS
          : hasContactPerson
            ? TabKeys.CONTACTPERSON
            : hasIntegratedApp
              ? TabKeys.INTEGRATEDAPP
              : undefined;
};

interface CustomerTabProps {
  disabled?: boolean;
}

// prettier-ignore
const CustomerTab: FC<CustomerTabProps> = ({ disabled = false }) => {// NOSONAR
  const { mcid, key: routerKey } = useParams<{ mcid: string; key: string }>();
  const location = useLocation();
  const [routes, setRoutes] = useState<BreadcrumbItemProps[]>([]);
  const [tenant, setTenant] = useState<TenantBaseInfoResponse>();
  const navigate = useNavigate();
  const [updateState, updateFn] = useUpdate();

  const firstKey = useGetFirstTabKey(disabled);

  const publicPath = useMemo(() => {
    return location.pathname.split(mcid!)[0].slice(0, -1);
  }, [location, mcid]);

  useEffect(() => {
    if (!routerKey && firstKey) {
      navigate(`${publicPath}/${mcid}/${firstKey}`);
    }
  }, [navigate, mcid, routerKey, firstKey, publicPath]);

  const onChangeTab = (key: string) => {
    navigate(`${publicPath}/${mcid}/${key}`);
  };

  useEffect(() => {
    getTenatInfo(mcid!).then(res => {
      setTenant(res);
    });
  }, [mcid, updateState]);

  const onBreadcrumbRoutesChange = (value: BreadcrumbItemProps[]) => {
    setRoutes(value);
  };

  const filter = (
    <div className={styles.content}>
      <Space className={styles.titleWrapper}>
        <div className={styles.title}>{tenant?.name}</div>
        <Tag className={styles.tag}>{tenant?.tenantCode}</Tag>
      </Space>
      <Button>
        <Link to="/tenant/customer/manage">返回列表</Link>
      </Button>
    </div>
  );

  const baseTab = useHasPermission(PermissionsType.PUPDATETENANTINFO) ? (
    <TabPane tab="租户基础信息" key={TabKeys.BASEINFO}>
      {routerKey === TabKeys.BASEINFO ? (
        <BaseInfo
          disabled={disabled}
          tenant={tenant}
          onBreacrumbChange={routerKey === TabKeys.BASEINFO ? onBreadcrumbRoutesChange : undefined}
        />
      ) : null}
    </TabPane>
  ) : null;

  const accounts = useHasPermission(PermissionsType.PTENANTOPENACCOUNT) ? (
    <TabPane tab="租户组织和开户设定" key={TabKeys.ACCOUNTS}>
      {routerKey === TabKeys.ACCOUNTS ? (
        <Accounts
          mcid={String(mcid)}
          key={updateState}
          updateFn={updateFn}
          disabled={disabled}
          tenant={tenant}
          onBreacrumbChange={routerKey === TabKeys.ACCOUNTS ? onBreadcrumbRoutesChange : undefined}
        />
      ) : null}
    </TabPane>
  ) : null;

  const channel = useHasPermission(PermissionsType.PCHANNELINFO) ? (
    <TabPane tab="渠道端设置" key={TabKeys.CHANNEL}>
      {routerKey === TabKeys.CHANNEL ? (
        <ChannelInfo
            disabled={disabled}
                  tenant={tenant}
                  updateFn={updateFn}
            onBreacrumbChange={routerKey === TabKeys.CHANNEL ? onBreadcrumbRoutesChange : undefined}
        />
      ) : null}
    </TabPane>
  ) : null;

  const funcPermissions = useHasPermission(
    disabled ? PermissionsType.PTENANTFUNCTIONPERMISSIONINFO : PermissionsType.PUPDATETENANTFUNCTIONPERMISSION
  ) ? (
    <TabPane tab="租户功能权限" key={TabKeys.FUNCPERMISSIONS}>
      {routerKey === TabKeys.FUNCPERMISSIONS ? (
        <TenantFuncPermissions
          disabled={disabled}
          tenant={tenant}
          onBreacrumbChange={routerKey === TabKeys.FUNCPERMISSIONS ? onBreadcrumbRoutesChange : undefined}
        />
      ) : null}
    </TabPane>
  ) : null;

  const contactPerson = useHasPermission(PermissionsType.PCUSTOMERCONTACTSINFO) ? (
    <TabPane tab="联系人信息" key={TabKeys.CONTACTPERSON}>
      {routerKey === TabKeys.CONTACTPERSON ? (
        <ContactPerson
          disabled={disabled}
          tenant={tenant}
          onBreacrumbChange={routerKey === TabKeys.CONTACTPERSON ? onBreadcrumbRoutesChange : undefined}
        />
      ) : null}
    </TabPane>
  ) : null;

  const integratedApp = useHasPermission(PermissionsType.P_UPDATE_SUBSCRIPTION) ? (
    <TabPane tab="集成应用订阅" key={TabKeys.INTEGRATEDAPP}>
      {routerKey === TabKeys.INTEGRATEDAPP ? (
        <IntegratedApp
          disabled={disabled}
          tenant={tenant}
          onBreacrumbChange={routerKey === TabKeys.INTEGRATEDAPP ? onBreadcrumbRoutesChange : undefined}
        />
      ) : null}
    </TabPane>
  ) : null;

  return (
    <Wrapper
      routes={routes}
      filters={filter}
      filtersStyle={{
        position: 'sticky',
        top: 0,
        padding: 0,
        background: '#FFFFFF',
        zIndex: 1000,
        boxShadow: '2px 2px 5px 0px rgba(209, 209, 209, 0.5)',
      }}
      className={styles.container}
    >
      <Tabs className={styles.tabs} activeKey={routerKey} onChange={onChangeTab}>
        {baseTab}
        {accounts}
        {channel}
        {funcPermissions}
        {contactPerson}
        {integratedApp}
      </Tabs>
    </Wrapper>
  );
};

export default CustomerTab;
