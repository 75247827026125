import { Cascader, Col, Form, Input, Row, Select } from 'antd';
import React, { Key, useCallback, useMemo, useState } from 'react';
import Filter from '@/shared/components/Filter';
import CascadingMultipleSelector from '@/shared/components/CascadingMultipleSelector';
import {
  formatOptionData,
  getAllChild,
  getChildNodesByParentIds,
} from '@/shared/components/CascadingMultipleSelector/utils';
import { getDeviceTypeData } from '../../../api/attribute';

import { Paging, Table, usePaging, useAsync } from '@maxtropy/components';
import { useQuery } from '../../../utils/utils';
import { templateColumns } from '../utils';
import { getTemplateList, Status, TemplateListProps, TemplateListRequest } from '../../../api/template';
import { getManufacturerList } from '../../../api/edgeTemplate';
import { getPhysicalModelList } from '../../../api/device';
import { DefaultOptionType } from 'antd/es/cascader';

interface TemplateModalProps {
  value?: TemplateListProps[];
  onChange?: (value: TemplateListProps[]) => void;
  disabledIds?: Key[];
}

interface FilterTemplateListRequest {
  deviceType: Array<Array<number>> | undefined;
  name?: string;
}

interface SearchParamsProps {
  name?: string;
  deviceType?: Array<number> | undefined;
}

const TemplateModal: React.FC<TemplateModalProps> = ({ value, onChange, disabledIds }) => {
  const [form] = Form.useForm();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchParamsProps>();
  const [templates, setTemplates] = useState<TemplateListProps[]>([]);

  const deviceTypeData = useAsync(getDeviceTypeData);

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        getTemplateList({
          ...searchParams,
          status: Status.ENABLE,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) {
            setTotalCount(res.total);
            setTemplates(res.list);
          }
          return res.list;
        }),
      [pageOffset, pageSize, searchParams, setTotalCount]
    )
  );

  const templateIds = useMemo(() => {
    return (value ?? []).map(i => i.id);
  }, [value]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: TemplateListProps[]) => {
      const templatesId = templates.map(i => i.id);
      const buildDataMap = new Map<number, TemplateListProps>();
      (value ?? [])
        .filter(i => !templatesId.includes(i.id))
        .forEach(i => {
          if (!buildDataMap.has(i.id)) {
            buildDataMap.set(i.id, i);
          }
        });
      selectedRows.forEach(i => {
        if (!buildDataMap.has(i.id)) {
          buildDataMap.set(i.id, i);
        }
      });
      onChange && onChange(Array.from(buildDataMap.values()));
    },
    getCheckboxProps: (record: TemplateListProps) => ({
      disabled: (disabledIds ?? []).includes(record.id),
    }),
    selectedRowKeys: templateIds,
  };

  const onReset = () => {
    setSearchParams({});
    setPageOffset(1);
  };

  const onFinish = (val: FilterTemplateListRequest) => {
    // const deviceType = getAllChild(val.deviceType, formatOptionData(deviceTypeData));
    const deviceType = getChildNodesByParentIds(val.deviceType, deviceTypeData?.tree);
    setSearchParams({
      ...val,
      deviceType,
    });
    setPageOffset(1);
  };
  const manufacturerId = Form.useWatch('manufacturerId', form);
  const deviceType = Form.useWatch('deviceType', form);
  const manufacturerList = useAsync(getManufacturerList, []);

  const physicalModelList = useAsync(
    useCallback(() => {
      const deviceTypeIds = [deviceType];
      const params = {
        deviceTypeIds,
        manufacturerId,
      };
      return getPhysicalModelList(params);
    }, [deviceType, manufacturerId])
  );

  const manufacturerOptions = useMemo(() => {
    if (manufacturerList && manufacturerList.length > 0) {
      return manufacturerList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [manufacturerList]);
  const objectModalTypeOptions = useMemo(() => {
    if (physicalModelList && physicalModelList.length > 0) {
      return physicalModelList.map(i => ({ label: i.modelNo, value: i.id }));
    }
  }, [physicalModelList]);

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const filters = (
    <Filter form={form} onFinish={val => onFinish(val as FilterTemplateListRequest)} onReset={onReset}>
      <>
        <Col span={8}>
          <Form.Item name="deviceType" label="所属类目">
            <Cascader
              options={formatOptionData(deviceTypeData)}
              allowClear={false}
              fieldNames={{ children: 'child' }}
              multiple
              maxTagCount="responsive"
              showSearch={{ filter }}
              placeholder={'请选择所属类目'}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="编号/名称" name="name">
            <Input placeholder="请输入编号或名称查询" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="manufacturerId" label="厂商">
            <Select
              optionFilterProp="label"
              placeholder="请选择"
              showSearch
              options={manufacturerOptions}
              onChange={() => {
                form.setFieldsValue({
                  physicalModelId: undefined,
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="physicalModelId" label="物模型型号">
            <Select optionFilterProp="label" placeholder="请选择" showSearch options={objectModalTypeOptions} />
          </Form.Item>
        </Col>
      </>
    </Filter>
  );

  return (
    <>
      {filters}
      <Table
        loading={isLoading}
        sticky
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        rowKey="id"
        scroll={{ y: 300 }}
        columns={templateColumns}
        dataSource={data}
      />
      <Paging pagingInfo={pagingInfo} />
    </>
  );
};

export default TemplateModal;
