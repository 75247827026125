import { fetch } from '@maxtropy/components';
import qs from 'qs';
import { Gender, UserStatus } from './cc-const';

export interface Paged<T> {
  total: number;
  offset: number;
  count: number;
  contents: T[];
}

export interface PageRequest {
  offset?: number;
  count?: number;
}

export interface CorpUser {
  id: number;
  username: string;
  name: string;
  cellphone: string;
}

export interface CustomerUser {
  muid: string;
  name: string;
  username: string;
  cellphone: string;
  disabled: boolean;
  expired: boolean;
  createTime: string;
  updateTime: string;
}

export interface UpdatePasswordRequest {
  newPassword: string;
  oldPassword: string;
  validNewPassword: string;
}

export interface InitOperationPasswordRequest {
  operationPassword: string;
  validateOperationPassword: string;
}

export interface UpdateOperationPasswordRequest {
  newOperationPassword: string;
  oldOperationPassword: string;
  validNewOperationPassword: string;
}

export interface OperateUserRequest {
  corpUserId: number;
}

export interface OperateUserResponse {
  id: number;
  username: string;
  name: string;
  cellphone: string;
  disabled: boolean;
  passwordUpdateTime: string;
  headPic: string;
  gender: number;
  birthday: string;
  status: number;
  parentId: number;
  createTime: string;
  updateTime: string;
  parentName: string;
}

export interface GetUserListRequest extends PageRequest {
  id?: number;
  name?: string;
  cellphone?: string;
  status?: number;
  parentId?: number;
}

export interface CreatePlatformUserRequest {
  username: string;
  name: string;
  cellphone: string;
  email: string;
  gender: Gender;
  birthday: string;
  status: UserStatus;
  parentId?: number;
  headPic?: string;
}

export interface PlatformUserResponse extends CreatePlatformUserRequest {
  id: number;
}

export interface UpdatePlatformUserRequest {
  id: number;
  status: UserStatus;
  parentId?: number;
  headPic?: string;
}

export async function getCurrentUser(): Promise<CorpUser> {
  return await fetch('/api/user/current');
}

export function updatePassword(body: UpdatePasswordRequest): Promise<boolean> {
  return fetch(`/api/user/update-password`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function existOperationPassword(params: { corpUserId: number | undefined }): Promise<boolean> {
  return fetch(`/api/user/exist-operation-password?corpUserId=${params.corpUserId}`);
}

export function initOperationPassword(body: InitOperationPasswordRequest): Promise<boolean> {
  return fetch(`/api/user/init-operation-password`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function updateOperationPassword(body: UpdateOperationPasswordRequest): Promise<boolean> {
  return fetch(`/api/user/update-operation-password`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
}

export function enableUser(body: OperateUserRequest): Promise<boolean> {
  return fetch(`/api/user/enable?corpUserId=${body.corpUserId}`);
}

export function freezeUser(body: OperateUserRequest): Promise<boolean> {
  return fetch(`/api/user/freeze?corpUserId=${body.corpUserId}`);
}

export function resetPassword(body: OperateUserRequest): Promise<boolean> {
  return fetch(`/api/user/reset-password?corpUserId=${body.corpUserId}`);
}

export function disableUser(body: OperateUserRequest): Promise<boolean> {
  return fetch(`/api/user/disable?corpUserId=${body.corpUserId}`);
}

export function getUserList(query: GetUserListRequest): Promise<Paged<OperateUserResponse>> {
  return fetch(`/api/user/page?${qs.stringify(query, { indices: false })}`);
}

export function getAllUser(): Promise<{ list: OperateUserResponse[] }> {
  return fetch(`/api/user/getAll`);
}

export function createPlatformUser(params: CreatePlatformUserRequest): Promise<PlatformUserResponse> {
  return fetch('/api/user/create', {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export function updatePlatformUser(params: UpdatePlatformUserRequest): Promise<PlatformUserResponse> {
  return fetch('/api/user/update', {
    method: 'PUT',
    body: JSON.stringify(params),
  });
}

export function getPlatformUser(id: number): Promise<PlatformUserResponse> {
  return fetch(`/api/user/get?corpUserId=${id}`);
}
