import { FC, useEffect, useMemo } from 'react';
import { Form, Row, Col, Button, Select, Divider, Space } from 'antd';
import { Wrapper, FormContent, useAsync } from '@maxtropy/components';
import { useNavigate, useParams } from 'react-router-dom';
import {
  updatePlatformUser,
  UpdatePlatformUserRequest,
  getPlatformUser,
  PlatformUserResponse,
  getAllUser,
} from '../../api/cc-user';

import styles from './index.module.scss';
import { GenderDisplay, UserStatus, UserStatusDisplay } from '../../api/cc-const';
import { useCurrent } from '@/components/Layout/hooks';
import ShowInput from '@/components/ShowInput';

const { Option } = Select;
const { useForm } = Form;

const formLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const tailLayout = {
  wrapperCol: { offset: 2, span: 16 },
};

const EditPlateformEmployee: FC<{ isEdit?: boolean }> = ({ isEdit = true }) => {
  const self = useCurrent();
  const { id } = useParams<{ id: string }>();
  const [form] = useForm();

  const routes = useMemo(() => {
    return [
      { name: '系统设置' },
      { name: '平台组织权限和账户' },
      { name: '员工管理' },
      { name: `${isEdit ? '编辑' : '查看'}运营人员` },
    ];
  }, [isEdit]);

  const navigate = useNavigate();
  const parents = useAsync(getAllUser);

  const parentsOption = useMemo(() => {
    if (!parents || !self) return [];
    return parents.list
      .filter(i => i.id !== self.id)
      .map(i => ({
        value: i.id,
        label: i.name,
      }));
  }, [parents, self]);

  useEffect(() => {
    getPlatformUser(Number(id)).then(res => {
      form.setFieldsValue({
        ...res,
        gender: GenderDisplay[res.gender],
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onFinish = (data: PlatformUserResponse) => {
    const newData: UpdatePlatformUserRequest = {
      id: data.id,
      status: data.status ?? undefined,
      parentId: data.parentId ?? undefined,
      headPic: data.headPic ?? undefined,
    };
    updatePlatformUser(newData).then(res => {
      navigate(`/platform/account/employee/function/edit/${res.id}`);
    });
  };

  return (
    <Wrapper routes={routes}>
      <div className={styles.container}>
        <Form form={form} layout="vertical" {...formLayout} onFinish={onFinish}>
          <FormContent title="员工基础信息">
            <Row>
              <Col span={8}>
                <Form.Item name="id" label="员工ID">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="name" label="员工姓名">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="username" label="账号">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="cellphone" label="手机号码">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="email" label="邮箱">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="birthday" label="生日">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="gender" label="性别">
                  <ShowInput />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="status" label="用户状态">
                  <Select style={{ width: '100%' }} disabled={!isEdit}>
                    <Option value={UserStatus.ENABLE}>{UserStatusDisplay[UserStatus.ENABLE]}</Option>
                    <Option value={UserStatus.DISABLE}>{UserStatusDisplay[UserStatus.DISABLE]}</Option>
                    <Option value={UserStatus.FREEZE}>{UserStatusDisplay[UserStatus.FREEZE]}</Option>
                  </Select>
                </Form.Item>
              </Col>
              {/*<Col span={8}>*/}
              {/*  <Form.Item name="headPic" label="用户头像">*/}
              {/*    <AttachmentUpload disabled={!isEdit}/>*/}
              {/*  </Form.Item>*/}
              {/*</Col>*/}
            </Row>
          </FormContent>
          <Divider />
          <FormContent title="组织关系维护">
            <Row>
              <Col span={8}>
                <Form.Item name="parentId" label="上级">
                  <Select
                    style={{ width: '100%' }}
                    disabled={!isEdit}
                    options={parentsOption}
                    placeholder="请选择"
                    showSearch
                    filterOption={(inputValue, option) => {
                      return (option?.label ?? '').includes(inputValue);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </FormContent>
          <Row className={styles.buttonWrapper}>
            <Col span={7}>
              <Form.Item {...tailLayout}>
                <Space>
                  {isEdit ? (
                    <>
                      <Button
                        onClick={() => {
                          navigate(`/platform/account/employee`);
                        }}
                      >
                        取消
                      </Button>
                      <Button htmlType="submit" type="primary">
                        下一步
                      </Button>
                    </>
                  ) : (
                    <>
                      {/* <Button type="primary" onClick={() => {history.push(`/platform/account/employee/edit/${id}`)}}>编辑</Button> */}
                      <Button
                        type="primary"
                        onClick={e => {
                          e.preventDefault();
                          navigate(`/platform/account/employee`);
                        }}
                      >
                        关闭
                      </Button>
                      <Button
                        onClick={e => {
                          e.preventDefault();
                          navigate(`/platform/account/employee/function/show/${id}`);
                        }}
                      >
                        下一步
                      </Button>
                    </>
                  )}
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Wrapper>
  );
};

export default EditPlateformEmployee;
