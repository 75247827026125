import { Col, Form, FormInstance, Input, InputNumber, Row, Select } from 'antd';
import React from 'react';
import {
  AddrOffsetOptions,
  BuadRateOptions,
  CheckType,
  CrcOrder,
  DataBitOptions,
  DriveType,
  StopBitOptions,
} from '../../../types';
import { MockingbirdFormValues } from '../types';
import { CheckTypeOptions, CrcOrderOptions, halfFormItemLayout, SerialPortOptions } from '../common';
import IpAddress, { ipAddressValidator } from '../../IpAddress';

interface SharedMockingbirdFormProps {
  form: FormInstance<MockingbirdFormValues>;
}

const SharedMockingbirdForm: React.FC<SharedMockingbirdFormProps> = ({ form }) => {
  return (
    <Row>
      <Form.Item noStyle shouldUpdate>
        {() => {
          const { driveType } = form.getFieldsValue();
          return (
            <>
              {driveType === DriveType.MODBUS_RTU && (
                <>
                  <Col span={12}>
                    <SerialPortField />
                  </Col>
                  <Col span={12}>
                    <StationNumField />
                  </Col>
                  <Col span={12}>
                    <BaudrateField />
                  </Col>
                  <Col span={12}>
                    <CheckTypeField />
                  </Col>
                  <Col span={12}>
                    <DataBitField />
                  </Col>
                  <Col span={12}>
                    <StopBitField />
                  </Col>
                  <Col span={12}>
                    <CrcOrderField />
                  </Col>
                  <Col span={12}>
                    <GroupWordsField />
                  </Col>
                  <Col span={12}>
                    <AddrOffsetField />
                  </Col>
                </>
              )}
              {driveType === DriveType.MODBUS_TCP && (
                <>
                  <Col span={12}>
                    <IpField />
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      {...halfFormItemLayout}
                      label="TCP端口"
                      name="port"
                      validateFirst
                      rules={[
                        { required: true, message: '请输入TCP端口' },
                        { validator: limitedIntegerValidatorBuilder(65535, 0) },
                      ]}
                    >
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <StationNumField />
                  </Col>
                  <Col span={12}>
                    <CrcOrderField />
                  </Col>
                  <Col span={12}>
                    <GroupWordsField />
                  </Col>
                  <Col span={12}>
                    <AddrOffsetField />
                  </Col>
                </>
              )}
              {(driveType === DriveType.DLT645_1997 || driveType === DriveType.DLT645_2007) && (
                <>
                  <Col span={12}>
                    <SerialPortField />
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      {...halfFormItemLayout}
                      label="表计地址域"
                      name="address"
                      validateFirst
                      rules={[
                        { required: true, message: '请输入表计地址域' },
                        { max: 32, message: '最多输入32个字符' },
                        { pattern: /^[0-9A-Fa-f]*$/, message: '请输入十六进制' },
                      ]}
                    >
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      {...halfFormItemLayout}
                      label="前导地址"
                      name="leadByte"
                      validateFirst
                      rules={[
                        { required: true, message: '请输入前导地址' },
                        { max: 16, message: '最多输入16个字符' },
                      ]}
                    >
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <BaudrateField />
                  </Col>
                  <Col span={12}>
                    <CheckTypeField />
                  </Col>
                  <Col span={12}>
                    <DataBitField />
                  </Col>
                  <Col span={12}>
                    <StopBitField />
                  </Col>
                </>
              )}

              {driveType === DriveType.IEC104 && (
                <>
                  <Col span={12}>
                    <IpField />
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      {...halfFormItemLayout}
                      label="端口号"
                      name="port"
                      validateFirst
                      rules={[
                        { required: true, message: '请输入TCP端口' },
                        { validator: limitedIntegerValidatorBuilder(65535, 0) },
                      ]}
                    >
                      <Input placeholder="请输入" />
                    </Form.Item>
                  </Col>
                </>
              )}
            </>
          );
        }}
      </Form.Item>

      <Col span={12}>
        <SamplingIntervalField />
      </Col>
      <Col span={12}>
        <SamplingTimeoutField />
      </Col>
      <Col span={12}>
        <SamplingRetryField />
      </Col>
    </Row>
  );
};

const SerialPortField = () => (
  <Form.Item
    {...halfFormItemLayout}
    label="串口地址"
    name="serialPort"
    rules={[{ required: true, message: '请选择串口地址' }]}
  >
    <Select placeholder="请选择" style={{ width: '100%' }}>
      {SerialPortOptions.map(item => (
        <Select.Option key={item.value} value={item.value}>
          {item.label}
        </Select.Option>
      ))}
    </Select>
  </Form.Item>
);

const StationNumField = () => (
  <Form.Item
    {...halfFormItemLayout}
    label="Modbus站号"
    name="stationNum"
    validateFirst
    rules={[
      { required: true, message: '请输入表计Modbus站号' },
      {
        validator: limitedIntegerValidatorBuilder(254),
      },
    ]}
  >
    <InputNumber placeholder="请输入" style={{ width: '100%' }} />
  </Form.Item>
);

const BaudrateField = () => (
  <Form.Item
    {...halfFormItemLayout}
    label="波特率"
    name="baudRate"
    initialValue={9600}
    rules={[{ required: true, message: '请选择波特率' }]}
  >
    <Select placeholder="请选择" style={{ width: '100%' }}>
      {BuadRateOptions.map(item => (
        <Select.Option key={item.value} value={item.value}>
          {item.label}
        </Select.Option>
      ))}
    </Select>
  </Form.Item>
);

const CheckTypeField = () => (
  <Form.Item
    {...halfFormItemLayout}
    label="校验"
    name="checkType"
    initialValue={CheckType.NULL}
    rules={[{ required: true, message: '请选择停止位' }]}
  >
    <Select placeholder="请选择" style={{ width: '100%' }}>
      {CheckTypeOptions.map(i => (
        <Select.Option key={i.value} value={i.value}>
          {i.label}
        </Select.Option>
      ))}
    </Select>
  </Form.Item>
);

const DataBitField = () => (
  <Form.Item
    {...halfFormItemLayout}
    label="数据位"
    name="dataBit"
    initialValue={8}
    rules={[{ required: true, message: '请选择数据位' }]}
  >
    <Select placeholder="请选择" style={{ width: '100%' }}>
      {DataBitOptions.map(item => (
        <Select.Option key={item.value} value={item.value}>
          {item.label}
        </Select.Option>
      ))}
    </Select>
  </Form.Item>
);

const StopBitField = () => (
  <Form.Item
    {...halfFormItemLayout}
    label="停止位"
    name="stopBit"
    initialValue={1}
    rules={[{ required: true, message: '请选择停止位' }]}
  >
    <Select placeholder="请选择" style={{ width: '100%' }}>
      {StopBitOptions.map(item => (
        <Select.Option key={item.value} value={item.value}>
          {item.label}
        </Select.Option>
      ))}
    </Select>
  </Form.Item>
);

const CrcOrderField = () => (
  <Form.Item
    {...halfFormItemLayout}
    label="CRC字节序"
    name="crcOrder"
    initialValue={CrcOrder.SMALL_END}
    rules={[{ required: true, message: '请选择CRC字节序' }]}
  >
    <Select placeholder="请选择" style={{ width: '100%' }}>
      {CrcOrderOptions.map(i => (
        <Select.Option key={i.value} value={i.value}>
          {i.label}
        </Select.Option>
      ))}
    </Select>
  </Form.Item>
);

const GroupWordsField = () => (
  <Form.Item
    {...halfFormItemLayout}
    className="ant-form-item__longLabel"
    label="最大单次请求长度"
    name="groupWords"
    initialValue={60}
    validateFirst
    rules={[
      { required: true, message: '请输入最大单次请求长度' },
      {
        validator: limitedIntegerValidatorBuilder(60),
      },
    ]}
  >
    <Input addonAfter="字(word)" placeholder="请输入" />
  </Form.Item>
);

const SamplingIntervalField = () => (
  <Form.Item
    {...halfFormItemLayout}
    label="采集间隔"
    name="samplingInterval"
    initialValue={25}
    validateFirst
    rules={[
      { required: true, message: '请输入采集间隔' },
      {
        validator: limitedIntegerValidatorBuilder(99999999),
      },
    ]}
  >
    <Input addonAfter="ms" placeholder="请输入" />
  </Form.Item>
);

const SamplingTimeoutField = () => (
  <Form.Item
    {...halfFormItemLayout}
    label="采集超时"
    name="samplingTimeout"
    initialValue={1000}
    validateFirst
    rules={[{ required: true, message: '请输入采集超时' }, { validator: limitedIntegerValidatorBuilder(25000) }]}
  >
    <Input addonAfter="ms" placeholder="请输入" />
  </Form.Item>
);

const SamplingRetryField = () => (
  <Form.Item
    {...halfFormItemLayout}
    label="重试次数"
    name="samplingRetry"
    initialValue={3}
    validateFirst
    rules={[{ required: true, message: '请输入重试次数' }, { validator: limitedIntegerValidatorBuilder(9) }]}
  >
    <InputNumber style={{ width: '100%' }} placeholder="请输入" />
  </Form.Item>
);

const AddrOffsetField = () => (
  <Form.Item
    {...halfFormItemLayout}
    label="首地址偏移"
    name="addrOffset"
    initialValue={0}
    rules={[{ required: true, message: '请选择首地址偏移' }]}
  >
    <Select placeholder="请选择首地址偏移" style={{ width: '100%' }}>
      {AddrOffsetOptions.map(item => (
        <Select.Option key={item.value} value={item.value}>
          {item.label}
        </Select.Option>
      ))}
    </Select>
  </Form.Item>
);

const IpField = () => (
  <Form.Item
    {...halfFormItemLayout}
    label="IP地址"
    name="ip"
    validateFirst
    validateTrigger="onBlur"
    rules={[{ required: true, message: '请输入IP地址' }, { validator: ipAddressValidator }]}
  >
    <IpAddress />
  </Form.Item>
);

export const limitedIntegerValidatorBuilder =
  (max: number, min = 1) =>
  (_: any, value: number | string, callback: (message?: string) => void) => {
    const num = Number(value);
    const isOk = num >= min && num <= max;
    const isFloat = String(num).indexOf('.') >= 0;
    if ((!isOk || isFloat) && value !== undefined) {
      callback(`输入${min}~${max}内的整数`);
    }
    callback();
  };

export default SharedMockingbirdForm;
