import { fetch } from '@maxtropy/components';
import { PageRequest } from './page';
import qs from 'qs';
// 地理位置
// 行政区域查询
interface LocationReq {
  keywords?: string;
  subdistrict?: string;
}
export type LocationResponse = {
  name: string;
  adcode: string;
  districts?: Location[];
};
export function getDistrict(params: LocationReq) {
  return fetch<LocationResponse>(`/api/location/district?${qs.stringify(params, { indices: false })}`);
}
// 获取输入框提示
interface AddressTips {
  keywords?: string;
  city?: string;
  cityLimit?: boolean;
}
export interface AddressTipsResponse {
  id?: string;
  name?: string;
  address?: string;
  location?: string;
  district?: string;
}
export function getAddressTips(params: AddressTips) {
  return fetch<{ tips?: AddressTipsResponse[] }>(`/api/location/inputTips?${qs.stringify(params, { indices: false })}`);
}

export interface LngLat {
  latitude: number;
  longitude: number;
}

interface TiltAzimuth {
  tilt: number;
  azimuth: number;
}
export function getOptimalAngles(params: LngLat) {
  return fetch<TiltAzimuth>(`/api/pvestimate/optimal_angles?${qs.stringify(params, { indices: false })}`);
}

export interface PvIrradiationReq extends LngLat {
  altitude: string;
  surfaceTilt: string;
  surfaceAzimuth: string;
  ratedPower: string;
}

export interface PvIrradiationRes {
  acPower: number;
  effectiveIrradiance: number;
}

export function getPvEstimation(params: PvIrradiationReq) {
  return fetch<PvIrradiationRes>(`/api/pvestimate/pv_estimation?${qs.stringify(params, { indices: false })}`);
}

export interface PvPowerReq {
  remark?: string;
  capacity: number;
  surfaceTilt: number;
  surfaceAzimuth: number;
  effectiveIrradiance: number;
  acPower: number;
  acOfFirstYear: number;
  acOfLater: number;
  cOfCarbonDust: number;
  cOfCo2: number;
  cOfSo2: number;
  cOfNox: number;
}

interface TaskInfo {
  taskId: number;
  msg: string;
}
export const getPvPower = (params: PvPowerReq) =>
  fetch<TaskInfo>(`/api/pvestimate/gc`, {
    method: 'POST',
    body: JSON.stringify(params),
  });

export interface PvProfitReq {
  remark?: string;
  gcInfoSource: number;
  gcInfoFileKey?: string;
  gcEstimateTaskFileKey?: string;
  surplusElecInternetRatio: number;
  internetElecPrice: number;
  municipalElecPriceFileKey: string;
  totalPackageElecPrice: string;
  estimationType: number;
  saleElecPriceDiscount?: number;
}

export const getPvProfit = (params: any) => {
  return fetch<TaskInfo>(`/api/pvestimate/incomeEstimate`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
};

export interface ResultListReq extends PageRequest {
  taskSource: string;
  remark: string;
}

export interface Result {
  id: number;
  remark: string;
  createTime: string;
  updateTime: string;
  corpUserId: number;
  taskStatus: number;
  fileKey: string;
  taskSource: number;
}

export interface ResultListRes {
  records: Result[];
  total: number;
}
export const getPvEstimationResultList = (params: ResultListReq) => {
  return fetch<ResultListRes>(`/api/pvestimate/page?${qs.stringify(params, { indices: false })}`);
};
