import { PlusSquareOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import styles from '../index.module.scss';
const ParentNodesTitle = (props: { label?: string; onClick?: () => void }) => {
  return (
    <div className={styles.parent_node}>
      <span className={styles.label}>{props.label}</span>
      <span
        className={styles.op}
        onClick={e => {
          e.stopPropagation();
          props.onClick?.();
        }}
      >
        <Tooltip placement="top" title="新建属性组">
          <PlusSquareOutlined />
        </Tooltip>
      </span>
    </div>
  );
};

export default ParentNodesTitle;
