import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useUpdate } from '@maxtropy/components';
import { FormInstance, Switch } from 'antd';
import { MenuType, MenuVisibilityType } from './types';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import {
  apiMiniAppCategoryEditSortingPost,
  apiMiniAppMenuEditPost,
  apiMiniAppMenuGetListGet,
  MiniAppAddOrUpdatePartOnePostRequest,
  MiniAppMenuGetListGetResponse,
} from '@maxtropy/cc-mgmt-apis';
import { groupBy } from 'lodash-es';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import MenuIconRenderer from './MenuIconRenderer';
import styles from './index.module.scss';
import ToolCenter from './ToolCenter';
import CustomPage from './CustomPage';
import AddToolsAndMenusModal from './ToolsAndMenusModal/ToolsAndMenusModal';
import CardHomePage from './CardHomePage';
import classnames from 'classnames/bind';
import { V2MiniAppToolGetListPostResponse, apiV2MiniAppToolGetListPost } from '@maxtropy/cc-mgmt-apis-v2';
import { updateCurrentMenuIndex } from './utils';

const cx = classnames.bind(styles);

interface Props {
  listId?: number;
  form?: FormInstance<MiniAppAddOrUpdatePartOnePostRequest>;
}
const FunctionSet: React.FC<Props> = ({ listId, form }) => {
  const [state, update] = useUpdate();
  const [x, forceUpdate] = useUpdate();
  const hasToolsCenterId = useRef<boolean>(false);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [customMenus, setCustomMenus] = useState<MiniAppMenuGetListGetResponse>([]);
  const [currentMenuIndex, setCurrentMenuIndex] = useState<number>(0);
  const [currentMenuType, setCurrentMenuType] = useState<number | undefined>();
  const [currentMenuSorting, setCurrentMenuSorting] = useState<number>();
  const [currentHomePageInfo, setCurrentHomePageInfo] = useState<V2MiniAppToolGetListPostResponse['list']>();
  const [toolModalOpen, setToolModalOpen] = useState<boolean>(false);
  const [toolAndMenuCardOpen, setToolAndMenuCardOpen] = useState<boolean>(false);
  const [currentCenterId, setCurrentCenterId] = useState<number>();
  const [currentMenuId, setCurrentMenuId] = useState<number>();
  const [sortCate, setSortCate] = useState<any[]>([]);

  // 菜单拖拽排序
  function handleOnDragEnd(result: any) {
    // 如果没有目标位置，直接返回
    if (!result.destination) return;

    // 过滤出可见的菜单项
    const items = customMenus.filter(item => item.visibility === MenuVisibilityType.SHOW);

    // 记录源索引和目标索引
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    // 交换数组中的菜单项
    const tempItem = { ...items[sourceIndex] };
    items[sourceIndex] = { ...items[destinationIndex], sorting: tempItem.sorting };
    items[destinationIndex] = { ...tempItem, sorting: items[destinationIndex].sorting };

    apiMiniAppMenuEditPost({ id: listId, editRequests: items }).then(res => {
      if (res) {
        setCustomMenus(items);
        update();
      }
    });

    // 检查当前菜单是否是源菜单
    const isCurrentSourceSelect = currentMenuIndex === sourceIndex;

    // 检查当前菜单是否是目标菜单
    const isCurrentDestinationSelect = currentMenuIndex === destinationIndex;

    updateCurrentMenuIndex(
      isCurrentSourceSelect,
      isCurrentDestinationSelect,
      sourceIndex,
      destinationIndex,
      currentMenuIndex,
      setCurrentMenuIndex
    );
  }

  const handleDragStart = useCallback((event: any) => {
    setActiveId(event.active.id);
  }, []);

  // 工具图标拖拽
  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (active.id !== over?.id) {
        const oldIndex = sortCate.findIndex(item => item.mirrorCategoryName === active.id);
        const newIndex = sortCate.findIndex(item => item.mirrorCategoryName === over?.id);
        let temp = arrayMove(sortCate, oldIndex, newIndex);
        temp = temp.map((item, index) => {
          return {
            ...item,
            mirrorCategorySorting: index,
          };
        });
        const tempData = temp.map(item => {
          return {
            id: item.id,
            sorting: item.mirrorCategorySorting,
          };
        });

        apiMiniAppCategoryEditSortingPost({ menuId: currentCenterId, list: tempData }).then(_res => {
          setSortCate([...temp]);
        });
      }

      setActiveId(null);
    },
    [sortCate, currentCenterId]
  );

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  // 自定义菜单的显示隐藏
  const onCustomSwitch = async (checked: boolean, id: number | undefined) => {
    if (!id) return;
    const data = customMenus.find(item => item.id === id);

    if (data) {
      data.visibility = checked ? MenuVisibilityType.SHOW : MenuVisibilityType.HIDDEN;
      await apiMiniAppMenuEditPost({ id: listId, editRequests: [data] });
      setCustomMenus([...customMenus]);
    }

    setCurrentMenuIndex(customMenus.length + 999);
    setCurrentMenuType(undefined);
    setCurrentHomePageInfo(undefined);
  };

  // 获取菜单
  useEffect(() => {
    apiMiniAppMenuGetListGet({ id: String(listId) }).then(res => {
      const validMenus = res.sort((a, b) => {
        const sortingA = a.sorting || 0;
        const sortingB = b.sorting || 0;
        return sortingA - sortingB;
      });
      setCustomMenus(validMenus);

      const firstMenu = validMenus.find(item => item.visibility === MenuVisibilityType.SHOW);
      if (currentMenuIndex === 0 && firstMenu) {
        const { type, id } = firstMenu;
        setCurrentMenuId(id);
        setCurrentMenuType(type);
        if (type !== MenuType.TOOL) {
          apiV2MiniAppToolGetListPost({ id: +id! }).then(res => {
            setCurrentHomePageInfo(res.list);
          });
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listId, form, state, x]);

  useEffect(() => {
    const toolCenterId = customMenus?.find(item => item.type === MenuType.TOOL)?.id;
    toolCenterId && setCurrentCenterId(toolCenterId);
  }, [customMenus]);

  // 获取菜单下的工具
  useEffect(() => {
    if (!currentCenterId) return;
    apiV2MiniAppToolGetListPost({ id: currentCenterId }).then(res => {
      hasToolsCenterId.current = true;
      const temp = groupBy(res.list, 'mirrorCategoryId');
      const tempList = Object.keys(temp).map(item => {
        return {
          mirrorCategoryName: temp[item][0].mirrorCategoryName,
          id: item,
          mirrorCategoryId: item,
          children: temp[item],
          mirrorCategorySorting: temp[item][0].mirrorCategorySorting,
        };
      });
      tempList.sort((a, b) => a.mirrorCategorySorting! - b.mirrorCategorySorting!);
      tempList.forEach(item => {
        item.children.sort((a, b) => a.sorting! - b.sorting!);
      });
      setSortCate(tempList);
    });
  }, [currentCenterId, state]);

  const renderContent = () => {
    switch (currentMenuType) {
      case MenuType.TOOL:
        return (
          <ToolCenter
            handleDragStart={handleDragStart}
            handleDragEnd={handleDragEnd}
            handleDragCancel={handleDragCancel}
            update={update}
            activeId={activeId}
            setActiveId={setActiveId}
            setToolModalOpen={setToolModalOpen}
            sortCate={sortCate}
            currentCenterId={currentCenterId}
          />
        );
      case MenuType.CARD:
        return (
          <CardHomePage
            listId={listId}
            currentMenuId={currentMenuId}
            currentHomePageInfo={currentHomePageInfo}
            setCurrentHomePageInfo={data => setCurrentHomePageInfo(data)}
            update={update}
          />
        );
      default:
        return (
          <CustomPage
            currentHomePageInfo={currentHomePageInfo}
            currentMenuId={currentMenuId}
            currentMenuIndex={currentMenuIndex}
            customMenus={customMenus}
            setToolAndMenuCardOpen={setToolAndMenuCardOpen}
            setCurrentHomePageInfo={setCurrentHomePageInfo}
          />
        );
    }
  };

  return (
    <>
      <div className={styles.functionSet}>
        <div className={cx('contentBg', currentMenuType === MenuType.CARD && 'cardHomePageBg')}>
          <div className={cx('menuTitle', currentMenuType === MenuType.CARD && 'cardMenuTitle')}>
            {customMenus.filter(item => item.visibility === MenuVisibilityType.SHOW)[currentMenuIndex]?.name}
          </div>
          {/* 内容区域 */}
          <div className={cx('wrapper', currentMenuType === MenuType.CARD && 'cardMenuWrapper')}>
            <div className={styles.content}>
              {renderContent()}
              {/* 图标拖拽区域 */}
            </div>
          </div>
          {/* 内容区域 */}

          {/* 导航拖拽区域 */}
          <div className={styles.navBar}>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="menus" direction="horizontal">
                {provided => (
                  <div className={styles.navBarArea} {...provided.droppableProps} ref={provided.innerRef}>
                    {customMenus
                      ?.filter(item => item.visibility === MenuVisibilityType.SHOW)
                      ?.map((item, index: number) => {
                        return (
                          <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                            {provided => {
                              return (
                                <div
                                  className={`${styles.navBarTitle} ${
                                    currentMenuIndex === index && styles.active_menu
                                  }`}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  onClick={async () => {
                                    const { id, type, sorting } = item;
                                    setCurrentMenuIndex(index);
                                    setCurrentMenuType(type);
                                    setCurrentMenuId(id);
                                    setCurrentMenuSorting(sorting);
                                    const { list } = await apiV2MiniAppToolGetListPost({ id: id as number });
                                    list && setCurrentHomePageInfo(list);
                                    if (type === MenuType.CUSTOM && list?.length === 0) {
                                      setToolAndMenuCardOpen(true);
                                    }
                                  }}
                                >
                                  <div className={styles.icon}>
                                    <MenuIconRenderer type={item.type as MenuType} icon={item.icon} />
                                  </div>
                                  <div
                                    className={`${styles.navText} ${
                                      currentMenuIndex === index && styles.active_navText
                                    }`}
                                  >
                                    {item.name}
                                  </div>
                                </div>
                              );
                            }}
                          </Draggable>
                        );
                      })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
        {/* 导航区域 */}

        {/* 配置区域 */}
        <div className={styles.config}>
          <div className={styles.configArea}>
            {customMenus.map(item => {
              return (
                <div className={styles.menus} key={item.id}>
                  <span className={styles.menuText}>{item.name}</span>
                  <Switch
                    checked={item.visibility === MenuVisibilityType.SHOW}
                    className={styles.switch}
                    onChange={checked => {
                      onCustomSwitch(checked, item.id);
                    }}
                  ></Switch>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <AddToolsAndMenusModal
        toolModalOpen={toolModalOpen}
        setToolModalOpen={setToolModalOpen}
        update={update}
        currentMenuId={currentMenuId}
        listId={listId}
        forceUpdate={forceUpdate}
        currentCenterId={currentCenterId}
      />

      <AddToolsAndMenusModal
        toolModalOpen={toolAndMenuCardOpen}
        setToolModalOpen={setToolAndMenuCardOpen}
        update={update}
        currentMenuId={currentMenuId}
        isWithMenu={true}
        listId={listId}
        setCurrentHomePageInfo={setCurrentHomePageInfo}
        currentMenuSorting={currentMenuSorting}
        forceUpdate={forceUpdate}
        currentCenterId={currentCenterId}
      />
    </>
  );
};

export default FunctionSet;
