import { FormContent, Wrapper } from '@maxtropy/components';
import { Button, Col, Form, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { selectTenantColumns } from '../utils';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getTemplateDistributionTenantSearch, TemplateDistributionTenantRes } from '../../../api/edgeTemplate';

const routes = [{ name: 'Iot配置' }, { name: '数采配置' }, { name: '模板分配' }, { name: '租户分配数采模板详情' }];

const formLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 18 },
};

const TemplateDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const navigate = useNavigate();
  const [info, setInfo] = useState<TemplateDistributionTenantRes>();

  useEffect(() => {
    if (id) {
      getTemplateDistributionTenantSearch(id).then(res => {
        setInfo(res);
      });
    }
  }, [id]);

  return (
    <Wrapper routes={routes} {...formLayout}>
      <FormContent title="模板信息">
        <Form layout="vertical">
          <Row>
            <Col span={12}>
              <Form.Item label="模板code">
                <span>{info?.code ?? '--'}</span>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="模板名称">
                <span>{info?.name ?? '--'}</span>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </FormContent>
      <FormContent title="分配租户">
        <Table
          columns={selectTenantColumns}
          dataSource={info?.tenantList ?? []}
          pagination={{
            defaultPageSize: 20,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '50', '100'],
            showTotal: () => `共${info?.tenantList.length ?? 0}条`,
          }}
        />
        <Button style={{ marginTop: 20 }} onClick={() => navigate(`/device/edge/distribution${search}`)}>
          返回
        </Button>
      </FormContent>
    </Wrapper>
  );
};

export default TemplateDetail;
