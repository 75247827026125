/* eslint no-console: ["error", { allow: ["error"] }] */
import React from 'react';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import FormContainer from '../components/FormContainer';
import styles from './index.module.scss';
import { Wrapper } from '@maxtropy/components';
import { updatePassword } from '../../../../api/cc-user';

const FormItem = Form.Item;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 12 },
  },
};

const routes = [{ name: '个人中心' }, { name: '个人设置' }, { name: '修改登录密码' }];

const LoginPasswordForm: React.FC = () => {
  const [modalApi, modalContextHolder] = Modal.useModal();
  const [form] = Form.useForm();

  const checkConfirm = (_: any, value: string) => {
    const promise = Promise;
    if (value && value !== form.getFieldValue('newPassword')) {
      return promise.reject('两次输入的密码不匹配');
    }
    return promise.resolve();
  };

  const handleSubmit = async (values: any) => {
    try {
      const res = await updatePassword(values);
      if (res) {
        form.resetFields();
        modalApi.success({
          content: '密码已经生效，请重新登录',
          okText: '确定',
          onOk: () => {
            window.open('/login', '_self');
          },
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onCancel = () => {
    form.resetFields();
  };

  const children: React.ReactNode = (
    <Form {...formItemLayout} form={form} onFinish={handleSubmit}>
      <FormItem name="oldPassword" rules={[{ required: true, message: '请输入原登录密码' }]} label="原登录密码">
        <Input.Password placeholder="请输入原登录密码" />
      </FormItem>
      <FormItem
        name="newPassword"
        rules={[
          { required: true, message: '请输入新登录密码' },
          { pattern: /^(?=.*[0-9])(?=.*[a-zA-Z])[\x20-\x7E]{8,}$/, message: '密码不少于8位，需同时包含字母和数字' },
        ]}
        label="新登录密码"
      >
        <Input.Password placeholder="请输入至少8位数密码，需同时包含字母和数字" />
      </FormItem>
      <FormItem
        name="validNewPassword"
        rules={[{ required: true, message: '请确认新登录密码' }, { validator: checkConfirm }]}
        label="确认新登录密码"
      >
        <Input.Password placeholder="请输入至少8位数密码，需同时包含字母和数字" />
      </FormItem>
      <Row>
        <Col span={5} />
        <Col span={12}>
          <ExclamationCircleOutlined className={styles.icon} />
          &nbsp;忘记密码请联系<a className={styles.contactAdmin}>系统管理员</a>
        </Col>
      </Row>
      <Row className={styles.operateContainer}>
        <Col span={5} />
        <Col span={12}>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
          <Button onClick={onCancel}>取消</Button>
        </Col>
      </Row>
    </Form>
  );

  return (
    <Wrapper routes={routes}>
      <FormContainer title="修改登录密码" children={children} />
      {modalContextHolder}
    </Wrapper>
  );
};

export default LoginPasswordForm;
