import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import {
  EllipsisSpan,
  Filter,
  FormContent,
  getRealUrl,
  Paging,
  usePaging,
  useUpdate,
  Wrapper,
} from '@maxtropy/components';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Upload,
  UploadFile,
} from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { PermissionsType } from '../../common/permissionsConst';
import dayjs from 'dayjs';
import {
  addAIReport,
  AIReportList,
  AIReportStatus,
  AIReportStatusValue,
  delAIReport,
  getAIReportPage,
  PageSearchParams,
  updateAIReportStatus,
} from '../../api/AIreport';
import { getRoot, Tenant } from '@/shared/api/options';
import { useMgmtPermissions } from '@/components/Layout/hooks';

const routes = [{ name: '工具中心' }, { name: 'AI报告' }, { name: '报告管理' }];

// 时间格式化
const dateFormat = 'YYYY-MM-DD';
const displayDateFormat = 'YYYY-MM-DD HH:mm:ss';

function onDownload(url: string, fileName: string) {
  let x = new XMLHttpRequest();
  x.open('GET', `${url}`, true);
  x.responseType = 'blob';
  x.onload = function () {
    let url = window.URL.createObjectURL(x.response);
    let a = document.createElement('a');
    a.href = url;
    a.download = `${fileName}.pdf`;
    a.click();
  };
  x.send();
}

// 表格：列名
const columns = [
  {
    title: '名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '分析日期',
    dataIndex: 'analysisDate',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format(dateFormat)} />,
  },
  {
    title: '呈现状态',
    dataIndex: 'display',
    ellipsis: { showTitle: true },
    render: (v: string) => (
      <EllipsisSpan value={v ? AIReportStatusValue[AIReportStatus.SHOW] : AIReportStatusValue[AIReportStatus.HIDDEN]} />
    ),
  },
  {
    title: '呈现租户',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '上传时间',
    dataIndex: 'uploadTime',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={dayjs(v).format(displayDateFormat)} />,
  },
];

// 查询参数类型
type SearchParams = Omit<PageSearchParams, 'page' | 'size'>;

const AIreport: FC = () => {
  const [form] = Form.useForm();
  const [updateState, updateFn] = useUpdate();
  const [uploadOpen, setUploadOpen] = useState<boolean>(false);
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [uploadForm] = Form.useForm();
  const permission = useMgmtPermissions();
  const [searchParams, setSearchParams] = useState<any>();

  const [loading, setLoading] = useState<boolean>(false);
  // const [tenantMcid, setTenantMcid] = useState<string>();
  const [reportsList, setReportsList] = useState<AIReportList[]>([]);

  const [tenant, setTenant] = useState<Tenant[]>([]);
  const [modalApi, modalContextHolder] = Modal.useModal();

  useEffect(() => {
    getRoot().then(setTenant);
  }, []);

  const tenantOptions = useMemo(() => {
    return tenant.map(i => ({ label: `【${i.tenantCode}】${i.name}`, value: i.mcid }));
  }, [tenant]);

  // 列表查询
  useEffect(() => {
    setLoading(true);
    getAIReportPage({
      ...searchParams,
      page: pageOffset,
      size: pageSize,
    })
      .then(res => {
        if (res) {
          setTotalCount(res.total);
          setReportsList(res.list);
        }
      })
      .finally(() => setLoading(false));
  }, [pageOffset, pageSize, searchParams, setTotalCount, updateState]);

  const onFinish = (value: SearchParams) => {
    setSearchParams(value);
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams(undefined);
    setPageOffset(1);
  };

  const onModalOk = async () => {
    const values = await uploadForm.validateFields();
    // 截取名字
    // const name = values.fileKey.file.name.slice(
    //   0,
    //   values.fileKey.file.name.split('').findLastIndex((v: string) => v === '.')
    // );
    const body = {
      ...values,
      analysisDate: dayjs(values.analysisDate).format(dateFormat),
      fileKey: values.fileKey.file.response.key,
      // name,
    };
    addAIReport(body).then(() => {
      setUploadOpen(false);
      uploadForm.resetFields();
      modalApi.success({
        content: `正在上传，稍后刷新列表查看。`,
      });
      updateFn();
    });
  };

  const beforeUploadFile = (file: UploadFile, fileList: UploadFile[]) => {
    const { size, type } = file;
    if ((size as number) > 104857600) {
      modalApi.error({
        content: `上传文件超过100MB`,
      });
      return Upload.LIST_IGNORE;
    }
    if (type !== 'application/pdf') {
      modalApi.error({
        content: `仅支持PDF格式文件`,
      });
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  // 删除
  const onDeleteRecord = (id: number) => {
    modalApi.confirm({
      title: `确认删除？`,
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <span color="red">删除后不可恢复，</span>你还要继续吗？
        </>
      ),
      okText: '继续',
      onOk() {
        delAIReport(id).then(res => {
          modalApi.success({
            content: '操作成功！',
          });
          updateFn();
        });
      },
      onCancel() {
        console.error('Cancel');
      },
    });
  };

  // 改变状态（显示与隐藏）
  const onChangeStatus = (record: AIReportList) => {
    modalApi.confirm({
      // title: <span>确认终止？终止合同后，结算规则不再生效。系统以终止时间进行最后一次结算。</span>,
      icon: <ExclamationCircleOutlined />,
      content: `确认${
        record.display === false ? AIReportStatusValue[AIReportStatus.SHOW] : AIReportStatusValue[AIReportStatus.HIDDEN]
      }？`,
      okText: '继续',
      onOk() {
        updateAIReportStatus(record.id as number, {
          display: record.display === false ? true : false,
        }).then(res => {
          modalApi.success({
            content: '操作成功！',
          });
          updateFn();
        });
      },
      onCancel() {
        console.error('Cancel');
      },
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      width: 200,
      // fixed: "right" as const,
      render: (value: undefined, record: AIReportList) => {
        return (
          <Space size={16}>
            {(permission ?? []).includes(PermissionsType.B_ANALYSISREPORTDELETE) && (
              <Button
                // disabled={record.status === SettlementRulesStatusType.Enable}
                // style={{ paddingLeft: 0, paddingRight: 0 }}
                type="link"
                onClick={() => onDeleteRecord(record.id as number)}
              >
                删除
              </Button>
            )}
            <Button
              // disabled={(record.status === ElectricitySettlementRulesStatusType.ABORTED)}
              // style={{ paddingLeft: 0, paddingRight: 0 }}
              type="link"
              onClick={() => onChangeStatus(record)}
            >
              {record.display === false
                ? AIReportStatusValue[AIReportStatus.SHOW]
                : AIReportStatusValue[AIReportStatus.HIDDEN]}
            </Button>
            <Button
              type="link"
              onClick={() => {
                if (record.fileKey) {
                  onDownload(getRealUrl(record.fileKey) as string, record.name);
                }
              }}
            >
              下载
            </Button>
          </Space>
        );
      },
    },
  ];

  const filters = (
    <Filter
      form={form}
      onFinish={val => {
        onFinish(val as SearchParams);
      }}
      onReset={onReset}
    >
      <>
        <Col span={6}>
          <Form.Item name="name" label="名称">
            <Input />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item name="tenantMcid" label="呈现租户">
            <Select placeholder="请选择" options={tenantOptions} optionFilterProp="label" showSearch />
          </Form.Item>
        </Col>
      </>
    </Filter>
  );

  return (
    <>
      <Wrapper routes={routes} filters={filters}>
        <div style={{ display: 'flex' }}>
          <Space size={12} style={{ marginRight: '12px' }}>
            <Button type="primary" onClick={() => setUploadOpen(true)}>
              上传
            </Button>
          </Space>
          <Space size={12}>
            <Button type="primary" onClick={() => updateFn()}>
              刷新
            </Button>
          </Space>
        </div>
        <FormContent style={{ padding: 0 }}>
          <Table
            sticky
            rowKey="id"
            loading={loading}
            scroll={{ x: 1500 }}
            columns={buildColumns}
            dataSource={reportsList}
            pagination={false}
          />
          <Paging pagingInfo={pagingInfo} />
        </FormContent>
      </Wrapper>
      <Modal
        width={600}
        open={uploadOpen}
        title="报告上传"
        onCancel={() => {
          setUploadOpen(false);
          uploadForm.resetFields();
        }}
        onOk={onModalOk}
        okText="确认上传"
      >
        <Form style={{ width: 550 }} labelAlign="right" labelCol={{ flex: '140px' }} form={uploadForm}>
          <Row>
            <Col span={20}>
              <Form.Item name="name" label="报告名称" rules={[{ required: true, message: '请输入报告名称' }]}>
                <Input max={100} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={20}>
              <Form.Item name="analysisDate" label="分析日期" rules={[{ required: true, message: '请选择报告时间' }]}>
                <DatePicker
                  style={{ width: '100%' }}
                  picker="date"
                  // disabledDate={(currentDate: any) => {
                  //   return currentDate.isAfter(dayjs().endOf('day'));
                  // }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={20}>
              <Form.Item name="tenantMcid" label="所属租户" rules={[{ required: true, message: '请选择所属租户' }]}>
                <Select
                  style={{ width: '100%' }}
                  placeholder="请选择"
                  // onChange={() => {
                  //   setTenantMcid(uploadForm.getFieldValue('tenantMcid'));
                  //   uploadForm.setFieldsValue({ typeId: undefined });
                  // }}
                  options={tenantOptions}
                  optionFilterProp="label"
                  showSearch
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                name="fileKey"
                label="选择文件"
                rules={[{ required: true, message: '请选择文件' }]}
                extra={'支持PDF格式文件，限制100MB以内。'}
              >
                <Upload
                  action="/api/file-center/upload"
                  accept=".pdf"
                  // onChange={handleChange}
                  maxCount={1}
                  beforeUpload={beforeUploadFile}
                >
                  <Button icon={<UploadOutlined />}>点击上传</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      {modalContextHolder}
    </>
  );
};

export default AIreport;
