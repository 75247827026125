import React, { useEffect } from 'react';
import { Select, SelectProps } from 'antd';

interface TextOrSelectProps<T extends any> extends SelectProps<T> {}

const TextOrSelect = <T extends any>(props: TextOrSelectProps<T>) => {
  const { options, onChange } = props;

  useEffect(() => {
    if (options?.length === 1) {
      onChange?.(options?.[0].value as any, { label: null, value: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <>
      {options?.length === 1 ? (
        <span>{options?.[0].label}</span>
      ) : (
        <Select options={props.options ?? []} {...(props as any)} />
      )}
    </>
  );
};

export default TextOrSelect;
