import { RouteObject } from 'react-router-dom';
import Rules from '../pages/MockDeviceData/Rules';
import RuleSets from '../pages/MockDeviceData/RuleSets';
import RuleSetForm from '../pages/MockDeviceData/RuleSetForm';
import RuleForm from '../pages/MockDeviceData/RuleForm';
import Index from '../pages/MockDeviceData/Index';
import AuthorizedPermission from '../components/AuthorizedPermission';
import { PermissionsType } from '../common/permissionsConst';
import { RuleType } from '../api/constants';
import TemplateManage from '../pages/TemplateManage';
import DataValueRules from '../pages/DataValueRules';
import RulesManage from '../pages/RulesManage';
import RulesGroup from '../pages/RulesGroup';
import Detail from '../pages/RulesGroup/Detail';
import FormsManage from '../pages/FormsManage';
import FormCreate from '../pages/FormCreate';
import FormTemplateManagement from '../pages/FormTemplateManagement';
import FormTemplateCreate from '../pages/FormTemplateManagement/FormTemplateCreate';
import DataGeneratorManagement from '../pages/DataGeneratorManagement';
import DataGeneratorCreate from '../pages/DataGeneratorManagement/DataGeneratorCreate';
import DataSupplement from '../pages/RulesManage/DataSupplement';
import MockDataDeletion from '../pages/MockDataDeletion/List';
import DataRun from '@/pages/DataRun';

const pageRulePermissonsType: Record<RuleType, PermissionsType> = {
  [RuleType.UPLOAD_TIME]: PermissionsType.P_UPLOAD_RULE_CONFIG,
  [RuleType.DATA_TIME]: PermissionsType.P_DATA_TIME_RULE_CONFIG,
  [RuleType.DATA_VALUE]: PermissionsType.P_DATA_VALUE_RULE_CONFIG,
};

const mockDeviceDataRoutes: RouteObject[] = [
  {
    path: 'data-tools/shaping/description',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_USER_MANUAL}>
        <Index />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/shaping/mockDataDeletion',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_MOCK_DATA_DELETION}>
        <MockDataDeletion></MockDataDeletion>
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/shaping/rule-set',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_RULE_CONFIGURATION}>
        <RuleSets />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/shaping/rule-set/create',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_RULE_CONFIGURATION}>
        <RuleSetForm isUpdate={false} />,
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/shaping/rule-set/:id/update',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_RULE_CONFIGURATION}>
        <RuleSetForm isUpdate={true} />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/shaping/UPLOAD_TIME',
    element: (
      <AuthorizedPermission permissionKey={pageRulePermissonsType[RuleType.UPLOAD_TIME]}>
        <Rules type={RuleType.UPLOAD_TIME} />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/shaping/UPLOAD_TIME/create',
    element: (
      <AuthorizedPermission permissionKey={pageRulePermissonsType[RuleType.UPLOAD_TIME]}>
        <RuleForm type={RuleType.UPLOAD_TIME} isUpdate={false} />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/shaping/UPLOAD_TIME/:id/update',
    element: (
      <AuthorizedPermission permissionKey={pageRulePermissonsType[RuleType.UPLOAD_TIME]}>
        <RuleForm type={RuleType.UPLOAD_TIME} isUpdate={true} />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/shaping/DATA_TIME',
    element: (
      <AuthorizedPermission permissionKey={pageRulePermissonsType[RuleType.DATA_TIME]}>
        <Rules type={RuleType.DATA_TIME} />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/shaping/DATA_TIME/create',
    element: (
      <AuthorizedPermission permissionKey={pageRulePermissonsType[RuleType.DATA_TIME]}>
        <RuleForm type={RuleType.DATA_TIME} isUpdate={false} />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/shaping/DATA_TIME/:id/update',
    element: (
      <AuthorizedPermission permissionKey={pageRulePermissonsType[RuleType.DATA_TIME]}>
        <RuleForm type={RuleType.DATA_TIME} isUpdate={true} />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/shaping/DATA_VALUE',
    element: (
      <AuthorizedPermission permissionKey={pageRulePermissonsType[RuleType.DATA_VALUE]}>
        <Rules type={RuleType.DATA_VALUE} />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/shaping/DATA_VALUE/create',
    element: (
      <AuthorizedPermission permissionKey={pageRulePermissonsType[RuleType.DATA_VALUE]}>
        <RuleForm type={RuleType.DATA_VALUE} isUpdate={false} />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/shaping/DATA_VALUE/:id/update',
    element: (
      <AuthorizedPermission permissionKey={pageRulePermissonsType[RuleType.DATA_VALUE]}>
        <RuleForm type={RuleType.DATA_VALUE} isUpdate={true} />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/shaping/templatemanage',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_DATA_TEMPLATE_MANAGE}>
        <TemplateManage />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/shaping/templatemanage/values/:type', // 数值型/解析型
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_DATA_TEMPLATE_MANAGE}>
        <DataValueRules />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/shaping/templatemanage/values/:type/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_DATA_TEMPLATE_MANAGE}>
        <DataValueRules isEdit />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/shaping/rulesmanage',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_DATA_RULE_MANAGE}>
        <RulesManage />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/shaping/rulesmanage/rules',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_DATA_RULE_MANAGE}>
        <RulesGroup />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/shaping/rulesmanage/rules/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_DATA_RULE_MANAGE}>
        <RulesGroup isEdit />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/shaping/rulesmanage/detail/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_DATA_RULE_MANAGE}>
        <Detail />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/shaping/rulesmanage/supplement/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_DATA_RULE_MANAGE}>
        <DataSupplement />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/business/formmanage',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_MOCKFORMMANAGE}>
        <FormsManage />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/business/formcreate/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_MOCKFORMMANAGE}>
        <FormCreate />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/business/formedit/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_MOCKFORMMANAGE}>
        <FormCreate isEdit />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/business/formTemplateManagement',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_MOCKFORMTEMPLATEMANAGE}>
        <FormTemplateManagement />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/business/formCreateModal',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_MOCKFORMTEMPLATEMANAGE}>
        <FormTemplateCreate />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/business/formCreateModal/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_MOCKFORMTEMPLATEMANAGE}>
        <FormTemplateCreate isEdit />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/business/dataGeneratorManagement',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_MOCKDATAGENERATORMANAGE}>
        <DataGeneratorManagement />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/business/datarun',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_RUN_TASK}>
        <DataRun />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/business/dataGeneratorCreate',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_MOCKDATAGENERATORMANAGE}>
        <DataGeneratorCreate />
      </AuthorizedPermission>
    ),
  },
  {
    path: 'data-tools/business/dataGeneratorCreate/:id',
    element: (
      <AuthorizedPermission permissionKey={PermissionsType.P_MOCKDATAGENERATORMANAGE}>
        <DataGeneratorCreate isEdit />
      </AuthorizedPermission>
    ),
  },
];

export default mockDeviceDataRoutes;
