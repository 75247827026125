import { fetch } from '@maxtropy/components';
import { ChannelApp } from '../types';

export function getStaffChannelApp() {
  return fetch<ChannelApp[]>(`/api/common/push/channel/staff`);
}

export function getOutsidersChannelApp() {
  return fetch<ChannelApp[]>(`/api/common/push/channel/outsiders`);
}
