import { fetch, Key, PromiseWithErrorHandler } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import qs from 'qs';
import { Operator, BatchDeviceTemplateStatus, IotProtocolType, DriveType } from '@/shared/types';
import { MockingbirdDeviceProps } from '@/shared/components/DataAcquisitionForm/types';

export interface BatchDeviceTemplate {
  id: number;
  code: string;
  templateStatus: BatchDeviceTemplateStatus;
  updateSource: Operator;
  updateByUserId: string;
  updateByUsername: string;
  typeId?: number;
  typeName?: string;
  modelId?: number;
  modelName?: string;
  updateTime: string;
  existAttribute: boolean;
  existEdgeTemplate: boolean;
}

export interface BatchDeviceTemplateListRequest extends PageRequest {
  code?: string;
  typeId?: Array<number>;
  rootMcid?: string;
}

export interface BatchDeviceTemplateListResponse extends PageResponse<BatchDeviceTemplate> {}

export function getBatchDeviceTemplateList(
  params: BatchDeviceTemplateListRequest
): Promise<BatchDeviceTemplateListResponse> {
  return fetch(`/api/batch/import/page?${qs.stringify(params, { indices: false })}`);
}

export interface BatchDeviceRecord {
  id: number;
  serialNumber: string;
  templateId: number;
  templateCode: string;
  importTime: string;
  updateSource: Operator;
  updateByUserId?: string;
  updateByUsername?: string;
  typeId: number;
  typeName: string;
  modelId: number;
  modelName: string;
  rootMcid: string;
  rootFullName: string;
  tenantCode: string;
  errorCount: number;
  successCount: number;
  totalCount: number;
}

export function getBatchDeviceRecordList(
  params: BatchDeviceTemplateListRequest
): Promise<PageResponse<BatchDeviceRecord>> {
  return fetch(`/api/batch/import/record/page?${qs.stringify(params, { indices: false })}`);
}

export interface CreateBatchDeviceTemplatePropertyRequest {
  typeId?: number;
  modelId?: number;
  modelName?: string;
  pic?: string;
  rootMcid: string;
  customerMcid: string;
  assetCode: string;
  ouIds?: Array<number>;
  addOuIds?: Array<number>;
  deleteOuIds?: Array<number>;
  tags: string;
  physicalModelId?: Key;
  modelNo?: string;
}

export interface BatchDeviceTemplatePropertyResponse extends CreateBatchDeviceTemplatePropertyRequest {
  id: number;
  code: string;
  typeName?: string;
  rootFullName: string;
  customerName: string;
  customerFullName: string;
}

export function getBatchDeviceTemplateProperty(id: string): Promise<BatchDeviceTemplatePropertyResponse> {
  return fetch(`/api/batch/import/info?id=${id}`);
}

export function createBatchDeviceTemplateProperty(
  params: CreateBatchDeviceTemplatePropertyRequest
): Promise<BatchDeviceTemplatePropertyResponse> {
  return fetch(`/api/batch/import/add`, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'x-tenant-mcid': `${params.rootMcid}`,
    },
  });
}

export interface UpdateBatchDeviceTemplatePropertyRequest
  extends Omit<CreateBatchDeviceTemplatePropertyRequest, 'typeId' | 'modelId' | 'modeName' | 'rootMcid'> {
  id: number;
}

export function updateBatchDeviceTemplateProperty(
  params: UpdateBatchDeviceTemplatePropertyRequest
): Promise<BatchDeviceTemplatePropertyResponse> {
  return fetch(`/api/batch/import/update`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });
}

export interface CopyBatchDeviceTemplatePropertyRequest extends CreateBatchDeviceTemplatePropertyRequest {
  copyId: number;
}

export function copyBatchDeviceTemplateProperty(
  params: CopyBatchDeviceTemplatePropertyRequest
): Promise<BatchDeviceTemplatePropertyResponse> {
  return fetch(`/api/batch/import/copy`, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'x-tenant-mcid': `${params.rootMcid}`,
    },
  });
}

export interface AttributeItem {
  fieldId: number;
  unitId?: number;
  value?: string;
}

export interface BatchDeviceTemplateAttributeResponse {
  batchTemplateId: number;
  templateId: number;
  templateVersion: number;
  list: AttributeItem[];
}

export function getBatchDeviceTemplateAttribute(id: string): Promise<BatchDeviceTemplateAttributeResponse> {
  return fetch(`/api/batch/import/attribute?id=${id}`);
}

export function updateBatchDeviceTemplateAttribute(
  params: BatchDeviceTemplateAttributeResponse,
  tenantMcid: string
): Promise<BatchDeviceTemplateAttributeResponse> {
  return fetch(`/api/batch/import/attribute/upsert`, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'x-tenant-mcid': tenantMcid,
    },
  });
}

export interface BatchDeviceTemplateEdgeInfo {
  batchTemplateId: number;
  iotProtocol: IotProtocolType;
  templateVersionId: number;
  driveType?: DriveType;
  parameters?: Omit<MockingbirdDeviceProps, 'driveType'>;
  typeId?: number;
}

export const getBatchDeviceTemplateEdgeInfo = (id: string) =>
  fetch<BatchDeviceTemplateEdgeInfo>(`/api/batch/import/edge?id=${id}`);

export const updateBatchDeviceTemplateEdge = (body: BatchDeviceTemplateEdgeInfo, tenantMcid: string) => {
  return fetch<void>(`/api/batch/import/edge/upsert`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'x-tenant-mcid': tenantMcid,
    },
  });
};

export function deleteBatchDeviceTemplate(id: number): Promise<boolean> {
  return fetch(`/api/batch/import/${id}`, {
    method: 'DELETE',
  });
}

export function deleteBatchDeviceRecord(id: number): Promise<boolean> {
  return fetch(`/api/batch/import/record/${id}`, {
    method: 'DELETE',
  });
}

export interface BatchDevicePageRequest extends PageRequest {
  codeOrName?: string;
  tag?: string;
  deviceTypeId: number;
  tenantMcid: string;
  templateId?: number;
  templateVersion?: number;
  operationType: number;
  physicalModelId?: number;
}

interface EdgeDevice {
  id: number;
  name: string;
  code: string;
  typeId: number;
  typeName: string;
  rootMcid: string;
  tenantCode: string;
  rootFullName: string;
  tags?: string[];
  customerFullName?: string;
}

export type BatchDevicePageResponse = PageResponse<EdgeDevice>;

export function getBatchDevicePage(params: BatchDevicePageRequest): Promise<BatchDevicePageResponse> {
  return fetch(`/api/batch/device/template/attribute/device/page?${qs.stringify(params, { indices: false })}`);
}

interface BatchDeviceAttributeForm {
  deviceIds?: number[];
  templateId?: number;
  templateVersion?: number;
  list: AttributeItem[];
  mcid?: string;
}

export function createBatchDeviceAttribute(params: BatchDeviceAttributeForm): PromiseWithErrorHandler<{ key: string }> {
  return fetch(`/api/batch/device/template/attribute/insert`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export function updateBatchDeviceAttribute(params: BatchDeviceAttributeForm): PromiseWithErrorHandler<{ key: string }> {
  return fetch(`/api/batch/device/template/attribute/update`, {
    method: 'POST',
    body: JSON.stringify(params),
  });
}
