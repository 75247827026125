import { FC, useState, useEffect, useCallback, ChangeEvent, useMemo, Key } from 'react';
import {
  Button,
  Form,
  Row,
  Col,
  Input,
  Radio,
  DatePicker,
  Divider,
  Space,
  message,
  Modal,
  Select,
  Table,
  Tooltip,
  Upload,
  UploadFile,
  UploadProps,
  RadioChangeEvent,
  Checkbox,
} from 'antd';
import { useNavigate, Link } from 'react-router-dom';
import { ExclamationCircleOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import { debounce, isNil } from 'lodash-es';

import {
  FormContent,
  AttachmentUpload,
  SearchInput as CustomSearchInput,
  SearchInputOptionProps,
  getRealUrl,
  EllipsisSpan,
  OrganizationTable,
  DataType,
  getDefaultNames,
  useAsync,
} from '@maxtropy/components';
import { EmailFormItem } from '../../../components/FormComponent';

import {
  UserStatus,
  UserStatusDisplay,
  Gender,
  GenderDisplay,
  HomePageType,
  isSetHomePageOptions,
  titleOptions,
  TitleType,
} from '../../../api/cc-const';
import {
  TenantBaseInfoResponse,
  OpenAccoundRequest,
  openAccound,
  getInfoOpenAccount,
  OpenAccoundResponse,
  updateInfoOpenAccount,
  UpdateInfoOpenAccountRequest,
  PassWordIsOpen,
} from '../../../api/cc-tenant';
import { checkCellphone, TenantUserDetailResponse, getByUsername } from '../../../api/cc-customer';
import { getDefaultLogoKey } from '../../../api/cc-fileCenter';

import { TabsBaseProps, transformSpace } from '../util';

import styles from './index.module.scss';
import dayjs, { Dayjs } from 'dayjs';

import { commonMobilePhoneRegex } from '../../../utils/regex';
import {
  CusNavListResponse,
  getNavigationDetail,
  getNavigationList,
  TenantMenuLevel,
  TenantMenuNavigationResponse,
} from '../../../api/cc-navigation';
import EditFormModal from './components/CusOutEditFormModal';
import { nanoid } from 'nanoid';
import defaultHomePage1 from './assets/defaultHomePage1.jpg';
import defaultHomePage2 from './assets/defaultHomePage2.jpg';
import titleLine from './assets/titleLine1920.png';
import templateIcon from './assets/templateIcon.png';
import {
  apiMiniAppAllListPost,
  apiMiniAppMenuGetListGet,
  apiTenantBindMiniAppPost,
  MiniAppAllListPostResponse,
  MiniAppMenuGetListGetResponse,
} from '@maxtropy/cc-mgmt-apis';

const { useForm } = Form;

const dateFormat = 'YYYY-MM-DD';

const DEFAULT_PLATFORM_NAME = 'Maxtropy';

const formLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 16 },
};

const statusOptions = [
  { label: UserStatusDisplay[UserStatus.ENABLE], value: UserStatus.ENABLE },
  { label: UserStatusDisplay[UserStatus.DISABLE], value: UserStatus.DISABLE },
  { label: UserStatusDisplay[UserStatus.FREEZE], value: UserStatus.FREEZE },
];

const genderOptions = [
  { label: GenderDisplay[Gender.MALE], value: Gender.MALE },
  { label: GenderDisplay[Gender.FEMALE], value: Gender.FEMALE },
];

interface AccountsProps extends TabsBaseProps {
  tenant?: TenantBaseInfoResponse;
  disabled?: boolean;
  mcid: string;
}

const columns = [
  {
    title: '一级导航',
    dataIndex: 'rootName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '一级排序权重',
    dataIndex: 'rootWeighting',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '二级导航',
    dataIndex: 'parentName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '二级排序权重',
    dataIndex: 'parentWeighting',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '三级导航',
    dataIndex: 'selfName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '三级排序权重',
    dataIndex: 'selfWeighting',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

export type TenantMenuNavigationResponseExpand = TenantMenuNavigationResponse & {
  selfName?: string;
  parentName?: string;
  rootName?: string;
  parentWeighting?: number;
  rootWeighting?: number;
  selfWeighting?: number;
  currentId?: number;
  firstLevel?: number;
  secondLevel?: number;
  thirdLevel?: number;
};

const Accounts: FC<AccountsProps> = props => {
  const [messageApi, messageContextHolder] = message.useMessage();
  const [modalApi, modalContextHolder] = Modal.useModal();
  const { onBreacrumbChange, tenant, disabled = false, updateFn } = props;
  const [account, setAccount] = useState<boolean>(false);
  const [startAccount, setStartAccount] = useState<boolean>(false);
  const [navgationList, setNavgationList] = useState<CusNavListResponse[]>([]);
  const navigate = useNavigate();

  const routes = useMemo(() => {
    return [
      { name: '租户管理' },
      { name: '租户组织权限和账户' },
      { name: `${disabled ? '查看' : '编辑'}租户` },
      { name: '租户开户设定' },
    ];
  }, [disabled]);

  const defaultLogoKey = useAsync(getDefaultLogoKey);

  const [createAccountRes, setCreateAcountRes] = useState<OpenAccoundResponse>();
  const [createUserForm] = useForm();
  const [updateUserForm] = useForm();

  useEffect(() => {
    getNavigationList().then(setNavgationList);
  }, []);

  useEffect(() => {
    if (tenant) {
      if (tenant.accountOpenStatus) {
        setAccount(true);
      } else {
        createUserForm.setFieldsValue({
          fullName: tenant.name,
          navigationId: 1,
        });
      }
    }
  }, [tenant, createUserForm]);

  // prettier-ignore
  useEffect(() => {// NOSONAR
    if (account && tenant) {
      getInfoOpenAccount(tenant?.mcid).then(res => {
        const tenantOrganizationLevelData =
          res.tenantOrganizationLevelVoList && res.tenantOrganizationLevelVoList.length
            ? res.tenantOrganizationLevelVoList.map(i => ({ key: i.index, name: i.name }))
            : Array(6)
              .fill('')
              .map((_, index) => ({
                key: index,
                name: getDefaultNames(index),
              }));
        updateUserForm.setFieldsValue({
          shortName: res.customer.name,
          fullName: res.customer.fullName,
          logoUrl: res.customer.logoUrl,
          tenantOrganizationLevelVoList: tenantOrganizationLevelData,
          navigationId: res.navigationId ?? 1,
          homePageLogoUrl: res.customer.homePageLogoUrl,
          platformName: res.platformName ?? DEFAULT_PLATFORM_NAME,
          platformTitleType: res.platformTitleType ?? TitleType.PLATFORM,
          homePageLogoType: res.homePageLogoType ?? HomePageType.DEFAULT1,
          isSetHomePageOptions: res.homePageLogoType ?? HomePageType.DEFAULT1,
          iconUrl: res.iconUrl,
          passwordOpSwitch:res.passwordOpSwitch ===PassWordIsOpen.OPEN ?true : false,
        });
        // 租户首页图获取
        if (res.customer.homePageLogoUrl) {
          setFileList([{ url: getRealUrl(res.customer.homePageLogoUrl) }] as unknown as UploadFile[]);
          updateUserForm.setFieldsValue({ isSetHomePageOptions: HomePageType.CUSTOM });
        }
        if (res.iconUrl) {
          setIconList([{ url: getRealUrl(res.iconUrl) }] as unknown as UploadFile[]);
        }

        setNavgationId(res.navigationId);
        res.customizeNavigations.forEach(i => {
          if (i.level === TenantMenuLevel.THREE) {
            i.selfName = i.name;
            i.selfWeighting = i.weighting;
            i.firstLevel = 3;
            i.secondLevel = 3;
            i.thirdLevel = 3;
            let findParent = res.customizeNavigations.find(item => item.currentId === i.parentId);
            if (findParent) {
              i.parentName = findParent.name;
              i.parentWeighting = findParent.weighting;
            }
            let findRoot = res.customizeNavigations.find(item => item.currentId === i.rootId);
            if (findRoot) {
              i.rootName = findRoot.name;
              i.rootWeighting = findRoot.weighting;
            }
            if (i.integratedAppId) {
              i.integratedAppPermissionCode = i.permissionCode;
            }
          } else if (i.level === TenantMenuLevel.TWO) {
            i.parentName = i.name;
            i.parentWeighting = i.weighting;
            i.firstLevel = 3;
            i.secondLevel = 3;
            i.thirdLevel = 0;
            let findRoot = res.customizeNavigations.find(item => item.currentId === i.rootId);
            if (findRoot) {
              i.rootName = findRoot.name;
              i.rootWeighting = findRoot.weighting;
            }
          } else if (i.level === TenantMenuLevel.ONE) {
            i.firstLevel = 3;
            i.secondLevel = 0;
            i.thirdLevel = 0;
            i.rootName = i.name;
            i.rootWeighting = i.weighting;
          }
        });
        // prettier-ignore
        let res1 = res.customizeNavigations.sort((a, b) => {// NOSONAR
          if (a.rootId === b.rootId) {
            if (a.firstLevel === b.firstLevel) {
              if (a.firstLevel === b.firstLevel && a.rootWeighting === b.rootWeighting) {
                if (a.firstLevel === b.firstLevel && a.secondLevel === b.secondLevel) {
                  if (
                    a.firstLevel === b.firstLevel &&
                    a.secondLevel === b.secondLevel &&
                    a.parentWeighting === b.parentWeighting
                  ) {
                    if (
                      a.firstLevel === b.firstLevel &&
                      a.secondLevel === b.secondLevel &&
                      a.thirdLevel === b.thirdLevel
                    ) {
                      return b.weighting - a.weighting;
                    } else {
                      return a.thirdLevel! - b.thirdLevel!;
                    }
                  } else {
                    return b.parentWeighting! - a.parentWeighting!;
                  }
                } else {
                  return a.secondLevel! - b.secondLevel!;
                }
              } else {
                return b.rootWeighting! - a.rootWeighting!;
              }
            } else {
              return a.firstLevel! - b.firstLevel!;
            }
          } else {
            return String(b.rootId).toLowerCase().localeCompare(String(a.rootId).toLowerCase());
          }
        });
        setDataSource(res1 ?? []);
      });
    }

     // 小程序列表 更新&查看的回显
     if((tenant?.miniAppIds??[]).length >0){
      // @ts-ignore
      setAppletTenant(tenant?.miniAppIds[0]) 
        updateUserForm.setFieldsValue({
          // @ts-ignore
          applet:tenant?.miniAppIds[0]
        })
        
        apiMiniAppMenuGetListGet({ id: String(tenant?.miniAppIds)}).then(
          setAppletMenuData
        );
     }

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, tenant, updateUserForm]);

  useEffect(() => {
    onBreacrumbChange && onBreacrumbChange(routes);
  }, [onBreacrumbChange, routes]);

  const [createUserDisabled, setCreateUserDisabled] = useState<boolean>(true);
  const [option, setOption] = useState<SearchInputOptionProps[]>([]);
  const [searchPatternPhoneValue, setSearchPatternPhoneValue] = useState<string>('');
  const [cellphoneRes, setCellphoneRes] = useState<TenantUserDetailResponse>();
  const [usernameRes, setUsernameResRes] = useState<TenantUserDetailResponse>();

  const onPhoneSearch = (value: string) => {
    if (commonMobilePhoneRegex.test(value)) {
      setSearchPatternPhoneValue(value);
    } else {
      createUserForm.validateFields(['cellphone']);
    }
  };

  useEffect(() => {
    if (commonMobilePhoneRegex.test(searchPatternPhoneValue)) {
      setCellphoneRes(undefined);
      checkCellphone(searchPatternPhoneValue).then(res => {
        setCellphoneRes(res);
        if (!res) {
          createUserForm.setFieldsValue({
            cellphone: searchPatternPhoneValue,
          });
        }
      });
    }
  }, [searchPatternPhoneValue, createUserForm]);

  useEffect(() => {
    if (cellphoneRes) {
      setUsernameResRes(undefined);
      setOption([
        {
          name: `${cellphoneRes.cellphone} ${cellphoneRes.name}`,
          value: cellphoneRes.cellphone,
          label: cellphoneRes.cellphone,
        },
      ]);
    } else {
      if (commonMobilePhoneRegex.test(searchPatternPhoneValue)) {
        setOption([]);
        createUserForm.setFieldsValue({
          username: undefined,
          name: undefined,
          status: UserStatus.ENABLE,
          gender: undefined,
          birthday: undefined,
          wechat: undefined,
          qq: undefined,
          email: undefined,
          headPic: undefined,
          workEmail: undefined,
          workQq: undefined,
          workWechat: undefined,
        });
        setCreateUserDisabled(false);
      }
    }
  }, [searchPatternPhoneValue, cellphoneRes, createUserForm]);

  const [navgationId, setNavgationId] = useState<string | number>();
  const [navgationDetailList, setNavgationDetailList] = useState<TenantMenuNavigationResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false); // 加载状态
  const [dataSource, setDataSource] = useState<any[]>([]); // 自定义外部导航列表
  const [editModalOpened, setEditModalOpened] = useState<boolean>(false); // 编辑导航弹窗
  const [level, setLevel] = useState<number>(); // 导航等级
  const [isAdd, setIsAdd] = useState<boolean>(false); // 是否是新增
  const [form] = Form.useForm();
  const [current, setCurrent] = useState<TenantMenuNavigationResponseExpand>();
  const [appletMenuData, setAppletMenuData] = useState<MiniAppMenuGetListGetResponse>([]);

  // 小程序绑定测试数据
  const [appletOptions, setAppletOptions] = useState<MiniAppAllListPostResponse>([]);

  // 请求小程序列表
  useEffect(() => {
    apiMiniAppAllListPost({}).then(setAppletOptions);
  }, []);

  // 储存绑定的小程序租户
  const [appletTenant, setAppletTenant] = useState<Key>();
  // 小程序保留 支持绑定多个

  // 根据小程序id version 获取底部菜单展示数据
  useEffect(() => {
    if (isNil(appletTenant)) return;
    apiMiniAppMenuGetListGet({ id: String(appletTenant) }).then(res => {
      const data = res.filter(i => i.visibility === 1); //只要visibility=1的工具菜单
      setAppletMenuData(data);
    });
  }, [appletTenant]);

  // 查找默认导航方案的一级导航权重
  useEffect(() => {
    if (navgationId) {
      getNavigationDetail(String(navgationId)).then((res: TenantMenuNavigationResponse[]) => {
        let navDetailListFilterLevel = res.filter(item => item.level === 1);
        let navDetailList = navDetailListFilterLevel.sort((a, b) => b.weighting - a.weighting); // NOSONAR
        setNavgationDetailList(navDetailList);
      });
    } else {
      // 否则就是默认的
      getNavigationDetail(String(1)).then((res: TenantMenuNavigationResponse[]) => {
        let navDetailListFilterLevel = res.filter(item => item.level === 1);
        let navDetailList = navDetailListFilterLevel.sort((a, b) => b.weighting - a.weighting); // NOSONAR
        setNavgationDetailList(navDetailList);
      });
    }
  }, [navgationId]);

  // 查找自定义外部导航的信息
  useEffect(() => {
    setLoading(false);
  }, []);

  const onDeleteRecord = (record: TenantMenuNavigationResponse) => {
    modalApi.confirm({
      icon: <ExclamationCircleOutlined />,
      content: `确认删除该导航以及下级导航？`,
      okText: '删除',
      onOk() {
        if (record.level === 1) {
          const newData = dataSource.filter(item => item.rootId !== record.rootId);
          setDataSource(newData);
        } else if (record.level === 2) {
          const newData1 = dataSource.filter(
            item => item.parentId !== record.currentId && item.currentId !== record.currentId
          );
          setDataSource(newData1);
        } else {
          const newData = dataSource.filter(item => item.currentId !== record.currentId);
          setDataSource(newData);
        }
      },
      onCancel() {
        console.error('Cancel');
      },
    });
  };

  // 处理小程序列表数据
  const appletOptionsFn = () => {
    return appletOptions.map((item: any) => {
      return {
        label: item.name,
        value: item.id,
        key: item.id,
        version: item.version,
      };
    });
  };

  const appletOnchange = (v: Key) => {
    if (isNil(v)) {
      setAppletMenuData([]);
      setAppletTenant(undefined);
    } else {
      setAppletTenant(v);
    }
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      // width: 183,
      // fixed: 'right' as 'right',
      render: (record: TenantMenuNavigationResponse) => {
        return (
          <Space size={16}>
            {disabled ? (
              <Button
                style={{ paddingLeft: 0, paddingRight: 0 }}
                type="link"
                onClick={() => {
                  setEditModalOpened(true);
                  setCurrent(record);
                  setLevel(record.level);
                }}
              >
                查看
              </Button>
            ) : (
              <></>
            )}
            {
              // hasNavDetailAddEditDel &&

              <Button
                disabled={disabled}
                style={{ paddingLeft: 0, paddingRight: 0 }}
                type="link"
                onClick={() => {
                  setEditModalOpened(true);
                  setCurrent(record);
                  setLevel(record.level);
                }}
              >
                编辑
              </Button>
            }
            {
              // hasNavDetailAddEditDel &&
              <Button
                disabled={disabled}
                style={{ paddingLeft: 0, paddingRight: 0 }}
                type="link"
                onClick={() => {
                  onDeleteRecord(record);
                }}
              >
                删除
              </Button>
            }
            {
              // hasNavDetailAddEditDel &&
              record.level !== 3 && (
                <Button
                  disabled={disabled}
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                  type="link"
                  onClick={() => {
                    setEditModalOpened(true);
                    setIsAdd(true);
                    setLevel(record.level + 1);
                    setCurrent(record);
                  }}
                >
                  新增下级
                </Button>
              )
            }
          </Space>
        );
      },
    },
  ];

  const onCancelAction = () => {
    setCurrent(undefined);
    form.resetFields();
    setEditModalOpened(false);
    setLevel(undefined);
    setIsAdd(false);
  };

  // prettier-ignore
  const onEditFormFinish = (values: TenantMenuNavigationResponse) => {// NOSONAR
    if (current && !isAdd) {
      // 如果有current无isAdd，那就是编辑
      // 将链接地址多余空白删除
      values = {
        ...values,
        path: values?.path?.trim(),
      };
      const newData = [...dataSource];
      const index = newData.findIndex(item => current.currentId === item.currentId);
      const item = newData[index];
      if (index > -1) {
        if (current.level === 1) {
          newData.splice(index, 1, {
            ...item,
            ...values,
            rootName: values.name,
            rootWeighting: values.weighting,
          });

          // 修改每一项的名称
          let newArr = [];
          for (let i of newData) {
            if (i.rootId === current.rootId) {
              newArr.push(i);
            }
          }
          for (let i of newArr) {
            const index1 = newData.findIndex(item => i.currentId === item.currentId);
            newData.splice(index1, 1, {
              ...i,
              rootName: values.name,
              rootWeighting: values.weighting,
            });
          }
          setDataSource(newData);
        } else if (current.level === 2) {
          newData.splice(index, 1, {
            ...item,
            ...values,
            rootName: current.rootName,
            rootWeighting: current.rootWeighting,
            parentName: values.name,
            parentWeighting: values.weighting,
          });

          // 修改每一项的名称
          let newArr = [];
          for (let i of newData) {
            if (i.rootId === current.rootId) {
              newArr.push(i);
            }
          }
          for (let i of newArr) {
            const index1 = newData.findIndex(item => i.currentId === item.currentId);
            if (i.level > 1) {
              newData.splice(index1, 1, {
                ...i,
                parentName: values.name,
                parentWeighting: values.weighting,
              });
            }
          }

          setDataSource(newData);
        } else {
          newData.splice(index, 1, {
            ...item,
            ...values,
            rootName: current.rootName,
            rootWeighting: current.rootWeighting,
            parentName: current.parentName,
            parentWeighting: current.parentWeighting,
            selfName: values.name,
            selfWeighting: values.weighting,
            integratedAppPermissionCode: values.permissionCode
              ? undefined
              : values.integratedAppPermissionCode
                ? values.integratedAppPermissionCode[2]
                : undefined,
            integratedAppId: values.permissionCode ? undefined : values.integratedAppId,
            permissionCode: values.permissionCode
              ? values.permissionCode[2]
              : values.integratedAppPermissionCode
                ? values.integratedAppPermissionCode[2]
                : undefined,
          });
          setDataSource(newData);
        }
      }
    } else if (current && isAdd) {
      // 如果有current有isAdd，那就是新建下一级
      let newDataSource = null;
      if (current.level === 1) {
        // 新增二级
        let currentId = nanoid();
        newDataSource = [
          ...dataSource,
          {
            ...values,
            currentId,
            rootId: current.rootId,
            rootName: current.rootName,
            rootWeighting: current.rootWeighting,
            parentId: current.currentId,
            parentName: values.name,
            parentWeighting: values.weighting,
            firstLevel: 3,
            secondLevel: 3,
            thirdLevel: 0,
          },
        ];
      } else {
        // 新增三级
        let currentId = nanoid();
        newDataSource = [
          ...dataSource,
          {
            ...values,
            currentId,
            rootId: current.rootId,
            rootName: current.rootName,
            rootWeighting: current.rootWeighting,
            parentId: current.currentId,
            parentName: current.name,
            parentWeighting: current.weighting,
            selfName: values.name,
            selfWeighting: values.weighting,
            firstLevel: 3,
            secondLevel: 3,
            thirdLevel: 3,
            permissionCode: values.permissionCode
              ? values.permissionCode[2]
              : values.integratedAppPermissionCode
                ? values.integratedAppPermissionCode[2]
                : undefined,
            integratedAppPermissionCode: values.integratedAppPermissionCode
              ? values.integratedAppPermissionCode[2]
              : undefined,
          },
        ];
      }
      // prettier-ignore
      let res1 = newDataSource.sort((a, b) => {// NOSONAR
        if (a.rootId === b.rootId) {
          if (a.firstLevel === b.firstLevel) {
            if (a.firstLevel === b.firstLevel && a.rootWeighting === b.rootWeighting) {
              if (a.firstLevel === b.firstLevel && a.secondLevel === b.secondLevel) {
                if (
                  a.firstLevel === b.firstLevel &&
                  a.secondLevel === b.secondLevel &&
                  a.parentWeighting === b.parentWeighting
                ) {
                  if (
                    a.firstLevel === b.firstLevel &&
                    a.secondLevel === b.secondLevel &&
                    a.thirdLevel === b.thirdLevel
                  ) {
                    return b.weighting - a.weighting;
                  } else {
                    return a.thirdLevel! - b.thirdLevel!;
                  }
                } else {
                  return b.parentWeighting! - a.parentWeighting!;
                }
              } else {
                return a.secondLevel! - b.secondLevel!;
              }
            } else {
              return b.rootWeighting! - a.rootWeighting!;
            }
          } else {
            return a.firstLevel! - b.firstLevel!;
          }
        } else {
          return String(b.rootId).toLowerCase().localeCompare(String(a.rootId).toLowerCase());
        }
      });
      setDataSource(res1);
    } else {
      // 新建一级导航
      // 将链接地址多余空白删除
      values = {
        ...values,
        path: values?.path?.trim(),
      };
      let currentId = nanoid();
      let newDataSource = [
        ...dataSource,
        {
          ...values,
          currentId,
          parentId: 0,
          rootId: currentId,
          rootName: values.name,
          rootWeighting: values.weighting,
          firstLevel: 3,
          secondLevel: 0,
          thirdLevel: 0,
        },
      ];
      // prettier-ignore
      let res1 = newDataSource.sort((a, b) => {// NOSONAR
        if (a.rootId - b.rootId) {
          if (a.firstLevel === b.firstLevel) {
            if (a.firstLevel === b.firstLevel && a.rootWeighting === b.rootWeighting) {
              if (a.firstLevel === b.firstLevel && a.secondLevel === b.secondLevel) {
                if (
                  a.firstLevel === b.firstLevel &&
                  a.secondLevel === b.secondLevel &&
                  a.parentWeighting === b.parentWeighting
                ) {
                  if (
                    a.firstLevel === b.firstLevel &&
                    a.secondLevel === b.secondLevel &&
                    a.thirdLevel === b.thirdLevel
                  ) {
                    return b.weighting - a.weighting;
                  } else {
                    return a.thirdLevel! - b.thirdLevel!;
                  }
                } else {
                  return b.parentWeighting! - a.parentWeighting!;
                }
              } else {
                return a.secondLevel! - b.secondLevel!;
              }
            } else {
              return b.rootWeighting! - a.rootWeighting!;
            }
          } else {
            return a.firstLevel! - b.firstLevel!;
          }
        } else {
          return String(b.rootId).toLowerCase().localeCompare(String(a.rootId).toLowerCase());
        }
      });
      setDataSource(res1);
    }
    onCancelAction();
  };

  const onCellphoneChange = (value: string) => {
    if (value === cellphoneRes?.cellphone) {
      createUserForm.setFieldsValue({
        ...cellphoneRes,
        birthday: cellphoneRes.birthday ? dayjs(cellphoneRes.birthday) : undefined,
        workEmail: cellphoneRes.email,
        workQq: cellphoneRes.qq,
        workWechat: cellphoneRes.wechat,
      });
      setCreateUserDisabled(true);
    }
  };

  const onInputChange = (v: string, key: string) => {
    createUserForm.setFieldsValue({
      [key]: v,
    });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onUsernameChange = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value) {
        getByUsername(e.target.value).then(res => {
          setUsernameResRes(res);
        });
      } else {
        setUsernameResRes(undefined);
      }
    }, 500),
    []
  );

  const onCreateUserAndStaffFinish = (value: OpenAccoundRequest) => {
    openAccound({
      ...value,
      birthday: value.birthday ? dayjs(value.birthday).format(dateFormat) : undefined,
      muid: cellphoneRes?.muid,
      mcid: tenant?.mcid!,
    }).then(res => {
      setCreateAcountRes(res);
    });
  };

  const onOk = () => {
    setCreateAcountRes(undefined);
    updateFn && updateFn();
  };

  const onCacel = () => {
    modalApi.confirm({
      title: null,
      icon: null,
      content: <div>是否放弃所有未保存信息并返回列表？</div>,
      onOk: () => {
        navigate('/tenant/customer/manage');
      },
    });
  };

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [iconList, setIconList] = useState<UploadFile[]>([]);

  const handleChange: UploadProps['onChange'] = ({ file, fileList }) => {
    setFileList(fileList);
  };

  const handleIconChange: UploadProps['onChange'] = ({ file, fileList }) => {
    setIconList(fileList);
  };

  interface AppletItem {
    disabled: boolean;
    key: string;
    label: string;
    title: string;
    value: string;
  }

  const onUpdateAccount = (
    value: Omit<UpdateInfoOpenAccountRequest, 'mcid' | 'tenantOrganizationLevelUpdRequestList'> & {
      tenantOrganizationLevelVoList: DataType[];
      applet?: AppletItem;
    }
  ) => {
    let newArr = JSON.parse(JSON.stringify(dataSource));
    newArr.forEach((item: any) => {
      delete item.firstLevel;
      delete item.secondLevel;
      delete item.thirdLevel;
      delete item.rootName;
      delete item.rootWeighting;
      delete item.parentName;
      delete item.parentWeighting;
      delete item.selfName;
      delete item.selfWeighting;
    });
    // 保存并前往租户管理
    updateInfoOpenAccount({
      navigationList: newArr,
      navigationId: value.navigationId,
      mcid: tenant?.mcid!,
      shortName: value.shortName,
      fullName: value.fullName,
      logoUrl: value.logoUrl ?? defaultLogoKey?.key,
      tenantOrganizationLevelUpdRequestList: (value.tenantOrganizationLevelVoList ?? []).map(i => ({
        index: i.key as number,
        name: i.name,
      })),
      passwordOpSwitch: value.passwordOpSwitch === true ? PassWordIsOpen.OPEN : PassWordIsOpen.CLOSE,
      homePageLogoUrl: value.homePageLogoUrl,
      homePageLogoType: value.isSetHomePageOptions!,
      platformTitleType: value.platformTitleType,
      platformName: value.platformName,
      iconUrl: value.iconUrl,
    }).then(res => {
      // 绑定小程序  如果不选择小程序 就过滤掉
      apiTenantBindMiniAppPost({
        tenantMcid: tenant?.mcid!,
        miniAppId: !isNil(value.applet) ? Number(value.applet) : undefined,
      });

      updateFn && updateFn();
      navigate(`/tenant/customer/manage/edit/${tenant?.mcid!}/function-permissions`);
    });
  };

  const uploadButton = (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <PlusOutlined
        style={{
          position: 'absolute',
          left: '44px',
          top: '28px',
          fontSize: '18px',
          cursor: 'pointer',
        }}
      />
      <div className={styles.pText}>
        <p>上传图片</p>
      </div>
    </div>
  );

  const previewModal = () => {
    let homePageType = updateUserForm.getFieldValue('isSetHomePageOptions');
    let customHomePageUrl = updateUserForm.getFieldValue('homePageLogoUrl');
    const imgSrc =
      homePageType === HomePageType.DEFAULT1
        ? defaultHomePage1
        : homePageType === HomePageType.DEFAULT2
        ? defaultHomePage2
        : getRealUrl(customHomePageUrl);
    const imgTip = HomePageType.DEFAULT1
      ? '默认首页图1'
      : homePageType === HomePageType.DEFAULT2
      ? '默认首页图2'
      : '自定义图片';
    modalApi.info({
      title: '预览',
      width: 1180,
      content: (
        <div className={styles.previewModal}>
          <img className={styles.topMenu} src={titleLine} alt="顶部导航" />
          <img className={styles.previewImg} src={imgSrc} alt={imgTip} />
        </div>
      ),
      okText: '确认',
    });
  };

  const beforeUploadHomeImg = (file: UploadFile, fileList: UploadFile[]) => {
    const { size, type } = file;
    if ((size as number) > 20971520) {
      modalApi.error({ content: `上传图片超过20MB` });
      return Upload.LIST_IGNORE;
    }
    if (type !== 'image/jpeg' && type !== 'image/png' && type !== 'image/gif') {
      modalApi.error({ content: `仅支持.jpg, .jpeg, .png, .gif格式` });
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const beforeUploadIcon = async (file: UploadFile, fileList: UploadFile[]) => {
    const { size, type } = file;
    if ((size as number) > 50 * 1024) {
      modalApi.error({ content: `上传图片超过50K` });
      return Upload.LIST_IGNORE;
    }
    if (
      type !== 'image/jpeg' &&
      type !== 'image/png' &&
      type !== 'image/vnd.microsoft.icon' &&
      type !== 'image/x-icon'
    ) {
      modalApi.error({ content: `仅支持.ico, .jpg, .jpeg, .png格式` });
      return Upload.LIST_IGNORE;
    }
    let isSuitable = await checkImgSize(file);
    if (!isSuitable) {
      modalApi.error({ content: `图片尺寸需要小于128*128px` });
      return Upload.LIST_IGNORE;
    }
    return true;
  };
  // 检查图片大小
  const checkImgSize = (file: UploadFile) => {
    const width = 128;
    const height = 128;
    return new Promise((resolve, reject) => {
      let _URL = window.URL || window.webkitURL;
      let img = new Image();
      img.onload = function () {
        let valid = img.width <= width && img.height <= height;
        valid ? resolve(true) : resolve(false);
      };
      img.src = _URL.createObjectURL(file as unknown as Blob);
    });
  };

  const startAccountForm = (
    <Form
      layout="vertical"
      {...formLayout}
      form={createUserForm}
      validateTrigger={['onBlur']}
      initialValues={{ status: UserStatus.ENABLE }}
      onFinish={onCreateUserAndStaffFinish}
    >
      <Row>
        <Col span={12}>
          <Form.Item
            name="shortName"
            label="租户根节点简称"
            validateFirst
            rules={[{ required: true }, { max: 8, message: '租户根节点简称最多8个字符' }]}
          >
            <Input placeholder="请输入租户根节点简称" disabled={disabled} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="fullName"
            label="租户根节点全称"
            validateFirst
            rules={[
              { required: true },
              {
                pattern: /^[\u4e00-\u9fa5a-zA-Z0-9\s\-.,()\uFF08\uFF09]+$/g,
                message: '租户根节点全称不能包含特殊字符',
              },
              { max: 30, message: '租户根节点全称最多30个字符' },
            ]}
          >
            <Input placeholder="请输入租户根节点全称" disabled={disabled} />
          </Form.Item>
        </Col>
      </Row>
      <FormContent style={{ paddingLeft: 0 }} title="租户管理员设定">
        <Row>
          <Col span={8}>
            <Form.Item
              name="cellphone"
              label="手机号码"
              validateFirst
              normalize={transformSpace}
              extra="登录密码将通过手机号发送，请确保手机号可用"
              rules={[{ required: true }]}
            >
              <CustomSearchInput
                disabled={disabled}
                timer={300}
                onSearch={onPhoneSearch}
                placeholder="请输入手机号"
                option={option}
                onChange={onCellphoneChange}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="username"
              label="用户登录账号"
              validateFirst
              rules={[
                { required: true },
                formInstance => ({
                  validator(_: any, value: string) {
                    if (usernameRes && usernameRes.username === value) {
                      return Promise.reject('抱歉，这个用户账号已经被他人使用了，请换一个');
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <Input
                placeholder="请输入用户登录账号"
                disabled={createUserDisabled || disabled}
                onChange={onUsernameChange}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="name"
              label="用户姓名"
              validateFirst
              rules={[
                { required: true, message: '请输入用户姓名' },
                { pattern: /^[\u4e00-\u9fa5]+$/g, message: '用户姓名必须为汉字' },
                { max: 8, message: '用户姓名最多8个字' },
              ]}
            >
              <Input placeholder="请输入用户姓名" disabled={createUserDisabled || disabled} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="status" label="用户状态" validateFirst rules={[{ required: true }]}>
              <Select options={statusOptions} disabled={createUserDisabled || disabled} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="gender" label="性别" validateFirst>
              <Radio.Group options={genderOptions} disabled={createUserDisabled || disabled} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="birthday" label="生日">
              <DatePicker
                style={{ width: '100%' }}
                disabledDate={(currentDate: Dayjs) => {
                  return currentDate.isAfter(dayjs(), 'date');
                }}
                disabled={createUserDisabled || disabled}
                placeholder="请输入生日"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="email" label="邮箱" validateFirst rules={[{ type: 'email', message: '请输入正确的邮箱' }]}>
              <EmailFormItem
                onChange={v => {
                  onInputChange(v, 'workEmail');
                }}
                disabled={createUserDisabled || disabled}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="wechat"
              label="微信"
              rules={[
                {
                  pattern: /^[a-zA-Z0-9_-]{6,20}?$/,
                  message: '请输入正确的微信',
                },
              ]}
              validateFirst
            >
              <Input
                placeholder="请输入微信"
                onChange={e => {
                  onInputChange(e.target.value, 'workWechat');
                }}
                disabled={createUserDisabled || disabled}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="qq"
              label="QQ"
              rules={[
                {
                  pattern: /^\d{4,15}?$/,
                  message: '请输入正确的QQ',
                },
              ]}
              validateFirst
            >
              <Input
                placeholder="请输入QQ"
                onChange={e => {
                  onInputChange(e.target.value, 'workQq');
                }}
                disabled={createUserDisabled || disabled}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="头像" validateFirst name="headPic">
              {/* <AttachmentUpload disabled={createUserDisabled || disabled} /> */}
              <AttachmentUpload />
            </Form.Item>
          </Col>
          <Divider />
          <Col span={8}>
            <Form.Item
              label="企业员工工号"
              validateFirst
              rules={[
                { pattern: /^(?=[a-zA-Z]*[0-9])(?=[0-9]*[a-zA-Z])[a-zA-Z0-9]+$/, message: '企业员工工号为字母加数字' },
                { max: 12, message: '企业员工工号最多12位' },
              ]}
              name="staffCode"
            >
              <Input disabled={disabled} placeholder="字母+数字，最多12位，可不填，不填则按系统默认规则生成" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="工作邮箱"
              validateFirst
              rules={[{ type: 'email', message: '请输入正确的邮箱' }]}
              name="workEmail"
            >
              <Input placeholder="请输入正确的邮箱" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="工作微信"
              validateFirst
              rules={[
                {
                  pattern: /^[a-zA-Z0-9_-]{6,20}?$/,
                  message: '请输入正确的微信',
                },
              ]}
              name="workWechat"
            >
              <Input placeholder="请输入正确的微信" disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="工作QQ"
              validateFirst
              rules={[
                {
                  pattern: /^\d{4,15}?$/,
                  message: '请输入正确的QQ',
                },
              ]}
              name="workQq"
            >
              <Input placeholder="请输入正确的QQ" disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
      </FormContent>
      <FormContent style={{ paddingLeft: 0 }} title="租户导航方案">
        <Row>
          <Col span={12}>
            <Form.Item name="navigationId" label="导航方案" validateFirst rules={[{ required: true }]}>
              <Select placeholder="请选择导航方案" disabled={disabled} onChange={val => setNavgationId(val)}>
                {navgationList.map(i => (
                  <Select.Option key={i.id} value={i.id}>{`${i.name}`}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </FormContent>
      <Row>
        <Col span={8}>
          <Table
            rowKey={'id'}
            dataSource={navgationDetailList}
            bordered
            pagination={false}
            columns={[
              {
                title: '名称',
                dataIndex: 'name',
                width: '220px',
              },
              {
                title: '权重',
                dataIndex: 'weighting',
              },
            ]}
          />
          {/* <Descriptions title="导航方案预览（一级导航权重）：" layout='vertical' bordered column={{ xs: 8, sm: 16, md: 24 }}>
            <Descriptions.Item key={'title'} style={{ width: '110px', textAlign: 'center', fontWeight: 700, backgroundColor: '#fafafa', color: 'black' }} label={'导航名称'}> {<div>权重</div>}</Descriptions.Item>
            {
              navgationDetailList.map(i => (<Descriptions.Item key={i.id} label={`${i.name}`}>{`${i.weighting}`}</Descriptions.Item>))
            }
          </Descriptions> */}
        </Col>
      </Row>
      {!disabled && (
        <Row className={styles.buttonWrapper}>
          <Divider />
          <Col span={7}>
            <Form.Item>
              <Space>
                <Button htmlType="submit" type="primary">
                  保存
                </Button>
                <Button onClick={onCacel}>关闭</Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      )}
    </Form>
  );

  const accountForm = (
    <Form
      layout="vertical"
      form={updateUserForm}
      onFinish={onUpdateAccount}
      initialValues={{
        isSetHomePageOptions: HomePageType.DEFAULT1,
        platformTitleType: TitleType.PLATFORM,
        platformName: DEFAULT_PLATFORM_NAME,
      }}
      {...formLayout}
    >
      <Row>
        <Col span={12}>
          <Form.Item
            name="shortName"
            label="租户根节点简称"
            validateFirst
            rules={[{ required: true }, { max: 8, message: '租户根节点简称最多8个字符' }]}
          >
            <Input placeholder="请输入租户根节点简称" disabled={disabled} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="fullName"
            label="租户根节点全称"
            validateFirst
            rules={[
              { required: true },
              {
                pattern: /^[\u4e00-\u9fa5a-zA-Z0-9\s\-.,()\uFF08\uFF09]+$/g,
                message: '租户根节点全称不能包含特殊字符',
              },
              { max: 30, message: '租户根节点简称最多30个字符' },
            ]}
          >
            <Input placeholder="请输入租户根节点全称" disabled={disabled} />
          </Form.Item>
        </Col>
        <Space>
          <Button type="primary">
            <Link to="/tenant/customer/user" target="_blank">
              租户用户列表
            </Link>
          </Button>
          <Button type="primary">
            <Link to="/tenant/customer/employee" target="_blank">
              租户员工列表
            </Link>
          </Button>
        </Space>
      </Row>

      <FormContent style={{ paddingLeft: 0 }} title="组织个性化设定">
        <Row>
          <Col span={24}>
            <Form.Item name="tenantOrganizationLevelVoList" valuePropName="dataSource" trigger="onDataSourceChange">
              <OrganizationTable disabled={disabled} />
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <Form.Item label="客户logo" name="logoUrl">
              <AttachmentUpload disabled={disabled} />
            </Form.Item>
          </Col>
          <Col span={12}>
            {defaultLogoKey?.key && (
              <div className={styles.example}>
                <span>示例：</span>
                <img src={getRealUrl(defaultLogoKey?.key)} alt="示例" />
              </div>
            )}
            <div className={styles.tips}>提示：示例logo为默认logo，若不上传logo，则默认使用该logo</div>
          </Col>
          {/* <Col span={12}>
            <ThemeSelect disabled={disabled} mcid={tenant?.mcid} />
          </Col> */}
        </Row>
      </FormContent>
      <FormContent style={{ paddingLeft: 0 }} title="浏览器设置">
        <Row>
          <Col span={12}>
            <Form.Item label="标签页icon">
              <Form.Item
                name="iconUrl"
                valuePropName="file"
                noStyle
                getValueFromEvent={e => {
                  if (e && e.file && e.file.response) {
                    return e.file.response.key;
                  }
                  if (e && e.file && e.file.status === 'removed') {
                    return null;
                  }
                  return e && e.file;
                }}
              >
                <Upload
                  action="/api/file-center/upload"
                  accept=".ico, .jpg, .jpeg, .png"
                  maxCount={1}
                  fileList={iconList}
                  listType="picture-card"
                  onChange={handleIconChange}
                  showUploadList={{ showPreviewIcon: false }}
                  className={styles.imgUpload}
                  beforeUpload={beforeUploadIcon}
                  disabled={disabled}
                >
                  {iconList.length === 1 ? null : uploadButton}
                </Upload>
              </Form.Item>
              <div className={styles.uploadTip}>支持.ico, .jpg, .jpeg, .png格式，尺寸小于128*128px，文件小于50K</div>
            </Form.Item>
          </Col>
          <Col span={12}>
            <div className={styles.example}>
              <span>示例：</span>
              <img src={templateIcon} style={{ width: 240, height: 50 }} alt="示例" />
            </div>
            <div className={styles.tips}>提示：示例为默认标签页icon与标签页标题</div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="标签页标题" name="platformTitleType">
              <Radio.Group
                options={titleOptions}
                disabled={disabled}
                onChange={(e: RadioChangeEvent) => {
                  if (e.target.value !== TitleType.NAVIGATE) {
                    updateUserForm.setFieldsValue({ platformName: DEFAULT_PLATFORM_NAME });
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item noStyle dependencies={['platformTitleType']}>
              {({ getFieldValue }) => {
                return (
                  getFieldValue('platformTitleType') !== TitleType.NAVIGATE && (
                    <Form.Item
                      name="platformName"
                      label="平台名称"
                      validateFirst
                      rules={[
                        { required: true },
                        { pattern: /^[\u4e00-\u9fa50-9a-zA-Z]+$/g, message: '平台名称必须汉字字母数字' },
                        { max: 15, message: '平台名称最多15个字符' },
                      ]}
                    >
                      <Input placeholder="请输入平台名称" disabled={disabled} />
                    </Form.Item>
                  )
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      </FormContent>
      <FormContent style={{ paddingLeft: 0 }} title="租户首页图">
        <Form.Item name="isSetHomePageOptions">
          <Radio.Group options={isSetHomePageOptions} disabled={disabled} />
        </Form.Item>
        <Form.Item noStyle dependencies={['isSetHomePageOptions']}>
          {({ getFieldValue }) => {
            const isSetHomePageOptions = getFieldValue('isSetHomePageOptions');
            if (isSetHomePageOptions === HomePageType.CUSTOM) {
              return (
                <Row>
                  <Col span={24}>
                    <Form.Item>
                      <Form.Item
                        validateFirst
                        name="homePageLogoUrl"
                        rules={[{ required: true, message: '请上传租户首页图' }]}
                        noStyle
                        valuePropName="file"
                        getValueFromEvent={e => {
                          if (e && e.file && e.file.response) {
                            return e.file.response.key;
                          }
                          if (e && e.file && e.file.status === 'removed') {
                            return null;
                          }
                          return e && e.file;
                        }}
                      >
                        <Upload
                          action="/api/file-center/upload"
                          accept=".jpg, .jpeg, .png, .gif"
                          maxCount={1}
                          fileList={fileList}
                          listType="picture-card"
                          onChange={handleChange}
                          showUploadList={{ showPreviewIcon: false }}
                          className={styles.imgUpload}
                          beforeUpload={beforeUploadHomeImg}
                          disabled={disabled}
                        >
                          {fileList.length === 1 ? null : uploadButton}
                        </Upload>
                      </Form.Item>
                      <div className={styles.uploadTip}>支持.jpg, .jpeg, .png, .gif格式，文件不超过20MB</div>
                    </Form.Item>
                  </Col>
                </Row>
              );
            } else if (isSetHomePageOptions === HomePageType.DEFAULT1) {
              return (
                <Row>
                  <Col span={24}>
                    <img className={styles.defaultPic} src={defaultHomePage1} alt="" />
                  </Col>
                </Row>
              );
            } else if (isSetHomePageOptions === HomePageType.DEFAULT2) {
              return (
                <Row>
                  <Col span={24}>
                    <img className={styles.defaultPic} src={defaultHomePage2} alt="" />
                  </Col>
                </Row>
              );
            }
          }}
        </Form.Item>
        <Form.Item noStyle dependencies={['isSetHomePageOptions']}>
          {({ getFieldValue }) => {
            const isSetHomePageOptions = getFieldValue('isSetHomePageOptions');
            return (
              <Form.Item>
                <Button
                  type="primary"
                  onClick={() => previewModal()}
                  disabled={fileList.length === 0 && isSetHomePageOptions === HomePageType.CUSTOM}
                >
                  预览
                </Button>
              </Form.Item>
            );
          }}
        </Form.Item>
      </FormContent>
      <FormContent style={{ paddingLeft: 0 }} title="账号设置">
        <Form.Item name="passwordOpSwitch" valuePropName="checked">
          <Checkbox>租户端创建账号显示密码、支持重置密码</Checkbox>
        </Form.Item>
      </FormContent>
      <FormContent style={{ paddingLeft: 0 }} title="租户导航方案">
        <Row>
          <Col span={12}>
            <Form.Item name="navigationId" label="导航方案" validateFirst rules={[{ required: true }]}>
              <Select placeholder="请选择导航方案" disabled={disabled} onChange={val => setNavgationId(val)}>
                {navgationList.map(i => (
                  <Select.Option key={i.id} value={i.id}>{`${i.name}`}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </FormContent>
      <Row>
        <Col span={8}>
          <Table
            rowKey={'id'}
            dataSource={navgationDetailList}
            bordered
            pagination={false}
            columns={[
              {
                title: '名称',
                dataIndex: 'name',
                width: '220px',
              },
              {
                title: '权重',
                dataIndex: 'weighting',
              },
            ]}
          />
          {/* <Descriptions title="导航方案预览（一级导航权重）：" layout='vertical' bordered column={8}>
            <Descriptions.Item key={'title'} style={{ width: '110px', textAlign: 'center', fontWeight: 700, backgroundColor: '#fafafa', color: 'black' }} label={'导航名称'}> {<div>权重</div>}</Descriptions.Item>
            {
              navgationDetailList.map(i => (<Descriptions.Item key={i.id} label={`${i.name}`}>{`${i.weighting}`}</Descriptions.Item>))
            }
          </Descriptions> */}
        </Col>
      </Row>

      <FormContent
        style={{ paddingLeft: 0 }}
        title={
          <div>
            <span style={{ marginRight: '10px' }}>自定义外部导航</span>
            <Tooltip title="有新增自定义导航可配置，需要配置到三级导航（页面级）">
              <InfoCircleOutlined />
            </Tooltip>
          </div>
        }
      >
        <div style={{ marginBottom: '20px' }}>
          <Button
            type="primary"
            disabled={disabled}
            onClick={() => {
              setEditModalOpened(true);
              setLevel(1);
              setIsAdd(true);
            }}
          >
            新建一级导航
          </Button>
        </div>
      </FormContent>

      <Row>
        <Col span={24}>
          <Form.Item name="navigationList" wrapperCol={{ span: 24 }}>
            <Table
              rowKey="id"
              sticky
              scroll={{ x: 1300 }}
              loading={loading}
              columns={buildColumns}
              dataSource={dataSource}
              pagination={false}
              locale={{
                emptyText: (
                  <>
                    <span>暂未配置，请先新建一级导航</span>
                  </>
                ),
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      {/* 小程序 */}
      <FormContent style={{ paddingLeft: 0 }} title="租户小程序">
        <Col span={12}>
          <Form.Item name="applet" label="小程序">
            <Select
              allowClear
              disabled={disabled}
              placeholder="请选择绑定的小程序"
              options={appletOptionsFn()}
              onChange={appletOnchange}
            />
          </Form.Item>
        </Col>
        {!isNil(appletTenant) ? (
          <Col span={8}>
            <Table
              rowKey={'id'}
              columns={[
                {
                  title: '底部菜单',
                  dataIndex: 'name',
                  ellipsis: { showTitle: true },
                  align: 'center',
                },
              ]}
              dataSource={appletMenuData}
              pagination={false}
            />
          </Col>
        ) : null}
      </FormContent>
      <Modal
        title={`导航配置`}
        width={600}
        open={editModalOpened}
        onCancel={onCancelAction}
        destroyOnClose
        onOk={() => form.submit()}
        okText={'保存'}
        okButtonProps={{ disabled }}
        bodyStyle={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <EditFormModal
          // editId={editId}
          disabled={disabled}
          isAdd={isAdd}
          form={form}
          current={current}
          onFinish={onEditFormFinish}
          level={level}
        />
      </Modal>

      {!disabled && (
        <Row className={styles.buttonWrapper}>
          <Divider />
          <Col span={7}>
            <Form.Item>
              <Space>
                <Button htmlType="submit" type="primary">
                  保存并前往租户权限管理
                </Button>
                <Button onClick={onCacel}>关闭</Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      )}
    </Form>
  );

  return (
    <div className={styles.container}>
      {!account ? (
        startAccount ? (
          startAccountForm
        ) : (
          <Space size={15}>
            <Button
              type="primary"
              disabled={disabled}
              onClick={() => {
                setStartAccount(true);
              }}
            >
              开始编辑开户信息
            </Button>
            <div>
              <Space size={5}>
                <ExclamationCircleOutlined className={styles.icon} />
                <span>注意：点击后必须填写相关必填项才能保存，未确定要为租户在系统内开户时不要点击！</span>
              </Space>
            </div>
          </Space>
        )
      ) : (
        accountForm
      )}
      <Modal
        width={540}
        open={!!createAccountRes}
        title="操作成功"
        onCancel={() => {
          setCreateAcountRes(undefined);
          updateFn && updateFn();
        }}
        footer={
          <Space>
            <CopyToClipboard
              text={`用户登录账号为${createAccountRes?.username},员工工号为${createAccountRes?.staff?.staffCode}${
                createAccountRes?.password ? '，密码为：' + createAccountRes?.password : ''
              }`}
            >
              <Button
                onClick={() => {
                  messageApi.success('复制成功');
                }}
                type="primary"
              >
                复制员工信息
              </Button>
            </CopyToClipboard>
            <Button type="primary" onClick={onOk}>
              确定并继续编辑其他信息
            </Button>
          </Space>
        }
      >
        <div>已经成功创建该租户的第一个员工</div>
        <div>
          用户登录账号为{createAccountRes?.username}
          {createAccountRes?.password && '，密码为' + createAccountRes?.password}
        </div>
        <div>员工工号为{createAccountRes?.staff?.staffCode}</div>
      </Modal>
      {messageContextHolder}
      {modalContextHolder}
    </div>
  );
};

export default Accounts;
