import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { App, Button, Form, Input, Select, Spin } from 'antd';
import { Wrapper } from '@maxtropy/components';
import { apiCarbonIndustryAllPost, CarbonIndustryAllPostResponse } from '@maxtropy/device-mgmt-apis';
import { v4 } from 'uuid';
import BasicInfoTable, { BasicInfoTableRef, FieldWithKey } from './BasicInfoTable';
import styles from '../index.module.scss';
import StepsView from '../components/StepsView';
import { useReadonly } from '../hooks';
import { apiV2CarbonEmissionProcessEditBasicPost } from '@maxtropy/device-mgmt-apis-v2';
import {
  apiV2CarbonEmissionProcessDetailPost,
  apiV2CarbonEmissionProcessEditNamePost,
} from '@maxtropy/tody-mgmt-apis-v2';
import { isEqual } from 'lodash-es';

const { Item } = Form;

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 8 },
};

export interface BasicInfoData {
  name?: string;
  industryId?: number;
  fields?: FieldWithKey[];
}

interface BasicInfoProps {
  isEdit: boolean;
}

const CarbonEmissionsEditBasicInfo: FC<BasicInfoProps> = ({ isEdit = false }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { modal } = App.useApp();
  const readonly = useReadonly();
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm<BasicInfoData>();
  const [industryList, setIndustryList] = useState<CarbonIndustryAllPostResponse>([]);
  const basicInfoTableRef = useRef<BasicInfoTableRef>(null);
  const [useFlag, setUseFlag] = useState<boolean>(false); // 排放过程是否被使用
  const [originData, setOriginData] = useState<FieldWithKey[]>([]);
  const data = Form.useWatch('fields', { form, preserve: true });

  useEffect(() => {
    if (!id) return;
    setLoading(true);
    apiV2CarbonEmissionProcessDetailPost({ id: Number(id) })
      .then(res => {
        const newData = { ...res } as BasicInfoData;
        newData.fields?.forEach((item, index) => {
          item.key = v4();
        });
        form.setFieldsValue(newData);
        setOriginData(newData.fields || []);
        if (isEdit) {
          setUseFlag(!!res.useFlag);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    apiCarbonIndustryAllPost({}).then(res => {
      setIndustryList(res);
    });
  }, []);

  const routes = useMemo(() => {
    return [{ name: '企业碳排查' }, { name: '排放过程管理' }, { name: id ? '编辑排放过程' : '新建排放过程' }];
  }, [id]);

  const confirm = async () => {
    if (readonly) {
      navigate(`/dualCarbon/carbonEmissions/process/calcFormula/detail/${id}`);
      return;
    }
    if (id) {
      const isAllPreserve = isEqual(data, originData);
      if (isAllPreserve) {
        await apiV2CarbonEmissionProcessEditNamePost({ id: Number(id), name: form.getFieldValue('name') });
        navigate(`/dualCarbon/carbonEmissions/process/calcFormula/edit/${id}`);
        return;
      }
    }
    form.validateFields().then(() => {
      if (!basicInfoTableRef.current?.getSubmitData()) return;
      setLoading(true);
      apiV2CarbonEmissionProcessEditBasicPost(form.getFieldsValue(true))
        .then(res => {
          navigate(`/dualCarbon/carbonEmissions/process/calcFormula/edit/${res.id}`);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const cancel = () => {
    modal.confirm({
      content: '确认放弃未保存的修改返回列表吗?',
      onOk: () => {
        navigate('/dualCarbon/carbonEmissions/process');
      },
    });
  };

  return (
    <Spin spinning={loading}>
      <Wrapper routes={routes} className={styles.wrapper}>
        <div className={styles.wrapperInner}>
          <StepsView currentStep={0}></StepsView>
          <Form<BasicInfoData>
            disabled={readonly}
            form={form}
            layout="vertical"
            {...formLayout}
            style={{ width: '100%' }}
          >
            <Item
              label="名称"
              name="name"
              rules={[
                {
                  required: true,
                  min: 2,
                  max: 50,
                  message: '请输入排放过程名称，2-50个字',
                },
              ]}
            >
              <Input placeholder="请输入排放过程名称，2-50个字" />
            </Item>
            <Item
              label="行业"
              name="industryId"
              rules={[
                {
                  required: true,
                  message: '请选择行业',
                },
              ]}
            >
              <Select placeholder="请选择行业" disabled={!!id}>
                {industryList.map(item => (
                  <Select.Option value={item.id} key={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Item>
            <Item label="表单" name="fields" required wrapperCol={{ span: 24 }}>
              <BasicInfoTable
                useFlag={useFlag}
                ref={basicInfoTableRef}
                originData={originData}
                setOriginData={setOriginData}
                form={form}
              ></BasicInfoTable>
            </Item>
          </Form>

          <div className={styles.ctrlWrap}>
            <Button type="primary" onClick={confirm}>
              下一步
            </Button>
            {readonly ? (
              <Button
                onClick={() => {
                  navigate('/dualCarbon/carbonEmissions/process');
                }}
              >
                关闭
              </Button>
            ) : (
              <Button onClick={cancel}>取消</Button>
            )}
          </div>
        </div>
      </Wrapper>
    </Spin>
  );
};

export default CarbonEmissionsEditBasicInfo;
