import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Space, Table, Row, Col, Tag, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { debounce } from 'lodash-es';

import { EllipsisSpan, SearchInput } from '@maxtropy/components';

import { getAllTenant, TenantAllRootReponse, TenantBaseInfoResponse } from '../../../../api/cc-tenant';

import styles from './index.module.scss';

const columns = [
  {
    title: '企业名称',
    dataIndex: 'name',
    key: 'name',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '统一信用代码',
    dataIndex: 'uscc',
    key: 'uscc',
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

interface ModalSelectProps {
  value?: TenantAllRootReponse[];
  onChange?: (value: TenantAllRootReponse[]) => void;
  tenant?: TenantBaseInfoResponse;
  disabled?: boolean;
}

const ModalSelect: FC<ModalSelectProps> = props => {
  const { value, onChange, tenant, disabled = false } = props;

  const [opened, setOpened] = useState(false);
  const [searchData, setSearchData] = useState<TenantAllRootReponse[]>([]);
  const [selectData, setSelectData] = useState<TenantAllRootReponse[]>([]);
  const [selectValues, setSelectValues] = useState<string[]>([]);

  const onOpen = () => {
    setOpened(true);
  };

  const localChange = (datas: TenantAllRootReponse[]) => {
    onChange && onChange(datas);
  };

  const onOk = () => {
    const filterData = selectData.filter(i => (value ?? []).findIndex(t => t.mcid === i.mcid) === -1);
    localChange([...(value ?? []), ...filterData]);
    setOpened(false);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(
    debounce((search: string) => {
      if (!search) {
        setSearchData([]);
        return;
      }
      getAllTenant({ value: search }).then(res => {
        setSearchData(res);
      });
    }),
    []
  );

  const onDelete = (data: TenantAllRootReponse) => {
    localChange([...(value ?? [])?.filter(i => i.mcid !== data.mcid)]);
  };

  const onSelect = (values: string[]) => {
    setSelectValues(values);
    const filterSelectData = selectData.filter(i => values.includes(i.mcid));
    values.forEach(i => {
      if (selectData.findIndex(data => data.mcid === i) === -1) {
        const index = searchData.findIndex(data => data.mcid === i);
        if (index !== -1) {
          filterSelectData.push(searchData[index]);
        }
      }
    });
    setSelectData([...filterSelectData]);
  };

  const mergeColumns = [
    ...columns,
    {
      title: '操作',
      dataIndex: 'operation',
      render: (value: undefined, record: TenantAllRootReponse) => {
        return (
          <Button
            type="link"
            onClick={() => {
              onDelete(record);
            }}
          >
            删除
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    if (!opened) {
      setSearchData([]);
      setSelectData([]);
      setSelectValues([]);
    }
  }, [opened]);

  const onClose = (mcid: string) => {
    const filterValues = selectValues.filter(i => i !== mcid);
    onSelect([...filterValues]);
  };

  const option = useMemo(() => {
    return searchData
      .filter(i => i.mcid !== tenant?.mcid)
      .map(i => ({
        value: i.mcid,
        name: `${i.name} ${i?.uscc ?? ''}`,
      }));
  }, [searchData, tenant]);

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {!disabled && (
        <Button type="dashed" icon={<PlusOutlined />} onClick={onOpen}>
          选择关联企业
        </Button>
      )}

      <Table
        rowKey="mcid"
        className={styles.table}
        dataSource={value ?? []}
        columns={mergeColumns}
        pagination={false}
      />
      <Modal
        title="选择企业"
        open={opened}
        width={700}
        onOk={onOk}
        onCancel={() => {
          setOpened(false);
        }}
      >
        <Row>
          <Col className={styles.col} span={4}>
            关联企业：
          </Col>
          <Col span={20}>
            <SearchInput<string[]>
              style={{ width: '80%' }}
              onSearch={onSearch}
              timer={500}
              placeholder="请输入部分/全部企业名或输入完整社会统一信用代码证检索"
              option={option}
              mode="multiple"
              onChange={onSelect}
              value={selectValues}
              // prettier-ignore
              tagRender={prop => {// NOSONAR
                const value = selectData.find(i => i.mcid === (prop.value as string));
                return (
                  <Tag
                    closable
                    onClose={() => {
                      onClose(prop.value as string);
                    }}
                  >{`${value?.name} ${value?.uscc ?? ''}`}</Tag>
                );
              }}
            ></SearchInput>
          </Col>
        </Row>
      </Modal>
    </Space>
  );
};

export default ModalSelect;
