import * as React from 'react';
import { DetailByType } from './factory';
import { Form } from 'antd';
import { FormInstance } from 'antd/es/form';
import { useImperativeHandle } from 'react';
import { MutableRefObject } from 'react';
import { UseDaqFormStateProps } from './common/useDaqFormState';
import { MockingbirdFormValues } from '@/shared/components/DataAcquisitionForm/types';

export type DataAcquisitionFormValues = EstunFormValues | MockingbirdFormValues;

export interface EstunFormValues {
  name: string;
  version: number;
  versionRemark: string;
  deviceTypeName: string;
  deviceModelName: string;
  iotProtocol: string;
  remark: string;
}

export interface DataAcquisitionFormRef {
  form: FormInstance<DataAcquisitionFormValues> | null;
}

export interface DataAcquisitionFormProps {
  state: UseDaqFormStateProps;
  isBatch?: boolean;
}

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 9 },
};

const DataAcquisitionForm = React.forwardRef(
  ({ state, isBatch }: DataAcquisitionFormProps, ref: React.Ref<DataAcquisitionFormRef>) => {
    const [form] = Form.useForm();
    const formRef: MutableRefObject<FormInstance<DataAcquisitionFormValues> | null> = React.useRef(null);

    const { iotProtocolType, rootMcid } = state;

    useImperativeHandle(ref, () => ({
      form: formRef.current,
    }));

    const Detail = DetailByType[iotProtocolType!];

    return (
      <Form<DataAcquisitionFormValues> form={form} {...formItemLayout} ref={formRef}>
        {iotProtocolType && rootMcid ? Detail ? <Detail form={form} isBatch={isBatch} state={state} /> : null : <div />}
      </Form>
    );
  }
);

export default DataAcquisitionForm;
