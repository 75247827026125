import { FormInstance } from 'antd/es';
import EstunDetails from './estun/EstunDetails';
import { ComponentType } from 'react';
import { DataAcquisitionFormProps } from './index';
import { IotProtocolType } from '@/shared/types';
import MockingbirdForm from './mockingbird/Form';

export interface DetailsProps<T = any> extends DataAcquisitionFormProps {
  form: FormInstance<T>;
  isBatch?: boolean;
}

export const DetailByType: Partial<Record<IotProtocolType, ComponentType<DetailsProps>>> = {
  [IotProtocolType.ESTUN]: EstunDetails,
  [IotProtocolType.MOCKINGBIRD]: MockingbirdForm,
};
