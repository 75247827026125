import React, { useCallback, useContext, useMemo } from 'react';
import { Table } from 'antd';
import { Button } from '@maxtropy/components';
import { PlusOutlined } from '@ant-design/icons';
import { ActionType, DataPointType, StaticPoint } from '../../../../types';
import { ColumnsType, ColumnType } from 'antd/es/table';
import columns from './columns';
import { useQuery } from '../../../../utils/utils';
import { getDataProperties } from '../../../../api/options';
import FormDialog from './FormDialog';
import { PointContext, PointContextProps } from '../../../EdgeDevicePointInfo/contextTypes';
import Header from '../../../EdgeDevicePointInfo/mockingbird/Header';

export type EdgeDeviceTemplatePoint = StaticPoint & { actionType?: ActionType };

interface StaticPointProps {
  editColumns?: ColumnsType<EdgeDeviceTemplatePoint>;
  onUpdate?: (values: any) => any;
}

const indexColumn: ColumnType<EdgeDeviceTemplatePoint> = {
  title: '序号',
  width: 70,
  fixed: 'left',
  render: (_, record, index) => index + 1,
};

const Static: React.FC<StaticPointProps> = props => {
  const { editColumns, onUpdate } = props;

  const { dataSource, row, setRow, info, promptSlot, editable } = useContext(
    PointContext
  ) as PointContextProps<EdgeDeviceTemplatePoint>;

  const { data: dataPropertiesAll = [] } = useQuery(
    useCallback(
      () =>
        info?.deviceTypeId
          ? getDataProperties(info.iotProtocol, info.deviceTypeId, info.physicalModelId)
          : Promise.resolve([]),
      [info?.deviceTypeId, info?.deviceTypeId, info?.physicalModelId]
    )
  );

  const usedProperties = useMemo(
    () =>
      dataSource
        ?.filter(item => item.actionType !== ActionType.DELETE && item.dataPropertyId !== row?.dataPropertyId)
        .map(item => item.dataPropertyId) || [],
    [dataSource, row]
  );

  const usedComputationalLogic = useMemo(
    () =>
      dataSource
        ?.filter(item => item.actionType !== ActionType.DELETE && item?.computationalLogic !== row?.computationalLogic)
        .map(item => item?.computationalLogic)
        .filter(Boolean) || [],
    [dataSource, row]
  );

  const onOk = (values: any) => {
    const _values = {
      ...values,
      pointType: DataPointType.STATIC_POINT,
    };
    onUpdate?.(_values);
  };

  return (
    <div>
      <Header slot={promptSlot}>
        {editable && (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setRow?.({ pointType: DataPointType.STATIC_POINT })}
          >
            添加静态点
          </Button>
        )}
      </Header>
      <Table
        sticky
        rowKey="id"
        scroll={{ x: 1300, y: 500 }}
        dataSource={dataSource?.filter(item => item.pointType === DataPointType.STATIC_POINT)}
        columns={[indexColumn, ...columns, ...(editColumns || [])]}
        pagination={false}
      />
      <FormDialog
        onCancel={() => setRow?.(undefined)}
        onOk={onOk}
        usedProperties={usedProperties}
        usedComputationalLogic={usedComputationalLogic}
        dataPropertiesAll={dataPropertiesAll}
        row={row}
      />
    </div>
  );
};

export default Static;
