import { getAddressTips, getDistrict, LocationResponse } from '../../../../api/tools';
import { Cascader, Col, Form, FormInstance, Row, Select } from 'antd';
import { gcj02towgs84 } from '@maxtropy/components';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';
const { Option } = Select;
interface Iprops {
  getLngLat?: (laglat: number[]) => void;
  form?: FormInstance;
  adcode?: string;
}
interface Tips {
  address?: string;
  district?: string;
  location?: string;
  id?: string;
  name?: string;
  typecode?: string;
  adcode?: string;
}
/* 递归处理末尾项district为0的空项 */
const getTreeData = (data: any) => {
  // 循环遍历返回的数据
  for (var i = 0; i < data.length; i++) {
    if (data[i].districts.length < 1) {
      // districts若为空数组，则将districts设为undefined
      data[i].districts = undefined;
    } else {
      // districts若不为空数组，则继续 递归调用 本方法
      getTreeData(data[i].districts);
    }
  }
  return data;
};
const fieldNames = {
  value: 'name',
  label: 'name',
  children: 'districts',
};
let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;
let currentAdcode: string; // 当前区域code
const AddressCascader: React.FC<Iprops> = ({ getLngLat, form, adcode }) => {
  const [options, setOptions] = useState<LocationResponse[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [position, setPosition] = useState<number[]>([]);
  // 所在地区
  const areaChange = (value: any, selectedOptions: any) => {
    if (Array.isArray(selectedOptions)) {
      let cityRow = selectedOptions.find(v => ['district', 'street'].includes(v.level));
      if (cityRow) {
        currentAdcode = cityRow.adcode;
      }
    }
    form?.setFieldsValue({
      address: '',
    });
    setData([]);
    setPosition([]);
  };
  // 选择详细地址
  const selectAddress = (value: string) => {
    if (value) {
      let arrTemp = value.split('$');
      if (arrTemp[1]) {
        form?.setFieldsValue({
          address: arrTemp[0],
        });
        let [tempLng, tempLat] = arrTemp[1]?.split(',');
        const lngLat = gcj02towgs84(Number(tempLng), Number(tempLat));
        setPosition(lngLat);
        getLngLat && getLngLat(lngLat);
      } else {
        setPosition([]);
        getLngLat && getLngLat([]);
      }
    }
  };
  // 获取省市区
  useEffect(() => {
    getDistrict({ keywords: '', subdistrict: '3' }).then(res => {
      let data = getTreeData(res.districts);
      setOptions(data);
    });
  }, []);
  useEffect(() => {
    if (adcode) {
      currentAdcode = adcode;
    }
  }, [adcode]);
  // 防抖处理
  const fetchData = (value: string, callback: (data: Tips[]) => void) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;
    const fake = () => {
      getAddressTips({ keywords: value, city: currentAdcode, cityLimit: true }).then(res => {
        if (currentValue === value) {
          // 过滤掉无效数据
          if (res.tips) {
            let sugs = res.tips.filter(item => typeof item.name === 'string' && typeof item.address === 'string');
            callback(sugs);
          }
        }
      });
    };
    timeout = setTimeout(fake, 300);
  };
  // 根据关键字/adcode搜索
  const handleSearch = (newValue: string) => {
    if (newValue) {
      fetchData(newValue, setData);
    } else {
      setData([]);
    }
  };
  const selectOptions = data.map(d => (
    <Option value={d.name + '$' + d.location} key={d.name}>
      {d.name + '-' + d.address}
    </Option>
  ));

  return (
    <Row>
      <Col span={12}>
        <Form.Item label="所在地区" name="location" rules={[{ required: true, message: '请选择' }]}>
          <Cascader fieldNames={fieldNames} options={options} onChange={areaChange} placeholder="请选择" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item noStyle dependencies={['location']}>
          {({ getFieldValue }) => (
            <Form.Item label="详细地址" required>
              <Form.Item
                name="address"
                noStyle
                rules={[
                  { required: true, message: '请输入' },
                  {
                    validator: (rule, value) => {
                      if (!value || value?.indexOf('$') === -1) {
                        return Promise.resolve();
                      }
                      let arrTemp = value.split('$');
                      if (arrTemp[1]) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('该地点无经纬度，请选择就近地点'));
                    },
                  },
                ]}
              >
                <Select
                  disabled={!getFieldValue('location')}
                  showArrow={false}
                  onSelect={selectAddress}
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  notFoundContent={null}
                  onSearch={handleSearch}
                  showSearch
                >
                  {selectOptions}
                </Select>
              </Form.Item>
              <div className={styles.lngLat}>
                经度（°）：{position?.[0] ?? '--'} &nbsp;&nbsp;&nbsp; 纬度（°）：{position?.[1] ?? '--'}
              </div>
            </Form.Item>
          )}
        </Form.Item>
      </Col>
    </Row>
  );
};

export default AddressCascader;
