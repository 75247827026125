import React, { useContext, useState } from 'react';
import { IotProtocolType, ActionType, UploadTypeEnum, UploadTypeEnumDisplay } from '../../../types';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { EllipsisSpan, FieldTable, MxColumnType, MxColumnsType, Table } from '@maxtropy/components';
import dayjs from 'dayjs';
import { EdgeDeviceTemplatePoint } from '../index';
import { PointContext, PointContextProps } from '../contextTypes';

type EdgeDeviceTemplatePointDetail = EdgeDeviceTemplatePoint & {
  edgeTime?: string;
  edgeData?: string | number;
};

export interface EstunPointDetailProps {
  type: IotProtocolType;
  dataSource?: (EdgeDeviceTemplatePointDetail & { actionType?: ActionType })[];
  loading?: boolean;
  hasDataColumns?: boolean;
}

const indexColumn: MxColumnType<EdgeDeviceTemplatePoint> = {
  title: '序号',
  width: 100,
  render: (_, record, index) => index + 1,
};
const columns: MxColumnsType<EdgeDeviceTemplatePoint> = [
  {
    title: '数据属性',
    dataIndex: 'dataPropertyName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '物联层数据点标识',
    dataIndex: 'symbol',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '单位',
    dataIndex: 'physicalUnitGeneralName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '上传方式',
    dataIndex: 'uploadType',
    ellipsis: { showTitle: true },
    render: (v: UploadTypeEnum) => <EllipsisSpan value={UploadTypeEnumDisplay[v]} />,
  },
];

export const dataColumns: MxColumnsType<any> = [
  {
    title: '实时数据',
    dataIndex: 'edgeData',
    ellipsis: { showTitle: true },
    width: 120,
    fixed: 'right',
    render: (v: string) => <EllipsisSpan value={v ? (isNaN(Number(v)) ? v : (+v).toFixed(4)) : v} />,
  },
  {
    title: '采集时间',
    dataIndex: 'edgeTime',
    ellipsis: { showTitle: true },
    width: 180,
    fixed: 'right',
    render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : undefined} />,
  },
];

const EstunPointDetail: React.FC<EstunPointDetailProps> = ({ type, dataSource, loading, hasDataColumns }) => {
  const { canAdjustField } = useContext(PointContext) as PointContextProps<EdgeDeviceTemplatePoint>;
  return (
    <FieldTable
      sticky
      rowKey="id"
      adjustField={canAdjustField}
      adjustWidth={canAdjustField}
      cacheKey="estunDetailPoint"
      dataSource={dataSource?.filter(item => item.actionType !== ActionType.DELETE)}
      loading={loading}
      columns={[indexColumn, ...columns, ...(hasDataColumns ? dataColumns : [])]}
    />
  );
};

export default EstunPointDetail;
