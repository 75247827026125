import { Cascader, Col, Form, Input, Select } from 'antd';
import React, { Key, useCallback, useEffect, useMemo, useState } from 'react';
import Filter from '@/shared/components/Filter';
import CascadingMultipleSelector from '@/shared/components/CascadingMultipleSelector';
import { formatOptionData, getChildNodesByParentIds } from '@/shared/components/CascadingMultipleSelector/utils';
import { getDeviceTypeData } from '../../../api/attribute';

import { Paging, Table, usePaging, useAsync } from '@maxtropy/components';
// import {EdgeDeviceTemplate, EdgeDeviceTemplateStatus} from "shared/types";
import { useQuery } from '../../../utils/utils';
import {
  EdgeTemplatePageRequest,
  EdgeTemplatePageTemplateRes,
  ManufacturerItem,
  getEdgeTemplatePageTemplate,
  getManufacturerList,
} from '../../../api/edgeTemplate';
import { templateColumns } from '../utils';
import { EdgeDeviceTemplateStatus } from '@/shared/types';
import { PhysicalModelRes, getPhysicalModelList } from '../../../api/device';
import { DefaultOptionType } from 'antd/es/cascader';

interface TemplateModalProps {
  value?: EdgeTemplatePageTemplateRes[];
  onChange?: (value: EdgeTemplatePageTemplateRes[]) => void;
  disabledIds?: Key[];
}

interface FilterEdgeTemplatePageRequest {
  deviceType?: Array<Array<number>> | undefined;
  name?: string;
  manufacturerId?: string;
  physicalModelId?: string;
}

interface SearchProps {
  deviceType?: Array<number> | undefined;
  brandDeviceTypes?: Array<number> | undefined;
  productDeviceTypes?: Array<number> | undefined;
  name?: string;
  manufacturerId?: string;
  physicalModelId?: string;
}

const TemplateModal: React.FC<TemplateModalProps> = ({ value, onChange, disabledIds }) => {
  const [form] = Form.useForm();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchProps>();
  const [templates, setTemplates] = useState<EdgeTemplatePageTemplateRes[]>([]);

  const deviceTypeData = useAsync(getDeviceTypeData);

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        getEdgeTemplatePageTemplate({
          ...searchParams,
          statuses: EdgeDeviceTemplateStatus.USED,
          page: pageOffset,
          size: pageSize,
        }).then(res => {
          if (res) {
            setTotalCount(res.total);
            setTemplates(res.list);
          }
          return res.list;
        }),
      [pageOffset, pageSize, searchParams, setTotalCount]
    )
  );

  const templateIds = useMemo(() => {
    return (value ?? []).map(i => i.id);
  }, [value]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: EdgeTemplatePageTemplateRes[]) => {
      const templatesId = templates.map(i => i.id);
      const buildDataMap = new Map<number, EdgeTemplatePageTemplateRes>();
      (value ?? [])
        .filter(i => !templatesId.includes(i.id))
        .forEach(i => {
          if (!buildDataMap.has(i.id)) {
            buildDataMap.set(i.id, i);
          }
        });
      selectedRows.forEach(i => {
        if (!buildDataMap.has(i.id)) {
          buildDataMap.set(i.id, i);
        }
      });
      onChange && onChange(Array.from(buildDataMap.values()));
    },
    getCheckboxProps: (record: EdgeTemplatePageTemplateRes) => ({
      disabled: (disabledIds ?? []).includes(record.id),
    }),
    selectedRowKeys: templateIds,
  };

  const onReset = () => {
    const params = {
      ...searchParams,
      name: undefined,
      deviceType: undefined,
      manufacturerId: undefined,
      physicalModelId: undefined,
    };
    setSearchParams(params as EdgeTemplatePageRequest);
    setPageOffset(1);
  };

  const onFinish = (val: FilterEdgeTemplatePageRequest) => {
    const deviceType = getChildNodesByParentIds(val.deviceType, deviceTypeData?.tree);
    const params = {
      ...val,
      deviceType: undefined,
      productDeviceTypes: deviceType,
    };
    setSearchParams({ ...params });
    setPageOffset(1);
  };

  const [manufacturerList, setManufacturerList] = useState<ManufacturerItem[]>([]); // 厂商列表

  useEffect(() => {
    getManufacturerList().then(setManufacturerList);
  }, []);

  const manufacturerOptions = useMemo(() => {
    if (manufacturerList && manufacturerList.length > 0) {
      return manufacturerList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [manufacturerList]);

  const deviceType = Form.useWatch('deviceType', form);
  const [physicalModelList, setPhysicalModelList] = useState<PhysicalModelRes[]>([]); // all 物模型型号列表
  const manufacturerId = Form.useWatch('manufacturerId', form);

  useEffect(() => {
    // 物模型型号
    form.setFieldsValue({
      physicalModelId: undefined,
    });
    const deviceTypeIds = getChildNodesByParentIds(deviceType, deviceTypeData?.tree);
    const params = {
      deviceTypeIds: deviceTypeIds,
      manufacturerId,
    };
    getPhysicalModelList(params).then(setPhysicalModelList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceType, manufacturerId]);

  const objectModalTypeOptions = useMemo(() => {
    if (physicalModelList && physicalModelList.length > 0) {
      return physicalModelList.map(i => ({ label: i.modelNo, value: i.id }));
    }
  }, [physicalModelList]);

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

  const filters = (
    <Filter form={form} onFinish={val => onFinish(val as FilterEdgeTemplatePageRequest)} onReset={onReset}>
      <>
        <Col span={8}>
          <Form.Item name="deviceType" label="所属类目">
            <Cascader
              options={formatOptionData(deviceTypeData)}
              allowClear={false}
              fieldNames={{ children: 'child' }}
              multiple
              maxTagCount="responsive"
              showSearch={{ filter }}
              placeholder={'请选择所属类目'}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="编号/名称" name="name">
            <Input placeholder="请输入编号或名称查询" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="manufacturerId" label="厂商">
            <Select
              optionFilterProp="label"
              placeholder="请选择"
              showSearch
              options={manufacturerOptions}
              onChange={() => {
                form.setFieldsValue({
                  physicalModelId: undefined,
                });
              }}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="physicalModelId" label="物模型型号">
            <Select optionFilterProp="label" placeholder="请选择" showSearch options={objectModalTypeOptions} />
          </Form.Item>
        </Col>
      </>
    </Filter>
  );

  return (
    <>
      {filters}
      <Table
        loading={isLoading}
        sticky
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        rowKey="id"
        scroll={{ y: 300 }}
        columns={templateColumns}
        dataSource={data}
      />
      <Paging pagingInfo={pagingInfo} />
    </>
  );
};

export default TemplateModal;
