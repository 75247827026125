import { fetch } from '@maxtropy/components';
import { OpenAPI } from '../pages/InterfaceDoc/utils/swagger-parse';

export interface UpstreamServiceListItem {
  upstreamServiceIdentifier: string;
  upstreamServiceName: string;
}

export function queryUpstreamServiceList(): Promise<UpstreamServiceListItem[]> {
  return fetch(`/api/upstreamService/list`);
}

export interface QueryUpstreamServiceInfoRequest {
  upstreamServiceIdentifier: string;
}

export function queryUpstreamServiceInfo({
  upstreamServiceIdentifier,
}: QueryUpstreamServiceInfoRequest): Promise<OpenAPI> {
  return fetch(`/api/upstreamService/${upstreamServiceIdentifier}/openApi`);
}
