import { EllipsisSpan } from '@maxtropy/components';

export const columns = [
  {
    title: '用户编号',
    dataIndex: 'humanCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '姓名',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '手机号',
    dataIndex: 'phone',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '员工工号',
    dataIndex: 'staffCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所在组织',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];
