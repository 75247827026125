import { MenuEntry } from '@maxtropy/components';
import { PermissionsType } from './permissionsConst';
import { RuleType } from '../api/constants';

export const menuData: MenuEntry[] = [
  {
    name: '系统设置',
    url: '/platform',
    key: PermissionsType.PSYSTEM,
    children: [
      {
        name: '平台组织权限和账户',
        url: '/platform/account',
        key: PermissionsType.PACCOUNT,
        children: [
          {
            name: '员工管理',
            key: PermissionsType.PCORPUSER,
            url: '/platform/account/employee',
          },
        ],
      },
      {
        name: '导航设置',
        url: '/platform/navigationSettings',
        key: PermissionsType.P_NAVIGATIONSETTING,
        children: [
          {
            name: '导航方案',
            key: PermissionsType.P_NAVIGATIONPROGRAM,
            url: '/platform/navigationSettings/scheme',
          },
        ],
      },
      {
        name: '小程序',
        url: '/platform/miniProgram',
        key: PermissionsType.P_MINIAPP,
        children: [
          {
            name: '小程序管理',
            key: PermissionsType.P_MINIAPPMANAGE,
            url: '/platform/miniProgram/manage',
          },
          {
            name: '工具管理',
            key: PermissionsType.P_MINIAPPTOOLMANAGE,
            url: '/platform/miniProgram/toolManage',
          },
        ],
      },
      {
        name: '熵云平台资料',
        url: '/platform/maxtropyInfoPlatform',
        key: PermissionsType.P_MAXTROPY_MATERIAL,
        children: [
          {
            name: '熵云能力清单',
            url: '/platform/maxtropyInfoPlatform/abilityList',
            key: PermissionsType.P_MAXTROPY_CAPABILITY_LIST,
          },
        ],
      },
    ],
  },
  {
    name: '租户管理',
    url: '/tenant',
    key: PermissionsType.PTENANT,
    children: [
      {
        name: '租户组织权限和账户',
        url: '/tenant/customer',
        key: PermissionsType.PTENANTPERMISSION,
        children: [
          {
            name: '租户管理',
            key: PermissionsType.PTENANTMANAGE,
            url: '/tenant/customer/manage',
          },
          {
            name: '轻流开户',
            key: PermissionsType.P_QING_FLOW_ACCOUNT_OPEN,
            url: '/tenant/customer/oalite',
          },
          {
            name: '预置角色管理',
            key: PermissionsType.PROLEMANAGE,
            url: '/tenant/customer/prefab-role',
          },
          {
            name: '租户用户管理',
            key: PermissionsType.PCUSTOMERUSERMANAGE,
            url: '/tenant/customer/user',
          },
          {
            name: '租户员工工号管理',
            key: PermissionsType.PSTAFFMANAGE,
            url: '/tenant/customer/employee',
          },
        ],
      },
    ],
  },
  {
    name: '个人中心',
    url: '/user',
    key: 'p-personal',
    always: true,
    children: [
      {
        name: '个人设置',
        url: '/user/settings',
        key: 'personalSettings',
        always: true,
        children: [
          {
            key: 'passManage',
            name: '登录密码管理',
            url: '/user/settings/loginPassword',
            always: true,
          },
          {
            key: 'opPassManage',
            name: '操作密码管理',
            url: '/user/settings/operatePassword',
            always: true,
          },
        ],
      },
    ],
  },
  {
    name: 'Iot配置',
    url: '/device/info/template',
    key: PermissionsType.PBASICSETTING,
    children: [
      {
        name: '设备管理',
        url: '/device/manage',
        key: PermissionsType.PDEVICEMANAGE,
        children: [
          {
            name: '设备管理',
            url: '/device/manage/device',
            key: PermissionsType.PDEVICEMANAGEMENT,
          },
          {
            name: '批量导入设备',
            url: '/device/manage/batch',
            key: PermissionsType.PBATCHIMPORTDEVICE,
          },
          {
            name: '物模型型号管理',
            url: '/device/manage/physicalmodel',
            key: PermissionsType.P_PHYSICAL_MODEL_MANAGE,
          },
        ],
      },
      {
        name: '设备信息模板库',
        url: '/device/info',
        key: PermissionsType.PDEVICETEMPLATE,
        children: [
          {
            name: '固定属性模板',
            url: '/device/info/template',
            key: PermissionsType.PATTRIBUTETEMPLATE,
          },
          {
            name: '属性管理',
            url: '/device/info/attribute',
            key: PermissionsType.PATTRIBUTEMANAGE,
          },
          {
            name: '模板分配',
            url: '/device/info/distribution',
            key: PermissionsType.P_ATTRIBUTETEMPLATEASSIGN,
          },
        ],
      },
      {
        name: '数采配置',
        url: '/device/edge',
        key: PermissionsType.PEDGESETTING,
        children: [
          {
            name: '数采模板',
            url: '/device/edge/template',
            key: PermissionsType.PEDGETEMPLATE,
          },
          {
            name: '模板分配',
            url: '/device/edge/distribution',
            key: PermissionsType.P_EDGETEMPLATEASSIGN,
          },
        ],
      },
      {
        name: '物联配置',
        url: '/device/config',
        key: PermissionsType.PIOTSETTING,
        children: [
          {
            name: '网关管理',
            url: '/device/config/gateway',
            key: PermissionsType.PEDGEGATEWAYMANAGE,
          },
        ],
      },
      {
        name: '设备类型库',
        url: '/device/type',
        key: PermissionsType.PDEVICETYPE,
        children: [
          {
            name: '类目树',
            url: '/device/type/category',
            key: PermissionsType.PDEVICETYPETREE,
          },
        ],
      },
      {
        name: '生产运营单元',
        url: '/device/ou',
        key: PermissionsType.P_OU,
        children: [
          {
            name: '单元管理',
            url: '/device/ou/production-unit',
            key: PermissionsType.P_OU_MANAGE,
          },
        ],
      },
      {
        name: '报警规则',
        url: '/device/rule',
        key: PermissionsType.P_ALARM_RULE,
        children: [
          {
            name: '设备报警规则',
            url: '/device/rule/list',
            key: PermissionsType.P_RULE_MANAGE,
          },
        ],
      },
      {
        name: '报警推送',
        url: '/device/alarm',
        key: PermissionsType.P_ALARM_PUSH,
        children: [
          {
            name: '设备推送管理',
            url: '/device/alarm/push',
            key: PermissionsType.P_ALARM_PUSH_STRATEGY_MANAGE,
          },
          {
            name: '设备报警记录',
            url: '/device/alarm/record',
            key: PermissionsType.P_ALARM_LOG,
          },
        ],
      },
    ],
  },
  {
    name: '数据中心',
    url: '/data',
    key: PermissionsType.P_DATACENTER,
    children: [
      {
        name: '历史数据',
        url: '/data/history',
        key: PermissionsType.P_HISTROYDATA,
        children: [
          {
            name: '设备历史数据',
            url: '/data/history/device',
            key: PermissionsType.PDEVICEHISTORY,
          },
        ],
      },
      {
        name: '信息管理',
        url: '/data/info',
        key: PermissionsType.P_INFORMATIONMANAGE,
        children: [
          {
            name: '报告管理',
            url: '/data/info/report',
            key: PermissionsType.P_ANALYSISREPORTMANAGE,
          },
        ],
      },
    ],
  },
  {
    name: '工具中心',
    url: '/tools',
    key: PermissionsType.P_TOOLSCENTER,
    children: [
      {
        name: 'AI报告',
        url: '/tools/AIreport',
        key: PermissionsType.P_AIREPORT,
        children: [
          {
            name: '报告管理',
            url: '/tools/AIreport/manage',
            key: PermissionsType.P_AIREPORTMANAGE,
          },
        ],
      },
      {
        name: '光伏测算工具',
        url: '/tools/photovoltaic',
        key: PermissionsType.P_PVESTIMATETOOLS,
        children: [
          {
            name: '光伏发电量测算',
            url: '/tools/photovoltaic/power',
            key: PermissionsType.P_PVGENERATINGCAPACITY,
          },
          {
            name: '光伏收益估算',
            url: '/tools/photovoltaic/profit',
            key: PermissionsType.P_PVINCOME,
          },
        ],
      },
    ],
  },
  {
    name: '双碳管理',
    url: '/dualCarbon',
    key: PermissionsType.P_DUAL_CARBON_MANAGEMENT,
    children: [
      {
        name: '基本信息',
        url: '/dualCarbon/basicInfo',
        key: PermissionsType.P_BASIC_INFORMATION,
        children: [
          {
            name: '排放因子库',
            url: '/dualCarbon/basicInfo/emissionFactor',
            key: PermissionsType.P_EMISSION_FACTOR_POOL,
          },
          {
            name: '行业管理',
            key: PermissionsType.P_TRADE_MANAGEMENT,
            url: '/dualCarbon/basicInfo/tradeManagement',
          },
        ],
      },
      {
        name: '企业碳排查',
        url: '/dualCarbon/carbonEmissions',
        key: PermissionsType.P_CARBON_INVENTORY,
        children: [
          {
            name: '核算指南管理',
            url: '/dualCarbon/carbonEmissions/accountingGuidelines',
            key: PermissionsType.P_ACCOUNTING_GUIDELINES_MANAGEMENT,
          },
          {
            name: '排放过程管理',
            url: '/dualCarbon/carbonEmissions/process',
            key: PermissionsType.P_EMISSION_PROCESS_MANAGEMENT,
          },
        ],
      },
    ],
  },
  {
    name: '数据运营工具',
    url: '/data-tool',
    key: PermissionsType.P_DATA_OPERATION_TOOLS,
    children: [
      {
        name: '数据模拟',
        url: '/data-tools/shaping',
        key: PermissionsType.P_DATA_SIMULATION,
        children: [
          {
            name: '使用说明',
            url: '/data-tools/shaping/description',
            key: PermissionsType.P_RULE_CONFIGURATION,
          },
          {
            name: '规则集配置',
            url: '/data-tools/shaping/rule-set',
            key: PermissionsType.P_RULE_CONFIGURATION,
          },
          {
            name: '上传时间规则配置',
            url: `/data-tools/shaping/${RuleType.UPLOAD_TIME}`,
            key: PermissionsType.P_UPLOAD_RULE_CONFIG,
          },
          {
            name: '数据时间规则配置',
            url: `/data-tools/shaping/${RuleType.DATA_TIME}`,
            key: PermissionsType.P_DATA_TIME_RULE_CONFIG,
          },
          {
            name: '数据值规则配置',
            url: `/data-tools/shaping/${RuleType.DATA_VALUE}`,
            key: PermissionsType.P_DATA_VALUE_RULE_CONFIG,
          },
          {
            name: '数据值模板管理',
            url: `/data-tools/shaping/templatemanage`,
            key: PermissionsType.P_DATA_TEMPLATE_MANAGE,
          },
          {
            name: '规则集管理',
            url: `/data-tools/shaping/rulesmanage`,
            key: PermissionsType.P_DATA_RULE_MANAGE,
          },
          {
            name: '模拟数据删除',
            url: `/data-tools/shaping/mockDataDeletion`,
            key: PermissionsType.P_MOCK_DATA_DELETION,
          },
        ],
      },
      {
        name: '业务数据模拟',
        url: '/data-tools/business',
        key: PermissionsType.P_BUSINESSDATASIMULATION,
        children: [
          {
            name: '表单模板管理',
            url: '/data-tools/business/formTemplateManagement',
            key: PermissionsType.P_MOCKFORMTEMPLATEMANAGE,
          },
          {
            name: '表单管理',
            url: '/data-tools/business/formmanage',
            key: PermissionsType.P_MOCKFORMMANAGE,
          },
          {
            name: '数据生成管理',
            url: '/data-tools/business/dataGeneratorManagement',
            key: PermissionsType.P_MOCKDATAGENERATORMANAGE,
          },
          {
            name: '定时任务数据重跑',
            url: '/data-tools/business/datarun',
            key: PermissionsType.P_RUN_TASK,
          },
        ],
      },
    ],
  },
  {
    name: '开发者平台',
    url: '/open-platform',
    key: PermissionsType.P_OPENPLATFORM,
    children: [
      {
        name: '应用开发开放平台',
        url: '/open-platform/open-platform',
        key: PermissionsType.P_APPOPENPLATFORM,
        children: [
          {
            name: '集成应用列表',
            url: '/open-platform/open-platform/app',
            key: PermissionsType.P_APPLIST,
          },
          // {
          //   name: '开发者权限管理',
          //   url: '/open-platform/open-platform/development-team',
          //   key: PermissionsType.P_DEVELOPERPERMISSIONMANAGE,
          // },
          {
            name: '接口文档',
            url: '/open-platform/open-platform/interface-document',
            key: PermissionsType.P_APIDOCUMENTATION,
          },
          {
            name: '数据属性管理',
            url: '/open-platform/open-platform/dataProperty-management',
            key: PermissionsType.P_DATAPROPERTYMANAGE,
          },
          // wait 服务商导航权限
          {
            name: '服务商管理',
            url: '/open-platform/open-platform/service-management',
            key: '',
            always: true,
          },
          {
            name: '开发者账号管理',
            url: '/open-platform/open-platform/developer-account',
            key: '',
            always: true,
          },
        ],
      },
    ],
  },
];
