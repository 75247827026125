import React, { ReactNode } from 'react';
import logoUrl from '../../assets/image/logo_yellow.png';
import { getRealUrl } from '@maxtropy/components';

export interface LogoProps {
  url?: string;
  title?: ReactNode;
  prefixCls?: string;
  emptyLogo?: boolean;
}

const Logo: React.FC<LogoProps> = props => {
  const { title } = props;

  const configLogoUrl = window?.MGMTLOGO ? getRealUrl(window.MGMTLOGO) ?? logoUrl : logoUrl;

  return (
    <div className={`central-menu-logo-wrapper`}>
      <div
        style={{
          width: 140,
          height: 40,
          backgroundSize: 'contain',
          backgroundPosition: 'left center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${configLogoUrl})`,
        }}
      />
      <span className={`central-menu-logo-title`}>{title}</span>
    </div>
  );
};

export default Logo;
