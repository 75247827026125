/* eslint no-console: ["error", { allow: ["error"] }] */
import { PlusOutlined } from '@ant-design/icons';
import { Button, EllipsisSpan, Wrapper, Table, Modal } from '@maxtropy/components';
import { Form, Space } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  createNavgationDetail,
  deleteNavgationDetail,
  TenantMenuLevel,
  TenantMenuNavigationResponse,
  updateNavgationDetail,
} from '../../../api/cc-navigation';
import { PermissionsType } from '../../../common/permissionsConst';
import { useHasPermission } from '../../../utils/hooks';
import EditFormModal from './components/EditFormModal';
import styles from './index.module.scss';
import { isEmpty } from 'lodash-es';
import { apiV2NavigationTreePost, apiV2NavigationUpdateParentIdPost } from '@maxtropy/tody-mgmt-apis-v2';
import { isNil } from 'lodash-es';
import SortNavigation from './components/SortNavigation';
import MoveIntoExists from './components/MoveIntoExists';
export const formatTreeData = (list: any[]): any[] => {
  return list.map(item => {
    const { trees, ...rest } = item;
    return {
      ...rest,
      trees: isEmpty(trees) ? undefined : formatTreeData(trees!),
    };
  });
};

export type TenantMenuNavigationResponseExpand = TenantMenuNavigationResponse & {
  selfName?: string;
  parentName?: string;
  rootName?: string;
  parentWeighting?: number;
  rootWeighting?: number;
  selfWeighting?: number;
  currentId?: number;
  firstLevel?: number;
  secondLevel?: number;
  thirdLevel?: number;
};

const columns = [
  {
    title: '导航',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];

const routes = [{ name: '系统设置' }, { name: '导航设置' }, { name: '导航方案' }, { name: '编辑导航方案' }];

const EditEachRecord: FC = () => {
  const hasNavDetailAddEditDel = useHasPermission(PermissionsType.B_NAVIGATIONPROGRAMDETAILUPSERT); // 编辑/删除/新增导航
  const [loading, setLoading] = useState<boolean>(false); // 加载状态
  const [navdata, setNavData] = useState<any>([]); // 后端返回数据

  const navigate = useNavigate();
  const [current, setCurrent] = useState<TenantMenuNavigationResponse>();
  const [editModalOpened, setEditModalOpened] = useState<boolean>(false); // 编辑导航弹窗
  const [form] = Form.useForm();
  const [level, setLevel] = useState<number>(); // 导航等级
  const [isAdd, setIsAdd] = useState<boolean>(false); // 是否是新增
  const { editId } = useParams<{ editId: string }>();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  let name = urlSearchParams.get('name') || undefined;
  const [rootLevelId, setRootLevelId] = useState<number>();
  const [parentLevelId, setParentLevelId] = useState<number>();
  const [opened, setOpened] = useState<boolean>(false);
  const [isChild, setIsChild] = useState<boolean>(false);
  const [isMoveModal, setIsMoveModal] = useState<boolean>(false);

  useEffect(() => {
    if (editId) {
      refreshData(editId);
    }
  }, [editId]);

  const rowClassName = (record: any) => {
    if (record.level === 1) {
      return 'row1';
    } else if (record.level === 2) {
      return 'row2';
    }
    return '';
  };

  const refreshData = (editId: string) => {
    setLoading(true);
    apiV2NavigationTreePost({ id: editId }).then(res => {
      setNavData(
        formatTreeData(
          (res.list ?? []).sort(function (a, b) {
            return (b.weighting as number) - (a.weighting as number);
          })
        )
      );
      setLoading(false);
    });
  };

  const onDeleteRecord = (id: number) => {
    Modal.confirm({
      content: `确认删除该导航以及下级导航？`,
      okText: '删除',
      onOk() {
        deleteNavgationDetail(id)
          .then(res => {
            refreshData(editId as string);
          })
          .catch(err => {});
      },
      onCancel() {
        console.error('Cancel');
      },
    });
  };

  const buildColumns = [
    ...columns,
    {
      title: '操作',
      render: (record: TenantMenuNavigationResponse) => {
        if (Number(editId) === 1) {
          return (
            <Button
              style={{ paddingLeft: 0, paddingRight: 0 }}
              type="link"
              onClick={() => {
                setEditModalOpened(true);
                setCurrent(record);
                setLevel(record.level);
                setParentLevelId(record.parentId);
                setRootLevelId(record.rootId);
              }}
            >
              查看
            </Button>
          );
        } else {
          return (
            <Space size={8}>
              {hasNavDetailAddEditDel && (
                <Button
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                  type="link"
                  onClick={() => {
                    setParentLevelId(record.parentId);
                    setRootLevelId(record.rootId);
                    setEditModalOpened(true);
                    setCurrent(record);
                    setLevel(record.level);
                  }}
                >
                  编辑
                </Button>
              )}
              {hasNavDetailAddEditDel && isNil(record.trees) && (
                <Button
                  style={{ paddingLeft: 0, paddingRight: 0 }}
                  type="link"
                  onClick={() => {
                    onDeleteRecord(record.id);
                  }}
                >
                  删除
                </Button>
              )}

              {record.level === TenantMenuLevel.ONE && (
                <>
                  <Button
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    type="link"
                    onClick={() => {
                      setOpened(true);
                    }}
                  >
                    排序
                  </Button>
                  {hasNavDetailAddEditDel && (
                    <Button
                      style={{ paddingLeft: 0, paddingRight: 0 }}
                      type="link"
                      onClick={() => {
                        setParentLevelId(record.parentId);
                        setRootLevelId(record.rootId);
                        setEditModalOpened(true);
                        setIsAdd(true);
                        setLevel(record.level + 1);
                        setCurrent(record);
                      }}
                    >
                      新增子导航
                    </Button>
                  )}
                  <Button
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    type="link"
                    onClick={() => {
                      setCurrent(record);

                      setIsMoveModal(true);
                    }}
                  >
                    移入已有导航
                  </Button>
                </>
              )}
              {record.level === TenantMenuLevel.ONE && !isNil(record.trees) && (
                <>
                  <Button
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    type="link"
                    onClick={() => {
                      setOpened(true);
                      setCurrent(record);

                      setIsChild(true);
                    }}
                  >
                    子导航排序
                  </Button>
                </>
              )}
              {record.level === TenantMenuLevel.TWO && (
                <>
                  {hasNavDetailAddEditDel && (
                    <Button
                      style={{ paddingLeft: 0, paddingRight: 0 }}
                      type="link"
                      onClick={() => {
                        setParentLevelId(record.currentId);
                        setRootLevelId(record.rootId);
                        setEditModalOpened(true);
                        setIsAdd(true);
                        setLevel(record.level + 1);
                        setCurrent(record);
                      }}
                    >
                      新增子导航
                    </Button>
                  )}
                  <Button
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    type="link"
                    onClick={() => {
                      setIsMoveModal(true);
                      setCurrent(record);
                    }}
                  >
                    移入已有导航
                  </Button>
                </>
              )}
              {record.level === TenantMenuLevel.TWO && !isNil(record.trees) && (
                <>
                  <Button
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    type="link"
                    onClick={() => {
                      setOpened(true);
                      setIsChild(true);
                      setCurrent(record);
                    }}
                  >
                    子导航排序
                  </Button>
                </>
              )}
            </Space>
          );
        }
      },
    },
  ];

  const onCancelAction = () => {
    setCurrent(undefined);
    form.resetFields();
    setEditModalOpened(false);
    setLevel(undefined);
    setIsAdd(false);
  };

  const onEditFormFinish = (values: TenantMenuNavigationResponse) => {
    const currentChildren = (current?.trees ?? []).map(item => ({
      id: item.id,
      rootId: values.rootId,
      parentId: item.parentId,
    }));

    if (current && !isAdd) {
      if (current.level === 2) {
        if (currentChildren.length === 0) return;
        apiV2NavigationUpdateParentIdPost({ requestList: currentChildren });
      }
      // 如果有current无isAdd，那就是编辑
      // console.log('编辑');
      updateNavgationDetail({
        ...values,
        path: values.path?.trim(),
        permissionCode: values.permissionCode
          ? values.permissionCode[2]
          : values.integratedAppPermissionCode
          ? values.integratedAppPermissionCode[2]
          : undefined,
        id: current.id,
        parentId: !isNil(values.parentId) ? values.parentId : values.rootId,
        rootId: values.rootId,
        navigationProgramId: Number(editId),
      }).then(res => {
        refreshData(editId as string);
        onCancelAction();
      });
    } else if (current && isAdd) {
      // 如果有current有isAdd，那就是新建下一级
      createNavgationDetail({
        ...values,
        path: values.path?.trim(),
        permissionCode: values.permissionCode
          ? values.permissionCode[2]
          : values.integratedAppPermissionCode
          ? values.integratedAppPermissionCode[2]
          : undefined,
        parentId: !isNil(values.parentId) ? values.parentId : values.rootId,
        rootId: values.rootId,
        navigationProgramId: Number(editId),
      }).then(res => {
        refreshData(editId as string);

        onCancelAction();
      });
    } else {
      // 否则就是新建一级导航
      createNavgationDetail({
        ...values,
        parentId: 0,
        navigationProgramId: Number(editId),
      }).then(res => {
        refreshData(editId as string);
        onCancelAction();
      });
    }
  };

  return (
    <>
      <Wrapper routes={routes} className={styles.content}>
        <div style={{ margin: '0px 20px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px 0 20px 0' }}>
            <div style={{ fontWeight: 600, fontSize: '24px', color: 'rgba(0, 0, 0, 0.85)' }}>
              导航方案 | <span style={{ fontSize: 16 }}> {name} </span>
            </div>
            {hasNavDetailAddEditDel && Number(editId) !== 1 && (
              <div style={{}}>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setEditModalOpened(true);
                    setLevel(1);
                    setIsAdd(true);
                  }}
                >
                  新建一级导航
                </Button>
              </div>
            )}
          </div>
          {navdata.length > 0 && (
            <Table
              rowKey={record => record.id}
              scroll={{ x: 1300 }}
              expandable={{
                childrenColumnName: 'trees',
              }}
              defaultExpandAllRows={true}
              loading={loading}
              rowClassName={rowClassName}
              indentSize={32}
              columns={buildColumns}
              dataSource={navdata}
              pagination={false}
            />
          )}
          <Space className={styles['sticky-footer']} style={{ marginTop: '30px', marginBottom: '30px' }}>
            <Button onClick={() => navigate('/platform/navigationSettings/scheme')}>取消</Button>
          </Space>
        </div>
        {/* 编辑 */}
        <Modal
          title={`导航配置`}
          width={600}
          open={editModalOpened}
          onCancel={onCancelAction}
          destroyOnClose
          onOk={() => form.submit()}
          okText={'保存'}
          okButtonProps={{ disabled: Number(editId) === 1 ? true : false }}
          bodyStyle={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <EditFormModal
            editId={editId}
            isAdd={isAdd}
            rootLevelId={rootLevelId}
            parentLevelId={parentLevelId}
            form={form}
            current={current}
            onFinish={onEditFormFinish}
            level={level}
          />
        </Modal>
        <SortNavigation
          setOpened={setOpened}
          opened={opened}
          editId={editId}
          refreshData={refreshData}
          isChild={isChild}
          setIsChild={setIsChild}
          current={current}
        ></SortNavigation>

        <MoveIntoExists
          navdata={navdata}
          setIsMoveModal={setIsMoveModal}
          isMoveModal={isMoveModal}
          refreshData={refreshData}
          editId={editId}
          current={current}
        ></MoveIntoExists>
      </Wrapper>
    </>
  );
};

export default EditEachRecord;
