import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Form,
  Input,
  Select,
  DatePicker,
  Row,
  Col,
  TreeSelect,
  Cascader,
  Space,
  Button,
  Modal,
  AutoComplete,
  Radio,
} from 'antd';
import { Wrapper, AttachmentUpload, FormContent, useAsync } from '@maxtropy/components';

import styles from './index.module.scss';

import {
  getRoot,
  getOrganization,
  OrganizationResponse,
  PhysicalModelRes,
  getPhysicalModelList,
} from '../../../api/device';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import { useQuery } from '../../../utils/utils';
import { getDeviceTypeTree } from '../../../api/template';
import {
  getDeviceProperty,
  createDeviceProperty,
  updateDeviceProperty,
  DevicePropertyResponse,
} from '../../../api/device';
import dayjs, { Dayjs } from 'dayjs';
import { getModel, ModelResponse } from '../../../api/model';
import ShowInput from '@/shared/components/ShowInput';
import MultiModalSelect from '@/shared/components/MultiModalSelect';
import { checkOuIsBound } from '../../../api/ouDevice';
import { getDefaultKey } from '@/shared/api/fileCenter';
import DeviceTags, { deviceTagsValidator } from '@/shared/components/DeviceTags';
import { formatDeviceTypeToDataNode } from '@/shared/utils/utils';
import { isNil } from 'lodash-es';

const formatTreeData = (data: OrganizationResponse[]) => {
  return data.map(item => {
    const res: DefaultOptionType = {
      key: item.data?.mcid ?? '',
      value: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children),
    };
    return res;
  });
};

const formLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 18 },
};

type RecordMap = Map<'old' | 'new', Array<number>>;

const CreateDevicePropertyInfo: FC<{ isEdit?: boolean; isNext?: boolean }> = ({ isEdit = false, isNext = false }) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const tenant = useAsync(getRoot, []);
  const [tenantMcid, setTenantMcid] = useState<string>();
  const [organization, setOrganition] = useState<OrganizationResponse>();
  const [form] = Form.useForm();
  const [selectedMcid, setSelectedMcid] = useState<string>();
  const [ouRecords, setOuRecords] = useState<RecordMap>();
  const [deviceProperty, setDeviceProperty] = useState<DevicePropertyResponse>();

  const defaultImgKey = useAsync(getDefaultKey, {});

  const [physicalModelList, setPhysicalModelList] = useState<PhysicalModelRes[]>([]); // all 物模型型号列表
  const [rootMcid, setRootMcid] = useState<string>();

  const routes = useMemo(() => {
    return [
      { name: 'Iot配置' },
      { name: '设备管理' },
      { name: '设备管理' },
      { name: `${isEdit ? '编辑' : '新建'}设备` },
    ];
  }, [isEdit]);

  useEffect(() => {
    if (id) {
      getDeviceProperty(id).then(res => {
        setDeviceProperty(res);
        const record: RecordMap = new Map();
        record.set('old', res.ouIds || []);
        record.set('new', []);
        setOuRecords(record);
        getOrganization(res.rootMcid).then(res => {
          setOrganition(res);
        });
      });
    }
  }, [id]);

  const { data: deviceTypes } = useQuery(useCallback(() => getDeviceTypeTree(), []));
  const [deviceType, setDeviceType] = useState<number>();
  const [modalApi, modalContextHolder] = Modal.useModal();

  useEffect(() => {
    if (deviceProperty) {
      form.setFieldsValue({
        name: deviceProperty.name,
        sn: deviceProperty.sn,
        typeId: deviceProperty.typeName,
        modelName: deviceProperty.modelName,
        manufactureDate: deviceProperty.manufactureDate ? dayjs(deviceProperty.manufactureDate) : undefined,
        installationDate: deviceProperty.installationDate ? dayjs(deviceProperty.installationDate) : undefined,
        pic: deviceProperty.pic,
        rootMcid: deviceProperty.tenantName,
        customerMcid: deviceProperty.customerMcid,
        assetCode: deviceProperty.assetCode,
        ouIds: deviceProperty.ouIds,
        tags: deviceProperty.tags ?? [],
        objectModalType: deviceProperty.physicalModelId
          ? `${deviceProperty.physicalModelNo ?? deviceProperty.modelNo}-${deviceProperty.manufacturerName}`
          : deviceProperty.physicalModelId,
        thirdParty: deviceProperty.thirdParty,
      });
      setRootMcid(deviceProperty.rootMcid);
      setDeviceType(deviceProperty.typeId);
      setSelectedMcid(deviceProperty.customerMcid);
    }
  }, [deviceProperty, form]);

  useEffect(() => {
    if (deviceType) {
      // 物模型型号
      getPhysicalModelList({ deviceTypeIds: [deviceType] }).then(setPhysicalModelList);
    }
  }, [deviceType]);

  const objectModalTypeOptions = useMemo(() => {
    if (physicalModelList && physicalModelList.length > 0) {
      return physicalModelList.map(i => ({ label: `${i.modelNo}-${i.manufacturerName}`, value: i.id }));
    }
  }, [physicalModelList]);

  useEffect(() => {
    // console.log(tenantMcid)
    if (tenantMcid) {
      form.setFieldsValue({
        customerMcid: undefined,
      });
      getOrganization(tenantMcid).then(res => {
        setOrganition(res);
      });
    }
  }, [tenantMcid, form]);

  const treeData = useMemo(() => {
    if (organization) {
      return formatTreeData([organization]);
    } else {
      return undefined;
    }
  }, [organization]);

  const onFinish = async (type: boolean) => {
    try {
      const v = await form.validateFields();
      let promise: () => Promise<DevicePropertyResponse>;
      if (isEdit) {
        // 编辑
        promise = () =>
          updateDeviceProperty(
            {
              id: Number(id),
              name: v.name,
              sn: v.sn,
              manufactureDate: v.manufactureDate ? dayjs(v.manufactureDate).format('YYYY-MM-DD') : undefined,
              installationDate: v.installationDate ? dayjs(v.installationDate).format('YYYY-MM-DD') : undefined,
              pic: v.pic ?? defaultImgKey['defaultDeviceKey'],
              addOuIds: v.ouIds?.filter((id: number) => !deviceProperty?.ouIds?.includes(id)),
              deleteOuIds: deviceProperty?.ouIds?.filter((id: number) => !v.ouIds?.includes(id)),
              customerMcid: v.customerMcid,
              assetCode: v.assetCode,
              tags: v.tags,
              physicalModelId: deviceProperty?.physicalModelId ? deviceProperty?.physicalModelId : v.objectModalType,
              thirdParty: v.thirdParty,
            },
            rootMcid
          );
      } else {
        // 添加
        promise = () =>
          createDeviceProperty({
            name: v.name,
            sn: v.sn,
            typeId: deviceType,
            modelName: v.modelName,
            manufactureDate: v.manufactureDate ? dayjs(v.manufactureDate).format('YYYY-MM-DD') : undefined,
            installationDate: v.installationDate ? dayjs(v.installationDate).format('YYYY-MM-DD') : undefined,
            pic: v.pic ?? defaultImgKey['defaultDeviceKey'],
            addOuIds: v.ouIds,
            rootMcid: v.rootMcid,
            customerMcid: v.customerMcid,
            assetCode: v.assetCode,
            tags: v.tags,
            physicalModelId: v.objectModalType,
            thirdParty: v.thirdParty,
          });
      }
      const submit = () =>
        promise().then(res => {
          if (type) {
            navigate(`/device/manage/device/${res.id}/attribute/create`);
          } else {
            navigate(`/device/manage/device`);
          }
        });
      let needWarn = v.ouIds?.length
        ? await checkOuIsBound({
            addOuIds: ouRecords?.get('new') || v.ouIds,
            oldOuIds: ouRecords?.get('old'),
          })
        : false;
      if (needWarn) {
        modalApi.confirm({
          title: '设备存在多个生产运营单元',
          content: (
            <>
              <span style={{ color: 'red' }}>可能会有数据重复</span>，你还要继续吗？
            </>
          ),
          okText: '继续',
          onOk: submit,
        });
      } else {
        await submit();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const [model, setModel] = useState<ModelResponse[]>([]);

  useEffect(() => {
    if (deviceType !== undefined) {
      getModel(deviceType).then(setModel);
    }
  }, [deviceType]);

  const [searchValue, setSearchValue] = useState<string>();

  const options = useMemo(() => {
    return model.filter(i => i.name.includes(searchValue ?? '')).map(i => ({ label: i.name, value: i.name }));
  }, [model, searchValue]);

  const onSearch = (value: string) => {
    setSearchValue(value);
  };

  const onBlur = () => {
    setSearchValue(undefined);
  };

  const onCacel = () => {
    modalApi.confirm({
      title: null,
      icon: null,
      content: <div>是否放弃所有未保存信息并返回列表？</div>,
      onOk: () => {
        navigate(`/device/manage/device`);
      },
    });
  };

  const onRecord = (v: Array<number> | undefined) => {
    if (isEdit) {
      const defaultOldOuIds: Array<number> = ouRecords!.get('old')!;
      const newOuIds = v?.filter((id: number) => !defaultOldOuIds?.includes(id)) || [];
      const oldOuIds = defaultOldOuIds.filter((id: number) => v?.includes(id)) || [];
      const record: RecordMap = new Map();
      record.set('old', oldOuIds);
      record.set('new', newOuIds);
      setOuRecords(record);
    }
  };
  return (
    <Wrapper routes={routes} className={styles.wrapper}>
      <Form
        form={form}
        initialValues={{ ou: [52, 51, 45, 14, 43, 44, 41, 40, 38, 15, 7] }}
        layout="vertical"
        {...formLayout}
      >
        <FormContent>
          <Row>
            <Col span={8} className={styles.col}>
              <Form.Item
                name="name"
                label="设备名称"
                rules={[
                  { required: true, message: '请输入设备名称' },
                  { max: 20, message: '不超过20位' },
                ]}
              >
                <Input placeholder="请输入设备名称，不超过二十个字" />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item
                name="sn"
                label="设备S/N码"
                rules={[
                  { required: true, message: '请输入设备S/N码' },
                  { pattern: /^([0-9a-zA-Z-]|\/)+$/g, message: '请输入英文、数字、特殊符号包括【/】、【-】' },
                  { max: 50, message: '设备S/N码不超过50个字符' },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item name="typeId" label="所属类目" rules={[{ required: true, message: '请选择所属类目' }]}>
                {isEdit ? (
                  <ShowInput />
                ) : (
                  <Cascader
                    allowClear={false}
                    style={{ width: '100%' }}
                    options={formatDeviceTypeToDataNode(deviceTypes?.tree ?? [])}
                    fieldNames={{ label: 'name', value: 'id' }}
                    onChange={(e: any) => {
                      if (e.length < 3) {
                        form.setFieldsValue({ typeId: undefined });
                      }
                      setDeviceType(e[2] as number | undefined);
                      form.setFieldsValue({
                        modelName: undefined,
                        objectModalType: undefined,
                      });
                    }}
                    placeholder="请选择"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item name="objectModalType" label="物模型型号">
                {isEdit ? (
                  isNil(form.getFieldValue('objectModalType')) ? (
                    <Select
                      allowClear
                      optionFilterProp="label"
                      showSearch
                      options={objectModalTypeOptions}
                      disabled={!form.getFieldValue('typeId')}
                      placeholder={form.getFieldValue('typeId') ? '请选择' : '请先选择所属类目'}
                    />
                  ) : (
                    <ShowInput />
                  )
                ) : (
                  <Select
                    allowClear
                    options={objectModalTypeOptions}
                    optionFilterProp="label"
                    showSearch
                    disabled={!form.getFieldValue('typeId')}
                    placeholder={form.getFieldValue('typeId') ? '请选择' : '请先选择所属类目'}
                  />
                )}
              </Form.Item>
            </Col>

            <Col span={8} className={styles.col}>
              <Form.Item name="modelName" label="所属型号" rules={[{ required: true, message: '请选择所属型号' }]}>
                {isEdit ? (
                  <ShowInput />
                ) : (
                  <AutoComplete
                    disabled={deviceType === undefined}
                    style={{ width: '100%' }}
                    showSearch
                    placeholder="请输入"
                    onSearch={onSearch}
                    onBlur={onBlur}
                    optionFilterProp="label"
                    options={options}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item name="manufactureDate" label="出厂日期">
                <DatePicker
                  style={{ width: '100%' }}
                  disabledDate={(currentDate: Dayjs) => {
                    return currentDate.isAfter(dayjs(), 'date') || currentDate.isBefore(dayjs('1950-01-01'));
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item name="installationDate" label="安装日期">
                <DatePicker
                  style={{ width: '100%' }}
                  disabledDate={(currentDate: Dayjs) => {
                    return currentDate.isAfter(dayjs(), 'date') || currentDate.isBefore(dayjs('1950-01-01'));
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item name="rootMcid" label="所属租户" rules={[{ required: true, message: '请选择所属租户' }]}>
                {isEdit ? (
                  <ShowInput />
                ) : (
                  <Select
                    style={{ width: '100%' }}
                    placeholder="请选择"
                    onChange={v => {
                      setTenantMcid(v as string);
                    }}
                    options={tenant.map(i => ({ label: i.name, value: i.mcid }))}
                    optionFilterProp="label"
                    showSearch
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item name="customerMcid" label="所属组织" rules={[{ required: true, message: '请选择所属组织' }]}>
                <TreeSelect
                  onChange={val => {
                    setSelectedMcid(val as string);
                    form.setFieldsValue({ ouIds: [] });
                  }}
                  treeData={treeData}
                  placeholder="请选择"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item name="assetCode" label="资产编码" rules={[{ max: 500, message: '最多输入500位' }]}>
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item name="pic" label="设备图片">
                <AttachmentUpload fileSize={5} />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item name="ouIds" label="生产运营单元">
                <MultiModalSelect onChange={onRecord} mcid={selectedMcid} />
              </Form.Item>
            </Col>
            <Col span={8} className={styles.col}>
              <Form.Item name="tags" label="设备标签" rules={[{ validator: deviceTagsValidator }]}>
                <DeviceTags />
              </Form.Item>
            </Col>
            <Col span={8}>
              {/* 否的情况下 配置了数采。不能改为是 */}
              <Form.Item name="thirdParty" label="第三方对接" rules={[{ required: true }]}>
                <Radio.Group disabled={!deviceProperty?.thirdParty && deviceProperty?.existEdgeTemplate}>
                  <Radio value={true}>是</Radio>
                  <Radio value={false}>否</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </FormContent>
        <Space className="sticky-footer">
          {isNext && (
            <Button
              type="primary"
              onClick={() => {
                onFinish(true);
              }}
            >
              保存并添加固定属性模板
            </Button>
          )}
          <Button
            type="primary"
            onClick={() => {
              onFinish(false);
            }}
          >
            保存
          </Button>
          <Button className={styles.button} onClick={onCacel}>
            取消
          </Button>
        </Space>
      </Form>
      {modalContextHolder}
    </Wrapper>
  );
};

export default CreateDevicePropertyInfo;
