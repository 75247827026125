import { EllipsisSpan, Paging, Table, usePaging, useAsync, Button, Modal, Select } from '@maxtropy/components';
import { Col, Form, Input, TreeSelect } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Filter from '@/shared/components/Filter';
import { useQuery } from '@/shared/utils/utils';
import styles from './index.module.scss';
import { DefaultOptionType } from 'rc-tree-select/lib/TreeSelect';
import {
  apiV2EdgeDeviceV2DeviceListPost,
  apiV2OuListByMcidPost,
  V2EdgeDeviceV2DeviceListPostRequest,
  V2EdgeDeviceV2DeviceListPostResponse,
} from '@maxtropy/tody-mgmt-apis-v2';
import { getOrganization, OrganizationResponse, PhysicalModelRes } from '@/api/device';
import { isEmpty } from 'lodash-es';
import { getAllTenant } from '@/api/cc-tenant';

type SearchParams = Omit<V2EdgeDeviceV2DeviceListPostRequest, 'page' | 'size'>;

type Device = Exclude<V2EdgeDeviceV2DeviceListPostResponse['list'], undefined>[number];

const columns: ColumnsType<Device> = [
  {
    title: '设备名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '设备编号',
    dataIndex: 'code',
    width: 200,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    dataIndex: 'typeName',
    width: 200,
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '物模型型号',
    dataIndex: 'physicalModel',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '运营单元',
    dataIndex: 'ou',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属租户',
    dataIndex: 'rootFullName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属组织',
    dataIndex: 'customerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];
export interface DeviceModalProps {
  id: number;
  onCancel: () => void;
}

const formatTreeData = (data: OrganizationResponse[]) => {
  return data.map(item => {
    const res: DefaultOptionType = {
      key: item.data?.mcid ?? '',
      value: item.data?.mcid ?? '',
      title: item.data?.name,
      children: formatTreeData(item.children),
    };
    return res;
  });
};

const DeviceModal: React.FC<DeviceModalProps> = ({ id, onCancel }) => {
  const pagingInfo = usePaging(50);
  const [form] = Form.useForm();
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [searchParams, setSearchParams] = useState<SearchParams>();
  const [organization, setOrganization] = useState<OrganizationResponse>();

  const rootMcid = Form.useWatch('rootMcid', form);
  const mcids = Form.useWatch('mcids', form);
  const tenantCallback = useCallback(() => getAllTenant({}, false), []);
  const tenant = useAsync(tenantCallback, []);

  const { data, isLoading } = useQuery(
    useCallback(
      () =>
        apiV2EdgeDeviceV2DeviceListPost(
          {
            ...searchParams,
            edgeDeviceTemplateId: id,
            ouIds: searchParams?.ouIds ? [searchParams!.ouIds as unknown as number] : undefined,
            mcids: isEmpty(searchParams?.mcids) ? undefined : [searchParams!.mcids as unknown as string],
            page: pageOffset,
            size: pageSize,
          },
        ).then(res => {
          setTotalCount(res.total ?? 0);
          return res.list ?? [];
        }),
      [id, pageOffset, pageSize, searchParams, setTotalCount]
    )
  );

  const { data: ouList } = useQuery(
    useCallback(
      () =>
        apiV2OuListByMcidPost(
          {
            request: mcids,
          },
        ).then(res => res.list ?? []),
      [mcids]
    )
  );

  const onReset = () => {
    form.resetFields();
    form.submit();
  };

  const onFinish = (val: SearchParams) => {
    setSearchParams(val);
    setPageOffset(1);
  };

  useEffect(() => {
    if (rootMcid) {
      getOrganization(rootMcid).then(res => {
        setOrganization(res);
      });
    } else {
      setOrganization(undefined);
    }
  }, [rootMcid]);

  const treeData = useMemo(() => {
    if (organization) {
      return formatTreeData([organization]);
    } else {
      return undefined;
    }
  }, [organization]);

  return (
    <>
      <Modal
        width={1024}
        title="已使用设备"
        open={true}
        onCancel={onCancel}
        footer={
          <Button type="primary" onClick={onCancel}>
            确定
          </Button>
        }
      >
        <div className={styles.deviceModal}>
          <Filter<SearchParams> form={form} onFinish={val => onFinish(val)} onReset={onReset}>
            <Col span={8}>
              <Form.Item name="name" label="编号/名称">
                <Input placeholder={'请输入编号或名称查询'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="rootMcid" label="所属租户">
                <Select
                  style={{ width: '100%' }}
                  placeholder="请选择"
                  onChange={v => {
                    form.setFieldsValue({ mcids: undefined });
                    form.setFieldsValue({ ouIds: undefined });
                  }}
                  options={tenant.map(i => ({ label: i.name, value: i.mcid }))}
                  optionFilterProp="label"
                  showSearch
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="mcids" label="所属组织">
                <TreeSelect
                  onChange={val => {
                    form.setFieldsValue({ ouIds: undefined });
                  }}
                  treeData={treeData}
                  placeholder="请选择"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="ouIds" label="运营单元">
                <Select
                  style={{ width: '100%' }}
                  placeholder="请选择"
                  options={ouList?.map(i => ({ label: i.name, value: i.id }))}
                  optionFilterProp="label"
                  allowClear
                  showSearch
                />
              </Form.Item>
            </Col>
          </Filter>
          <Table scroll={{ y: 300 }} rowKey="id" loading={isLoading} columns={columns} dataSource={data} />
          <Paging pagingInfo={pagingInfo} />
        </div>
      </Modal>
    </>
  );
};

export default DeviceModal;
