import { FC, Key, useEffect, useReducer, useState } from 'react';
import { EllipsisSpan, Paging, useSearchParams, Wrapper } from '@maxtropy/components';
import { Button, Col, Form, Input, Modal, Row, Space, Table } from 'antd';
import styles from './index.module.scss';
import TemplateSelectModal from './components/TemplateSelectModal';
import { apiFormPageGet } from '../../ytt/types/sprint64/form/page';
import { apiFormIdCopyPost } from '../../ytt/types/sprint64/id/copy';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { apiFormIdDelete } from '../../ytt/types/sprint64/form/id';
import { useNavigate } from 'react-router-dom';
import { FormItem } from '../../api/mockForm';
import dayjs from 'dayjs';

const { useForm } = Form;
const routes = [{ name: '数据运营工具' }, { name: '业务数据模拟' }, { name: '表单管理' }];

type SearchParams = {
  name?: string;
};

const FormsManage: FC = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const pagingInfo = useSearchParams<SearchParams>();
  const { searchParams, finish, reset, setTotalCount } = pagingInfo;
  const [dataSource, setDataSource] = useState<FormItem[]>([]);
  const [modalApi, modalContextHolder] = Modal.useModal();
  const [xx, forceUpdate] = useReducer(x => x + 1, 0);
  const [form] = useForm();

  useEffect(() => {
    setLoading(true);
    apiFormPageGet({
      ...searchParams,
      page: String(searchParams.pageOffset),
      size: String(searchParams.pageSize),
    })
      .then(res => {
        setDataSource(res.list ?? []);
        setTotalCount(res.total ?? 0);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [searchParams, xx]);

  const onFinish = (val: SearchParams) => {
    finish({ ...val });
  };
  // Filter组件的onReset重置事件
  const onReset = () => {
    reset();
  };

  const copyForm = (formId: Key) => {
    modalApi.confirm({
      title: '是否复制此表单?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        apiFormIdCopyPost({ id: String(formId) }).then(_ => {
          forceUpdate();
        });
      },
    });
  };

  const deleteForm = (formId: Key) => {
    modalApi.confirm({
      title: '您是否要删除此表单?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        apiFormIdDelete({ id: String(formId) }).then(_ => {
          forceUpdate();
        });
      },
    });
  };

  const columns = [
    {
      title: '表单名称',
      dataIndex: 'name',
      ellipsis: { showTitle: true },
    },
    {
      title: '表单说明',
      dataIndex: 'description',
      ellipsis: { showTitle: true },
    },
    {
      title: '表单模板',
      dataIndex: 'formTemplateName',
      ellipsis: { showTitle: true },
    },
    {
      title: '更新人',
      dataIndex: 'updateCropUserName',
      ellipsis: { showTitle: true },
    },
    {
      title: '更新时间',
      dataIndex: 'updateTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v ? dayjs(v).format('YYYY-MM-DD HH:mm:ss') : '--'} />,
    },
    {
      title: '操作',
      fixed: 'right' as const,
      width: 200,
      render: (text: any, record: FormItem) => {
        return (
          <Space size={16}>
            <Button style={{ padding: 0 }} type="link" onClick={v => copyForm(record.id!)}>
              复制
            </Button>
            <Button
              style={{ padding: 0 }}
              type="link"
              onClick={() => navigate(`/data-tools/business/formedit/${record.id!}`)}
            >
              编辑
            </Button>
            <Button style={{ padding: 0 }} type="link" onClick={() => deleteForm(record.id!)}>
              删除
            </Button>
          </Space>
        );
      },
    },
  ];
  return (
    <Wrapper
      routes={routes}
      className={styles.outer}
      filters={
        <div className={styles.filter}>
          <Form form={form} onFinish={val => onFinish(val)} onReset={onReset}>
            <Row gutter={10}>
              <Col span={6}>
                <Form.Item label="名称" name="name">
                  <Input placeholder="请输入表单名称搜索"></Input>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item>
                  <Space size={12}>
                    <Button type="primary" htmlType="submit">
                      搜索
                    </Button>
                  </Space>
                </Form.Item>
              </Col>
              <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type="primary" onClick={() => setShowModal(true)}>
                  从模板新建
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      }
    >
      <Table
        loading={isLoading}
        dataSource={dataSource}
        sticky
        rowKey="id"
        scroll={{ y: 600 }}
        columns={columns}
        pagination={false}
      />
      <Paging pagingInfo={pagingInfo} />
      {showModal && <TemplateSelectModal onCancel={() => setShowModal(false)} />}
      {modalContextHolder}
    </Wrapper>
  );
};

export default FormsManage;
