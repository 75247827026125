import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { EllipsisSpan, Filter, Table, Wrapper, Paging, useSearchParams } from '@maxtropy/components';
import { Button, Col, Divider, Form, Input, Popconfirm, Select, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  AccountItem,
  AccountRequest,
  changeDeveloperStatus,
  deleteDeveloperAccount,
  queryDeveloperAccountList,
} from '../../../api/openPlatform-account';
import { RoleType, RoleTypeDisplay, StateType, StateTypeDisplay } from '../../../api/openPlatform-const';
import dayjs from 'dayjs';
import styles from './index.module.scss';
import { isNil } from 'lodash-es';
import { queryIsvList, QueryIsvListProps } from '../../../api/openPlatform-application';
import { PlusOutlined } from '@ant-design/icons';
import { PermissionsType } from '@/common/permissionsConst';
import { useMgmtPermissions } from '@/components/Layout/hooks';

const routes = [{ name: '开发者平台' }, { name: '应用开发开放平台' }, { name: '开发者账号管理' }];

interface SearchParams extends Omit<AccountRequest, 'offset' | 'count'> {}

const statusOptions = [
  { label: StateTypeDisplay[StateType.DISABLE], value: StateType.DISABLE },
  { label: StateTypeDisplay[StateType.ENABLE], value: StateType.ENABLE },
];

const DeveloperAccountList: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const pagingInfo = useSearchParams<SearchParams>(50);
  const { searchParams, setTotalCount, finish, reset } = pagingInfo;
  const [tableSource, setTableSource] = useState<AccountItem[]>([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [xx, forceUpdate] = useReducer(x => x + 1, 0);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const serviceId = urlSearchParams.get('serviceId') ?? undefined;
  const [serverProviderList, setServerProviderList] = useState<QueryIsvListProps[]>([]);

  const permissions = useMgmtPermissions();

  useEffect(() => {
    const initServiceId = isNil(serviceId) ? undefined : Number(serviceId);
    form.setFieldsValue({ independentSoftwareVendorId: initServiceId });
    searchParams.independentSoftwareVendorId = initServiceId;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 查询服务商list
  useEffect(() => {
    queryIsvList().then(setServerProviderList);
  }, []);

  // 副作用
  useEffect(() => {
    setLoading(true);
    queryDeveloperAccountList({
      ...searchParams,
      status: searchParams.status ?? StateType.ENABLE,
      // 请求时分页器信息
      offset: searchParams.pageOffset,
      count: searchParams.pageSize,
    })
      .then(res => {
        setTotalCount(res.total);
        setTableSource(res.records);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, xx]);

  const onFinish = (val: SearchParams) => {
    finish({ ...val });
  };

  const serverProviderOptions = useMemo(() => {
    if (serverProviderList && serverProviderList.length !== 0) {
      return serverProviderList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [serverProviderList]);

  const columns = [
    {
      title: '账号',
      dataIndex: 'username',
      key: 'username',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '手机号',
      dataIndex: 'cellphone',
      key: 'cellphone',
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '角色',
      dataIndex: 'roleType',
      key: 'roleType',
      render: (v: RoleType) => <EllipsisSpan value={RoleTypeDisplay[v]} />,
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (v: StateType) => (
        <>
          <div className={v === StateType.DISABLE ? styles.disablePoint : styles.enablePoint}></div>
          <EllipsisSpan value={StateTypeDisplay[v]} />
        </>
      ),
    },
    {
      title: '服务商',
      dataIndex: 'independentSoftwareVendorName',
      key: 'independentSoftwareVendorName',
      render: (v: string, record: AccountItem) => (
        <EllipsisSpan value={`${v}${!record.independentSoftwareVendorStatus ? '（已禁用）' : ''}`} />
      ),
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      render: (text: string) => <EllipsisSpan value={dayjs(text).format('YYYY-MM-DD HH:mm:ss')} />,
    },
    {
      title: '操作',
      key: 'action',
      width: 260,
      render: (text: any, record: AccountItem) => (
        <Space size={16}>
          <Button
            type="link"
            style={{ paddingLeft: 0, paddingRight: 0 }}
            onClick={() => navigate(`/open-platform/open-platform/developer-account/detail/${record.id}`)}
          >
            查看
          </Button>
          <Button
            type="link"
            style={{ paddingLeft: 0, paddingRight: 0 }}
            onClick={() => navigate(`/open-platform/open-platform/developer-account/edit/${record.id}`)}
          >
            编辑
          </Button>
          <Popconfirm
            title="你确定要删除该账号吗？"
            onConfirm={() => {
              deleteDeveloperAccount(record.id).then(() => {
                forceUpdate();
              });
            }}
            okText="确定"
            cancelText="取消"
          >
            <Button type="link" style={{ paddingLeft: 0, paddingRight: 0 }}>
              删除
            </Button>
          </Popconfirm>
          {record.status === StateType.ENABLE ? (
            <Popconfirm
              title={`确认禁用${record.username}账号吗，停用后该账号将不能登录开发者平台。`}
              onConfirm={() => {
                changeDeveloperStatus(record.id, { status: StateType.DISABLE }).then(() => {
                  forceUpdate();
                });
              }}
              placement="topRight"
              overlayStyle={{ width: 300 }}
              okText="确定"
              cancelText="取消"
            >
              <Button type="link" style={{ paddingLeft: 0, paddingRight: 0 }}>
                禁用
              </Button>
            </Popconfirm>
          ) : (
            <Button
              type="link"
              style={{ paddingLeft: 0, paddingRight: 0 }}
              onClick={() => {
                changeDeveloperStatus(record.id, { status: StateType.ENABLE }).then(() => {
                  forceUpdate();
                });
              }}
            >
              启用
            </Button>
          )}
        </Space>
      ),
    },
  ];

  const filters = (
    <Filter onFinish={onFinish} form={form} onReset={reset} initialValues={{ status: StateType.ENABLE }}>
      <Col span={6}>
        <Form.Item name="independentSoftwareVendorId" label="服务商">
          <Select placeholder="请选择服务商" options={serverProviderOptions} showSearch optionFilterProp="label" />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name="username" label="账号">
          <Input placeholder="请输入账号进行搜索" />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name="cellphone" label="手机号">
          <Input placeholder="请输入手机号进行搜索" />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item name="status" label="状态">
          <Select placeholder="请选择" options={statusOptions} />
        </Form.Item>
      </Col>
    </Filter>
  );

  return (
    <Wrapper routes={routes} filters={filters}>
      {permissions?.includes(PermissionsType.B_CREATEDEVELOPER) && (
        <Button
          type="primary"
          style={{ marginBottom: 20 }}
          onClick={() => navigate('/open-platform/open-platform/developer-account/create')}
        >
          <PlusOutlined /> 创建账号
        </Button>
      )}
      <Table loading={loading} columns={columns} dataSource={tableSource} rowKey="id" scroll={{ x: 1300 }} />
      <Paging pagingInfo={pagingInfo} />
    </Wrapper>
  );
};

export default DeveloperAccountList;
