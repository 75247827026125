import React, { useState, useEffect } from 'react';
import { Wrapper, Filter, Table, Paging, usePaging, EllipsisSpan, useUpdate } from '@maxtropy/components';
import { Col, Form, Input, Space, Button, Typography, Modal } from 'antd';
import styles from '../index.module.scss';
import { useNavigate } from 'react-router-dom';
import {
  deleteWorkspace,
  getWorkspaceInfo,
  getWorkspacePages,
  Workspace,
  WorkspacePagesRequest,
} from '../../../api/cc-tenant-ql';
import dayjs from 'dayjs';
import CreateWorkspaceModal from './CreateWorkspaceModal';
import UpdateWorkspaceModal from './UpdateWorkspaceModal';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const routes = [{ name: '租户管理' }, { name: '租户组织权限和账户' }, { name: '轻流开户' }, { name: '管理工作区' }];

type SearchParams = Omit<WorkspacePagesRequest, 'offset' | 'count'>;

export default function ManageWorkSpace() {
  const [modalApi, modalContextHolder] = Modal.useModal();
  const [form] = Form.useForm();
  const [updateState, forceUpdate] = useUpdate();
  const navigate = useNavigate();
  const pagingInfo = usePaging(50);
  const { pageOffset, pageSize, setTotalCount, setPageOffset } = pagingInfo;
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<SearchParams>();
  // 工作区表格数据
  const [workspaceData, setWorkspaceData] = useState<Workspace[]>([]);
  // 单个工作区数据
  const [workspaceInfo, setWorkspaceInfo] = useState<Workspace>();
  // 工作区增删改查 modal 的显示
  const [showCreateWorkspaceModal, setShowCreateWorkspaceModal] = useState<boolean>(false);
  const [showUpdateWorkspaceModal, setShowUpdateWorkspaceModal] = useState<boolean>(false);

  // 查询工作区表格数据
  useEffect(() => {
    setLoading(true);
    getWorkspacePages({ ...searchParams, count: pageSize, offset: pageOffset })
      .then(res => {
        setTotalCount(res.total);
        setWorkspaceData(res.contents);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [pageOffset, pageSize, searchParams, setTotalCount, updateState]);

  // 删除工作区
  const handleDeleteWorkspace = (id: number) => {
    modalApi.confirm({
      title: '是否删除此工作区？',
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        deleteWorkspace(id)
          .then(res => {
            modalApi.success({
              content: '工作区已删除成功。',
            });
            // 重新渲染列表
            forceUpdate();
          })
          .catch(error => {
            modalApi.error({
              content: '工作区已被使用，不支持删除。',
            });
          });
      },
    });
  };

  // 编辑工作区
  const handleUpdateWorkspace = (id: number) => {
    getWorkspaceInfo(id).then(res => {
      setWorkspaceInfo(res);
      setShowUpdateWorkspaceModal(true);
    });
  };

  const columns = [
    {
      title: '工作区ID',
      dataIndex: 'spaceIdCode',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '工作区名称',
      dataIndex: 'spaceName',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '最后操作人',
      dataIndex: 'updateByUsername',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={v} />,
    },
    {
      title: '操作时间',
      dataIndex: 'updateTime',
      ellipsis: { showTitle: true },
      render: (v: string) => <EllipsisSpan value={dayjs(v).format('YYYY-MM-DD HH:mm:ss')} />,
    },
    {
      title: '操作',
      dataIndex: '',
      render: (value: any, record: Workspace) => {
        return (
          <Space size={16}>
            <Typography.Link
              onClick={() => {
                // console.log('record', record);
                handleUpdateWorkspace(record.id);
              }}
            >
              编辑
            </Typography.Link>
            <Typography.Link
              onClick={() => {
                handleDeleteWorkspace(record.id);
              }}
            >
              删除
            </Typography.Link>
          </Space>
        );
      },
    },
  ];

  const onFinish = (value: SearchParams) => {
    setSearchParams(value);
    setPageOffset(1);
  };

  const onReset = () => {
    setSearchParams({});
    setPageOffset(1);
  };

  return (
    <Wrapper
      routes={routes}
      filters={
        <Filter<SearchParams> form={form} onFinish={onFinish} onReset={onReset}>
          <>
            <Col span={6}>
              <Form.Item name="spaceIdCode" label="ID">
                <Input placeholder="输入工作区ID搜索" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="spaceName" label="名称">
                <Input placeholder="输入工作区名称搜索" />
              </Form.Item>
            </Col>
          </>
        </Filter>
      }
    >
      <div className={styles.operationArea}>
        <Space>
          <Button
            type="primary"
            onClick={() => {
              setShowCreateWorkspaceModal(true);
            }}
          >
            新建工作区
          </Button>
          <Button
            onClick={() => {
              navigate(-1);
            }}
          >
            返回
          </Button>
        </Space>
      </div>
      <Table rowKey="id" loading={loading} dataSource={workspaceData} columns={columns} />
      <Paging pagingInfo={pagingInfo} />

      {showCreateWorkspaceModal && (
        <CreateWorkspaceModal
          open={showCreateWorkspaceModal}
          closeModal={() => {
            setShowCreateWorkspaceModal(false);
          }}
          forceUpdate={forceUpdate}
        />
      )}
      {showUpdateWorkspaceModal && (
        <UpdateWorkspaceModal
          open={showUpdateWorkspaceModal}
          closeModal={() => {
            setShowUpdateWorkspaceModal(false);
          }}
          forceUpdate={forceUpdate}
          workspaceInfo={workspaceInfo}
        />
      )}
      {modalContextHolder}
    </Wrapper>
  );
}
