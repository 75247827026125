import { Wrapper, useAsync } from '@maxtropy/components';
import { Col, Form, Input, Select, Tabs } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import Filter from '@/shared/components/Filter';
import styles from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import TenantList from './TenantList';
import TemplateList from './TemplateList';
import CascadingMultipleSelector from '@/shared/components/CascadingMultipleSelector';
import { formatOptionData } from '@/shared/components/CascadingMultipleSelector/utils';
import { getDeviceTypeData } from '../../api/attribute';

import qs from 'qs';
import { getPhysicalModelList, PhysicalModelRes } from '../../api/device';
import { getManufacturerList, ManufacturerItem } from '../../api/edgeTemplate';
import { useMgmtPermissions } from '@/components/Layout/hooks';

const routes = [{ name: 'Iot配置' }, { name: '数采配置' }, { name: '模板分配' }];

export interface ParamsProps {
  tenantCode?: string;
  name?: string;
  uscc?: string;
  deviceType?: Array<number> | undefined;
  brandDeviceTypes?: Array<number> | undefined;
  productDeviceTypes?: Array<number> | undefined;
  manufacturerId?: string;
  physicalModelId?: string;
}

interface FilterParamsProps {
  tenantCode?: string;
  name?: string;
  uscc?: string;
  deviceType?: Array<Array<number>> | undefined;
  manufacturerId?: string;
  physicalModelId?: string;
}

const EdgeDistribution: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const tabs = urlSearchParams.get('tabs') || '1';
  const [tabsKey, setTabsKey] = useState<string>(tabs);
  const [searchParams, setSearchParams] = useState<ParamsProps>();

  const permission = useMgmtPermissions();

  const deviceTypeData = useAsync(getDeviceTypeData);

  const [physicalModelList, setPhysicalModelList] = useState<PhysicalModelRes[]>([]); // all 物模型型号列表

  const deviceType = Form.useWatch('deviceType', form);

  const [manufacturerList, setManufacturerList] = useState<ManufacturerItem[]>([]); // 厂商列表
  const manufacturerId = Form.useWatch('manufacturerId', form);

  useEffect(() => {
    // 物模型型号
    form.setFieldsValue({
      physicalModelId: undefined,
    });
    const params = {
      deviceTypeIds: deviceType && deviceType.length > 0 ? deviceType[deviceType.length - 1] : undefined,
      manufacturerId,
    };
    getPhysicalModelList(params).then(setPhysicalModelList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceType, manufacturerId]);

  const objectModalTypeOptions = useMemo(() => {
    if (physicalModelList && physicalModelList.length > 0) {
      return physicalModelList.map(i => ({ label: i.modelNo, value: i.id }));
    }
  }, [physicalModelList]);

  useEffect(() => {
    getManufacturerList().then(setManufacturerList);
  }, []);

  const manufacturerOptions = useMemo(() => {
    if (manufacturerList && manufacturerList.length > 0) {
      return manufacturerList.map(i => ({ label: i.name, value: i.id }));
    }
  }, [manufacturerList]);

  const onFinish = (val: FilterParamsProps) => {
    const params = {
      ...val,
      deviceType: val.deviceType && val.deviceType[0] ? val.deviceType[0] : undefined,
      brandDeviceTypes: val.deviceType && val.deviceType[1] ? val.deviceType[1] : undefined,
      productDeviceTypes: val.deviceType && val.deviceType[2] ? val.deviceType[2] : undefined,
    };
    setSearchParams({ ...params });
    const paramsMap = new Map<string, string>();
    Object.entries(val).forEach(([key, value]) => {
      value && paramsMap.set(key, value);
    });
    navigate(`?${qs.stringify(Object.fromEntries(paramsMap))}`);
  };

  const onReset = () => {
    const params = {
      tenantCode: undefined,
      name: undefined,
      uscc: undefined,
      deviceType: undefined,
      manufacturerId: undefined,
      physicalModelId: undefined,
    };
    setSearchParams(params);
    navigate(`?`);
  };

  const filters = (
    <Filter form={form} onFinish={val => onFinish(val as FilterParamsProps)} onReset={onReset}>
      {tabsKey === '1' ? (
        <>
          <Col span={5}>
            <Form.Item label="租户CODE" name="tenantCode">
              <Input placeholder="输入完整租户code" />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="租户名称" name="name">
              <Input placeholder="输入全部/部分租户名称进行搜索" />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="统一社会信用代码" name="uscc">
              <Input placeholder="输入完整代码" />
            </Form.Item>
          </Col>
        </>
      ) : (
        <>
          <Col span={4}>
            <Form.Item name="name" label="编号/名称">
              <Input placeholder={'请输入编号或名称查询'} />
            </Form.Item>
          </Col>
          <Col span={10} style={{ minWidth: 780 }}>
            <Form.Item name="deviceType">
              <CascadingMultipleSelector
                labels={deviceTypeData?.aliasName}
                data={formatOptionData(deviceTypeData)}
                mode={['single']}
              />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item name="manufacturerId" label="厂商">
              <Select
                optionFilterProp="label"
                placeholder="请选择"
                showSearch
                options={manufacturerOptions}
                onChange={() => {
                  form.setFieldsValue({
                    physicalModelId: undefined,
                  });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="physicalModelId" label="物模型型号">
              <Select optionFilterProp="label" placeholder="请选择" showSearch options={objectModalTypeOptions} />
            </Form.Item>
          </Col>
        </>
      )}
    </Filter>
  );

  const onChangeTabs = (v: string) => {
    form.resetFields();
    onReset();
    setTabsKey(v);
    const urlSearchParams = new URLSearchParams(window.location.search);
    urlSearchParams.delete('tabs');
    urlSearchParams.append('tabs', v);
    navigate(`?${urlSearchParams.toString()}`);
  };

  return (
    <Wrapper routes={routes} filters={filters}>
      <Tabs
        defaultActiveKey={tabs}
        tabBarStyle={{
          marginBottom: 3,
        }}
        tabBarGutter={43}
        className={styles.tabs}
        onChange={v => onChangeTabs(v)}
        destroyInactiveTabPane
      >
        <Tabs.TabPane tab="租户列表" key="1">
          <TenantList searchParams={searchParams} tabsKey={tabsKey} permission={permission} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="模板列表" key="2">
          <TemplateList searchParams={searchParams} tabsKey={tabsKey} permission={permission} />
        </Tabs.TabPane>
      </Tabs>
    </Wrapper>
  );
};

export default EdgeDistribution;
