import { DriveType } from '../../types';

export function remove<T>(arr: T[], ...forDeletion: T[]) {
  return arr.filter(item => !forDeletion.includes(item));
}

export function getUpdatePointParameters(parameters: string[], driveType: DriveType) {
  let _parameters = [...parameters].filter(parameter => parameter !== 'other');
  if (parameters.includes('other')) {
    if (driveType === DriveType.MODBUS_TCP || driveType === DriveType.MODBUS_RTU) {
      _parameters = _parameters.concat([
        'physicalUnitId',
        'writable',
        'objectType',
        'length',
        'mode',
        'valueType',
        'valueFloor',
        'valueCeiling',
      ]);
    } else if (
      driveType === DriveType.DLT645_1997 ||
      driveType === DriveType.DLT645_2007 ||
      driveType === DriveType.IEC104
    ) {
      _parameters = _parameters.concat(['physicalUnitId', 'writable', 'valueFloor', 'valueCeiling']);
    }
  }
  return _parameters;
}

export function increment<T>(previousValue: T[], latestValue: T[]) {
  const intersection = Array.from(new Set(previousValue.filter(item => latestValue.includes(item))));
  const addSection = Array.from(new Set(latestValue.filter(item => !intersection.includes(item))));
  const deleteSection = Array.from(new Set(previousValue.filter(item => !intersection.includes(item))));
  return [addSection, deleteSection] as const;
}
