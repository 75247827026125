import { fetch, Key } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import qs from 'qs';
import { DeviceStatus, Operator } from '@/shared/types';

export interface Device {
  id: number;
  name: string;
  code: string;
  typeId?: number;
  typeName?: string;
  pic?: string;
  rootMcid: string;
  rootCode: string;
  rootName: string;
  tenantCode: string;
  tenantName: string;
  status: DeviceStatus;
  updateSource: Operator;
  updateByUsername: string;
  updateTime: string;
  existAttribute: boolean;
  existEdgeTemplate: boolean;
  existRuleGroup: boolean;
  ruleGroupId?: number;
  thirdParty?: boolean;
  physicalModelId?: number; // 物模型id
}

export interface DeviceListRequest extends PageRequest {
  codeOrName?: string;
  status?: DeviceStatus;
  typeId?: Array<number>;
  rootMcid?: string;
  invalid?: boolean;
  tag?: string;
  thirdParty?: boolean;
  originDeviceType?: Array<Array<number>>;
}

export interface DeviceListResponse extends PageResponse<Device> {}

export function getDeviceList(params: DeviceListRequest): Promise<DeviceListResponse> {
  return fetch(`/api/device/page?${qs.stringify(params, { indices: false })}`);
}

export interface UpdateDeviceStatusRequest {
  deviceId: number;
  status: DeviceStatus;
}

export function updateDeviceStatus(params: UpdateDeviceStatusRequest): Promise<boolean> {
  return fetch(`/api/device/updateStatus`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });
}

export interface Tenant {
  id: number;
  mcid: string;
  name: string;
  tenantCode: string;
  address: string;
  adCode: string;
  accountOpenStatus: boolean;
}

export function getRoot(): Promise<Tenant[]> {
  return fetch(`/api/customer/getRoot?check=true`);
}

export interface Organization {
  mcid: string;
  name: string;
  hasPermission: boolean;
}

export interface OrganizationResponse {
  data: Organization;
  children: OrganizationResponse[];
}

export function getOrganization(mcid: string): Promise<OrganizationResponse> {
  return fetch(`/api/customer/organization?mcid=${mcid}`);
}

export interface CreateDevicePropertyRequest {
  name: string;
  sn?: string;
  typeId?: number;
  modelId?: number;
  modelName?: string;
  manufactureDate?: string;
  installationDate?: string;
  pic?: string;
  rootMcid: string;
  customerMcid: string;
  assetCode: string;
  ouIds?: Array<number>;
  addOuIds?: Array<number>;
  deleteOuIds?: Array<number>;
  tags: string[];
  physicalModelId?: Key;
  thirdParty?: boolean;
}

export interface DevicePropertyResponse extends CreateDevicePropertyRequest {
  id: number;
  code: string;
  typeName?: string;
  tenantName: string;
  customerName: string;
  customerFullName: string;
  manufacturerId: Key; // 厂商id
  manufacturerName: string; // 厂商名称
  physicalModelNo: string; // 物模型号
  modelNo: string;
  thirdParty?: boolean;
  existEdgeTemplate?: boolean;
  existAttribute?: boolean;
}

export function getDeviceProperty(id: string): Promise<DevicePropertyResponse> {
  return fetch(`/api/device/info?id=${id}`);
}

export function createDeviceProperty(params: CreateDevicePropertyRequest): Promise<DevicePropertyResponse> {
  return fetch(`/api/device/add`, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'x-tenant-mcid': `${params.rootMcid}`,
    },
  });
}

export interface UpdateDevicePropertyRequest
  extends Omit<CreateDevicePropertyRequest, 'typeId' | 'modelId' | 'modeName' | 'rootMcid'> {
  id: number;
}

export function updateDeviceProperty(
  params: UpdateDevicePropertyRequest,
  rootMcid?: string
): Promise<DevicePropertyResponse> {
  return fetch(`/api/device/update`, {
    method: 'PUT',
    body: JSON.stringify(params),
    headers: {
      'x-tenant-mcid': rootMcid ? rootMcid : '',
    },
  });
}

export interface AttributeItem {
  fieldId: number;
  unitId?: number;
  value?: string;
}

export interface DeviceAttributeResponse {
  deviceId: number;
  templateId: number;
  templateVersion: number;
  list: AttributeItem[];
}

export function getDeviceAttribute(id: string): Promise<DeviceAttributeResponse> {
  return fetch(`/api/device/attribute?id=${id}`);
}

export function updateDeviceAttribute(
  params: DeviceAttributeResponse,
  tenantMcid: string
): Promise<DeviceAttributeResponse> {
  return fetch(`/api/device/attribute/upsert`, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'x-tenant-mcid': tenantMcid,
    },
  });
}

export interface PhysicalModelRes {
  id: Key;
  modelNo: string; // 型号
  manufacturerId: Key; // 厂商id
  deviceTypeId: Key; // 设备类目id
  createTime: string;
  updateTime: string;
  manufacturerName: string; // 厂商名称
}

//  获取all 物模型型号
export function getPhysicalModelAllList() {
  return fetch<PhysicalModelRes[]>('/api/physical-model/getPhysicalModelAllList');
}

export interface PhysicalParams {
  deviceTypeIds?: Key[]; // 设备类目id
  manufacturerId?: Key; // 厂商id
}

// 根据设备类目和厂商查询物模型列表

export function getPhysicalModelList(params: PhysicalParams) {
  return fetch<PhysicalModelRes[]>(`/api/physical-model/list?${qs.stringify(params, { indices: false })}`);
}

export interface PhysicalModelRequest extends PageRequest {
  deviceTypeIds?: Array<number>;
  manufacturerId?: number;
  physicalModelId?: string;
}

export interface PhysicalModelItems {
  id: number;
  modelNo: string;
  manufacturerName: string;
  scene: string;
  firstLevelDeviceType: string;
  secondLevelDeviceType: string;
  updateTime: any;
}
export const getPhysicalModelPage = (params: PhysicalModelRequest) =>
  fetch<PageResponse<PhysicalModelItems>>(`/api/physical-model/page?${qs.stringify(params, { indices: false })}`);
