import { Cascader } from 'antd';
import { useEffect, useState } from 'react';
import { AppFunctionPermissionVo } from '../../../api/cc-app';
import { getThreePermissionTree } from '../../../api/cc-tenant-ql';
import { formateTreePemission } from '../common';
interface Iprops {
  appId: string;
  isSet?: boolean;
  value?: string[];
  disabled?: boolean;
  hasSelects?: string[];
  onChange?: (value?: any, selectedOptions?: any) => void;
}
const AppPermissionSelect: React.FC<Iprops> = ({ appId, value, onChange, disabled, isSet, hasSelects }) => {
  const [data, setData] = useState<AppFunctionPermissionVo[]>();
  const [treeList, setTreeList] = useState<AppFunctionPermissionVo[]>();
  const [permissionValue, setPermissionValue] = useState<string[]>();

  // 依赖项变化, 重置表单且依赖项不为空时初始化下拉框
  useEffect(() => {
    setPermissionValue(undefined);
    onChange?.();
    if (appId) {
      getThreePermissionTree(appId).then(res => {
        formateTreePemission(hasSelects ?? [], res);
        setData(res);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId]);

  // 设置disabled项
  useEffect(() => {
    if (data) {
      formateTreePemission(hasSelects ?? [], data);
      setTreeList(data);
    }
  }, [hasSelects, data]);
  // 初始化表单
  useEffect(() => {
    if (value && isSet) {
      setPermissionValue(value);
      onChange?.(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, isSet]);

  const selectChange = (val?: any, children?: any) => {
    setPermissionValue(val);
    onChange?.(val);
  };
  return (
    <>
      <Cascader
        disabled={disabled}
        value={permissionValue}
        fieldNames={{ label: 'name', value: 'code' }}
        placeholder="请选择"
        options={treeList}
        onChange={selectChange}
      />
    </>
  );
};

export default AppPermissionSelect;
