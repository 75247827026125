import { FC, useState, useMemo, useEffect, ReactNode } from 'react';
import { Button, Form, Row, Col, Space, Modal } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { AppFunctionPermissionVo } from '../../../api/cc-app';
import { RoleTree } from '@maxtropy/components';
import { dig } from '../../RolePermissions/Edit/EditPermissions';

import styles from './index.module.scss';
import { getRolePermissions, Wrapper, FormContent, Key } from '@maxtropy/components';
import {
  getIntegratedAppPermission,
  getIntegratedAppPermissionTree,
  getTenantIntegratedAppInfo,
  TenantIntegratedAppInfoResponse,
  updateIntegrateAppFuctionPermission,
} from '../../../api/cc-tenant';

const ShowInput: FC<{ value?: ReactNode }> = ({ value }) => <div>{value ?? '--'}</div>;

const formLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 },
};

const { useForm } = Form;

const IntegrateAppPermissions: FC = props => {
  const [modalApi, modalContextHolder] = Modal.useModal();
  const { mcid, integratedAppId } = useParams<{ mcid: string; integratedAppId: string }>();

  const [integratedAppPermissionTree, setIntegratedAppPermissionTree] = useState<AppFunctionPermissionVo[]>([]);
  const [integratedAppPermissions, setIntegratedAppPermissions] = useState<Key[]>([]);
  const [tenantIntegratedAppInfo, setTenantIntegratedAppInfo] = useState<TenantIntegratedAppInfoResponse>();

  const navigate = useNavigate();
  const [form] = useForm();

  const routes = useMemo(() => {
    return [{ name: '租户管理' }, { name: '租户组织权限和账户' }, { name: `编辑租户` }, { name: '集成应用功能权限' }];
  }, []);

  useEffect(() => {
    if (mcid && integratedAppId) {
      getIntegratedAppPermission({ rootMcid: mcid, integratedAppId }).then(setIntegratedAppPermissions);
      getIntegratedAppPermissionTree({ rootMcid: mcid, integratedAppId }).then(setIntegratedAppPermissionTree);
      getTenantIntegratedAppInfo({ rootMcid: mcid, integratedAppId }).then(setTenantIntegratedAppInfo);
    }
  }, [mcid, integratedAppId]);

  const treeData = useMemo(() => {
    return dig(integratedAppPermissionTree ?? []);
  }, [integratedAppPermissionTree]);

  useEffect(() => {
    form.setFieldsValue({
      codes: integratedAppPermissions,
    });
  }, [integratedAppPermissions, form]);

  const onFinish = (value: any) => {
    const { permissions } = getRolePermissions({
      treeData,
      checkedKeys: value.codes ?? [],
      originalCheckedKeys: integratedAppPermissions,
    });
    updateIntegrateAppFuctionPermission({
      integratedAppId: Number(integratedAppId),
      mcid: mcid!,
      addCodes: permissions[0],
      deleteCodes: permissions[1],
    }).then(() => {
      navigate(`/tenant/customer/manage/edit/${mcid}/integrated-app`);
    });
  };

  const onCacel = () => {
    modalApi.confirm({
      title: null,
      icon: null,
      content: <div>是否放弃所有未保存信息并返回集成应用页面？</div>,
      onOk: () => {
        navigate(`/tenant/customer/manage/edit/${mcid}/integrated-app`);
      },
    });
  };

  return (
    <Wrapper routes={routes}>
      <FormContent>
        <div className={styles.container}>
          <Form form={form} layout="vertical" {...formLayout} onFinish={onFinish}>
            <Row>
              <Col span={8}>
                <Form.Item label="租户全称">
                  <ShowInput value={tenantIntegratedAppInfo?.tenantFullName} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="租户简称">
                  <ShowInput value={tenantIntegratedAppInfo?.tenantName} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="租户code">
                  <ShowInput value={tenantIntegratedAppInfo?.tenantCode} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="集成应用名称">
                  <ShowInput value={tenantIntegratedAppInfo?.integratedAppName} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="集成应用ID">
                  <ShowInput value={tenantIntegratedAppInfo?.integratedAppId} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="开放平台APPID">
                  <ShowInput value={tenantIntegratedAppInfo?.openPlatformKey} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={20} className={styles.functionFormLabelContainer}>
                <Form.Item
                  name="codes"
                  label="功能权限管理"
                  wrapperCol={{ span: 24 }}
                  validateFirst={true}
                  valuePropName="checkedKeys"
                  trigger="onCheck"
                >
                  <RoleTree treeData={treeData} className={styles.tree} showDetail />
                </Form.Item>
              </Col>
            </Row>
            {
              // !(!account || disabled) && (
              <Row className={styles.buttonWrapper}>
                {/* <Divider /> */}
                <Col span={7}>
                  <Form.Item>
                    <Space>
                      <Button htmlType="submit" type="primary">
                        保存
                      </Button>
                      <Button onClick={onCacel}>关闭</Button>
                    </Space>
                  </Form.Item>
                </Col>
              </Row>
              // )
            }
          </Form>
        </div>
      </FormContent>
      {modalContextHolder}
    </Wrapper>
  );
};

export default IntegrateAppPermissions;
