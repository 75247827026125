import { Steps } from 'antd';
import styles from '../index.module.scss';
import { FC } from 'react';

const stepItems = [{ title: '基本信息' }, { title: '计算公式' }];

interface StepsViewProps {
  currentStep: 0 | 1;
}

const StepsView: FC<StepsViewProps> = props => {
  const { currentStep } = props;

  return (
    <div className={styles.stepsWrapper}>
      <Steps current={currentStep} items={stepItems} className={styles.steps}></Steps>
    </div>
  );
};

export default StepsView;
