import qs from 'qs';
import { Key } from 'react';
import { fetch } from '@maxtropy/components';
import { AppFunctionPermissionVo } from './cc-app';
import { Gender, TenantStatus, UserStatus } from './cc-const';
import { BaseCustomer, BaseStaff } from './cc-entity';
import { TenantMenuNavigationResponse } from './cc-navigation';

export interface Paged<T> {
  total: number;
  offset: number;
  count: number;
  contents: T[];
}

export interface PageRequest {
  offset?: number;
  count?: number;
}

export interface TenantAllRootReponse {
  mcid: string;
  name: string;
  uscc: string;
}

interface TenantQueryRequest {
  value?: string;
  status?: boolean;
}

export function getAllTenant(query: TenantQueryRequest = {}, check: boolean = true): Promise<TenantAllRootReponse[]> {
  return fetch(`/api/tenant/allRoot?${qs.stringify({ ...query, check }, { indices: false })}`);
}

export interface TenantBase {
  name: string;
  uscc?: string;
  adCode: string;
  address?: string;
  provinceCityDistrict?: string;
  phone?: string;
  businessLicense?: string;
}

export interface TenantPageResponse extends TenantBase {
  id: number;
  mcid: string;
  tenantCode: string;
  accountOpenStatus: boolean;
}

export interface TenantRequest extends PageRequest {
  name?: string;
  tenantCode?: string;
  accountOpenStatus?: TenantStatus;
  uscc?: string;
}

export function getTenantList(query: TenantRequest): Promise<Paged<TenantPageResponse>> {
  return fetch(`/api/tenant/page?${qs.stringify(query, { indices: false })}`);
}

export interface CreateTenantBaseInforequest extends TenantBase {
  affiliatendMcids: string[];
  enterprises?: TenantPageResponse[];
}

export interface TenantBaseInfoResponse extends TenantPageResponse {
  enterprises?: TenantPageResponse[];
  miniAppIds?: number[];
  tenantType?: 1 | 2; // 1:普通租户 2:渠道端租户
}

export function createTenantBaseInfo(params: CreateTenantBaseInforequest): Promise<TenantBaseInfoResponse> {
  return fetch('/api/tenant/create', {
    method: 'POST',
    body: JSON.stringify(params),
  });
}

export interface UpdateTenantRequest extends CreateTenantBaseInforequest {
  id: number;
}

export function updateTenantBaseInfo(params: UpdateTenantRequest): Promise<TenantBaseInfoResponse> {
  return fetch('/api/tenant/update', {
    method: 'PUT',
    body: JSON.stringify(params),
  });
}

export function getTenatInfo(mcid: string): Promise<TenantBaseInfoResponse> {
  return fetch(
    `/api/v2/tenant/info`,
    {
      method: 'POST',
      body: JSON.stringify({ mcid }),
    },
    true
  );
}

export interface CreateCustomerContactRequest {
  mcid: string;
  name: string;
  cellphone?: string;
  telephone?: string;
  email?: string;
  qq?: string;
  dept?: string;
  parentId?: number;
}

export interface CustomerContactResponse extends CreateCustomerContactRequest {
  id: number;
  code: string;
  parentName?: string;
  createTime: string;
  updateTime: string;
}

export interface UpdateCustomerContactRequest extends CreateCustomerContactRequest {
  id: number;
}

export function createCustomerContact(params: CreateCustomerContactRequest): Promise<CustomerContactResponse> {
  return fetch(`/api/tenant/create-customer-contacts`, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'x-tenant-mcid': `${params.mcid}`,
    },
  });
}

export function getCustomerContact(mcid: string): Promise<CustomerContactResponse[]> {
  return fetch(`/api/tenant/list-customer-contacts?mcid=${mcid}`);
}

export function updateCustomerContact(params: UpdateCustomerContactRequest): Promise<CustomerContactResponse> {
  return fetch(`/api/tenant/update-customer-contacts`, {
    method: 'PUT',
    body: JSON.stringify(params),
    headers: {
      'x-tenant-mcid': `${params.mcid}`,
    },
  });
}

export interface UpdateContactLogResponse {
  id: number;
  operatorId: number;
  operatorName: number;
  contactsCode: string;
  contactsName: string;
  mcid: string;
  beforeInfo: string;
  afterInfo: string;
  operatorTime: string;
  ip: string;
}

export function getUpdateContactLogs(mcid: string): Promise<UpdateContactLogResponse[]> {
  return fetch(`/api/tenant/contacts-update-log?mcid=${mcid}`);
}

export interface OpenAccoundRequest {
  mcid: string;
  shortName: string;
  fullName: string;
  muid?: string;
  username: string;
  name: string;
  cellphone: string;
  status: UserStatus;
  gender: Gender;
  birthday?: string;
  email?: string;
  wechat?: string;
  qq?: string;
  headPic?: string;
  staffCode?: string;
  workEmail?: string;
  workWechat?: string;
  workQq?: string;
  homePic?: string;
  isSetHomePageOptions?: string;
}

export interface OpenAccoundResponse {
  customer: BaseCustomer;
  muid: string;
  username: string;
  name: string;
  cellphone: string;
  status: UserStatus;
  gender: Gender;
  birthday?: string;
  email?: string;
  wechat?: string;
  qq?: string;
  headPic?: string;
  staff: BaseStaff;
  password?: string;
}

export function openAccound(params: OpenAccoundRequest): Promise<OpenAccoundResponse> {
  return fetch(`/api/tenant/open-account`, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'x-tenant-mcid': `${params.mcid}`,
    },
  });
}

export type TenantMenuNavigationResponseExpand = TenantMenuNavigationResponse & {
  selfName?: string;
  parentName?: string;
  rootName?: string;
  parentWeighting?: number;
  rootWeighting?: number;
  selfWeighting?: number;
  currentId?: number;
  firstLevel?: number;
  secondLevel?: number;
  thirdLevel?: number;
};

export enum PassWordIsOpen {
  OPEN = 1, // 开启
  CLOSE = 0, // 关闭
}

export interface InfoOpenAccountResponse {
  passwordOpSwitch: PassWordIsOpen;
  customizeNavigations: TenantMenuNavigationResponseExpand[];
  navigationId: number;
  customer: BaseCustomer;
  logoUrl?: string;
  tenantOrganizationLevelVoList?: { name: string; index: number }[];
  homePageLogoType: number;
  homePageLogoUrl: string;
  platformTitleType: number;
  platformName: string;
  iconUrl: string;
}

export function getInfoOpenAccount(mcid: string): Promise<InfoOpenAccountResponse> {
  return fetch(`/api/tenant/info-open-account?mcid=${mcid}`);
}

export interface UpdateInfoOpenAccountRequest {
  navigationId: number;
  mcid: string;
  shortName: string;
  fullName: string;
  logoUrl?: string;
  tenantOrganizationLevelUpdRequestList?: { name: string; index: number }[];
  navigationList?: TenantMenuNavigationResponse[];
  homePageLogoType: number;
  homePageLogoUrl?: string;
  platformTitleType: number;
  platformName?: string;
  iconUrl?: string;
  isSetHomePageOptions?: number;
  passwordOpSwitch?: boolean | PassWordIsOpen;
}

export function updateInfoOpenAccount(params: UpdateInfoOpenAccountRequest): Promise<OpenAccoundResponse> {
  return fetch(`/api/tenant/update-open-account`, {
    method: 'PUT',
    body: JSON.stringify(params),
    headers: {
      'x-tenant-mcid': `${params.mcid}`,
    },
  });
}

export function getTenantFuctionPermission(mcid: string): Promise<string[]> {
  return fetch(`/api/tenant/function-permissions?mcid=${mcid}`);
}

export interface UpdateTenantFuctionPermissionRequest {
  mcid: string;
  addCodes: Key[];
  deleteCodes: Key[];
}

export function updateTenantFuctionPermission(params: UpdateTenantFuctionPermissionRequest): Promise<string[]> {
  return fetch(`/api/tenant/update-function-permission`, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'x-tenant-mcid': `${params.mcid}`,
    },
  });
}

export interface ThemeProps {
  id: number;
  name: string;
  color: string;
  colorReserve: string;
  colorWords: string;
  imageUrl: string;
  themeName: string;
}

export function getThmeList(): Promise<ThemeProps[]> {
  return fetch('/api/tenant/personalized/getImagesDefaultList');
}

export interface TenantTheme extends ThemeProps {
  mcid: string;
}

export function getTenantTheme(mcid: string): Promise<TenantTheme> {
  return fetch(`/api/tenant/personalized/getColorConfig/${mcid}`);
}

export function updateTenantTheme(params: TenantTheme): Promise<boolean> {
  return fetch(`/api/tenant/personalized/updateColorConfig`, {
    method: 'PUT',
    body: JSON.stringify(params),
    headers: {
      'x-tenant-mcid': `${params.mcid}`,
    },
  });
}

export interface IntegratedAppPermissionRequest {
  rootMcid: string;
  integratedAppId: string;
}

export interface TenantIntegratedAppInfoResponse {
  tenantFullName: string;
  tenantName: string;
  tenantCode: string;
  integratedAppName: string;
  integratedAppId: number;
  openPlatformKey: number;
}

export function getTenantIntegratedAppInfo(
  query: IntegratedAppPermissionRequest
): Promise<TenantIntegratedAppInfoResponse> {
  return fetch(`/api/tenant/tenantIntegratedAppInfo?${qs.stringify(query, { indices: false })}`);
}

export function getIntegratedAppPermissionTree(
  query: IntegratedAppPermissionRequest
): Promise<AppFunctionPermissionVo[]> {
  return fetch(`/api/tenant/integratedAppTreeByRootMcid?${qs.stringify(query, { indices: false })}`);
}

export function getIntegratedAppPermission(query: IntegratedAppPermissionRequest): Promise<string[]> {
  return fetch(`/api/tenant/integrated-app-permission?${qs.stringify(query, { indices: false })}`);
}

export interface UpdateIntegrateAppFuctionPermissionRequest extends UpdateTenantFuctionPermissionRequest {
  integratedAppId: number;
}

export function updateIntegrateAppFuctionPermission(
  params: UpdateIntegrateAppFuctionPermissionRequest
): Promise<string[]> {
  return fetch(`/api/tenant/update-integrated-app-permission`, {
    method: 'POST',
    body: JSON.stringify(params),
    headers: {
      'x-tenant-mcid': `${params.mcid}`,
    },
  });
}
