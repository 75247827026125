import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RoleTree, Key, TreeDataNode as DataNode } from '@maxtropy/components';

import styles from './index.module.scss';
import { getMiniAppPermissionFunctionTree, MiniAppPermissionFunctionTreeItem } from '../../../api/cc-miniApp';
import { getRoleMiniAppPermissions, MiniAppPermissionItem, UpdateAppPermissionBodyItem } from '../../../api/cc-role';

export interface RefMiniAppPermissionsProps {
  getSubmitData: () => UpdateAppPermissionBodyItem[];
}

interface MiniAppPermissionsProps {
  disabled: boolean;
}

const MiniAppPermissions = forwardRef<RefMiniAppPermissionsProps, MiniAppPermissionsProps>((props, ref) => {
  const { code } = useParams<{ code: string }>();
  const [originalTreeData, setOriginalTreeData] = useState<MiniAppPermissionFunctionTreeItem[]>([]);
  const [checkedItems, setCheckedItems] = useState<MiniAppPermissionItem[]>([]);
  const [autoSyncedItems, setAutoSyncedItems] = useState<MiniAppPermissionItem[]>([]);

  useEffect(() => {
    if (!code) return;
    getMiniAppPermissionFunctionTree().then(res => {
      setOriginalTreeData(res);
      getRoleMiniAppPermissions(code).then(res => {
        setCheckedItems(res);
      });
    });
  }, [code]);

  const treeData: DataNode[] = useMemo(() => {
    return convertToTreeData(originalTreeData);
  }, [originalTreeData]);

  const checkedKeys: Key[] = useMemo(() => {
    return checkedItems.map(item => item.permissionCode!);
  }, [checkedItems]);

  const autoSyncedKeys: Key[] = useMemo(() => {
    return autoSyncedItems.map(item => item.permissionCode!);
  }, [autoSyncedItems]);

  const setCheckedKeys = (keys: Key[]) => {
    const foundList = keys.map(key => {
      return getOriginalTreeItemByKey(originalTreeData, key as string);
    });
    const checkedItems = foundList.map(item => {
      return {
        permissionCode: item?.code,
      };
    });
    setCheckedItems(checkedItems);
  };

  const setAutoSyncedKeys = (keys: Key[]) => {
    const foundList = keys.map(key => {
      return getOriginalTreeItemByKey(originalTreeData, key as string);
    });
    const autoSyncedItems = foundList.map(item => {
      return {
        permissionCode: item?.code,
      };
    });
    setAutoSyncedItems(autoSyncedItems);
  };

  const getSubmitData = (): UpdateAppPermissionBodyItem[] => {
    if (!treeData.length) return [];
    let data = {
      roleCode: code,
      miniAppId: originalTreeData[0].miniAppId,
      permissions: checkedKeys as string[],
      syncPermissions: autoSyncedKeys as string[],
    };
    return [data];
  };

  useImperativeHandle(ref, () => ({
    getSubmitData: getSubmitData,
  }));

  return (
    <RoleTree
      treeData={treeData}
      className={styles.treeWrapper}
      checkedKeys={checkedKeys}
      onCheck={setCheckedKeys}
      autoSyncedKeys={autoSyncedKeys}
      onAutoSync={setAutoSyncedKeys}
      autoSyncable
      showDetail
      disabled={props.disabled}
    />
  );
});

// 原始树结构转为组件的树结构
function convertToTreeData(originalTreeData: MiniAppPermissionFunctionTreeItem[]): DataNode[] {
  const nodes = originalTreeData.map(item => {
    const node: DataNode = {
      children: [] as DataNode[],
      key: item.code as Key,
      title: item.name,
      description: item.description,
      level: item.level,
      code: item.code,
    };
    if (item.children?.length) {
      node.children = convertToTreeData(item.children);
    }
    return node;
  });
  return nodes;
}

// 根据 key 获取原始树结构中的节点
function getOriginalTreeItemByKey(
  originalTreeData: MiniAppPermissionFunctionTreeItem[],
  key: string
): MiniAppPermissionFunctionTreeItem | undefined {
  for (const item of originalTreeData) {
    if (item.code === key) {
      return item;
    }
    if (item.children?.length) {
      let found = getOriginalTreeItemByKey(item.children, key);
      if (found) return found;
    }
  }
}

export default MiniAppPermissions;
