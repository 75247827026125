import { EllipsisSpan } from '@maxtropy/components';
import React from 'react';
import { Badge } from 'antd';
import { Status } from '../../api/template';
import { Link } from 'react-router-dom';

export const templateColumns = [
  {
    title: '模板编号',
    dataIndex: 'serialNumber',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '模板名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    dataIndex: 'deviceTypeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '厂商',
    dataIndex: 'manufacturerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '物模型型号',
    dataIndex: 'modelNo',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最新版本号',
    dataIndex: 'lastVersion',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '操作',
    dataIndex: 'id',
    render: (v: number) => (
      <Link to={`/device/info/template/${v}`} target="_blank">
        查看
      </Link>
    ),
  },
];

export const selectTemplateColumns = [
  {
    title: '模板编号',
    dataIndex: 'serialNumber',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '模板名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '所属类目',
    dataIndex: 'deviceTypeName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '厂商',
    dataIndex: 'manufacturerName',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '物模型型号',
    dataIndex: 'modelNo',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '最新版本号',
    dataIndex: 'lastVersion',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v.toString()} />,
  },
  {
    title: '状态',
    dataIndex: 'status',
    ellipsis: { showTitle: true },
    render: (v: Status) => (
      <Badge status={v === Status.DISABLE ? 'error' : 'success'} text={v === Status.DISABLE ? '禁用' : '启用'} />
    ),
  },
  {
    title: '已分配租户数',
    dataIndex: 'tenantUseCount',
    ellipsis: { showTitle: true },
    render: (v: number) => <EllipsisSpan value={v ? v.toString() : '0'} />,
  },
];

export const selectTenantColumns = [
  {
    title: '租户CODE',
    dataIndex: 'tenantCode',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '租户名称',
    dataIndex: 'name',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
  {
    title: '统一社会信用代码',
    dataIndex: 'uscc',
    ellipsis: { showTitle: true },
    render: (v: string) => <EllipsisSpan value={v} />,
  },
];
