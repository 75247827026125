import { Modal, Input, Button } from '@maxtropy/components';
import { apiV2NavigationUpdateParentIdPost } from '@maxtropy/tody-mgmt-apis-v2';
import { Form, Tree, Space } from 'antd';
import { FC, useEffect, useState, useMemo } from 'react';
import styles from './index.module.scss';
import { TenantMenuNavigationResponse } from '../../../../../api/cc-navigation';

import { cloneDeep, isNil } from 'lodash-es';
import { InfoCircleOutlined } from '@ant-design/icons';

interface EditModalProps {
  setIsMoveModal: (val: boolean) => void;
  isMoveModal?: boolean;
  editId?: string;
  refreshData: (val: string) => void;
  current?: TenantMenuNavigationResponse;
  navdata?: any;
}
const MoveIntoExists: FC<EditModalProps> = props => {
  const { setIsMoveModal, isMoveModal, navdata, editId, current, refreshData } = props;
  const [form] = Form.useForm();

  const [treeSelectedNodes, setTreeSelectedNodes] = useState<any[]>([]); // 选择树
  const [treeExpandedKeys, setTreeExpandedKeys] = useState<any>([]);
  const [searchParams, setSearchParams] = useState<any>();

  const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true); // 左部树父节点是否自动展开
  const [changeData, setChangeData] = useState<any>([]);

  const onClose = () => {
    setSearchParams({ name: '' });
    setIsMoveModal(false);
  };
  const dataList: { key: React.Key; title: string }[] = [];
  const generateList = (data: any[]) => {
    for (let i = 0; i < data.length; i++) {
      const node = data[i];
      console.log(node);
      const { name, id } = node;
      dataList.push({ key: id, title: name as string });
      if (node.trees) {
        generateList(node.trees);
      }
    }
  };

  const getParentKey = (key: React.Key, tree: any[]): React.Key => {
    let parentKey: React.Key;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item: any) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey!;
  };
  // const flattenArray = (data:any, arr: { key: string; title: string }[] = []) => {
  //   data.forEach(i => {
  //     arr.push({ key: i.key as string, title: i.name as string });
  //     if (i.children) {
  //       flattenArray(i.children, arr);
  //     }
  //   });
  //   return arr;
  // };

  // // 根据节点的Id获取所有父节点
  // const getPathByNodeId = (data: DataNode[], key: React.Key, path: string[] = []): string[] => {
  //   for (const node of data) {
  //     path.push(node.key as string);
  //     if (node.key === key) return path;
  //     if (node.children) {
  //       const findChildren = getPathByNodeId(node.children, key, path);
  //       if (findChildren?.length) return findChildren;
  //     }
  //     path.pop();
  //   }
  //   return [];
  // };
  // // 找parentKey
  // useEffect(() => {
  //   if (navdata && navdata.length) {
  //     if (searchParams && searchParams.name) {
  //       const dataflatArr = flattenArray(navdata);
  //       const newExpandedKeys = dataflatArr
  //         ?.map(item => {
  //           if (item.title.includes(searchParams.name)) {
  //             return getPathByNodeId(navdata, item.key);
  //           }
  //           return null;
  //         })
  //         .filter(i => !!i);
  //       newExpandedKeys.forEach(i => i?.pop());
  //       setTreeExpandedKeys(Array.from(new Set(newExpandedKeys.flat())) as string[]);
  //     } else {
  //       // setTreeExpandedKeys(dataList.map(i => i.key));
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [searchParams, navdata]);

  function findAndDeleteNode(data: any, targetCurrentId: number) {
    for (let i = 0; i < data.length; i++) {
      const node = data[i];
      if (node.currentId === targetCurrentId) {
        // 如果找到目标节点，删除整个对象
        data.splice(i, 1);
        return data; // 返回删除节点后的数组
      }
      if (node.trees && node.trees.length > 0) {
        // 如果有子节点，递归查找并删除
        const updatedChildNodes = findAndDeleteNode(node.trees, targetCurrentId);
        if (updatedChildNodes.length !== node.trees.length) {
          // 如果子节点数组有变化，则更新当前节点的子节点数组
          node.trees = updatedChildNodes;
          return data; // 返回更新后的数组
        }
      }
    }
    return data; // 没有找到对应节点，返回原始数组
  }

  useEffect(() => {
    const cloneNavdata = cloneDeep(navdata);
    if (isMoveModal && current && current.level === 1) {
      const isNodeDeleted = findAndDeleteNode(cloneNavdata, current.currentId);
      const clearTreeData = clearNestedTrees(isNodeDeleted);
      setChangeData(clearTreeData);
      generateList(clearTreeData);
      console.log(dataList);
      setTreeExpandedKeys(dataList.map(i => i.key));
    } else if (isMoveModal && current && current.level === 2) {
      const isNodeDeleted = findAndDeleteNode(cloneNavdata, current.currentId);
      setChangeData(isNodeDeleted);
      generateList(isNodeDeleted);
      setTreeExpandedKeys(dataList.map(i => i.key));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMoveModal]);

  function clearNestedTrees(data: any) {
    const updatedData = data.map((node: any) => {
      if (node.level === 2 && node.trees && node.trees.length > 0) {
        return { ...node, trees: undefined };
      }
      if (node.trees && node.trees.length > 0) {
        // 递归处理子节点
        return { ...node, trees: clearNestedTrees(node.trees) };
      }
      return node;
    });
    return updatedData;
  }

  const onOk = () => {
    form.validateFields().then(res => {
      if (isNil(current)) return;
      const mapList = (treeSelectedNodes ?? []).map(item => ({
        id: item.id,
        rootId: current.rootId,
        parentId: current.currentId,
      }));
      console.log(mapList);

      apiV2NavigationUpdateParentIdPost({ requestList: mapList }).then(() => {
        onClose();
        refreshData(editId as string);
      });
    });
  };
  // 搜索的tree文字标红色
  const treeData = useMemo(() => {
    if (changeData && changeData.length) {
      const loop = (changeData: any) =>
        changeData.map((item: any) => {
          const strTitle = item.name as string;
          const searchName = searchParams?.name ? searchParams.name : '';
          const index = strTitle.indexOf(searchName);
          const beforeStr = strTitle.substring(0, index);
          const afterStr = strTitle.slice(index + searchName?.length ?? 0);
          const title =
            index > -1 ? (
              <>
                <span style={{ marginRight: '5px' }} id={`${item.key}`}>
                  {beforeStr}
                  <span style={{ color: 'red' }}>{searchName}</span>
                  {afterStr}
                </span>
              </>
            ) : (
              <>
                <span style={{ marginRight: '5px' }} id={`${item.key}`}>
                  <span>{strTitle}</span>
                </span>
              </>
            );
          if (item.trees) {
            return { ...item, title, key: item.id, value: item.id, children: loop(item.trees), disableCheckbox: true };
          }
          return {
            ...item,
            key: item.id,
            disableCheckbox: !(current?.level === 1 && item.level === 2) && !(current?.level === 2 && item.level === 3),
            value: item.id,
            title,
          };
        });
      return loop(changeData);
    } else {
      return changeData;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, changeData]);

  return (
    <div className={styles.content}>
      <Modal
        title={`移入已有导航`}
        size={'normal'}
        open={isMoveModal}
        onCancel={onClose}
        destroyOnClose
        bodyStyle={{
          display: 'flex',
          justifyContent: 'center',
        }}
        bodyScroll
        footer={
          <div className={styles.footer}>
            <div>
              <InfoCircleOutlined style={{ color: 'var(--warning-color)' }} />
              &nbsp;移动后原目录中导航将移入选中导航下,不再保留！
            </div>
            <div>
              <Space size={'small'}>
                <Button onClick={onClose}>取消</Button>
                <Button
                  type="primary"
                  onClick={() => {
                    if (treeSelectedNodes.length === 0) {
                      Modal.warning({
                        title: '请选择要移动的导航！',
                      });
                      return;
                    }
                    Modal.warning({
                      title: '您是否确定将选中的导航移动到本导航下，移动后原目录中将没有这些移走的导航。',
                      onOk: onOk,
                    });
                  }}
                >
                  移动
                </Button>
              </Space>
            </div>
          </div>
        }
      >
        <div className={styles.paddingContent}>
          <Form form={form}>
            <Input.Search
              placeholder="请输入"
              allowClear
              style={{ width: 474 }}
              onSearch={(v: string) => {
                setAutoExpandParent(true);
                setSearchParams({ name: v });
                generateList(treeData);
                const newExpandedKeys = dataList
                  .map(item => {
                    if (item.title.indexOf(v) > -1) {
                      return getParentKey(item.key, treeData);
                    }
                    return null;
                  })
                  .filter((item, i, self): item is React.Key => !!(item && self.indexOf(item) === i));
                setTreeExpandedKeys(newExpandedKeys);
              }}
            />
            {treeData && treeData.length > 0 ? (
              <div className={styles.treeArea}>
                <Tree
                  treeData={treeData}
                  multiple
                  checkable
                  checkStrictly
                  onExpand={v => {
                    setTreeExpandedKeys(v);
                    setAutoExpandParent(false);
                  }}
                  onCheck={(v, info) => {
                    console.log(v, info);
                    setTreeSelectedNodes(info.checkedNodes);
                  }}
                  expandedKeys={treeExpandedKeys}
                  autoExpandParent={autoExpandParent}
                  defaultExpandAll={true}
                  defaultExpandParent={true}
                />
              </div>
            ) : null}
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default MoveIntoExists;
