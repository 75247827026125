import { useState, useEffect, Key } from 'react';
import Cookies from 'js-cookie';
import { IMenu, MenuEntry, PlaceType } from '@/api/menu';
import { useMgmtPermissions } from '@/components/Layout/hooks';

export const useQuery = <T>(action: () => Promise<T | undefined>) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<T | undefined>(undefined);
  useEffect(() => {
    setIsLoading(true);
    action()
      .then(setData)
      .finally(() => setIsLoading(false));
  }, [action]);
  return { isLoading, data };
};

export function useHasPermission(key: string) {
  const [status, setStatus] = useState<boolean>(false);
  const corpUserFunctionPermission = useMgmtPermissions();
  useEffect(() => {
    setStatus((corpUserFunctionPermission ?? []).findIndex(i => i === key) !== -1);
  }, [corpUserFunctionPermission, key]);
  return status;
}

export function downloadFile(url: string, fileName?: string) {
  window
    .fetch(url, {
      headers: {
        JwtToken: Cookies.get('token') ?? '',
      },
    })
    .then(async res => {
      const blobData = await res.blob();
      const blob = new Blob([blobData], { type: 'application/octet-stream' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      const contentDisposition = decodeURIComponent(escape(res.headers.get('Content-Disposition') ?? ''));
      const resFileName = contentDisposition.split('fileName=')[1];
      link.download = fileName || resFileName;
      link.click();
      URL.revokeObjectURL(url);
    });
}

export function getAllChildKey(data: MenuEntry[]) {
  const res: Key[] = [];
  for (const node of data) {
    if (Array.isArray(node.children) && node.children.length) {
      res.push(...getAllChildKey(node.children));
    } else {
      res.push(node.key!);
    }
  }
  return res;
}

export function getEffectTreeByChildKey(data: MenuEntry[], keys: Key[]) {
  const res: MenuEntry[] = [];
  for (const node of data) {
    if (node.always) {
      res.push(node);
    } else {
      if (keys.includes(node.key!)) {
        res.push(node);
      } else {
        const children =
          Array.isArray(node.children) && node.children.length
            ? getEffectTreeByChildKey(node.children, keys)
            : undefined;
        if (children && Array.isArray(children) && children.length) {
          res.push({
            ...node,
            children: children,
          });
        }
      }
    }
  }
  return res;
}

export function getFilterMenu(menuData: MenuEntry[], permissions: string[]) {
  const menuChildKeys = getAllChildKey(menuData);
  const menu = getEffectTreeByChildKey(
    menuData,
    permissions.filter(i => menuChildKeys.includes(i))
  );
  return menu;
}

export function buildOriginMenu(menuData: MenuEntry[], menuOption: IMenu[]) {
  return menuOption
    .filter(i => i.place === PlaceType.TOP)
    .map(i => {
      const findMenu = menuData.find(j => j.key === i.code);
      if (findMenu) {
        return {
          ...setChildren([findMenu])[0],
          url: i.path ?? '',
          name: i.name,
        };
      } else {
        return {
          name: i.name,
          key: i.code,
          url: i.path ?? '',
          children: [],
        } as MenuEntry;
      }
    });
}

export function setChildren(menuData: MenuEntry[]): MenuEntry[] {
  return menuData.map(i => ({
    ...i,
    children: i.children && i.children.length ? setChildren(i.children) : [],
  }));
}

export function getFirstUrl(data?: MenuEntry[]): string {
  if (!data) {
    return '';
  }
  for (const node of data) {
    if (Array.isArray(node.children) && node.children.length) {
      return getFirstUrl(node.children);
    } else {
      return node.url ?? '';
    }
  }
  return '';
}
