import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Table, useAsync } from '@maxtropy/components';

import { Button, Divider, Form, Input, Modal, Space, Switch } from 'antd';
import { uniqueId, cloneDeep } from 'lodash-es';
import { FC, useEffect, useState } from 'react';
import { columns } from './util';

import { OutsiderResponse } from '../../../../api/deviceAlarmPushStrategy';
import { getOutsidersChannelApp } from '@/shared/api/common';
import { ChannelApp } from '@/shared/types';

export interface SelectPushOutSiderProps {
  value?: OutsiderResponse[];
  onChange?: (value: OutsiderResponse[]) => void;
  disabled?: boolean;
  // 返回true，校验不通过, 否则校验通过
  validator?: (values: string[]) => boolean | undefined;
}

const errorMessage = '手机号已被添加/与内部推送人员重复，请重新';

const SelectPushOutSider: FC<SelectPushOutSiderProps> = ({ disabled = false, validator, value, onChange }) => {
  const [form] = Form.useForm();

  const outsidersChannelApps = useAsync(getOutsidersChannelApp);

  const [current, setCurrent] = useState<OutsiderResponse>();

  const onDelete = (index: number) => {
    onChange?.([...(value ?? []).slice(0, index), ...(value ?? []).slice(index + 1)]);
  };

  useEffect(() => {
    if (current) {
      form.setFieldsValue({
        contactName: current.contactName,
        contactPhone: current.contactPhone,
      });
    }
  }, [current, form]);

  const changeRecordThirdApp = (checked: boolean, channelApp: ChannelApp, record: OutsiderResponse) => {
    const index = (value ?? []).findIndex(i => i.id === record.id);
    const newRecord = cloneDeep(record);
    if (!Array.isArray(newRecord.thirdPartyIdList)) {
      newRecord.thirdPartyIdList = [];
    }
    if (index !== -1) {
      const thirdAppIndex = newRecord.thirdPartyIdList.findIndex(i => i === channelApp.channelId);
      if (checked) {
        if (thirdAppIndex === -1) {
          newRecord.thirdPartyIdList.push(channelApp.channelId);
        }
      } else {
        if (thirdAppIndex !== -1) {
          newRecord.thirdPartyIdList = record.thirdPartyIdList.filter(i => i !== channelApp.channelId);
        }
      }
      const newValue = [...(value ?? []).slice(0, index), newRecord, ...(value ?? []).slice(index + 1)];
      onChange?.(newValue);
    }
  };

  const buildColumns = [
    ...columns,
    ...(outsidersChannelApps ?? []).map(i => ({
      title: i.channelName,
      render: (value: undefined, record: OutsiderResponse) => {
        const checked = !!(record.thirdPartyIdList ?? []).find(j => j === i.channelId);
        return disabled ? (
          checked ? (
            '开'
          ) : (
            '关'
          )
        ) : (
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={checked}
            onChange={(checked: boolean) => {
              changeRecordThirdApp(checked, i, record);
            }}
          />
        );
      },
    })),
    ...(disabled
      ? []
      : [
          {
            title: '操作',
            dataIndex: 'operation',
            width: 250,
            fixed: 'right' as const,
            render: (value: undefined, record: OutsiderResponse, index: number) => {
              return (
                <Space size={16}>
                  <Button
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    type="link"
                    onClick={() => {
                      onDelete(index);
                    }}
                  >
                    移除
                  </Button>
                  <Button
                    style={{ paddingLeft: 0, paddingRight: 0 }}
                    type="link"
                    onClick={() => {
                      setCurrent(record);
                    }}
                  >
                    编辑
                  </Button>
                </Space>
              );
            },
          },
        ]),
  ];

  /** 添加编辑 */

  const [visible, setVisible] = useState<boolean>(false);

  const onAdd = () => {
    setVisible(true);
  };

  const onOk = () => {
    form.validateFields().then((data: Omit<OutsiderResponse, 'id' | 'thirdPartyIdList'>) => {
      if (current) {
        const index = (value ?? []).findIndex(i => i.id === current.id);
        onChange?.([
          ...(value ?? []).slice(0, index),
          {
            ...current,
            ...data,
          },
          ...(value ?? []).slice(index + 1),
        ]);
      } else {
        onChange?.([
          {
            ...data,
            id: uniqueId(),
            thirdPartyIdList: [],
          },
          ...(value ?? []),
        ]);
      }
      onClose();
    });
  };

  const onClose = () => {
    setCurrent(undefined);
    setVisible(false);
    form.resetFields();
  };

  /** 添加编辑 */

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        {!disabled && (
          <Space>
            <Button type="dashed" onClick={onAdd}>
              <PlusOutlined />
              添加
            </Button>
          </Space>
        )}
        <Table
          rowKey="contactPhone"
          scroll={{ x: 1300, y: 400 }}
          sticky
          loading={false}
          dataSource={value ?? []}
          columns={buildColumns}
        />
      </Space>
      <Modal
        title="外部推送人员"
        width={600}
        open={!!current || visible}
        onCancel={onClose}
        destroyOnClose
        onOk={onOk}
        bodyStyle={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Form style={{ width: 450 }} form={form} labelCol={{ flex: '110px' }} labelAlign="right">
          <Form.Item
            name="contactName"
            label="联系人"
            rules={[
              { required: true, message: '请输入联系人', whitespace: true, type: 'string' },
              { max: 20, message: '最多输入20个字' },
            ]}
          >
            <Input maxLength={20} disabled={disabled} placeholder="请输入" />
          </Form.Item>
          <Form.Item
            name="contactPhone"
            label="手机号"
            rules={[
              { required: true, message: '请输入手机号', whitespace: true, type: 'string' },
              { max: 20, message: '最多输入20个字' },
              {
                validator: (_: any, v: string) => {
                  const validateStatus = validator?.([v]) || undefined;
                  if (validateStatus !== true) {
                    // 外部校验通过
                    // TODO： 排除current下的值，校验当前组件内的value是否通过
                    const find = (value ?? []).filter(i => i !== current).find(i => i.contactPhone === v);
                    if (find !== undefined) {
                      return Promise.reject(`${errorMessage}输入`);
                    }
                    return Promise.resolve();
                  } else {
                    return Promise.reject(`${errorMessage}输入`);
                  }
                },
              },
            ]}
          >
            <Input maxLength={20} disabled={disabled} placeholder="请输入" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SelectPushOutSider;
