import { DataPointType, DeviceAttribute, DeviceType, DriveType, IotProtocolType, PointType } from '@/shared/types';
import { fetch, Key } from '@maxtropy/components';
import { PageRequest, PageResponse } from './page';
import qs from 'qs';
import { DeviceTypes } from './attribute';

export interface DeviceTypeTree {
  aliasName: Array<string>;
  tree: Array<DeviceTypes>;
}

export const getDeviceTypeData = () => fetch<DeviceTypeTree>(`/api/deviceType/tree`);

export interface TemplateBody {
  name: string;
  deviceTypeId: number;
  remark: string;
  attributeList: number[];
  description?: string;
}

export interface Template {
  id: number;
  name: string;
  serialNumber: string;
  deviceTypeId: number;
  deviceTypeName: string;
  lastVersion: number;
  remark: string;
  deviceAttributes: DeviceAttribute[];
  description?: string;
  physicalModelId?: number;
  modelNo?: string;
  manufacturerName?: string;
}

export interface UpdateTemplateBody extends TemplateBody {
  id: number;
}

export const addTemplate = (params: TemplateBody) =>
  fetch<void>(`/api/template`, {
    method: 'POST',
    body: JSON.stringify(params),
  });

export const updateTemplate = (params: UpdateTemplateBody) =>
  fetch<void>(`/api/template`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });

export const getTemplate = (id: number) => fetch<{ template: Template }>(`/api/template/${id}`);

export const getDeviceTypeTree = () => fetch<{ tree: DeviceType[] }>(`/api/deviceType/tree`);

export const getDeviceTypeTreeWithoutScene = (params?: { onlyGateway?: boolean }) =>
  fetch<{ deviceTypes: DeviceType[] }>(`/api/deviceType/treeWithoutScene?${qs.stringify(params, { indices: false })}`);
// 根据物模型查询数据属性
export const getTypeAttributeListByPhysicalModel = (id: number) =>
  fetch<{ attributes: DeviceAttribute[] }>(`/api/attribute/listByPhysicalModel?physicalModelId=${id}`);
// 根据所属类目的设备类型查询数据属性
export const getTypeAttributeListBydeviceType = (id: number) =>
  fetch<{ attributes: DeviceAttribute[] }>(`/api/attribute/list?deviceTypeId=${id}`);
export interface TemplateListRequest extends PageRequest {
  name?: string;
  deviceType?: Array<number> | undefined;
  status?: Status;
  manufacturerId?: number;
  physicalModelId?: number;
  originDeviceType?: Array<Array<number>>;
}

export enum Status {
  DISABLE,
  ENABLE,
}

export const StatusDisplay = {
  [Status.DISABLE]: '停用',
  [Status.ENABLE]: '启用',
};

export type TemplateResponse = PageResponse<TemplateListProps>;

export interface TemplateListProps {
  id: number;
  name: string;
  serialNumber: string;
  deviceTypeId: number;
  lastVersion: number;
  status: Status;
  createTime: string;
  updateTime: string;
  lastUsername: string;
  remark: string;
  attributeList: string;
  manufacturerName: string;
  modelNo: string;
}

export const getTemplateList = (params?: TemplateListRequest) =>
  fetch<TemplateResponse>(`/api/template/page?${qs.stringify(params, { indices: false })}`);

export const changeTemplateStatus = (params: { id: number; state: Status }) =>
  fetch(
    `/api/template/${params.id}/state?${qs.stringify(params, {
      indices: false,
    })}`,
    {
      method: 'PUT',
    }
  );

export interface EdgeTemplatePageRequest extends PageRequest {
  name?: string;
  deviceType?: Array<number>;
  iotProtocol?: IotProtocolType;
  driveType?: DriveType;
  mcid?: string;
  physicalModelId?: Key;
}

export interface EdgeTemplate {
  id: number;
  iotProtocol: number;
  latestVersion: number;
  name: string;
  serialNumber: string;
  status: number;
  updateByUsername: string;
  updateTime: string;
  usedByDevice: number;
}

export type EdgeTemplateResponse = PageResponse<EdgeTemplate>;

export const getEdgeTemplatePage = (params: EdgeTemplatePageRequest) =>
  fetch<EdgeTemplateResponse>(`/api/edgeTemplate/version/page?${qs.stringify(params, { indices: false })}`);

export interface EdgeTemplateVersionPageRequest extends PageRequest {
  templateVersionId: number;
  mcid?: string;
}

export interface EdgeTemplateVersion {
  id: number;
  remark: string;
  status: number;
  usedByUsername: string;
  usedByDevice: number;
  updateTime: string;
  version: number;
}

export type EdgeTemplateVersionResponse = PageResponse<EdgeTemplateVersion>;

export const getEdgeTemplateVersionPage = (params: EdgeTemplateVersionPageRequest) =>
  fetch<EdgeTemplateVersionResponse>(`/api/edgeTemplate/version/page?${qs.stringify(params, { indices: false })}`);

export interface EdgeTemplateVersion {
  id: number;
  remark: string;
  status: number;
  usedByUsername: string;
  usedByDevice: number;
  updateTime: string;
  version: number;
}

export interface EdgeTemplateDetail {
  id: number;
  name: string;
  serialNumber: string;
  remark: string;
  latestVersion: number;
  updateByUserId: string;
  updateByUsername: string;
  status: number;
  iotProtocol: number;
  deviceTypeId: number;
  deviceModelId: number;
  createTime: string;
  updateTime: string;
  deviceTypeName: string;
  deviceModelName: string;
  version: number;
  versionStatus: number;
  versionRemark: string;
}

export const getEdgeTemplateDetail = (id: number) => fetch<EdgeTemplateDetail>(`/api/edgeTemplate/version/${id}`);

export interface Points {
  id: number;
  dataPropertyId: number;
  physicalUnitId: number;
  uploadType: number;
  iotProtocol: number;
}

export const getPointsByTemplateVersionId = (id: number) =>
  fetch<PointType[]>(`/api/edgeTemplate/version/point/${id}/list`);

export interface PointsPageRequest extends PageRequest {
  templateId: number;
}

export type PointsPage = PageResponse<Points>;

interface VersionPointsRequest extends PageRequest {
  templateVersionId: number;
  name?: string;
  pointType?: DataPointType;
}

export const getPointsPageByVersionId = (params: VersionPointsRequest) =>
  fetch<PointsPage>(
    `/api/edgeTemplate/version/point/${params.templateVersionId}/page?${qs.stringify(params, { indices: false })}`
  );

export const getPointsPageByTemplateId = (params: PointsPageRequest) =>
  fetch<PointsPage>(
    `/api/edgeTemplate/${params.templateId}/latestVersion/point/page?${qs.stringify(params, { indices: false })}`
  );

export interface ModifyVersion {
  versionId: number;
  remark: string | undefined;
}

export const modifyVersion = (params: ModifyVersion) =>
  fetch(`/api/edgeTemplate/version/${params.versionId}`, {
    method: 'PUT',
    body: JSON.stringify(params),
  });

export const getAllTemplateList = (id: number, mcid: string, physicalModelId = '') =>
  fetch<TemplateListProps[]>(`/api/template/list/${id}?mcid=${mcid}&physicalModelId=${physicalModelId}`);

export const getTemplateByVersion = (id: number, version: number) =>
  fetch<{ template: Template }>(`/api/template/${id}/getByVersion?version=${version}`);

export interface AttributeTemplateListRequest extends PageRequest {
  codeOrName?: string;
  templateId?: number;
  deviceTypeId: number;
  tenantMcid: string;
  operationType: number;
  physicalModelId?: number;
}

export interface AttributeTemplate {
  id: number;
  name: string;
  serialNumber: string;
  version: number;
  useCount: number;
}

export type AttributeTemplateListResponse = PageResponse<AttributeTemplate>;

export const getAttributeTemplateList = (params: AttributeTemplateListRequest) =>
  fetch<AttributeTemplateListResponse>(
    `/api/batch/device/template/attribute/page?${qs.stringify(params, { indices: false })}`
  );
