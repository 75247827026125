import { useState, useRef, useEffect } from 'react';
import { Modal } from 'antd';
import { ModalFuncProps } from 'antd/es/modal';
import { ModalFunc } from 'antd/es/modal/confirm';
import { NavigateFunction } from 'react-router-dom';

type ModalAPiType = ReturnType<typeof Modal.useModal>[0]

export function countModal(modalapi: ModalAPiType, secondsToGo: number, navigate: NavigateFunction, url: string, title: string) {
  const modal = modalapi.success({
    title: '操作成功',
    content: title,
    okText: `返回列表(${secondsToGo}秒)`,
    onOk: () => {
      navigate(url);
      clearInterval(timer);
    },
  });
  const timer = setInterval(() => {
    secondsToGo -= 1;
    if (secondsToGo !== 0) {
      modal.update({
        okText: `返回列表(${secondsToGo}秒)`,
      });
    } else navigate(url);
  }, 1000);
  setTimeout(() => {
    clearInterval(timer);
    modal.destroy();
  }, secondsToGo * 1000);
}

export const useCountDownGotoModal = (count: number) => {
  const [countDown, setCountDown] = useState<number>(count ?? 10);
  const [option, setOption] = useState<ModalFuncProps>();
  const ref = useRef<number>();
  const [modal, contextHolder] = Modal.useModal();
  const modalRef = useRef<ReturnType<ModalFunc>>();

  useEffect(() => {
    if (option) {
      if (ref.current) {
        window.clearInterval(ref.current);
        setCountDown(count);
      }
      ref.current = window.setInterval(() => {
        setCountDown(pre => {
          if (pre > 0) {
            return pre - 1;
          } else {
            return 0;
          }
        });
      }, 1000);
    }
    return () => {
      window.clearInterval(ref.current);
      modalRef.current && modalRef.current.destroy && modalRef.current.destroy();
      modalRef.current = undefined;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option]);

  useEffect(() => {
    if (option && countDown === 0) {
      window.clearInterval(ref.current);
      option.onOk && option.onOk();
      modalRef.current && modalRef.current.destroy && modalRef.current.destroy();
      setCountDown(count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countDown, option]);

  useEffect(() => {
    if (option) {
      if (modalRef.current) {
        modalRef.current.update({
          ...option,
          okText: `${option.okText}(${countDown})秒`,
          onOk() {
            option.onOk && option.onOk();
            modalRef.current && modalRef.current.destroy && modalRef.current.destroy();
            setCountDown(count);
          },
        });
      } else {
        modalRef.current = modal.success({
          ...option,
          okText: `${option.okText}(${countDown})秒`,
          onOk() {
            option.onOk && option.onOk();
            modalRef.current && modalRef.current.destroy && modalRef.current.destroy();
            setCountDown(count);
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option, countDown, modal]);

  useEffect(() => {
    return () => {
      window.clearInterval(ref.current);
      modalRef.current && modalRef.current.destroy && modalRef.current.destroy();
    };
  }, []);
  return {
    setOption,
    contextHolder,
  };
};
